import { Role } from "@common/types/reports/roles/roles";
import { TableColumn } from "@common/types/table";
import classNames from "classnames";
import { DataType } from "ka-table/enums";
import { ICellTextProps, IDataRowProps } from "ka-table/props";
import grid from "@styles/modules/grid.module.scss";
import { SecondLevelTable } from "@ui";
import { RoleAction, RoleInfo } from "@features/settings/blocks/roles";
import { PropsWithChildren } from "react";
import { getDeclensions } from "@common/helpers/string-helpers";
import styles from "../../blocks.module.scss";

const getEmployees = (data: PropsWithChildren<ICellTextProps>): string => {
  return `${data.value} ${getDeclensions(data.value, "employee", "employees")}`;
};

export const SecondLevel: React.FC<IDataRowProps> = ({ rowData }) => {
  const data = rowData.roles;
  const columns: TableColumn<Role>[] = [
    {
      key: "name",
      title: "Role Name",
      dataType: DataType.String,
      modClass: classNames(grid.item, grid["item-8x"], styles["align-left"], "!pl-[30px]"),
      component: RoleInfo
    },
    {
      key: "members_count",
      title: "Employees",
      dataType: DataType.Number,
      modClass: classNames(grid.item, grid["item-2x"], styles["align-left"]),
      decorator: data => getEmployees(data)
    },
    {
      key: "id",
      modClass: classNames(grid.item, grid["item-1x"]),
      component: RoleInfo
    },
    {
      key: "index",
      modClass: classNames(grid.item, grid["item-1x"]),
      component: RoleAction
    }
  ];
  return SecondLevelTable<Role>({
    data,
    columns,
    sorting: false,
    modStyles: { "cell-inner": "justify-start !px-0" },
    theme: "orange",
    hideHeader: true
  });
};
