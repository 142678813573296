import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import { DefaultLayout } from "../../layouts";
import { ResetPasswordPage } from "@pages";

export const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const startPage = <Navigate to="/login" />;
  return (
    <DefaultLayout>
      <Routes>
        <Route path="/" element={token ? <ResetPasswordPage /> : startPage}></Route>
        <Route path="/*" element={startPage}></Route>
      </Routes>
    </DefaultLayout>
  );
};
