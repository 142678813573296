import { getTimezones as getTimezonesFunction } from "@common/api/services/timezones";
import { $destinationDataStore } from "@common/api/store/destination";
import { CommonWithId } from "@common/types/common";

export const getTimezones = async (callback: any) => {
  try {
    const response = await getTimezonesFunction<CommonWithId>("client/location/timezones");
    $destinationDataStore.setTimezones(response.data.data);
    callback(response.data.data);
  } catch (e) {
    console.error("Get timezones error");
  }
};
