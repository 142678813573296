import React, { useState } from "react";
import { Course } from "@features/learn/types";
import styles from "./carousel.module.scss";
import stylesCarousels from "./../carousels.module.scss";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Svg } from "@ui";
import classNames from "classnames";
import { getPhrases } from "@common/helpers/localization";
import { CourseCard } from "./CourseCard";
import { Link } from "react-router-dom";
import { CarouselHeader } from "../carousel-header";

interface CarouselProps {
  CoursesData: Course[];
  isFavouriteCoursesPage?: boolean;
  leftHeading?: React.ReactNode;
  leftSubHeading?: React.ReactNode;
  rightSubHeading?: React.ReactNode;
  rightHeading?: React.ReactNode;
  isLearningPath?: boolean;
  refresh?: () => void;
}

export const Carousel: React.FC<CarouselProps> = ({
  CoursesData,
  rightHeading,
  leftHeading,
  leftSubHeading,
  rightSubHeading,
  isFavouriteCoursesPage,
  isLearningPath = false,
  refresh = () => null
}) => {
  const [controlledSwiper, setControlledSwiper] = useState<SwiperCore>();

  const leftArrowClickHandler = () => {
    controlledSwiper?.slidePrev();
  };

  const rightArrowClickHandler = () => {
    controlledSwiper?.slideNext();
  };

  const loc = getPhrases("learn_page", "common");

  return (
    <div>
      <CarouselHeader
        leftHeading={leftHeading}
        leftSubHeading={leftSubHeading}
        rightHeading={rightHeading}
        rightSubHeading={rightSubHeading}
      />

      {CoursesData.length > 0 && (
        <div className={styles.carouselContainer}>
          {/*         LEFT ARROW      */}
          <div onClick={leftArrowClickHandler} className={classNames(styles.arrow, styles.arrowLeft)} />

          {/*    Main Slider  */}
          <Swiper
            loop={CoursesData.length <= 5 ? false : true}
            onSwiper={setControlledSwiper}
            spaceBetween={30}
            slidesPerView="auto"
            className={styles.mySwiper}
            preventClicks={false}
            preventClicksPropagation={false}
          >
            {CoursesData.map(({ id, ...data }) => (
              <SwiperSlide key={id} className={styles.swiperSlide}>
                {isLearningPath ? (
                  <LearningPathWrapper id={id}>
                    <CourseCard id={id} {...data} isShowFavorite={false} isLearningPath={isLearningPath} />
                  </LearningPathWrapper>
                ) : (
                  <CourseCard id={id} {...data} refresh={refresh} />
                )}
              </SwiperSlide>
            ))}
          </Swiper>

          {/*     RIGHT ARROW     */}
          <div onClick={rightArrowClickHandler} className={classNames(styles.arrowRight, styles.arrow)} />
        </div>
      )}

      {!CoursesData?.length && isFavouriteCoursesPage && (
        <div className={styles.noCourseToShow}>
          There are no favorite items to show here because you have not marked any items as favorite: <br />
          please click on the heart icon <Svg name="heart-red" /> at the top right of any learn item in order to add it
          to this screen
        </div>
      )}
    </div>
  );
};

interface Props {
  id: number;
  children: React.ReactChild | React.ReactChild[];
}

const LearningPathWrapper = ({ id, children }: Props) => {
  return <Link to={`/learn/learning-path/${id}`}>{children}</Link>;
};
