import React, { FC, useEffect, useState } from "react";
import * as yup from "yup";
import classNames from "classnames";
import { getSkillCategories } from "@common/features/skills/model";
import { useForm } from "@common/hooks/form";
import { ISkillForm } from "@common/types/skill-engine/skill";
import { SkillCategory } from "@common/types/skill-engine/skill-category";
import { ComplexModalButtons, FormInputAutocomplete, FormInputText, FormTextArea, FormWrapper } from "@ui";

export interface ISkillsFormProps {
  creating?: boolean;
  formData?: ISkillForm[];
  onSubmit(data: ISkillForm[]): void;
  onClose(): void;
  onSetModalButtons(buttons?: ComplexModalButtons): void;
}

export interface ISkill extends ISkillForm {
  id: number;
}

export interface ISkills {
  skills: ISkill[];
}

export interface ISkillCategory extends SkillCategory {
  children?: ISkillCategory[];
}

const skillsFormSchema: yup.SchemaOf<ISkills> = yup.object().shape({
  skills: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required("This field is required"),
        description: yup.string().required("This field is required"),
        category_id: yup.number().required("This field is required"),
        is_active: yup.boolean()
      })
    )
    .min(1)
});

export const SkillsForm: FC<ISkillsFormProps> = ({ creating, formData, onSubmit, onClose, onSetModalButtons }) => {
  const methods = useForm<ISkills>({
    schema: skillsFormSchema,
    defaultValues: { skills: formData?.length ? formData : [{ id: 1, is_active: true, name: "", description: "" }] }
  });
  const data = methods.getValues();
  const [categories, setCategories] = useState<ISkillCategory[]>([]);
  const [timestamp, setTimestamp] = useState<number>();

  useEffect(() => {
    if (formData) {
      methods.reset({ skills: formData });
    }
  }, [formData]);

  useEffect(() => {
    getSkillCategories().then((categories: ISkillCategory[]) => {
      categories.forEach(category => {
        if (category.parent_id) {
          const parentCategory = categories.find(item => item.id === category.parent_id);
          if (parentCategory) {
            if (parentCategory.children) {
              parentCategory.children.push(category);
            } else {
              parentCategory.children = [category];
            }
          }
        }
      });
      setCategories(categories.filter(item => !item.parent_id));
    });
  }, []);

  useEffect(() => {
    onSetModalButtons({
      rightSide: [
        {
          title: (
            <span>
              Cancel
              <br />
              Changes
            </span>
          ),
          theme: "secondary-gradient",
          className: "!w-30 !h-14 !text-sm !text-left !font-semibold",
          onClick: onClose
        },
        {
          title: (
            <span>
              Save
              <br />
              Changes
            </span>
          ),
          theme: "orange",
          className: "!w-30 !h-14 !text-sm !text-left !font-semibold",
          onClick() {
            methods.handleSubmit(handleSuccess, handleError)();
          }
        }
      ]
    });
  }, [onSetModalButtons]);

  const onFieldChange = (field: any, value: any) => {
    methods.setValue(field, value);
    // methods.trigger(field);
    setTimestamp(new Date().getTime());
  };

  const onAddSkill = (index: number) => {
    const maxId = Math.max(0, ...data.skills.map(item => item.id));
    const skills: Partial<ISkill>[] = [...data.skills];
    skills.splice(index + 1, 0, {
      id: maxId + 1,
      name: "",
      is_active: true,
      description: ""
    });
    onFieldChange("skills", skills);
  };

  const onPatchSkill = (id: number, field: string, value: any) => {
    const skills = data.skills.map(item => (item.id === id ? { ...item, [field]: value } : item));
    onFieldChange("skills", skills);
  };

  const onRemoveSkill = (id: number) => {
    const skills = data.skills.filter(item => item.id !== id);
    onFieldChange("skills", skills);
  };

  const handleSuccess = (values: ISkills) => {
    onSubmit(values.skills);
  };

  const handleError = () => {};

  return (
    <FormWrapper className="text-black" onSubmit={methods.handleSubmit(handleSuccess, handleError)}>
      {data.skills.map((item, i) => (
        <div
          key={item.id}
          className={classNames(
            "grid sm:grid-cols-2 gap-4 border-gray-cd px-5",
            i && "border-t pt-4",
            i < data.skills.length - 1 && "pb-4"
          )}
        >
          <FormInputText
            className="!text-sm !text-black !placeholder-secondary placeholder-italic"
            name={`skills[${i}].name`}
            label="Name your skill"
            placeholder="Type the name of your skill"
            register={methods.register}
            error={methods.formState.errors?.skills && methods.formState.errors?.skills[i]?.name?.message}
          />
          <FormInputAutocomplete
            className="!text-sm !text-black !placeholder-secondary placeholder-italic"
            name={`skills[${i}].category_id`}
            label="Search for your skill category"
            placeholder="Type the name of the category here"
            list={categories}
            value={item.category_id}
            group
            groupSelectable
            getItemLabel={(item: SkillCategory) => item.name}
            getItemValue={(item: SkillCategory) => item.id}
            dropdownClass="max-h-30 overflow-auto !mt-0"
            dropdownItemClass="!text-sm"
            register={methods.register}
            error={
              methods.formState.errors?.skills &&
              methods.formState.errors?.skills[i]?.category_id?.message &&
              "This field is required"
            }
            onChange={id => onPatchSkill(item.id, "category_id", id)}
          />
          <FormTextArea
            className="!text-sm !text-black !placeholder-secondary placeholder-italic"
            name={`skills[${i}].description`}
            label="Skill Description"
            placeholder="Description here"
            register={methods.register}
            error={methods.formState.errors?.skills && methods.formState.errors?.skills[i]?.description?.message}
          />
          {creating && (
            <div className="flex flex-wrap items-start justify-between pt-6">
              <div
                className={classNames(
                  "text-warning font-bold text-sm mb-3",
                  i ? "cursor-pointer" : "pointer-events-none opacity-60"
                )}
                onClick={() => onRemoveSkill(item.id)}
              >
                <i className="fa fa-minus-circle" /> Delete this skill
              </div>
              <div className="text-warning font-bold text-sm mb-3 cursor-pointer" onClick={() => onAddSkill(i)}>
                Add another skill <i className="fa fa-plus-circle" />
              </div>
            </div>
          )}
        </div>
      ))}
    </FormWrapper>
  );
};
