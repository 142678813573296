import { FirstLevel as CategoryTable } from "./ui/category/first-level";
import { Skills } from "./ui/targets";
import { SkillsWrapper } from "@features/skills";

import { useContext, useEffect, useMemo, useState } from "react";
import { SkillsDataProps } from "@common/types/reports/skills/skills-employee";
import { getSkillCategories, getSkills } from "@common/features/skills/model";
import { GlobalStore } from "@common/api/store/global";
import { observer } from "mobx-react-lite";
import { SelectOptionsProp } from "@ui";

export const SkillsPageEmployee: React.FC = observer(() => {
  const [data, setData] = useState<SkillsDataProps[]>();
  const [selectedRootCategories, setSelectedRootCategories] = useState<SelectOptionsProp[]>([]);

  const { $commonStore } = useContext(GlobalStore);

  const onLoad = (data: SkillsDataProps[]) => {
    setData(data);
  };

  const sorted = (arr: SkillsDataProps[]) => {
    return arr.sort((a: Record<string, any>, b: Record<string, any>) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  };

  const filterd = (data: SkillsDataProps[]) => {
    if (!data) return [];
    const techSkills = sorted(data.filter(item => item.parent_id === 253));
    const softSkils = sorted(data.filter(item => item.parent_id === 254));
    const otherSkills = sorted(data.filter(item => item.parent_id !== 253 && item.parent_id !== 254));
    const result = [...techSkills, ...softSkils, ...otherSkills];
    return result;
  };

  const filteredData = useMemo(() => {
    const selectedIds = selectedRootCategories.map(option => option.value);
    const transformedData = data?.map(item => {
      const obj = $commonStore.skillCategories.find(el => Number(el.id) === Number(item.id));
      if (!obj) return item;
      return { ...item, parent_id: obj.parent_id };
    });
    const sortedTransformedData = filterd(transformedData!);
    const result = (sortedTransformedData || []).filter(datum => {
      const category = $commonStore.skillCategories.find(cat => +cat.id === +datum.id);
      if (selectedIds.length === 0) {
        return true;
      }
      return selectedIds.includes(category?.parent_id);
    });
    return result;
  }, [data, selectedRootCategories, $commonStore.skillCategories]);

  const rootSkills = useMemo(() => {
    const options = $commonStore.skillCategories
      .filter(category => !category.parent_id)
      .map(category => ({ value: category.id, label: category.name }));
    setSelectedRootCategories(options);
    return options;
  }, [$commonStore.skillCategories]);

  useEffect(() => {
    getSkills(onLoad);
    getSkillCategories();
  }, []);

  return (
    <SkillsWrapper
      Category={<CategoryTable modelData={filteredData} />}
      SkillsBelow={<Skills modelData={filteredData} type="below" />}
      SkillsAbove={<Skills modelData={filteredData} type="above" />}
      SkillsAll={<Skills modelData={filteredData} />}
      rootSkills={rootSkills}
      setSelectedRootCategories={setSelectedRootCategories}
    />
  );
});
