export interface ClassCommon {
  reset: () => void;
}

export enum BannerType {
  success = "success",
  info = "info",
  warning = "warning",
  error = "error"
}

export interface Banner {
  text: string;
  type: BannerType;
}
