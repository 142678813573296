import { GlobalStore } from "@common/api/store/global";
import { Roles } from "@common/constants";
import { getPhrases } from "@common/helpers/localization";
import classNames from "classnames";
import { useContext } from "react";
import styles from "./legend.module.scss";

export const Legend = () => {
  const { $profileStore } = useContext(GlobalStore);
  const loc = getPhrases("forms", "assessment");
  const role = $profileStore.getCurrentRoleId();
  return (
    <div className={styles.wrapper}>
      <div className={styles.list}>
        <div className={styles.row}>
          <div className={classNames(styles.symbol, styles.your)} />
          <div className={styles.text}>{loc.header_legend_rating}</div>
        </div>
        <div className={styles.row}>
          <div className={classNames(styles.symbol, styles.target)}></div>
          <div className={styles.text}>{loc.header_legend_target}</div>
        </div>
        {role === Roles.manager && (
          <div className={styles.row}>
            <div className={classNames(styles.symbol, styles.self)}></div>
            <div className={styles.text}>{loc.header_legend_self}</div>
          </div>
        )}
      </div>
    </div>
  );
};
