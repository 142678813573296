import { Button } from "@ui";
import styles from "./question-info.module.scss";
import { rating } from "./mock";
import { usePopper } from "react-popper";
import { useMemo, useState } from "react";

interface Props {
  level: number;
}

type RefType = HTMLDivElement | null;

export const QuestionInfo = ({ level }: Props) => {
  const [referenceElement, setReferenceElement] = useState<RefType>();
  const [popperElement, setPopperElement] = useState<RefType>();

  const offsetModifier = useMemo(
    () => ({
      name: "offset",
      options: {
        // @ts-ignore
        offset: ({ placement, reference, popper }) => {
          return [-popper.width - 15, -reference.height / 2 - popper.height / 2];
        }
      }
    }),
    []
  );

  const { styles: stylesBlock, attributes: attributesBlock } = usePopper(referenceElement, popperElement, {
    placement: "top-start",
    modifiers: [offsetModifier]
  });
  const ratingBlock = rating[level - 1];
  return (
    <div className={styles.wrapper} ref={setReferenceElement}>
      <Button appearance="solid" size="thin" theme="blue" className={styles.block}>
        {ratingBlock.title ?? null} <span className={styles.icon}>?</span>
      </Button>
      <div className={styles.info} ref={setPopperElement} style={stylesBlock.popper} {...attributesBlock.popper}>
        <div className={styles["info-title"]}>{ratingBlock.title}</div>
        {ratingBlock.description}
      </div>
    </div>
  );
};
