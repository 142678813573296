import Slider from "rc-slider";
import { useEffect, useState } from "react";
import "./slider.scss";

export type RangeSliderValueType = number | number[];
interface Props {
  min: number;
  max: number;
  defaultValue?: number;
  value?: number;
  onChange?: (value: RangeSliderValueType) => void;
  className?: string;
}

export const RangeSlider = ({ value: currentValue, onChange: decorator, ...props }: Props) => {
  const [value, setValue] = useState<RangeSliderValueType | undefined>(currentValue);
  useEffect(() => {
    setValue(currentValue);
  }, [currentValue]);
  const onChange = (value: RangeSliderValueType) => {
    if (decorator) {
      decorator(value);
    }
    setValue(value);
  };
  return <Slider {...props} {...(value && { value })} onChange={onChange} />;
};
