import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { ShadowBlock, ShortModal, Titles } from "@ui";
import { getPhrases } from "@common/helpers/localization";
import { GlobalStore } from "@common/api/store/global";
import { ForgotModal, LoginForm } from "@features/user";
import { AuthNotify } from "@features/user/auth-notify";
import grid from "@styles/modules/grid.module.scss";
import styles from "./login-page.module.scss";
import { getDomainName } from "@common/api/services/public";

export const LoginPage = observer((): JSX.Element => {
  const [loc] = useState(getPhrases("login_page", "auth_form"));
  const [invalidAuthLoc] = useState(getPhrases("login_page", "failure"));
  const [title, setTitle] = useState(loc.title);
  const { $userStore } = useContext(GlobalStore);

  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    getDomainName();
    localStorage.removeItem("impersonationToken");
    localStorage.removeItem("impersonationUserInfo");
  }, []);

  useEffect(() => {
    setTitle(loc.title.replace("Innovorg", $userStore.domainName));
  }, [$userStore.domainName]);

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className={styles.wrapper}>
      <div className={grid.root}>
        <div className={grid.container}>
          <ShadowBlock modClass={styles.block}>
            <div className={styles.inner}>
              {$userStore.invalidAuth?.message ? (
                <AuthNotify
                  title={invalidAuthLoc.title}
                  submitText={invalidAuthLoc.actions.retry}
                  onSubmit={() => $userStore.setInvalidAuth(null)}
                >
                  <span dangerouslySetInnerHTML={{ __html: invalidAuthLoc.description }} />
                  <button className={styles["reset-button"]} type="button" onClick={() => setIsOpen(true)}>
                    {invalidAuthLoc.actions.reset}
                  </button>
                </AuthNotify>
              ) : (
                <>
                  <Titles level="level_1">{title}</Titles>
                  <LoginForm openResetPasswordModal={() => setIsOpen(true)} />
                </>
              )}
            </div>
          </ShadowBlock>
        </div>
      </div>
      <ShortModal isOpen={modalIsOpen} closeModal={closeModal}>
        <ForgotModal onClose={closeModal} />
      </ShortModal>
    </div>
  );
});
