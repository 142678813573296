import { CommonWithId } from "@common/types/common";
import { makeAutoObservable } from "mobx";
import { ClassCommon } from "../types/common";

export class EmploymentTypes implements ClassCommon {
  types: CommonWithId[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  getTypes() {
    return this.types;
  }

  setTypes(types: CommonWithId[]) {
    this.types = types;
  }

  reset(): void {
    this.types = [];
  }
}

export const $employmentTypesStore = new EmploymentTypes();
