import React, { FC, useEffect, useState } from "react";
import * as yup from "yup";
import classNames from "classnames";
import { useForm } from "@common/hooks/form";
import { IRoleForm } from "@common/types/forms/role";
import { Button, ButtonGroup, Checkbox, FormInputText, FormWrapper } from "@ui";
import { IRoleFormTabInitData } from "../index";
import { FormError } from "../form-error";

export interface IRoleAccountabilityTabProps {
  formData: Partial<IRoleForm>;
  onInit?: (data: IRoleFormTabInitData) => void;
  onBack(): void;
  onNext(data: Partial<IRoleForm>): void;
  onSubmit(data: Partial<IRoleForm>): Partial<IRoleForm>;
  onClose(): void;
}

interface IRoleAccountabilityForm {
  accountability?: {
    id: number;
    area: string;
    metrics: string[];
    weight: number;
  }[];
  // accountabilityStartDate?: Date;
  accountabilityInterval?: number;
  accountabilityIntervalBackup: number;
  accountabilityIntervalEnabled?: boolean;
  selfAccountabilityOn?: boolean;
  managerAccountabilityOn?: boolean;
}

const intervalOptions = [
  { label: "None", value: 0 },
  { label: "Monthly", value: 1 },
  { label: "3 months", value: 3 },
  { label: "6 months", value: 6 },
  { label: "1 year", value: 12 }
];

const accountabilityFormSchema: yup.SchemaOf<IRoleAccountabilityForm> = yup
  .object()
  .shape(
    {
      accountability: yup.array().when("accountabilityInterval", accountabilityInterval => {
        if (!accountabilityInterval) {
          return yup.array().notRequired();
        }

        return yup
          .array()
          .of(
            yup.object().shape({
              id: yup.number().required(),
              area: yup.string().required(),
              metrics: yup.array().of(yup.string().required()).min(1),
              weight: yup.number().required().min(0).max(100)
            })
          )
          .min(0);
      }),
      // accountabilityStartDate: yup.date(),
      accountabilityInterval: yup.number(),
      accountabilityIntervalBackup: yup.number(),
      accountabilityIntervalEnabled: yup.boolean(),
      selfAccountabilityOn: yup.boolean(),
      managerAccountabilityOn: yup.boolean()
    },
    [["accountabilityInterval", "accountabilityInterval"]]
  )
  .notRequired();

export const RoleAccountabilityTab: FC<IRoleAccountabilityTabProps> = ({
  formData,
  onInit,
  onBack,
  onNext,
  onSubmit,
  onClose
}) => {
  const methods = useForm<IRoleAccountabilityForm>({
    schema: accountabilityFormSchema,
    defaultValues: {
      accountability: formData.accountability?.length
        ? formData.accountability
        : [{ id: 1, area: "", metrics: [], weight: 100 }],
      // accountabilityStartDate: formData.accountabilityStartDate,
      accountabilityInterval: formData.accountabilityInterval,
      accountabilityIntervalBackup: formData.accountabilityIntervalBackup,
      accountabilityIntervalEnabled: (formData.accountabilityInterval || 0) > 0,
      selfAccountabilityOn: (formData.accountabilityInterval || 0) > 0,
      managerAccountabilityOn: (formData.accountabilityInterval || 0) > 0
    }
  });
  const data = methods.getValues();
  const [timestamp, setTimestamp] = useState<number>();
  const [showFormError, setShowFormError] = useState(false);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [metrics, setMetrics] = useState<string[][]>([]);

  const interval = intervalOptions.find(item => item.value === data.accountabilityInterval);
  const totalWeight = data.accountability?.reduce((sum, item) => sum + (Number(item.weight) || 0), 0);
  /*let nextRefreshDate = '';
  if (data.accountabilityStartDate && data.accountabilityInterval) {
    let date = moment(data.accountabilityStartDate);
    while(date.isSameOrBefore()) {
      date.add(data.accountabilityInterval, 'months');
    }
    nextRefreshDate = date.format('DD MMMM YYYY');
  }*/

  useEffect(() => {
    if (onInit) {
      if (showFormError) {
        onInit({ buttons: undefined });
      } else {
        onInit({
          buttons: {
            leftSide: {
              title: (
                <span>
                  Cancel
                  <br />
                  And Close
                </span>
              ),
              theme: "secondary-gradient",
              className: "!w-30 !h-14 !text-sm !text-left !font-semibold",
              onClick: onClose
            },
            rightSide: [
              {
                title: "Prev",
                theme: "warning",
                shape: "left-arrow",
                className: "!w-20 !text-sm !font-semibold",
                onClick() {
                  goBack();
                }
              },
              {
                title: "Save & Finish",
                theme: "warning",
                className: "!w-32 !text-sm !font-semibold",
                onClick() {
                  methods.handleSubmit(handleSuccess, handleError)();
                }
              }
            ]
          }
        });
      }
    }
  }, [onInit, showFormError]);

  const onFieldChange = (field: any, value: any) => {
    if (field === "accountabilityInterval") {
      methods.setValue("accountabilityIntervalEnabled", value > 0);
      methods.setValue("managerAccountabilityOn", value > 0);
      methods.setValue("selfAccountabilityOn", value > 0);
    }
    methods.setValue(field, value);
    methods.trigger(field);
    setTimestamp(new Date().getTime());
  };

  const onToggleExpand = (id: number) => {
    if (expandedRows.includes(id)) {
      setExpandedRows(expandedRows.filter(item => item !== id));
    } else {
      setExpandedRows([...expandedRows, id]);
      const item = data.accountability?.find(item => item.id === id);
      setMetrics({
        ...metrics,
        [id]: item?.metrics?.length ? item.metrics : [""]
      });
    }
  };

  const onAddAccountability = (i: number) => {
    const accountability = [...(data.accountability ? data.accountability : [])];
    const maxId = Math.max(0, ...accountability.map(item => item.id));
    accountability.splice(i + 1, 0, { id: maxId + 1, area: "", metrics: [], weight: 0 });
    const weight = Math.floor(100 / accountability.length);
    const remain = 100 - weight * accountability.length;
    accountability.forEach((item, index) => {
      if (index >= accountability.length - remain) {
        item.weight = weight + 1;
      } else {
        item.weight = weight;
      }
    });
    onFieldChange("accountability", accountability);
  };

  const onUpdateAccountability = (id: number, updates: any) => {
    const accountability = data.accountability?.map(item => (item.id === id ? { ...item, ...updates } : item));
    onFieldChange("accountability", accountability);
  };

  const onRemoveAccountability = (id: number) => {
    if (data.accountability) {
      const accountability = data.accountability.filter(item => item.id !== id);
      const weight = Math.floor(100 / accountability.length);
      const remain = 100 - weight * accountability.length;
      accountability.forEach((item, index) => {
        if (index >= accountability.length - remain) {
          item.weight = weight + 1;
        } else {
          item.weight = weight;
        }
      });
      if (!accountability.length) {
        accountability.push({ id: 1, area: "", metrics: [], weight: 100 });
      }
      onFieldChange("accountability", accountability);
      setMetrics({
        ...metrics,
        [id]: [""]
      });
    }
  };

  const onAddMetric = (id: number) => {
    setMetrics({
      ...metrics,
      [id]: [...metrics[id], ""]
    });
  };

  const onEditMetric = (id: number, i: number, value: string) => {
    const items = [...metrics[id]];
    items.splice(i, 1, value);
    setMetrics({
      ...metrics,
      [id]: items
    });
  };

  const onRemoveMetric = (id: number, i: number) => {
    const items = [...metrics[id]];
    items.splice(i, 1);
    setMetrics({
      ...metrics,
      [id]: items
    });
  };

  const onSaveMetrics = (id: number) => {
    onUpdateAccountability(id, {
      metrics: metrics[id]
    });
    onToggleExpand(id);
  };

  const handleSuccess = (values: IRoleAccountabilityForm) => {
    const totalWeight = values.accountability?.reduce((sum, item) => sum + (Number(item.weight) || 0), 0);
    if ((values.accountability?.length || 0) > 0 && totalWeight !== 100) {
      handleError();
      return;
    }
    const formData = onSubmit(values || {});
    onNext(formData);
  };

  const handleError = (e?: any) => {
    console.log(e);
    setShowFormError(true);
  };

  const goBack = () => {
    onSubmit(methods.getValues());
    onBack();
  };

  return (
    <FormWrapper className="text-black" onSubmit={methods.handleSubmit(handleSuccess, handleError)}>
      <div className="grid sm:grid-cols-2 gap-4 border-b border-gray-cd px-5 pb-4">
        <div>
          <h6 className="text-primary text-sm font-bold mb-1">
            <i className="fa fa-caret-down" /> This is where you work out accountabilities
          </h6>
          <p className="text-xs">
            Accountabilities are areas of responsibility within the employee’s remit: performance within these
            accountabilities is assessed by metrics, which we define. These accountabilities and associated metrics are
            then assigned a weight percentage.
          </p>
        </div>
      </div>

      {!!data.accountabilityInterval && (
        <div className="border-b border-gray-cd py-4">
          <table className="w-full text-sm">
            <thead>
              <tr className="text-xs text-blue text-left font-semibold">
                <th className="pl-5 pr-6 pb-1">Accountability area</th>
                <th className="pr-6 pb-1">Metric</th>
                <th className="pr-6 pb-1">Weight%</th>
                <th className="pr-5 pb-1">Add/remove</th>
              </tr>
            </thead>
            <tbody>
              {data.accountability?.map((item, i) => {
                const expanded = expandedRows.includes(item.id);
                const editingMetrics = metrics[item.id] || [];
                const metricsInvalid = editingMetrics.some(item => !item);
                return (
                  <React.Fragment key={item.id}>
                    <tr>
                      <td className="pb-2 pl-5 pr-6">
                        <FormInputText
                          className="!text-sm !text-black !placeholder-secondary placeholder-italic"
                          name={`accountability[${i}].area`}
                          placeholder="Type the area of the accountability"
                          showErrorText={false}
                          register={methods.register}
                          error={
                            methods.formState.errors?.accountability &&
                            methods.formState.errors?.accountability[i]?.area?.message
                          }
                        />
                      </td>
                      <td className="pb-2 pr-6">
                        <div className="flex items-center cursor-pointer" onClick={() => onToggleExpand(item.id)}>
                          <i
                            className={classNames(
                              "fa fa-caret-right text-gray transition-all mr-2",
                              expanded && "rotate-90"
                            )}
                          />
                          {item.metrics.length ? `${item.metrics.length}x` : "No"} Metrics (click to view)
                        </div>
                      </td>
                      <td className="w-30 pb-2 pr-6">
                        <FormInputText
                          className="!text-sm !text-black !placeholder-secondary placeholder-italic"
                          name={`accountability[${i}].weight`}
                          type="number"
                          showErrorText={false}
                          register={methods.register}
                          error={
                            methods.formState.errors?.accountability &&
                            methods.formState.errors?.accountability[i]?.weight?.message
                          }
                          onChange={e => onFieldChange(`accountability[${i}].weight`, e.target.value)}
                        />
                      </td>
                      <td className="w-20 text-md pb-2 pr-5">
                        <i
                          className="fa fa-times text-primary cursor-pointer"
                          onClick={() => onRemoveAccountability(item.id)}
                        />
                        {i === (data.accountability?.length ?? 0) - 1 && (
                          <i
                            className="fa fa-plus text-warning cursor-pointer ml-2"
                            onClick={() => onAddAccountability(i)}
                          />
                        )}
                      </td>
                    </tr>
                    {expanded && (
                      <tr>
                        <td />
                        <td className="pb-2 pr-5" colSpan={3}>
                          <div className="flex items-center">
                            <div className="flex-grow mr-8">
                              {editingMetrics.map((metric, j) => (
                                <div key={j} className="flex items-center mb-1">
                                  <FormInputText
                                    className="!text-sm !text-black !placeholder-secondary placeholder-italic"
                                    name={`metric-${item.id}-${j}`}
                                    placeholder="Response time SLA..."
                                    value={metric}
                                    onChange={e => onEditMetric(item.id, j, e.target.value)}
                                  />
                                  <i
                                    className="fa fa-times text-primary text-md cursor-pointer ml-2"
                                    onClick={() => onRemoveMetric(item.id, j)}
                                  />
                                  <i
                                    className={classNames(
                                      "fa fa-plus text-warning text-md cursor-pointer ml-2",
                                      j < editingMetrics.length - 1 && "opacity-0 pointer-events-none"
                                    )}
                                    onClick={() => onAddMetric(item.id)}
                                  />
                                </div>
                              ))}
                            </div>
                            <Button
                              className="!w-32 !text-xs !font-semibold"
                              theme="blue"
                              title="Save Metrics"
                              disabled={metricsInvalid}
                              onClick={() => onSaveMetrics(item.id)}
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
            <tfoot>
              <tr className="border-t border-gray-cd">
                <td className="text-primary text-right italic pl-5 pr-4 pt-4" colSpan={2}>
                  The total weight of all accountabilities must equal 100%
                </td>
                <td className="pt-4 pr-6">
                  <div
                    className={classNames(
                      "italic font-semibold border rounded px-2 py-1",
                      totalWeight === 100 ? "text-success border-success" : "text-danger border-danger"
                    )}
                  >
                    {totalWeight}%
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      )}

      <div className="flex items-center flex-wrap px-5 pt-4">
        {/*<div className="mr-8">
          <div className="text-xs text-gray-97 font-semibold mb-1">Calendar year start month</div>
          <ReactDatePicker
            className={classNames(
              '!text-sm !text-black !placeholder-secondary placeholder-italic',
              methods.formState.errors?.accountabilityStartDate?.message && '!border-danger'
            )}
            popperPlacement="top-start"
            selected={data.accountabilityStartDate}
            onChange={(value) => onFieldChange('accountabilityStartDate', value)}
          />
        </div>*/}
        {totalWeight === 100 && (
          <div className="min-h-70 grid xs:grid-cols-1 md:!grid-cols-7 gap-x-6 gap-y-6 px-5 pt-4">
            <div className="col-span-3">
              <div className="text-xs text-gray-97 font-semibold mb-1">Accountability assessment Interval</div>
              <ButtonGroup
                buttons={intervalOptions}
                buttonClass="italic"
                value={data.accountabilityInterval}
                onChange={value => onFieldChange("accountabilityInterval", value)}
              />
              <p className="text-xs text-secondary italic mt-3">
                This sets the interval for all accountability assessment for this role
              </p>
            </div>
            <div className="col-span-2">
              <div className="text-xs text-gray-97 font-semibold">Self Accountability Interval</div>
              <div className="flex items-center">
                <span
                  className={classNames(
                    "flex-shrink-0 text-xs text-black leading-none bg-gray-cd rounded p-2 mt-1 mr-2",
                    !data.selfAccountabilityOn && "bg-opacity-30 text-opacity-20"
                  )}
                >
                  {interval?.label || "-"}
                </span>
                <Checkbox
                  className="italic mt-1"
                  readonly
                  size="sm"
                  theme="success"
                  label={data.selfAccountabilityOn ? "Self assessment ON" : "Self assessment OFF"}
                  value={data.selfAccountabilityOn}
                  disabled={data.accountabilityIntervalEnabled}
                />
              </div>
            </div>
            <div className="col-span-2">
              <div className="text-xs text-gray-97 font-semibold">Manager Accountability Interval</div>
              <div className="flex items-center">
                <span
                  className={classNames(
                    "flex-shrink-0 text-xs text-black leading-none bg-gray-cd rounded p-2 mt-1 mr-2",
                    !data.managerAccountabilityOn && "bg-opacity-30 text-opacity-20"
                  )}
                >
                  {interval?.label || "-"}
                </span>
                <Checkbox
                  className="italic mt-1"
                  readonly
                  size="sm"
                  theme="success"
                  label={data.managerAccountabilityOn ? "Manager assessment ON" : "Manager assessment OFF"}
                  value={data.managerAccountabilityOn}
                  disabled={data.accountabilityIntervalEnabled}
                />
              </div>
            </div>
          </div>
        )}
        {/*{Boolean(nextRefreshDate) && (
          <div className="text-xs ml-auto mt-4">
            The next accountabilities assessment for this role is set to {nextRefreshDate}
          </div>
        )}*/}
      </div>

      {showFormError && (
        <FormError className="w-full h-full absolute top-0 left-0 z-100" onClose={() => setShowFormError(false)} />
      )}
    </FormWrapper>
  );
};
