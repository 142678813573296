import { useSearchParams, useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import { AxiosError } from "axios";
import grid from "@styles/modules/grid.module.scss";
import { AlertModal, ShadowBlock, Titles } from "@ui";
import { ResetPasswordForm } from "@features/user";
import { DataWrapper } from "@common/api/types/response/common";
import { SendResetPasswordLink } from "@common/api/types/response/send-reset-password-link";
import styles from "./reset-password-page.module.scss";

export const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const isInitial = searchParams.get("initial") === "1";
  const token = searchParams.get("token") ?? "";

  const navigate = useNavigate();
  const [isSuccess, setModalType] = useState<boolean>();

  const [sendModalIsOpen, setSendModalIsOpen] = useState<boolean>(false);

  const onCloseModal = useCallback(() => {
    setSendModalIsOpen(false);
    if (isSuccess) {
      navigate("/login/");
    }
  }, [isSuccess]);

  const onFail = useCallback((e?: AxiosError<DataWrapper<SendResetPasswordLink>>) => {
    if (e) {
      if (e.response?.data?.data.status === "token_expired") {
        setModalType(false);
        setSendModalIsOpen(true);
      } else {
        navigate("/login/");
      }
    }
  }, []);

  const onSuccess = useCallback(() => {
    setModalType(true);
    setSendModalIsOpen(true);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={grid.root}>
        <div className={grid.container}>
          <ShadowBlock modClass={styles.block}>
            <div className={styles.inner}>
              <Titles level="level_1">{isInitial ? "Please set up your account" : "Reset Password"}</Titles>
              <ResetPasswordForm
                token={token}
                isInitial={isInitial}
                failCallback={onFail}
                successCallback={onSuccess}
              />
              <AlertModal
                closeModal={onCloseModal}
                isOpen={sendModalIsOpen}
                title={isSuccess ? "Success!" : "Your security link is expired"}
                cancelButtonText={isSuccess ? "Close" : "OK"}
                type={isSuccess ? "success" : "fail"}
                message={
                  isSuccess ? (
                    "You have successfully set a new password. After closing the window you will be redirected to the authorization page"
                  ) : (
                    <>
                      Please check your email box (or spam folder). <br />
                      We will send you a new link via email shortly to allow you set your password.
                    </>
                  )
                }
              />
            </div>
          </ShadowBlock>
        </div>
      </div>
    </div>
  );
};
