import {
  getOffices as getOfficesFunction,
  getOfficeDetail as getOfficeDetailFunction,
  saveOfficeDetail as saveOfficeDetailFunction,
  removeOffice as removeOfficeFunction
} from "@common/api/services/offices";
import { $destinationDataStore } from "@common/api/store/destination";
import {
  AreaFormInterface,
  CountryProps,
  OfficeDetailFormInterface,
  OfficeDetailInterface,
  RegionProps
} from "@common/types/reports/offices/office-detail";
import { AreaOfficesDataProps } from "@common/types/reports/offices/offices";
import { CommonWithIntegerId } from "@common/types/common";

export const getReportOffices = async (callback?: any) => {
  try {
    const response = await getOfficesFunction<AreaOfficesDataProps>("web/settings/areas");
    $destinationDataStore.setAreas(response.data.data);
    callback && callback(response.data.data);
  } catch (e) {
    console.error("Get offices error");
  }
};

export const getOfficeDetail = async (id: number, callback?: any) => {
  try {
    const response = await getOfficeDetailFunction<OfficeDetailInterface>(`/client/organization/offices/${id}`);
    callback && callback(response.data.data);
    return response.data.data;
  } catch (e) {
    console.error("Get detail office error");
    throw e;
  }
};

export const removeOffice = async (id: number, callback?: any) => {
  try {
    const response = await removeOfficeFunction<OfficeDetailInterface>(`/client/organization/offices/${id}`);
    callback && callback(response.data.data);
    return response.data.data;
  } catch (e) {
    console.error("Remove office error");
    throw e;
  }
};

export const removeArea = async (id: number, callback?: any) => {
  try {
    const response = await removeOfficeFunction<OfficeDetailInterface>(`/client/organization/areas/${id}`);
    callback && callback(response.data.data);
    return response.data.data;
  } catch (e) {
    console.error("Remove area error");
    throw e;
  }
};

export const getAreaDetail = async (id: number, callback?: any) => {
  try {
    const response = await getOfficeDetailFunction<CommonWithIntegerId>(`/client/organization/areas/${id}`);
    callback && callback(response.data.data);
    return response.data.data;
  } catch (e) {
    console.error("Get detail office error");
  }
};

export const saveOfficeDetail = async (data: OfficeDetailFormInterface, id?: number, callback?: any) => {
  try {
    const response = await saveOfficeDetailFunction<OfficeDetailFormInterface>(
      `/client/organization/offices${id ? "/" + id : ""}`,
      data
    );
    callback && callback(response.data.data);
    return response.data.data;
  } catch (e) {
    console.error("Save detail office error");
    throw e;
  }
};

export const saveArea = async (data: AreaFormInterface, id?: number, callback?: any) => {
  try {
    const response = await saveOfficeDetailFunction<AreaFormInterface>(
      `/client/organization/areas${id ? "/" + id : ""}`,
      data
    );
    callback && callback(response.data.data);
    return response.data.data;
  } catch (e) {
    console.error("Save detail area error");
    throw e;
  }
};

export const getOffices = async (callback: any) => {
  try {
    const response = await getOfficesFunction<AreaOfficesDataProps>("/client/organization/offices");
    $destinationDataStore.setOffices(response.data.data);
    callback(response.data.data);
  } catch (e) {
    console.error("Get offices error");
  }
};

export const getCountries = async (callback?: any) => {
  try {
    const response = await getOfficesFunction<CountryProps>("/client/location/countries");
    $destinationDataStore.setCountries(response.data.data);
    callback && callback(response.data.data);
  } catch (e) {
    console.error("Get countries error");
  }
};

export const getCountryRegions = async (id: number, callback?: any) => {
  try {
    const response = await getOfficesFunction<RegionProps>(`/client/location/countries/${id}/regions`);
    $destinationDataStore.setCountryRegions(id, response.data.data);
    callback && callback(response.data.data);
  } catch (e) {
    console.error("Get countries error");
  }
};
