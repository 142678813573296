import { GlobalStore } from "@common/api/store/global";
import { Roles } from "@common/constants";
import { getPhrases } from "@common/helpers/localization";
import { AddSkillCategoryModal } from "@features/skills/add-skill-category-modal";
import { AddSkillModal } from "@features/skills/add-skill-modal";
import grid from "@styles/modules/grid.module.scss";
import { ButtonProps, ControlLine, ControlLineProps, SelectOptionsProp, SelectProps } from "@ui";
import { observer } from "mobx-react-lite";
import { useContext, useMemo, useState } from "react";
import { ExtendedSelectProps } from "src/ui/control-line";

interface Props {
  Category: JSX.Element;
  SkillsBelow: JSX.Element;
  SkillsAbove: JSX.Element;
  SkillsAll: JSX.Element;
  rootSkills?: SelectOptionsProp[];
  setSelectedRootCategories?: (newValue: SelectOptionsProp[]) => void;
}

enum skillCategories {
  "category" = 1,
  "targetsBelow" = 2,
  "targetsAbove" = 3,
  "allSkills" = 4
}

const loc = getPhrases("skills_page", "filter");

export const SkillsWrapper = observer(
  ({ Category, SkillsBelow, SkillsAbove, SkillsAll, rootSkills, setSelectedRootCategories }: Props) => {
    const { $profileStore, $userStore } = useContext(GlobalStore);
    const options: SelectOptionsProp[] = [
      { value: skillCategories.category, label: loc.filter_category },
      { value: skillCategories.targetsBelow, label: loc.filter_below_targets },
      { value: skillCategories.targetsAbove, label: loc.filter_above_targets },
      { value: skillCategories.allSkills, label: loc.filter_all }
    ];
    const [selectedOption, setSelectedOption] = useState<SelectOptionsProp>(options[0]);
    const [showCreateSkillModal, setShowCreateSkillModal] = useState(false);
    const [showCreateSkillCategoryModal, setShowCreateSkillCategoryModal] = useState(false);
    const isAdmin = $profileStore.currentRoleId === Roles.clientAdmin;

    const getTableComponent = (): React.ReactNode => {
      switch (selectedOption?.value) {
        case skillCategories.category:
          return Category;
        case skillCategories.targetsBelow:
          return SkillsBelow;
        case skillCategories.targetsAbove:
          return SkillsAbove;
        case skillCategories.allSkills:
          return SkillsAll;
      }
    };

    // TODO: add correct type for filterProps

    const filterProps: SelectProps = {
      name: "skills-category",
      options: options,
      defaultValue: selectedOption,
      onChange: (newValue: any) => {
        setSelectedOption(newValue);
      }
    };

    const buttonsProps: ButtonProps[] = useMemo(
      () =>
        isAdmin
          ? [
              {
                theme: "orange",
                title: "Add Skill",
                onClick: () => setShowCreateSkillModal(true)
              },
              {
                theme: "orange",
                title: "Add Skill Category",
                className: "!w-auto",
                onClick: () => setShowCreateSkillCategoryModal(true)
              }
            ]
          : [],
      [isAdmin]
    );

    const extraFilters: ExtendedSelectProps[] = [
      {
        label: "Filter by",
        name: "root-categories",
        placeholder: "Top Skill Categories",
        options: rootSkills || [],
        isMulti: true,
        onChange: (newValue: any) => {
          setSelectedRootCategories?.(newValue);
        }
      }
    ];

    const lineProps: ControlLineProps = {
      filterProps,
      ...($userStore.getUserRoleId() === Roles.clientAdmin ? { buttonsProps } : {})
    };
    return (
      <>
        <div className={grid.root}>
          <div className={grid.container}>
            <ControlLine {...lineProps} extraFilters={extraFilters} />
            {getTableComponent()}
          </div>
        </div>

        {showCreateSkillModal && <AddSkillModal isOpen closeModal={() => setShowCreateSkillModal(false)} />}
        {showCreateSkillCategoryModal && (
          <AddSkillCategoryModal isOpen closeModal={() => setShowCreateSkillCategoryModal(false)} />
        )}
      </>
    );
  }
);
