import {
  EmployeeAnalytics,
  OfficeReport,
  RoleReport,
  Analytics,
  SupervisorAnalytics,
  TeamAnalytics
} from "@common/types/reports/analytics/analytics";

export const SkillOverviewData: Analytics[] = [
  {
    id: 0,
    skill_category: "Databases",
    skills_count: 13,
    target: 116,
    avg_expertise_level: 116,
    avg_skills_target: 90,
    high_training_needs: 4,
    medium_training_needs: 13,
    low_training_needs: 54
  },
  {
    id: 1,
    skill_category: "cPanel / WHM Control Panel",
    skills_count: 8,
    target: 95,
    avg_expertise_level: 116,
    avg_skills_target: 90,
    high_training_needs: 3,
    medium_training_needs: 10,
    low_training_needs: 14
  },
  {
    id: 2,
    skill_category: "Plesk Control Panel",
    skills_count: 8,
    target: 117,
    avg_expertise_level: 116,
    avg_skills_target: 90,
    high_training_needs: 4,
    medium_training_needs: 10,
    low_training_needs: 29
  },
  {
    id: 3,
    skill_category: "DirectAdmin control panel",
    skills_count: 8,
    target: 179,
    avg_expertise_level: 116,
    avg_skills_target: 90,
    high_training_needs: 3,
    medium_training_needs: 2,
    low_training_needs: 21
  },
  {
    id: 4,
    skill_category: "Linux-based Operating Systems",
    skills_count: 6,
    target: 95,
    avg_expertise_level: 116,
    avg_skills_target: 90,
    high_training_needs: 3,
    medium_training_needs: 7,
    low_training_needs: 12
  },
  {
    id: 5,
    skill_category: "Cyber Security",
    skills_count: 6,
    target: 114,
    avg_expertise_level: 116,
    avg_skills_target: 90,
    high_training_needs: 1,
    medium_training_needs: 8,
    low_training_needs: 29
  },
  {
    id: 6,
    skill_category: "Networking Fundamentals",
    skills_count: 5,
    target: 97,
    avg_expertise_level: 116,
    avg_skills_target: 90,
    high_training_needs: 1,
    medium_training_needs: 7,
    low_training_needs: 11
  },
  {
    id: 7,
    skill_category: "Problem-solving",
    skills_count: 5,
    target: 84,
    avg_expertise_level: 116,
    avg_skills_target: 90,
    high_training_needs: 7,
    medium_training_needs: 11,
    low_training_needs: 12
  },
  {
    id: 8,
    skill_category: "Personal time management",
    skills_count: 4,
    target: 145,
    avg_expertise_level: 116,
    avg_skills_target: 90,
    high_training_needs: 0,
    medium_training_needs: 4,
    low_training_needs: 25
  },
  {
    id: 9,
    skill_category: "Stress management",
    skills_count: 2,
    target: 132,
    avg_expertise_level: 116,
    avg_skills_target: 90,
    high_training_needs: 2,
    medium_training_needs: 9,
    low_training_needs: 34
  },
  {
    id: 10,
    skill_category: "Conflict or dispute resolution",
    skills_count: 2,
    target: 107,
    avg_expertise_level: 116,
    avg_skills_target: 90,
    high_training_needs: 6,
    medium_training_needs: 13,
    low_training_needs: 37
  }
];

export const OfficeOverviewData: OfficeReport[] = [
  {
    id: 0,
    area: "Remote Office - Portugal",
    employees_count: 12,
    industry_exp: 12,
    company_tenure: 6,
    high_expertise: 4,
    high_percentage: 33,
    medium_expertise: 4,
    medium_percentage: 33,
    limited_expertise: 4,
    limited_percentage: 33
  },
  {
    id: 1,
    area: "Remote Office - USA",
    employees_count: 20,
    industry_exp: 12,
    company_tenure: 9,
    high_expertise: 5,
    high_percentage: 25,
    medium_expertise: 10,
    medium_percentage: 50,
    limited_expertise: 5,
    limited_percentage: 25
  },
  {
    id: 2,
    area: "Remote Office - Brazil",
    employees_count: 24,
    industry_exp: 13,
    company_tenure: 7,
    high_expertise: 4,
    high_percentage: 8,
    medium_expertise: 10,
    medium_percentage: 46,
    limited_expertise: 10,
    limited_percentage: 46
  },
  {
    id: 3,
    area: "Remote Office – Egypt",
    employees_count: 3,
    industry_exp: 5,
    company_tenure: 2,
    high_expertise: 3,
    high_percentage: 100,
    medium_expertise: 0,
    medium_percentage: 0,
    limited_expertise: 0,
    limited_percentage: 0
  },
  {
    id: 4,
    area: "Remote Office – Canada",
    employees_count: 9,
    industry_exp: 7,
    company_tenure: 5,
    high_expertise: 6,
    high_percentage: 70,
    medium_expertise: 2,
    medium_percentage: 20,
    limited_expertise: 1,
    limited_percentage: 10
  },
  {
    id: 5,
    area: "Remote Office – Italy",
    employees_count: 7,
    industry_exp: 21,
    company_tenure: 8,
    high_expertise: 2,
    high_percentage: 15,
    medium_expertise: 3,
    medium_percentage: 35,
    limited_expertise: 4,
    limited_percentage: 50
  }
];

export const RoleOverviewData: RoleReport[] = [
  {
    id: 0,
    title: "Sr. Support Engineer, Linux OS",
    employees_count: 15,
    industry_exp: 9.5,
    company_tenure: 2.8,
    high_expertise: 9,
    high_percentage: 60,
    medium_expertise: 5,
    medium_percentage: 33,
    limited_expertise: 1,
    limited_percentage: 7
  },
  {
    id: 1,
    title: "Lead Sr. Support Engineer, Linux OS",
    employees_count: 10,
    industry_exp: 9.2,
    company_tenure: 1.9,
    high_expertise: 8,
    high_percentage: 80,
    medium_expertise: 1,
    medium_percentage: 10,
    limited_expertise: 1,
    limited_percentage: 10
  },
  {
    id: 2,
    title: "Sr. Support Engineer, Security",
    employees_count: 7,
    industry_exp: 8.3,
    company_tenure: 2.3,
    high_expertise: 6,
    high_percentage: 86,
    medium_expertise: 0,
    medium_percentage: 0,
    limited_expertise: 1,
    limited_percentage: 14
  },
  {
    id: 3,
    title: "Team Lead, Security",
    employees_count: 5,
    industry_exp: 14.6,
    company_tenure: 2.1,
    high_expertise: 1,
    high_percentage: 20,
    medium_expertise: 1,
    medium_percentage: 20,
    limited_expertise: 3,
    limited_percentage: 60
  },
  {
    id: 4,
    title: "Manager, Cloud",
    employees_count: 4,
    industry_exp: 11.5,
    company_tenure: 4.2,
    high_expertise: 1,
    high_percentage: 25,
    medium_expertise: 2,
    medium_percentage: 50,
    limited_expertise: 1,
    limited_percentage: 25
  },
  {
    id: 5,
    title: "Support Engineer, Cloud",
    employees_count: 4,
    industry_exp: 23.0,
    company_tenure: 2.8,
    high_expertise: 3,
    high_percentage: 75,
    medium_expertise: 1,
    medium_percentage: 25,
    limited_expertise: 0,
    limited_percentage: 0
  },
  {
    id: 6,
    title: "Sr. Support Engineer, Cloud",
    employees_count: 2,
    industry_exp: 18.0,
    company_tenure: 5.4,
    high_expertise: 2,
    high_percentage: 100,
    medium_expertise: 0,
    medium_percentage: 0,
    limited_expertise: 0,
    limited_percentage: 0
  },
  {
    id: 7,
    title: "Knowledge Management Lead",
    employees_count: 2,
    industry_exp: 9.0,
    company_tenure: 5.0,
    high_expertise: 2,
    high_percentage: 100,
    medium_expertise: 0,
    medium_percentage: 0,
    limited_expertise: 0,
    limited_percentage: 0
  },
  {
    id: 8,
    title: "Training and QA Lead",
    employees_count: 1,
    industry_exp: 30.0,
    company_tenure: 3.9,
    high_expertise: 1,
    high_percentage: 100,
    medium_expertise: 0,
    medium_percentage: 0,
    limited_expertise: 0,
    limited_percentage: 0
  },
  {
    id: 9,
    title: "Lead Sr. Security",
    employees_count: 1,
    industry_exp: 20.0,
    company_tenure: 0.8,
    high_expertise: 0,
    high_percentage: 0,
    medium_expertise: 0,
    medium_percentage: 0,
    limited_expertise: 1,
    limited_percentage: 100
  }
];

export const EmployeeOverviewData: EmployeeAnalytics[] = [
  {
    id: 0,
    full_name: "Charmaine Winnifred",
    industry_experience: 14.0,
    company_tenure: 2.2,
    area_name: "Mexico",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Heath Zula",
    expertise: "High",
    skill_percentage: 100,
    training_need: "Low"
  },
  {
    id: 1,
    full_name: "Woodrow Stella",
    industry_experience: 14.0,
    company_tenure: 9.1,
    area_name: "East Canada",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Heath Zula",
    expertise: "High",
    skill_percentage: 100,
    training_need: "Low"
  },
  {
    id: 2,
    full_name: "Merle Melody",
    industry_experience: 11.0,
    company_tenure: 2.0,
    area_name: "Mexico",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Heath Zula",
    expertise: "High",
    skill_percentage: 100,
    training_need: "Low"
  },
  {
    id: 3,
    full_name: "Maya Corrina",
    industry_experience: 7.0,
    company_tenure: 1.6,
    area_name: "Mexico",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Heath Zula",
    expertise: "High",
    skill_percentage: 100,
    training_need: "Low"
  },
  {
    id: 4,
    full_name: "Melvyn Jennifer",
    industry_experience: 6.0,
    company_tenure: 0.9,
    area_name: "Mexico",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Heath Zula",
    expertise: "High",
    skill_percentage: 100,
    training_need: "Low"
  },
  {
    id: 5,
    full_name: "Kermit Jeffrey",
    industry_experience: 6.0,
    company_tenure: 0.9,
    area_name: "Mexico",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Heath Zula",
    expertise: "High",
    skill_percentage: 100,
    training_need: "Low"
  },
  {
    id: 6,
    full_name: "Wayna Oneida",
    industry_experience: 30.0,
    company_tenure: 3.7,
    area_name: "North-East USA",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Heath Zula",
    expertise: "High",
    skill_percentage: 100,
    training_need: "Low"
  },
  {
    id: 7,
    full_name: "Tonatiuh Arleen",
    industry_experience: 7.0,
    company_tenure: 0.7,
    area_name: "Mexico",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Heath Zula",
    expertise: "High",
    skill_percentage: 100,
    training_need: "Low"
  },
  {
    id: 8,
    full_name: "Kaneonuskatew Hester",
    industry_experience: 4.0,
    company_tenure: 1.1,
    area_name: "Mexico",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Heath Zula",
    expertise: "High",
    skill_percentage: 100,
    training_need: "Low"
  },
  {
    id: 9,
    full_name: "Mekaisto Sophie",
    industry_experience: 8.0,
    company_tenure: 1.5,
    area_name: "India",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Heath Zula",
    expertise: "High",
    skill_percentage: 100,
    training_need: "Low"
  },
  {
    id: 10,
    full_name: "Sybil Nyree",
    industry_experience: 30.0,
    company_tenure: 0.5,
    area_name: "Mexico",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Heath Zula",
    expertise: "High",
    skill_percentage: 98,
    training_need: "Low"
  },
  {
    id: 11,
    full_name: "Milo Aleta",
    industry_experience: 17.0,
    company_tenure: 5.1,
    area_name: "Mexico",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Heath Zula",
    expertise: "High",
    skill_percentage: 98,
    training_need: "Low"
  },
  {
    id: 12,
    full_name: "Atahualpa Bevan",
    industry_experience: 5.0,
    company_tenure: 10.5,
    area_name: "North-East USA",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Heath Zula",
    expertise: "High",
    skill_percentage: 96,
    training_need: "Low"
  },
  {
    id: 13,
    full_name: "Pheobe Roberta",
    industry_experience: 6.0,
    company_tenure: 1.5,
    area_name: "Mexico",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Heath Zula",
    expertise: "High",
    skill_percentage: 96,
    training_need: "Low"
  },
  {
    id: 14,
    full_name: "Vance Carmen",
    industry_experience: 21.0,
    company_tenure: 2.1,
    area_name: "North-East USA",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Heath Zula",
    expertise: "High",
    skill_percentage: 96,
    training_need: "Low"
  },
  {
    id: 15,
    full_name: "Coral Leroy",
    industry_experience: 30.0,
    company_tenure: 5.6,
    area_name: "Mexico",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Heath Zula",
    expertise: "High",
    skill_percentage: 94,
    training_need: "Low"
  },
  {
    id: 16,
    full_name: "Brendon Roseann",
    industry_experience: 19.0,
    company_tenure: 0.6,
    area_name: "India",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Jocelyn Finlay",
    expertise: "High",
    skill_percentage: 92,
    training_need: "Low"
  },
  {
    id: 17,
    full_name: "Bruce Placide",
    industry_experience: 13.0,
    company_tenure: 6.1,
    area_name: "Mexico",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Jocelyn Finlay",
    expertise: "High",
    skill_percentage: 92,
    training_need: "Low"
  },
  {
    id: 18,
    full_name: "Victor Palmer",
    industry_experience: 15.0,
    company_tenure: 2.3,
    area_name: "India",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Jocelyn Finlay",
    expertise: "High",
    skill_percentage: 92,
    training_need: "Low"
  },
  {
    id: 19,
    full_name: "Hayk Yves",
    industry_experience: 11.0,
    company_tenure: 4.2,
    area_name: "Mexico",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Jocelyn Finlay",
    expertise: "High",
    skill_percentage: 92,
    training_need: "Low"
  },
  {
    id: 20,
    full_name: "Carlyle Joyce",
    industry_experience: 2.0,
    company_tenure: 2.9,
    area_name: "Mexico",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Jocelyn Finlay",
    expertise: "High",
    skill_percentage: 92,
    training_need: "Low"
  },
  {
    id: 21,
    full_name: "Jerold Bruce",
    industry_experience: 11.0,
    company_tenure: 2.5,
    area_name: "Mexico",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Jocelyn Finlay",
    expertise: "High",
    skill_percentage: 92,
    training_need: "Low"
  },
  {
    id: 22,
    full_name: "Brandt Garey",
    industry_experience: 22.0,
    company_tenure: 3.0,
    area_name: "Mexico",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Jocelyn Finlay",
    expertise: "High",
    skill_percentage: 92,
    training_need: "Low"
  },
  {
    id: 23,
    full_name: "Georgette Talia",
    industry_experience: 10.0,
    company_tenure: 2.7,
    area_name: "Mexico",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Jocelyn Finlay",
    expertise: "High",
    skill_percentage: 88,
    training_need: "Low"
  },
  {
    id: 24,
    full_name: "Cowessess Mack",
    industry_experience: 9.0,
    company_tenure: 4.2,
    area_name: "India",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Randolph Shelly",
    expertise: "High",
    skill_percentage: 86,
    training_need: "Low"
  },
  {
    id: 25,
    full_name: "Felix Ulysses",
    industry_experience: 23.0,
    company_tenure: 4.6,
    area_name: "Mexico",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Randolph Shelly",
    expertise: "High",
    skill_percentage: 84,
    training_need: "Low"
  },
  {
    id: 26,
    full_name: "Sampson Samantha",
    industry_experience: 10.0,
    company_tenure: 0.3,
    area_name: "India",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Randolph Shelly",
    expertise: "High",
    skill_percentage: 83,
    training_need: "Low"
  },
  {
    id: 27,
    full_name: "Deborah Muriel",
    industry_experience: 6.0,
    company_tenure: 0.3,
    area_name: "India",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Randolph Shelly",
    expertise: "High",
    skill_percentage: 83,
    training_need: "Low"
  },
  {
    id: 28,
    full_name: "Suresh Yadev",
    industry_experience: 10.0,
    company_tenure: 0.3,
    area_name: "India",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Randolph Shelly",
    expertise: "High",
    skill_percentage: 83,
    training_need: "Low"
  },
  {
    id: 29,
    full_name: "Emmitt Ruth",
    industry_experience: 10.0,
    company_tenure: 2.5,
    area_name: "India",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "Randolph Shelly",
    expertise: "High",
    skill_percentage: 83,
    training_need: "Low"
  },
  {
    id: 30,
    full_name: "Malik Celeste",
    industry_experience: 15.0,
    company_tenure: 0.1,
    area_name: "India",
    role_name: "Microsoft",
    office_name: "New York",
    manager_full_name: "David Morris",
    expertise: "Medium",
    skill_percentage: 81,
    training_need: "Medium"
  }
];

export const TeamRosterData: EmployeeAnalytics[] = [
  {
    id: 0,
    full_name: "Brendon Roseann",
    title: "Sr. Support Engineer, Linux OS",
    industry_experience: 30,
    company_tenure: 0.5,
    area_name: "Mexico",
    expertise: "High",
    skill_percentage: 100,
    training_need: "Low"
  },
  {
    id: 1,
    full_name: "Bruce Placide",
    title: "Sr. Support Engineer, Linux OS",
    industry_experience: 30,
    company_tenure: 0.5,
    area_name: "Mexico",
    expertise: "High",
    skill_percentage: 100,
    training_need: "Low"
  },
  {
    id: 2,
    full_name: "Victor Palmer",
    title: "Sr. Support Engineer, Linux OS",
    industry_experience: 30,
    company_tenure: 0.5,
    area_name: "Mexico",
    expertise: "High",
    skill_percentage: 100,
    training_need: "Low"
  },
  {
    id: 3,
    full_name: "Hayk Yves",
    title: "Lead Sr. Support Engineer, Linux OS",
    industry_experience: 30,
    company_tenure: 0.5,
    area_name: "Mexico",
    expertise: "High",
    skill_percentage: 100,
    training_need: "Low"
  },
  {
    id: 4,
    full_name: "Jocelyn Finlay",
    title: "Sr. Support Engineer, Linux OS",
    industry_experience: 30,
    company_tenure: 0.5,
    area_name: "Mexico",
    expertise: "High",
    skill_percentage: 100,
    training_need: "Low"
  },
  {
    id: 5,
    full_name: "Carlyle Joyce",
    title: "Sr. Support Engineer, Linux OS",
    industry_experience: 30,
    company_tenure: 0.5,
    area_name: "Mexico",
    expertise: "High",
    skill_percentage: 100,
    training_need: "Low"
  },
  {
    id: 6,
    full_name: "Jerold Bruce",
    title: "Sr. Support Engineer, Linux OS",
    industry_experience: 30,
    company_tenure: 0.5,
    area_name: "Mexico",
    expertise: "High",
    skill_percentage: 100,
    training_need: "Low"
  },
  {
    id: 7,
    full_name: "Brandt Garey",
    title: "Sr. Support Engineer, Linux OS",
    industry_experience: 30,
    company_tenure: 0.5,
    area_name: "Mexico",
    expertise: "High",
    skill_percentage: 100,
    training_need: "Low"
  },
  {
    id: 8,
    full_name: "Georgette Talia",
    title: "Lead Sr. Support Engineer, Linux OS",
    industry_experience: 30,
    company_tenure: 0.5,
    area_name: "Mexico",
    expertise: "High",
    skill_percentage: 100,
    training_need: "Low"
  },
  {
    id: 9,
    full_name: "Cowessess Mack",
    title: "Sr. Support Engineer, Security",
    industry_experience: 30,
    company_tenure: 0.5,
    area_name: "Mexico",
    expertise: "High",
    skill_percentage: 100,
    training_need: "Low"
  },
  {
    id: 10,
    full_name: "Felix Ulysses",
    title: "Sr. Support Engineer, Security",
    industry_experience: 30,
    company_tenure: 0.5,
    area_name: "Mexico",
    expertise: "High",
    skill_percentage: 100,
    training_need: "Low"
  },
  {
    id: 11,
    full_name: "Sampson Samantha",
    title: "Sr. Support Engineer, Security",
    industry_experience: 30,
    company_tenure: 0.5,
    area_name: "Mexico",
    expertise: "High",
    skill_percentage: 100,
    training_need: "Low"
  },
  {
    id: 12,
    full_name: "Deborah Muriel",
    title: "Sr. Support Engineer, Security",
    industry_experience: 30,
    company_tenure: 0.5,
    area_name: "Mexico",
    expertise: "High",
    skill_percentage: 100,
    training_need: "Low"
  }
];

export const EmployeeRosterData: EmployeeAnalytics[] = [
  {
    id: 0,
    full_name: "Brendon Roseann",
    title: "Sr. Support Engineer, Linux OS",
    industry_experience: 22,
    company_tenure: 35,
    area_name: "Mexico",
    geography: "Onshore (CAN)",
    skill_percentage: 100,
    result: "Above skill lvl",
    score_avg: 5.2,
    target_avg: 2.7
  },
  {
    id: 1,
    full_name: "Bruce Placide",
    title: "Sr. Support Engineer, Linux OS",
    industry_experience: 22,
    company_tenure: 35,
    area_name: "Mexico",
    geography: "Onshore (CAN)",
    skill_percentage: 100,
    result: "Above skill lvl",
    score_avg: 5.2,
    target_avg: 2.7
  },
  {
    id: 2,
    full_name: "Victor Palmer",
    title: "Sr. Support Engineer, Linux OS",
    industry_experience: 22,
    company_tenure: 35,
    area_name: "Mexico",
    geography: "Onshore (CAN)",
    skill_percentage: 100,
    result: "Above skill lvl",
    score_avg: 5.2,
    target_avg: 2.7
  },
  {
    id: 3,
    full_name: "Hayk Yves",
    title: "Lead Sr. Support Engineer, Linux OS",
    industry_experience: 22,
    company_tenure: 35,
    area_name: "Mexico",
    geography: "Onshore (CAN)",
    skill_percentage: 100,
    result: "Above skill lvl",
    score_avg: 5.2,
    target_avg: 2.7
  },
  {
    id: 4,
    full_name: "Carlyle Joyce",
    title: "Sr. Support Engineer, Linux OS",
    industry_experience: 22,
    company_tenure: 35,
    area_name: "Mexico",
    geography: "Onshore (CAN)",
    skill_percentage: 100,
    result: "Above skill lvl",
    score_avg: 5.2,
    target_avg: 2.7
  },
  {
    id: 5,
    full_name: "Jerold Bruce",
    title: "Sr. Support Engineer, Linux OS",
    industry_experience: 22,
    company_tenure: 35,
    area_name: "Mexico",
    geography: "Onshore (CAN)",
    skill_percentage: 100,
    result: "Above skill lvl",
    score_avg: 5.2,
    target_avg: 2.7
  },
  {
    id: 6,
    full_name: "Brandt Garey",
    title: "Sr. Support Engineer, Linux OS",
    industry_experience: 22,
    company_tenure: 35,
    area_name: "Mexico",
    geography: "Onshore (CAN)",
    skill_percentage: 100,
    result: "Above skill lvl",
    score_avg: 5.2,
    target_avg: 2.7
  },
  {
    id: 7,
    full_name: "Georgette Talia",
    title: "Lead Sr. Support Engineer, Linux OS",
    industry_experience: 22,
    company_tenure: 35,
    area_name: "Mexico",
    geography: "Onshore (CAN)",
    skill_percentage: 100,
    result: "Above skill lvl",
    score_avg: 5.2,
    target_avg: 2.7
  },
  {
    id: 8,
    full_name: "Cowessess Mack",
    title: "Sr. Support Engineer, Security",
    industry_experience: 22,
    company_tenure: 35,
    area_name: "Mexico",
    geography: "Onshore (CAN)",
    skill_percentage: 100,
    result: "Above skill lvl",
    score_avg: 5.2,
    target_avg: 2.7
  }
];
