import React from "react";
import ReactSelect, { OptionsOrGroups, GroupBase, StylesConfig, ActionMeta } from "react-select";
import { Option } from "./Option/Option";
import styles from "./multi-select-checkbox.module.scss";

//  Styles for react reselect
const colourStyles: StylesConfig = {
  control: styles => ({
    ...styles,
    backgroundColor: "white"
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isSelected ? "white" : "white",
      color: "#808184",
      cursor: isDisabled ? "not-allowed" : "default",
      border: isFocused ? 0 : 0
    };
  }
};

interface MultiSelect {
  data: OptionsOrGroups<unknown, GroupBase<unknown>> | undefined;
  placeholder: string;
  isMulti?: boolean;
  onChange?: ((newValue: any, actionMeta: ActionMeta<unknown>) => void) | undefined;
  className?: string;
}

export const MultiSelectCheckbox: React.FC<MultiSelect> = ({
  data,
  placeholder,
  isMulti = true,
  onChange,
  className,
  ...props
}) => {
  return (
    <div className={styles.multiSelectCheckboxItem}>
      <ReactSelect
        className={className}
        options={data}
        isMulti={isMulti}
        placeholder={placeholder}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        controlShouldRenderValue={false}
        styles={colourStyles}
        components={{ Option }}
        isClearable
        onChange={onChange}
      />
    </div>
  );
};
