import { GlobalStore } from "@common/api/store/global";
import { Roles } from "@common/constants";
import grid from "@styles/modules/grid.module.scss";
import { FormTextArea } from "@ui";
import classNames from "classnames";
import { useContext } from "react";
import { UseFormReturn } from "react-hook-form";
import { AccountabilitiesFeedback } from "./..";
import modalStyles from "./../accountabilities-form-modal.module.scss";
import styles from "./feedback.module.scss";

interface Props {
  methods: UseFormReturn<AccountabilitiesFeedback, object>;
}

export const Feedback = ({ methods }: Props) => {
  const { $profileStore } = useContext(GlobalStore);
  return (
    <div className={classNames(grid.list, styles.feedback)}>
      <div className={classNames(grid.item, grid["item-4x"])}>
        <div className={modalStyles.title}>Congratulations</div>
        <div className={styles.description}>
          One last step, please provide additional feedback to create a full picture of the accountabilities review.
          Please consider answering the following questions:
          <ul className="mt-4 list-disc list-inside">
            {$profileStore.currentRoleId === Roles.employee ? (
              <>
                <li>What were your biggest accomplishments for the period in review?</li>
                <li>What were the lowlights?</li>
                <li>How can your supervisor help you more?</li>
              </>
            ) : (
              <>
                <li>What were employee’s biggest accomplishments for the period in review?</li>
                <li>What were the lowlights?</li>
                <li>What does the employee need to focus on?</li>
              </>
            )}
          </ul>
        </div>
      </div>
      <div className={classNames(grid.item, grid["item-8x"], styles["feedback-column"])}>
        <div className={classNames(modalStyles.title, styles["feedback-title"])}>
          Additional feedback (max 240 characters)
        </div>
        <FormTextArea
          name="feedback"
          className={styles.textarea}
          wrapperClass={styles["textarea-wrapper"]}
          register={methods.register}
          error={methods?.formState.errors["feedback"]?.message}
        />
      </div>
    </div>
  );
};
