import { getAccountabilitiesMembersParams } from "@common/features/accountabilities/model";
import { AccountabilitiesMembersParams } from "@common/types/reports/accountabilities/accountabilities";
import { Svg } from "@ui";
import { useEffect, useState } from "react";
import styles from "./additional-report.module.scss";

interface Props {
  profileId: number;
}

export const AdditionalReport = ({ profileId }: Props) => {
  const [params, setParams] = useState<AccountabilitiesMembersParams>();

  useEffect(() => {
    getAccountabilitiesMembersParams(profileId, setParams);
  }, []);
  return params?.custom_report_url ? (
    <a href={params.custom_report_url} rel="noreferrer" target="_blank" className={styles.wrapper}>
      Additional Reports <Svg modClass={styles.icon} name="external-link" />
    </a>
  ) : null;
};
