import { AxiosResponse } from "axios";
import { HttpService } from "../http";
import { DataWrapper } from "../types/response/common";

export const getOffices = async <T>(url: string): Promise<AxiosResponse<DataWrapper<T[]>>> => {
  return HttpService.get<DataWrapper<T[]>>(url);
};

export const getOfficeDetail = async <T>(url: string): Promise<AxiosResponse<DataWrapper<T>>> => {
  return HttpService.get<DataWrapper<T>>(url);
};

export const saveOfficeDetail = async <T>(url: string, data: T): Promise<AxiosResponse<DataWrapper<T>>> => {
  return HttpService.post<DataWrapper<T>>(url, data);
};

export const removeOffice = async <T>(url: string): Promise<AxiosResponse<DataWrapper<T>>> => {
  return HttpService.delete<DataWrapper<T>>(url);
};
