import { observer } from "mobx-react-lite";
import { GlobalStore } from "@common/api/store/global";
import { TableDetailRows } from "@common/types/table";
import { FirstLevelTable, Switch } from "@ui";
import { FormattedPeople, People } from "@common/types/reports/people/people";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getPeopleByManager } from "@common/features/people/model";
import { IntermediateLevel } from "./intermediate-level";
import { columns } from "./model";
import { createFormattedPeopleItem } from "./helpers";
import { getPhrases } from "@common/helpers/localization";
import { filterUsers } from "@features/people/reports/helpers";
import { cloneDeep } from "lodash";

export const FirstLevel = observer(() => {
  const loc = getPhrases("people_page", "table");

  const { $profileStore } = useContext(GlobalStore);
  const [data, setData] = useState<People[]>();
  const [showInactive, setShowInactive] = useState(false);
  useEffect(() => {
    getPeopleByManager(true, setData);
  }, [$profileStore.getCurrentRoleId()]);

  const formattedData: FormattedPeople[] | null = useMemo(() => {
    if (data?.length) {
      const peopleData = cloneDeep(data);
      filterUsers(
        "subordinates",
        member => (member.is_active && !showInactive) || (!member.is_active && showInactive),
        peopleData
      );
      console.log(peopleData);
      return peopleData.map(item => {
        return createFormattedPeopleItem<People>(item);
      });
    }
    return null;
  }, [data, showInactive]);

  const detailRows: TableDetailRows<People> = {
    component: IntermediateLevel,
    dataProperty: "subordinates",
    ...(formattedData?.length && {
      expandedRows: formattedData.reduce((prev: any[], current: FormattedPeople) => {
        return [...prev, current.id];
      }, [])
    })
  };

  const handleChangeShowInactive = useCallback((value: boolean) => {
    setShowInactive(value);
  }, []);

  const extraFilter = useMemo(
    () => (
      <div className="ml-4 mr-auto flex item-center">
        <Switch value={showInactive} onChange={handleChangeShowInactive} />
        <span className="ml-2">{loc[showInactive ? "only_inactive_employee" : "only_active_employee"]}</span>
      </div>
    ),
    [showInactive, handleChangeShowInactive]
  );

  return formattedData?.length
    ? FirstLevelTable<FormattedPeople>({
        data: formattedData,
        columns,
        detailRows,
        pagination: true,
        pageSize: 25,
        additionalFilter: extraFilter
      })
    : null;
});
