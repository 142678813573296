import React, { FC, useEffect, useState } from "react";
import * as yup from "yup";
import classNames from "classnames";
import { getSkillCategories } from "@common/features/skills/model";
import { useForm } from "@common/hooks/form";
import { ISkillCategoryForm, SkillCategory } from "@common/types/skill-engine/skill-category";
import { ComplexModalButtons, FormInputAutocomplete, FormInputText, FormTextArea, FormWrapper } from "@ui";
import { FieldErrors } from "react-hook-form";

export interface ISkillCategoriesFormProps {
  creating?: boolean;
  formData?: ISkillCategoryForm[];
  onSubmit(data: ISkillCategoryForm[]): void;
  onClose(): void;
  onSetModalButtons(buttons?: ComplexModalButtons): void;
}

export interface ICategoryForm extends ISkillCategoryForm {
  id: number;
}

export interface ISkillCategories {
  categories: ICategoryForm[];
}

export interface ISkillCategory extends SkillCategory {
  children?: ISkillCategory[];
}

const skillCategoriesFormSchema: yup.SchemaOf<ISkillCategories> = yup.object().shape({
  categories: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required("This field is required"),
        description: yup.string().required("This field is required"),
        parent_id: yup.mixed(),
        is_active: yup.boolean()
      })
    )
    .min(1)
});

export const SkillCategoriesForm: FC<ISkillCategoriesFormProps> = ({
  creating,
  formData,
  onSubmit,
  onClose,
  onSetModalButtons
}) => {
  const methods = useForm<ISkillCategories>({
    schema: skillCategoriesFormSchema,
    defaultValues: { categories: formData?.length ? formData : [{ id: 1, is_active: true, name: "", description: "" }] }
  });
  const data = methods.getValues();
  const [categories, setCategories] = useState<ISkillCategory[]>([]);
  const [timestamp, setTimestamp] = useState<number>();

  useEffect(() => {
    if (formData) {
      methods.reset({ categories: formData });
    }
  }, [formData]);

  useEffect(() => {
    getSkillCategories().then((categories: ISkillCategory[]) => {
      categories.forEach(category => {
        if (category.parent_id) {
          const parentCategory = categories.find(item => item.id === category.parent_id);
          if (parentCategory) {
            if (parentCategory.children) {
              parentCategory.children.push(category);
            } else {
              parentCategory.children = [category];
            }
          }
        }
      });
      setCategories(categories.filter(item => !item.parent_id));
    });
  }, []);

  useEffect(() => {
    onSetModalButtons({
      rightSide: [
        {
          title: (
            <span>
              Cancel
              <br />
              Changes
            </span>
          ),
          theme: "secondary-gradient",
          className: "!w-30 !h-14 !text-sm !text-left !font-semibold",
          onClick: onClose
        },
        {
          title: (
            <span>
              Save
              <br />
              Changes
            </span>
          ),
          theme: "orange",
          className: "!w-30 !h-14 !text-sm !text-left !font-semibold",
          onClick() {
            methods.handleSubmit(handleSuccess, handleError)();
          }
        }
      ]
    });
  }, [onSetModalButtons]);

  const onFieldChange = (field: any, value: any) => {
    methods.setValue(field, value);
    // methods.trigger(field);
    setTimestamp(new Date().getTime());
  };

  const onAddSkillCategory = (index: number) => {
    const maxId = Math.max(0, ...data.categories.map(item => item.id));
    const categories: Partial<ISkillCategory>[] = [...data.categories];
    categories.splice(index + 1, 0, {
      id: maxId + 1,
      name: "",
      description: "",
      is_active: true
    });
    onFieldChange("categories", categories);
  };

  const onPatchSkillCategory = (id: number, field: string, value: any) => {
    const categories = data.categories.map(item => (item.id === id ? { ...item, [field]: value } : item));
    onFieldChange("categories", categories);
  };

  const onRemoveSkillCategory = (id: number) => {
    const categories = data.categories.filter(item => item.id !== id);
    onFieldChange("categories", categories);
  };

  const handleSuccess = (values: ISkillCategories) => {
    onSubmit(values.categories);
  };

  const handleError = (e: FieldErrors<any>) => {
    console.log(e);
  };

  return (
    <FormWrapper className="text-black" onSubmit={methods.handleSubmit(handleSuccess, handleError)}>
      {data.categories.map((item, i) => (
        <div
          key={item.id}
          className={classNames(
            "grid sm:grid-cols-2 gap-4 border-gray-cd px-5",
            i && "border-t pt-4",
            i < data.categories.length - 1 && "pb-4"
          )}
        >
          <FormInputText
            className="!text-sm !text-black !placeholder-secondary placeholder-italic"
            name={`categories[${i}].name`}
            label="Name your Skill Category"
            placeholder="Type the name of your skill category"
            register={methods.register}
            error={methods.formState.errors?.categories && methods.formState.errors?.categories[i]?.name?.message}
          />
          <FormInputAutocomplete
            className="!text-sm !text-black !placeholder-secondary placeholder-italic"
            name={`categories[${i}].parent_id`}
            label="Search for your parent category"
            placeholder="Type the name of the category here"
            list={categories}
            value={item.parent_id}
            group
            groupSelectable
            getItemLabel={(item: SkillCategory) => item.name}
            getItemValue={(item: SkillCategory) => item.id}
            dropdownClass="max-h-30 overflow-auto !mt-0"
            dropdownItemClass="!text-sm"
            register={methods.register}
            onChange={id => onPatchSkillCategory(item.id, "parent_id", id)}
          />
          <FormTextArea
            className="!text-sm !text-black !placeholder-secondary placeholder-italic"
            name={`categories[${i}].description`}
            label="Skill Category Description"
            placeholder="Description here"
            register={methods.register}
            error={
              methods.formState.errors?.categories && methods.formState.errors?.categories[i]?.description?.message
            }
          />
          {creating && (
            <div className="flex flex-wrap items-start justify-between pt-6">
              <div
                className={classNames(
                  "text-warning font-bold text-sm mb-3",
                  i ? "cursor-pointer" : "pointer-events-none opacity-60"
                )}
                onClick={() => onRemoveSkillCategory(item.id)}
              >
                <i className="fa fa-minus-circle" /> Delete this skill category
              </div>
              <div className="text-warning font-bold text-sm mb-3 cursor-pointer" onClick={() => onAddSkillCategory(i)}>
                Add another skill category <i className="fa fa-plus-circle" />
              </div>
            </div>
          )}
        </div>
      ))}
    </FormWrapper>
  );
};
