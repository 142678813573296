import { CategoryLine } from "@features/settings";
import { useCallback, useState } from "react";
import { Offices } from "../offices";
import { AreaInfoModal, OfficeInfoModal } from "@features/settings/blocks/offices";
import { Button } from "@ui";

export const OfficesCategoryLine = () => {
  const [addOfficeOpen, setAddOfficeOpen] = useState<boolean>(false);
  const [addAreaOpen, setAddAreaOpen] = useState<boolean>(false);

  const handleCloseOfficeModal = () => {
    setAddOfficeOpen(false);
  };

  const handleCloseAreaModal = () => {
    setAddAreaOpen(false);
  };

  const onAddOfficeClick = useCallback(e => {
    e.stopPropagation();
    setAddOfficeOpen(prev => !prev);
  }, []);

  const onAddAreaClick = useCallback(e => {
    e.stopPropagation();
    setAddAreaOpen(prev => !prev);
  }, []);

  return (
    <>
      <CategoryLine
        title="Areas / Offices"
        content={<Offices />}
        buttons={
          <div className="ml-auto flex">
            <Button title="Add area" size="thin" theme="dark-blue" onClick={onAddAreaClick} />
            <Button title="Add office" size="thin" theme="dark-blue" className="ml-2" onClick={onAddOfficeClick} />
          </div>
        }
      />
      {addOfficeOpen && <OfficeInfoModal isOpen closeModal={handleCloseOfficeModal} />}
      {addAreaOpen && <AreaInfoModal isOpen closeModal={handleCloseAreaModal} />}
    </>
  );
};
