import { Common, CommonWithIntegerId, WithIntegerId } from "@common/types/common";

export enum ExpertiseLevelEnum {
  Beginner = 1,
  Intermediate = 2,
  Advanced = 3
}

export enum ContentTypeEnum {
  Video = 1,
  OnlineCourse = 2,
  Document = 3,
  Article = 4
}

export interface ExpertiseLevel extends CommonWithIntegerId {
  sort: ExpertiseLevelEnum;
}
interface Language extends CommonWithIntegerId {
  code: string;
}

interface Provider extends CommonWithIntegerId {
  website_url: string;
  logo_image_url: string;
  favicon_image_url: string;
  is_active: boolean;
}

interface ContentType extends Common {
  id: ContentTypeEnum;
}

export interface SkillCategories {
  common: string;
  common_root: string;
}

interface CourseItemCommon extends WithIntegerId {
  is_active: boolean;
  title: string;
  image_url: string;
  duration: number;
  is_free: boolean;
  provider: Provider;
  expertise_level: ExpertiseLevel;
  progress: number;
  rating_average: number;
  rating_count: number;
  skill_categories: SkillCategories;
  skills: string[];
}

export interface CourseItemInterface extends CourseItemCommon {
  short_description: string;
  publication_year: number;
  publication_month: number;
  url: string;
  video_preview_url: string;
  content_type: ContentType;
  rating_average: number;
  rating_count: number;
  stars_eval: number;
  reviews_count: number;
  language: Language;
  instructors: string;
  is_favourite: boolean;
  syllabus: string;
}

export interface YourLearningPathsInterface extends CourseItemCommon {
  description: string;
}

export interface YourLearningPathsList {
  order: number;
  resource: CourseItemInterface;
}

export interface LearnResource extends WithIntegerId {
  provider: {
    id: number;
    name: string;
    website_url: string;
    logo_image_url: string;
    favicon_image_url: string;
    is_active: boolean;
  };
  title: string;
  content_type: {
    id: number;
    name: string;
  };
  duration: number;
  stars_eval: number;
  language: {
    id: number;
    name: string;
    code: string;
  };
  rating_average: number;
  rating_count: number;
  skills: string[];
  members: {
    id: number;
    first_name: string;
    last_name: string;
    middle_name: string;
    progress: number;
  }[];
  updated_at: string;
}
export interface TreeViewInterface extends CommonWithIntegerId {
  description: string;
  learn_resources: LearnResource[];
}
