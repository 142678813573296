import React, { FC, useCallback, useState } from "react";
import { TableCellProps } from "@common/types/table";
import styles from "./role-action.module.scss";
import { AlertModal, Button, Titles } from "@ui";
import { deleteRole } from "@common/features/roles/model";
import { $commonStore } from "@common/api/store/common";
import { BannerType } from "@common/api/types/common";

interface RoleActionProps extends TableCellProps {}

export const RoleAction: FC<RoleActionProps> = ({ props }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { rowData } = props;

  const toggleModal = useCallback(() => {
    setIsOpen(isOpen => !isOpen);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSubmit = useCallback(() => {
    deleteRole(rowData.id)
      .then(() => {
        $commonStore.setBanner(BannerType.success, `The role ${rowData.name} has been deleted.`);
      })
      .catch(() =>
        $commonStore.setBanner(
          BannerType.error,
          `The role ${rowData.name} cannot be deleted, because some other entities are attached to it. Please check with our support if needed.`
        )
      )
      .finally(() => setIsOpen(false));
  }, [rowData]);

  return (
    <>
      <div className={styles.close} onClick={toggleModal} />
      {isOpen && (
        <AlertModal closeModal={toggleModal} isOpen>
          <div className={styles.buttons}>
            <Titles level="level_3" modClass="text-warning/80">
              You are about to delete a role. Are you sure to delete{" "}
              <span className="text-warning">{rowData.name}</span>?
            </Titles>
            <div className="flex justify-end">
              <Button className="w-30" title="Cancel" theme="gray" onClick={closeModal} />
              <Button className="ml-4 w-30" title="Delete" theme="orange" onClick={handleSubmit} />
            </div>
          </div>
        </AlertModal>
      )}
    </>
  );
};
