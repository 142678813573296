import { TableColumn, TableDetailRows } from "@common/types/table";
import classNames from "classnames";
import { DataType, SortDirection } from "ka-table/enums";
import grid from "@styles/modules/grid.module.scss";
import { FirstLevelTable } from "@ui";
import { SecondLevel } from "../second-level";
import { RoleTree } from "@common/types/roles/roles";
import includedStyles from "./../../included-first-level-table.module.scss";
import { rowStyler } from "../../helpers";
import { PropsWithChildren } from "react";
import { ICellTextProps } from "ka-table/props";
import { getDeclensions } from "@common/helpers/string-helpers";
import styles from "../../blocks.module.scss";
import { RoleAction } from "@features/settings/blocks/roles";

interface Props {
  roles: RoleTree[];
}

const getRoles = (data: PropsWithChildren<ICellTextProps>): string => {
  return `${data.value} ${getDeclensions(data.value, "role", "roles")}`;
};

const getEmployees = (data: PropsWithChildren<ICellTextProps>): string => {
  return `${data.value} ${getDeclensions(data.value, "employee", "employees")}`;
};

export const FirstLevel: React.FC<Props> = ({ roles }) => {
  const columns: TableColumn<RoleTree>[] = [
    {
      key: "name",
      title: "Department Name",
      dataType: DataType.String,
      sortDirection: SortDirection.Descend,
      modClass: classNames(grid.item, grid["item-4x"], styles["align-left"]),
      expandCell: true
    },
    {
      key: "roles",
      title: "Roles",
      field: "roles.length",
      dataType: DataType.Number,
      modClass: classNames(grid.item, grid["item-4x"], styles["align-left"]),
      decorator: data => getRoles(data)
    },
    {
      key: "members_count",
      title: "Employees",
      dataType: DataType.Number,
      modClass: classNames(grid.item, grid["item-2x"], styles["align-left"]),
      decorator: data => getEmployees(data)
    },
    {
      key: "id",
      title: "",
      modClass: classNames(grid.item, grid["item-1x"]),
      decorator: () => " "
    },
    {
      key: "index",
      title: "",
      modClass: classNames(grid.item, grid["item-1x"]),
      component: RoleAction
    }
  ];

  const detailRows: TableDetailRows<RoleTree> = {
    component: SecondLevel,
    dataProperty: "roles"
  };

  if (!roles?.length) {
    return null;
  }

  return FirstLevelTable<RoleTree>({
    data: roles,
    columns,
    detailRows,
    theme: "orange",
    modStyles: includedStyles,
    rowStyler,
    hideHeader: true
  });
};
