import classNames from "classnames";
import grid from "@styles/modules/grid.module.scss";
import modal from "@styles/modules/complex-modal.module.scss";
import styles from "./questions-list.module.scss";
import modalStyles from "./../accountabilities-form-modal.module.scss";
import { observer } from "mobx-react-lite";
import { AccountabilitiesScoreEnums } from "@common/types/reports/accountabilities/accountabilities";
import { AccountabilitiesForm } from "@common/types/forms/accountabilities";
import { $accountabilitiesStore } from "@common/api/store/accountabilities";
import { getOnlyDateFormat } from "@common/helpers/data-helpers";
import { getValue } from "@features/accountabilities/helpers";

interface Props {
  data: AccountabilitiesForm;
  categoryName?: string;
  categoryId: string;
  isManager?: boolean;
}

export type AccountabilitiesMarks =
  | AccountabilitiesScoreEnums.A
  | AccountabilitiesScoreEnums.B
  | AccountabilitiesScoreEnums.C;

export const QuestionsList = observer(({ data, categoryId, categoryName, isManager }: Props) => {
  const results = $accountabilitiesStore.getResults();
  const defaultMark = results?.results[categoryId];
  if (!!categoryId) {
    const marks = [
      {
        id: AccountabilitiesScoreEnums.A,
        value: "A",
        description: "Met or exceeded expectations"
      },
      {
        id: AccountabilitiesScoreEnums.B,
        value: "B",
        description: "Fell somewhat short of expectations"
      },
      {
        id: AccountabilitiesScoreEnums.C,
        value: "C",
        description: "Fell far short of expectations"
      }
    ];

    const onMarkChangeHandler = (value: number) => {
      if (defaultMark !== value) {
        $accountabilitiesStore.addResult(categoryId, value);
      }
    };

    return (
      <div className={styles.wrapper}>
        <div className={classNames(styles.section, styles["top-line"])}>
          <div className={styles["top-line-info"]}>
            <div className={modalStyles.title}>
              Please assess your aptitude in <span className={styles.category}>{categoryName ?? ""}</span>
            </div>
            <div className={styles.description}>
              Based on your reading of the Metrics below, please assess your performance in this area:
            </div>
          </div>
          {isManager && (
            <div className={styles["top-line-legend"]}>
              <div className={styles["top-line-self"]}>_ is Self score for this session</div>
            </div>
          )}
        </div>
        <div className={styles.section}>
          <div className={styles.marks}>
            {marks.map(item => {
              return (
                <div
                  className={styles["marks-item"]}
                  key={`mark-item-${item.id}`}
                  onClick={() => onMarkChangeHandler(item.id)}
                >
                  <div
                    className={classNames(
                      styles["marks-indicator"],
                      item.id === defaultMark && styles["marks-indicator-active"]
                    )}
                  />
                  <div
                    className={classNames(
                      styles["marks-value"],
                      isManager ? styles["marks-value-manager"] : styles["marks-value-self"],
                      data.results?.current?.self?.score &&
                        data.results?.current?.self?.score === item.id &&
                        styles["marks-with-self"],
                      data.results?.current?.manager?.score &&
                        data.results?.current?.manager?.score === item.id &&
                        styles["marks-with-manager"]
                    )}
                  >
                    {item.value}
                  </div>
                  <div className={styles["marks-description"]}>{item.description}</div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={classNames(styles.section, styles["metrics-section"])}>
          <div className={modalStyles.title}>
            Metrics for <span className={styles.category}>{categoryName}</span>
          </div>
          <div className={classNames(styles["metrics-wrapper"], modal["row-negative"])}>
            <div className={classNames(styles.metrics, grid.list)}>
              {data.metrics &&
                data.metrics.map((item, index) => {
                  return (
                    <div
                      className={classNames(styles["metrics-item"], grid.item, grid["item-4x"])}
                      key={`metric-item-${index}`}
                    >
                      <div className={styles["metrics-title"]}>Metric {index + 1}:</div>
                      <div className={styles["metrics-description"]}>{item}</div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {data.results?.previous && (
          <div className={styles.section}>
            <div className={modalStyles.title}>
              Previous grades for <span className={styles.category}>{categoryName}</span>
            </div>
            <div className={styles.previous}>
              {data.results?.previous?.self && (
                <div className={styles["previous-item"]}>
                  <div className={classNames(styles["previous-value"], styles["previous-value-self"])}>
                    {getValue(data.results?.previous?.self?.score)}
                  </div>
                  <div className={styles["previous-text"]}>
                    <div className={styles["previous-date"]}>
                      {getOnlyDateFormat(data.results?.previous?.self?.date)}
                    </div>
                    <div className={styles["previous-type"]}>Self Assessment</div>
                  </div>
                </div>
              )}
              {data.results?.previous?.manager && (
                <div className={styles["previous-item"]}>
                  <div className={classNames(styles["previous-value"], styles["previous-value-manager"])}>
                    {getValue(data.results?.previous?.manager?.score)}
                  </div>
                  <div className={styles["previous-text"]}>
                    <div className={styles["previous-date"]}>
                      {getOnlyDateFormat(data.results?.previous?.manager?.date)}
                    </div>
                    <div className={styles["previous-type"]}>Manager Assessment</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
});
