export const readFile = (file: File | Blob): Promise<string | ArrayBuffer | null> => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
};

const getImageDataUrl = (url: string): Promise<string | ArrayBuffer | null> => {
  return fetch(url)
    .then(res => res.blob())
    .then(blob => {
      return readFile(blob);
    });
};

const getImageData = (url: string): Promise<HTMLImageElement> => {
  return new Promise(async (resolve, reject) => {
    const img = document.createElement("img");
    img.setAttribute("style", "position: fixed; opacity: 0; z-index: -100;");
    img.onload = () => {
      resolve(img);
    };
    img.onerror = error => {
      reject(error);
    };
    const dataUrl = await getImageDataUrl(url);
    img.src = dataUrl?.toString() || "";
    document.body.appendChild(img);
  });
};

export const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    try {
      const image = new Image();
      image.crossOrigin = "";
      image.src = url;
      const corsImage = new Image();
      corsImage.addEventListener("load", async () => {
        const img = await getImageData(url);
        image.width = img.width;
        image.height = img.height;
        resolve(image);
      });
      corsImage.addEventListener("error", error => reject(error));
      corsImage.src = url + (url.startsWith("http", 0) ? "?not-from-cache-please" : "");
    } catch (e) {
      console.error("error", e);
    }
  });

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height)
  };
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg(
  imageSrc: any,
  percentCrop: any,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
): Promise<{ dataUrl: string; url: string; file: Blob } | null> {
  let img = await getImage(imageSrc);
  if (!img) {
    return null;
  }
  let { image, dataUrl, url } = img;

  image.src = image.src + (image.src.startsWith("http", 0) ? `?not-from-cache-${Date.now()}` : "");

  if (!image.width && !image.height) {
    const img = new Image(500, 500);
    img.src = image.src;
    image = img;
  }
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const pixelCrop = {
    x: (image.width / 100) * percentCrop.x,
    y: (image.height / 100) * percentCrop.y,
    width: (image.width / 100) * percentCrop.width,
    height: (image.height / 100) * percentCrop.height
  };
  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(file => {
      resolve({ dataUrl: canvas.toDataURL("image/jpeg"), url: URL.createObjectURL(file as Blob), file: file as File });
    }, "image/jpeg");
  });
}

export async function getRotatedImage(
  imageSrc: any,
  rotation = 0
): Promise<{ dataUrl: string; image: HTMLImageElement; url: string } | null> {
  const image = await getImageData(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  image.src = image.src + (image.src.startsWith("http", 0) ? `?not-from-cache-${Date.now()}` : "");

  const orientationChanged = rotation === 90 || rotation === -90 || rotation === 270 || rotation === -270;
  if (orientationChanged) {
    canvas.width = image.height;
    canvas.height = image.width;
  } else {
    canvas.width = image.width;
    canvas.height = image.height;
  }

  if (!ctx) {
    return null;
  }

  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate((rotation * Math.PI) / 180);
  ctx.drawImage(image, -image.width / 2, -image.height / 2);
  const data = ctx.getImageData(0, 0, image.width, image.height);

  canvas.width = image.width;
  canvas.height = image.height;

  ctx.putImageData(data, 0, 0);

  return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve({ dataUrl: canvas.toDataURL("image/jpeg"), image, url: URL.createObjectURL(file as Blob) });
    }, "image/png");
  });
}

export async function getImage(
  imageSrc: any
): Promise<{ dataUrl: string; image: HTMLImageElement; url: string } | null> {
  const image = await getImageData(imageSrc);
  const canvas = document.createElement("canvas");

  const ctx = canvas.getContext("2d");
  image.src = image.src + (image.src.startsWith("http", 0) ? `?not-from-cache-${Date.now()}` : "");

  canvas.width = image.width;
  canvas.height = image.height;

  if (!ctx) {
    return null;
  }

  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.drawImage(image, -image.width / 2, -image.height / 2);

  return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve({ dataUrl: canvas.toDataURL("image/jpeg"), image, url: URL.createObjectURL(file as Blob) });
    }, "image/png");
  });
}
