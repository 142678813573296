import { getReportOrgUnits } from "@common/features/org-units/model";
import { makeAutoObservable, toJS } from "mobx";
import { ClassCommon } from "../types/common";
import { OrgUnits as OrgUnitsType } from "@common/types/reports/org-units/org-units";

export class OrgUnits implements ClassCommon {
  orgUnits: OrgUnitsType[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setOrgUnits(orgUnits: any) {
    this.orgUnits = orgUnits;
  }

  getOrgUnits() {
    return toJS(this.orgUnits);
  }

  updateOrgUnits() {
    getReportOrgUnits();
  }

  reset(): void {
    this.orgUnits = [];
  }
}

export const $orgUnitsStore = new OrgUnits();
