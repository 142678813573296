import React, { FormEventHandler } from "react";

interface Props {
  onSubmit?: FormEventHandler<HTMLFormElement>;
  children?: React.ReactNode;
  className?: string;
}

export const FormWrapper = ({ onSubmit, className, children }: Props) => {
  return (
    <form action="" className={className} onSubmit={onSubmit}>
      {children}
    </form>
  );
};
