import { Common, CommonWithIntegerId, WithId, WithIntegerId } from "@common/types/common";

// TODO: change on special interface
export interface AccountabilityMark extends WithId {
  value: string;
  description: string;
}

export interface AccountabilitiesSessionAssessmentResult {
  self: AccountabilitiesScoreType;
  manager: AccountabilitiesScoreType;
  date: string;
}
export interface AccountabilitiesSessionAssessment extends Omit<Accountability, "area_name">, Common {
  results: AccountabilitiesSessionAssessmentResult[];
}

// TODO: change on special interface

interface AccountabilitiesSessionFeedback {
  self?: string;
  manager?: string;
  date: string;
}

export interface AccountabilitiesSession {
  interval: number;
  feedbacks?: AccountabilitiesSessionFeedback[];
  accountabilities: AccountabilitiesSessionAssessment[];
}

export interface AccountabilitiesSessionResults {
  previous?: AccountabilitiesSessionResultsTypes;
  current?: AccountabilitiesSessionResultsTypes;
}
interface AccountabilitiesSessionResultsScoreType {
  score: AccountabilitiesScoreType;
  date: string;
}
interface AccountabilitiesSessionResultsTypes {
  self?: AccountabilitiesSessionResultsScoreType;
  manager?: AccountabilitiesSessionResultsScoreType;
}
export interface AccountabilitiesCurrentSession {
  started_at: string;
  completed_at?: string;
  feedbacks?: {
    self?: AccountabilitiesScoreType;
    manager?: AccountabilitiesScoreType;
  };
  accountabilities: (CurrentSessionAccountability & CurrentSessionResults)[];
}

interface CurrentSessionResults {
  results?: AccountabilitiesSessionResults;
}

export interface CurrentSessionAccountability extends CommonWithIntegerId {
  metrics: string[];
}

export interface Accountability extends WithIntegerId {
  area_name: string;
  metrics: string[];
  weight: number;
}

export interface AccountabilitySetting {
  is_enabled: boolean;
  // starting_month: number;
  interval_months?: number | null;
}

export enum AccoutabilitiesSessionsTypes {
  self = 1,
  manager = 2
}

export enum AccountabilitiesScoreEnums {
  A = 1,
  B = 2,
  C = 3
}

export type AccountabilitiesScoreType =
  | AccountabilitiesScoreEnums.A
  | AccountabilitiesScoreEnums.B
  | AccountabilitiesScoreEnums.C;

export interface SendAccountabilityValue {
  session_accountability_id: number;
  mark_id: number;
}

export interface SendAccountabilityValues {
  feedback: string;
  accountabilities: SendAccountabilityValue[];
}

export interface AccountabilitiesMembersParams {
  custom_report_url?: string;
}
