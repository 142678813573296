import { makeAutoObservable, set, toJS } from "mobx";
import { ClassCommon } from "./../types/common";
import {
  EmployeeAnalytics,
  EmployeeAnalyticsSummary,
  SupervisorAnalytics,
  TeamAnalytics,
  TeamAnalyticsSummary
} from "@common/types/reports/analytics/analytics";
import { OrgUnit } from "@common/types/org-unit/org-unit";
import { CommonWithId } from "@common/types/common";
import { Role } from "@common/types/roles/roles";

export class Analytics implements ClassCommon {
  teamTypes: OrgUnit[] = [];
  teams: TeamAnalytics[] = [];
  teamsByEmployees: { [key: string]: EmployeeAnalytics[] } = {};
  teamsBySupervisors: { [key: string]: SupervisorAnalytics[] } = {};
  teamsSummary: TeamAnalyticsSummary | null = null;
  employeeTypes: CommonWithId[] = [];
  roles: Role[] = [];
  employees: EmployeeAnalytics[] = [];
  employeesByType: EmployeeAnalytics[][] = [];
  employeesSummary: EmployeeAnalyticsSummary | null = null;
  supervisors: SupervisorAnalytics[] = [];
  supervisorEmployees: { [key: string]: EmployeeAnalytics[] } = {};

  constructor() {
    makeAutoObservable(this);
  }

  setTeamTypes(value: OrgUnit[]) {
    this.teamTypes = value;
  }

  setTeams(value: TeamAnalytics[]) {
    this.teams = value;
  }

  getTeams() {
    return this.teams;
  }

  setTeamsByEmployees(value: EmployeeAnalytics[], id: number) {
    this.teamsByEmployees = { ...toJS(this.teamsByEmployees), [id]: value };
  }

  setTeamsBySupervisors(value: SupervisorAnalytics[], id: number) {
    set(this.teamsBySupervisors, { [id]: value });
  }

  setTeamsSummary(value: TeamAnalyticsSummary) {
    this.teamsSummary = value;
  }

  getTeamsSummary() {
    return this.teamsSummary;
  }

  setRoles(value: Role[]) {
    this.roles = value;
  }

  setEmployeeTypes(value: CommonWithId[]) {
    this.employeeTypes = value;
  }

  setEmployees(value: EmployeeAnalytics[], id?: number) {
    this.employees = value;
  }

  setEmployeesByType(value: EmployeeAnalytics[], id: number) {
    this.employeesByType = { ...toJS(this.employeesByType), [id]: value };
  }

  setSupervisors(value: SupervisorAnalytics[]) {
    this.supervisors = value;
  }

  setSupervisorEmployees(value: EmployeeAnalytics[], id: number) {
    this.supervisorEmployees = { ...toJS(this.supervisorEmployees), [id]: value };
  }

  setEmployeesSummary(value: EmployeeAnalyticsSummary) {
    this.employeesSummary = value;
  }

  reset(): void {
    this.teamTypes = [];
    this.teams = [];
    this.teamsByEmployees = {};
    this.teamsBySupervisors = {};
    this.teamsSummary = null;
    this.employeeTypes = [];
    this.roles = [];
    this.employees = [];
    this.employeesByType = [];
    this.employeesSummary = null;
    this.supervisors = [];
    this.supervisorEmployees = {};
  }
}

export const $analyticsStore = new Analytics();
