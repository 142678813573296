import { Button, FormInputPassword, FormInputText, FormWrapper } from "@ui";
import { FieldErrors } from "react-hook-form";
import form from "@styles/modules/form.module.scss";
import styles from "./reset-password.module.scss";
import { defaultValues, ResetPasswordFormInterface, resetPasswordFormSchema } from "./model";
import { useForm } from "@common/hooks/form";
import { resetPassword } from "@common/features/user/model";
import { APICallbacks } from "@common/types/forms/common";
import { PasswordTooltip } from "@features/user/password-tooltip";

interface Props extends APICallbacks {
  token: string;
  isInitial: boolean;
}

export const ResetPasswordForm = ({ token, isInitial, successCallback, failCallback }: Props) => {
  const methods = useForm<ResetPasswordFormInterface>({
    schema: resetPasswordFormSchema,
    defaultValues
  });
  const handleSuccess = (values: ResetPasswordFormInterface) => {
    if (values.email && values.passwordConfirm && values.password) {
      resetPassword({
        email: values.email,
        token: token,
        password: values.password,
        successCallback,
        failCallback
      });
    }
  };
  const handleError = (errors: FieldErrors<ResetPasswordFormInterface>) => {
    console.error(errors);
  };

  return (
    <>
      <FormWrapper onSubmit={methods.handleSubmit(handleSuccess, handleError)}>
        <div className={styles["form-wrapper"]}>
          <div className={form.row}>
            <FormInputText
              name="email"
              register={methods.register}
              placeholder="Email address"
              error={methods.formState.errors["email"]?.message}
            />
          </div>
          <div className={form.row}>
            <FormInputPassword
              placeholder="Password"
              name="password"
              register={methods.register}
              error={methods.formState.errors["password"]?.message}
            />
            <PasswordTooltip />
          </div>
          <div className={form.row}>
            <FormInputPassword
              name="passwordConfirm"
              register={methods.register}
              placeholder="Password (repeat)"
              error={methods.formState.errors["passwordConfirm"]?.message}
            />
          </div>
        </div>
        <Button title="Submit" className={styles.button} type="submit" />
      </FormWrapper>
    </>
  );
};
