import React from "react";
import { components, OptionProps } from "react-select";
import styles from "./option.module.scss";
import { Badge } from "../../../../ui/Badge";

interface Props extends OptionProps {
  data: any;
}

// todo - props.data ts error on line number 27
export const Option: React.FC<Props> = props => {
  return (
    <div>
      <components.Option {...props}>
        <div className={styles.optionContainer}>
          <div className={styles.option}>
            <input type="checkbox" checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
          </div>

          {props?.data.withPartner && <Badge type={"Advanced"}>Partner</Badge>}
        </div>
      </components.Option>
    </div>
  );
};
