import styles from "./footer.module.scss";
import grid from "@styles/modules/grid.module.scss";
import classNames from "classnames";
import { getPhrases } from "@common/helpers/localization";

export const Footer = () => {
  const loc = getPhrases();
  const footerInner = classNames(grid.container, styles.inner);
  const date = new Date();
  return (
    <div className={styles.footer}>
      <div className={grid.root}>
        <div className={footerInner}>
          <a rel="noreferrer" href="https://www.innovorg.com" target="_blank" className={styles.link}>
            {loc.footer?.site}
          </a>
          <div className={styles.copyright}>{`${loc.footer?.copyright_pre} ${date.getFullYear()} ${
            loc.footer?.copyright_post
          }`}</div>
          <a href="mailto:support@innovorg.com" className={styles.link}>
            {loc.footer?.contact}
          </a>
        </div>
      </div>
    </div>
  );
};
