import { TableColumn, TableDetailRows } from "@common/types/table";
import { CategoryTable } from "@ui";
import { FormattedOrgUnits } from "../types";
import { orgUnitsTableModel } from "../model";
import { IntermediateLevel } from "../intermediate-level";
import { IDataRowProps } from "ka-table/props";
import classNames from "classnames";
import grid from "@styles/modules/grid.module.scss";
import { OrgAction } from "@features/settings/blocks/org-units";

export const CategoryLevel: React.FC<IDataRowProps> = ({ rowData }) => {
  const columns: TableColumn<FormattedOrgUnits>[] = [
    ...orgUnitsTableModel,
    {
      key: "index",
      modClass: classNames(grid.item, grid["item-1x"]),
      component: OrgAction
    }
  ];
  const data = rowData.children;

  const detailRows: TableDetailRows<FormattedOrgUnits> = {
    component: IntermediateLevel,
    dataProperty: "children"
  };

  return CategoryTable<FormattedOrgUnits>({
    data,
    columns,
    detailRows,
    hideHeader: true,
    theme: "orange"
  });
};
