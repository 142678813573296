import React, { FC, useState } from "react";
import { ComplexModal, ComplexModalButtons } from "@ui";
import { ISkillCategoryForm } from "@common/types/skill-engine/skill-category";
import { SkillCategoriesForm } from "@features/skills/skill-categories-form";
import { SavingError } from "@features/settings/blocks/roles/role-form/saving-error";
import { createSkill, createSkillCategory } from "@common/features/skills/model";
import { $userStore } from "@common/api/store/user";

interface AddSkillCategoryModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export const AddSkillCategoryModal: FC<AddSkillCategoryModalProps> = ({ isOpen, closeModal }) => {
  const [apiError, setApiError] = useState("");
  const [buttons, setButtons] = useState<ComplexModalButtons>();
  const [formData, setFormData] = useState<ISkillCategoryForm[]>();

  const onSubmit = async (data: ISkillCategoryForm[]) => {
    console.log("submit", data);
    const categories: ISkillCategoryForm[] = [];
    Promise.allSettled(
      data.map((item, index) => {
        return createSkillCategory(item).catch(() => {
          categories.push(item);
        });
      })
    ).finally(() => {
      console.log(categories);
      if (categories.length === 0) {
        closeModal();
        $userStore.setSkillUpdate(true);
      } else {
        setFormData(categories);
      }
    });
  };

  return (
    <ComplexModal
      isOpen={isOpen}
      closeModal={closeModal}
      shouldCloseOnOverlayClick={false}
      headerTitle="Add Skill Category"
      headerSecondRow={
        <p className="text-md uppercase font-normal opacity-80">
          Please enter the details of your skill category below
        </p>
      }
      headerClass="pb-1"
      buttons={buttons}
    >
      <SkillCategoriesForm
        creating
        onSubmit={onSubmit}
        formData={formData}
        onClose={closeModal}
        onSetModalButtons={setButtons}
      />
      {apiError && (
        <SavingError
          className="absolute w-full h-full top-0 lef-0 z-100"
          message={apiError}
          onClose={() => setApiError("")}
        />
      )}
    </ComplexModal>
  );
};
