import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";

import { ITableProps, kaReducer, Table as TableKA } from "ka-table";
import { DataType, EditingMode, PagingPosition, SortingMode } from "ka-table/enums";
import { DispatchFunc } from "ka-table/types";

import { TableColumn, TableFCComponent, TableProps, TablePropsInterface } from "@common/types/table";
import { CountSelect } from "./count-select";
import { DetailsButton } from "./details-button";
import { Search } from "./search";
import tableStyles from "./table.module.scss";

const TableWrapper = <T extends {}>({
  data,
  columns,
  modStyles,
  detailRows,
  sorting = true,
  pagination = false,
  pageSize = 10,
  pageIndex = 0,
  pagesCount = 1,
  hideHeader = false,
  totalInfoRow = {
    shown: false
  },
  rowStyler,
  additionalFilter,
  sort,
  onChangeProps = (props: ITableProps) => null,
  search
}: TableProps<T>) => {
  let indexItem = useMemo(() => pageSize * pageIndex, [data]);
  const tablePropsInit: ITableProps = {
    columns: columns.map(item => {
      return { ...item, key: String(item.key) };
    }),
    data: data,
    sort,
    editingMode: EditingMode.None,
    rowKeyField: "id",
    search,
    ...(sorting && { sortingMode: SortingMode.Single }),
    ...(pagination && {
      paging: {
        enabled: true,
        pageIndex,
        pageSize,
        position: PagingPosition.Bottom
      }
    })
  };
  const [tableProps, changeTableProps] = useState<ITableProps>(tablePropsInit);

  const handleChangeTableProps = (props: ITableProps) => {
    if (onChangeProps) {
      onChangeProps(props);
    }
    changeTableProps(props);
  };

  useEffect(() => {
    if (pagination) {
      handleChangeTableProps({
        ...tableProps,
        paging: {
          enabled: true,
          pageIndex,
          pageSize,
          pagesCount,
          position: PagingPosition.Bottom
        }
      });
    }
  }, [pageIndex, pageSize, pagesCount, pagination]);

  const dispatch: DispatchFunc = action => {
    if (action.type === "UpdatePageIndex") {
      handleChangeTableProps({
        ...tableProps,
        paging: {
          ...tableProps.paging,
          pageIndex: action.pageIndex
        }
      });
    }
    changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
  };

  function checkExpandData<T>(propertyRowData: any, property?: keyof T): boolean {
    return !!property && !propertyRowData;
  }

  interface CheckComponentType extends TablePropsInterface {
    Component: TableFCComponent;
  }
  const checkComponentType = ({ Component, props }: CheckComponentType) => {
    if (typeof Component === "function") {
      return <Component props={props} />;
    } else if (Component instanceof Object) {
      return (
        <Component.JSX
          {...{
            props: props,
            ...Component.componentProps
          }}
        />
      );
    }
  };

  const getCellClass = (column: TableColumn<T>): string => classNames(tableStyles.cell, column.modClass);

  // TODO: release work with classes array in getUnionClass function

  const getUnionClass = (className: string): string => {
    return classNames(tableStyles[className], modStyles?.[className]);
  };

  return (
    <>
      {pagination && tableProps.paging?.pageSize && (
        <div className={tableStyles.filter}>
          <CountSelect
            defaultValue={{
              value: tableProps.paging.pageSize,
              label: `${tableProps.paging.pageSize}`
            }}
            tableProps={tableProps}
            changeTableProps={handleChangeTableProps}
          />
          {additionalFilter}
          <Search dispatch={dispatch} />
        </div>
      )}
      <TableKA
        {...tableProps}
        data={[...data]}
        dispatch={dispatch}
        childComponents={{
          tableWrapper: {
            elementAttributes: () => ({
              className: classNames(getUnionClass("table-wrapper"))
            })
          },
          table: {
            elementAttributes: () => ({
              className: getUnionClass("table")
            })
          },
          // TODO: Find method for hide thead without styles
          tableHead: {
            elementAttributes: () => {
              return {
                className: !hideHeader ? getUnionClass("head") : tableStyles["head-hide"]
              };
            },
            ...(hideHeader
              ? {
                  content: props => {
                    return (
                      <tr>
                        <th />
                      </tr>
                    );
                  }
                }
              : {})
          },
          tableBody: {
            elementAttributes: () => ({
              className: getUnionClass("body")
            })
          },
          headRow: {
            elementAttributes: () => ({
              className: classNames(getUnionClass("row"), getUnionClass("head-row"))
            })
          },
          headCell: {
            elementAttributes: ({ column }) => {
              return {
                className: classNames(
                  getCellClass(column as TableColumn<T>),
                  sorting && getUnionClass("head-cell-sort-mode")
                )
              };
            }
          },
          headCellContent: {
            content: props => {
              const tableProps: TableColumn<T> = {
                ...props.column
              } as TableColumn<T>;
              if (tableProps.headCellComponent) {
                return <tableProps.headCellComponent props={props} />;
              }
            },
            elementAttributes: ({ column }) => ({
              className: classNames(
                getUnionClass("head-cell"),
                column.sortDirection && getUnionClass("head-cell-sorted")
              )
            })
          },
          dataRow: {
            elementAttributes: data => ({
              className: classNames(getUnionClass("row"), getUnionClass("data-row"), rowStyler && rowStyler(data))
            })
          },
          cell: {
            elementAttributes: ({ column }) => ({
              className: classNames(getCellClass(column as TableColumn<T>), getUnionClass("cell"))
            })
          },
          cellText: {
            content: props => {
              if ((props.column as TableColumn<T>).dataType === DataType.Number) {
                (props.column as TableColumn<T>).maxValue = Math.max(
                  ...(data?.map(item => (item as any)[props.column.key]) || []),
                  0
                );
              }
              const cellProps: TableColumn<T> = {
                ...props.column
              } as TableColumn<T>;
              let content = null;
              if (cellProps.key === "") {
                indexItem += 1;
                content = indexItem;
              } else if (cellProps.component) {
                content = checkComponentType({
                  Component: cellProps.component,
                  props
                });
              } else if (cellProps.decorator) {
                content = cellProps.decorator(props);
              }
              if (
                detailRows &&
                cellProps.expandCell &&
                !checkExpandData<T>(props.rowData[detailRows.dataProperty], detailRows.dataProperty)
              ) {
                return <DetailsButton {...props}>{content ?? props.value}</DetailsButton>;
              } else if (content !== null) {
                return content;
              }
            },
            elementAttributes: ({ column }) => {
              return {
                className: classNames(
                  getUnionClass("cell-inner"),
                  column.sortDirection && modStyles?.["cell-inner-sorted"]
                )
              };
            }
          },
          ...(detailRows &&
            detailRows.component && {
              detailsRow: {
                content: props => {
                  return checkExpandData<T>(
                    props.rowData[detailRows.dataProperty],
                    detailRows.dataProperty
                  ) ? undefined : (
                    // @ts-ignore
                    <detailRows.component {...props} />
                  );
                  // : checkComponentType({
                  //     Component: detailRows.component,
                  //     props,
                  //   });
                },
                elementAttributes: props => {
                  return checkExpandData<T>(props.rowData[detailRows.dataProperty], detailRows.dataProperty)
                    ? undefined
                    : {
                        className: classNames(getUnionClass("row"), getUnionClass("details-row"))
                      };
                }
              }
            }),
          pagingPages: {
            elementAttributes: () => {
              return {
                className: tableStyles?.pagination
              };
            }
          },
          pagingIndex: {
            elementAttributes: data => {
              return {
                className: classNames(tableStyles?.["pagination-item"], data.isActive && "text-warning")
              };
            }
          }
        }}
      />
    </>
  );
};

export const Table = React.memo(TableWrapper) as typeof TableWrapper;
