import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import tabs from "@styles/modules/tabs.module.scss";
import { DetailsTab, ScopeTab, SkillsTab, RoleTab } from "./tabs";
import { EditPersonInterface } from "@common/types/reports/profile/profile";
import { SendingManager, UserModalProps } from "@common/types/managment/edit-person";
import { PersonProfile } from "..";
import { $RoleInfoStore, RoleInfo } from "../model";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import modalStyles from "@styles/modules/complex-modal.module.scss";
import { Accountabilities } from "@features/accountabilities";
import { AdditionalReportManager } from "@features/accountabilities/ui/additional-report-manager";

interface Props extends SendingManager, UserModalProps {
  tabIndex: number;
  personProfile: PersonProfile;
  setPersonProfile: (data: PersonProfile) => void;
}

export const UserInfoContent = observer(
  ({ tabIndex, personProfile, setPersonProfile, isEditModal, ...anotherProperties }: Props) => {
    useEffect(() => {
      if (personProfile?.id) {
        $RoleInfoStore.updateRoleInfo(personProfile.id);
      }
    }, [personProfile?.id]);
    const roleInfo = $RoleInfoStore.getRoleInfo();
    return (
      <Tabs
        selectedIndex={tabIndex}
        className={tabs.tabs}
        selectedTabClassName={tabs["tab-selected"]}
        onSelect={() => console.log("select")}
      >
        <TabList className={tabs["tab-hidden"]}>
          <Tab className={tabs["tab-hidden"]} />
          {personProfile?.id && <Tab className={tabs["tab-hidden"]} />}
          {roleInfo && <Tab className={tabs["tab-hidden"]} />}
          {roleInfo && <Tab className={tabs["tab-hidden"]} />}
          {roleInfo && personProfile && <Tab className={tabs["tab-hidden"]} />}
        </TabList>
        <TabPanel className={tabs.panel}>
          <DetailsTab
            setPersonProfile={setPersonProfile}
            isEditModal={isEditModal}
            {...(personProfile && { personProfile })}
            {...anotherProperties}
          />
        </TabPanel>
        {personProfile?.id && (
          <TabPanel className={tabs.panel}>
            <RoleTab
              memberId={personProfile.id}
              {...(roleInfo && { role: roleInfo })}
              managerId={0}
              {...anotherProperties}
            />
          </TabPanel>
        )}
        {roleInfo && (
          <TabPanel className={tabs.panel}>
            <SkillsTab roleId={roleInfo?.role?.id} />
          </TabPanel>
        )}
        {roleInfo && (
          <TabPanel className={tabs.panel}>
            <ScopeTab jobPurpose={roleInfo?.role?.job_purpose} roleId={roleInfo?.role?.id} />
          </TabPanel>
        )}
        {roleInfo && personProfile?.id && (
          <TabPanel className={tabs.panel}>
            <div className={modalStyles.row}>
              <Accountabilities
                roleId={roleInfo.role.id}
                profileId={personProfile.id}
                legendComponent={
                  <AdditionalReportManager name={personProfile.full_name} profileId={personProfile.id} />
                }
              />
            </div>
          </TabPanel>
        )}
      </Tabs>
    );
  }
);
