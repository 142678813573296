import { TableCellProps } from "@common/types/table";
import { useContext, useState } from "react";
import { UserInfoModal } from "@features/people";
import styles from "./user-people.module.scss";
import { observer } from "mobx-react-lite";
import { Roles } from "@common/constants";
import { GlobalStore } from "@common/api/store/global";

interface PeopleUserProps extends TableCellProps {}

export const PeopleUser: React.FC<PeopleUserProps> = observer(({ props }) => {
  const { $profileStore } = useContext(GlobalStore);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { rowData } = props;
  return (
    <>
      {rowData.is_editable || [Roles.superAdmin, Roles.clientAdmin].includes($profileStore.currentRoleId) ? (
        <>
          <div className={styles.user}>
            <div className={styles.name} onClick={() => setIsOpen(true)}>
              {rowData.full_name}
            </div>
          </div>
          {isOpen && (
            <UserInfoModal closeModal={() => setIsOpen(false)} isOpen={isOpen} props={props} isEditModal={true} />
          )}
        </>
      ) : (
        rowData.full_name
      )}
    </>
  );
});
