import { getPhrases } from "@common/helpers/localization";
import { MyProfileInterface } from "@common/types/reports/profile/profile";
import * as yup from "yup";

const loc = getPhrases("common", "forms").validation;

// TODO: Needed work with declination in localization

export const ProfileFormSchema: yup.SchemaOf<MyProfileInterface> = yup.object().shape({
  first_name: yup.string().required(loc.required),
  middle_name: yup.string(),
  last_name: yup.string().required(loc.required),
  timezone_id: yup.number().required(loc.required),
  industry_experience: yup
    .number()
    .min(1, "Please enter the industry experience. (If you have less then 1 year, please enter 1)")
    .required(loc.required)
});
