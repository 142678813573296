import { ScoreDataProps } from "@common/types/reports/skills/skills-employee";
import { SkillsOptics } from ".";

export const formatSkillsData = (data: ScoreDataProps[]): SkillsOptics[] => {
  return data.reduce((previous: SkillsOptics[], current) => {
    return [
      ...previous,
      ...current.skills.reduce((previousSkills: SkillsOptics[], currentSkills) => {
        return [
          ...previousSkills,
          {
            id: currentSkills.id,
            name: currentSkills.name,
            is_active: currentSkills.is_active,
            manager_score: currentSkills.manager_score ? currentSkills.manager_score * 10 : 0,
            self_score: currentSkills.self_score ? currentSkills.self_score * 10 : 0,
            peer_score: currentSkills.peer_score ? currentSkills.peer_score * 10 : 0,
            avg_score: currentSkills.avg_score * 10,
            // @ts-ignore
            avg_target: currentSkills.avg_target * 10
          }
        ];
      }, [])
    ];
  }, []);
};
