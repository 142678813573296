import React, { useContext } from "react";
import { GlobalStore } from "@common/api/store/global";
import { observer } from "mobx-react-lite";
import { Svg } from "@ui";

export const SpinnerContainer = observer(() => {
  const { $commonStore } = useContext(GlobalStore);

  return (
    <>
      {$commonStore.loadingIds.length > 0 && (
        <div className="fixed top-0 left-0 z-max w-screen h-screen bg-white bg-opacity-80 flex-center">
          <Svg name="logo-new" modClass="text-primary w-15 h-15 animate-spin" />
        </div>
      )}
    </>
  );
});
