import { WithIntegerId } from "@common/types/common";

export type AssessmentType = "Manager Assessment" | "Peer Assessment" | "Self Assessment";

export interface AssessmentTypeInterface extends WithIntegerId {
  name: AssessmentType;
}

export enum AssessmentTypeEnum {
  "SelfAssessment" = 1,
  "PeerAssessment" = 2,
  "ManagerAssessment" = 3
}
