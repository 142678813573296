import React from "react";
import ReactModal from "react-modal";
import styles from "./short-modal.module.scss";
import { getDefaultProps } from "./helpers";
import { ModalProps } from "./types";
import classNames from "classnames";

interface Props extends ModalProps {}

export const PureModal: React.FC<Props> = ({ isOpen, closeModal, overlayClassName, children, ...props }) => {
  const expandProps = { ...getDefaultProps(), ...props };
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={styles.empty}
      portalClassName={styles.empty}
      {...expandProps}
      overlayClassName={classNames(expandProps.overlayClassName, overlayClassName)}
    >
      {children}
    </ReactModal>
  );
};
