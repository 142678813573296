import classNames from "classnames";
import styles from "./list.module.scss";

export interface ListItem {
  label: string;
  value: string | React.ReactNode;
}

interface ListProps {
  list: ListItem[];
  modClass?: string;
}

export const List = ({ list, modClass }: ListProps) => {
  if (!list.length) {
    return null;
  }
  return (
    <div className={classNames(styles.block, modClass)}>
      {list.map((item, index) => {
        return (
          <div className={styles.row} key={`list-item-${index}`}>
            <div className={styles.label}>{item.label}:</div>
            <div className={styles.value}>{item.value}</div>
          </div>
        );
      })}
    </div>
  );
};
