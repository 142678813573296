import { getPhrases } from "@common/helpers/localization";

const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december"
];

const days = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

export const getExtendData = (time: number): string => {
  const loc = getPhrases();
  const date = new Date(time);
  const month = loc["months"]?.[`${months[date.getMonth()]}_short`] ?? "";
  return `${month} ${date.getDate()} ${date.getFullYear()}`;
};

export const getCalendarData = (time: number): string => {
  const date = new Date(time);
  return `${checkDigit(date.getDate())}.${checkDigit(date.getMonth() + 1)}.${date.getFullYear()}`;
};

export const checkDigit = (value: number) => {
  return `${value < 10 ? "0" : ""}${value}`;
};

export const getOnlyDateFormat = (date: string): string => {
  const dateObject = new Date(date);
  return `${dateObject.getFullYear()}-${checkDigit(dateObject.getMonth() + 1)}-${checkDigit(dateObject.getDate())}`;
};
