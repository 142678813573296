import { TableProps } from "@common/types/table";
import { Table } from "@ui";
import categoryTableStyles from "./category-table.module.scss";
import defaultTheme from "./themes/category-table-default.module.scss";
import orangeTheme from "./themes/category-table-orange.module.scss";

// TODO: Move types, interfaces and functions to abstract

type TableTheme = "orange" | "default";
interface Props<T> extends TableProps<T> {
  theme?: TableTheme;
}

function getTheme(theme: TableTheme) {
  switch (theme) {
    case "orange":
      return orangeTheme;
    default:
      return defaultTheme;
  }
}

export function CategoryTable<T>({ theme = "default", ...props }: Props<T>) {
  // TODO: think about types for classes

  const tableClass: any = {};

  // TODO: think about memo for getTheme

  const themeClass: any = getTheme(theme);

  for (let key in themeClass) {
    tableClass[key] = `${tableClass[key] ? `${tableClass[key]} ` : ""}${themeClass[key]}`;
  }
  for (let key in categoryTableStyles) {
    tableClass[key] = `${tableClass[key] ? `${tableClass[key]} ` : ""}${categoryTableStyles[key]}`;
  }
  for (let key in props.modStyles) {
    tableClass[key] = `${tableClass[key] ? `${tableClass[key]} ` : ""}${props.modStyles[key]}`;
  }
  const modStyles = { ...tableClass };
  return <Table<T> {...{ ...props, modStyles }} />;
}
