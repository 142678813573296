import React, { FC, useMemo, useState } from "react";
import { usePopper } from "react-popper";
import "./status-tooltip-header.scss";
import classNames from "classnames";

type RefType = HTMLDivElement | null;
interface KpiTooltipProps {
  title: string;
  description: string;
  className?: string;
}

export const StatusTooltipHeader: FC<KpiTooltipProps> = ({ className = "" }) => {
  const [referenceElement, setReferenceElement] = useState<RefType>();
  const [popperElement, setPopperElement] = useState<RefType>();

  const offsetModifier = useMemo(
    () => ({
      name: "offset",
      options: {
        // @ts-ignore
        offset: ({ placement, reference, popper }) => {
          return [popper.width + 15, -reference.height / 2 - popper.height / 2];
        }
      }
    }),
    []
  );

  const { styles: stylesBlock, attributes: attributesBlock } = usePopper(referenceElement, popperElement, {
    modifiers: [offsetModifier],
    placement: "top-end"
  });

  return (
    <div className={classNames("status-header-wrapper", className)} ref={setReferenceElement}>
      <div className="flex item-center">
        Status
        <div className="w-3 h-3 mt-[2px] ml-2 pt-[1px] flex justify-center items-center text-[8px] rounded-full border border-solid border-black font-bold cursor-pointer select-none">
          ?
        </div>
      </div>
      <div
        className="w-96 detail flex flex-col"
        ref={setPopperElement}
        style={stylesBlock.popper}
        {...attributesBlock.popper}
      >
        <p className="flex text-xs">
          <span className="min-w-10 mr-2 text-success">Active:</span>
          <span>
            The person is currently working for the company and has already activated the account in the platform.
          </span>
        </p>
        <p className="mt-4 flex text-xs">
          <span className="min-w-10 mr-2 text-warning">Active:</span>
          <span>
            The person is currently working for the company but has not activated the account in the platform yet.
          </span>
        </p>
        <p className="mt-4 flex text-xs">
          <span className="min-w-10 mr-2 text-danger">Inactive:</span>
          <span>The person is not working for the company. The account in the platform is not active.</span>
        </p>
      </div>
    </div>
  );
};
