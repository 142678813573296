import React, { MouseEventHandler, ReactElement, useEffect, useState } from "react";
import classNames from "classnames";
import { Button, ButtonProps } from "@ui";
import styles from "./dropdown.module.scss";

export interface DropdownProps {
  className?: string;
  button?: ReactElement;
  buttonProps?: ButtonProps;
  options: any[];
  getOptionLabel?: (option: any) => string | ReactElement;
  onSelectOption?: (option: any) => void;
}

export const Dropdown: React.FC<DropdownProps> = ({
  className,
  button,
  buttonProps,
  options,
  getOptionLabel,
  onSelectOption
}) => {
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const wrapperClass = classNames(styles.wrapper, dropdownOpened && styles["opened"], className);

  useEffect(() => {
    const clickListener = () => {
      setDropdownOpened(false);
    };
    window.addEventListener("click", clickListener);

    return () => {
      window.removeEventListener("click", clickListener);
    };
  }, []);

  const onToggleDropdown: MouseEventHandler = e => {
    e.preventDefault();
    e.stopPropagation();
    setDropdownOpened(opened => !opened);
  };

  const onSelect = (option: any) => {
    setDropdownOpened(false);
    if (onSelectOption) {
      onSelectOption(option);
    }
  };

  return (
    <div className={wrapperClass}>
      <div onClick={onToggleDropdown}>{button || <Button {...buttonProps} />}</div>
      <div className={styles.dropdown}>
        {options.map((option, i) => {
          const label = getOptionLabel ? getOptionLabel(option) : option;
          return (
            <div key={i} className={styles["dropdown-item"]} onClick={() => onSelect(option)}>
              {label}
            </div>
          );
        })}
      </div>
    </div>
  );
};
