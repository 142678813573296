import { getResponsibilitiesService, postResponsibilitiesService } from "@common/api/services/responsibilities";
import { Responsibility } from "@common/types/responsibilities/responsibilities";

export const getResponsibilities = async (roleId: number, callback = (value: Responsibility[]) => {}) => {
  return getResponsibilitiesService<Responsibility>(`/client/organization/roles/${roleId}/responsibilities`).then(
    response => {
      callback(response.data.data);
      return response.data.data;
    }
  );
};

export const setResponsibilities = (id: number, responsibilities: Omit<Responsibility, "id">[]) => {
  return postResponsibilitiesService<Responsibility>(`/client/organization/roles/${id}/responsibilities`, {
    responsibilities
  }).then(response => response.data.data);
};
