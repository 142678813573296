import React from "react";
import classNames from "classnames";
import styles from "./checkbox-with-desc.module.scss";
import { Checkbox } from "@ui";

type Props = {
  isChecked: boolean;
  onClick?: () => void;
  desc: string;
};

export const CheckboxWithDesc = ({ isChecked, desc, onClick }: Props) => {
  return (
    <div onClick={onClick} className={classNames(styles["checkbox-with-desc"])}>
      {/*   checkbox  */}
      <Checkbox className={styles["checkbox"]} value={isChecked} />
      <div className={classNames(styles["checkbox-text"])}>{desc}</div>
    </div>
  );
};
