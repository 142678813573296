import React, { ReactNode } from "react";
import styles from "./text-with-icon.module.scss";

interface Props {
  description: ReactNode;
  text: string;
}

export const TextWithDescription: React.FC<Props> = ({ description, text }) => {
  return (
    <div className={styles["text-with-icon"]}>
      <div className={styles.title}>{text}:</div>
      <div className={styles.text}>{description}</div>
    </div>
  );
};
