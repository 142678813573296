import { useCallback, useEffect, useMemo, useState } from "react";
import { getFavorites } from "@common/features/learn/model";
import { CourseItemInterface } from "@common/types/reports/learn/learn";
import { Carousel } from "@features/learn/carousels/carousel";
import { Course } from "@features/learn/types";
import { getReducedData } from "../helpers";
import { CarouselContent } from "../types";

export const Favorites = (props: CarouselContent) => {
  const [data, setData] = useState<CourseItemInterface[]>();

  const refresh = useCallback(() => getFavorites(setData), []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const editedData: Course[] = useMemo(() => {
    return data?.length ? getReducedData(data) : [];
  }, [data]);

  return <Carousel CoursesData={editedData} isFavouriteCoursesPage={true} refresh={refresh} {...props} />;
};
