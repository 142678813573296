import { TableCellProps } from "@common/types/table";
import classNames from "classnames";
import styles from "./user-status-cell.module.scss";

interface UserStatusCellInterface extends TableCellProps {}

export const UserStatusCell = ({ props }: UserStatusCellInterface) => {
  const { value, rowData } = props;

  return (
    <div
      className={classNames(
        styles[value ? (rowData.is_user_activated ? "activated" : "active") : "deleted"],
        styles.status
      )}
    >
      {value ? "Active" : "Inactive"}
    </div>
  );
};
