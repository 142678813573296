import { GlobalStore } from "@common/api/store/global";
import { checkAuth } from "@common/features/user/model";
import { UIPage } from "@pages";
import { observer } from "mobx-react-lite";
import { useContext, useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { AccountActivationPage } from "../pages/account-activation";
import { Auth, ProtectedRoute, ResetPassword } from "./stacks";

const NotFoundPage = () => <div>Page not found</div>;

export const RoutesList = observer(() => {
  const { $userStore, $profileStore } = useContext(GlobalStore);
  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    if ($userStore.isAuth) {
      localStorage.setItem("currentRoleId", $profileStore.getCurrentRoleId().toString());
    } else {
      localStorage.removeItem("currentRoleId");
    }
  }, [$userStore.isAuth, $profileStore.getCurrentRoleId()]);

  // useEffect(() => {
  //   if ($userStore.getCurrentRoleId()) {
  //     navigate($userStore.getRootPage(), { replace: false });
  //   }
  // }, [$userStore.getCurrentRoleId()]);
  // TODO: think about auth routes
  if (!$userStore.isAuthChecked) {
    return null;
  }
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route
        path="/login/*"
        element={
          $userStore.isAuth && $profileStore.currentRoleId ? (
            <Navigate to={$profileStore.getRootPage()} replace />
          ) : (
            <Auth />
          )
        }
      />
      <Route
        path="/reset-password/*"
        element={$userStore.isAuth ? <Navigate to={$profileStore.getRootPage()} replace /> : <ResetPassword />}
      />
      <Route path="/account-activation/*" element={<AccountActivationPage />} />

      {$userStore.isAuthChecked && (
        <>
          <Route path="/dev" element={$userStore.isAuth ? <UIPage /> : <Navigate to="/login" />} />
          <Route path="/*" element={<ProtectedRoute />} />
        </>
      )}
      <Route path="*" element={$userStore.isAuth ? <NotFoundPage /> : <Navigate to="/login" />} />
    </Routes>
  );
});
