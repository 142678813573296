import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import styles from "./avatar.module.scss";
import { PeopleProfileInterface } from "@common/types/reports/profile/profile";
import classNames from "classnames";
import { ComplexModal, Svg } from "@ui";
import { ChangeEvent, useCallback, useContext, useRef, useState } from "react";
import { getCroppedImg, readFile } from "@common/helpers/canvas-helpers";
import { GlobalStore } from "@common/api/store/global";

interface Props {
  id?: number;
  selectedProfile?: PeopleProfileInterface;
  setSavingImage?: (image: File) => void;
}

export const Avatar = ({ id, selectedProfile, setSavingImage }: Props) => {
  const { $profileStore } = useContext(GlobalStore);
  const companySetting = $profileStore.getCompanySetting();
  const [croppedImage, setCroppedImage] = useState("");
  const [isEditLogo, setIsEditLogo] = useState(false);
  const [imageSrc, setImageSrc] = useState<any>(null);
  const [crop, setCrop] = useState<Crop | undefined>();
  const fileInput = useRef<HTMLInputElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const showCroppedImage = useCallback(async () => {
    try {
      const image = await getCroppedImg(imageSrc, crop, 0);
      if (image) {
        const { url: croppedImage, file } = image;
        setCroppedImage(croppedImage);
        const imageFile = new File([file], "newPhoto.jpg", {
          type: "image/jpg"
        });
        setSavingImage && setSavingImage(imageFile);
      }
    } catch (e) {
      console.error(e);
    }
    setIsEditLogo(false);
  }, [imageSrc, crop]);

  const onClickSelectFile = () => {
    fileInput.current?.click();
  };

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);

      await new Promise(res => setTimeout(() => res(""), 500));
      if (imageRef.current) {
        const { width, height } = imageRef.current;
        const minLength = Math.min(width, height);
        if (minLength > 300) {
          setCrop({
            width: 300 / (width / 100),
            x: (width - 300) / (width / 100) / 2,
            height: 300 / (height / 100),
            y: (height - 300) / (height / 100) / 2,
            unit: "%"
          });
        } else {
          setCrop({
            width: minLength / (width / 100),
            x: (width - minLength) / (width / 100) / 2,
            height: minLength / (height / 100),
            y: (height - minLength) / (height / 100) / 2,
            unit: "%"
          });
        }
      }
    }
  };

  const onCloseLogoEdit = useCallback(() => {
    setIsEditLogo(false);
    setCroppedImage("");
  }, []);

  return (
    <>
      <div className={styles.wrapper}>
        {/* Click here to upload a picture of the User */}
        {/*<div*/}
        {/*  className={styles.image}*/}
        {/*  style={{ backgroundImage: `url(${selectedProfile?.avatar_url ? selectedProfile?.avatar_url  : profile?.company?.logo_url})` }}*/}
        {/*/>*/}
        <div className={classNames(styles["logo-block"])}>
          {!id && (
            <span className={styles["logo-edit-btn"]} onClick={() => setIsEditLogo(true)}>
              <Svg name="edit" />
            </span>
          )}
          <span className={styles.backdrop} />
          <div
            className={styles.logo}
            style={{
              backgroundImage: `url(${croppedImage || selectedProfile?.avatar_url || companySetting?.logo_url || ""})`
            }}
          />
        </div>
      </div>
      <ComplexModal
        isOpen={isEditLogo}
        closeModal={onCloseLogoEdit}
        bodyClass="py-0"
        modClass="w-[400px]"
        shouldCloseOnOverlayClick={false}
        headerTitle="Upload photo"
        headerClass="pb-1"
        buttons={{
          leftSide: {
            title: "Open File",
            theme: "blue",
            className: "!w-30 !h-10 !text-sm !text-left !font-semibold",
            onClick: onClickSelectFile
          },
          rightSide: [
            {
              title: <span>Save & Upload</span>,
              theme: "orange",
              className: "!w-30 !h-10 !text-sm !text-left !font-semibold",
              disabled: !imageSrc,
              onClick: showCroppedImage
            }
          ]
        }}
      >
        <input ref={fileInput} type="file" className="hidden" onChange={onFileChange} />
        <ReactCrop
          className={styles["logo-crop-container"]}
          renderSelectionAddon={() => <div className={styles["logo-crop-selection"]} />}
          aspect={1}
          crop={crop}
          keepSelection
          circularCrop
          disabled={!imageSrc}
          onChange={(c, pc) => setCrop(pc)}
          onComplete={(c, pc) => setCrop(pc)}
        >
          {imageSrc ? (
            <img ref={imageRef} src={imageSrc} className="w-full" />
          ) : (
            <span className={styles.center}>Please select image file</span>
          )}
        </ReactCrop>
      </ComplexModal>
    </>
  );
};
