import React, { FC, useCallback, useMemo, useState } from "react";
import { TableCellProps } from "@common/types/table";
import styles from "./area-action.module.scss";
import { AlertModal, Button, Titles } from "@ui";
import { getReportOffices, removeArea, removeOffice } from "@common/features/offices/model";
import { $commonStore } from "@common/api/store/common";
import { BannerType } from "@common/api/types/common";

interface AreaActionProps extends TableCellProps {}

export const AreaAction: FC<AreaActionProps> = ({ props }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { rowData } = props;
  const isArea = useMemo(() => Object.keys(rowData).includes("offices"), [rowData]);

  const toggleModal = useCallback(() => {
    setIsOpen(isOpen => !isOpen);
  }, []);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSubmit = useCallback(() => {
    if (isArea) {
      removeArea(rowData.id)
        .then(() => {
          getReportOffices();
          $commonStore.setBanner(BannerType.success, `The area ${rowData.name} has been deleted.`);
        })
        .catch(() =>
          $commonStore.setBanner(
            BannerType.error,
            `The area ${rowData.name} cannot be deleted, because some other entities are attached to it. Please check with our support if needed.`
          )
        )
        .finally(() => setIsOpen(false));
    } else {
      removeOffice(rowData.id)
        .then(() => {
          getReportOffices();
          $commonStore.setBanner(BannerType.success, `The office ${rowData.name} has been deleted.`);
        })
        .catch(() =>
          $commonStore.setBanner(
            BannerType.error,
            `The office ${rowData.name} cannot be deleted, because some other entities are attached to it. Please check with our support if needed.`
          )
        )
        .finally(() => setIsOpen(false));
    }
  }, [isArea, rowData]);

  return (
    <>
      <div className={styles.close} onClick={toggleModal} />
      {isOpen && (
        <AlertModal closeModal={toggleModal} isOpen>
          <div className={styles.buttons}>
            <Titles level="level_3" modClass="text-warning/80">
              You are about to delete an {isArea ? "area" : "office"}. Are you sure to delete{" "}
              <span className="text-warning">{rowData.name}</span>?
            </Titles>
            <div className="flex justify-end">
              <Button className="w-30" title="Cancel" theme="gray" onClick={closeModal} />
              <Button className="ml-4 w-30" title="Delete" theme="orange" onClick={handleSubmit} />
            </div>
          </div>
        </AlertModal>
      )}
    </>
  );
};
