import { FormattedEmployeeList, PeopleSubordinates } from "@common/types/reports/people/people";
import { SecondLevelTable } from "@ui";
import styles from "./../employees-table.module.scss";
import { employeeColumns } from "../model";
import { createEmployeeTableData } from "../helpers";

interface Props {
  data: PeopleSubordinates[];
}

export const LastLevel = ({ data }: Props) => {
  const finalData: FormattedEmployeeList[] = createEmployeeTableData(data);

  return SecondLevelTable<FormattedEmployeeList>({
    data: finalData,
    columns: employeeColumns,
    modStyles: styles
  });
};
