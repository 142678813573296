import classNames from "classnames";
import { hideDetailsRow, showDetailsRow } from "ka-table/actionCreators";
import { ICellTextProps } from "ka-table/props";
import styles from "./details-button.module.scss";

export const DetailsButton: React.FC<ICellTextProps> = ({
  dispatch,
  rowKeyValue,
  isDetailsRowShown,
  value,
  children
}) => {
  return (
    <div
      className={styles.expand}
      onClick={() => {
        dispatch(isDetailsRowShown ? hideDetailsRow(rowKeyValue) : showDetailsRow(rowKeyValue));
      }}
    >
      <div
        className={
          isDetailsRowShown
            ? classNames(styles["expand-icon"], styles["expand-hide"])
            : classNames(styles["expand-icon"], styles["expand-show"])
        }
      />
      {children}
    </div>
  );
};
