import { $api } from "@common/api/http";

export const getListCompanies = async (params: Record<string, unknown>, callback?: any) => {
  try {
    const response = await $api.get("/admin/organization/companies", {
      params
    });
    callback && callback(response.data);
  } catch (e) {
    console.error("Get list companies error");
  }
};
