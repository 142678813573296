import React from "react";
import classNames from "classnames";
import { LearningResourceCard, Titles } from "@ui";
import styles from "./related-resource-card.module.scss";
import { LearningResource } from "@common/types/reports/learn/learning-resource";
import Grid from "@styles/modules/grid.module.scss";
import { CourseCard } from "@features/learn/carousels/carousel/CourseCard";

interface Props {
  title: string;
  cardsData: LearningResource[];
}

export const RelatedResourceCard: React.FC<Props> = ({ title, cardsData }) => {
  return (
    <div className={styles["related-learning-resources"]}>
      <Titles level={"level_3"} modClass={styles.title}>
        {title}
      </Titles>
      <div className={classNames(Grid.list)}>
        {cardsData
          ?.map((data: any) => ({
            id: data.id,
            isFree: data.is_free,
            rating: data.rating_average || 0,
            link: data.id,
            authorImgSrc: data.provider?.logo_image_url,
            authorName: data.provider?.name,
            category: data.provider?.name,
            difficulty: data.expertise_level.name,
            duration: data.duration,
            heroImgSrc: data.image_url,
            // todo - add this property after completing
            isCompleted: false,
            isFavourite: data.is_favourite,
            // title: data.short_description || "",
            title: data.title || "",
            totalRatings: data.rating_count || 0,
            type: data?.content_type?.id,
            progress: data?.progress,
            ...(data?.url && { url: data.url })
          }))
          .map(({ id, ...data }: any) => (
            <div key={id} className={classNames(Grid["item-3x"], "p-1")}>
              <div className={styles.gridCourseCard}>
                <CourseCard id={id} {...data} />
              </div>
            </div>
          ))}
      </div>

      {/*     All the related learning resources      */}
      <div className={classNames(styles["resources"], "text-blue")}>
        {(!cardsData || !cardsData?.length) && "No related resources"}
      </div>
    </div>
  );
};
