import { AssessmentFormResult, AssessmentFormSetterValue } from "@common/types/forms/assessments-employee";
import { makeAutoObservable, toJS } from "mobx";
import { ClassCommon } from "./../types/common";

export class Assessments implements ClassCommon {
  results: AssessmentFormResult | null = null;
  finishPercent: number = 0;
  currentAssessmentCategory: string | null = null;
  assessmentCategorySetter: AssessmentFormSetterValue | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  addResult(id: string, value: number) {
    if (this.results) {
      this.results.results = { ...this.results.results, ...{ [id]: value } };
    } else {
      this.results = {
        results: {
          [id]: value
        }
      };
    }
    this.calcPercent();
  }
  setResults(results: AssessmentFormResult) {
    this.results = results;
  }
  getResults() {
    if (this.results) {
      return toJS(this.results);
    }
  }
  getResultById(id: string) {
    if (this.results?.results) {
      return this.results.results[id];
    }
  }
  calcPercent() {
    if (!this.results?.results) {
      this.finishPercent = 0;
    } else {
      const results = Object.values(this.results.results);
      const settedResults = results.filter(item => item);
      this.finishPercent = Math.round(settedResults.length / (results.length / 100));
    }
  }
  getPercent() {
    return this.finishPercent;
  }
  setAssessmentCategorySetter(category: string, value: number | null) {
    if (this.assessmentCategorySetter) {
      this.assessmentCategorySetter = {
        ...this.assessmentCategorySetter,
        [category]: value
      };
    } else {
      this.assessmentCategorySetter = { [category]: value };
    }
  }

  getAssessmentCategorySetter() {
    return this.assessmentCategorySetter;
  }

  getAssessmentCategorySetterById(id: string) {
    const value = this.assessmentCategorySetter?.[id];
    if (value !== undefined && value !== null) {
      return value;
    } else {
      return null;
    }
  }

  setCurrentAssessmentCategory(value: string) {
    this.currentAssessmentCategory = value;
  }

  getCurrentAssessmentCategory() {
    return this.currentAssessmentCategory;
  }

  reset(): void {
    this.results = null;
    this.finishPercent = 0;
    this.currentAssessmentCategory = null;
    this.assessmentCategorySetter = null;
  }
}

export const $assessmentsStore = new Assessments();
