import { getWorkTypesService } from "@common/api/services/work-types";
import { $workTypesStore } from "@common/api/store/work-types";
import { CommonWithId } from "@common/types/common";

export const getWorkTypes = async (callback: any) => {
  try {
    const response = await getWorkTypesService<CommonWithId>("/client/organization/work-types");
    $workTypesStore.setTypes(response.data.data);
    callback(response.data.data);
  } catch (e) {
    console.error("Get work types error");
  }
};
