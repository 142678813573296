import { getDeclensions } from "@common/helpers/string-helpers";
import { FormattedEmployeeList } from "@common/types/reports/people/people";
import { TableCellProps, TableColumn } from "@common/types/table";
import styles from "./employees-table.module.scss";
import { UserStatusCell } from "@ui";
import classNames from "classnames";
import { DataType } from "ka-table/enums";
import { ICellTextProps } from "ka-table/props";
import { PropsWithChildren } from "react";
import { AverageScore } from "./progress";
import { PeopleReport } from "./report";
import { PeopleUser } from "../user";
import { StatusTooltipHeader } from "@features/people/status-tooltip-header";

const getYears = (data: PropsWithChildren<ICellTextProps>): string => {
  return +data.value ? `${data.value} ${getDeclensions(data.value, "year", "years")}` : "<1 year";
};

const UserName = ({ props }: TableCellProps): JSX.Element => {
  return <PeopleUser props={props} />;
};

export const employeeColumns: TableColumn<FormattedEmployeeList>[] = [
  {
    key: "is_active",
    title: "Status",
    dataType: DataType.Boolean,
    modClass: classNames(styles.item, styles["item-status"]),
    component: UserStatusCell,
    headCellComponent: StatusTooltipHeader
  },
  {
    key: "full_name",
    title: "Full Name",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["item-name"]),
    component: UserName
  },
  {
    key: "reports_to",
    title: "Reports to",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["item-reports"])
  },
  {
    key: "role",
    title: "Role",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["item-role"])
  },
  {
    key: "industry_experience",
    title: "Industry Experience",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["item-industry"]),
    decorator: data => getYears(data)
  },
  {
    key: "company_experience",
    title: "Company Tenure",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["item-company"]),
    decorator: data => getYears(data)
  },
  {
    key: "office",
    title: "Office",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["item-employed"])
  },
  {
    key: "expertise",
    title: "Expertise",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["item-expertise"])
  },
  {
    key: "skill_gap",
    title: "Skill Gap",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["item-gap"]),
    component: AverageScore
  },
  {
    key: "report",
    title: "Report",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["item-report"]),
    component: PeopleReport
  }
];
