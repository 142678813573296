import type { InvalidAuthResponse, UserInfo } from "@common/api/types/response/auth";
import { makeAutoObservable, toJS } from "mobx";
import { ClassCommon } from "../types/common";

export class User implements ClassCommon {
  isAuthChecked: boolean = false;
  isFirstLogin: boolean = false;
  isAuth: boolean = false;
  userInfo: UserInfo | null = null;
  invalidAuth: InvalidAuthResponse | null = null;
  skillUpdated: boolean = false;
  domainName: string = "Innovorg";

  constructor() {
    makeAutoObservable(this);
  }

  setAuth(status: boolean) {
    this.isAuth = status;
  }

  setInvalidAuth(result: InvalidAuthResponse | null) {
    this.invalidAuth = result;
  }

  setIsFirstLogin(status: boolean) {
    this.isFirstLogin = status;
  }

  setAuthChecked(status: boolean) {
    this.isAuthChecked = status;
  }

  setUserInfo(info: UserInfo) {
    this.userInfo = info ? { ...info } : null;
  }

  setDomainName(domainName?: string) {
    this.domainName = domainName || "Innovorg";
  }

  getUserRoleId() {
    return Number(toJS(this.userInfo?.role.id));
  }

  getUserInfo() {
    return toJS(this.userInfo);
  }
  setSkillUpdate(status = true) {
    this.skillUpdated = status;
  }
  getSkillUpdate() {
    return this.skillUpdated;
  }

  reset(): void {
    this.userInfo = null;
    this.isAuthChecked = false;
    this.isAuth = false;
    this.invalidAuth = null;
    this.isFirstLogin = false;
  }
}

export const $userStore = new User();
