import { $userStore } from "@common/api/store/user";
import { getSelfAssessmentQuestions, sendSelfAssessmentResult } from "@common/features/assessments/model";
import { AssessmentForm, AssessmentFormCallback } from "@common/types/forms/assessments-employee";
import { EmployeeAssessmentFormModal } from "@features/assessments";
import { useEffect, useState } from "react";

interface Props {
  role?: string;
  closeModal: () => void;
}

export const SelfAssessmentsModal = ({ role, closeModal }: Props) => {
  const [questions, setQuestions] = useState<AssessmentForm[]>();
  const user = $userStore.getUserInfo();
  const userName = `${user?.first_name ? `${user.first_name} ` : ``}${user?.last_name}`;

  useEffect(() => {
    getSelfAssessmentQuestions(setQuestions);
  }, []);

  const onSend = (props: AssessmentFormCallback) => {
    sendSelfAssessmentResult(props);
  };

  return !!questions?.length ? (
    <EmployeeAssessmentFormModal
      onSend={onSend}
      assessmentQuestions={questions}
      isOpen={true}
      closeModal={closeModal}
      name={userName}
      {...(role ? { role } : {})}
    />
  ) : null;
};
