import { getPhrases } from "@common/helpers/localization";
import { CompanyFormInterface } from "@common/types/reports/profile/profile";
import * as yup from "yup";

const loc = getPhrases("common", "forms").validation;

export const CompanyFormSchema: yup.SchemaOf<CompanyFormInterface> = yup.object().shape({
  name: yup.string().required(loc.required),
  portal_name: yup.string(),
  calendar_year_end: yup.number()
});
