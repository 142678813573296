import { TableColumn, TableDetailRows } from "@common/types/table";
import classNames from "classnames";
import { DataType } from "ka-table/enums";
import grid from "@styles/modules/grid.module.scss";
import { FirstLevelTable } from "@ui";
import includedStyles from "./../../included-first-level-table.module.scss";
import { PropsWithChildren, useEffect, useState } from "react";
import { OrgUnits } from "@common/types/reports/org-units/org-units";
import { rowStyler } from "../../helpers";
import { $orgUnitsStore } from "@common/api/store/org-units";
import { getReportOrgUnits } from "@common/features/org-units/model";
import { formatOrgUnitsObject } from "../helpers";
import { OrgUnitActionInterface } from "../types";
import { orgUnitsTableModel } from "../model";
import { getDeclensions } from "@common/helpers/string-helpers";
import { ICellTextProps } from "ka-table/props";
import { observer } from "mobx-react-lite";
import { IntermediateLevel } from "../intermediate-level";
import { OrgAction } from "@features/settings/blocks/org-units";

interface FormattedOrgUnits extends OrgUnits, OrgUnitActionInterface {}

export const FirstLevel: React.FC = observer(() => {
  const [orgUnits, setOrgUnits] = useState<OrgUnits[]>([]);

  useEffect(() => {
    $orgUnitsStore.setOrgUnits([]);
    getReportOrgUnits(setOrgUnits);
  }, []);

  useEffect(() => {
    setOrgUnits($orgUnitsStore.orgUnits);
  }, [$orgUnitsStore.orgUnits]);

  const getOrgUnitsCount = (data: PropsWithChildren<ICellTextProps>) => {
    return `${data.value || 0} ${getDeclensions(data.value, "org unit", "org units")}`;
  };

  const columns: TableColumn<FormattedOrgUnits>[] = [
    ...orgUnitsTableModel.filter(col => col.key !== "id"),
    {
      key: "org_units_count",
      title: "",
      dataType: DataType.Number,
      modClass: classNames(grid.item, grid["item-2x"]),
      decorator: data => getOrgUnitsCount(data)
    }
  ];

  // replace action and org unit count columns

  const editActionIndex: number = columns.findIndex(item => item.key === "action");

  columns[editActionIndex] = columns.splice(columns.length - 1, 1, columns[editActionIndex])[0];

  columns.push({
    key: "index",
    modClass: classNames(grid.item, grid["item-1x"]),
    component: OrgAction
  });

  const detailRows: TableDetailRows<FormattedOrgUnits> = {
    component: IntermediateLevel,
    dataProperty: "children"
  };

  if (!!orgUnits?.length) {
    const data: FormattedOrgUnits[] = formatOrgUnitsObject<OrgUnits>(orgUnits);

    return FirstLevelTable<FormattedOrgUnits>({
      data,
      columns,
      detailRows,
      theme: "orange",
      modStyles: { ...includedStyles, "cell-inner": "justify-start" },
      rowStyler,
      hideHeader: true
    });
  } else {
    return <div>No data</div>;
  }
});
