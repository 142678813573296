import { GlobalStore } from "@common/api/store/global";
import { getProfile } from "@common/features/profile/model";
import { ProfileInterface } from "@common/types/reports/profile/profile";
import { ProfileEmployeeForm } from "@features/profile";
import { useContext, useEffect, useState } from "react";

export const Profile = () => {
  const { $profileStore } = useContext(GlobalStore);
  const [profile, setProfile] = useState<ProfileInterface>();
  useEffect(() => {
    const profile = $profileStore.getProfile();
    if (profile) {
      setProfile(profile);
    } else {
      getProfile(setProfile);
    }
  }, []);
  return profile ? <ProfileEmployeeForm data={profile} /> : null;
};
