import { getCurrentAccountabilitiesSession } from "@common/features/accountabilities/model";
import { AccountabilitiesCurrentSession } from "@common/types/reports/accountabilities/accountabilities";
import { AccountabilitiesWrapper } from "@features/accountabilities/modals/accountabilities-form-modal/wrapper";
import { useEffect, useState } from "react";

interface Props {
  memberId: number;
  closeModal: () => void;
  userName: string;
}

export const ManagerAccountabilitiesAssessment = ({ memberId, closeModal, ...props }: Props) => {
  const [currentSession, setCurrentSession] = useState<AccountabilitiesCurrentSession>();

  useEffect(() => {
    getCurrentAccountabilitiesSession(Number(memberId), setCurrentSession);
  }, []);

  return currentSession ? (
    <AccountabilitiesWrapper
      currentSession={currentSession}
      closeModal={closeModal}
      isManager={true}
      memberId={memberId}
      {...props}
    />
  ) : null;
};
