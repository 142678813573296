import { getPhrases } from "@common/helpers/localization";
import { Skill } from "@common/types/reports/skills/skills-employee";
import { TableColumn } from "@common/types/table";
import classNames from "classnames";
import { DataType, SortDirection } from "ka-table/enums";
import grid from "@styles/modules/grid.module.scss";
import { getRatedValue } from "./helpers";
import { ProgressCell, StatusCell } from "@ui";

const loc = getPhrases("skills_page", "table_employee_inner");
export const skillsColumns: TableColumn<Skill>[] = [
  {
    key: "is_active",
    title: loc.title_status,
    dataType: DataType.Boolean,
    sortDirection: SortDirection.Descend,
    modClass: classNames(grid.item, grid["item-1x"]),
    component: StatusCell
  },
  {
    key: "name",
    title: loc.title_name,
    dataType: DataType.String,
    modClass: classNames(grid.item, grid["item-6x"])
  },
  {
    key: "self_score",
    title: loc.title_self,
    dataType: DataType.String,
    modClass: classNames(grid.item, grid["item-1x"]),
    decorator: data => getRatedValue(data.value)
  },
  {
    key: "peer_score",
    title: loc.title_peer,
    dataType: DataType.String,
    modClass: classNames(grid.item, grid["item-1x"]),
    decorator: data => getRatedValue(data.value)
  },
  {
    key: "manager_score",
    title: loc.title_manager,
    dataType: DataType.String,
    modClass: classNames(grid.item, grid["item-1x"]),
    decorator: data => getRatedValue(data.value)
  },
  {
    key: "weighted_score",
    title: loc.title_score,
    dataType: DataType.Number,
    modClass: classNames(grid.item, grid["item-2x"]),
    component: ProgressCell
  }
];
