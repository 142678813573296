import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./learning-path.module.scss";
import Grid from "@styles/modules/grid.module.scss";
import classNames from "classnames";
import {
  Rating,
  RatingWithNumber,
  OutlinedButtonWithIcon,
  Titles,
  TextWithDescription,
  CategoryAndSubCategory,
  Button,
  CircularChart
} from "@ui";
import { getPhrases } from "@common/helpers/localization";
import { CourseCard } from "./CourseCard";
import { Link, useParams } from "react-router-dom";
import {
  getLearningPathById,
  getLearningPathList,
  getRelatedLearningPaths,
  setLearningPathRating
} from "@common/features/learn/model";
import { ContentTypeEnum, YourLearningPathsInterface, YourLearningPathsList } from "@common/types/reports/learn/learn";
import { learningPath } from "./CourseCard/types";
import { getDeclensions } from "@common/helpers/string-helpers";
import { RelatedPaths } from "@features/learn/related-paths";
import { getDurationHoursAndMinutes } from "@common/helpers/time-helpers";

// src/features/my-staff/modals/user-info-modal/index.ts

export const LearningPath = () => {
  const { id } = useParams();
  const [data, setData] = useState<YourLearningPathsInterface>();
  const [learningPathList, setLearningPathList] = useState<YourLearningPathsList[]>();
  const [relatedPaths, setRelatedPaths] = useState<YourLearningPathsInterface[]>();

  useEffect(() => {
    if (id) {
      getLearningPathById(Number(id), setData);
      getRelatedLearningPaths(Number(id), setRelatedPaths);
    }
  }, [id]);

  useEffect(() => {
    if (data?.id) {
      getLearningPathList(data.id, setLearningPathList);
    }
  }, [data]);

  const getCourseStatus = useCallback((progress: number) => {
    switch (progress) {
      case 0:
        return "not-started";
      case 100:
        return "completed";
      default:
        return "in-progress";
    }
  }, []);

  const onChangeRating = (value: number) => {
    if (data?.id) {
      setLearningPathRating(data.id, {
        value
      }).then(data => getLearningPathById(Number(id), setData));
    }
  };

  const learningPaths: learningPath[] = useMemo(() => {
    return learningPathList?.length
      ? learningPathList?.reduce((previous: learningPath[], current) => {
          return [
            ...previous,
            {
              id: current.resource.id,
              name: current.resource.title,
              imgSrc: current.resource.image_url,
              status: getCourseStatus(current.resource.progress),
              author: current.resource.provider.name,
              date: `${
                current.resource.publication_month
                  ? `${current.resource.publication_month}, `
                  : `${current.resource.publication_year ?? ``}`
              }`,
              desc: "",
              learners: current.resource.reviews_count,
              type: current.resource.content_type.id,
              url: current.resource.url,
              duration: current.resource.duration,
              difficulty: current.resource.expertise_level.name,
              isFree: current.resource.is_free
            }
          ];
        }, [])
      : [];
  }, [learningPathList, getCourseStatus]);

  const loc_summary_block = getPhrases("learning_path_page", "summary_block");

  return (
    <div className={Grid["page-row"]}>
      <div className={Grid.root}>
        <div className={Grid.container}>
          {data && (
            <>
              {" "}
              <CategoryAndSubCategory
                category={data.skill_categories.common}
                subcategory={data.skill_categories.common_root}
              />
              <div className={classNames(Grid.list, styles["top-details-container"])}>
                <div className={classNames(Grid.item, Grid["item-9x"])}>
                  {/*     Top Details Card    */}
                  <div className={styles["top-details"]}>
                    {/*     Logos And Actions   */}
                    <div className={styles["tag-and-actions"]}>
                      {/*<Button*/}
                      {/*  size={"thin"}*/}
                      {/*  className={styles.tag}*/}
                      {/*  appearance={"bordered"}*/}
                      {/*  theme={"dark-gray"}*/}
                      {/*>*/}
                      {/*  Learning Path*/}
                      {/*</Button>*/}

                      {/*     Course Title    */}
                      <Titles level={"level_3"} modClass={styles.title}>
                        {data.title}
                      </Titles>

                      {/*     top details actions     */}
                      <div className={styles.actions}>
                        {/* <div className={styles.bookmark}>
                          <Svg name={"bookmark"} />
                          <p className={styles["bookmark-number"]}>19.5k</p>
                        </div> */}

                        {/* <OutlinedButtonWithIcon
                          theme={"orange"}
                          icon={<Svg name={"list"} />}
                          text={
                            <div className={styles.addToListBtn}>
                              {loc_title_block.add_to_list}
                            </div>
                          }
                        /> */}
                      </div>
                    </div>

                    <div className={styles["top-details-bottom"]}>
                      <div>
                        {/*     Course Title    */}

                        {data.description && (
                          <Titles level={"level_3"} modClass={styles.title}>
                            {data.description}
                          </Titles>
                        )}

                        <RatingWithNumber totalRatings={data.rating_count || 0} rating={data.rating_average || 0} />
                      </div>
                    </div>
                  </div>

                  {/*     Course Cards    */}
                  <div className={styles["course-cards"]}>
                    {learningPaths.length &&
                      learningPaths.map(data => {
                        const {
                          id,
                          name,
                          date,
                          desc,
                          learners,
                          imgSrc,
                          status,
                          author,
                          type,
                          url,
                          duration,
                          difficulty,
                          isFree
                        } = data;
                        return (
                          <Link to={`/learn/course-details/${data.id}`} key={`learning-path-${data.id}`}>
                            <CourseCard
                              id={id}
                              name={name}
                              imgSrc={imgSrc}
                              status={status}
                              author={author}
                              date={date}
                              desc={desc}
                              learners={learners}
                              type={type}
                              url={url}
                              duration={duration}
                              difficulty={difficulty}
                              isFree={isFree}
                            />
                          </Link>
                        );
                      })}
                  </div>

                  {/*     All the related learning resources      */}
                  {!!relatedPaths?.length && (
                    <div className={styles["related-cards-container"]}>
                      <RelatedPaths data={relatedPaths} />
                    </div>
                  )}
                </div>

                <div className={classNames(Grid.item, Grid["item-3x"])}>
                  <div className={styles["course-details-card"]}>
                    <div className={styles.features}>
                      {/*chart*/}
                      <div className={styles["chart-container"]}>
                        <CircularChart progress={data.progress} />
                      </div>

                      <div className={styles["features-items"]}>
                        <TextWithDescription description={data.title} text={loc_summary_block.learning_path} />

                        <TextWithDescription description={data.is_free ? "Free" : "Paid"} text={"Type"} />

                        {/* <TextWithDescription
                          description={"English"}
                          text={"Language"}
                        /> */}

                        <TextWithDescription
                          description={`${learningPathList ? learningPathList.length : 0} ${getDeclensions(
                            learningPathList ? learningPathList.length : 0,
                            "Course",
                            "Courses"
                          )}`}
                          text={`${loc_summary_block.includes}`}
                        />

                        <TextWithDescription
                          description={getDurationHoursAndMinutes(data.duration || 0)}
                          text={"Duration"}
                        />

                        <TextWithDescription description={data.expertise_level.name} text={"Level"} />

                        {/* <TextWithDescription
                          description={
                            " Arabic, French, Portuguese, Italian, German, Russian, English, Spanish, Korean"
                          }
                          text={"Closed Captions"}
                        /> */}
                      </div>
                    </div>

                    {/* <div className={styles["share-path"]}>
                      <p className={styles.title}>
                        {loc_summary_block.share_this_learning_path}
                      </p>
                    </div> */}

                    <div className={styles["rate-course"]}>
                      <div className={styles.title}>Rate this path</div>

                      <Rating rating={data.rating_average} onChange={onChangeRating} />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
