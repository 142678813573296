import { TableCellProps } from "@common/types/table";
import styles from "./edit.module.scss";

export const Edit = ({ props }: TableCellProps) => {
  const id: number = props.value;
  return (
    <div className={styles.icon}>
      <i className="fa fa-edit" onClick={() => console.log("click")} />
    </div>
  );
};
