import { Button, FormInputText, FormWrapper } from "@ui";
import { FieldErrors } from "react-hook-form";
import { defaultValues, forgotFormSchema } from "./model";
import styles from "./forgot-form.module.scss";
import form from "@styles/modules/form.module.scss";
import { useForm } from "@common/hooks/form";
import classNames from "classnames";
import { getPhrases } from "@common/helpers/localization";
import { sendResetPasswordLink } from "@common/features/user/model";
import { ForgotFormProps } from "./types";
import { APICallbacks } from "@common/types/forms/common";
import { ResetPasswordInterface } from "../reset-password/model";

interface Props extends ForgotFormProps, Omit<APICallbacks, "successCallback"> {
  successCallback: (email: string) => void;
}

export const ForgotForm = ({ onClose, successCallback, failCallback }: Props) => {
  const methods = useForm<ResetPasswordInterface>({
    schema: forgotFormSchema,
    defaultValues
  });

  const handleSuccess = (values: ResetPasswordInterface) => {
    sendResetPasswordLink({
      email: values.email,
      failCallback,
      successCallback: () => successCallback(values.email)
    });
  };
  const handleError = (errors: FieldErrors<ResetPasswordInterface>) => {
    console.error(errors);
  };

  const buttonCancel = classNames(styles.button, styles["button-cancel"]);

  const loc = getPhrases("login_page", "reset_modal");
  return (
    <FormWrapper onSubmit={methods.handleSubmit(handleSuccess, handleError)}>
      <div className={styles.wrapper}>
        <div className={form.row}>
          <FormInputText
            placeholder={loc.forms?.emailPlaceholder}
            name="email"
            register={methods.register}
            error={methods.formState.errors["email"]?.message}
          />
        </div>
      </div>
      <div className={styles.buttons}>
        <Button className={buttonCancel} title={loc.buttons?.button_cancel} theme="gray" onClick={onClose} />
        <Button className={styles.button} title={loc.buttons?.button_send} type="submit" />
      </div>
    </FormWrapper>
  );
};
