import { InputInterface } from "@common/types/form";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import form from "@styles/modules/form.module.scss";
import dayjs from "dayjs";
import usePopup from "@common/hooks/outside";

// TODO: Move Props to abstract interface

interface Props extends InputInterface {
  type?: string;
  dateTime?: number;
  onChange?: (event: any) => void;
}

export const FormInputDatePicker = ({
  name,
  className,
  type = "text",
  register,
  dateTime,
  error,
  theme = "gray",
  onChange,
  ...props
}: Props) => {
  const [isCalendarShow, setCalendarView] = useState<boolean>(false);
  const [value, setDateValue] = useState<string>();
  const ref = useRef<HTMLDivElement>(null);
  const { isOpen, openMenu } = usePopup(ref);
  const onChangeHandler = (date: any) => {
    const formattedDate = dayjs(date).format("DD.MM.YYYY");
    setDateValue(formattedDate);
    if (onChange) {
      onChange(formattedDate);
    }
  };

  useEffect(() => {
    const formattedDate = dayjs(dateTime).format("DD.MM.YYYY");
    setDateValue(formattedDate);
    if (onChange) {
      onChange(formattedDate);
    }
  }, [dateTime]);

  const inputClass = classNames(
    form.text,
    form[`text-${theme}`],
    form.input,
    form["calendar-input"],
    className,
    "w-full",
    error && form["input-error"]
  );

  return (
    <div className={form.field} data-error={error}>
      <input
        type={type}
        {...(register && register(name))}
        className={inputClass}
        {...(value && { value })}
        onClick={() => !isOpen && openMenu()}
        readOnly
        {...props}
      />
      <div ref={ref}>
        <Calendar
          onChange={onChangeHandler}
          value={dateTime ? new Date(dateTime) : new Date()}
          className={classNames(form.calendar, isOpen ? form["calendar-show"] : form["calendar-hide"])}
        />
      </div>
    </div>
  );
};
