import { FormattedPeople, PeopleSubordinates } from "@common/types/reports/people/people";
import { IDataRowProps } from "ka-table/props";
import React from "react";
import { CategoryLevel } from "./category-level";
import { createFormattedPeopleItem } from "./helpers";
import { LastLevel } from "./last-level";

export const IntermediateLevel: React.FC<IDataRowProps> = ({ rowData }) => {
  const categories: FormattedPeople[] = [];
  const lastLevel: PeopleSubordinates[] = [];
  rowData.subordinates.map((item: PeopleSubordinates) => {
    if (item.subordinates.length) {
      categories.push(createFormattedPeopleItem<PeopleSubordinates>(item));
    } else {
      lastLevel.push(item);
    }
  });
  return (
    <>
      {!!categories.length && <CategoryLevel data={categories} />}
      {!!lastLevel.length && <LastLevel data={lastLevel} />}
    </>
  );
};
