import { FC, useCallback, useState } from "react";
import { ComplexModalButtons } from "@ui";
import { ILearnPathForm } from "@common/types/forms/learn";
import { LearnDetailsTab } from "@features/learn/learn-path-form/details-tab";
import { LearnResourcesTab } from "@features/learn/learn-path-form/resources-tab";
import { LearnSkillsTab } from "@features/learn/learn-path-form/skills-tab";
import { LearnQuizTab } from "@features/learn/learn-path-form/quiz-tab";
import { setLearningPathDetail, setLearningPathList } from "@common/features/learn/model";
import * as Url from "url";

export interface ILearnPathFormProps {
  tab: number;
  formData: Partial<ILearnPathForm>;
  onTabChange(tab: number): void;
  onChange(data: Partial<ILearnPathForm>): Partial<ILearnPathForm>;
  onSubmit(data: Partial<ILearnPathForm>): void;
  onClose(): void;
  onSetModalButtons(buttons?: ComplexModalButtons): void;
}

export interface ILearnPathFormTabInitData {
  buttons?: ComplexModalButtons;
}

export const LearnPathForm: FC<ILearnPathFormProps> = ({
  tab,
  formData,
  onTabChange,
  onChange,
  onSubmit,
  onClose,
  onSetModalButtons,
}) => {
  const [pathId, setPathId] = useState<number>();
  const onInitForm = useCallback((data: ILearnPathFormTabInitData) => {
    onSetModalButtons(data.buttons);
  }, []);

  const gotoPrevTab = () => {
    if (tab === 0) {
      onClose();
    } else {
      onTabChange(tab - 1);
    }
  };

  const gotoNextTab = (data?: Partial<ILearnPathForm>) => {
    if (tab === 3) {
      onSubmit(data as Partial<ILearnPathForm>);
    } else if (tab === 0) {
      if (data) {
        let imageUrl = '';
        if (typeof data.imageFile === 'object') {
          imageUrl = URL.createObjectURL(data.imageFile);
        } else if (typeof data.imageFile === 'string') {
          imageUrl = data.imageFile;
        }
        setLearningPathDetail({
          title: data.name || '',
          image_url: imageUrl,
          is_active: data.is_active || true,
          description: data.description || '',
        }, pathId).then((data) => {
          if (data) {
            setPathId(data.id);
            onTabChange(tab + 1);
          }
        });
      }
    } else if (tab === 1) {
      if (data?.resources && pathId) {
        setLearningPathList(pathId, data.resources.map((resource, index) => ({ id: resource.id, order: index + 1 })));
        onTabChange(tab + 1);
      }
    }
  };

  return (
    <div>
      {tab === 0 && (
        <LearnDetailsTab
          formData={formData}
          onInit={onInitForm}
          onBack={gotoPrevTab}
          onNext={gotoNextTab}
          onSubmit={onChange}
          onClose={onClose}
        />
      )}
      {tab === 1 && (
        <LearnResourcesTab
          formData={formData}
          onInit={onInitForm}
          onBack={gotoPrevTab}
          onNext={gotoNextTab}
          onSubmit={onChange}
          onClose={onClose}
        />
      )}
      {tab === 2 && (
        <LearnSkillsTab
          formData={formData}
          onInit={onInitForm}
          onBack={gotoPrevTab}
          onNext={gotoNextTab}
          onSubmit={onChange}
          onClose={onClose}
        />
      )}
      {tab === 3 && (
        <LearnQuizTab
          formData={formData}
          onInit={onInitForm}
          onBack={gotoPrevTab}
          onNext={gotoNextTab}
          onSubmit={onChange}
          onClose={onClose}
        />
      )}
    </div>
  );
};
