import { TableColumn, TableDetailRows } from "@common/types/table";
import classNames from "classnames";
import { DataType, SortDirection } from "ka-table/enums";
import grid from "@styles/modules/grid.module.scss";
import { FirstLevelTable } from "@ui";
import { SecondLevel } from "../second-level";
import { FormattedOfficesDataProps, AreaOfficesDataProps } from "@common/types/reports/offices/offices";
import includedStyles from "./../../included-first-level-table.module.scss";
import { rowStyler } from "../../helpers";
import { PropsWithChildren, useContext } from "react";
import { ICellTextProps } from "ka-table/props";
import { getDeclensions } from "@common/helpers/string-helpers";
import { observer } from "mobx-react-lite";
import { GlobalStore } from "@common/api/store/global";
import { AreaAction, AreaInfo } from "@features/settings/blocks/offices";

interface Props {
  offices: AreaOfficesDataProps[];
}

const getOffices = (data: PropsWithChildren<ICellTextProps>): string => {
  return `${data.value} ${getDeclensions(data.value, "office", "offices")}`;
};

const getEmployees = (data: PropsWithChildren<ICellTextProps>): string => {
  return `${data.value} ${getDeclensions(data.value, "employee", "employees")}`;
};

export const FirstLevel: React.FC<Props> = observer(() => {
  const { $destinationDataStore } = useContext(GlobalStore);
  const columns: TableColumn<FormattedOfficesDataProps>[] = [
    {
      key: "name",
      title: "Area Name",
      dataType: DataType.String,
      sortDirection: SortDirection.Descend,
      modClass: classNames(grid.item, grid["item-4x"]),
      expandCell: true
    },
    {
      key: "offices_count",
      title: "Offices",
      dataType: DataType.Number,
      modClass: classNames(grid.item, grid["item-4x"]),
      decorator: data => getOffices(data)
    },
    {
      key: "members_count",
      title: "Employees",
      dataType: DataType.Number,
      modClass: classNames(grid.item, grid["item-2x"]),
      decorator: data => getEmployees(data)
    },
    {
      key: "id",
      title: "",
      dataType: DataType.Number,
      modClass: classNames(grid.item, grid["item-1x"]),
      component: AreaInfo
    },
    {
      key: "id",
      title: "",
      dataType: DataType.Number,
      modClass: classNames(grid.item, grid["item-1x"]),
      component: AreaAction
    }
  ];

  const detailRows: TableDetailRows<FormattedOfficesDataProps> = {
    component: SecondLevel,
    dataProperty: "offices"
  };

  if (!!$destinationDataStore.areas?.length) {
    const data: FormattedOfficesDataProps[] = $destinationDataStore.areas.map((item: any) => {
      return {
        ...item,
        offices: item.offices.length > 0 ? item.offices : undefined
      };
    });
    return FirstLevelTable<FormattedOfficesDataProps>({
      data,
      columns,
      detailRows,
      theme: "orange",
      modStyles: { ...includedStyles, "cell-inner": "justify-start" },
      rowStyler,
      hideHeader: true
    });
  } else {
    return null;
  }
});
