import { setPeopleProfileStatus } from "@common/features/profile/model";
import classNames from "classnames";
import { useEffect, useState } from "react";
import styles from "./status-switcher.module.scss";

interface Props {
  userId?: number;
  is_active?: boolean;
}

export const StatusSwitcher = ({ userId, is_active }: Props) => {
  const [isActive, setIsActive] = useState<boolean>(is_active || false);
  const [isEditable, setIsEditable] = useState<boolean>(!!userId);

  useEffect(() => {
    setIsEditable(!!userId);
  }, [userId]);

  const onSwitch = () => {
    if (userId) {
      const response = setPeopleProfileStatus(userId, {
        is_active: !isActive
      });
      response.then(data => !!data && setIsActive(prev => !prev));
    }
  };

  return (
    <div className={styles.block}>
      <div className={styles.text}>
        This profile is{" "}
        <span className={classNames(styles.label, isActive ? styles["label-active"] : styles["label-inactive"])}>
          {isActive ? "Active" : "Inactive"}
        </span>
      </div>
      <div
        className={classNames(
          styles.switcher,
          isActive ? styles["switcher-active"] : styles["switcher-inactive"],
          !isEditable && styles["switcher-banned"]
        )}
        {...(userId && { onClick: onSwitch })}
      ></div>
    </div>
  );
};
