import { SkillsAssessmentTable } from "./skills-assessment-table";
import { useEffect, useMemo, useState } from "react";
import { ControlLine, SelectOptionsProp, SelectProps } from "@ui";
import { ExtendedSelectProps } from "../../../ui/control-line";
import { ActiveStatus, DurationCategories } from "@common/types/reports/activity-logs/activity-logs";
import { getPhrases } from "@common/helpers/localization";
import moment from "moment";
import { getAssessmentsSummary } from "@common/features/activity-logs/model";
import { AssessmentSummary } from "@common/types/activity-logs/activity-logs";

enum ForCategories {
  all_org_unit = 1,
  virtualization,
  networking,
  storage
}

const displayOptions: SelectOptionsProp[] = [
  { value: ActiveStatus.completed, label: "All Skills Assessment" },
  { value: ActiveStatus.active, label: "Active Skills Assessment" },
  { value: ActiveStatus.pending, label: "Pending Skills Assessment" },
  { value: ActiveStatus.inactive, label: "Inactive Skills Assessment" }
];

const forOptions: SelectOptionsProp[] = [
  { value: ForCategories.all_org_unit, label: "All Org Units" },
  { value: ForCategories.virtualization, label: "Virtualization" },
  { value: ForCategories.networking, label: "Networking" },
  { value: ForCategories.storage, label: "Storage" }
];

const durationOptions: SelectOptionsProp[] = [
  { value: DurationCategories.month, label: "The Last 30 days" },
  { value: DurationCategories.quarter, label: "The Last 90 days" },
  { value: DurationCategories.half_year, label: "The Last six months" },
  { value: DurationCategories.year, label: "The Last 12 months" }
];

const loc = getPhrases("activity_logs_page", "filter");

export const SkillsAssessment = () => {
  const [displaying, setDisplaying] = useState<SelectOptionsProp>(displayOptions[0]);
  const [forOption, setForOption] = useState<SelectOptionsProp>(forOptions[0]);
  const [duration, setDuration] = useState<SelectOptionsProp>(durationOptions[3]);
  const [summary, setSummary] = useState<AssessmentSummary>();

  useEffect(() => {
    getAssessmentsSummary("skills-assessments", setSummary);
  }, []);

  const filterProps: SelectProps = {
    name: "invitation-category",
    options: displayOptions,
    defaultValue: displaying,
    isDisabled: true,
    onChange: (newValue: any) => {
      setDisplaying(newValue);
    }
  };

  const extraFilters: ExtendedSelectProps[] = [
    {
      label: "For",
      name: "for-category",
      options: forOptions,
      defaultValue: forOption,
      isDisabled: true,
      onChange: (newValue: any) => {
        setForOption(newValue);
      }
    },
    {
      label: "During",
      name: "during-category",
      options: durationOptions,
      defaultValue: duration,
      isDisabled: true,
      onChange: (newValue: any) => {
        setDuration(newValue);
      }
    }
  ];

  const durationTexts = useMemo(
    () => [moment().subtract(duration.value, "days").format("MMM DD, yyyy"), moment().format("MMM DD, yyyy")],
    [duration]
  );

  return (
    <>
      <ControlLine modClass="my-10 md:my-5" {...{ filterProps, extraFilters: extraFilters }}>
        <div className="w-full px-5 py-3 mt-8 mb-2 border border-solid border-black/20 rounded-lg bg-gray-f5 text-[#474a51]">
          <div className="mt-2 flex items-center">
            <span className="w-[350px] font-bold">{loc.period}:</span>
            <span>
              {durationTexts[0]} - {durationTexts[1]}
            </span>
          </div>
          <div className="mt-2 flex items-center">
            <span className="w-[350px] font-bold">{loc.total_assess}:</span>
            <span className="mr-6">Sessions: {summary?.total_assessments.sessions}</span>
            <span className="mr-6">Assessed roles: {summary?.total_assessments.assessed_roles}</span>
            <span>Assessed Employees: {summary?.total_assessments.assessed_roles}</span>
          </div>
          <div className="mt-2 flex items-center">
            <span className="w-[350px] font-bold">{loc.pending_assess}:</span>
            <span className="mr-10 text-danger">Self: {summary?.total_pending_assessments.self}</span>
            <span className="mr-6 text-danger">Manager: {summary?.total_pending_assessments.manager}</span>
            <span>Peer: {summary?.total_pending_assessments.peer}</span>
          </div>
          <div className="mt-2 flex items-center">
            <span className="w-[350px] font-bold">{loc.completed_assess}:</span>
            <span className="mr-10 text-success">Self: {summary?.total_completed_assessments.self}</span>
            <span className="mr-6 text-success">Manager: {summary?.total_completed_assessments.manager}</span>
            <span>Peer: {summary?.total_completed_assessments.peer}</span>
          </div>
        </div>
      </ControlLine>
      <SkillsAssessmentTable duration={duration.value} display={displaying.value} />
    </>
  );
};
