import { TableCellProps } from "@common/types/table";
import { useState } from "react";
import { EditRoleModal } from "../modals/edit-role-modal";
import styles from "./role-info.module.scss";

interface RoleProps extends TableCellProps {}

export const RoleInfo: React.FC<RoleProps> = ({ props }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { rowData, column, value } = props;
  return (
    <>
      <div className={styles.role}>
        <div className={styles.name} onClick={() => setIsOpen(true)}>
          {(column.title && value) || "Edit"}
        </div>
      </div>
      {isOpen && <EditRoleModal id={rowData.id} isOpen={isOpen} closeModal={() => setIsOpen(false)} />}
    </>
  );
};
