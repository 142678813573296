import { AxiosError } from "axios";
import { $globalStore, $resetGlobalStore } from "@common/api/store/global";
import { login as loginFunction, loginAs as loginAsFunction } from "@common/api/services/auth";
import {
  sendResetPasswordLink as sendResetPasswordLinkFunction,
  resetPassword as resetPasswordFunction,
  activateAccount as activateAccountFunction
} from "@common/api/services/auth";
import { LoginFormInterface } from "@features/user/forms/login/model";
import { InvalidAuthResponse } from "@common/api/types/response/auth";
import {
  ResetPasswordInterfaceFunction,
  ResetPasswordSendFormInterfaceFunction
} from "@features/user/forms/reset-password/model";
import { getProfile } from "@common/features/profile/model";
import { AccountActivationSendFormInterfaceFunction } from "@features/user/forms/account-activation/model";
import { ProfileInterface } from "@common/types/reports/profile/profile";
import { DataWrapper } from "@common/api/types/response/common";
import { SendResetPasswordLink } from "@common/api/types/response/send-reset-password-link";

const { $userStore, $profileStore } = $globalStore;

export const login = async ({ email, password }: LoginFormInterface) => {
  try {
    localStorage.removeItem("currentRoleId");
    const response = await loginFunction({ email, password });
    const userData = response.data;
    localStorage.setItem("token", userData.token);
    localStorage.setItem("userInfo", JSON.stringify(userData.user));
    if (!$profileStore.getProfile()) {
      await getProfile((data: ProfileInterface) => {
        // TODO: need to change the setting isFirstLogin after api ready
        if (!data.industry_experience) {
          $userStore.setIsFirstLogin(true);
          localStorage.setItem("isFirstLogin", "1");
        }
      });
    }
    $userStore.setUserInfo(userData.user);
    $userStore.setAuth(true);
    $profileStore.setCurrentRoleId(userData.user.role.id);
    return userData;
  } catch (e) {
    console.error("error here");
    $userStore.setInvalidAuth((e as AxiosError<InvalidAuthResponse>)?.response?.data || null);
  }
};

export const loginAs = async (memberId: number) => {
  try {
    const response = await loginAsFunction(memberId);
    localStorage.setItem("impersonationToken", response.data.token);
    localStorage.setItem("impersonationUserInfo", JSON.stringify(response.data.user));
    $resetGlobalStore();
    window.location.reload();
  } catch (e) {
    console.error("error while impersonation");
    $userStore.setInvalidAuth((e as AxiosError<InvalidAuthResponse>)?.response?.data || null);
  }
};

export const logout = async () => {
  try {
    localStorage.removeItem("impersonationToken");
    localStorage.removeItem("impersonationUserInfo");
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("currentRoleId");
    $resetGlobalStore();
    window.location.reload();
  } catch (e) {
    console.error("error");
  }
};

export const logoutImpersonation = async () => {
  try {
    localStorage.removeItem("impersonationToken");
    localStorage.removeItem("impersonationUserInfo");
    $resetGlobalStore();
    window.location.reload();
  } catch (e) {
    console.error("error");
  }
};

export const checkAuth = async () => {
  try {
    let userInfo = localStorage.getItem("userInfo");
    if (localStorage.getItem("token")) {
      $userStore.setAuth(true);
      const impersonationUserinfo = localStorage.getItem("impersonationUserInfo");
      if (impersonationUserinfo) {
        userInfo = impersonationUserinfo;
      }
    }
    if (userInfo) {
      $userStore.setUserInfo(JSON.parse(userInfo));
    }
    $userStore.setAuthChecked(true);
    if (!$profileStore.getProfile()) {
      getProfile();
    }
  } catch (e) {
    console.error("error");
  }
};

export const sendResetPasswordLink = async ({
  email,
  successCallback,
  failCallback
}: ResetPasswordInterfaceFunction) => {
  try {
    const response = await sendResetPasswordLinkFunction({ email });
    if (response) {
      successCallback();
    }
  } catch (e) {
    failCallback();
    console.error("send reset password link error");
  }
};

export const resetPassword = async ({
  successCallback,
  failCallback,
  ...props
}: ResetPasswordSendFormInterfaceFunction) => {
  try {
    const response = await resetPasswordFunction(props);
    if (response) {
      successCallback();
    }
  } catch (e) {
    failCallback(e as AxiosError<DataWrapper<SendResetPasswordLink>>);
    console.error("reset password error");
  }
};

export const activateAccount = async ({
  successCallback,
  failCallback,
  ...props
}: AccountActivationSendFormInterfaceFunction) => {
  try {
    const response = await activateAccountFunction(props);
    if (response) {
      successCallback();
    }
  } catch (e) {
    failCallback();
    console.error("activate password error");
  }
};
