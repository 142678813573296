import { getEmploymentTypesService } from "@common/api/services/employment-types";
import { $employmentTypesStore } from "@common/api/store/employment-types";
import { CommonWithId } from "@common/types/common";

export const getEmploymentTypes = async (callback: any) => {
  try {
    const response = await getEmploymentTypesService<CommonWithId>("/client/organization/employment-types");
    $employmentTypesStore.setTypes(response.data.data);
    callback(response.data.data);
  } catch (e) {
    console.error("Get employment types error");
  }
};
