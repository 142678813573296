import moment from "moment";
import { ProfileInterface } from "@common/types/reports/profile/profile";
import { Button, List, ListItem, Svg } from "@ui";
import classNames from "classnames";
import styles from "./details-employee.module.scss";
import grid from "@styles/modules/grid.module.scss";
import { downloadAssessmentReport } from "@common/features/assessments/model";

const MONTH_NAME = moment.monthsShort();

interface Props {
  information: ProfileInterface;
  report?: string;
}

export const DetailsEmployee = ({ information, report }: Props) => {
  const reportSessionId = report ? (report.toString().match(/\d+/) || [])[0] : null;
  const informationList: ListItem[] = [
    ...(information.first_name || information.last_name
      ? [
          {
            label: "Name",
            value: `${information.first_name ? `${information.first_name} ` : ""}${information.last_name}`
          }
        ]
      : []),
    ...(information.company?.name
      ? [
          {
            label: "Company",
            value: information.company.name
          }
        ]
      : []),
    ...(information.current_unit?.name && information.current_unit?.type?.name
      ? [
          {
            label: information.current_unit.type.name,
            value: information.current_unit.name
          }
        ]
      : []),
    ...(information.current_role?.name
      ? [
          {
            label: "Title",
            value: information.current_role.name
          }
        ]
      : []),
    ...(information.current_manager?.first_name || information.current_manager?.last_name
      ? [
          {
            label: "Reporting To",
            value: `${information.current_manager.first_name ? `${information.current_manager.first_name} ` : ""}${
              information.current_manager.last_name
            }`
          }
        ]
      : []),
    {
      label: "Review Period",
      value: `${MONTH_NAME[(information.company?.calendar.year_start_month || 1) - 1]} - ${
        MONTH_NAME[(information.company?.calendar.year_end_month || 1) - 1]
      }, ${information.company?.calendar.current_year}`
    },
    ...(reportSessionId
      ? [
          {
            label: "Latest Report",
            value: (
              <Button
                theme="red"
                size="thin"
                onClick={() => downloadAssessmentReport(Number(reportSessionId))}
                className={styles["report-button"]}
              >
                <Svg name="pdf" modClass={styles["report-button-icon"]} />
                Download PDF
              </Button>
            )
          }
        ]
      : [])
  ];
  return <List list={informationList} modClass={classNames(grid.item, styles.list)} />;
};
