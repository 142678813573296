import { AxiosResponse } from "axios";
import { HttpService } from "../http";
import { DataWrapper } from "../types/response/common";

export const getCategories = async <T>(url: string): Promise<AxiosResponse<DataWrapper<T[]>>> => {
  return HttpService.get<DataWrapper<T[]>>(url);
};

export const getCategory = async <T>(url: string): Promise<AxiosResponse<DataWrapper<T>>> => {
  return HttpService.get<DataWrapper<T>>(url);
};
