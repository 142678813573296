import { Roles } from "@common/constants";
import { CompanySetting, PeopleProfileInterface, ProfileInterface } from "@common/types/reports/profile/profile";
import { makeAutoObservable, toJS } from "mobx";
import { ClassCommon } from "../types/common";
import { $userStore } from "./user";

export class Profile implements ClassCommon {
  profile: ProfileInterface | null = null;
  detailedProfile: PeopleProfileInterface | null = null;
  companySetting: CompanySetting | null = null;
  personProfileError: string = "";
  currentRoleId: number = 0;

  constructor() {
    makeAutoObservable(this);
    const savedId = localStorage.getItem("currentRoleId");
    if (savedId) {
      this.currentRoleId = +savedId;
    }
  }

  setProfile(profile: ProfileInterface) {
    this.profile = profile ? { ...profile } : null;
    let role = $userStore.getUserRoleId();
    if ([Roles.manager, Roles.clientAdmin, Roles.superAdmin].includes(role)) {
      const savedRoleId = localStorage.getItem("currentRoleId") || role;
      if ([Roles.clientAdmin, Roles.manager, Roles.employee].includes(+savedRoleId)) {
        role = +savedRoleId;
      }
    }
    if (this.currentRoleId !== role) {
      this.currentRoleId = role;
    }
  }
  getProfile() {
    return toJS(this.profile);
  }
  setDetailedProfile(profile: PeopleProfileInterface) {
    this.detailedProfile = profile ? { ...profile } : null;
  }

  setCurrentRoleId(roleId: Roles) {
    this.currentRoleId = roleId;
  }
  getCurrentRoleId() {
    return this.currentRoleId;
  }

  getRootPage() {
    const isFirstLogin = localStorage.getItem("isFirstLogin");
    if (isFirstLogin) {
      return "/profile";
    }
    switch (this.getCurrentRoleId()) {
      case Roles.superAdmin:
        return "/innovadmin/dashboard";
      case Roles.employee:
        return "/dashboard";
      case Roles.manager:
        return "/people";
      default:
        return "/people";
    }
  }

  getDetailedProfile() {
    return toJS(this.detailedProfile);
  }
  setCompanySetting(companySetting: CompanySetting) {
    this.companySetting = companySetting ? { ...companySetting } : null;
  }
  getCompanySetting() {
    return toJS(this.companySetting);
  }
  reset(): void {
    this.profile = null;
    this.personProfileError = "";
    this.companySetting = null;
    this.personProfileError = "";
  }
}

export const $profileStore = new Profile();
