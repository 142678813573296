import { AccountabilitySendForm } from "@common/types/forms/accountabilities";
import { SendAccountabilityValues } from "@common/types/reports/accountabilities/accountabilities";
import { AxiosResponse } from "axios";
import { HttpService } from "../http";
import { DataWrapper } from "../types/response/common";

export const getAccountabilityService = async <T>(url: string): Promise<AxiosResponse<DataWrapper<T>>> => {
  return HttpService.get<DataWrapper<T>>(url);
};

export const getAccountabilitiesService = async <T>(url: string): Promise<AxiosResponse<DataWrapper<T[]>>> => {
  return HttpService.get<DataWrapper<T[]>>(url);
};

export const setAccountabilityService = async <T>(url: string, values: any): Promise<AxiosResponse<T>> => {
  return HttpService.post<T>(url, values);
};

export const updateAccountabilityService = async <T>(url: string, values: any): Promise<AxiosResponse<T>> => {
  return HttpService.post<T>(url, values);
};

export const sendAccountabilitiesResult = async (
  url: string,
  result: AccountabilitySendForm
): Promise<AxiosResponse<DataWrapper<AccountabilitySendForm>>> => {
  return HttpService.post<DataWrapper<AccountabilitySendForm>>(url, result);
};
