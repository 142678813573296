import { Button, Titles } from "@ui";
import styles from "./assessment-form-close-modal.module.scss";

interface Props {
  onCancel: () => void;
  onSuccess: () => void;
}

export const AssessmentFormCloseModal = ({ onCancel, onSuccess }: Props) => {
  return (
    <>
      <Titles level="level_1">Alert</Titles>
      <p>Closing the form will result in the loss of all progress. Are you sure you want to close the form?</p>
      <div className={styles.buttons}>
        <Button title="No" theme="gray" onClick={onCancel} />
        <Button title="Yes" onClick={onSuccess} />
      </div>
    </>
  );
};
