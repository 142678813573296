import { MenuProps } from "@common/types/reports/common";

export const menuData: MenuProps[] = [
  {
    key: "reportType",
    title: "reportType",
    subMenus: [
      {
        key: "byTeam",
        title: "byTeam",
        subMenus: []
      },
      {
        key: "bySupervisor",
        title: "bySupervisor",
        subMenus: [
          {
            key: "Rakesh Ramakrishnan",
            title: "Rakesh Ramakrishnan"
          },
          {
            key: "Miguel Vega",
            title: "Miguel Vega"
          },
          {
            key: "David Perez Gomez",
            title: "David Perez Gomez"
          },
          {
            key: "Rodrigo Alejandro Zamudio",
            title: "Rodrigo Alejandro Zamudio"
          },
          {
            key: "Ricardo Hernandez Ladino",
            title: "Ricardo Hernandez Ladino"
          }
        ]
      },
      /* TODO: need to uncomment after integration
      {
        key: "byRole",
        title: "byRole",
        subMenus: [
          {
            key: "High Skill",
            title: "High Skill",
          },
          {
            key: "Medium Skill",
            title: "Medium Skill",
          },
          {
            key: "Low Skill",
            title: "Low Skill",
          },
        ],
      },
      {
        key: "byOffice",
        title: "byOffice",
        subMenus: [
          {
            key: "North America",
            title: "North America",
          },
          {
            key: "South America",
            title: "South America",
          },
          {
            key: "Europe",
            title: "Europe",
          },
        ],
      },
      {
        key: "bySkill",
        title: "bySkill",
        subMenus: [
          {
            key: "Technical Skills",
            title: "Technical Skills",
          },
          {
            key: "Soft Skills",
            title: "Soft Skills",
          },
        ],
      },
      */
      {
        key: "byEmployee",
        title: "byEmployee",
        subMenus: [
          {
            key: "Full time",
            title: "Full time"
          },
          {
            key: "Contractor",
            title: "Contractor"
          },
          {
            key: "Intern",
            title: "Intern"
          }
        ]
      }
    ]
  }
];
