import { GlobalStore } from "@common/api/store/global";
import { Roles } from "@common/constants";
import { getPhrases } from "@common/helpers/localization";
import { Skill } from "@common/types/reports/skills/skills";
import { Skill as SkillDetail } from "@common/types/skill-engine/skill";
import { TableColumn } from "@common/types/table";
import { EditSkillModal } from "@features/skills/edit-skill-modal";
import grid from "@styles/modules/grid.module.scss";
import { ProgressCell, SecondLevelTable, StatusCell } from "@ui";
import classNames from "classnames";
import { DataType, SortDirection } from "ka-table/enums";
import { IDataRowProps } from "ka-table/props";
import { useContext, useState } from "react";

const loc = getPhrases("skills_page", "table_inner");

export const SecondLevel: React.FC<IDataRowProps> = ({ rowData }) => {
  const data: Skill[] | null = rowData.skills?.length ? rowData.skills : null;
  const [editingSkill, setEditingSkill] = useState<SkillDetail>();
  const { $profileStore } = useContext(GlobalStore);
  const isClientAdmin = $profileStore.currentRoleId === Roles.clientAdmin;

  const columns: TableColumn<Skill>[] = [
    {
      key: "is_active",
      title: loc.title_status,
      dataType: DataType.Boolean,
      sortDirection: SortDirection.Descend,
      modClass: classNames(grid.item, grid["item-3x"]),
      component: StatusCell
    },
    {
      key: "name",
      title: loc.title_name,
      dataType: DataType.String,
      modClass: classNames(grid.item, grid["item-3x"]),
      component: ({ props }) => {
        const skill: SkillDetail = {
          id: props.rowData.id,
          name: props.rowData.name,
          description: props.rowData.description,
          is_active: props.is_active,
          category: {
            id: rowData.id,
            name: rowData.name,
            is_active: rowData.is_active,
            description: rowData.description
          }
        };
        return (
          <span
            className={classNames(isClientAdmin && "hover:text-primary hover:underline cursor-pointer")}
            onClick={() => isClientAdmin && setEditingSkill(skill)}
          >
            {props.value}
          </span>
        );
      }
    },
    {
      key: "employees_count",
      title: loc.title_count,
      dataType: DataType.Number,
      modClass: classNames(grid.item, grid["item-3x"])
    },
    {
      key: "avg_skill_level",
      title: loc.title_average,
      dataType: DataType.Number,
      modClass: classNames(grid.item, grid["item-3x"]),
      component: ProgressCell
    }
  ];

  if (!data) {
    return null;
  }

  return (
    <>
      {SecondLevelTable<Skill>({
        data,
        columns
      })}
      {editingSkill && <EditSkillModal isOpen skill={editingSkill} closeModal={() => setEditingSkill(undefined)} />}
    </>
  );
};
