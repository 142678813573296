import React, { useState } from "react";
import classNames from "classnames";
import { getPhrases } from "@common/helpers/localization";
import { OfficeReport } from "@common/types/reports/analytics/analytics";
import { OfficeOverviewData } from "@features/reporting/report-overview/report-overview.mock";
import grid from "@styles/modules/grid.module.scss";
import { FirstLevelTable, Titles } from "@ui";
import { officeKpiList, officeOverviewColumns } from "../model";
import styles from "../report-overview.module.scss";

interface OfficeOverviewProps {
  route: string;
}

export const OfficeOverview: React.FC<OfficeOverviewProps> = () => {
  const [loc] = useState(getPhrases("reporting_page", "overview"));
  const [data] = useState<OfficeReport[]>(OfficeOverviewData);

  return (
    <div className={classNames(grid.container, styles["page-container"])}>
      <Titles level="level_3" modClass={styles.title}>
        {loc.byOfficeTitle} {loc.reporting}
      </Titles>
      <p className={styles.description}>{loc.description}</p>
      <div className={styles["kpi-box-wrapper"]}>
        {officeKpiList.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && <div className={styles["kpi-box-divider"]} />}
            <div className={styles["kpi-box"]}>
              <Titles level="level_3" modClass={classNames(styles["kpi-box-title"])}>
                {item.value}
              </Titles>
              <p className={classNames(styles["kpi-box-description"], styles.team)}>{item.title}</p>
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className={styles["report-table-wrapper"]}>
        {FirstLevelTable<OfficeReport>({
          data,
          columns: officeOverviewColumns,
          modStyles: styles
        })}
      </div>
    </div>
  );
};
