import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Svg } from "@ui";
import tabs from "@styles/modules/tabs.module.scss";
import { PureModal } from "../../../ui/modal/pure-modal";
import styles from "./score-card-modal.module.scss";
import { getAssessmentReportUrl } from "@common/features/assessments/model";

interface ScoreCardModalProps {
  name: string;
  url?: string;
  onClose?: () => void;
}

export const ScoreCardModal: React.FC<ScoreCardModalProps> = ({ name, url, onClose }) => {
  const [urls, setUrls] = useState(["", "", "", ""]);
  const [activeTab, setActiveTab] = useState(urls.length - 1);
  const sessionId = useMemo(() => (url ? (url.toString().match(/\d+/) || [])[0] : null), [url]);

  useEffect(() => {
    if (!urls[activeTab] && activeTab === 3 && sessionId) {
      getAssessmentReportUrl(+sessionId).then(url => {
        if (url) {
          urls.splice(activeTab, 1, url);
          setUrls([...urls]);
        }
      });
    }
  }, [activeTab]);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleSelect = (index: number) => {
    setActiveTab(index);
  };

  return (
    <PureModal isOpen={true} closeModal={handleClose}>
      <div className={styles["header-wrapper"]}>
        <p className={styles["header-title"]}>
          Score Card:
          <span className={styles.name}> {name}</span>
        </p>
        <div className={styles["header-divider"]} />
        <Svg name="times" modClass={styles["close-icon"]} onClick={handleClose} />
      </div>
      <Tabs
        onSelect={handleSelect}
        className={tabs.tabs}
        selectedTabClassName={tabs["tab-selected"]}
        selectedIndex={activeTab}
      >
        <TabList controls={false} className={classNames(tabs.list, styles["tab-wrapper"])}>
          {[2019, 2020, 2021, 2022].map(year => (
            <Tab key={year} className={classNames(tabs.tab, styles["tab-item"])}>
              {year.toString()}
            </Tab>
          ))}
        </TabList>
        {[2019, 2020, 2021, 2022].map((year, index) => (
          <TabPanel
            key={year}
            className={classNames(tabs.panel, styles["main-panel"], index === activeTab ? styles.active : "")}
          >
            <div className={styles["pdf-panel"]}>
              {urls[activeTab] ? (
                <iframe src={urls[activeTab]} className="w-full h-full" />
              ) : (
                <>
                  <span className={styles.year}>{year}</span>
                  <span className={styles.desc}>
                    {index < 3 || !sessionId ? "SCORECARD NOT AVAILABLE" : "LOADING..."}
                  </span>
                </>
              )}
            </div>
          </TabPanel>
        ))}
      </Tabs>
    </PureModal>
  );
};
