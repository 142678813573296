import React, { ChangeEvent, FC } from "react";
import classNames from "classnames";
import { InputInterface } from "@common/types/form";
import form from "@styles/modules/form.module.scss";

interface Props extends Omit<InputInterface, "placeholder" | "register" | "error"> {
  label: string;
  value: any;
  checked?: boolean;
  size?: "sm" | "md";
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const FormInputRadio: FC<Props> = ({
  className,
  name,
  theme = "gray",
  label,
  value,
  checked = false,
  size = "md",
  onChange,
  disabled = false,
  ...props
}) => {
  const labelClassName = classNames(form.label, form[`label-${theme}`], form["radio-label"], form[`size-${size}`]);
  const checkClassName = classNames(form["radio-check"]);
  const inputClassName = classNames(form.checker, form.hide);
  return (
    <label className={labelClassName}>
      <input
        type="radio"
        name={name}
        className={inputClassName}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        {...props}
      />
      <span className={checkClassName} />
      <span className={form["label-text"]}>{label}</span>
    </label>
  );
};
