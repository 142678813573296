import React, { FC, useEffect, useState } from "react";
import * as yup from "yup";
import { CKEditor, FormWrapper } from "@ui";
import { ILearnFormTabInitData } from "../index";
import { ILearnForm } from "@common/types/forms/learn";
import { useForm } from "@common/hooks/form";
import { getPhrases } from "@common/helpers/localization";
import { LearningResourceDeleteConfirm } from "@features/learn/learn-form/learning-resource-delete-confirm";
import "./syllabus-tab.scss";

export interface ISyllabusTabProps {
  formData: Partial<ILearnForm>;
  onInit?: (data: ILearnFormTabInitData) => void;
  onBack(): void;
  onNext(): void;
  onSubmit(data: Partial<ILearnForm>): void;
  onClose(): void;
  editable?: boolean;
}

interface ILearningResourceSyllabusForm {
  syllabus: string;
}

const loc = getPhrases("common", "forms").validation;

const syllabusFormSchema: yup.SchemaOf<ILearningResourceSyllabusForm> = yup.object().shape({
  syllabus: yup.string().required(loc.required)
});

export const LearnSyllabusTab: FC<ISyllabusTabProps> = ({ formData, onInit, onBack, onNext, onSubmit, onClose }) => {
  const methods = useForm<ILearningResourceSyllabusForm>({
    schema: syllabusFormSchema,
    defaultValues: {
      syllabus: "",
      ...formData
    }
  });
  const syllabus = methods.watch("syllabus");
  const [showFormError, setShowFormError] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [timestamp, setTimestamp] = useState<number>();

  useEffect(() => {
    if (onInit) {
      if (showDeleteConfirm || showFormError) {
        onInit({ buttons: undefined });
      } else {
        onInit({
          buttons: {
            leftSide: [
              {
                title: (
                  <span>
                    Cancel
                    <br />
                    And Close
                  </span>
                ),
                theme: "secondary-gradient",
                className: "!w-30 !h-14 !text-sm !text-left !font-semibold",
                onClick: onClose
              },
              ...(formData.id
                ? [
                    {
                      title: (
                        <span className="w-full px-4">
                          Delete this
                          <br />
                          Learning Resource
                        </span>
                      ),
                      theme: "danger" as "danger",
                      className: "!w-44 !h-14 !text-xs !text-left !font-semibold !ml-4",
                      onClick: () => setShowDeleteConfirm(true)
                    }
                  ]
                : [])
            ],
            rightSide: [
              {
                title: "Prev",
                theme: "warning",
                shape: "left-arrow",
                className: "!w-20 !text-sm !font-semibold",
                onClick() {
                  onBack();
                }
              },
              {
                title: "Next",
                theme: "warning",
                shape: "right-arrow",
                className: "!w-20 !text-sm !font-semibold",
                onClick() {
                  methods.handleSubmit(handleSuccess, handleError)();
                }
              }
            ]
          }
        });
      }
    }
  }, [onInit, showDeleteConfirm, showFormError]);

  const onDelete = () => {
    onClose();
  };

  const onFieldChange = (field: keyof ILearningResourceSyllabusForm, value: any) => {
    methods.setValue(field, value);
    methods.trigger(field);
    setTimestamp(new Date().getTime());
  };

  const handleSuccess = (values: ILearningResourceSyllabusForm) => {
    onSubmit(values);
    onNext();
  };

  const handleError = (e: any) => {
    console.log(e);
    // setShowFormError(true);
  };

  return (
    <FormWrapper
      className="learn-form syllabus-wrapper text-black"
      onSubmit={methods.handleSubmit(handleSuccess, handleError)}
    >
      <div className="grid px-5 pb-4">
        <CKEditor
          value={syllabus}
          className="text-sm !leading-[25px] min-h-[150px]"
          config={{
            removePlugins: ["MediaEmbed", "Table"],
            placeholder: "Short description",
            editorClass: "min-h-[150px]"
          }}
          helperText={methods.formState.errors?.syllabus?.message}
          helperTextClass="text-xs text-danger mt-1"
          onChange={value => onFieldChange("syllabus", value)}
        />
      </div>

      {showDeleteConfirm && (
        <LearningResourceDeleteConfirm
          className="w-full h-full absolute top-0 left-0 z-100"
          onDelete={onDelete}
          onClose={() => setShowDeleteConfirm(false)}
        />
      )}
    </FormWrapper>
  );
};
