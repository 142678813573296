import { TableCellProps } from "@common/types/table";
import classNames from "classnames";
import styles from "./status-cell.module.scss";

interface StatusCellInterface extends TableCellProps {}

export const StatusCell = ({ props }: StatusCellInterface) => {
  const { value } = props;
  return (
    <div className={classNames(styles[value ? "active" : "deleted"], styles.status)}>
      {value ? "Active" : "Inactive"}
    </div>
  );
};
