import classNames from "classnames";
import grid from "@styles/modules/grid.module.scss";
import styles from "./questions-list.module.scss";
import { AssessmentFormQuestion } from "@common/types/forms/assessments-employee";
import { QuestionItem, QuestionItemProps } from "./question-item";
import { getPhrases } from "@common/helpers/localization";
import { Legend, LevelSetter } from "@features/assessments";
import { $assessmentsStore } from "@common/api/store/assessments";
import { observer } from "mobx-react-lite";

interface Props {
  data: AssessmentFormQuestion[];
}

export const QuestionsList = observer(({ data }: Props) => {
  const currentCategory = $assessmentsStore.getCurrentAssessmentCategory();

  if (!!currentCategory) {
    const loc = getPhrases("forms", "assessment");
    const questionClass = classNames(grid.item, grid["item-4x"]);
    const skillClass = classNames(grid.item, grid["item-5x"], styles.skill);
    const ratingClass = classNames(grid.item, grid["item-3x"], styles.rating);
    const tableRowClass = classNames(grid.list, styles["table-row"]);
    const levelsMax = 10;
    const currentLevel = $assessmentsStore.getAssessmentCategorySetterById(currentCategory) ?? null;

    return (
      <div className={styles.table}>
        <div className={styles["table-header"]}>
          <div className={tableRowClass}>
            <div className={questionClass}>
              <div className={styles["table-title"]}>{loc.header_skill}</div>
            </div>
            <div className={skillClass}>
              {loc.header_level_pre} <br />
              {loc.header_level_post}
            </div>
            <div className={ratingClass}>
              {loc.header_rating_pre} <br />
              {loc.header_rating_post}
            </div>
          </div>
          <div className={tableRowClass}>
            <div className={questionClass}>
              <Legend />
            </div>
            <div className={skillClass}>
              <LevelSetter maxLevel={levelsMax} currentCategory={currentCategory} />
            </div>
            <div className={ratingClass}></div>
          </div>
        </div>
        <div className={styles["table-list"]}>
          {data.map((item, index) => {
            const props: QuestionItemProps = {
              item,
              questionClass,
              skillClass,
              ratingClass,
              levelsMax,
              category: currentCategory,
              ...(currentLevel !== null ? { currentLevel } : {})
            };
            return <QuestionItem key={`question-item-${item.id}`} {...props} />;
          })}
        </div>
      </div>
    );
  } else {
    return null;
  }
});
