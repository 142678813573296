import React, { FC, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Tab, TabList, Tabs } from 'react-tabs';
import { ComplexModal, ComplexModalButtons, Switch } from '@ui';
import { IRoleForm } from '@common/types/forms/role';
import {
  attachResourceSkills,
  createResource,
  saveResourceQuizSetting,
  updateResource,
  uploadQuizQuestions
} from "@common/features/learn/model";
import { LearnForm } from '../../learn-form';
import { SavingError } from '../../learn-form/saving-error';
import tabs from '@styles/modules/tabs.module.scss';
import {ILearnForm, ILearnResource, ILearnResourceQuizSetting} from "@common/types/forms/learn";
import { LearningResource } from "@common/types/reports/learn/learning-resource";

interface AddLearningResourceModalProps {
  closeModal: (addOrEdit?: boolean) => void;
  isOpen: boolean;
  editableResource: LearningResource | null;
}

export const AddLearningResourceModal: FC<AddLearningResourceModalProps> = ({ isOpen, closeModal, editableResource }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [apiError, setApiError] = useState('');
  const [buttons, setButtons] = useState<ComplexModalButtons>();
  const [formData, setFormData] = useState<Partial<ILearnForm>>({
    is_active: true,
  });

  useEffect(() => {
    if (editableResource) {
      const { instructors, content_type, expertise_level, language, provider, duration, quiz, ...rest  } = editableResource;

      setFormData({
        ...rest,
        duration: parseInt(String(duration)),
        language_id: language.id,
        content_type_id: content_type.id,
        expertise_level_id: expertise_level.id,
        provider_id: provider.id,
        quizFile: 'Quiz Aiken Format',
        quizRetryUnlimited: !quiz?.retries_limit,
        quizRetryLimit: quiz?.retries_limit || 1,
        quizTimeUnlimited: !quiz?.complete_time_limit_min,
        quizTimeLimit: quiz?.complete_time_limit_min || 1,
        quizShouldComplete: !quiz?.min_pass_score_percent || quiz?.min_pass_score_percent === 100,
        quizMinPassingScore: quiz?.min_pass_score_percent || 100,
      });
    }
  }, [editableResource]);

  const tabsHeader = (
    <Tabs
      selectedIndex={tabIndex}
      className={tabs.tabs}
      selectedTabClassName={tabs['tab-selected']}
      disabledTabClassName={tabs['tab-disabled']}
    >
      <TabList className={classNames(tabs.list, 'mt-2')}>
        <Tab className={tabs.tab} disabled>01 Details</Tab>
        <Tab className={tabs.tab} disabled>02 Sylabus</Tab>
        <Tab className={tabs.tab} disabled>03 Skills</Tab>
        <Tab className={tabs.tab} disabled>04 Media</Tab>
        <Tab className={tabs.tab} disabled>05 Quiz</Tab>
      </TabList>
    </Tabs>
  );

  const onFieldChange = (field: string, value: any) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const onFormDataChange = (data: Partial<IRoleForm>) => {
    const newData = {
      ...formData,
      ...data,
    };
    setFormData(newData);
    return newData;
  };

  const onSubmit = async (data: ILearnForm) => {
    try {
      //Create resource
      const createResourceRequest: ILearnResource = {
        is_active: data.is_active,
        title: data.title,
        provider_id: data.provider_id,
        short_description: data.short_description,
        url: data.url,
        image_url: data?.image_url || undefined,
        video_preview_url: data.mediaSrc,
        content_type_id: data.content_type_id,
        duration: data.duration,
        is_free: data.is_free,
        stars_eval: data?.stars_eval || undefined,
        reviews_count: data?.reviews_count || undefined,
        expertise_level_id: data?.expertise_level_id,
        language_id: data.language_id,
        instructors: data.instructors,
        syllabus: data.syllabus
      };

      const resource = editableResource?.id ?
          await updateResource(editableResource.id ,createResourceRequest)
          : await createResource(createResourceRequest);

      //Attach skills to resource
      const attachResourceSkillsRequest = data.skills.map(skill => skill.id)

      //Save quiz setting to resource
      const resourceQuizSettingRequest: ILearnResourceQuizSetting = {
        retries_limit: data.quizRetryUnlimited ? null : data.quizRetryLimit,
        complete_time_limit_min: data.quizTimeUnlimited ? null : data.quizTimeLimit,
        min_pass_score_percent: data.quizShouldComplete ? 100 : data.quizMinPassingScore,
      }

      await Promise.all([
        await attachResourceSkills(resource?.id, {
          skills: attachResourceSkillsRequest
        }),
        await saveResourceQuizSetting(resource?.id, resourceQuizSettingRequest),
        await uploadQuizQuestions(resource?.id, data.quizFile)
      ])
      closeModal(true);
    } catch (e: unknown) {
      console.log(e)
    }
  };

  return (
    <ComplexModal
      isOpen={isOpen}
      closeModal={closeModal}
      shouldCloseOnOverlayClick={false}
      headerTitle={<span>{Boolean(editableResource) ? 'Edit' : 'Add'} <span className="text-warning">Learning Resource</span></span>}
      HeaderTitleComponent={(
        <div className="flex items-center ml-auto mr-4">
          <div className="text-secondary text-sm font-normal mr-3">
            This learning resource is <b className={formData.is_active ? 'text-success' : ''}>{formData.is_active ? 'Active' : 'Inactive'}</b>
          </div>
          <Switch
            theme="success"
            readonly
            value={formData.is_active}
            onChange={(value) => onFieldChange('isActive', value)}
          />
        </div>
      )}
      headerSecondRow={tabsHeader}
      headerClass="pb-0"
      buttons={buttons}
    >
      <LearnForm
        editable={Boolean(editableResource)}
        tab={tabIndex}
        formData={formData}
        onTabChange={setTabIndex}
        onChange={onFormDataChange}
        onSubmit={onSubmit}
        onClose={closeModal}
        onSetModalButtons={setButtons}
      />
      {apiError && (
        <SavingError
          className="absolute w-full h-full top-0 lef-0 z-100"
          message={apiError}
          onClose={() => setApiError('')}
        />
      )}
    </ComplexModal>
  );
};
