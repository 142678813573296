import {
  getAccountabilityService,
  getAccountabilitiesService,
  setAccountabilityService,
  updateAccountabilityService,
  sendAccountabilitiesResult
} from "@common/api/services/accountabilities";
import { AccountabilitiesMembersFormParams, AccountabilitySendFormHandler } from "@common/types/forms/accountabilities";
import {
  AccountabilitiesSession,
  Accountability,
  SendAccountabilityValues,
  AccountabilitySetting,
  AccountabilitiesCurrentSession,
  AccountabilitiesMembersParams
} from "@common/types/reports/accountabilities/accountabilities";

export const getAccountabilitiesSession = async (id: number, successCallback: any) => {
  try {
    const response = await getAccountabilityService<AccountabilitiesSession>(
      `/client/scorecards/members/${id}/latest-session/`
    );
    successCallback(response.data.data);
  } catch (e) {
    console.error("Get accountabilities latest session by member error");
  }
};

export const getAccountabilitiesSessions = async (id: number, successCallback: any) => {
  try {
    const response = await getAccountabilityService<AccountabilitiesSession>(`/web/dashboard/scorecards-summary/${id}`);
    successCallback(response.data.data);
  } catch (e) {
    console.error("Get accountabilities sessions by member error");
  }
};

export const getRoleAccountabilityDetail = (roleId: number) => {
  return getAccountabilityService<AccountabilitySetting>(`/client/scorecards/roles/${roleId}/settings`).then(
    response => response.data.data
  );
};

export const setRoleAccountabilityDetail = (roleId: number, accountability: AccountabilitySetting) => {
  return setAccountabilityService<AccountabilitySetting>(
    `/client/scorecards/roles/${roleId}/settings`,
    accountability
  ).then(response => response.data);
};

export const updateRoleAccountabilityDetail = (roleId: number, accountability: AccountabilitySetting) => {
  return updateAccountabilityService<AccountabilitySetting>(
    `/client/scorecards/roles/${roleId}/settings`,
    accountability
  ).then(response => response.data);
};

export const getRoleAccountabilities = (roleId: number) => {
  return getAccountabilitiesService<Accountability>(`/client/scorecards/roles/${roleId}/accountabilities`).then(
    response => response.data.data
  );
};

export const getCurrentAccountabilitiesSession = async (id: number, callback: any) => {
  try {
    const response = await getAccountabilitiesService<AccountabilitiesCurrentSession>(
      `/web/scorecards/current-session/${id}`
    );
    callback(response.data.data);
  } catch (e: any) {
    console.error("Get accountabilities current session by member error");
  }
};

export const sendSelfAccountabilityResult = async (props: AccountabilitySendFormHandler) => {
  const { successCallback, failCallback, type, memberId, ...results } = props;
  try {
    // const memberId = $userStore.getUserInfo()?.member_id;
    const response = await sendAccountabilitiesResult(
      `/client/scorecards/current-session/${memberId}/${type}`,
      results
    );
    if (response) {
      successCallback();
    }
  } catch (e) {
    failCallback();
    console.error("Send self assessment results error");
  }
};

export const setRoleAccountabilities = (roleId: number, accountabilities: Omit<Accountability, "id">[]) => {
  return setAccountabilityService<Accountability>(`/client/scorecards/roles/${roleId}/accountabilities`, {
    accountabilities
  }).then(response => response.data);
};

export const setAccountability = async (memberId: number, assessmentType: number, values: SendAccountabilityValues) => {
  try {
    return await setAccountabilityService(`/client/scorecards/current-session/${memberId}/${assessmentType}`, values);
  } catch (e) {
    console.error("Set accountability error");
  }
};

export const getAccountabilitiesMembersParams = async (id: number, callback: any) => {
  try {
    const response = await getAccountabilityService<AccountabilitiesMembersParams>(
      `/client/scorecards/members/${id}/params`
    );
    return callback(response.data.data);
  } catch (e) {
    console.error("Get Accountabilities Members Params error");
  }
};

export const setAccountabilitiesMembersParams = async (id: number, values: AccountabilitiesMembersFormParams) => {
  return await setAccountabilityService(`/client/scorecards/members/${id}/params`, values);
};
