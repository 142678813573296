import React from "react";
import classNames from "classnames";
import { Controller } from "react-hook-form";
import Select, { CSSObjectWithLabel, SingleValue } from "react-select";
import { Svg } from "../svg";
import styles from "./select.module.scss";
import { StylesConfig } from "react-select/dist/declarations/src/styles";

export type SelectOptionsProp = {
  value: any;
  label: string;
};

// TODO: add types for control

export interface SelectProps {
  options: SelectOptionsProp[];
  defaultValue?: SelectOptionsProp;
  value?: SelectOptionsProp;
  classNamePrefix?: string;
  name: string;
  className?: string;
  control?: any;
  placeholder?: string;
  isSearchable?: boolean;
  isDisabled?: boolean;
  styles?: StylesConfig<SingleValue<SelectOptionsProp>, false>;
  onChange?: (newValue: SingleValue<SelectOptionsProp>) => void;
}

export const SelectUI = (props: SelectProps) => {
  const className = classNames(props.className, "select");
  const componentProps = {
    ...props,
    classNamePrefix: "select",
    className,
    isSearchable: props.isSearchable ?? false
  };
  const onChangeHandler = (newValue: SingleValue<SelectOptionsProp>, controlChange: any) => {
    controlChange(newValue?.value);
    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  return props.control ? (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field: { value, onChange } }) => {
        return (
          <Select
            {...componentProps}
            value={props.options.filter(option => value == option.value)}
            defaultValue={props.options.filter(option => value == option.value)}
            onChange={option => onChangeHandler(option, onChange)}
            menuPosition="fixed"
            menuPlacement="bottom"
          />
        );
      }}
    />
  ) : (
    <Select
      {...componentProps}
      styles={{
        option: (styles: CSSObjectWithLabel) => ({ ...styles, whiteSpace: "nowrap" })
      }}
    />
  );
};

const DownIcon = () => <Svg name="chevron-down" modClass={styles["dropdown-indicator"]} />;

export const SelectFilter = (props: SelectProps) => {
  const className = classNames(props.className, styles["selector-wrapper"]);
  const componentProps = {
    ...props,
    styles: {
      control: (styles: CSSObjectWithLabel) => ({ ...styles, minHeight: 27 }),
      valueContainer: (styles: CSSObjectWithLabel) => ({ ...styles, height: 27 }),
      placeholder: (styles: CSSObjectWithLabel) => ({ ...styles, fontSize: 14, lineHeight: "19px" }),
      input: (styles: CSSObjectWithLabel) => ({ ...styles, fontSize: 14, lineHeight: "19px" }),
      singleValue: (styles: CSSObjectWithLabel) => ({ ...styles, fontSize: 14, lineHeight: "19px" }),
      menu: (styles: CSSObjectWithLabel) => ({ ...styles, fontSize: 14, lineHeight: "19px" }),
      indicatorSeparator: (styles: CSSObjectWithLabel) => ({ ...styles, marginTop: 0, marginBottom: 0 })
    },
    classNamePrefix: "select",
    className,
    isSearchable: props.isSearchable ?? false
  };
  return (
    <Select
      {...componentProps}
      components={{
        DropdownIndicator: DownIcon
      }}
    />
  );
};
