import React, { useContext, useEffect } from "react";
import styles from "./course-notify-modal.module.scss";
import { ComplexModal, ShadowBlock, Svg } from "@ui";
import textColors from "@styles/modules/common/text-colors.module.scss";
import classNames from "classnames";
import { NotifyModal } from "./types";
import { GlobalStore } from "@common/api/store/global";

interface Props extends NotifyModal {
  isOpenModal: boolean;
  toggleModal(): void;
}

export const CourseNotifyModal = ({ isOpenModal, toggleModal, url, logo, authorName, title }: Props) => {
  const { $profileStore } = useContext(GlobalStore);
  useEffect(() => {
    setTimeout(() => {
      window.open(url);
      toggleModal();
    }, 1000);
  }, []);

  return (
    <ComplexModal
      isOpen={isOpenModal}
      closeModal={toggleModal}
      modClass="!w-auto"
      headerClass="hidden"
      bodyClass={classNames(styles["modal-body"], "bg-transparent")}
      shouldCloseOnOverlayClick={false}
    >
      <ShadowBlock modClass={classNames(styles.block, "cursor-pointer")}>
        <div className={classNames(styles.logo, "w-full flex")}>
          <img src={logo} alt="" title="" className={styles.image} />
          <Svg name="logo-new" modClass="text-primary w-15 h-15 ml-auto mb-auto animate-spin" />
        </div>
        <div className={styles.title}>You are now leaving {$profileStore.companySetting?.portal_name}</div>
        <div className={styles.description}>
          Don’t worry, you are being auto-directed to <span className={textColors.lightorange}>{authorName}</span> where
          your class <span className={textColors.lightorange}>{title}</span> is waiting for you
        </div>
      </ShadowBlock>
    </ComplexModal>
  );
};
