import { FC, useCallback } from "react";
import { IRoleForm } from "@common/types/forms/role";
import { ComplexModalButtons } from "@ui";
import { RoleDetailsTab } from "./details-tab";
import { RoleSkillsTab } from "./skills-tab";
import { RoleAssessmentsTab } from "./assessments-tab";
import { RoleAccountabilityTab } from "./accountability-tab";

export interface IRoleFormProps {
  tab: number;
  formData: Partial<IRoleForm>;
  onTabChange(tab: number): void;
  onChange(data: Partial<IRoleForm>): Partial<IRoleForm>;
  onSubmit(data: Partial<IRoleForm>): void;
  onClose(): void;
  onSetModalButtons(buttons?: ComplexModalButtons): void;
}

export interface IRoleFormTabInitData {
  buttons?: ComplexModalButtons;
}

export const RoleForm: FC<IRoleFormProps> = ({
  tab,
  formData,
  onTabChange,
  onChange,
  onSubmit,
  onClose,
  onSetModalButtons
}) => {
  const onInitForm = useCallback((data: IRoleFormTabInitData) => {
    onSetModalButtons(data.buttons);
  }, []);

  const gotoPrevTab = () => {
    if (tab === 0) {
      onClose();
    } else {
      onTabChange(tab - 1);
    }
  };

  const gotoNextTab = (data?: Partial<IRoleForm>) => {
    if (tab === 3) {
      onSubmit(data as Partial<IRoleForm>);
    } else {
      onTabChange(tab + 1);
    }
  };

  return (
    <div>
      {tab === 0 && (
        <RoleDetailsTab
          formData={formData}
          onInit={onInitForm}
          onBack={gotoPrevTab}
          onNext={gotoNextTab}
          onSubmit={onChange}
          onClose={onClose}
        />
      )}
      {tab === 1 && (
        <RoleSkillsTab
          formData={formData}
          onInit={onInitForm}
          onBack={gotoPrevTab}
          onNext={gotoNextTab}
          onSubmit={onChange}
          onClose={onClose}
        />
      )}
      {tab === 2 && (
        <RoleAssessmentsTab
          formData={formData}
          onInit={onInitForm}
          onBack={gotoPrevTab}
          onNext={gotoNextTab}
          onSubmit={onChange}
          onClose={onClose}
        />
      )}
      {tab === 3 && (
        <RoleAccountabilityTab
          formData={formData}
          onInit={onInitForm}
          onBack={gotoPrevTab}
          onNext={gotoNextTab}
          onSubmit={onChange}
          onClose={onClose}
        />
      )}
    </div>
  );
};
