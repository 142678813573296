import * as yup from "yup";
import YupPassword from "yup-password";
import { ResetPasswordInterface } from "../reset-password/model";
YupPassword(yup);

export const defaultValues: ResetPasswordInterface = {
  email: ""
};

export const forgotFormSchema: yup.SchemaOf<ResetPasswordInterface> = yup.object().shape({
  email: yup.string().required("This field is required").email("The field must be in email format")
});
