import { Button, FormInputDatePicker, FormWrapper, SelectOptionsProp, SelectUI, Titles } from "@ui";
import modalStyles from "@styles/modules/complex-modal.module.scss";
import styles from "./role.module.scss";
import classNames from "classnames";
import grid from "@styles/modules/grid.module.scss";
import form from "@styles/modules/form.module.scss";
import {
  AssignRoleForm,
  CurrentRole,
  EditPersonInterface,
  PeopleProfileInterface
} from "@common/types/reports/profile/profile";
import { useEffect, useMemo, useState } from "react";
import { getClientOrgUnits } from "@common/features/org-units/model";
import { OrgUnit } from "@common/types/reports/org-units/org-units";
import { getRoleByOrgUnit, updateMemberRole } from "@common/features/roles/model";
import { getAvailableManagers } from "@common/features/profile/model";
import { assignRoleSchema } from "./helpers";
import { useForm } from "@common/hooks/form";
import { FieldErrors } from "react-hook-form";
import { SendingManager } from "@common/types/managment/edit-person";
import { Role } from "@common/types/roles/roles";
import { $RoleInfoStore } from "../../../model";

interface Props extends SendingManager {
  role?: CurrentRole;
  managerId?: number;
  memberId: number;
}

export const RoleTab = ({ role, managerId, isSending, setSendingStatus, memberId }: Props) => {
  const [orgUnits, setOrgUnits] = useState<OrgUnit[]>();
  const [roles, setRoles] = useState<Role[]>();
  const [managers, setManagers] = useState<PeopleProfileInterface[]>();

  const [errorMessage, setErrorMessage] = useState<string>();

  const [currentOrgUnit, setCurrentOrgUnit] = useState<number | undefined>(undefined);

  const [currentRole, setCurrentRole] = useState<number | undefined>(undefined);
  const [currentManager, setCurrentManager] = useState<number | undefined>(undefined);
  const [currentDate, setCurrentDate] = useState<string | undefined>(undefined);

  const checkForm = (): boolean => {
    return !!currentOrgUnit && !!currentRole && !!currentManager && !!currentDate;
  };

  const [isSaveButtonShow, setSaveButtonStatus] = useState<boolean>(checkForm());

  useEffect(() => {
    getClientOrgUnits(setOrgUnits);
  }, []);

  useEffect(() => {
    if (currentOrgUnit) {
      getRoleByOrgUnit(currentOrgUnit, setRoles);
    }
  }, [currentOrgUnit]);

  useEffect(() => {
    if (currentRole) {
      getAvailableManagers(currentRole, setManagers);
    }
  }, [currentRole]);

  const orgUnitsSelect: SelectOptionsProp[] = useMemo(() => {
    orgUnits?.length &&
      setCurrentOrgUnit(
        role?.role?.unit?.id && orgUnits.filter(orgUnit => Number(orgUnit.id) === role?.role.unit.id).length
          ? Number(role?.role?.unit?.id)
          : undefined
      );
    return orgUnits?.length
      ? orgUnits.reduce((previous: SelectOptionsProp[], current) => {
          return [...previous, { value: Number(current.id), label: current.name }];
        }, [])
      : [];
  }, [orgUnits, role]);

  const rolesSelect: SelectOptionsProp[] = useMemo(() => {
    roles?.length &&
      setCurrentRole(
        role?.role?.id && roles.filter(item => item.id === role.role.id) ? Number(role?.role?.id) : undefined
      );
    return roles?.length
      ? roles.reduce((previous: SelectOptionsProp[], current) => {
          return [...previous, { value: Number(current.id), label: current.name }];
        }, [])
      : [];
  }, [roles, role]);

  const managersSelect: SelectOptionsProp[] = useMemo(() => {
    if (managers?.length) {
      setCurrentManager(
        managerId && managers.filter(manager => Number(manager.id) === managerId).length ? managerId : undefined
      );
      setCurrentDate(role?.date_started ? role.date_started : undefined);
    }

    managers?.length === 1 && setCurrentManager(Number(managers[0].id));

    return managers?.length
      ? managers.reduce((previous: SelectOptionsProp[], current) => {
          return [
            ...previous,
            {
              value: Number(current.id),
              label: `${current.first_name} ${current.last_name}`
            }
          ];
        }, [])
      : [];
  }, [managers, role, managerId]);

  const onChangeHandler = (data: any): number => {
    return data.value;
  };

  const methods = useForm<AssignRoleForm>({
    schema: assignRoleSchema
  });

  const handleSuccess = (values: AssignRoleForm) => {
    updateMemberRole(
      memberId,
      {
        role: {
          id: values.role,
          date_started: values.startDate
        },
        manager: {
          id: values.manager,
          date_started: values.startDate
        }
      },
      () => $RoleInfoStore.updateRoleInfo(memberId),
      (error: any) => console.log("error :>> ", error)
    ).finally(() => setSendingStatus(false));
  };

  const handleError = (errors: FieldErrors<AssignRoleForm>) => {
    console.error(errors);
  };

  useEffect(() => {
    methods.reset({
      orgUnit: currentOrgUnit ?? 0,
      role: currentRole ?? 0,
      manager: currentManager ?? 0,
      startDate: currentDate ?? ""
    });
    setSaveButtonStatus(!!currentOrgUnit && !!currentRole && !!currentManager && !!currentDate);
  }, [methods, currentOrgUnit, currentManager, currentRole, currentDate]);

  return (
    <div className={modalStyles.row}>
      <FormWrapper onSubmit={methods.handleSubmit(handleSuccess, handleError)}>
        <Titles level="level_3">Update Role</Titles>
        {!!orgUnitsSelect && (
          <div className={styles.roles}>
            <div className={classNames(grid.list, styles["grid-list"], form.row)}>
              <div className={classNames(styles["grid-item"], styles["grid-active"])}>
                <div className={classNames(form.item, form["item-vertical"])}>
                  <div className={form["item-label"]}>1. Org Unit</div>
                  <SelectUI
                    onChange={data => {
                      setCurrentRole(undefined);
                      setCurrentManager(undefined);
                      setManagers([]);
                      setCurrentOrgUnit(onChangeHandler(data));
                    }}
                    className={styles["roles-input"]}
                    name="orgUnit"
                    options={orgUnitsSelect}
                    control={methods.control}
                  />
                </div>
              </div>
              {!!rolesSelect?.length && (
                <div className={classNames(styles["grid-item"], styles["grid-active"])}>
                  <div className={classNames(form.item, form["item-vertical"])}>
                    <div className={form["item-label"]}>2. Role</div>
                    <SelectUI
                      className={styles["roles-input"]}
                      name="role"
                      options={rolesSelect}
                      control={methods.control}
                      onChange={data => {
                        setCurrentManager(undefined);
                        setCurrentRole(onChangeHandler(data));
                      }}
                    />
                  </div>
                </div>
              )}
              {!!managersSelect?.length && (
                <div className={classNames(styles["grid-item"], styles["grid-active"])}>
                  <div className={classNames(form.item, form["item-vertical"])}>
                    <div className={form["item-label"]}>3. Manager</div>
                    <SelectUI
                      onChange={data => {
                        setCurrentManager(onChangeHandler(data));
                      }}
                      className={styles["roles-input"]}
                      name="manager"
                      options={managersSelect}
                      control={methods.control}
                    />
                  </div>
                </div>
              )}
              {!!currentManager && (
                <div className={classNames(styles["grid-item"], styles["grid-active"])}>
                  <div className={classNames(form.item, form["item-vertical"])}>
                    <div className={form["item-label"]}>4. Start Date</div>
                    <FormInputDatePicker
                      onChange={(date: string) => {
                        setCurrentDate(date);
                      }}
                      className={styles["roles-input"]}
                      name="startDate"
                      placeholder="Start Date"
                      register={methods?.register}
                      error={methods?.formState.errors["startDate"]?.message}
                    />
                  </div>
                </div>
              )}
              <div className={classNames(styles["grid-item"], styles["grid-button"], styles["button-item"])}>
                <Button
                  title="Update Role"
                  className={styles.button}
                  size="thin"
                  type="submit"
                  {...((isSending || !isSaveButtonShow) && { disabled: true })}
                />
              </div>
            </div>
          </div>
        )}
      </FormWrapper>
      {errorMessage && <div className={styles.error}>{errorMessage}</div>}
    </div>
  );
};
