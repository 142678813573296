import { useContext, useEffect, useMemo, useState } from "react";
import {
  AlertModal,
  Button,
  FormInputDatePicker,
  FormInputSelect,
  FormInputText,
  FormWrapper,
  SelectOptionsProp,
  SelectUI,
  Svg
} from "@ui";
import classNames from "classnames";
import modalStyles from "@styles/modules/complex-modal.module.scss";
import styles from "./details.module.scss";
import grid from "@styles/modules/grid.module.scss";
import form from "@styles/modules/form.module.scss";
import { Avatar } from "@features/people";
import { PeopleFormSendInterface, PeopleProfileInterface } from "@common/types/reports/profile/profile";
import { FieldErrors } from "react-hook-form";
import { EditProfileSchema } from "./model";
import { useForm } from "@common/hooks/form";
import { CommonWithId } from "@common/types/common";
import { $destinationDataStore } from "@common/api/store/destination";
import { getTimezones } from "@common/features/timezones/model";
import { $employmentTypesStore } from "@common/api/store/employment-types";
import { getEmploymentTypes } from "@common/features/employment-types/model";
import { $workTypesStore } from "@common/api/store/work-types";
import { getWorkTypes } from "@common/features/work-types/model";
import { getOffices } from "@common/features/offices/model";
import { getWebMemberProfile, setPeopleProfile } from "@common/features/profile/model";
import { getCalendarData } from "@common/helpers/data-helpers";
import { downloadAssessmentReport } from "@common/features/assessments/model";
import { SendingManager, UserModalProps } from "@common/types/managment/edit-person";
import { PersonProfile } from "../../..";
import { GlobalStore } from "@common/api/store/global";
import { Roles } from "@common/constants";
import { loginAs } from "@common/features/user/model";
import { setPeopleProfilePhotoService } from "@common/api/services/profile";
import { OfficeDetailInterface } from "@common/types/reports/offices/office-detail";
import { CSSObjectWithLabel } from "react-select";

interface Props extends SendingManager, UserModalProps {
  personProfile?: PersonProfile;
  setPersonProfile: (data: PersonProfile) => void;
}

export const DetailsTab = ({ personProfile, setPersonProfile, isSending, setSendingStatus, isEditModal }: Props) => {
  const { $profileStore } = useContext(GlobalStore);
  const [profile, setProfile] = useState<PeopleProfileInterface>();
  const [timezones, setTimezones] = useState<CommonWithId[]>();
  const [offices, setOffices] = useState<OfficeDetailInterface[]>();
  const [employmentTypes, setEmploymentTypes] = useState<CommonWithId[]>();
  const [workTypes, setWorkTypes] = useState<CommonWithId[]>();
  const [savingImage, setSetSavingImage] = useState<File | undefined>();
  const [isSendingError, setSendingErrorStatus] = useState<boolean | string>(false);

  const methods = useForm<PeopleFormSendInterface>({
    schema: EditProfileSchema
  });

  const workTypeId = methods.watch("work_type_id");
  const officeId = methods.watch("office_id");

  const handleSuccess = async (values: PeopleFormSendInterface) => {
    setSendingStatus(true);
    const response = setPeopleProfile(
      {
        ...values
      },
      personProfile?.id && personProfile.id
    );
    response
      .then(data => {
        if (savingImage) {
          setPeopleProfilePhotoService(+data.id, savingImage);
        }
        !data && setSendingErrorStatus(true);
        if (data) {
          setPersonProfile({
            id: Number(data.id),
            full_name: data.first_name ?? ""
          });
        }
      })
      .catch(e => {
        setSendingErrorStatus(e?.response?.data.errors[0].title);
      })
      .finally(() => setSendingStatus(false));
  };
  const handleError = (errors: FieldErrors<PeopleFormSendInterface>) => {
    console.error(errors);
  };

  useEffect(() => {
    if (personProfile) {
      getWebMemberProfile(personProfile.id, setProfile);
    }
  }, [personProfile]);

  useEffect(() => {
    const $timezones = $destinationDataStore.getTimezones();
    if ($timezones.length) {
      setTimezones($timezones);
    } else {
      getTimezones(setTimezones);
    }
  }, []);

  useEffect(() => {
    const $offices = $destinationDataStore.getOffices();
    if ($offices.length) {
      setOffices($offices as unknown as OfficeDetailInterface[]);
    } else {
      getOffices(setOffices);
    }
  }, []);

  useEffect(() => {
    const $employmentTypes = $employmentTypesStore.getTypes();
    if ($employmentTypes.length) {
      setEmploymentTypes($employmentTypes);
    } else {
      getEmploymentTypes(setEmploymentTypes);
    }
  }, []);

  useEffect(() => {
    const $workTypes = $workTypesStore.getTypes();
    if ($workTypes.length) {
      setWorkTypes($workTypes);
    } else {
      getWorkTypes(setWorkTypes);
    }
  }, []);

  useEffect(() => {
    methods.reset({
      first_name: profile?.first_name ?? "",
      ...(profile?.middle_name && { middle_name: profile?.middle_name }),
      last_name: profile?.last_name ?? "",
      ...(profile?.linkedin && { linkedin: profile?.linkedin }),
      ...(profile?.primary_phone && { primary_phone: profile?.primary_phone }),
      email: profile?.email ?? "",
      industry_experience: profile?.industry_experience ?? 0,
      start_date: profile?.start_date ? getCalendarData(profile.start_date * 1000) : getCalendarData(Date.now()),
      timezone_id: profile?.timezone?.id ? Number(profile?.timezone?.id) : 18,
      office_id: Number(profile?.office?.id) ?? 0,
      employment_type_id: profile?.employment_type?.id ? Number(profile?.employment_type?.id) : 1,
      ...(profile?.work_type?.id && {
        work_type_id: Number(profile.work_type.id)
      })
    });
  }, [profile, methods]);

  const timezonesEdited: SelectOptionsProp[] | undefined = timezones
    ? timezones.map(item => {
        return {
          value: Number(item.id),
          label: item.name
        };
      })
    : undefined;

  const officesEdited: SelectOptionsProp[] | undefined = useMemo(
    () =>
      offices
        ? offices
            .filter(office => (workTypeId !== 1 || office.is_virtual) && (workTypeId !== 3 || !office.is_virtual))
            .map(item => ({
              value: Number(item.id),
              label: item.name
            }))
        : undefined,
    [offices, workTypeId]
  );

  useEffect(() => {
    if (officesEdited && !officesEdited.find(office => office.value === officeId)) {
      methods.resetField("office_id", { defaultValue: null });
    }
  }, [methods, officeId, officesEdited]);

  const employmentTypesEdited: SelectOptionsProp[] | undefined = employmentTypes
    ? employmentTypes.map(item => {
        return {
          value: Number(item.id),
          label: item.name
        };
      })
    : undefined;

  const workTypesEdited: SelectOptionsProp[] | undefined = workTypes
    ? workTypes.map(item => {
        return {
          value: Number(item.id),
          label: item.name
        };
      })
    : undefined;

  //TODO: Move getting session id to abstract

  const reportSessionId = personProfile?.report ? (personProfile.report.toString().match(/\d+/) || [])[0] : null;

  const handleImpersonate = () => {
    if (personProfile) {
      loginAs(personProfile.id);
    }
  };

  const rowClass = classNames(modalStyles["two-columns"], modalStyles.row, styles.row);
  return (
    <>
      <FormWrapper onSubmit={methods.handleSubmit(handleSuccess, handleError)}>
        <div className={rowClass}>
          <div
            className={classNames(
              modalStyles["two-columns-left"],
              modalStyles["separate-column"],
              "flex flex-col items-center"
            )}
          >
            <Avatar id={personProfile?.id} selectedProfile={profile} setSavingImage={file => setSetSavingImage(file)} />
            {$profileStore.currentRoleId === Roles.clientAdmin && (
              <div
                className="flex items-center text-sm text-[#112d66] cursor-pointer hover:underline"
                onClick={handleImpersonate}
              >
                Login as {personProfile?.full_name}
                <Svg name="login" modClass="ml-2 w-4 h-4" />
              </div>
            )}
          </div>
          <div className={classNames(modalStyles["two-columns-right"], styles["main-info"])}>
            <div className={classNames(grid.list, form.row)}>
              <div className={classNames(grid.item, grid["item-4x"])}>
                <FormInputText
                  name="first_name"
                  placeholder="First Name"
                  register={methods?.register}
                  error={methods?.formState.errors["first_name"]?.message}
                />
              </div>
              <div className={classNames(grid.item, grid["item-4x"])}>
                <FormInputText
                  name="middle_name"
                  placeholder="Middle Name"
                  register={methods?.register}
                  error={methods?.formState.errors["middle_name"]?.message}
                />
              </div>
              <div className={classNames(grid.item, grid["item-4x"])}>
                <FormInputText
                  name="last_name"
                  placeholder="Last Name"
                  register={methods?.register}
                  error={methods?.formState.errors["last_name"]?.message}
                />
              </div>
            </div>
            <div className={classNames(grid.list, form.row)}>
              <div className={classNames(grid.item, grid["item-8x"])}>
                <FormInputText
                  name="linkedin"
                  placeholder="Linkedin URL"
                  register={methods?.register}
                  error={methods?.formState.errors["linkedin"]?.message}
                />
              </div>
              <div className={classNames(grid.item, grid["item-4x"])}>
                <FormInputText
                  name="primary_phone"
                  placeholder="Phone Number"
                  register={methods?.register}
                  error={methods?.formState.errors["primary_phone"]?.message}
                />
              </div>

              {timezonesEdited && (
                <div className={classNames(grid.item, grid["item-12x"], "mt-6")}>
                  <SelectUI
                    placeholder="Time zone select"
                    name="timezone_id"
                    options={timezonesEdited}
                    control={methods.control}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={rowClass}>
          <div className={modalStyles["two-columns-left"]}>
            {reportSessionId && (
              <span
                className={classNames(styles.link, styles.report)}
                onClick={() => downloadAssessmentReport(Number(reportSessionId))}
              >
                <span className={styles["link-inner"]}>
                  <span>Download this user’s Scorecard</span>
                  <span className={styles["report-icon"]} />
                </span>
              </span>
            )}
          </div>
          <div className={modalStyles["two-columns-right"]}>
            <div className={classNames(grid.list, form.row, styles["horizontal-row"])}>
              <div className={classNames(form.item, form["item-horizontal"], grid.item)}>
                <div className={form["item-label"]}>Industry experience (Years)</div>
                <FormInputText
                  className={styles.industry}
                  name="industry_experience"
                  placeholder="Years"
                  register={methods?.register}
                  error={methods?.formState.errors["industry_experience"]?.message}
                />
              </div>
              <div className={classNames(form.item, form["item-horizontal"], grid.item)}>
                <div className={form["item-label"]}>Start date</div>
                <FormInputDatePicker
                  name="start_date"
                  dateTime={profile?.start_date ? profile?.start_date * 1000 : undefined}
                  className={styles.start}
                  placeholder="Start Date"
                  register={methods?.register}
                  error={methods?.formState.errors["start_date"]?.message}
                  onChange={date => methods?.setValue("start_date", date)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={rowClass}>
          <div className={classNames(modalStyles["two-columns-left"], modalStyles["separate-column"])}></div>
          <div className={modalStyles["two-columns-right"]}>
            <div className={classNames(grid.list, form.row)}>
              {employmentTypesEdited && (
                <div className={classNames(grid.item, grid["item-4x"])}>
                  <div className={classNames(form.item, form["item-vertical"])}>
                    <div className={form["item-label"]}>Employment type</div>
                    <SelectUI name="employment_type_id" options={employmentTypesEdited} control={methods.control} />
                  </div>
                </div>
              )}
              {workTypesEdited && (
                <div className={classNames(grid.item, grid["item-4x"])}>
                  <div className={classNames(form.item, form["item-vertical"])}>
                    <div className={form["item-label"]}>Working from</div>
                    <SelectUI name="work_type_id" options={workTypesEdited} control={methods.control} />
                  </div>
                </div>
              )}
              {officesEdited && (
                <div className={classNames(grid.item, grid["item-4x"])}>
                  <div className={classNames(form.item, form["item-vertical"])}>
                    <div className={form["item-label"]}>Office</div>
                    <FormInputSelect
                      methods={methods}
                      list={officesEdited}
                      name="office_id"
                      type="select"
                      getItemLabel={option => option.label}
                      getItemValue={option => option.value}
                      error={methods?.formState.errors["office_id"]?.message}
                      styles={{
                        control: (styles: CSSObjectWithLabel) => ({
                          ...styles,
                          background: "none !important",
                          border: "none !important"
                        }),
                        valueContainer: (styles: CSSObjectWithLabel) => ({
                          ...styles,
                          padding: "0 9px 0 !important"
                        }),
                        singleValue: (styles: CSSObjectWithLabel) => ({
                          ...styles,
                          color: "#3478bc !important"
                        })
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={classNames(grid.list, form.row)}>
              {officesEdited && (
                <div className={classNames(grid.item, grid["item-8x"])}>
                  <div className={classNames(form.item, form["item-vertical"])}>
                    <div className={form["item-label"]}>Work email address</div>
                    <FormInputText
                      name="email"
                      placeholder="Email"
                      register={methods?.register}
                      error={methods?.formState.errors["email"]?.message}
                    />
                  </div>
                </div>
              )}
              <div className={classNames(grid.item, grid["item-4x"], styles.save)}>
                <Button type="submit" title="Save changes" size="thin" {...(isSending && { disabled: true })} />
              </div>
            </div>
          </div>
        </div>
        <div className={rowClass}>
          <div className={styles.note}>
            -{" "}
            {personProfile
              ? "Update the details and click on Save changes. You can click on other tabs for additional information about the person"
              : "Enter the  details, click on Save changes, then click on Next to move to the next tab to assign a role."}
          </div>
        </div>
      </FormWrapper>
      <AlertModal
        cancelButtonText="Close"
        message={
          typeof isSendingError === "string"
            ? isSendingError
            : "Something went wrong :-( We are working on it. Please try again later"
        }
        title="Alert"
        type="fail"
        closeModal={() => setSendingErrorStatus(false)}
        isOpen={!!isSendingError}
      />
    </>
  );
};
