import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getLearningPaths } from "@common/features/learn/model";
import { getPhrases } from "@common/helpers/localization";
import { YourLearningPathsInterface } from "@common/types/reports/learn/learn";
import { Carousel } from "@features/learn/carousels/carousel";
import { Course } from "../../types";
import { CompletedSwitcher } from "../completed-switcher";
import { observer } from "mobx-react-lite";
import { GlobalStore } from "@common/api/store/global";

export const YourLearningPaths = observer(() => {
  const { $learnStore } = useContext(GlobalStore);
  const loc_your_learning_paths_section = getPhrases("learn_page", "your_learning_paths_section");

  const [data, setData] = useState<YourLearningPathsInterface[]>();
  const [isShowCompleted, setShowCompletedStatus] = useState<boolean>(false);

  const refresh = useCallback(
    () =>
      getLearningPaths(
        setData,
        `filter[for_you]=true${
          !!isShowCompleted ? "" : "&filter[progress_status][]=not_started&filter[progress_status][]=in_progress"
        }`
      ),
    [isShowCompleted]
  );
  useEffect(() => {
    refresh();
  }, [refresh, isShowCompleted]);

  const editedData: Course[] = useMemo(() => {
    $learnStore.setEmployeeResourceAvailability("learningPaths", !!data?.length);
    return data?.length
      ? data.reduce((previous: Course[], current) => {
          return [
            ...previous,
            {
              id: current.id,
              title: current.title,
              category: current.provider?.name || "",
              ...(current.expertise_level?.name && {
                difficulty: current.expertise_level.name
              }),
              rating: current.rating_average,
              totalRatings: current.rating_count,
              duration: current.duration,
              heroImgSrc: current.image_url,
              ...(current.provider?.favicon_image_url && {
                authorImgSrc: current.provider?.favicon_image_url
              }), // BE issue
              ...(current.provider?.name && {
                authorName: current.provider?.name
              }), // BE issue
              // isFavourite: current.is_favourite,
              isCompleted: current.progress === 100, // BE issue,
              isFree: current.is_free,
              progress: current.progress
            }
          ];
        }, [])
      : [];
  }, [data, $learnStore]);

  return editedData.length ? (
    <Carousel
      leftHeading={loc_your_learning_paths_section.your_learning_paths}
      leftSubHeading={"Resources which allow you to continue learning on whatever subject you desire."}
      rightHeading={
        <CompletedSwitcher
          isShowCompleted={isShowCompleted}
          setShowCompletedStatus={() => setShowCompletedStatus(prev => !prev)}
        />
      }
      CoursesData={editedData}
      isLearningPath={true}
      refresh={refresh}
    />
  ) : null;
});
