import { IDataRowProps } from "ka-table/props";
import React from "react";
import { CategoryLevel } from "./category-level";
import { LastLevel } from "./last-level";

export const IntermediateLevel: React.FC<IDataRowProps> = ({ rowData }) => {
  return (
    <>
      {!!rowData.inside.children?.length && <CategoryLevel data={rowData.inside.children} />}
      {!!rowData.inside.members?.length && <LastLevel data={rowData.inside.members} />}
    </>
  );
};
