import {
  MyProfileInterface,
  PeopleProfileInterface,
  PeopleFormSendInterface,
  PeopleSendStatusServiceInterface,
  AvatarUploadResultInterface,
  CompanyFormInterface,
  CompanySetting
} from "@common/types/reports/profile/profile";
import { AxiosResponse } from "axios";
import { HttpService } from "../http";
import { DataWrapper } from "../types/response/common";
import { $API_URL } from "@common/api/config";

export const getProfile = async <T>(url: string): Promise<AxiosResponse<DataWrapper<T>>> => {
  return HttpService.get<DataWrapper<T>>(url);
};

export const setProfile = async (values: MyProfileInterface): Promise<AxiosResponse<PeopleProfileInterface>> => {
  return HttpService.post<PeopleProfileInterface>("/web/profile/me", values);
};

export const setPeopleProfileService = async <T>(
  url: string,
  values: PeopleFormSendInterface
): Promise<AxiosResponse<DataWrapper<T>>> => {
  return HttpService.post<DataWrapper<T>>(url, values);
};

export const setPeopleProfileStatusService = async (
  id: number,
  values: PeopleSendStatusServiceInterface
): Promise<AxiosResponse<PeopleSendStatusServiceInterface>> => {
  return HttpService.post<PeopleSendStatusServiceInterface>(`/client/organization/members/${id}`, values);
};

export const setCompanySettingService = async (
  values: CompanyFormInterface
): Promise<AxiosResponse<CompanySetting>> => {
  return HttpService.post<CompanySetting>("client/organization/company", values);
};

export const setPeopleProfilePhotoService = async (
  id: number,
  value: File
): Promise<DataWrapper<AvatarUploadResultInterface>> => {
  const formData = new FormData();
  formData.append("image", value);

  return await fetch(`${$API_URL}/client/organization/members/${id}/avatar`, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    }
  }).then(async res => {
    return res.json();
  });
};

export const setCompanyLogoService = async (
  id: number,
  value: File
): Promise<DataWrapper<AvatarUploadResultInterface>> => {
  const formData = new FormData();
  formData.append("file", value);

  return await fetch(`${$API_URL}/client/organization/company/logo`, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    }
  }).then(async res => {
    return res.json();
  });
};

export const getProfilesList = async <T>(url: string): Promise<AxiosResponse<DataWrapper<T[]>>> => {
  return HttpService.get<DataWrapper<T[]>>(url);
};
