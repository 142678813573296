import { getPhrases } from "@common/helpers/localization";
import { APICallbacks } from "@common/types/forms/common";
import * as yup from "yup";
import YupPassword from "yup-password";
YupPassword(yup);

export interface AccountActivationInterfaceFunction extends APICallbacks {}

export interface AccountActivationFormInterface {
  password?: string;
  passwordConfirm?: string;
}

export interface AccountActivationSendFormInterface {
  token: string;
  password: string;
}

export interface AccountActivationSendFormInterfaceFunction extends AccountActivationSendFormInterface, APICallbacks {}

export const defaultValues: AccountActivationFormInterface = {
  password: "",
  passwordConfirm: ""
};

const loc = getPhrases("common", "forms").validation;
const passwordMinSpecial = `${loc.password} ${loc.min_special_middle}`;

// TODO: Move password schema to common

const passwordSchema = yup
  .string()
  .required(loc.required)
  .min(12, `${loc.min_characters_pre} 12 ${loc.min_characters_post}`)
  .minLowercase(4, `${passwordMinSpecial} 4 ${loc.min_lowercase_post}`)
  .minUppercase(2, `${passwordMinSpecial} 2 ${loc.min_uppercase_post}`)
  .minNumbers(2, `${passwordMinSpecial} 2 ${loc.digits}`)
  .minSymbols(2, `${passwordMinSpecial} 2 ${loc.symbols}`);

export const accountActivationFormSchema: yup.SchemaOf<AccountActivationFormInterface> = yup.object().shape({
  password: passwordSchema,
  passwordConfirm: passwordSchema.oneOf([yup.ref("password"), null], loc.passwords_must_match)
});
