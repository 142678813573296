import { FC, ReactElement, useEffect, useState } from "react";
import classNames from "classnames";
import "./button-group.scss";

export interface IButton {
  label: string | ReactElement;
  value: any;
  disabled?: boolean;
}

interface IButtonGroupProps {
  className?: string;
  buttons: IButton[];
  value?: any;
  buttonClass?: string;
  activeButtonClass?: string;
  disabledButtonClass?: string;
  error?: string;
  onChange?: (value: any) => void;
}

export const ButtonGroup: FC<IButtonGroupProps> = ({
  className,
  buttons,
  value,
  buttonClass,
  activeButtonClass,
  disabledButtonClass,
  error,
  onChange = () => {}
}) => {
  const [selected, setSelected] = useState(value);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  const onButtonClick = (button: IButton) => {
    setSelected(button.value);
    onChange(button.value);
  };

  return (
    <div className={classNames("button-group", error && "invalid", className)}>
      {buttons.map((item, i) => (
        <button
          key={item.value}
          className={classNames(
            buttonClass,
            { active: item.value === selected },
            item.value === selected && activeButtonClass,
            item.disabled && disabledButtonClass
          )}
          type="button"
          disabled={item.disabled}
          onClick={() => onButtonClick(item)}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};
