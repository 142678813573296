import { TableColumn } from "@common/types/table";
import classNames from "classnames";
import { DataType, SortDirection } from "ka-table/enums";
import grid from "@styles/modules/grid.module.scss";
import { getExtendData } from "@common/helpers/data-helpers";
import { ProgressCell } from "@ui";
import { getPhrases } from "@common/helpers/localization";
import { AssessmentsMyCompleted } from "@common/types/reports/assessments/assessments";

const loc = getPhrases("assessments_page", "table_employee");
export const assessmentsColumns: TableColumn<AssessmentsMyCompleted>[] = [
  {
    key: "type",
    title: loc.title_type,
    dataType: DataType.String,
    modClass: classNames(grid.item, grid["item-4x"])
  },
  {
    key: "assessed_by",
    title: loc.title_name,
    dataType: DataType.String,
    sortDirection: SortDirection.Descend,
    modClass: classNames(grid.item, grid["item-4x"])
  },
  {
    key: "completed_at",
    title: loc.title_completed,
    dataType: DataType.Number,
    modClass: classNames(grid.item, grid["item-4x"]),
    decorator: data => getExtendData(data.value * 1000)
  }
];
