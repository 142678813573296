import { MenuProps } from "@common/types/reports/common";

export const appendRoute = (menuList: MenuProps[], route = ""): MenuProps[] =>
  menuList.map(menuItem => {
    const breadcrumb = `${route}${route ? ">" : ""}${menuItem.key}`;
    if (menuItem.subMenus) {
      const subMenus = appendRoute(menuItem.subMenus, breadcrumb);
      return { ...menuItem, subMenus, breadcrumb };
    }
    return { ...menuItem, breadcrumb };
  });
