import { downloadAssessmentReport } from "@common/features/assessments/model";
import { TableCellProps } from "@common/types/table";
import { Svg } from "@ui";
import styles from "./report-people.module.scss";

interface PeopleReportProps extends TableCellProps {}

export const PeopleReport: React.FC<PeopleReportProps> = ({ props }) => {
  const sessionId = props.value ? (props.value.toString().match(/\d+/) || [])[0] : null;
  return sessionId ? (
    <span className={styles.download} onClick={() => downloadAssessmentReport(sessionId, props?.rowData?.full_name)}>
      <Svg name="download" modClass={styles.icon} />
    </span>
  ) : null;
};
