import { OrgUnitsForm } from "@common/types/reports/org-units/org-units";
import { AxiosResponse } from "axios";
import { HttpService } from "../http";
import { DataWrapper } from "../types/response/common";

export const getOrgUnits = async <T>(url: string): Promise<AxiosResponse<DataWrapper<T[]>>> => {
  return HttpService.get<DataWrapper<T[]>>(url);
};

export const sendOrgUnits = async (
  url: string,
  data: OrgUnitsForm
): Promise<AxiosResponse<DataWrapper<OrgUnitsForm>>> => {
  return HttpService.post<DataWrapper<OrgUnitsForm>>(url, data);
};

export const deleteOrgUnit = async (url: string): Promise<AxiosResponse<DataWrapper<OrgUnitsForm>>> => {
  return HttpService.delete<DataWrapper<OrgUnitsForm>>(url);
};
