import { GlobalStore } from "@common/api/store/global";
import { Roles } from "@common/constants";
import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { allRoutes, PageComponentType, RolesRoutingType, Templates } from "../config/routes";
import { Content } from "./content";
import { Settings } from "./settings";

export const ProtectedRoute = observer(() => {
  const { $userStore, $profileStore } = useContext(GlobalStore);
  const role = $profileStore.getCurrentRoleId();
  const profile = $profileStore.getProfile();

  const AuthRedirect = <Navigate to={$userStore.isAuth ? $profileStore.getRootPage() : "/login/"} replace />;

  const hasPermissions = (access?: Roles[], route?: string) => {
    if (!$userStore.isAuth) {
      return false;
    }

    return !(access && role && access.indexOf(role) === -1);
  };

  const getRouteComponent = (Component: PageComponentType): React.FC | null => {
    if (typeof Component === "object") {
      if (!role) {
        return null;
      }
      return Component[role as keyof RolesRoutingType];
    } else {
      return Component;
    }
  };

  const getWrapper = (template: Templates, component: React.ReactNode) => {
    switch (template) {
      case "settings":
        return <Settings children={component} />;
      default:
        return <Content children={component} />;
    }
  };

  const NotFoundPage = () => <div>Page not found</div>;

  return (
    <Routes>
      {allRoutes.map(({ template, routes }) => {
        return routes.map(({ route, access, Component, module }) => {
          const InsideComponent: React.FC | null = getRouteComponent(Component);
          return (
            (!module || (module && profile?.company?.modules && profile.company.modules[module])) && (
              <Route
                key={route}
                path={`/${route}/`}
                element={
                  hasPermissions(access, route)
                    ? getWrapper(template, InsideComponent && React.createElement(InsideComponent))
                    : AuthRedirect
                }
              ></Route>
            )
          );
        });
      })}
      <Route path="/*" element={$userStore.isAuth ? <NotFoundPage /> : <Navigate to="/login" />}></Route>
    </Routes>
  );
});
