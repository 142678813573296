import React, { ReactElement } from "react";
import form from "@styles/modules/form.module.scss";
import classNames from "classnames";
import { InputInterface } from "@common/types/form";

// TODO: add type and any input properties
interface Props extends InputInterface {
  type?: string;
  label?: string | ReactElement;
  labelClass?: string;
  wrapperClass?: string;
  showErrorText?: boolean;
  readOnly?: boolean;
  onChange?: (event: any) => void;
}

export function FormInputText({
  name,
  className,
  label,
  labelClass,
  wrapperClass,
  showErrorText = true,
  type = "text",
  register,
  error,
  theme = "gray",
  onChange,
  ...props
}: Props) {
  const inputClass = classNames(form.text, form[`text-${theme}`], form.input, className, error && form["input-error"]);
  return (
    <div className={classNames(form.field, wrapperClass)} data-error={showErrorText ? error : ""}>
      {label && (
        <label className={classNames("text-xs", error ? "text-danger" : "text-gray", labelClass)}>{label}</label>
      )}
      <input
        {...props}
        type={type}
        {...(register && register(name))}
        className={inputClass}
        {...(onChange && { onChange })}
      />
    </div>
  );
}
