import { InputInterface } from "@common/types/form";
import form from "@styles/modules/form.module.scss";
import classNames from "classnames";
import React, { FC, ReactElement } from "react";

interface Props extends InputInterface {
  label?: string | ReactElement;
  labelClass?: string;
  wrapperClass?: string;
}

export const FormTextArea: FC<Props> = ({
  name,
  className,
  label,
  labelClass,
  register,
  error,
  theme = "gray",
  wrapperClass,
  ...props
}) => {
  const textareaClass = classNames(
    form.text,
    form[`text-${theme}`],
    form.textarea,
    className,
    error && form["input-error"]
  );

  const wrapperClassName = classNames(form.field, wrapperClass);
  return (
    <div className={wrapperClassName} data-error={error}>
      {label && (
        <label className={classNames("text-xs", error ? "text-danger" : "text-gray", labelClass)}>{label}</label>
      )}
      <textarea {...(register && register(name))} className={textareaClass} {...props} />
    </div>
  );
};
