import { NotificationTable } from "./notification-table";
import { useEffect, useMemo, useState } from "react";
import { ControlLine, SelectOptionsProp, SelectProps } from "@ui";
import { ExtendedSelectProps } from "../../../ui/control-line";
import { DisplayCategories, DurationCategories } from "@common/types/reports/activity-logs/activity-logs";
import { getPhrases } from "@common/helpers/localization";
import moment from "moment";
import { getNotificationsSummary } from "@common/features/activity-logs/model";
import { NotificationSummary } from "@common/types/activity-logs/activity-logs";

enum ForCategories {
  all_org_unit = 1,
  virtualization,
  networking,
  storage
}

const displayOptions: SelectOptionsProp[] = [
  { value: DisplayCategories.all, label: "All Notifications" },
  { value: DisplayCategories.activation, label: "Activation Notifications" },
  { value: DisplayCategories.password_reset, label: "Password reset Notifications" },
  { value: DisplayCategories.self_assessment, label: "Self Assessment Notifications" },
  { value: DisplayCategories.manage_assessment, label: "Manage Assessment Notifications" }
];

const forOptions: SelectOptionsProp[] = [
  { value: ForCategories.all_org_unit, label: "All Org Units" },
  { value: ForCategories.virtualization, label: "Virtualization" },
  { value: ForCategories.networking, label: "Networking" },
  { value: ForCategories.storage, label: "Storage" }
];

const durationOptions: SelectOptionsProp[] = [
  { value: DurationCategories.month, label: "The Last 30 days" },
  { value: DurationCategories.quarter, label: "The Last 90 days" },
  { value: DurationCategories.half_year, label: "The Last six months" },
  { value: DurationCategories.year, label: "The Last 12 months" }
];

const loc = getPhrases("activity_logs_page", "filter");

export const Notifications = () => {
  const [displaying, setDisplaying] = useState<SelectOptionsProp>(displayOptions[0]);
  const [forOption, setForOption] = useState<SelectOptionsProp>(forOptions[0]);
  const [duration, setDuration] = useState<SelectOptionsProp>(durationOptions[3]);
  const [summary, setSummary] = useState<NotificationSummary>();

  useEffect(() => {
    getNotificationsSummary(setSummary);
  }, []);

  const filterProps: SelectProps = {
    name: "invitation-category",
    options: displayOptions,
    defaultValue: displaying,
    isDisabled: true,
    onChange: (newValue: any) => {
      setDisplaying(newValue);
    }
  };

  const extraFilters: ExtendedSelectProps[] = [
    {
      label: "For",
      name: "for-category",
      options: forOptions,
      defaultValue: forOption,
      isDisabled: true,
      onChange: (newValue: any) => {
        setForOption(newValue);
      }
    },
    {
      label: "During",
      name: "during-category",
      options: durationOptions,
      defaultValue: duration,
      isDisabled: true,
      onChange: (newValue: any) => {
        setDuration(newValue);
      }
    }
  ];

  const durationTexts = useMemo(
    () => [moment().subtract(duration.value, "days").format("MMM DD, yyyy"), moment().format("MMM DD, yyyy")],
    [duration]
  );

  return (
    <>
      <ControlLine modClass="my-10 md:my-5" {...{ filterProps, extraFilters }}>
        <div className="w-full px-5 py-3 mt-8 mb-2 border border-solid border-black/20 rounded-lg bg-gray-f5 text-[#474a51]">
          <div className="flex items-center">
            <span className="w-[350px] font-bold">{loc.period}:</span>
            <span>
              {durationTexts[0]} - {durationTexts[1]}
            </span>
          </div>
          <div className="mt-2 flex items-center">
            <span className="w-[350px] font-bold">{loc.total_invitation_sent}:</span>
            <span>{summary?.total_invitations_sent}</span>
          </div>
          <div className="mt-1 flex items-center">
            <span className="w-[350px] font-bold">{loc.skill_assess_notification}:</span>
            <span className="mr-10">Self: {summary?.total_se_assessment_invitation.self}</span>
            <span className="mr-6">Manager: {summary?.total_se_assessment_invitation.manager}</span>
            <span>Peer: {summary?.total_se_assessment_invitation.peer}</span>
          </div>
          <div className="mt-2 flex items-center">
            <span className="w-[350px] font-bold">{loc.acc_assess_notification}:</span>
            <span className="mr-10">Self: {summary?.total_sc_assessment_invitation.self}</span>
            <span className="mr-6">Manager: {summary?.total_sc_assessment_invitation.manager}</span>
          </div>
          <div className="mt-2 flex items-center">
            <span className="w-[350px] font-bold">{loc.pwd_reset_notification}:</span>
            <span>{summary?.total_password_reset}</span>
          </div>
          <div className="mt-2 flex items-center">
            <span className="w-[350px] font-bold">{loc.emp_invite_notification}:</span>
            <span>{summary?.total_employee_invitation_notifications}</span>
          </div>
        </div>
      </ControlLine>
      <NotificationTable duration={duration.value} />
    </>
  );
};
