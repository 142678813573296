import { FC, useMemo, useState } from "react";
import { usePopper } from "react-popper";
import "./kpi-tooltip.scss";
import classNames from "classnames";

type RefType = HTMLDivElement | null;
interface KpiTooltipProps {
  title?: string;
  description?: string | React.ReactNode;
  className?: string;
}

export const KpiTooltip: FC<KpiTooltipProps> = ({ className = "", title, description, children }) => {
  const [referenceElement, setReferenceElement] = useState<RefType>();
  const [popperElement, setPopperElement] = useState<RefType>();

  const offsetModifier = useMemo(
    () => ({
      name: "offset",
      options: {
        // @ts-ignore
        offset: ({ placement, reference, popper }) => {
          return [-popper.width - 15, -reference.height / 2 - popper.height / 2];
        }
      }
    }),
    []
  );

  const { styles: stylesBlock, attributes: attributesBlock } = usePopper(referenceElement, popperElement, {
    placement: "top-start",
    strategy: "fixed",
    modifiers: [offsetModifier]
  });

  return (
    <div className={classNames("wrapper", className)} ref={setReferenceElement}>
      {children}
      <div
        className="w-54 info flex flex-col"
        ref={setPopperElement}
        style={stylesBlock.popper}
        {...attributesBlock.popper}
      >
        {title && <span className="uppercase text-sm font-bold text-black mb-3">{title}</span>}
        {description && <span className="text-xs font-normal text-black">{description}</span>}
      </div>
    </div>
  );
};
