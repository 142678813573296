import { $api, HttpService } from "../http";
import { DataWrapper } from "../types/response/common";
import { $userStore } from "@common/api/store/user";

export const getDomainName = async (): Promise<string> => {
  return HttpService.get<DataWrapper<string>>("/web/portal/name", { params: { url: window.location.host } }).then(
    response => {
      const domainName = response.data.data;
      $userStore.setDomainName(domainName);
      return domainName;
    }
  );
};
