import React, { ChangeEvent, FC } from "react";
import classNames from "classnames";
import { Controller } from "react-hook-form";
import { InputInterface, InputRadioOption } from "@common/types/form";
import form from "@styles/modules/form.module.scss";
import { FormInputRadio } from "./input-radio";

// TODO: add type and any input properties
interface Props extends Omit<InputInterface, "placeholder" | "register" | "error"> {
  methods?: any;
  options: InputRadioOption[];
  size?: "sm" | "md";
  direction?: "horizontal" | "vertical";
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  defaultValue?: any;
  disabled?: boolean;
  isSkipValue?: boolean;
}

export const FormInputRadioGroup: FC<Props> = ({
  className,
  methods,
  name,
  theme = "gray",
  options,
  size = "md",
  direction = "vertical",
  defaultValue,
  onChange,
  isSkipValue,
  disabled = false,
  ...props
}) => (
  <Controller
    name={name}
    control={methods.control}
    render={({ field: { value, onChange: onFieldChange } }) => {
      if (defaultValue === null || defaultValue === undefined) {
        throw Error("You need to add a default radio input to the schema");
      }
      const currentValue = isSkipValue ? defaultValue : value ?? defaultValue;
      const handleChange = (value: any) => {
        onFieldChange(value);
        if (onChange) {
          onChange(value);
        }
      };
      return (
        <div
          className={classNames(
            form.field,
            form[`dir-${direction}`],
            className,
            direction === "horizontal" && "flex items-center"
          )}
        >
          {options.map((item, index) => (
            <FormInputRadio
              key={index}
              name={name}
              label={item.label}
              value={item.value}
              size={size}
              theme={theme}
              checked={item.value.toString() === currentValue.toString()}
              onChange={handleChange}
              {...props}
            />
          ))}
        </div>
      );
    }}
  />
);
