import grid from "@styles/modules/grid.module.scss";
import styles from "./greetings.module.scss";
import classNames from "classnames";
import { getPhrases } from "@common/helpers/localization";
import { Next } from "@features/dashboard";
import { DashboardAssessmentsCounters } from "@common/types/reports/assessments/dashboard-assessments-counters";

interface Props {
  name: string;
  counters?: DashboardAssessmentsCounters;
}

export const Greetings = ({ name, counters }: Props) => {
  const loc = getPhrases("dashboard_page", "greetings");
  const daysLeft = counters?.progress.left;
  return (
    <div className={grid["page-row"]}>
      <div className={grid.root}>
        <div className={grid.container}>
          <div className={classNames(grid.list, styles.list)}>
            <div className={classNames(grid.item, grid["item-4x"], styles.item)}>
              {loc.welcome}, <span className={styles.special}>{name}</span>
            </div>
            {(!!daysLeft || daysLeft === 0) && (
              <div className={classNames(grid.item, grid["item-4x"], styles.item)}>
                {loc.next_review},{" "}
                <span className={styles.special}>
                  {daysLeft > 0 ? `${loc.next_review_pre} ${daysLeft} ${loc.next_review_past}` : loc.next_review_ready}
                </span>
              </div>
            )}
            {!!(counters && Number.isInteger(counters?.progress.passed)) && (
              <div className={classNames(grid.item, grid["item-4x"])}>
                <Next props={counters} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
