import { Switch } from "@ui";
import styles from "./completed-switcher.module.scss";

interface Props {
  isShowCompleted: boolean;
  setShowCompletedStatus: () => void;
}

export const CompletedSwitcher = ({ isShowCompleted, setShowCompletedStatus }: Props) => {
  return (
    <div className={styles.block}>
      <Switch value={isShowCompleted} onChange={setShowCompletedStatus} className={styles.switcher} />
      {isShowCompleted ? "Show Completed" : "Hide Completed"}
    </div>
  );
};
