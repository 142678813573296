import { ProgressBar, Switcher, Titles } from "@ui";
import classNames from "classnames";
import styles from "./skills.module.scss";
import optics from "./../optics.module.scss";
import information from "./../../information.module.scss";
import { useEffect, useMemo, useState } from "react";
import { getPhrases } from "@common/helpers/localization";
import { Score, ScoreDataProps } from "@common/types/reports/skills/skills-employee";
import { formatSkillsData } from "./model";

export interface SkillsOptics extends Score {}

interface Props {
  data: ScoreDataProps[];
}

export const Skills = ({ data }: Props) => {
  const [skills, setSkills] = useState<SkillsOptics[]>([]);
  const loc = getPhrases("dashboard_page", "optics_skill");
  const showedQuantity = 5;
  const [isTop, setIsTop] = useState<boolean>(true);

  useEffect(() => {
    setSkills(formatSkillsData(data));
  }, [data]);

  const filteredSkills: SkillsOptics[] = useMemo(() => {
    const compareSkills = (a: SkillsOptics, b: SkillsOptics) => {
      if (a.avg_score > b.avg_score) {
        return isTop ? -1 : 1;
      }
      if (a.avg_score < b.avg_score) {
        return isTop ? 1 : -1;
      }
      return 0;
    };
    return skills.sort(compareSkills).slice(0, showedQuantity);
  }, [isTop, skills]);

  return (
    <>
      <div className={optics.titleline}>
        <Titles level="level_3" modClass={classNames(optics.title, information.title)}>
          {loc.title}
        </Titles>
        <div className={styles["switcher-wrapper"]}>
          <Switcher
            trueValue={`${loc.filter_top} ${showedQuantity}`}
            falseValue={`${loc.filter_bottom} ${showedQuantity}`}
            isTrue={isTop}
            onClick={() => setIsTop(prev => !prev)}
            modClass={styles.switcher}
          />
        </div>
      </div>
      <div className={styles.skills}>
        {filteredSkills.map((item, index) => {
          const skillProgressClass = classNames(
            item.avg_score >= item.avg_target ? styles["skills-done"] : styles["skills-fail"],
            styles.progress
          );
          return (
            <div className={styles.item} key={`skills-item-${index}`}>
              <div className={styles["item-title"]}>{`${index + 1}. ${item.name}`}</div>
              <div className={styles["list-wrapper"]}>
                <div className={styles.list}>
                  <div className={classNames(styles["item-row"], styles.row)}>
                    <div className={styles.name}>{loc.rating_my}</div>
                    <ProgressBar progress={item.self_score} modClass={skillProgressClass} />
                  </div>
                  <div className={classNames(styles["item-row"], styles.row)}>
                    <div className={styles.name}>{loc.rating_peer}</div>
                    <ProgressBar progress={item.peer_score ?? 0} modClass={styles.progress} />
                  </div>
                  <div className={classNames(styles["item-row"], styles.row)}>
                    <div className={styles.name}>{loc.rating_manager}</div>
                    <ProgressBar progress={item.manager_score} modClass={skillProgressClass} />
                  </div>
                  <div className={classNames(styles["item-row"], styles.row, styles["row-weighted"])}>
                    <div className={styles.name}>{loc.rating_weighted}</div>
                    <ProgressBar progress={item.avg_score} modClass={skillProgressClass} />
                  </div>
                </div>
                <div className={styles["target-wrapper"]}>
                  <div className={styles.target} style={{ left: `${item.avg_target}%` }}>
                    <div className={styles["target-value"]}>{`${loc.rating_target} ${item.avg_target}%`}</div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
