import { AxiosResponse } from "axios";
import { HttpService } from "../http";
import { DataWrapper } from "../types/response/common";

export const getSkills = async <T>(url: string): Promise<AxiosResponse<DataWrapper<T[]>>> => {
  return HttpService.get<DataWrapper<T[]>>(url);
};

export const saveSkill = async <T>(url: string, data: T): Promise<AxiosResponse<DataWrapper<T[]>>> => {
  return HttpService.post<DataWrapper<T[]>>(url, data);
};
