import { YourLearningPathsInterface } from "@common/types/reports/learn/learn";
import { LearningResourceCard, Titles } from "@ui";
import { Link } from "react-router-dom";
import styles from "./related-paths.module.scss";

interface Props {
  data: YourLearningPathsInterface[];
}

export const RelatedPaths = ({ data }: Props) => {
  return (
    <div className={styles["related-learning-resources"]}>
      <Titles level={"level_3"} modClass={styles.title}>
        Related Learning Paths
      </Titles>

      {/*     All the related learning resources      */}
      <div className={styles["resources"]}>
        {data.map(({ id, title, image_url, rating_count, rating_average }) => (
          <Link to={`/learn/learning-path/${id}`} key={`related-path-${id}`}>
            <LearningResourceCard
              title={title}
              authorImgSrc={image_url}
              rating={rating_average}
              totalRating={rating_count}
            />
          </Link>
        ))}
      </div>
    </div>
  );
};
