import { Svg } from "@ui";
import React from "react";
import styles from "./react-paginate.module.scss";
import ReactPaginate from "react-paginate";

interface ReactPaginateProps {
  onPageChange: (page: { selected: number }) => void;
  initialPage: number;
  pageCount: number;
  pageRangeDisplayed?: number;
}

const ReactPaginateComponent = ({
  onPageChange,
  initialPage,
  pageCount,
  pageRangeDisplayed = 25
}: ReactPaginateProps) => {
  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={
        <button className={styles.nextBtn}>
          <Svg name={"right-arrow"} />
        </button>
      }
      previousLabel={
        <button className={styles.prevBtn}>
          <Svg name="left-arrow" modClass="text-white" />
        </button>
      }
      onPageChange={page => onPageChange(page)}
      pageRangeDisplayed={pageRangeDisplayed}
      initialPage={initialPage}
      pageCount={pageCount}
      renderOnZeroPageCount={() => {}}
      className={styles.paginationContainer}
      activeLinkClassName={styles.activeLinkClassName}
      pageLinkClassName={styles.pageLinkClassName}
    />
  );
};

export default ReactPaginateComponent;
