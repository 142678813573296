import React from "react";
import { OutlinedButtonWithIcon, Svg } from "@ui";
import styles from "./Mark-as-complete-btn.module.scss";
import { getPhrases } from "@common/helpers/localization";

interface Props {
  isCourseCompleted: boolean;
  onClick: () => void;
}

export const MarkAsCompleteButton: React.FC<Props> = ({ isCourseCompleted, onClick }) => {
  const loc_title_block = getPhrases("course_details_page", "title_block");

  return (
    <OutlinedButtonWithIcon
      modClass={!isCourseCompleted ? "cursor-pointer" : "!cursor-default"}
      theme="orange"
      icon={
        <Svg modClass={styles["circle-success"]} name={isCourseCompleted ? "circle-success" : "circle-success-gray"} />
      }
      text={
        <div className={isCourseCompleted ? styles.green : styles.gray}>
          {loc_title_block[isCourseCompleted ? "completed" : "mark_complete"]}
        </div>
      }
      onClick={!isCourseCompleted ? onClick : undefined}
    />
  );
};
