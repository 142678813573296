import { getManagerAssessmentQuestions, sendManagerAssessmentResult } from "@common/features/assessments/model";
import { WithId } from "@common/types/common";
import { AssessmentForm, AssessmentFormCallback } from "@common/types/forms/assessments-employee";
import { EmployeeAssessmentFormModal } from "@features/assessments";
import { useEffect, useState } from "react";

interface Props extends WithId {
  closeModal: () => void;
  name: string;
  role: string;
}

export const ManagerAssessmentsModal = ({ id, closeModal, name, role }: Props) => {
  const [questions, setQuestions] = useState<AssessmentForm[]>();
  useEffect(() => {
    getManagerAssessmentQuestions(id, setQuestions);
  }, []);
  const onSend = (props: AssessmentFormCallback) => {
    sendManagerAssessmentResult({ ...props, id });
  };
  return !!questions?.length ? (
    <EmployeeAssessmentFormModal
      assessmentQuestions={questions}
      closeModal={closeModal}
      isOpen={true}
      name={name}
      onSend={onSend}
      role={role}
    />
  ) : null;
};
