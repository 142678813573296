import { Button, ShortModal, Titles } from "@ui";
import { ModalProps } from "../types";
import styles from "./alert-modal.module.scss";

type ResultType = "fail" | "success";

interface AlertModalInterface extends ModalProps {
  title?: string;
  type?: ResultType;
  message?: string | React.ReactNode;
  cancelButtonText?: string;
}

export const AlertModal: React.FC<AlertModalInterface> = ({
  title,
  type,
  message,
  cancelButtonText,
  closeModal,
  isOpen,
  children
}) => {
  return (
    <ShortModal isOpen={isOpen} closeModal={closeModal}>
      {children ? (
        <>{children}</>
      ) : (
        <>
          {title && (
            <Titles level="level_1" {...(type ? { modClass: styles[type] } : {})}>
              {title}
            </Titles>
          )}
          {message && <p className={styles.message}>{message}</p>}
          <div className={styles.buttons}>
            <Button title={cancelButtonText} theme="gray" onClick={closeModal} />
          </div>
        </>
      )}
    </ShortModal>
  );
};
