import { TableCellProps } from "@common/types/table";
import { ComplexModal, ComplexModalButtons } from "@ui";
import { OrgUnitChangeForm } from "@features/settings/blocks/org-units";
import { OrgUnitsForm, OrgUnitsFormDetail } from "@common/types/reports/org-units/org-units";
import { useForm } from "@common/hooks/form";
import { defaultOrgUnitTypeValue, UpdateOrgUnitSchema } from "./model";
import { FieldErrors } from "react-hook-form";
import { getOrgUnitDetail, sendNewOrgUnit, updateOrgUnit } from "@common/features/org-units/model";
import { useEffect, useState } from "react";
import modal from "@styles/modules/complex-modal.module.scss";

interface Props extends Partial<TableCellProps> {
  closeModal: () => void;
  isOpen: boolean;
}

export const OrgUnitChangeModal = ({ isOpen, closeModal, props }: Props) => {
  const [detailOrgUnit, setDetailOrgUnit] = useState<OrgUnitsFormDetail>();

  const title = props ? "Edit Org Unit" : "Create New Org Unit";
  const name: string | null = props?.rowData?.name ?? null;

  const methods = useForm<OrgUnitsForm>({
    schema: UpdateOrgUnitSchema
  });

  useEffect(() => {
    if (props?.rowData.id) {
      getOrgUnitDetail(props.rowData.id, setDetailOrgUnit);
    }
  }, []);

  useEffect(() => {
    methods.reset({
      name: detailOrgUnit?.name ?? "",
      type_id: detailOrgUnit?.type.id ? Number(detailOrgUnit?.type.id) : defaultOrgUnitTypeValue,
      parent_id: detailOrgUnit?.parent_id ?? 0
    });
  }, [methods, detailOrgUnit]);

  const handleSuccess = (values: OrgUnitsForm) => {
    const { parent_id, ...valuesNeeded }: OrgUnitsForm = values;
    const sendingValues: OrgUnitsForm = {
      ...valuesNeeded,
      ...(parent_id && { parent_id })
    };
    if (props) {
      if (props?.rowData.id) {
        updateOrgUnit(props.rowData.id, sendingValues);
      }
    } else {
      sendNewOrgUnit(sendingValues);
    }
    closeModal();
  };
  const handleError = (errors: FieldErrors<OrgUnitsForm>) => {
    console.error(errors);
  };

  const buttons: ComplexModalButtons = {
    leftSide: {
      title: "Close",
      theme: "gray",
      onClick: closeModal
    },
    rightSide: [
      {
        title: "Save Org Unit",
        onClick: () => methods.handleSubmit(handleSuccess, handleError)()
      }
    ]
  };
  return (
    <ComplexModal
      isOpen={isOpen}
      closeModal={closeModal}
      headerTitle={`${title}${name ? ":" : ""}`}
      {...(name ? { secondaryTitle: name } : {})}
      buttons={buttons}
    >
      <div className={modal.row}>
        <OrgUnitChangeForm methods={methods} id={props?.rowData.id} />
      </div>
    </ComplexModal>
  );
};
