import { Office } from "@common/types/reports/offices/offices";
import { TableColumn } from "@common/types/table";
import classNames from "classnames";
import { DataType } from "ka-table/enums";
import { ICellTextProps, IDataRowProps } from "ka-table/props";
import grid from "@styles/modules/grid.module.scss";
import { SecondLevelTable } from "@ui";
import { AreaAction, OfficeInfo } from "@features/settings/blocks/offices";
import { PropsWithChildren } from "react";
import { getDeclensions } from "@common/helpers/string-helpers";
import includedStyles from "@features/settings/blocks/included-first-level-table.module.scss";

const getEmployees = (data: PropsWithChildren<ICellTextProps>): string => {
  return `${data.value} ${getDeclensions(data.value, "employee", "employees")}`;
};

export const SecondLevel: React.FC<IDataRowProps> = ({ rowData }) => {
  const data = rowData.offices;
  const columns: TableColumn<Office>[] = [
    {
      key: "name",
      title: "Location Name",
      dataType: DataType.String,
      modClass: classNames(grid.item, grid["item-4x"], "!pl-[30px]"),
      component: OfficeInfo
    },
    {
      key: "address",
      title: "Address",
      dataType: DataType.String,
      modClass: classNames(grid.item, grid["item-4x"])
    },
    {
      key: "members_count",
      title: "Employees",
      dataType: DataType.Number,
      modClass: classNames(grid.item, grid["item-2x"]),
      decorator: data => getEmployees(data)
    },
    {
      key: "name",
      title: "",
      dataType: DataType.String,
      modClass: classNames(grid.item, grid["item-1x"]),
      component: OfficeInfo
    },
    {
      key: "id",
      title: "",
      dataType: DataType.Number,
      modClass: classNames(grid.item, grid["item-1x"]),
      component: AreaAction
    }
  ];
  return SecondLevelTable<Office>({
    data,
    columns,
    sorting: false,
    theme: "orange",
    modStyles: { "cell-inner": "justify-start !px-0" },
    hideHeader: true
  });
};
