import React, { ReactElement, useCallback, useEffect, useState } from "react";
import form from "@styles/modules/form.module.scss";
import classNames from "classnames";
import { InputInterface } from "@common/types/form";
import { SelectOptionsProp, SelectUI } from "@ui";
import { CSSObjectWithLabel, SingleValue } from "react-select";
import { StylesConfig } from "react-select/dist/declarations/src/styles";

// TODO: add type and any input properties
interface Props extends InputInterface {
  modClass?: string;
  label?: string | ReactElement;
  labelClass?: string;
  list: any[];
  type?: string;
  group?: boolean;
  groupSelectable?: boolean;
  getItemLabel?: (item: any) => string | ReactElement;
  getItemValue?: (item: any) => any;
  dropdownClass?: string;
  dropdownItemClass?: string;
  readOnly?: boolean;
  value?: any;
  methods?: any;
  styles?: StylesConfig<SingleValue<SelectOptionsProp>, false>;
  onChange?: (value: any) => void;
}

interface IOption {
  label: string;
  value: number;
  children?: IOption[];
}

export function FormInputSelect({
  list,
  name,
  className,
  label,
  labelClass,
  register,
  error,
  theme = "gray",
  group,
  groupSelectable,
  getItemLabel,
  getItemValue,
  dropdownClass,
  dropdownItemClass,
  value,
  placeholder,
  methods,
  readOnly,
  onChange,
  styles,
  modClass,
  ...props
}: Props) {
  const [text, setText] = useState<string>("");
  const [options, setOptions] = useState<IOption[]>([]);
  const [suggestions, setSuggestions] = useState<IOption[]>([]);

  const findOption = useCallback((options: IOption[], value): IOption | undefined => {
    for (const option of options) {
      if (option.value === value) {
        return option;
      }
      const subSearch = findOption(option.children || [], value);
      if (subSearch) {
        return subSearch;
      }
    }
  }, []);

  useEffect(() => {
    if (!value) {
      setText("");
    } else {
      const option = findOption(options, value);
      if (option) {
        setText(option.label);
      }
    }
  }, [value, options]);

  const formatOptions = useCallback((options: any[]): IOption[] => {
    return options.map(item => ({
      label: getItemLabel ? getItemLabel(item) : item,
      value: getItemValue ? getItemValue(item) : item,
      children: formatOptions(item.children || [])
    }));
  }, []);

  useEffect(() => {
    setOptions(formatOptions(list));
  }, [list, formatOptions]);

  const inputClass = classNames(
    form.text,
    "p-0 text-primary",
    form[`text-${theme}`],
    form.input,
    className,
    error && form["input-error"]
  );

  const onClickOption = (e: MouseEvent, option: IOption) => {
    if (group && !groupSelectable && option.children?.length) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    setText(option.label);
    if (onChange) {
      onChange(option.value);
    }
    setSuggestions([]);
  };

  const getGroupOptions = (key: string, option: IOption, level = 0) => {
    const selectable = !group || groupSelectable || !option.children?.length;
    return (
      <div key={key}>
        <div
          className={classNames(
            "pr-2",
            option.children?.length && "font-semibold",
            selectable && "hover:text-white hover:bg-blue cursor-pointer",
            dropdownItemClass
          )}
          style={{ paddingLeft: `${0.5 + level}rem` }}
          onClick={e => onClickOption(e as any, option)}
        >
          {option.label}
        </div>
        {(option.children || []).map((child, index) => getGroupOptions(`${key}-${index}`, child, level + 1))}
      </div>
    );
  };

  const getFilteredOptions = (options: IOption[], search: string): IOption[] => {
    const result: IOption[] = [];
    options.forEach(option => {
      const isMatch = option.label.toLowerCase().includes(search);
      const children = getFilteredOptions(option.children || [], search);
      if (isMatch || children.length) {
        result.push({
          ...option,
          children
        });
      }
    });
    return result;
  };

  return (
    <div className={classNames("relative", form.field, form.select, modClass)} data-error={error}>
      {label && (
        <label className={classNames("text-xs", error ? "text-danger" : "text-gray", labelClass)}>{label}</label>
      )}
      <SelectUI
        className={inputClass}
        placeholder={placeholder}
        isDisabled={readOnly}
        options={options}
        name={name}
        control={methods.control}
        styles={styles}
        onChange={onChange}
      />
    </div>
  );
}
