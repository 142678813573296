import React, { FC, useMemo, useState } from "react";
import { usePopper } from "react-popper";
import "./password-tooltip.scss";
import classNames from "classnames";

type RefType = HTMLDivElement | null;
interface IPasswordTooltip {
  className?: string;
}

export const PasswordTooltip: FC<IPasswordTooltip> = ({ className = "" }) => {
  const [referenceElement, setReferenceElement] = useState<RefType>();
  const [popperElement, setPopperElement] = useState<RefType>();

  const offsetModifier = useMemo(
    () => ({
      name: "offset",
      options: {
        // @ts-ignore
        offset: ({ placement, reference, popper }) => {
          return [popper.width + 15, -reference.height / 2 - popper.height / 2];
        }
      }
    }),
    []
  );

  const { styles: stylesBlock, attributes: attributesBlock } = usePopper(referenceElement, popperElement, {
    modifiers: [offsetModifier],
    placement: "top-end"
  });

  return (
    <div className={classNames("password-tooltip-wrapper", className)} ref={setReferenceElement}>
      <div className="w-3 h-3 mt-[2px] ml-2 pt-[1px] flex justify-center items-center text-[8px] rounded-full border border-solid border-black font-bold cursor-pointer select-none">
        ?
      </div>
      <div
        className="w-80 detail flex flex-col"
        ref={setPopperElement}
        style={stylesBlock.popper}
        {...attributesBlock.popper}
      >
        <span className="uppercase text-sm font-bold text-black text-center">PASSWORD POLICY RULES</span>
        <span className="text-xs font-normal text-black">Choose a strong password to keep your account safe.</span>
        <br />
        <span className="text-xs font-normal text-black">- At least 12 characters length</span>
        <span className="text-xs font-normal text-black">- Must contain at least 4 lowercase characters</span>
        <span className="text-xs font-normal text-black">- Must contain at least 2 uppercase characters</span>
        <span className="text-xs font-normal text-black">- Must contain at least 2 special characters</span>
      </div>
    </div>
  );
};
