import React, { useContext, useEffect, useMemo, useState } from "react";
import { EmployeeAnalytics } from "@common/types/reports/analytics/analytics";
import { employeeOverviewColumns } from "@features/reporting/report-overview/model";
import { FirstLevelTable, Titles } from "@ui";
import styles from "../report-overview.module.scss";
import { GlobalStore } from "@common/api/store/global";
import { CommonWithId } from "@common/types/common";
import { getEmployeesByType } from "@common/features/analytics/model";
import { TableColumn } from "@common/types/table";

interface EmployeeViewProps {
  employeeType: string;
  columns: TableColumn<EmployeeAnalytics>[];
}

export const EmployeeView: React.FC<EmployeeViewProps> = ({ employeeType, columns }) => {
  const { $analyticsStore } = useContext(GlobalStore);
  const [isLoading, setIsLoading] = useState(false);
  const employee = useMemo(
    () => $analyticsStore.employeeTypes.find(emp => +emp.id === +employeeType) as CommonWithId,
    [$analyticsStore.employeeTypes, employeeType]
  );
  const employees = useMemo(
    () => $analyticsStore.employeesByType[+employeeType] || [],
    [$analyticsStore.employeesByType[+employeeType], employeeType]
  );

  useEffect(() => {
    if (!isLoading && !$analyticsStore.employeesByType[+employeeType]) {
      setIsLoading(true);
      getEmployeesByType(+employeeType).finally(() => setIsLoading(false));
    }
  }, [employeeType, $analyticsStore.employeesByType]);

  return (
    <>
      <Titles level="level_3" modClass={styles.title}>
        {employee.name}
      </Titles>
      <p className={styles["virtual-description"]}>
        This is where a generalized overview of all teams would be placed: it would summarize the highs and lows of each
        team and allow the user to see where significant problems or breakthroughs are occurring
      </p>
      {/*TODO: need to uncomment after integration*/}
      {/*<EmployeeFilter category={`${employee.name} employees`} modClass={styles["supervisor-filter"]} />*/}
      <div className={styles["report-table-wrapper"]}>
        {FirstLevelTable<EmployeeAnalytics>({
          data: employees,
          columns: employeeOverviewColumns,
          modStyles: styles
        })}
      </div>
    </>
  );
};
