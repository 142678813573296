import classNames from "classnames";
import styles from "./course-modal.module.scss";
import { Button, ComplexModal } from "@ui";
import tabs from "@styles/modules/tabs.module.scss";
import modal from "@styles/modules/complex-modal.module.scss";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { VideoTab } from "./video-tab";
import QuizTab from "./quiz-tab";
import { NotesTab } from "./notes-tab";
import { Course } from "../types";

interface Props extends Course {
  isOpenModal: boolean;
  toggleModal(): void;
  isCourseCompleted: boolean;
  toggleIsCourseCompleted(): void;
  activeTabIndex: number;
  updateActiveTabIndex(index: number): void;
}

export const CourseModal = ({
  isOpenModal,
  toggleModal,
  isCourseCompleted,
  toggleIsCourseCompleted,
  activeTabIndex,
  updateActiveTabIndex,
  ...course
}: Props) => {
  return (
    <ComplexModal
      isOpen={isOpenModal}
      closeModal={toggleModal}
      // headerTitle={`${course.title && `${course.title}`}${
      //   course.short_description ? `:` : ""
      // }${course.title && " "}${course.short_description ?? ""}`}
      headerTitle={`${course.title && `${course.title}`}`}
      headerSecondRow={
        <div className={classNames(styles["form-row"])}>
          {/* <div
            className={classNames(
              styles["modal-tags-container"],
              modal["two-columns-left"]
            )}
          >
            <Button
              theme={"blue"}
              appearance={"bordered"}
              className={styles["modal-tag"]}
              size="thin"
            >
              Programming
            </Button>
          </div> */}
          <Tabs
            selectedIndex={activeTabIndex}
            className={classNames(tabs.tabs, modal["two-columns-right"], styles.tabs)}
            selectedTabClassName={tabs["tab-selected"]}
            onSelect={index => updateActiveTabIndex(index)}
          >
            <TabList className={classNames(tabs["list"], styles["tabs-list"])}>
              <Tab name={"Watch The Video"} className={classNames(tabs["tab"], styles["tab"])}>
                01 Watch The Video
              </Tab>

              <Tab
                name={"Quiz"}
                className={classNames(tabs["tab"], tabs["tab-disabled"], styles["tab"])}
                disabled={true}
              >
                02 complete Quiz
              </Tab>

              <Tab
                name={"personal notes"}
                className={classNames(tabs["tab"], tabs["tab-disabled"], styles["tab"])}
                disabled={true}
              >
                03 notes
              </Tab>
            </TabList>
            <TabPanel className={tabs.hidden} />
            <TabPanel className={tabs.hidden} />
            <TabPanel className={tabs.hidden} />
          </Tabs>
        </div>
      }
      bodyClass={styles["modal-body"]}
    >
      <div className={classNames(modal.row, styles["modal-row"])}>
        <Tabs selectedIndex={activeTabIndex}>
          <TabList className={tabs.hidden}>
            <Tab />
            <Tab />
            <Tab />
          </TabList>
          <TabPanel className={classNames(tabs.panel)}>{course.url && <VideoTab {...course} />}</TabPanel>

          <TabPanel className={tabs.panel} disabled={true}>
            <QuizTab updateActiveTabIndex={updateActiveTabIndex} />
          </TabPanel>
          <TabPanel className={tabs.panel} disabled={true}>
            <NotesTab />
          </TabPanel>
        </Tabs>
      </div>
    </ComplexModal>
  );
};
