import { Svg } from "@ui";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import { GlobalStore } from "@common/api/store/global";
import { observer } from "mobx-react-lite";
import { CompanySetting } from "@common/types/reports/profile/profile";
import { getCompanySetting, getProfile } from "@common/features/profile/model";
import styles from "./logo.module.scss";

export const Logo = observer(() => {
  const { $userStore, $profileStore } = useContext(GlobalStore);
  const { pathname: location } = useLocation();
  const isRoot = location === "/" || location === $profileStore.getRootPage();
  const logoClass = classNames(styles["icon-logo"], styles["icon-special"]);
  const logoWrapperClass = classNames(
    styles["logo-wrapper"],
    !isRoot && $userStore.isAuth && styles["logo-wrapper-linked"]
  );

  const [companySetting, setCompanySetting] = useState<CompanySetting>();

  useEffect(() => {
    if ($userStore.isAuth) {
      const companySettings = $profileStore.getCompanySetting();
      if (companySettings) {
        setCompanySetting(companySettings);
      } else {
        getProfile();
        getCompanySetting(setCompanySetting);
      }
    } else {
      setCompanySetting(undefined);
    }
  }, []);

  useEffect(() => {
    const setting = $profileStore.getCompanySetting();
    if (setting) {
      setCompanySetting(setting);
    }
  }, [$profileStore.companySetting]);

  const SvgBlock = useCallback(
    () => (
      <>
        <Svg name="logo-new" modClass={logoClass} />
        <div className={styles.text}>{companySetting?.portal_name ?? $userStore.domainName}</div>
      </>
    ),
    [companySetting?.portal_name, $userStore.domainName, $userStore.isAuth]
  );

  return isRoot || !$userStore.isAuth ? (
    <div className={logoWrapperClass}>
      <SvgBlock />
    </div>
  ) : (
    <Link to="/dashboard" className={logoWrapperClass}>
      <SvgBlock />
    </Link>
  );
});
