import { AssessmentsManager, AssessmentsMyCompleted } from "@common/types/reports/assessments/assessments";
import {
  downloadAssessmentReport as downloadAssessmentReportFunction,
  getAssessmentIntervalSetting as getAssessmentIntervalSettingFunction,
  getAssessmentQuestions as getAssessmentQuestionsFunction,
  getAssessments as getAssessmentsFunction,
  getDashboardAssessmentCounters as getDashboardAssessmentCountersFunction,
  getLastAssessmentResult as getLastAssessmentResultFunction,
  sendAssessmentResult as sendAssessmentResultFunction,
  setAssessmentIntervalSetting as setAssessmentIntervalSettingFunction
} from "@common/api/services/assessments";
import { $userStore } from "@common/api/store/user";
import { Roles } from "@common/constants";
import { AssessmentsSummary } from "@common/types/reports/assessments/summary";
import {
  AssessmentForm,
  AssessmentFormCallback,
  AssessmentManagerFormCallback
} from "@common/types/forms/assessments-employee";
import { AssessmentIntervalSetting } from "../../types/reports/assessments/assessments";
import { $api } from "@common/api/http";
import { DataWrapper } from "@common/api/types/response/common";
import { $profileStore } from "@common/api/store/profile";
import { $commonStore } from "@common/api/store/common";
import { BannerType } from "@common/api/types/common";

export const getAssessments = async (callback: (data: AssessmentsManager) => void) => {
  try {
    const response = await $api.get<DataWrapper<AssessmentsManager>>("/web/assessments/my-staff");
    callback(response.data.data);
  } catch (e) {
    console.error("Get assessments error", e);
  }
};

export const getAssessmentsSummary = async (callback: (data: AssessmentsSummary[]) => void) => {
  try {
    const response = await getAssessmentsFunction<AssessmentsSummary>("/web/assessments/summary");
    callback(response.data.data);
  } catch (e) {
    console.error("Get summary assessment error");
  }
};

export const getManagerAssessmentQuestions = async (id: string, callback: any) => {
  try {
    const response = await getAssessmentQuestionsFunction(`/client/skills-engine/assessments/${id}`);
    callback(response.data.data);
  } catch (e) {
    console.error("Get manager assessment questions error");
  }
};

export const getSelfAssessmentQuestions = async (callback: any) => {
  try {
    const profile = $profileStore.getProfile();
    const id = profile?.id;
    const response = await getAssessmentQuestionsFunction<DataWrapper<AssessmentForm[]> | { error: string }>(`/client/skills-engine/assessments/${id}`);
    if ('error' in response.data && response.data.error) {
      $commonStore.setBanner(BannerType.error, response.data.error || 'Something went wrong with assessments');
    } else if ('data' in response.data && response.data.data) {
      callback(response.data.data || []);
    }
  } catch (e) {
    console.error("Get self assessment questions error");
  }
};

export const sendManagerAssessmentResult = async (props: AssessmentManagerFormCallback) => {
  try {
    const response = await sendAssessmentResultFunction(
      `/client/skills-engine/assessments/${props.id}/3`,
      props.results
    );
    if (response) {
      props.successCallback();
    }
  } catch (e) {
    props.failCallback();
    console.error("Send manager assessment results error");
  }
};

export const sendSelfAssessmentResult = async (props: AssessmentFormCallback) => {
  try {
    const memberId = $userStore.getUserInfo()?.id;
    const response = await sendAssessmentResultFunction(
      `/client/skills-engine/assessments/${memberId}/1`,
      props.results
    );
    if (response) {
      props.successCallback();
    }
  } catch (e) {
    props.failCallback();
    console.error("Send self assessment results error");
  }
};

export const getLastAssessmentResult = async (callback: any) => {
  try {
    const response = await getLastAssessmentResultFunction("/web/dashboard/employee-current-assessment");
    callback(response.data.data);
  } catch (e) {
    console.error("Get last assessment result error");
  }
};

export const getDashboardAssessmentCounters = async (callback: any) => {
  try {
    const response = await getDashboardAssessmentCountersFunction("/web/dashboard/member-counters");
    callback(response.data.data);
  } catch (e) {
    console.error("Get dashboard assessment counters error");
  }
};

export const downloadAssessmentReport = async (id: number, downloadName = "") => {
  try {
    const url = await getAssessmentReportUrl(id, downloadName);
    if (url) {
      window.open(url);
    }
  } catch (e) {
    console.error("Get dashboard assessment counters error");
  }
};

export const getAssessmentReportUrl = async (id: number, downloadName = "") => {
  try {
    const response = await downloadAssessmentReportFunction(`/client/skills-engine/assessment-sessions/${id}/report`);
    const blob = new Blob([response.data], { type: "application/pdf" });

    const url = window.URL.createObjectURL(blob);

    if (downloadName) {
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.target = "_blank";
      anchor.download = downloadName;
      anchor.click();
    }

    return url;
  } catch (e) {
    console.error("Get report document error");
  }
};

export const getAssessmentIntervalSetting = async (id: number) => {
  try {
    const response = await getAssessmentIntervalSettingFunction(`/client/skills-engine/roles/${id}/settings`);
    return response.data.data;
  } catch (e) {
    console.error("Get role assessment interval settings error");
  }
};

export const setAssessmentIntervalSetting = async (id: number, setting: Omit<AssessmentIntervalSetting, "role">) => {
  try {
    const response = await setAssessmentIntervalSettingFunction(`/client/skills-engine/roles/${id}/settings`, setting);
    return response.data.data;
  } catch (e) {
    console.error("Set role assessment interval settings error");
  }
};
