import { AssessmentModules } from "@common/types/reports/assessments/assessments";
import { ManagerAccountabilitiesAssessment } from "@features/accountabilities";
import { TableCellProps } from "@common/types/table";
import { ManagerAssessmentsModal } from "@features/dashboard/employee/personal/modals";
import { Button } from "@ui";
import { useState } from "react";
import styles from "./assessment-action.module.scss";

interface AssessmentActionInterface extends TableCellProps {}

export const AssessmentAction: React.FC<AssessmentActionInterface> = ({ props }) => {
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const { rowData } = props;
  const module: AssessmentModules = rowData.module;
  const name = `${rowData?.member?.first_name} ${rowData?.member?.last_name}`;
  return (
    <>
      <Button
        size="thin"
        theme="blue"
        title="Start"
        className={styles.assessmentAction}
        onClick={() => setIsOpen(true)}
      />
      {modalIsOpen &&
        (module === "se" ? (
          <ManagerAssessmentsModal
            id={rowData.member.id}
            closeModal={() => setIsOpen(false)}
            name={name}
            role={rowData?.member?.role_name}
          />
        ) : (
          <ManagerAccountabilitiesAssessment
            memberId={rowData.member.id}
            closeModal={() => setIsOpen(false)}
            userName={name}
          />
        ))}
    </>
  );
};
