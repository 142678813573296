import { AxiosRequestConfig, AxiosResponse } from "axios";
import { HttpService } from "../http";
import { DataWrapper } from "../types/response/common";

export const getPeopleService = async <T>(
  url: string,
  option: AxiosRequestConfig = {}
): Promise<AxiosResponse<DataWrapper<T[]>>> => {
  return HttpService.get<DataWrapper<T[]>>(url, option);
};
