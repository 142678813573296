import styles from "./optics.module.scss";
import grid from "@styles/modules/grid.module.scss";
import classNames from "classnames";
import { Categories } from "./categories";
import { Skills } from "./skills";
import { useEffect, useState } from "react";
import { ScoreDataProps } from "@common/types/reports/skills/skills-employee";
import { getScores } from "@common/features/skills/model";

export const Optics = () => {
  const [scores, setScores] = useState<ScoreDataProps[]>([]);
  useEffect(() => {
    getScores(setScores);
  }, []);

  return (
    <div className={grid.list}>
      <div className={classNames(grid.item, grid["item-6x"], styles.column)}>
        <Categories data={scores} />
      </div>
      <div className={classNames(grid.item, grid["item-6x"])}>
        <Skills data={scores} />
      </div>
    </div>
  );
};
