import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { GlobalStore } from "@common/api/store/global";
import { Banner as BannerInterface, BannerType } from "@common/api/types/common";
import grid from "@styles/modules/grid.module.scss";
import { $commonStore } from "@common/api/store/common";

interface BannerProps {
  banner: BannerInterface;
}

export const Banner: FC<BannerProps> = ({ banner }) => {
  let bgClass = "";
  let textClass = "";

  switch (banner.type) {
    case BannerType.success:
      bgClass = "bg-success";
      textClass = "text-success";
      break;
    case BannerType.info:
      bgClass = "bg-primary";
      textClass = "text-primary";
      break;
    case BannerType.warning:
      bgClass = "bg-warning";
      textClass = "text-warning";
      break;
    case BannerType.error:
      bgClass = "bg-danger-dark";
      textClass = "text-danger-dark";
      break;
  }

  return (
    <div className={`${grid.root} ${bgClass}`}>
      <div className={classNames(grid.container, "py-3 flex justify-between items-center")}>
        <p className="text-white">
          <span className="ml-2 mr-4 text-lg uppercase font-bold">{banner.type}</span>
          <span className="text-sm font-normal">{banner.text}</span>
        </p>
        <button
          className={`px-1 outline-none border-none uppercase rounded bg-white font-bold text-xs cursor-pointer ${textClass}`}
          onClick={() => $commonStore.clearBanner()}
        >
          CLOSE
        </button>
      </div>
    </div>
  );
};
