import { useEffect } from "react";
import { Button, FormInputPassword, FormInputText, FormWrapper } from "@ui";
import { FieldErrors } from "react-hook-form";
import form from "@styles/modules/form.module.scss";
import styles from "./login-form.module.scss";
import { defaultValues, LoginFormInterface, loginFormSchema } from "./model";
import { useForm } from "@common/hooks/form";
import { getPhrases } from "@common/helpers/localization";
import { login } from "@common/features/user/model";

interface LoginFormProps {
  openResetPasswordModal?: () => void;
}

export const LoginForm: React.FC<LoginFormProps> = ({ openResetPasswordModal }) => {
  const methods = useForm<LoginFormInterface>({
    schema: loginFormSchema,
    defaultValues
  });

  useEffect(() => {
    localStorage.removeItem("currentRoleId");
  }, []);

  const handleSuccess = (values: LoginFormInterface) => {
    return login({ email: values.email, password: values.password });
  };
  const handleError = (errors: FieldErrors<LoginFormInterface>) => {
    console.error(errors);
  };

  function openModal() {
    openResetPasswordModal && openResetPasswordModal();
  }

  const loc = getPhrases("login_page", "auth_form");
  return (
    <>
      <FormWrapper onSubmit={methods.handleSubmit(handleSuccess, handleError)}>
        <div className={styles["form-wrapper"]}>
          <div className={form.row}>
            <FormInputText
              placeholder={loc.forms?.emailPlaceholder}
              name="email"
              register={methods.register}
              error={methods.formState.errors["email"]?.message}
            />
          </div>
          <div className={form.row}>
            <FormInputPassword
              name="password"
              register={methods.register}
              placeholder={loc.passwordPlaceholder}
              error={methods.formState.errors["password"]?.message}
            />
          </div>
        </div>
        <div className={styles.forgot}>
          <button className={styles["forgot-button"]} type="button" onClick={openModal}>
            {loc.changePassword}
          </button>
        </div>
        <Button title={loc.buttons?.button_ok} className={styles.button} type="submit" />
      </FormWrapper>
    </>
  );
};
