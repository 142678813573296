import {
  getProfile as getProfileFunction,
  getProfilesList,
  setPeopleProfileStatusService,
  setPeopleProfilePhotoService,
  setCompanyLogoService,
  setCompanySettingService
} from "@common/api/services/profile";
import { setPeopleProfileService } from "@common/api/services/profile";
import {
  MyProfileInterface,
  ProfileInterface,
  PeopleProfileInterface,
  PeopleFormSendInterface,
  PeopleSendStatusServiceInterface,
  CompanyFormInterface,
  CompanySetting
} from "@common/types/reports/profile/profile";
import { setProfile as setProfileFunction } from "@common/api/services/profile";
import { $profileStore } from "@common/api/store/profile";
import { BannerType } from "@common/api/types/common";
import { $commonStore } from "@common/api/store/common";

export const getProfile = async (callback?: any) => {
  try {
    const response = await getProfileFunction<ProfileInterface>("/web/profile/me");
    const data: ProfileInterface = response.data.data;
    $profileStore.setProfile(data);
    // await getPeopleProfile(+data.id, (data: PeopleProfileInterface) => $profileStore.setDetailedProfile(data));
    // $profileStore.setCompanySetting(data.company);
    callback && callback(data);
  } catch (e) {
    console.error("Get profile error");
  }
};

export const getPeopleProfile = async (id: number, callback?: any) => {
  try {
    const response = await getProfileFunction<PeopleProfileInterface>(`/client/organization/members/${id}`);
    const data: PeopleProfileInterface = response.data.data;
    $profileStore.setDetailedProfile(data);
    callback && callback(data);
  } catch (e) {
    console.error("Get people profile error");
  }
};

export const getWebMemberProfile = async (id: number, callback?: any) => {
  try {
    const response = await getProfileFunction<PeopleProfileInterface>(`/web/members/${id}`);
    const data: PeopleProfileInterface = response.data.data;
    $profileStore.setDetailedProfile(data);
    callback && callback(data);
  } catch (e) {
    console.error("Get people profile error");
  }
};

export const setProfile = async (values: MyProfileInterface) => {
  try {
    const response = await setProfileFunction(values);
  } catch (e) {
    console.error("Set profile error");
  }
};

export const setPeopleProfile = async (values: PeopleFormSendInterface, id?: number) => {
  try {
    const response = await setPeopleProfileService<PeopleProfileInterface>(`/web/members/${id ?? ""}`, values);
    return response.data.data;
  } catch (e) {
    console.error("Set people profile error");
    throw e;
  }
};

export const setPeopleProfileStatus = async (id: number, values: PeopleSendStatusServiceInterface) => {
  try {
    return await setPeopleProfileStatusService(id, values);
  } catch (e: any) {
    $profileStore.personProfileError = e?.response?.data?.error || "Set people profile status error";
    console.error("Set people profile status error");
  }
};

export const setPeopleProfilePhoto = async (id: number, file: File) => {
  try {
    return await setPeopleProfilePhotoService(id, file);
  } catch (e) {
    console.error("Uploading profile photo error");
  }
};

export const setCompanyLogo = async (id: number, file: File) => {
  try {
    return await setCompanyLogoService(id, file);
  } catch (e) {
    console.error("Uploading profile photo error");
  }
};

export const getCompanySetting = async (callback?: any) => {
  try {
    const response = await getProfileFunction<CompanySetting>("/client/organization/company");
    const data: CompanySetting = response.data.data;
    callback && callback(data);
    $profileStore.setCompanySetting(data);
  } catch (e) {
    console.error("Get company setting error");
  }
};

export const setCompanySetting = async (data: CompanyFormInterface) => {
  try {
    return await setCompanySettingService(data);
  } catch (e) {
    console.error("Set company setting error");
    throw e;
  }
};

export const getAvailableManagers = async (id: number, callback: any) => {
  try {
    const response = await getProfilesList<PeopleProfileInterface>(
      `/client/organization/roles/${id}/available-managers`
    );
    callback(response.data.data);
  } catch (e) {
    console.error("Get available managers by role error");
  }
};
