import React, { FC, useEffect, useState } from "react";
import * as yup from "yup";
import classNames from "classnames";
import {
  CKEditor,
  FormInputDatePicker,
  FormInputSelect,
  FormInputText,
  FormWrapper,
  SelectOptionsProp,
  Switch
} from "@ui";
import { CSSObjectWithLabel, SingleValue } from "react-select";
import { StylesConfig } from "react-select/dist/declarations/src/styles";
import { useForm } from "@common/hooks/form";
import { ILearnForm } from "@common/types/forms/learn";
import { getPhrases } from "@common/helpers/localization";
import { $learnStore } from "@common/api/store/learn";
import { ILearnFormTabInitData } from "../index";
import { FormError } from "../form-error";
import { LearningResourceDeleteConfirm } from "@features/learn/learn-form/learning-resource-delete-confirm";
import styles from "@features/people/modals/user-info-modal/user-info-content/tabs/details/details.module.scss";
import "./details-tab.scss";

const SelectorStyle: StylesConfig<SingleValue<SelectOptionsProp>, false> = {
  control: (styles: CSSObjectWithLabel) => ({
    ...styles,
    height: "27px !important",
    background: "none !important",
    border: "none !important"
  }),
  valueContainer: (styles: CSSObjectWithLabel) => ({ ...styles, padding: "0 9px 0 !important", fontSize: 14 }),
  singleValue: (styles: CSSObjectWithLabel) => ({ ...styles, color: "#000 !important" }),
  menu: (styles: CSSObjectWithLabel) => ({
    ...styles,
    color: "#000 !important",
    borderColor: "#cdcdcd !important",
    fontSize: 14
  }),
  option: (styles: CSSObjectWithLabel) => ({ ...styles, padding: "1px 9px !important", fontSize: 14 })
};

export interface ILearningResourceDetailsTabProps {
  formData: Partial<ILearnForm>;
  onInit?: (data: ILearnFormTabInitData) => void;
  onBack(): void;
  onNext(): void;
  onSubmit(data: Partial<ILearnForm>): void;
  onClose(): void;
  editable?: boolean;
}

interface ILearningResourceDetailsForm {
  content_type_id: number;
  title: string;
  url: string;
  duration: string;
  provider_id?: number;
  is_free: boolean;
  price?: number;
  expertise_level_id?: number;
  language_id?: number;
  course_type_id?: number;
  publication_date: string;
  short_description: string;
}

// TODO: need to delete after api integration
const emptyOption = [{ id: undefined, name: "Empty" }];

const loc = getPhrases("common", "forms").validation;

const detailsFormSchema: yup.SchemaOf<ILearningResourceDetailsForm> = yup.object().shape({
  content_type_id: yup.number().required(loc.required),
  title: yup.string().required(loc.required),
  url: yup.string().url("This field should be valid url").required(loc.required),
  duration: yup
    .string()
    .matches(/^\d+(\.\d+)?\s*(m|h)$/g, "This field should be valid format")
    .required(loc.required),
  provider_id: yup.number().required(loc.required),
  is_free: yup.boolean().required(loc.required),
  price: yup.number(),
  expertise_level_id: yup.number().required(loc.required),
  language_id: yup.number().required(loc.required),
  course_type_id: yup.number(),
  publication_date: yup.string().required(loc.required),
  short_description: yup.string().required(loc.required).nullable()
});

const parseDuration = (text: string) => {
  const match = /^(\d+(\.\d+)?)\s*(m|h)$/g.exec(text);
  if (!match) {
    return undefined;
  }
  return Number(match[1]) * (match[3] === "h" ? 60 : 1);
};

const getMediaTypeAndSrc = (url: string) => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regExp);
  const youtubeId = match && match[7].length === 11 ? match[7] : false;
  if (youtubeId) {
    return {
      mediaType: "youtube",
      mediaSrc: `https://i.ytimg.com/vi/${youtubeId}/hqdefault.jpg`
    };
  }
  return {
    mediaType: "local",
    mediaSrc: undefined
  };
};

const formatDuration = (minutes: number) => {
  if (minutes >= 60 && minutes % 6 === 0) {
    return `${minutes / 60}h`;
  }
  return `${minutes}m`;
};

export const LearnDetailsTab: FC<ILearningResourceDetailsTabProps> = ({
  formData,
  onInit,
  onNext,
  onSubmit,
  onClose,
  editable
}) => {
  const resourceTypes = $learnStore.resourceTypes;
  const providers = $learnStore.providers;
  const languages = $learnStore.languages;
  const expertiseLevels = $learnStore.expertiseLevels;

  const methods = useForm<ILearningResourceDetailsForm>({
    schema: detailsFormSchema,
    defaultValues: {
      is_free: true,
      price: 0,
      ...formData,
      duration: typeof formData.duration === "number" ? formatDuration(formData.duration) : ""
    }
  });

  const contentTypeId = methods.watch("content_type_id");
  const short_description = methods.watch("short_description");
  const isFree = methods.watch("is_free");
  const [showFormError, setShowFormError] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [timestamp, setTimestamp] = useState<number>();

  useEffect(() => {
    if (onInit) {
      if (showDeleteConfirm || showFormError) {
        onInit({ buttons: undefined });
      } else {
        onInit({
          buttons: {
            leftSide: [
              {
                title: (
                  <span>
                    Cancel
                    <br />
                    And Close
                  </span>
                ),
                theme: "secondary-gradient",
                className: "!w-30 !h-14 !text-sm !text-left !font-semibold",
                onClick: onClose
              },
              ...(formData.id
                ? [
                    {
                      title: (
                        <span className="w-full px-4">
                          Delete this
                          <br />
                          Learning Resource
                        </span>
                      ),
                      theme: "danger" as "danger",
                      className: "!w-44 !h-14 !text-xs !text-left !font-semibold !ml-4",
                      onClick: () => setShowDeleteConfirm(true)
                    }
                  ]
                : [])
            ],
            rightSide: [
              {
                title: "Next",
                theme: "warning",
                shape: "right-arrow",
                className: "!w-20 !text-sm !font-semibold",
                onClick() {
                  methods.handleSubmit(handleSuccess, handleError)();
                }
              }
            ]
          }
        });
      }
    }
  }, [onInit, contentTypeId, showDeleteConfirm, showFormError, editable]);

  const onDelete = () => {
    onClose();
  };

  const onFieldChange = (field: keyof ILearningResourceDetailsForm, value: any) => {
    methods.setValue(field, value);
    methods.trigger(field);
    setTimestamp(new Date().getTime());
  };

  const handleSuccess = (values: ILearningResourceDetailsForm) => {
    const { mediaType, mediaSrc } = getMediaTypeAndSrc(values.url);
    onSubmit({
      ...values,
      duration: parseDuration(values.duration),
      mediaType,
      mediaSrc
    });
    onNext();
  };

  const handleError = (e: any) => {
    console.log(e);
    // setShowFormError(true);
  };

  return (
    <FormWrapper
      className="learn-form details-wrapper text-black"
      onSubmit={methods.handleSubmit(handleSuccess, handleError)}
    >
      {!contentTypeId && (
        <>
          <div className="grid sm:grid-cols-2 gap-4 border-b border-gray-cd px-5 pb-4">
            <FormInputSelect
              className="!h-[27px] !leading-[25px] border-gray-cd"
              label="Select the Resource Type"
              list={resourceTypes}
              name="content_type_id"
              placeholder="Please select"
              getItemLabel={option => option.name}
              getItemValue={option => option.id}
              methods={methods}
              value={contentTypeId}
              styles={SelectorStyle}
              onChange={({ value }) => onFieldChange("content_type_id", value)}
            />
          </div>

          <div className="flex justify-center items-center mt-8 mb-8 px-5 pb-4">
            <h6 className="w-[60%] text-primary text-2xl text-center font-semibold whitespace-pre-wrap">
              Welcome to the Resource Wizard!
              <br />
              To add a new Learning Resource, please select the Resource Type in the list above
            </h6>
          </div>
        </>
      )}

      {contentTypeId && (
        <div className="grid grid-cols-2 gap-4 px-5">
          <FormInputText
            name="title"
            className="!h-[27px] text-sm !leading-[25px] italic text-black"
            label="Learning resource name"
            labelClass="leading-6"
            wrapperClass="col-span-2"
            theme="gray"
            register={methods?.register}
            error={methods?.formState.errors["title"]?.message}
          />
          <FormInputText
            name="url"
            className="!h-[27px] text-sm !leading-[25px] text-black"
            label="URL to the learning resource"
            labelClass="leading-6"
            theme="gray"
            register={methods?.register}
            error={methods?.formState.errors["url"]?.message}
          />
          <FormInputText
            name="duration"
            className="!h-[27px] text-sm !leading-[25px] text-black"
            label="Duration (exp. 15m or 2h)"
            labelClass="leading-6"
            theme="gray"
            register={methods?.register}
            error={methods?.formState.errors["duration"]?.message}
          />
          <FormInputSelect
            className="!h-[27px] !leading-[25px] border-gray-cd"
            label="Provider"
            list={providers}
            name="provider_id"
            placeholder="Please select"
            getItemLabel={option => option.name}
            getItemValue={option => option.id}
            methods={methods}
            styles={SelectorStyle}
            error={methods?.formState.errors["provider_id"]?.message}
            onChange={({ value }) => onFieldChange("provider_id", value)}
          />
          <div className="flex justify-between">
            <div className="flex items-center pt-6">
              <div className="text-secondary text-sm font-normal mr-3 whitespace-nowrap">
                This learning resource is&nbsp;
                <b className={isFree ? "text-primary" : ""}>{isFree ? "Free" : "not Free"}</b>
              </div>
              <Switch
                theme="primary"
                value={isFree}
                onChange={value => {
                  onFieldChange("is_free", value);
                  onFieldChange("price", 0);
                }}
              />
            </div>
            {isFree ? (
              <div className="flex flex-col justify-center flex-grow ml-8">
                <label className="text-xs text-gray leading-6">Price in USD</label>
                <span>0.00</span>
              </div>
            ) : (
              <FormInputText
                name="price"
                className="!h-[27px] text-sm !leading-[25px] text-black"
                wrapperClass="w-auto ml-2"
                label="Price in USD"
                labelClass="leading-6"
                theme="gray"
                register={methods?.register}
                error={isFree ? undefined : methods?.formState.errors["price"]?.message}
              />
            )}
          </div>
          <FormInputSelect
            className="!h-[27px] !leading-[25px] border-gray-cd"
            labelClass="leading-6"
            label="Course Level"
            list={expertiseLevels}
            name="expertise_level_id"
            placeholder="Please select"
            getItemLabel={option => option.name}
            getItemValue={option => option.id}
            methods={methods}
            styles={SelectorStyle}
            error={methods?.formState.errors["expertise_level_id"]?.message}
            onChange={({ value }) => onFieldChange("expertise_level_id", value)}
          />
          <FormInputSelect
            className="!h-[27px] !leading-[25px] border-gray-cd"
            labelClass="leading-6"
            label="Language"
            list={languages}
            name="language_id"
            placeholder="Please select"
            getItemLabel={option => option.name}
            getItemValue={option => option.id}
            methods={methods}
            styles={SelectorStyle}
            error={methods?.formState.errors["language_id"]?.message}
            onChange={({ value }) => onFieldChange("language_id", value)}
          />
          <FormInputSelect
            className="!h-[27px] !leading-[25px] border-gray-cd"
            labelClass="leading-6"
            label="Course type"
            list={resourceTypes}
            name="content_type_id"
            placeholder="Please select"
            getItemLabel={option => option.name}
            getItemValue={option => option.id}
            methods={methods}
            styles={SelectorStyle}
            error={methods?.formState.errors["content_type_id"]?.message}
            onChange={({ value }) => onFieldChange("content_type_id", value)}
          />
          <div className={classNames("flex flex-col")}>
            <div className="text-xs leading-6 text-gray">Publication date</div>
            <FormInputDatePicker
              name="publication_date"
              className={classNames(styles.start, "!h-[27px] text-sm !leading-[25px] text-black")}
              placeholder="Start Date"
              register={methods?.register}
              error={methods?.formState.errors["publication_date"]?.message}
              onChange={value => onFieldChange("publication_date", value)}
            />
          </div>
          <div className="col-span-2">
            <CKEditor
              value={short_description}
              className="text-sm !leading-[25px] mt-2"
              config={{
                removePlugins: ["MediaEmbed", "Table"],
                placeholder: "Short description"
              }}
              helperText={methods.formState.errors?.short_description?.message}
              helperTextClass="text-xs text-danger mt-1"
              onChange={value => onFieldChange("short_description", value)}
            />
          </div>
        </div>
      )}

      {showDeleteConfirm && (
        <LearningResourceDeleteConfirm
          className="w-full h-full absolute top-0 left-0 z-100"
          onDelete={onDelete}
          onClose={() => setShowDeleteConfirm(false)}
        />
      )}

      {showFormError && (
        <FormError className="w-full h-full absolute top-0 left-0 z-100" onClose={() => setShowFormError(false)} />
      )}
    </FormWrapper>
  );
};
