import React from "react";
import styles from "./circular-progress-bar.module.scss";
import { CircularProgressbar } from "react-circular-progressbar";

interface Props {
  value: number;
}

export const CircularProgressBar = ({ value }: Props) => {
  return (
    <div className={styles.iconProgress}>
      <CircularProgressbar
        value={value}
        strokeWidth={43}
        classes={{
          root: styles.root,
          trail: styles.trail,
          path: styles.path,
          text: styles.text,
          background: styles.bg
        }}
        backgroundPadding={7}
        background
      />
    </div>
  );
};
