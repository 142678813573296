import { LearnResource } from "@common/types/reports/learn/learn";
import { TableColumn } from "@common/types/table";
import { SecondLevelTable } from "@ui";
import classNames from "classnames";
import { DataType, SortDirection } from "ka-table/enums";
import { IDataRowProps } from "ka-table/props";
import grid from "@styles/modules/grid.module.scss";
import { useMemo } from "react";
import { HiddenInfo, Edit, Rating } from "@features/learn/views/";
import { getDurationHoursAndMinutes } from "@common/helpers/time-helpers";
import { getCalendarData } from "@common/helpers/data-helpers";
import styles from "./last-level.module.scss";

interface LearnResourceTable extends LearnResource {
  edit: number;
}

export const LastLevel: React.FC<IDataRowProps> = ({ rowData }) => {
  const data: LearnResource[] = rowData.learn_resources;
  const editedData: LearnResourceTable[] = useMemo(() => {
    return data.length
      ? data.reduce((previous: LearnResourceTable[], current) => {
          return [...previous, { ...current, edit: current.id }];
        }, [])
      : [];
  }, [data]);

  const columns: TableColumn<LearnResourceTable>[] = [
    {
      key: "title",
      title: "Learning Resource",
      dataType: DataType.String,
      sortDirection: SortDirection.Ascend,
      modClass: classNames(styles.item, grid["item-3x"])
    },
    {
      key: "skills",
      title: "Covered Skills",
      dataType: DataType.Object,
      modClass: classNames(styles.item, grid["item-1x"]),
      decorator: data => data.value?.length ?? 0
    },
    {
      key: "content_type",
      title: "Type",
      dataType: DataType.Object,
      modClass: classNames(styles.item, grid["item-1x"]),
      decorator: data => data.value?.name ?? ""
    },
    {
      key: "duration",
      title: "Duration",
      dataType: DataType.Number,
      modClass: classNames(styles.item, grid["item-1x"]),
      decorator: data => getDurationHoursAndMinutes(data.value, true) ?? ""
    },
    {
      key: "rating_average",
      title: "Rating",
      dataType: DataType.Number,
      modClass: classNames(styles.item, grid["item-2x"]),
      component: Rating
    },
    {
      key: "members",
      title: "Taken By",
      dataType: DataType.Number,
      modClass: classNames(styles.item, grid["item-1x"]),
      decorator: data => (data.value ? (data.value === 1 ? "Learner" : "Learners") : "0 Learners")
    },
    {
      key: "provider",
      title: "Provider",
      dataType: DataType.Object,
      modClass: classNames(styles.item, grid["item-1x"]),
      decorator: data => data.value?.name ?? ""
    },
    {
      key: "updated_at",
      title: "Last Updated",
      dataType: DataType.String,
      modClass: classNames(styles.item, grid["item-1x"]),
      decorator: data => (data.value ? getCalendarData(data.value) : "")
    },
    {
      key: "edit",
      title: "Edit",
      dataType: DataType.Number,
      modClass: classNames(styles.item, grid["item-1x"]),
      component: Edit
    }
  ];

  return SecondLevelTable<LearnResourceTable>({
    data: editedData,
    columns,
    modStyles: styles
  });
};
