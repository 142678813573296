import React, { useEffect, useState } from "react";
import styles from "./rating.module.scss";
import { Svg } from "@ui";

interface Props {
  rating: number;
  onlyVisible?: boolean;
  onChange?: (val: number) => void;
}

export const Rating: React.FC<Props> = ({ rating, onlyVisible, onChange }) => {
  const [rat, setRat] = useState<number>(rating);

  useEffect(() => {
    setRat(rating);
  }, [rating]);

  const ratingChangeHandler = (val: number) => {
    setRat(val);
    if (onChange) {
      onChange(val);
    }
  };

  return (
    <div className={styles.rating}>
      {[...Array(5)].map((star, index) => {
        return (
          <Svg
            key={index}
            onClick={() => {
              !onlyVisible && ratingChangeHandler(index + 1);
            }}
            name={rat > index ? "star-filled" : "star-outlined"}
          />
        );
      })}
    </div>
  );
};
