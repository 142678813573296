import React, { ChangeEvent, useContext, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { GlobalStore } from "@common/api/store/global";
import { rootRoutes } from "@router";
import { Svg } from "@ui";
import { logout } from "@common/features/user/model";
import grid from "@styles/modules/grid.module.scss";
import { RouteItem, settingsRoutes, adminRoutes } from "../../../router/config/routes";
import styles from "./menu.module.scss";
import { InputRadioOption } from "@common/types/form";
import { Roles } from "@common/constants";

const roleOptions: InputRadioOption[] = [{ label: "Employee View", value: Roles.employee }];

const managerRoleOption: InputRadioOption = {
  label: "Manager View",
  value: Roles.manager
};
const clientAdminRoleOption: InputRadioOption = {
  label: "Admin View",
  value: Roles.clientAdmin
};

export const Menu = observer(() => {
  const [rootNavItems, setRootNavItems] = React.useState<RouteItem[]>([]);
  const navigate = useNavigate();
  const { $profileStore, $userStore } = useContext(GlobalStore);
  const rootClass = classNames(grid.root, styles.wrapper, styles["inherit-height"]);
  const containerClass = classNames(grid.container, styles["inherit-height"]);
  const activeLinkClass = classNames(styles.link, styles["link-active"]);
  const profile = $profileStore.getProfile();
  const role = $profileStore.getCurrentRoleId();
  const getUserRoleId = $userStore.getUserRoleId();

  const handleChangeRoleMode = (e: ChangeEvent<HTMLInputElement>, value: any) => {
    e.target.checked && $profileStore.setCurrentRoleId(value);
    navigate($profileStore.getRootPage(), { replace: false });
  };

  const onSetRootNavItems = () => {
    switch ($userStore.getUserRoleId()) {
      case Roles.superAdmin:
        setRootNavItems(adminRoutes);
        return;
      default:
        setRootNavItems(rootRoutes);
        return;
    }
  };

  useEffect(() => {
    onSetRootNavItems();
  }, [$userStore.getUserRoleId()]);

  return (
    <div className={rootClass}>
      <div className={containerClass}>
        <nav className={styles.menu}>
          <ul className={styles.list}>
            {rootNavItems.map(({ route, text, access, module, disabled }) => {
              return (!access || (access && role && access.indexOf(role) !== -1)) &&
                (!module || (module && profile?.company?.modules && profile.company.modules[module])) ? (
                <li className={styles.item} key={text}>
                  <NavLink
                    aria-disabled={true}
                    to={disabled ? "#" : `/${route}`}
                    className={({ isActive }) => {
                      return disabled ? styles.link : isActive ? activeLinkClass : styles.link;
                    }}
                  >
                    {text}
                  </NavLink>
                </li>
              ) : null;
            })}
            <div className={styles["mobile-divider"]} />
            {role === Roles.superAdmin ? (
              <li className={styles.item}>
                <NavLink to={`#`} className={styles.link}>
                  Innovorg Superadmin
                </NavLink>
              </li>
            ) : (
              settingsRoutes.map(({ route, text, iconCode }) => (
                <li className={styles.item} key={text}>
                  <NavLink
                    to={`/${route}`}
                    className={({ isActive }) => {
                      return isActive ? activeLinkClass : styles.link;
                    }}
                  >
                    {iconCode && <Svg name={iconCode} modClass={styles.icon} />}
                    {text}
                  </NavLink>
                </li>
              ))
            )}
            <div className={styles["mobile-divider"]} />
            <li className={styles.item}>
              <NavLink to="#" className={styles.link} onClick={() => logout()}>
                <Svg name="logout" modClass={styles.icon} />
                Log out
              </NavLink>
            </li>
          </ul>
          {[Roles.manager, Roles.clientAdmin, Roles.superAdmin].includes($userStore.getUserRoleId()) && (
            <div className="mt-auto ml-4 flex flex-col items-start md:mt-0 md:ml-auto md:items-center md:flex-row">
              {[
                ...roleOptions,
                ...(getUserRoleId === Roles.manager || $profileStore.profile?.current_role?.is_managerial
                  ? [managerRoleOption]
                  : []),
                ...([Roles.clientAdmin, Roles.superAdmin].includes(getUserRoleId) ? [clientAdminRoleOption] : [])
              ].map((item, index) => {
                return (
                  <label key={`${item.label}-${index}`} className="flex cursor-pointer">
                    <input
                      name="role_mode"
                      type="radio"
                      value={item.value}
                      checked={role === item.value}
                      onChange={e => handleChangeRoleMode(e, item.value)}
                    />
                    <span
                      className={classNames(styles.link, "!bg-transparent !text-white capitalize whitespace-nowrap")}
                    >
                      {item.label}
                    </span>
                  </label>
                );
              })}
            </div>
          )}
          {role === Roles.clientAdmin && (
            <ul className={styles.list}>
              <li className={styles.item}>
                <NavLink
                  to="/activity-logs"
                  className={({ isActive }) => {
                    return classNames(isActive ? activeLinkClass : styles.link, styles["active-log"]);
                  }}
                >
                  Activity Logs
                </NavLink>
              </li>
            </ul>
          )}
        </nav>
      </div>
    </div>
  );
});
