import classNames from "classnames";
import { CarouselContent } from "../types";
import styles from "./carousel-header.module.scss";

interface Props extends CarouselContent {}

export const CarouselHeader = ({ leftHeading, leftSubHeading, rightHeading, rightSubHeading }: Props) => {
  const hasLeftPart = leftHeading || leftSubHeading;
  const hasRightPart = rightHeading || rightSubHeading;
  return (
    <div className={styles.carouselHeader}>
      {hasLeftPart && (
        <div>
          <h2 className={styles.carouselHeading}>{leftHeading}</h2>
          <p className={styles.description}>{leftSubHeading}</p>
        </div>
      )}
      {hasRightPart && (
        <div>
          <div className={classNames(styles.carouselHeading, hasLeftPart && styles["right-side"])}>{rightHeading}</div>
          <div className={styles.description}>{rightSubHeading}</div>
        </div>
      )}
    </div>
  );
};
