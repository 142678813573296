import React, { useState } from "react";
import form from "@styles/modules/form.module.scss";
import classNames from "classnames";
import { Svg } from "@ui";
import { InputInterface } from "@common/types/form";

export const FormInputPassword = ({ name, className, register, error, theme = "gray", ...props }: InputInterface) => {
  const [passwordIsHidden, setPasswordVisible] = useState<boolean>(true);
  const inputClass = classNames(
    form.text,
    form[`text-${theme}`],
    form.input,
    form["password-input"],
    error && form["input-error"],
    className
  );
  const iconClass = classNames(form["password-icon"], !passwordIsHidden && form["password-icon__checked"]);
  const onClick = () => {
    setPasswordVisible(!passwordIsHidden);
  };

  // TODO: Move input to reusable component

  return (
    <div className={form.field} data-error={error}>
      <input
        type={passwordIsHidden ? "password" : "text"}
        {...(register && register(name))}
        className={inputClass}
        {...props}
      />
      <Svg name="eye" modClass={iconClass} onClick={onClick} />
    </div>
  );
};
