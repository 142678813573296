import React, { FC, useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { FormInputAutocomplete, FormInputRadioGroup, FormWrapper } from "@ui";
import { getAllSkills } from "@common/features/skills/model";
import { useForm } from "@common/hooks/form";
import { InputRadioOption } from "@common/types/form";
import { ILearnForm } from "@common/types/forms/learn";
import { ILearnFormTabInitData } from "../index";
import { FormError } from "../form-error";
import { SkillDeleteConfirm } from "@features/learn/learn-form/skill-delete-confirm";
import { getPhrases } from "@common/helpers/localization";
import { LearningResourceDeleteConfirm } from "@features/learn/learn-form/learning-resource-delete-confirm";

export interface IResourceSkillsTabProps {
  formData: Partial<ILearnForm>;
  onInit?: (data: ILearnFormTabInitData) => void;
  onBack(): void;
  onNext(): void;
  onSubmit(data: Partial<ILearnForm>): void;
  onClose(): void;
  editable?: boolean;
}

interface IResourceSkillsForm {
  skillName?: string;
  skillType?: number;
  skills: {
    id: number;
    name: string;
    category: string;
  }[];
}

interface ISkill {
  id: number;
  name: string;
  category?: string;
  children?: ISkill[];
}

const loc = getPhrases("common", "forms").validation;

const skillsFormSchema: yup.SchemaOf<IResourceSkillsForm> = yup.object().shape({
  skillName: yup.string(),
  skillType: yup.number(),
  skills: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required(loc.required),
        category: yup.string().required(loc.required)
      })
    )
    .min(1)
});

const skillTypes: InputRadioOption[] = [
  { label: "All Skills", value: 0 },
  { label: "Soft Skills", value: 1 },
  { label: "Hard Skills", value: 2 }
];

export const LearnSkillsTab: FC<IResourceSkillsTabProps> = ({
  formData,
  onInit,
  onBack,
  onNext,
  onSubmit,
  onClose
}) => {
  const methods = useForm<IResourceSkillsForm>({
    schema: skillsFormSchema,
    defaultValues: {
      skillName: "",
      skillType: 0,
      skills: formData.skills || []
    }
  });
  const data = methods.getValues();
  const [skills, setSkills] = useState<ISkill[]>([]);
  const [timestamp, setTimestamp] = useState<number>();
  const [deletingSkill, setDeletingSkill] = useState<ISkill>();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showFormError, setShowFormError] = useState(false);

  useEffect(() => {
    getAllSkills().then(skills => {
      const categories: ISkill[] = [];
      skills.forEach(skill => {
        const category = categories.find(item => item.id === skill.category.id);
        if (category) {
          category.children!.push({
            id: skill.id,
            name: skill.name,
            category: skill.category.name
          });
        } else {
          categories.push({
            id: skill.category.id,
            name: skill.category.name,
            children: [
              {
                id: skill.id,
                name: skill.name,
                category: skill.category.name
              }
            ]
          });
        }
      });
      setSkills(categories);
    });
  }, []);

  const groups: string[] = [];
  data.skills.forEach(item => {
    if (!groups.includes(item.category)) {
      groups.push(item.category);
    }
  });

  const filteredSkills = useMemo(() => {
    const selectedSkills = data.skills.map(item => item.id);
    return skills
      .map(skill => ({
        ...skill,
        children: skill.children!.filter(item => !selectedSkills.includes(item.id))
      }))
      .filter(skill => skill.children.length);
  }, [skills, data.skills]);

  useEffect(() => {
    if (onInit) {
      if (showDeleteConfirm || deletingSkill || showFormError) {
        onInit({ buttons: undefined });
      } else {
        onInit({
          buttons: {
            leftSide: [
              {
                title: (
                  <span>
                    Cancel
                    <br />
                    And Close
                  </span>
                ),
                theme: "secondary-gradient",
                className: "!w-30 !h-14 !text-sm !text-left !font-semibold",
                onClick: onClose
              },
              ...(formData.id
                ? [
                    {
                      title: (
                        <span className="w-full px-4">
                          Delete this
                          <br />
                          Learning Resource
                        </span>
                      ),
                      theme: "danger" as "danger",
                      className: "!w-44 !h-14 !text-xs !text-left !font-semibold !ml-4",
                      onClick: () => setShowDeleteConfirm(true)
                    }
                  ]
                : [])
            ],
            rightSide: [
              {
                title: "Prev",
                theme: "warning",
                shape: "left-arrow",
                className: "!w-20 !text-sm !font-semibold",
                onClick() {
                  goBack();
                }
              },
              {
                title: "Next",
                theme: "warning",
                shape: "right-arrow",
                className: "!w-20 !text-sm !font-semibold",
                onClick() {
                  methods.handleSubmit(handleSuccess, handleError)();
                }
              }
            ]
          }
        });
      }
    }
  }, [onInit, showDeleteConfirm, deletingSkill, showFormError]);

  const onDelete = () => {
    onClose();
  };

  const onFieldChange = (field: keyof IResourceSkillsForm, value: any) => {
    methods.setValue(field, value);
    methods.trigger(field);
    setTimestamp(new Date().getTime());
  };

  const onAddSkill = (skill: ISkill) => {
    const skills = [...data.skills, skill];
    onFieldChange("skillName", "");
    onFieldChange("skills", skills);
  };

  const onAttemptDeleteSkill = (skill: ISkill) => {
    setDeletingSkill(skill);
  };

  const onRemoveSkill = () => {
    const skills = data.skills.filter(item => item.id !== deletingSkill!.id);
    onFieldChange("skills", skills);
    setDeletingSkill(undefined);
  };

  const handleSuccess = (values: IResourceSkillsForm) => {
    onSubmit(values);
    onNext();
  };

  const handleError = () => {
    // setShowFormError(true);
  };

  const goBack = () => {
    onSubmit(methods.getValues());
    onBack();
  };

  return (
    <FormWrapper className="text-black" onSubmit={methods.handleSubmit(handleSuccess, handleError)}>
      <div className="grid grid-cols-1fr-auto gap-4 px-5">
        <FormInputAutocomplete
          className="!text-sm !text-black !placeholder-secondary placeholder-italic"
          name="skillName"
          label="Skill Name"
          placeholder="Type the name of your skill to select it"
          list={filteredSkills}
          group
          getItemLabel={(item: ISkill) => item.name}
          getItemValue={(item: ISkill) => item}
          dropdownClass="max-h-40 overflow-auto !mt-0"
          dropdownItemClass="!text-sm"
          register={methods.register}
          onChange={onAddSkill}
        />
        <div className="flex items-center">
          <FormInputRadioGroup
            className="radio-gray mt-5"
            name="skillType"
            size="sm"
            direction="horizontal"
            options={skillTypes}
            defaultValue={0}
            methods={methods}
          />
        </div>
      </div>

      <div className="min-h-60 w-full mt-4">
        <table className="w-full text-sm mt-2">
          <thead>
            <tr className="text-gray text-xs text-left font-semibold border-b border-solid border-gray-cd">
              <th className="pl-5 pr-4 pb-1">Skill Category / Skill name</th>
              <th className="pr-5 pb-1" />
            </tr>
          </thead>
          <tbody>
            {groups.map((group, i) => {
              const items = data.skills.filter(item => item.category === group);
              return (
                <React.Fragment key={i}>
                  <tr>
                    <td className="font-semibold px-5 pt-2" colSpan={2}>
                      <div className="flex items-center leading-tight pb-2">
                        <span className="bg-gray-97 uppercase text-2xs text-white px-1 py-0.5">Hard Skill</span>
                        <span className="ml-2">{group}</span>
                      </div>
                    </td>
                  </tr>
                  {items.map(item => (
                    <tr key={item.id} className="align-top">
                      <td className="pl-5">
                        <div className="flex items-center bg-gray-f5 mb-1">
                          <div className="pl-2 pr-6">{item.name}</div>
                        </div>
                      </td>
                      <td className="pr-5">
                        <div className="h-5 bg-gray-f5 flex items-center justify-end pr-2 mb-1">
                          <i
                            className="fa fa-times text-warning cursor-pointer"
                            onClick={() => onAttemptDeleteSkill(item)}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              );
            })}
            {!groups.length && (
              <p className="max-w-180 text-primary text-xl text-center px-20 pt-10 mx-auto">
                Welcome to the skills tab - this is where you find the skills and map them onto the role you are
                creating
              </p>
            )}
          </tbody>
        </table>
      </div>

      {showDeleteConfirm && (
        <LearningResourceDeleteConfirm
          className="w-full h-full absolute top-0 left-0 z-100"
          onDelete={onDelete}
          onClose={() => setShowDeleteConfirm(false)}
        />
      )}

      {deletingSkill && (
        <SkillDeleteConfirm
          className="w-full h-full absolute top-0 left-0 z-100"
          skill={deletingSkill}
          onDelete={onRemoveSkill}
          onClose={() => setDeletingSkill(undefined)}
        />
      )}

      {showFormError && (
        <FormError className="w-full h-full absolute top-0 left-0 z-100" onClose={() => setShowFormError(false)} />
      )}
    </FormWrapper>
  );
};
