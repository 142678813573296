import modalStyles from "@styles/modules/complex-modal.module.scss";
import { useEffect, useMemo, useState } from "react";
import { getSkillsByRole } from "@common/features/skills/model";
import { SkillByRole } from "@common/types/managment/edit-person";
import { Skill, SkillsTree } from "./types";
import { FirstLevel } from "./tables";
import classNames from "classnames";
import styles from "./skills.module.scss";
import { Titles } from "@ui";

interface Props {
  roleId?: number;
}

interface CategoriesList extends Omit<SkillsTree, "skills"> {}

export const SkillsTab = ({ roleId }: Props) => {
  const [skills, setSkills] = useState<SkillByRole[]>();
  const [categoriesList, setCategories] = useState<CategoriesList[]>();
  useEffect(() => {
    if (roleId) {
      getSkillsByRole(Number(roleId), setSkills);
    }
  }, [roleId]);

  const tabClass = classNames(modalStyles.row, styles.tab);

  useMemo(() => {
    if (skills?.length) {
      const categories: string[] = [];
      skills.forEach(item => {
        if (!~categories.indexOf(item.skill.category.id)) {
          setCategories(prev => [
            ...(prev ?? []),
            {
              id: item.skill.category.id,
              name: item.skill.category.name,
              description: item.skill.category.description
            }
          ]);
          categories.push(item.skill.category.id);
        }
      });
    }
  }, [skills]);
  const combinedCategories: SkillsTree[] = useMemo(() => {
    return categoriesList?.length && skills?.length
      ? categoriesList.reduce((previous: SkillsTree[], categoryItem: Omit<SkillsTree, "skills">) => {
          const skillsSubtree: Skill[] = skills.reduce((previousArray: Skill[], currentItem) => {
            if (currentItem.skill.category.id === categoryItem.id) {
              return [
                ...previousArray,
                {
                  id: currentItem.skill.id,
                  name: currentItem.skill.name,
                  is_active: currentItem.skill.is_active,
                  importance: currentItem.importance,
                  target: currentItem.target_assessment
                }
              ];
            }
            return [...previousArray];
          }, []);
          return [
            ...previous,
            {
              id: categoryItem.id,
              name: categoryItem.name,
              description: categoryItem.description,
              skills: skillsSubtree
            }
          ];
        }, [])
      : [];
  }, [categoriesList, skills]);
  return (
    <div className={tabClass}>
      <Titles level="level_3">Skills assigned to this role</Titles>
      {combinedCategories.length ? <FirstLevel data={combinedCategories} /> : <>No skills data</>}
    </div>
  );
};
