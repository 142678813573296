import { AccountabilitiesAssessment, Notifications } from "@features/activity-logs";
import grid from "@styles/modules/grid.module.scss";
import classNames from "classnames";
import tabs from "@styles/modules/tabs.module.scss";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import styles from "./activity-logs.module.scss";
import { SkillsAssessment } from "@features/activity-logs/skills-assessment";

export const ActivityLogsPage = () => {
  return (
    <div className={classNames(grid.root, "relative my-[-15px] pt-[30px]")}>
      <div className={grid.container}>
        <Tabs className={tabs.tabs} selectedTabClassName={styles["link-active"]} defaultIndex={0}>
          <div className={classNames(styles.list, "bg-primary grid", grid.root)}>
            <TabList className={classNames(tabs.list, grid.container)}>
              <Tab className={styles.link}>Notifications</Tab>
              <Tab className={styles.link}>Skills Assessment</Tab>
              <Tab className={styles.link}>Accountabilities Assessment</Tab>
            </TabList>
          </div>
          <TabPanel>
            <Notifications />
          </TabPanel>
          <TabPanel>
            <SkillsAssessment />
          </TabPanel>
          <TabPanel>
            <AccountabilitiesAssessment />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};
