import { getCountries, getReportOffices } from "@common/features/offices/model";
import { AreaOfficesDataProps } from "@common/types/reports/offices/offices";
import { useEffect, useState } from "react";
import { FirstLevel } from "./first-level";

export const Offices = () => {
  const [offices, setOffices] = useState<AreaOfficesDataProps[]>();
  useEffect(() => {
    getReportOffices(setOffices);
    getCountries();
  }, []);
  return !!offices ? <FirstLevel offices={offices} /> : null;
};
