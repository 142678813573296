import { TeamOverview } from "@features/reporting/report-overview/team-overview";
import { SkillOverview } from "@features/reporting/report-overview/skill-overview";
import { SupervisorOverview } from "@features/reporting/report-overview/supervisor-overview";
import { OfficeOverview } from "@features/reporting/report-overview/office-overview";
import { EmployeeOverview } from "@features/reporting/report-overview/employee-overview";
import { RoleOverview } from "@features/reporting/report-overview/role-overview";

interface ReportOverviewProps {
  keyword: string;
  route: string;
  onNavigate: (value: string) => void;
}

export const ReportOverview: React.FC<ReportOverviewProps> = ({ keyword = "byTeam", route, onNavigate }) => {
  return (
    <>
      {keyword === "byTeam" && <TeamOverview route={route} onNavigate={onNavigate} />}
      {keyword === "byRole" && <RoleOverview route={route} />}
      {keyword === "bySupervisor" && <SupervisorOverview route={route} />}
      {keyword === "byOffice" && <OfficeOverview route={route} />}
      {keyword === "bySkill" && <SkillOverview route={route} />}
      {keyword === "byEmployee" && <EmployeeOverview route={route} />}
    </>
  );
};
