import { UpdateMembersRole } from "@common/types/managment/edit-person";
import { AxiosResponse } from "axios";
import { HttpService } from "../http";
import { DataWrapper } from "../types/response/common";

export const getRoles = async <T>(url: string): Promise<AxiosResponse<DataWrapper<T[]>>> => {
  return HttpService.get<DataWrapper<T[]>>(url);
};
export const updateMemberRoleService = async (
  id: number,
  values: UpdateMembersRole
): Promise<AxiosResponse<UpdateMembersRole>> => {
  return HttpService.post<UpdateMembersRole>(`/web/members/${id}/role`, values);
};

export const deleteRole = async (url: string): Promise<AxiosResponse> => {
  return HttpService.delete(url);
};

export const createRole = async <T>(url: string, data: any): Promise<AxiosResponse<DataWrapper<T>>> => {
  return HttpService.post<DataWrapper<T>>(url, data);
};

export const getRoleDetail = async <T>(url: string): Promise<AxiosResponse<DataWrapper<T>>> => {
  return HttpService.get<DataWrapper<T>>(url);
};
