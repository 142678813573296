import React, { FC, useEffect, useState } from "react";
import classNames from "classnames";
import { Tab, TabList, Tabs } from "react-tabs";
import { ComplexModal, ComplexModalButtons, Switch } from "@ui";
import {
  getRoleAccountabilityDetail,
  getRoleAccountabilities,
  setRoleAccountabilities,
  updateRoleAccountabilityDetail
} from "@common/features/accountabilities/model";
import { getResponsibilities, setResponsibilities } from "@common/features/responsibilities/model";
import {
  attachSkillsToRole,
  getRoleById,
  getRoleSkills,
  getRoleSummaries,
  updateRole
} from "@common/features/roles/model";
import { getAssessmentIntervalSetting, setAssessmentIntervalSetting } from "@common/features/assessments/model";
import { IRoleForm } from "@common/types/forms/role";
import { Responsibility } from "@common/types/responsibilities/responsibilities";
import { Accountability } from "@common/types/reports/accountabilities/accountabilities";
import { RoleSkill } from "@common/types/roles/roles";
import tabs from "@styles/modules/tabs.module.scss";
import { RoleForm } from "../../role-form";
import { SavingError } from "../../role-form/saving-error";

interface EditRoleModalProps {
  id: number;
  closeModal: () => void;
  isOpen: boolean;
}

export const EditRoleModal: FC<EditRoleModalProps> = ({ id, isOpen, closeModal }) => {
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const [buttons, setButtons] = useState<ComplexModalButtons>();
  const [formData, setFormData] = useState<Partial<IRoleForm>>({});

  useEffect(() => {
    setLoading(true);
    let form: Partial<IRoleForm> = {};
    Promise.all([
      getRoleById(id).then(role => {
        form.name = role.name;
        form.description = role.job_purpose;
        form.orgUnit = role.unit.id;
        form.isActive = role.is_active;
        form.isManagerial = role.is_managerial;
      }),
      getAssessmentIntervalSetting(id).then(setting => {
        if (setting) {
          form.assessmentIntervalEnabled = setting.is_enabled;
          form.overallAssessmentInterval = setting.is_enabled ? setting.assessment_interval : 0;
        }
      }),
      getRoleAccountabilityDetail(id).then(settings => {
        const interval = settings?.is_enabled ? settings.interval_months || 0 : 0;
        if (settings) {
          /*if (settings.starting_month) {
            const currentDate = new Date();
            currentDate.setMonth(settings.starting_month - 1);
            form.accountabilityStartDate = currentDate;
          }*/
        }
        form.accountabilityInterval = interval;
        form.accountabilityIntervalBackup = interval;
      }),
      getResponsibilities(id).then(responsibilities => {
        form.responsibilities = responsibilities;
      }),
      getRoleSkills(id).then(skills => {
        form.skills = skills.map(item => ({
          id: item.skill.id,
          name: item.skill.name,
          category: item.skill.category.name,
          target: item.target_assessment,
          important: item.is_core,
          frequentlyUsed: item.is_used_now,
          frequentlyUsedInFuture: item.is_used_in_future
        }));
      }),
      getRoleAccountabilities(id).then(accountabilities => {
        form.accountability = accountabilities.map(item => ({
          id: item.id,
          area: item.area_name,
          metrics: item.metrics || [],
          weight: item.weight
        }));
      })
    ]).then(() => {
      setFormData(form);
      setLoading(false);
    });
  }, [id]);

  const tabsHeader = (
    <Tabs
      selectedIndex={tabIndex}
      className={tabs.tabs}
      selectedTabClassName={tabs["tab-selected"]}
      disabledTabClassName={tabs["tab-disabled"]}
    >
      <TabList className={classNames(tabs.list, "mt-2")}>
        <Tab className={tabs.tab} disabled>
          01 Details
        </Tab>
        <Tab className={tabs.tab} disabled>
          02 Skills
        </Tab>
        <Tab className={tabs.tab} disabled>
          03 Skills Assessments
        </Tab>
        <Tab className={tabs.tab} disabled>
          04 Accountabilities
        </Tab>
      </TabList>
    </Tabs>
  );

  const onFieldChange = (field: string, value: any) => {
    setFormData(formData => ({
      ...formData,
      [field]: value
    }));
  };

  const onFormDataChange = (data: Partial<IRoleForm>) => {
    setFormData(formData => ({
      ...formData,
      ...data
    }));
    return {
      ...formData,
      ...data
    };
  };

  const onSubmit = async (data: IRoleForm) => {
    console.log("submit", data);
    const role = await updateRole(id, {
      name: data.name,
      unit_id: data.orgUnit,
      is_managerial: data.isManagerial,
      is_active: data.isActive,
      job_purpose: data.description,
      description: data.description
    }).catch(e => {
      if (e?.response?.data?.message) {
        setApiError(e.response.data.message);
        setTabIndex(0);
      }
    });

    if (role) {
      await Promise.all([
        setAssessmentIntervalSetting(id, {
          is_enabled: data.assessmentIntervalEnabled,
          assessment_interval: data.overallAssessmentInterval || 1
        }).then(setting => {
          console.log(setting);
        }),

        setResponsibilities(
          id,
          data.responsibilities!.map(item => ({ title: item.title, description: item.description }))
        ).then((responsibilities: Responsibility[]) => {
          console.log(responsibilities);
        }),

        updateRoleAccountabilityDetail(id, {
          is_enabled: data.accountabilityInterval > 0,
          // starting_month: new Date(data.accountabilityStartDate).getMonth() + 1,
          ...(data.accountabilityInterval > 0
            ? {
                interval_months:
                  data.accountabilityInterval === 0 ? data.accountabilityIntervalBackup : data.accountabilityInterval
              }
            : {})
        }).then(accountabilitySetting => {
          console.log(accountabilitySetting);
        }),

        attachSkillsToRole(
          id,
          data.skills!.map(item => {
            let importance = 0;
            if (item.important) {
              importance |= 1;
            }
            if (item.frequentlyUsed) {
              importance |= 2;
            }
            if (item.frequentlyUsedInFuture) {
              importance |= 4;
            }
            return {
              id: item.id,
              target_assessment: item.target,
              importance,
              is_core: item.important,
              is_used_now: item.frequentlyUsed,
              is_used_in_future: item.frequentlyUsedInFuture
            };
          })
        ).then((skill: RoleSkill) => {
          console.log(skill);
        }),

        setRoleAccountabilities(
          id,
          data.accountability!.map(item => ({ area_name: item.area, metrics: item.metrics, weight: item.weight }))
        ).then((accountablities: Accountability) => {
          console.log(accountablities);
        })
      ]);
      getRoleSummaries();
      closeModal();
    }
  };

  return (
    <ComplexModal
      isOpen={isOpen}
      closeModal={closeModal}
      shouldCloseOnOverlayClick={false}
      headerTitle="Edit Role"
      HeaderTitleComponent={
        <div className="flex items-center ml-auto mr-4">
          <div className="text-secondary text-sm font-normal mr-3">
            This role is{" "}
            <b className={formData.isActive ? "text-success" : ""}>{formData.isActive ? "Active" : "Inactive"}</b>
          </div>
          <Switch
            theme="success"
            value={formData.isActive}
            readonly={false}
            onChange={value => onFieldChange("isActive", value)}
          />
        </div>
      }
      headerSecondRow={tabsHeader}
      headerClass="pb-0"
      buttons={buttons}
    >
      {loading ? (
        <p className="text-center p-5">Loading...</p>
      ) : (
        <RoleForm
          tab={tabIndex}
          formData={formData}
          onTabChange={setTabIndex}
          onChange={onFormDataChange}
          onSubmit={onSubmit}
          onClose={closeModal}
          onSetModalButtons={setButtons}
        />
      )}
      {apiError && (
        <SavingError
          className="absolute w-full h-full top-0 lef-0 z-100"
          message={apiError}
          onClose={() => setApiError("")}
        />
      )}
    </ComplexModal>
  );
};
