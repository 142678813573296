import { ProfileFormSchema } from "./model";
import { useForm } from "@common/hooks/form";
import { FieldErrors } from "react-hook-form";
import classNames from "classnames";
import grid from "@styles/modules/grid.module.scss";
import form from "@styles/modules/form.module.scss";
import styles from "./profile-employee.module.scss";
import layout from "@styles/layouts/settings.module.scss";
import { Button, FormInputSelect, FormInputText, SelectOptionsProp, SelectUI } from "@ui";
import { Titleline } from "@features/profile/titleline";
import { getPhrases } from "@common/helpers/localization";
import { MyProfileInterface, ProfileInterface } from "@common/types/reports/profile/profile";
import { setProfile } from "@common/features/profile/model";
import { getExtendData } from "@common/helpers/data-helpers";
import { useContext, useEffect, useMemo, useState } from "react";
import { getTimezones } from "@common/features/timezones/model";
import { CommonWithId } from "@common/types/common";
import { GlobalStore } from "@common/api/store/global";
import { Roles } from "@common/constants";
import { CSSObjectWithLabel } from "react-select";

const loc = getPhrases("profile_page", "form_employee");

interface Props {
  data: ProfileInterface;
}

export const ProfileEmployeeForm = ({ data }: Props) => {
  localStorage.removeItem("isFirstLogin");
  const { $destinationDataStore, $userStore, $profileStore } = useContext(GlobalStore);
  const userInfo = $userStore.getUserInfo();
  const defaultValues: MyProfileInterface = {
    first_name: data?.first_name ?? "",
    middle_name: data?.middle_name ?? "",
    last_name: data?.last_name ?? "",
    industry_experience: data?.industry_experience ?? 0,
    timezone_id: data?.timezone?.id ? +data?.timezone.id : 18
  };

  const [timezones, setTimezones] = useState<CommonWithId[]>();

  useEffect(() => {
    const fetchedTimezones = $destinationDataStore.getTimezones();
    if (fetchedTimezones.length) {
      setTimezones(fetchedTimezones);
    } else {
      getTimezones(setTimezones);
    }
  }, []);

  const timezoneOptions: SelectOptionsProp[] | undefined = useMemo(
    () =>
      timezones
        ? timezones.map(item => ({
            value: Number(item.id),
            label: item.name
          }))
        : [],
    [timezones]
  );

  const methods = useForm<MyProfileInterface>({
    schema: ProfileFormSchema,
    defaultValues
  });
  const handleSuccess = (values: MyProfileInterface) => {
    if (JSON.stringify(values) !== JSON.stringify(defaultValues)) {
      setProfile(values);
    }
  };
  const handleError = (errors: FieldErrors<MyProfileInterface>) => {
    console.error(errors);
  };

  const rowClass = classNames(form.row, grid.list);
  const labelClass = classNames(grid.item, grid["item-4x"]);
  const valueClass = classNames(grid.item, grid["item-8x"]);
  return (
    <form onSubmit={methods.handleSubmit(handleSuccess, handleError)}>
      <div className={layout.row}>
        <div className={grid.root}>
          <div className={grid.container}>
            <Titleline name={userInfo?.first_name ?? ""} />
          </div>
        </div>
      </div>
      <div className={layout.row}>
        <div className={grid.root}>
          <div className={grid.container}>
            <div className={rowClass}>
              <div className={labelClass}>{loc.name_label}</div>
              <div className={classNames(valueClass, grid.list)}>
                <div className={classNames(grid.item, grid["item-4x"])}>
                  <FormInputText
                    register={methods.register}
                    placeholder={loc.first_name_placeholder}
                    name="first_name"
                    theme="orange"
                    error={methods.formState.errors["first_name"]?.message}
                  />
                </div>
                <div className={classNames(grid.item, grid["item-4x"])}>
                  <FormInputText
                    register={methods.register}
                    placeholder={loc.middle_name_placeholder}
                    name="middle_name"
                    theme="orange"
                    error={methods.formState.errors["middle_name"]?.message}
                  />
                </div>
                <div className={classNames(grid.item, grid["item-4x"])}>
                  <FormInputText
                    register={methods.register}
                    placeholder={loc.last_name_placeholder}
                    name="last_name"
                    theme="orange"
                    error={methods.formState.errors["last_name"]?.message}
                  />
                </div>
              </div>
            </div>
            <div className={rowClass}>
              <div className={labelClass}>{loc.primary_email_label}</div>
              <div className={valueClass}>{data.email}</div>
            </div>
            <div className={rowClass}>
              <div className={labelClass}>{loc.timezone_label}</div>
              {$profileStore.currentRoleId === Roles.employee ? (
                <div className={classNames(valueClass, grid.list)}>
                  <FormInputSelect
                    type="select"
                    modClass={classNames(grid.item, grid["item-12x"])}
                    name="timezone_id"
                    theme="orange"
                    getItemLabel={option => option.label}
                    getItemValue={option => option.value}
                    list={timezoneOptions}
                    methods={methods}
                    styles={{
                      control: (styles: CSSObjectWithLabel) => ({
                        ...styles,
                        background: "none !important",
                        border: "none !important"
                      }),
                      valueContainer: (styles: CSSObjectWithLabel) => ({
                        ...styles,
                        padding: "0 9px 0 !important"
                      }),
                      singleValue: (styles: CSSObjectWithLabel) => ({
                        ...styles,
                        color: "white !important"
                      }),
                      placeholder: (styles: CSSObjectWithLabel) => ({
                        ...styles,
                        color: "#3478bc !important"
                      })
                    }}
                  />
                </div>
              ) : (
                <div className={valueClass}>{data.timezone?.name}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={layout.row}>
        <div className={grid.root}>
          <div className={grid.container}>
            <div className={rowClass}>
              <div className={labelClass}>{loc.experience_label}</div>
              <div className={classNames(valueClass, grid.list)}>
                <div className={classNames(grid.item, grid["item-12x"])}>
                  <FormInputText
                    type="number"
                    register={methods.register}
                    placeholder={loc.experience_placeholder}
                    name="industry_experience"
                    theme="orange"
                    error={methods.formState.errors["industry_experience"]?.message}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={layout.row}>
        <div className={grid.root}>
          <div className={grid.container}>
            <div className={rowClass}>
              <div className={labelClass}>{loc.role_label}</div>
              <div className={valueClass}>{data.current_role?.name}</div>
            </div>
            <div className={rowClass}>
              <div className={labelClass}>{loc.start_date_label}</div>
              <div className={valueClass}>{getExtendData(data.start_date * 1000)}</div>
            </div>
            {data?.current_manager && (
              <div className={rowClass}>
                <div className={labelClass}>{loc.reporting_label}</div>
                <div
                  className={valueClass}
                >{`${data.current_manager.first_name} ${data.current_manager.last_name}`}</div>
              </div>
            )}
            <div className={rowClass}>
              <div className={labelClass}>{loc.team_label}</div>
              <div className={valueClass}>{data.current_unit?.name}</div>
            </div>
            <div className={rowClass}>
              <div className={labelClass}>{loc.location_label}</div>
              <div className={valueClass}>{data.office?.name}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={layout.row}>
        <div className={grid.root}>
          <div className={grid.container}>
            <Button title={loc.save_button} theme="dark-blue" className={styles.submit} type="submit" />
          </div>
        </div>
      </div>
    </form>
  );
};
