import React, {FC, useCallback, useEffect, useState} from 'react';
import * as yup from 'yup';
import {useDropzone} from "react-dropzone";
import {
  Button,
  CKEditor,
  FormInputRadio,
  FormInputText,
  FormWrapper,
} from '@ui';
import { useForm } from '@common/hooks/form';
import { ILearnPathForm } from '@common/types/forms/learn';
import { getPhrases } from '@common/helpers/localization';
import { ILearnPathFormTabInitData } from '../index';
import { FormError } from '../form-error';
import './details-tab.scss';

export interface ILearningPathDetailsTabProps {
  formData: Partial<ILearnPathForm>;
  onInit?: (data: ILearnPathFormTabInitData) => void;
  onBack(): void;
  onNext(data: Partial<ILearnPathForm>): void;
  onSubmit(data: Partial<ILearnPathForm>): void;
  onClose(): void;
}

interface ILearningPathDetailsForm {
  name: string;
  description: string;
  imageFile: string;
}

const loc = getPhrases('common', 'forms').validation;

const detailsFormSchema: yup.SchemaOf<ILearningPathDetailsForm> = yup
  .object()
  .shape({
    name: yup.string().required(loc.required),
    description: yup.string().required(loc.required),
    imageFile: yup.string().required(loc.required),
  });

export const LearnDetailsTab: FC<ILearningPathDetailsTabProps> = ({
  formData,
  onInit,
  onBack,
  onNext,
  onSubmit,
  onClose,
}) => {
  const methods = useForm<ILearningPathDetailsForm>({
    schema: detailsFormSchema,
    defaultValues: {
      name: formData.name || '',
      description: formData.description || '',
      imageFile: formData.imageFile ? (typeof formData.imageFile === 'string' ? formData.imageFile : URL.createObjectURL(formData.imageFile)) : '',
    },
  });

  const description = methods.watch('description');
  const filePath = methods.watch('imageFile');
  const [imageFile, setImageFile] = useState<File>();
  const [showFormError, setShowFormError] = useState(false);
  const [timestamp, setTimestamp] = useState<number>();

  const onDropImage = useCallback((acceptedFiles) => {
    if (!acceptedFiles.length) {
      return;
    }

    const imageUrl = URL.createObjectURL(acceptedFiles[0]);
    setImageFile(acceptedFiles[0]);
    onFieldChange('imageFile', imageUrl);
  }, []);

  const {getRootProps, getInputProps, open: openDropzone} = useDropzone({
    noClick: true,
    accept: {
      'image/*': ['.jpeg', '.png'],
    },
    onDrop: onDropImage,
  });
  
  useEffect(() => {
    if (onInit) {
      if (showFormError) {
        onInit({ buttons: undefined });
      } else {
        onInit({
          buttons: {
            leftSide: [
              {
                title: <span>Cancel<br/>And Close</span>,
                theme: 'secondary-gradient',
                className: '!w-30 !h-14 !text-sm !text-left !font-semibold',
                onClick: onClose,
              },
            ],
            rightSide: [
              {
                title: 'Next',
                theme: 'warning',
                shape: 'right-arrow',
                className: '!w-20 !text-sm !font-semibold',
                onClick() {
                  methods.handleSubmit(handleSuccess, handleError)();
                },
              },
            ],
          },
        });
      }
    }
  }, [onInit, showFormError]);

  const onFieldChange = (field: keyof ILearningPathDetailsForm, value: any) => {
    methods.setValue(field, value);
    methods.trigger(field);
    setTimestamp(new Date().getTime());
  };

  const handleSuccess = (values: ILearningPathDetailsForm) => {
    const form: Partial<ILearnPathForm> = values;
    if (imageFile) {
      form.imageFile = imageFile;
    }
    onSubmit(form);
    onNext(form);
  };

  const handleError = (e: any) => {
    console.log(e);
    // setShowFormError(true);
  };

  return (
    <FormWrapper className="learn-path-form details-wrapper text-black px-6" onSubmit={methods.handleSubmit(handleSuccess, handleError)}>
      <FormInputText
        name="name"
        className="text-sm italic text-black"
        label="Learning Path Name"
        register={methods?.register}
        error={methods?.formState.errors.name?.message}
      />

      <CKEditor
        value={description}
        className="text-sm mt-6"
        config={{
          removePlugins: ['MediaEmbed', 'Table'],
          placeholder: 'Enter a short description',
        }}
        helperText={methods.formState.errors?.description?.message}
        helperTextClass="text-xs text-danger mt-1"
        onChange={(value) => onFieldChange('description', value)}
      />

      <div className="flex flex-wrap">
        <div className="mr-4 mt-4">
          <FormInputRadio
            name="mediaType"
            label="Select an image from local device"
            value="local"
            size="sm"
            theme="gray"
            checked
          />
          <Button className="!w-auto !h-8 text-sm font-semibold ml-10 mt-4" theme="blue" appearance="solid" onClick={openDropzone}>
            <span className="px-4">Upload</span>
          </Button>
        </div>

        <div className="flex flex-col items-center mt-4">
          <div className="border border-solid border-gray-cd text-sm text-gray rounded-full px-6 py-1">Image preview:</div>
          <div
            className="w-120 h-60 max-w-[70vw] max-h-[30vw] relative flex items-center bg-contain bg-center bg-no-repeat border border-solid border-gray-cd rounded-md mt-2"
            style={{ backgroundImage: `url(${filePath})` }}
          />
          {methods.formState.errors?.imageFile?.message && (
            <div className="text-xs text-danger mr-auto mt-2">Please select am image.</div>
          )}
        </div>
      </div>

      <div {...getRootProps()}>
        <input {...getInputProps()} />
      </div>

      {showFormError && (
        <FormError className="w-full h-full absolute top-0 left-0 z-100" onClose={() => setShowFormError(false)} />
      )}
    </FormWrapper>
  );
};
