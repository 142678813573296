import {
  FormattedEmployeeList,
  PeopleByOrgUnit,
  PeopleIntermediate,
  PeopleSubordinates
} from "@common/types/reports/people/people";

export const createEmployeeTableData = (items: PeopleSubordinates[]): FormattedEmployeeList[] => {
  return items.reduce((previousArray: FormattedEmployeeList[], currentElement: PeopleSubordinates) => {
    return [
      ...previousArray,
      {
        id: currentElement.id,
        is_active: currentElement.is_active,
        is_user_activated: currentElement.is_user_activated,
        full_name: currentElement.full_name,
        role: currentElement.current_role_name,
        industry_experience: currentElement.industry_years,
        company_experience: currentElement.company_years,
        office: currentElement.office_name,
        reports_to: currentElement.current_manager_full_name,
        is_editable: currentElement.is_editable,
        ...(currentElement.last_assessment_session?.expertise_level?.name && {
          expertise: currentElement.last_assessment_session.expertise_level.name
        }),
        ...(currentElement.last_assessment_session?.skill_gap && {
          skill_gap: currentElement.last_assessment_session.skill_gap
        }),
        ...(currentElement.last_assessment_session?.report_card_url && {
          report: currentElement.last_assessment_session.report_card_url
        })
      }
    ];
  }, []);
};

export const createIntermediateTableData = (items: PeopleByOrgUnit[]): PeopleIntermediate[] => {
  return items.reduce((previousArray: PeopleIntermediate[], currentElement: PeopleByOrgUnit) => {
    return [
      ...previousArray,
      {
        id: currentElement.id,
        name: currentElement.name,
        ...((currentElement.children.length || currentElement.members.length) && {
          inside: {
            children: currentElement.children,
            members: currentElement.members
          }
        }),
        members_count: currentElement.members_count,
        avg_skill_gap: currentElement.avg_skill_gap
      }
    ];
  }, []);
};

export const filterUsers = (field: string, filter: (...params: any[]) => boolean, data: any, count = 0, depth = 0) => {
  if (data && typeof data === "object") {
    if (Array.isArray(data)) {
      data.forEach(datum => {
        count += filterUsers(field, filter, datum, 0, depth + 1);
      });
    } else {
      Object.keys(data).forEach(key => {
        if (key === field && Array.isArray(data[key])) {
          count += filterUsers(field, filter, data[key], 0, depth + 1);
          console.log();
          data[key] = data[key].filter((member: any) => filter(member) || count > 0);
          count += data[key].filter((member: any) => filter(member)).length;
          data[`${key}_count`] = count;
        } else {
          count += filterUsers(field, filter, data[key], 0, depth + 1);
          data[`${field}_count`] = count;
        }
      });
    }
  }

  return count;
};
