import { getExtendData } from "@common/helpers/data-helpers";
import { getPhrases } from "@common/helpers/localization";
import { AssessmentTypeEnum } from "@common/types/reports/assessments/common";
import { Svg } from "@ui";
import classNames from "classnames";
import { EmployeeCurrentAssessmentButtonProps } from "../types";
import styles from "./employee-current-assessment-button.module.scss";

const loc = getPhrases("dashboard_page", "personal");

interface DataByType {
  icon?: string;
  text: string;
  type: string;
}

const getDataByType = (id: number): DataByType | undefined => {
  switch (id) {
    case AssessmentTypeEnum.ManagerAssessment: {
      return {
        text: loc.assessments_manager,
        icon: "chat-double",
        type: "manager"
      };
    }
    case AssessmentTypeEnum.PeerAssessment: {
      return { text: loc.assessments_peer, type: "peer" };
    }
    case AssessmentTypeEnum.SelfAssessment: {
      return { text: loc.assessments_self, type: "self", icon: "chat" };
    }
  }
};

export const EmployeeCurrentAssessmentButton = ({ item, label, onClick }: EmployeeCurrentAssessmentButtonProps) => {
  let date = "";
  if (item.completed_at) {
    date = `${loc.assessments_completed_at} ${getExtendData(item.completed_at * 1000)}`;
  } else if (item.required_at) {
    date = `${loc.assessments_due} ${getExtendData(item.required_at * 1000)}`;
  }

  const dataByType = getDataByType(Number(item.type.id));

  return (
    <div
      className={classNames(styles.item, styles[`item-${item.status}`], styles[`item-${dataByType?.type}`])}
      onClick={onClick}
    >
      <div className={styles.content}>
        <div className={styles.text}>
          <div className={styles.date}>{date}</div>
          <div className={styles.type}>{label ?? dataByType?.text}</div>
        </div>
        {dataByType?.icon && <Svg name={dataByType.icon} modClass={styles.status} />}
      </div>
    </div>
  );
};
