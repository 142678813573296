import { getReportOrgUnits } from "@common/features/org-units/model";
import { makeAutoObservable, toJS } from "mobx";
import { ClassCommon } from "../types/common";
import { RoleSummary } from "@common/types/roles/roles";

export class Roles implements ClassCommon {
  roleSummaries: RoleSummary[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  setRoleSummaries(roleSummaries: any) {
    this.roleSummaries = roleSummaries;
  }

  getRoleSummaries() {
    return toJS(this.roleSummaries);
  }

  updateRoleSummaries() {
    getReportOrgUnits();
  }

  reset(): void {
    this.roleSummaries = [];
  }
}

export const $rolesStore = new Roles();
