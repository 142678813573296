import {
  AccountabilitiesSessionAssessment,
  AccountabilitiesSessionAssessmentResult
} from "@common/types/reports/accountabilities/accountabilities";
import classNames from "classnames";
import { useState } from "react";
import grid from "@styles/modules/grid.module.scss";
import styles from "./accountability-item.module.scss";
import textColors from "@styles/modules/common/text-colors.module.scss";
import { getValue, getItemWeight } from "../../helpers";
import { getSessionMonthId, months } from "./model";

interface Props {
  item: AccountabilitiesSessionAssessment;
  interval: number;
}

interface PreviousSessions {
  [key: number]: AccountabilitiesSessionAssessmentResult;
}

export const AccountabilityItem = ({ item, interval }: Props) => {
  const [isVisible, setVisibility] = useState<boolean>(false);
  const onClickHandler = () => {
    setVisibility(prev => !prev);
  };

  const date = new Date();
  const currentMonth: number = date.getMonth() + 1;
  const previousSessions: PreviousSessions = {};
  let lastSessionMonth: number | null = null;
  const onboardingMonth = 1;

  item.results.length &&
    item.results.forEach((session, index) => {
      const sessionMonthId = getSessionMonthId(session.date);
      if (sessionMonthId >= onboardingMonth) {
        previousSessions[getSessionMonthId(session.date)] = session;
      }
      if (index + 1 === item.results.length) {
        lastSessionMonth = sessionMonthId;
      }
    });

  const nextSession = lastSessionMonth && lastSessionMonth + interval <= 12 ? lastSessionMonth + interval : null;

  return (
    <div className={styles.item}>
      <div className={styles["info"]} onClick={onClickHandler}>
        <div className={styles.top}>
          <div className={styles["info-metrics"]}>
            <div className={styles["info-title"]}>{item.name}</div>
            <div className={styles["info-status"]}>
              <span className={classNames(styles["info-icon"], isVisible && "expand")} />
              {item.metrics.length}x Metrics (show)
            </div>
          </div>
          <div className={styles.weight}>
            <span className={styles["weight-title"]}>Weight:</span>
            <span className={styles["weight-value"]}>{`${getItemWeight(item.weight)}%`}</span>
          </div>
        </div>
        {item.metrics.length && isVisible && (
          <div className={styles.metrics}>
            <div className={classNames(grid.list, styles["metrics-wrapper"])}>
              {item.metrics.map((item, index) => {
                return (
                  <div
                    className={classNames(grid.item, grid["item-3x"], styles["metrics-item"])}
                    key={`metric-item-${index}`}
                  >
                    <div className={styles["metrics-title"]}>Metric {index + 1}:</div>
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className={styles.calendar}>
        {months.map(month => {
          const monthId = Number(month.id);
          const blockClassName = classNames(
            styles["month-block"],
            nextSession === monthId
              ? styles["month-next"]
              : currentMonth === monthId
              ? styles["month-current"]
              : onboardingMonth > monthId
              ? styles["month-disabled"]
              : styles["month-active"]
          );

          const manager = previousSessions[monthId]?.manager
            ? // @ts-ignore
              getValue(previousSessions[monthId]?.manager)
            : null;
          const self = previousSessions[monthId]?.self
            ? // @ts-ignore
              getValue(previousSessions[monthId]?.self)
            : null;

          return (
            <div className={styles.month} key={`month-${item.id}-${month.id}`}>
              <div className={styles["month-name"]}>{month.name}</div>
              <div className={blockClassName}>
                {(manager || self) && (
                  <>
                    <span className={textColors.lightorange}>{self}</span>
                    <span className={styles["value-separator"]}>/</span>
                    <span className={textColors.cornflowerblue}>{manager}</span>
                  </>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
