import moment, { Moment } from "moment";
import { TableCellProps, TableColumn } from "@common/types/table";
import classNames from "classnames";
import styles from "./status.module.scss";
import { LogStatus } from "@common/types/reports/activity-logs/activity-logs";

const getStatusClassName = (status: LogStatus) => {
  switch (status) {
    case LogStatus.active:
      return styles.active;
    case LogStatus.completed:
      return styles.active;
    case LogStatus.pending:
      return styles.pending;
    case LogStatus.inactive:
      return styles.deleted;
    default:
      return styles.none;
  }
};

export const Status = ({ props }: TableCellProps) => {
  const { column } = props;
  const { decorator } = column as TableColumn<string>;
  let value = props.value || "";
  let completedAt = value?.completed_at;
  let createdAt = value.created_at;
  if (typeof props.value === "object") {
    if (props.value?.completed_at) {
      value = LogStatus.completed;
    } else if (props.value?.created_at) {
      value = LogStatus.pending;
    } else {
      value = "";
    }
  }
  const transformedValue = !value && decorator ? (decorator(props) as unknown as any) : "";

  return (
    <div
      className={classNames(
        styles.status,
        getStatusClassName(value),
        "w-full text-xs rounded-sm whitespace-pre text-center"
      )}
    >
      {value || transformedValue || "N/A"}
      {value === LogStatus.completed ? "\n" + moment(completedAt).format("MMM DD yyyy") : ""}
      {value === LogStatus.pending ? "\n" + moment(createdAt).format("MMM DD yyyy") : ""}
    </div>
  );
};
