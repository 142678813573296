import { FC, ReactElement, useEffect, useState } from "react";
import classNames from "classnames";
import "./checkbox.scss";

interface ICheckboxProps {
  className?: string;
  theme?: "default" | "primary" | "success";
  size?: "sm" | "md" | "lg";
  value?: boolean;
  label?: string | ReactElement;
  labelClass?: string;
  disabled?: boolean;
  readonly?: boolean;
  onChange?: (value: boolean) => void;
}

export const Checkbox: FC<ICheckboxProps> = ({
  className,
  theme = "default",
  size = "md",
  value = false,
  label,
  labelClass,
  disabled,
  readonly,
  onChange = () => {}
}) => {
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  const onToggle = () => {
    if (!disabled && !readonly) {
      setChecked(!checked);
      onChange(!checked);
    }
  };

  return (
    <div
      className={classNames(
        `checkbox checkbox-${theme} checkbox-${size}`,
        checked && "checked",
        disabled && "disabled",
        className
      )}
    >
      <div className="checkbox-input" onClick={onToggle}>
        <i className="fa fa-check" />
      </div>

      {label && (
        <label className={labelClass} onClick={onToggle}>
          {label}
        </label>
      )}
    </div>
  );
};
