import { TableColumn } from "@common/types/table";
import { SecondLevelTable } from "@ui";
import { FormattedOrgUnits } from "../types";
import { orgUnitsTableModel } from "../model";
import classNames from "classnames";
import grid from "@styles/modules/grid.module.scss";
import { OrgAction } from "@features/settings/blocks/org-units";

interface Props {
  data: FormattedOrgUnits[];
}

export const SecondLevel = ({ data }: Props) => {
  const [nameCol, ...columns]: TableColumn<FormattedOrgUnits>[] = orgUnitsTableModel;
  return SecondLevelTable<FormattedOrgUnits>({
    data,
    columns: [
      { ...nameCol, modClass: classNames(nameCol.modClass, "!pl-[5px]") },
      ...columns,
      {
        key: "index",
        modClass: classNames(grid.item, grid["item-1x"]),
        component: OrgAction
      }
    ],
    modStyles: { "cell-inner": "justify-start !px-0" },
    sorting: false,
    theme: "orange",
    hideHeader: true
  });
};
