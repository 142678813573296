import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { Tab, TabList, Tabs } from 'react-tabs';
import { ComplexModal, ComplexModalButtons, Switch } from '@ui';
import { IRoleForm } from '@common/types/forms/role';
import { LearnPathForm } from '../../learn-path-form';
import { SavingError } from '../../learn-path-form/saving-error';
import tabs from '@styles/modules/tabs.module.scss';

interface AddLearningPathModalProps {
  closeModal: () => void;
  isOpen: boolean;
}

export const AddLearningPathModal: FC<AddLearningPathModalProps> = ({ isOpen, closeModal }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [apiError, setApiError] = useState('');
  const [buttons, setButtons] = useState<ComplexModalButtons>();
  const [formData, setFormData] = useState<Partial<IRoleForm>>({
    isActive: true,
    isManagerial: true,
  });

  const tabsHeader = (
    <Tabs
      selectedIndex={tabIndex}
      className={tabs.tabs}
      selectedTabClassName={tabs['tab-selected']}
      disabledTabClassName={tabs['tab-disabled']}
    >
      <TabList className={classNames(tabs.list, 'mt-2')}>
        <Tab className={tabs.tab} disabled>01 Details</Tab>
        <Tab className={tabs.tab} disabled>02 Learning Resources</Tab>
        <Tab className={tabs.tab} disabled>03 Skills</Tab>
        <Tab className={tabs.tab} disabled>04 Quiz</Tab>
        <Tab className={tabs.tab} disabled>05 Gamification</Tab>
        <Tab className={tabs.tab} disabled>06 Enrollment</Tab>
      </TabList>
    </Tabs>
  );

  const onFieldChange = (field: string, value: any) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const onFormDataChange = (data: Partial<IRoleForm>) => {
    const newData = {
      ...formData,
      ...data,
    };
    setFormData(newData);
    return newData;
  };

  const onSubmit = async (data: IRoleForm) => {
    console.log('submit', data);
    closeModal();
  };

  return (
    <ComplexModal
      isOpen={isOpen}
      closeModal={closeModal}
      shouldCloseOnOverlayClick={false}
      headerTitle={<span>Add <span className="text-warning">Learning Path</span></span>}
      HeaderTitleComponent={(
        <div className="flex items-center ml-auto mr-4">
          <div className="text-secondary text-sm font-normal mr-3">
            This learning path is <b className={formData.isActive ? 'text-success' : ''}>{formData.isActive ? 'Active' : 'Inactive'}</b>
          </div>
          <Switch
            theme="success"
            readonly
            value={formData.isActive}
            onChange={(value) => onFieldChange('isActive', value)}
          />
        </div>
      )}
      headerSecondRow={tabsHeader}
      headerClass="pb-0"
      buttons={buttons}
    >
      <LearnPathForm
        tab={tabIndex}
        formData={formData}
        onTabChange={setTabIndex}
        onChange={onFormDataChange}
        onSubmit={onSubmit}
        onClose={closeModal}
        onSetModalButtons={setButtons}
      />
      {apiError && (
        <SavingError
          className="absolute w-full h-full top-0 lef-0 z-100"
          message={apiError}
          onClose={() => setApiError('')}
        />
      )}
    </ComplexModal>
  );
};
