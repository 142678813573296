import classNames from "classnames";
import styles from "./progressbar.module.scss";

type ProgressbarType = "line" | "rectangle";

interface Props {
  progress: number;
  modClass?: string;
  type?: ProgressbarType;
}
export const ProgressBar = ({ progress, modClass, type = "rectangle" }: Props) => {
  const blockClass = classNames(styles.block, styles[`block-${type}`], modClass);
  return (
    <div className={blockClass}>
      <div className={styles["block-inner"]} style={{ width: `${progress}%` }} />
    </div>
  );
};
