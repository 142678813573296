import React from "react";
import { Switch } from "./Switch";
import styles from "./swithc-with-text.module.scss";

interface SwitchWithTextProps {
  text: string;
  checked: boolean;
  onClick: () => void;
}

export const SwitchWithText: React.FC<SwitchWithTextProps> = ({ checked, text, onClick }) => {
  return (
    <div onClick={onClick} className={styles.wrapper}>
      <Switch checked={checked} />

      <p className={styles.text}>{text}</p>
    </div>
  );
};
