import classNames from "classnames";
import styles from "./switcher.module.scss";

interface Props {
  trueValue: string;
  falseValue: string;
  isTrue: boolean;
  onClick: () => void;
  modClass?: string;
}

export const Switcher = ({ trueValue, falseValue, isTrue, onClick, modClass }: Props) => {
  const onClickHandler = () => {
    onClick();
  };
  const switcherClass = classNames(styles.block, modClass);
  const innerClass = classNames(styles.inner, !isTrue && styles["inner-false"]);
  const itemTrueClass = classNames(styles.item, isTrue && styles["item-active"]);
  const itemFalseClass = classNames(styles.item, !isTrue && styles["item-active"]);
  return (
    <div className={switcherClass}>
      <div className={innerClass}>
        <div className={itemTrueClass} onClick={onClickHandler}>
          {trueValue}
        </div>
        <div className={itemFalseClass} onClick={onClickHandler}>
          {falseValue}
        </div>
      </div>
    </div>
  );
};
