import classNames from "classnames";
import React from "react";
import styles from "./titles.module.scss";

interface Props {
  level: "level_1" | "level_2" | "level_3";
  modClass?: string;
}

export const Titles: React.FC<Props> = ({ level, modClass, children }) => {
  const titleClass = classNames(styles[level], modClass);
  switch (level) {
    case "level_1":
      return <h1 className={titleClass}>{children}</h1>;
    case "level_2":
      return <h2 className={titleClass}>{children}</h2>;
    case "level_3":
      return <h3 className={titleClass}>{children}</h3>;
  }
};
