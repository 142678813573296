import classNames from "classnames";
import "./html-viewer.scss";

interface Props {
  html: string;
  modClass?: string;
}

export const HTMLViewer = ({ modClass, html }: Props) => {
  return <div className={classNames("html-viewer", modClass)} dangerouslySetInnerHTML={{ __html: html }} />;
};
