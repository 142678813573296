import React from "react";
import styles from "./badge.module.scss";
import classNames from "classnames";

interface BadgeProps {
  type: "Beginner" | "Intermediate" | "Advanced";
}

export const Badge: React.FC<BadgeProps> = ({ children, type }) => {
  const getClassName = () => {
    if (type === "Beginner") {
      return styles.beginner;
    } else if (type === "Intermediate") {
      return styles.intermediate;
    } else if (type === "Advanced") {
      return styles.advanced;
    }
  };

  return <div className={classNames(styles.badge, getClassName())}>{children}</div>;
};
