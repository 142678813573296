import React from "react";
import styles from "./circular-progress-with-duration.module.scss";
import { CircularProgressBar } from "@ui";

interface Props {
  duration?: string;
  progress: number;
}

export const CircularProgressWithDuration = ({ duration, progress }: Props) => {
  return (
    <div className={styles.iconWithTime}>
      {duration && <CircularProgressBar value={progress} />}
      <div className={styles.time}>
        {duration}
        {/*2 hrs 40 mins*/}
      </div>
    </div>
  );
};
