import { getPhrases } from "@common/helpers/localization";
import { AssessmentFormRating } from "@common/types/forms/assessments-employee";

const loc = getPhrases("forms", "assessment");
export const rating: AssessmentFormRating[] = [
  {
    title: loc.rating_no_knowledge_title,
    description: loc.rating_no_knowledge_description
  },
  {
    title: loc.rating_basics_concepts_title,
    description: loc.rating_basics_concepts_description
  },
  {
    title: loc.rating_getting_started_title,
    description: loc.rating_getting_started_description
  },
  {
    title: loc.rating_some_experience_title,
    description: loc.rating_some_experience_description
  },
  {
    title: loc.rating_continuous_experience_title,
    description: loc.rating_continuous_experience_description
  },
  {
    title: loc.rating_almost_independent_title,
    description: loc.rating_almost_independent_description
  },
  {
    title: loc.rating_fully_independent_title,
    description: loc.rating_fully_independent_description
  },
  {
    title: loc.rating_help_others_title,
    description: loc.rating_help_others_description
  },
  {
    title: loc.rating_teach_others_title,
    description: loc.rating_teach_others_description
  },
  {
    title: loc.rating_industry_experts_title,
    description: loc.rating_industry_experts_description
  }
];
