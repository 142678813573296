import { ScoreDataProps } from "@common/types/reports/skills/skills-employee";
import { CategoriesOptics } from ".";

export const formatCategoriesData = (data: ScoreDataProps[]): CategoriesOptics[] => {
  return data.reduce((previous: CategoriesOptics[], current) => {
    return [
      ...previous,
      {
        id: current.id,
        avg_target: current.avg_target ? current.avg_target * 10 : 0,
        name: current.name,
        avg_score: current.avg_score ? current.avg_score * 10 : 0
      }
    ];
  }, []);
};
