import { TableCellProps } from "@common/types/table";
import classNames from "classnames";
import styles from "./status.module.scss";

interface StatusCellInterface extends TableCellProps {}

export const StatusCell = ({ props }: StatusCellInterface) => {
  const { value } = props;
  return (
    <div className={classNames(styles.status, value ? styles.active : styles.pending)}>
      {value ? "Active" : "Pending"}
    </div>
  );
};
