import { FC, useCallback } from "react";
import { ComplexModalButtons } from "@ui";
import { ILearnForm } from "@common/types/forms/learn";
import { LearnDetailsTab } from "@features/learn/learn-form/details-tab";
import { LearnSyllabusTab } from "@features/learn/learn-form/syllabus-tab";
import { LearnSkillsTab } from "@features/learn/learn-form/skills-tab";
import { LearnMediaTab } from "@features/learn/learn-form/media-tab";
import { LearnQuizTab } from "@features/learn/learn-form/quiz-tab";

export interface ILearnFormProps {
  editable?: boolean;
  tab: number;
  formData: Partial<ILearnForm>;
  onTabChange(tab: number): void;
  onChange(data: Partial<ILearnForm>): Partial<ILearnForm>;
  onSubmit(data: Partial<ILearnForm>): void;
  onClose(): void;
  onSetModalButtons(buttons?: ComplexModalButtons): void;
}

export interface ILearnFormTabInitData {
  buttons?: ComplexModalButtons;
}

export const LearnForm: FC<ILearnFormProps> = ({
  tab,
  formData,
  onTabChange,
  onChange,
  onSubmit,
  onClose,
  onSetModalButtons,
  editable
}) => {
  const onInitForm = useCallback((data: ILearnFormTabInitData) => {
    onSetModalButtons(data.buttons);
  }, []);

  const gotoPrevTab = () => {
    if (tab === 0) {
      onClose();
    } else {
      onTabChange(tab - 1);
    }
  };

  const gotoNextTab = (data?: Partial<ILearnForm>) => {
    if (tab === 4) {
      onSubmit(data as Partial<ILearnForm>);
    } else {
      onTabChange(tab + 1);
    }
  };

  return (
    <div>
      {tab === 0 && (
        <LearnDetailsTab
          formData={formData}
          onInit={onInitForm}
          onBack={gotoPrevTab}
          onNext={gotoNextTab}
          onSubmit={onChange}
          onClose={onClose}
          editable={editable}
        />
      )}
      {tab === 1 && (
        <LearnSyllabusTab
          formData={formData}
          onInit={onInitForm}
          onBack={gotoPrevTab}
          onNext={gotoNextTab}
          onSubmit={onChange}
          onClose={onClose}
          editable={editable}
        />
      )}
      {tab === 2 && (
        <LearnSkillsTab
          formData={formData}
          onInit={onInitForm}
          onBack={gotoPrevTab}
          onNext={gotoNextTab}
          onSubmit={onChange}
          onClose={onClose}
          editable={editable}
        />
      )}
      {tab === 3 && (
        <LearnMediaTab
          formData={formData}
          onInit={onInitForm}
          onBack={gotoPrevTab}
          onNext={gotoNextTab}
          onSubmit={onChange}
          onClose={onClose}
          editable={editable}
        />
      )}
      {tab === 4 && (
        <LearnQuizTab
          formData={formData}
          onInit={onInitForm}
          onBack={gotoPrevTab}
          onNext={gotoNextTab}
          onSubmit={onChange}
          onClose={onClose}
          editable={editable}
        />
      )}
    </div>
  );
};
