import styles from "./additional-report-employee.module.scss";
import { Link } from "react-router-dom";
import { Svg } from "@ui";
import { AccountabilitiesLegend } from "../legend";
import { useEffect, useState } from "react";
import { getAccountabilitiesMembersParams } from "@common/features/accountabilities/model";
import { AccountabilitiesMembersParams } from "@common/types/reports/accountabilities/accountabilities";

interface Props {
  name?: string;
  profileId: number;
}

export const AdditionalReportEmployee = ({ name, profileId }: Props) => {
  const [params, setParams] = useState<AccountabilitiesMembersParams>();

  useEffect(() => {
    getAccountabilitiesMembersParams(profileId, setParams);
  }, []);

  return (
    <div className={styles.info}>
      <div className={styles["info-legend"]}>
        <AccountabilitiesLegend />
      </div>
      {params?.custom_report_url && (
        <a href={params.custom_report_url} rel="noreferrer" target="_blank" className={styles["info-report"]}>
          <Svg modClass={styles["info-icon"]} name="external-link" />
          {`${name && `${name}'s `}Additional Reports`}
        </a>
      )}
    </div>
  );
};
