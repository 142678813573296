import { Button, FormInputPassword, FormInputText, FormWrapper } from "@ui";
import { FieldErrors } from "react-hook-form";
import form from "@styles/modules/form.module.scss";
import styles from "./account-activation.module.scss";
import { defaultValues, AccountActivationFormInterface, accountActivationFormSchema } from "./model";
import { useForm } from "@common/hooks/form";
import { APICallbacks } from "@common/types/forms/common";
import { activateAccount } from "@common/features/user/model";

interface Props extends APICallbacks {
  token: string;
}

export const AccountActivationForm = ({ token, successCallback, failCallback }: Props) => {
  const methods = useForm<AccountActivationFormInterface>({
    schema: accountActivationFormSchema,
    defaultValues
  });
  const handleSuccess = (values: AccountActivationFormInterface) => {
    if (values.passwordConfirm && values.password) {
      activateAccount({
        token: token,
        password: values.password,
        successCallback,
        failCallback
      });
    }
  };
  const handleError = (errors: FieldErrors<AccountActivationFormInterface>) => {
    console.error(errors);
  };

  return (
    <>
      <FormWrapper onSubmit={methods.handleSubmit(handleSuccess, handleError)}>
        <div className={styles["form-wrapper"]}>
          <div className={form.row}>
            <FormInputPassword
              placeholder="Password"
              name="password"
              register={methods.register}
              error={methods.formState.errors["password"]?.message}
            />
          </div>
          <div className={form.row}>
            <FormInputPassword
              name="passwordConfirm"
              register={methods.register}
              placeholder="Password (repeat)"
              error={methods.formState.errors["passwordConfirm"]?.message}
            />
          </div>
        </div>
        <Button title="Submit" className={styles.button} type="submit" />
      </FormWrapper>
    </>
  );
};
