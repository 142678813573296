import { FormattedPeople, People, PeopleSubordinates } from "@common/types/reports/people/people";

export const createFormattedPeopleItem = <T extends PeopleSubordinates | People>(item: T): FormattedPeople => {
  const { last_assessment_session: _, ...itemProps } = item;
  return {
    employees_count: item.subordinates_count || 0,
    ...(item.last_assessment_session?.average_score && {
      average_score: item.last_assessment_session?.average_score
    }),
    ...itemProps
  };
};
