import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import styles from "./header.module.scss";
import { TopLine } from "../top-line";
import { Menu } from "../menu";
import classNames from "classnames";
import { Banner } from "../banner";
import { GlobalStore } from "@common/api/store/global";
import { observer } from "mobx-react-lite";

export const Header = observer(() => {
  const { $commonStore, $userStore } = useContext(GlobalStore);
  const [isShowMenu, setShowMenu] = useState<boolean>(false);

  useEffect(() => {
    return () => {
      const freshWorks = document.getElementById("freshworks-container");
      if (freshWorks && !$userStore.isAuth) {
        freshWorks.remove();
      }
    };
  }, [$userStore.isAuth]);

  const headerClass = classNames(styles.header, isShowMenu && styles["show-menu"]);
  return (
    <header className={headerClass}>
      {$userStore.isAuth && (
        <Helmet>
          <script>
            {`
              window.fwSettings={ 'widget_id':150000001356 };
              !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
            `}
          </script>
          <script
            type="text/javascript"
            src="https://widget.freshworks.com/widgets/150000001356.js"
            async
            defer
          ></script>
        </Helmet>
      )}
      <TopLine changeMenu={() => setShowMenu(prev => !prev)} />

      <div className={styles["menu-line"]}>{$userStore.isAuth ? <Menu /> : null}</div>

      {$commonStore.banner && <Banner banner={$commonStore.banner} />}
    </header>
  );
});
