import React, { useContext, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { EmployeeAnalytics, Analytics, SupervisorAnalytics } from "@common/types/reports/analytics/analytics";
import {
  employeeRosterColumns,
  skillOverviewColumns,
  supervisorTabItems,
  teamRosterColumns
} from "@features/reporting/report-overview/model";
import tabs from "@styles/modules/tabs.module.scss";
import { FirstLevelTable, Titles } from "@ui";
import styles from "../report-overview.module.scss";
import { GlobalStore } from "@common/api/store/global";
import { getSupervisorEmployees } from "@common/features/analytics/model";

interface SupervisorViewProps {
  supervisorId: string;
}

export const SupervisorView: React.FC<SupervisorViewProps> = ({ supervisorId }) => {
  const { $analyticsStore } = useContext(GlobalStore);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const selectedSupervisor = useMemo(
    () => $analyticsStore.supervisors.find(supervisor => +supervisor.id === +supervisorId) as SupervisorAnalytics,
    [$analyticsStore.supervisors, supervisorId]
  );
  const sortByItems = useMemo(() => {
    switch (supervisorTabItems[activeTab].key) {
      case "Supervisors":
        return teamRosterColumns.map((item, index) => ({ value: index, label: item.title || "" }));
      case "Employees":
        return employeeRosterColumns.map((item, index) => ({ value: index, label: item.title || "" }));
      case "Skills":
        return skillOverviewColumns.map((item, index) => ({ value: index, label: item.title || "" }));
    }
    return [];
  }, [activeTab]);

  useEffect(() => {
    if (!$analyticsStore.supervisorEmployees[supervisorId] && !isLoading) {
      setIsLoading(true);
      getSupervisorEmployees(+supervisorId).finally(() => {
        setIsLoading(false);
      });
    }
  }, [supervisorId, supervisorTabItems[activeTab].key, $analyticsStore.teamTypes]);

  return (
    <>
      <Titles level="level_3" modClass={styles.title}>
        {selectedSupervisor.full_name}
      </Titles>
      <p className={styles["supervisor-description"]}>
        This is where a generalized overview of this supervisor’s activity and status would be placed: there would be
        explanatory to give the user insight into the supervisor’s performance
      </p>
      <Tabs
        className={tabs.tabs}
        selectedTabClassName={classNames(tabs["tab-selected"], styles["report-table-wrapper"])}
        onSelect={value => setActiveTab(value)}
      >
        <TabList controls={false} className={classNames(tabs.list, styles["overview-tab-wrapper"])}>
          {supervisorTabItems.map(teamName => (
            <Tab key={teamName.key} className={classNames(tabs.tab, styles["overview-tab-item"])}>
              {teamName.title}
            </Tab>
          ))}
        </TabList>
        {/*TODO: need to uncomment after integration*/}
        {/*<ReportFilter modClass={styles["report-filter"]} options={sortByItems} />*/}
        <Titles level="level_3" modClass={styles["tab-title"]}>
          {supervisorTabItems[activeTab].title} Roster
        </Titles>
        <TabPanel className={tabs.panel}>
          {FirstLevelTable<EmployeeAnalytics>({
            data: $analyticsStore.supervisorEmployees[supervisorId] || [],
            columns: teamRosterColumns,
            modStyles: styles
          })}
        </TabPanel>
        {/*<TabPanel className={tabs.panel}>*/}
        {/*  {FirstLevelTable<EmployeeAnalytics>({*/}
        {/*    data: $analyticsStore.supervisorEmployees,*/}
        {/*    columns: employeeRosterColumns,*/}
        {/*    modStyles: styles,*/}
        {/*  })}*/}
        {/*</TabPanel>*/}
        {/*<TabPanel className={tabs.panel}>*/}
        {/*</TabPanel>*/}
        {/*<TabPanel className={tabs.panel}>*/}
        {/*</TabPanel>*/}
        {/*<TabPanel className={tabs.panel}>*/}
        {/*  {FirstLevelTable<Analytics>({*/}
        {/*    data: SkillOverviewData,*/}
        {/*    columns: skillOverviewColumns,*/}
        {/*    modStyles: styles,*/}
        {/*  })}*/}
        {/*</TabPanel>*/}
      </Tabs>
    </>
  );
};
