import React, { useContext, useEffect, useState } from "react";
import { getRoleSummaries } from "@common/features/roles/model";
import { RoleTree } from "@common/types/roles/roles";
import { FirstLevel } from "./first-level";
import { GlobalStore } from "@common/api/store/global";
import { observer } from "mobx-react-lite";

interface RolesProps {}

export const Roles: React.FC<RolesProps> = observer(() => {
  const { $rolesStore } = useContext(GlobalStore);
  const [roles, setRoles] = useState<RoleTree[]>();
  useEffect(() => {
    getRoleSummaries();
  }, []);

  useEffect(() => {
    if ($rolesStore.roleSummaries) {
      const roles = $rolesStore.roleSummaries;
      const tree: RoleTree[] = [];
      roles.forEach(item => {
        let orgUnit = tree.find(org => org.id === item.unit.id);
        if (!orgUnit) {
          orgUnit = {
            ...item.unit,
            members_count: 0,
            roles: []
          };
          tree.push(orgUnit);
        }

        orgUnit.members_count += item.members_count;
        orgUnit.roles.push(item);
      });

      setRoles(tree);
    }
  }, [$rolesStore.roleSummaries]);
  return !!roles ? <FirstLevel roles={roles} /> : null;
});
