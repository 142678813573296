import React, { useContext, useMemo, useState } from "react";
import classNames from "classnames";
import { GlobalStore } from "@common/api/store/global";
import { getPhrases } from "@common/helpers/localization";
import { SupervisorAnalytics } from "@common/types/reports/analytics/analytics";
import { ReportFilter } from "@features/reporting/report-filter";
import grid from "@styles/modules/grid.module.scss";
import { FirstLevelTable, Titles } from "@ui";
import { supervisorKpiList, supervisorOverviewColumns } from "../model";
import { SupervisorView } from "./supervisor-view";
import styles from "../report-overview.module.scss";

interface SupervisorOverviewProps {
  route: string;
}

export const SupervisorOverview: React.FC<SupervisorOverviewProps> = ({ route }) => {
  const { $analyticsStore } = useContext(GlobalStore);
  const [loc] = useState(getPhrases("reporting_page", "overview"));
  const [data] = useState<SupervisorAnalytics[]>();
  const [sortByItems] = useState(
    supervisorOverviewColumns.map((item, index) => ({
      value: index,
      label: item.title || ""
    }))
  );
  const category = useMemo(
    () => (route.includes("bySupervisor>") ? route.split("bySupervisor>")[1].toLowerCase() : ""),
    [route]
  );

  return (
    <div className={classNames(grid.container, styles["page-container"])}>
      {category ? (
        <SupervisorView supervisorId={category} />
      ) : (
        <>
          <Titles level="level_3" modClass={styles.title}>
            {loc.bySupervisorTitle} {loc.reporting}
          </Titles>
          <p className={styles.description}>{loc.description}</p>
          {/* TODO: need to uncomment after integration
            <div className={styles["kpi-box-wrapper"]}>
              {supervisorKpiList.map((item, index) => (
                <React.Fragment key={item.title}>
                  {index > 0 && <div className={styles["kpi-box-divider"]} />}
                  <div className={styles["kpi-box"]}>
                    <Titles level="level_3"
                            modClass={classNames(styles["kpi-box-title"])}>{item.value}{item.affix}</Titles>
                    <p className={classNames(styles["kpi-box-description"], styles.team)}>{item.title}</p>
                  </div>
                </React.Fragment>
              ))}
            </div>
            */}
          {/*TODO: need to uncomment after integration*/}
          {/*<ReportFilter modClass={styles["report-filter"]} options={sortByItems} />*/}
          <div className={styles["report-table-wrapper"]}>
            {FirstLevelTable<SupervisorAnalytics>({
              data: $analyticsStore.supervisors,
              columns: supervisorOverviewColumns,
              modStyles: styles
            })}
          </div>
        </>
      )}
    </div>
  );
};
