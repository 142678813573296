import styles from "./scope.module.scss";
import modalStyles from "@styles/modules/complex-modal.module.scss";
import grid from "@styles/modules/grid.module.scss";
import classNames from "classnames";
import { HTMLViewer, Titles } from "@ui";
import { useEffect, useState } from "react";
import { getResponsibilities } from "@common/features/responsibilities/model";
import { Responsibility } from "@common/types/responsibilities/responsibilities";

interface Props {
  jobPurpose?: string;
  roleId?: number;
}

export const ScopeTab = ({ jobPurpose, roleId }: Props) => {
  const [responsibilities, setResponsibilities] = useState<Responsibility[]>();

  useEffect(() => {
    if (roleId) {
      getResponsibilities(Number(roleId), setResponsibilities);
    }
  }, [roleId]);

  const noInfo = "No information";

  return (
    <div className={modalStyles.row}>
      <div className={grid.list}>
        <div className={classNames(grid.item, grid["item-8x"])}>
          <Titles level="level_3">Job Purpose</Titles>
          {jobPurpose ? <HTMLViewer modClass={styles["scope-text"]} html={jobPurpose} /> : noInfo}
        </div>
        <div className={classNames(grid.item, grid["item-4x"])}>
          <Titles level="level_3">Responsibilities</Titles>
          {!!responsibilities?.length ? (
            <HTMLViewer
              html={responsibilities.reduce((previous, current) => {
                return `${previous}
                <div key={responsibility-${current.id}}>
                  ${!!current.title && `<h3>${current.title}</h3>`}
                  ${!!current.description && `<p>${current.description}</p>`}
                </div>
              `;
              }, "")}
            />
          ) : (
            noInfo
          )}
        </div>
      </div>
    </div>
  );
};
