import { OrgUnitsCommon } from "@common/types/reports/org-units/org-units";
import { TableColumn } from "@common/types/table";
import classNames from "classnames";
import { DataType, SortDirection } from "ka-table/enums";
import grid from "@styles/modules/grid.module.scss";
import { getEmployeesCount, getRolesCount } from "./helpers";
import { OrgUnitActionInterface } from "./types";
import { OrgUnitEditAction } from "./edit-action";

interface OrgUnitsCommonColumns extends OrgUnitActionInterface, OrgUnitsCommon {}

export const orgUnitsTableModel: TableColumn<OrgUnitsCommonColumns>[] = [
  {
    key: "name",
    title: "Name",
    dataType: DataType.String,
    sortDirection: SortDirection.Descend,
    modClass: classNames(grid.item, grid["item-4x"]),
    expandCell: true,
    component: OrgUnitEditAction
  },
  {
    key: "roles_count",
    title: "",
    dataType: DataType.Number,
    modClass: classNames(grid.item, grid["item-2x"]),
    decorator: data => getRolesCount(data)
  },
  {
    key: "members_count",
    title: "",
    dataType: DataType.Number,
    modClass: classNames(grid.item, grid["item-2x"]),
    decorator: data => getEmployeesCount(data)
  },
  {
    key: "id",
    title: "",
    dataType: DataType.Number,
    modClass: classNames(grid.item, grid["item-2x"]),
    decorator: () => " "
  },
  {
    key: "action",
    title: "",
    dataType: DataType.String,
    modClass: classNames(grid.item, grid["item-1x"]),
    component: OrgUnitEditAction
  }
];
