export const getDurationHoursOrMinutes = (duration: number): string => {
  return duration < 60 ? `${duration}m` : `${Math.round(duration / 60)}h`;
};

export const getDurationHoursAndMinutes = (duration: number, isShort?: boolean): string => {
  const minutes = duration % 60;
  const hours = Math.floor(duration / 60);

  const padTo2Digits = (num: number) => {
    return num.toString().padStart(2, "0");
  };
  const minuteString = minutes
    ? `${padTo2Digits(minutes)}${isShort ? "" : " "}${isShort ? "m" : minutes === 1 ? "minute" : "minutes"}`
    : "";
  return `${
    hours > 0 ? `${padTo2Digits(hours)}${isShort ? "" : " "}${isShort ? "h" : hours === 1 ? "hour" : "hours"} ` : ""
  }${minuteString}`;
};
