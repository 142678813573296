import { $assessmentsStore } from "@common/api/store/assessments";
import { getPhrases } from "@common/helpers/localization";
import { AssessmentForm, AssessmentFormCallback, AssessmentFormResult } from "@common/types/forms/assessments-employee";
import { AssessmentFormCloseModal, QuestionsList } from "@features/assessments";
import { AlertModal, CompleteState, ComplexModal, ComplexModalButtons, ShortModal, ProgressiveModalWrapper } from "@ui";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import modal from "@styles/modules/complex-modal.module.scss";

interface Props {
  assessmentQuestions: AssessmentForm[];
  isOpen: boolean;
  closeModal: () => void;
  name: string;
  role?: string;
  onSend: (props: AssessmentFormCallback) => void;
}

export const EmployeeAssessmentFormModal = observer(
  ({ assessmentQuestions, isOpen, closeModal, name, role, onSend }: Props) => {
    const loc = getPhrases("forms", "assessment");

    const [cancelModalIsOpen, setCancelModalIsOpen] = useState<boolean>(false);

    // TODO: optimize and decompoze the code
    const [sendFailModalIsOpen, setSendFailModalIsOpen] = useState<boolean>(false);
    const [sendServerFailModalIsOpen, setSendServerFailModalIsOpen] = useState<boolean>(false);
    const [sendSuccessModalIsOpen, setSendSuccessModalIsOpen] = useState<boolean>(false);

    const [currentQuestionPage, setCurrentQuestionPage] = useState<number>(0);

    const categoriesQuantity = assessmentQuestions?.length ?? 1;

    // TODO: delete percent calc after moving results to global state
    const completePercent = $assessmentsStore.getPercent();

    useEffect(() => {
      // TODO: Move results to global state
      if (assessmentQuestions && !$assessmentsStore.results) {
        const defaultValues: AssessmentFormResult = assessmentQuestions.reduce(
          (prev, category) => {
            $assessmentsStore.setAssessmentCategorySetter(category.id, null);
            return category.skills.reduce((prevSkills, skill) => {
              return {
                results: { ...prevSkills.results, ...{ [skill.id]: null } }
              };
            }, prev);
          },
          { results: {} }
        );

        $assessmentsStore.setResults(defaultValues);
        $assessmentsStore.setCurrentAssessmentCategory(assessmentQuestions[0].id);
      }
    }, [assessmentQuestions]);

    if (assessmentQuestions) {
      const checkCategoryAnswers = (successCallback: (results: AssessmentFormResult) => void) => {
        const results = $assessmentsStore.getResults();
        const questions = assessmentQuestions[currentQuestionPage].skills;
        const resultsValues = results?.results;
        if (resultsValues) {
          let isValid = true;
          questions.forEach(item => {
            if (!resultsValues[item.id]) {
              isValid = false;
            }
          });
          if (isValid) {
            successCallback(results);
          } else {
            setSendFailModalIsOpen(true);
          }
        } else {
          setSendServerFailModalIsOpen(true);
        }
      };

      const onPageChange = (index: number) => {
        $assessmentsStore.setCurrentAssessmentCategory(assessmentQuestions[index].id);
        return index;
      };

      const goToNextPage = () => {
        checkCategoryAnswers(() => {
          setCurrentQuestionPage(prev => onPageChange(prev + 1));
        });
      };
      const goToPrevPage = () => {
        setCurrentQuestionPage(prev => onPageChange(prev - 1));
      };
      const onClose = () => {
        setCancelModalIsOpen(true);
      };

      const onCancelModalSuccess = () => {
        $assessmentsStore.reset();
        setCancelModalIsOpen(false);
        closeModal();
      };

      const sendResults = () => {
        checkCategoryAnswers(results => {
          const failCallback = () => {
            setSendServerFailModalIsOpen(true);
          };
          const successCallback = () => {
            setSendSuccessModalIsOpen(true);
          };
          onSend({ results, failCallback, successCallback });
        });
      };

      const buttons: ComplexModalButtons = {
        ...(currentQuestionPage > 0 && {
          leftSide: {
            title: loc.buttons_prev,
            theme: "gray",
            onClick: goToPrevPage
          }
        }),
        rightSide: [
          {
            title: loc.buttons_cancel,
            appearance: "bordered",
            theme: "gray",
            onClick: onClose
          },
          // @ts-ignore
          ...(currentQuestionPage < categoriesQuantity - 1
            ? [
                {
                  title: loc.buttons_next,
                  onClick: goToNextPage
                }
              ]
            : [
                {
                  title: loc.buttons_submit,
                  type: "button",
                  theme: "red",
                  onClick: () => {
                    sendResults();
                  }
                }
              ])
        ]
      };

      return (
        <>
          <ComplexModal
            isOpen={isOpen}
            closeModal={onClose}
            headerTitle={`${name} /`}
            secondaryTitle={role}
            headerSecondRow={
              <CompleteState category={assessmentQuestions[currentQuestionPage].name} percent={completePercent} />
            }
            buttons={buttons}
          >
            <div className={modal.row}>
              <ProgressiveModalWrapper progress={completePercent}>
                <QuestionsList data={assessmentQuestions[currentQuestionPage].skills} />
              </ProgressiveModalWrapper>
            </div>
          </ComplexModal>
          <ShortModal isOpen={cancelModalIsOpen} closeModal={() => setCancelModalIsOpen}>
            <AssessmentFormCloseModal onCancel={() => setCancelModalIsOpen(false)} onSuccess={onCancelModalSuccess} />
          </ShortModal>
          <AlertModal
            cancelButtonText="Close"
            message="Please rate all skills before continuing"
            title="Alert"
            type="fail"
            closeModal={() => setSendFailModalIsOpen(false)}
            isOpen={sendFailModalIsOpen}
          />
          <AlertModal
            cancelButtonText="Close"
            message="Your answers were not saved properly for a technical reason. Please try again later or contact our support team."
            title="Alert"
            type="fail"
            closeModal={() => setSendServerFailModalIsOpen(false)}
            isOpen={sendServerFailModalIsOpen}
          />
          <AlertModal
            cancelButtonText="Close"
            message="Thank you for participating!"
            title="Results sent successfully!"
            type="success"
            closeModal={() => {
              setSendSuccessModalIsOpen(false);
              closeModal();
              window.location.reload();
            }}
            isOpen={sendSuccessModalIsOpen}
          />
        </>
      );
    } else {
      return null;
    }
  }
);
