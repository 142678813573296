import React, { ChangeEvent, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import form from '@styles/modules/form.module.scss';
import classNames from 'classnames';
import { InputInterface } from '@common/types/form';
import { Checkbox } from '../checkbox';
import usePopup from '@common/hooks/outside';

// TODO: add type and any input properties
interface Props extends InputInterface {
  label?: string | ReactElement;
  labelClass?: string;
  list: any[];
  type?: string;
  group?: boolean;
  multiSelectable?: boolean;
  groupSelectable?: boolean;
  getItemLabel?: (item: any) => string | ReactElement;
  getItemValue?: (item: any) => any;
  dropdownClass?: string;
  dropdownItemClass?: string;
  value?: any;
  loadableMore?: boolean;
  onChange?: (value: any) => void;
  onChangeBulk?: (value: any[]) => void;
  onLoadMore?: () => void;
}

interface IOption {
  label: string;
  value: any;
  children?: IOption[];
}

export function FormInputAutocomplete({
  list,
  name,
  className,
  label,
  labelClass,
  type = 'text',
  register,
  error,
  theme = 'gray',
  group,
  groupSelectable,
  multiSelectable,
  getItemLabel,
  getItemValue,
  dropdownClass,
  dropdownItemClass,
  value,
  loadableMore,
  onChange,
  onChangeBulk,
  onLoadMore,
  ...props
}: Props) {
  const ref = useRef<HTMLDivElement>(null);
  const { isOpen, openMenu, closeMenu } = usePopup(ref);

  const [text, setText] = useState<string>('');
  const [options, setOptions] = useState<IOption[]>([]);
  const [suggestions, setSuggestions] = useState<IOption[]>([]);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  const findOption = useCallback((options: IOption[], value): IOption | undefined => {
    for (const option of options) {
      if (option.value === value) {
        return option;
      }
      const subSearch = findOption(option.children || [], value);
      if (subSearch) {
        return subSearch;
      }
    }
  }, []);

  // useEffect(() => {
  //   if (!value) {
  //     setText('');
  //   } else {
  //     const option = findOption(options, value);
  //     if (option) {
  //       setText(option.label);
  //     }
  //   }
  // }, [value, options]);

  const formatOptions = useCallback((options: any[]): IOption[] => {
    return options.map((item) => ({
      label: getItemLabel ? getItemLabel(item) : item,
      value: getItemValue ? getItemValue(item) : item,
      children: item.children ? formatOptions(item.children) : undefined,
    }));
  }, []);

  useEffect(() => {
    setOptions(formatOptions(list));
  }, [list, formatOptions]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedItems([]);
    } else {
      const filteredOptions = getFilteredOptions(options, text.toLowerCase());
      setSuggestions(filteredOptions);
    }
  }, [isOpen, text, options]);

  useEffect(() => {
    if (!isOpen && onChangeBulk) {
      onChangeBulk(selectedItems);
    }
  }, [isOpen, onChangeBulk, selectedItems]);

  const inputClass = classNames(
    form.text,
    form[`text-${theme}`],
    form.input,
    suggestions.length && form['select-control'],
    className,
    error && form['input-error'],
  );

  const onClickOption = useCallback((e: MouseEvent, option: IOption) => {
    if (group && !groupSelectable && option.children) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    if (multiSelectable) {
      setSelectedItems((items) => {
        if (items.includes(option.value)) {
          return items.filter((item) => item !== option.value);
        }
        return [...items, option.value];
      });
    } else {
      closeMenu();
      onChange && onChange(option.value);
    }
  }, [closeMenu, group, groupSelectable, multiSelectable, onChange]);

  const getGroupOptions = useCallback((key: string, option: IOption, level = 0) => {
    const selectable = !group || groupSelectable || option.children === undefined;
    return (
      <div key={key}>
        <div
          className={classNames(
            'pr-2 flex items-center',
            option.children && 'font-semibold',
            selectable && 'hover:text-white hover:bg-blue cursor-pointer',
            dropdownItemClass,
          )} style={{ paddingLeft: `${0.5 + level}rem` }} onClick={(e) => onClickOption(e as any, option)}
        >
          {(multiSelectable && option.children === undefined) && (
            <Checkbox
              className="mr-2 my-1" readonly size="sm" theme="success" value={selectedItems.includes(option.value)}
            />
          )}
          <span className="mt-1">{option.label}</span>
        </div>
        {(option.children || []).map((child, index) => (
          getGroupOptions(`${key}-${index}`, child, level + 1)
        ))}
      </div>
    );
  }, [dropdownItemClass, group, groupSelectable, multiSelectable, selectedItems, onClickOption]);

  const getFilteredOptions = useCallback((options: IOption[], search: string): IOption[] => {
    const result: IOption[] = [];
    options.forEach((option) => {
      const isMatch = option.label.toLowerCase().includes(search);
      const children = option.children ? getFilteredOptions(option.children, search) : undefined;
      if (isMatch || children?.length) {
        result.push({
          ...option,
          children,
        });
      }
    });
    return result;
  }, []);

  const onChangeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setText(text);
    if (!text) {
      return options;
    }
    const filteredOptions = getFilteredOptions(options, text.toLowerCase());
    setSuggestions(filteredOptions);
  }, [getFilteredOptions, options]);

  const onFocus = useCallback(() => {
    openMenu();
  }, [suggestions, openMenu]);

  return (
    <div ref={ref} className={classNames('relative', form.field, form.select)} data-error={error}>
      {label && (
        <label
          className={classNames(
            'text-xs',
            error ? 'text-danger' : 'text-gray',
            labelClass,
          )}
        >
          {label}
        </label>
      )}
      <input
        type={type}
        {...props}
        {...(register && register(name))}
        className={inputClass}
        value={text}
        onChange={onChangeHandler}
        onFocus={onFocus}
        autoComplete="off"
      />
      {isOpen && (
        <div className={classNames(form['select-list'], dropdownClass)}>
          {suggestions.map((suggestion, index) => (
            getGroupOptions(`group-${index}`, suggestion)
          ))}
          {loadableMore && (
            <div
              className={classNames(
                'mt-2 pt-1 px-2 flex items-center font-semibold hover:text-white hover:bg-blue cursor-pointer',
                dropdownItemClass,
              )}
              onClick={onLoadMore}
            >
              Load more...
            </div>
          )}
        </div>
      )}
    </div>
  );
}
