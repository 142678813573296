import moment from "moment";
import classNames from "classnames";
import { NotificationModel } from "@common/types/reports/activity-logs/activity-logs";
import { TableColumn } from "@common/types/table";
import { DataType, SortDirection } from "ka-table/enums";
import grid from "@styles/modules/grid.module.scss";
import { ClassMapper } from "@features/activity-logs/ui/class-mapper";
import { UserStatusCell } from "@ui";
import { StatusTooltipHeader } from "@features/people/status-tooltip-header";

export const columns: TableColumn<NotificationModel>[] = [
  {
    key: "",
    title: "#",
    dataType: DataType.Number,
    sortDirection: SortDirection.Ascend,
    modClass: classNames(grid.item, "w-15 text-sm")
  },
  {
    key: "typeAbbreviation",
    title: "Notification Type",
    dataType: DataType.String,
    modClass: classNames(grid.item, "flex-[0_0_10%] max-w-[10%]"),
    component: ClassMapper("bg-info whitespace-nowrap")
  },
  {
    key: "name",
    title: "Name",
    dataType: DataType.String,
    modClass: classNames(grid.item, grid["item-2x"], "flex-[0_0_10%] max-w-[10%] text-xs whitespace-pre")
  },
  {
    key: "status_name",
    title: "Account Status",
    dataType: DataType.String,
    modClass: classNames(grid.item, grid["item-1x"], "flex-[0_0_10%] max-w-[10%] text-xs"),
    component: UserStatusCell,
    headCellComponent: StatusTooltipHeader
  },
  {
    key: "role_name",
    title: "Role",
    dataType: DataType.String,
    modClass: classNames(grid.item, "flex-[0_0_15%] max-w-[15%] text-xs")
  },
  {
    key: "unit_name",
    title: "Org Unit",
    dataType: DataType.String,
    modClass: classNames(grid.item, "flex-[0_0_15%] max-w-[15%] text-xs")
  },
  {
    key: "used_at",
    title: "Link Used?",
    dataType: DataType.String,
    modClass: classNames(grid.item, "flex-[0_0_4%] max-w-[4%]"),
    component: ClassMapper("bg-secondary")
  },
  {
    key: "created_at",
    title: "Date",
    dataType: DataType.String,
    modClass: classNames(grid.item, grid["item-2x"], "text-xs"),
    decorator: ({ value }) => (value ? moment(value).format("MMM DD yyyy") : "")
  }
];
