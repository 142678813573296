import { TargetsType } from "@common/types/reports/skills/common";

export const checkTargetsType = (value: number, type?: TargetsType): boolean => {
  switch (type) {
    case "below":
      return value <= 0;
    case "above":
      return value > 0;
    default:
      return true;
  }
};
