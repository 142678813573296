import { FirstLevelTable } from "@ui";
import { FC, useCallback, useEffect, useState } from "react";
import { SortDirection } from "ka-table/enums";
import { columns } from "./model";
import {
  ActiveStatus,
  AssessmentModel,
  DurationCategories,
  ExtendedMember
} from "@common/types/reports/activity-logs/activity-logs";
import { getAssessments } from "@common/features/activity-logs/model";
import { MetaData } from "@common/api/types/response/common";
import { ITableProps } from "ka-table";
import { SortFunc } from "ka-table/types";

interface SkillsAssessmentTableProps {
  duration: DurationCategories;
  display: ActiveStatus;
}

export const SkillsAssessmentTable: FC<SkillsAssessmentTableProps> = ({ duration, display }) => {
  const [data, setData] = useState<AssessmentModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [metaData, setMetadata] = useState<MetaData>({
    current_page: 1,
    per_page: 25,
    total: 1
  } as MetaData);

  useEffect(() => {
    setIsLoading(true);
    const createdAt = Date.now() - (duration || 0) * 1000 * 60 * 60 * 24;
    getAssessments(
      {
        "page": metaData?.current_page ? metaData?.current_page : 1,
        "per-page": metaData?.per_page || 25,
        "filter[created_at]": new Date(createdAt).toISOString().split("T")[0]
      },
      "skills-assessments"
    )
      .then(({ data, meta }) => {
        setData(data);
        setMetadata(meta);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [metaData?.current_page, metaData?.per_page, duration, display]);

  const handleChangeProps = (props: ITableProps) => {
    if (metaData && !isLoading) {
      setMetadata({
        ...metaData,
        current_page: (props?.paging?.pageIndex || 0) + 1,
        per_page: props?.paging?.pageSize || 10
      });
    }
  };

  const sortingFunc = useCallback<SortFunc>(({ column }) => {
    if (column.key === "self" || column.key === "manager_completed_at") {
      return (a: ExtendedMember, b: ExtendedMember) => {
        if (a == null || b == null) return -1;

        const aValue = a.completed_at ? new Date(a.completed_at).getTime() : 0;
        const bValue = b.completed_at ? new Date(b.completed_at).getTime() : 0;
        return aValue === bValue
          ? 0
          : aValue < bValue
          ? column.sortDirection === SortDirection.Ascend
            ? -1
            : 1
          : column.sortDirection === SortDirection.Ascend
          ? 1
          : -1;
      };
    }
  }, []);

  return FirstLevelTable<AssessmentModel>({
    data,
    columns,
    pagination: true,
    sort: sortingFunc,
    onChangeProps: handleChangeProps,
    pageIndex: metaData?.current_page ? metaData?.current_page - 1 : 0,
    pageSize: metaData?.per_page,
    pagesCount: metaData ? Math.ceil(metaData.total / metaData.per_page) : 1
  });
};
