import { CategoryLine } from "@features/settings";
import { useState } from "react";
import { OrgUnits } from "../org-units";
import { OrgUnitChangeModal } from "@features/settings/blocks/org-units";

export const OrgUnitsCategoryLine = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const button = {
    title: "Add Org Unit",
    onClick: () => setIsOpen(prev => !prev)
  };
  return (
    <>
      <CategoryLine title="Organizational Units" content={<OrgUnits />} button={button} />
      <OrgUnitChangeModal closeModal={() => setIsOpen(false)} isOpen={isOpen} />
    </>
  );
};
