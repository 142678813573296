import { GlobalStore } from "@common/api/store/global";
import { getResponsibilities } from "@common/features/responsibilities/model";
import { ProfileInterface } from "@common/types/reports/profile/profile";
import { Responsibility } from "@common/types/responsibilities/responsibilities";
import { HTMLViewer, Titles } from "@ui";
import { useContext, useEffect, useState } from "react";
import informationStyles from "./../information.module.scss";

export const DetailsRole = () => {
  const { $profileStore } = useContext(GlobalStore);
  const profile: ProfileInterface | null = $profileStore.getProfile();
  const role = profile?.current_role;

  const [responsibilities, setResponsibilities] = useState<Responsibility[]>();

  useEffect(() => {
    if (role?.id) {
      getResponsibilities(Number(role.id), setResponsibilities);
    }
  }, [role?.id]);
  const noInfo = "No information";
  return (
    <>
      <div className={informationStyles.row}>
        <Titles level="level_3" modClass={informationStyles.title}>
          Job Purpose
        </Titles>
        {!!role?.job_purpose ? <HTMLViewer html={role.job_purpose} /> : noInfo}
      </div>
      <div className={informationStyles.row}>
        <Titles level="level_3" modClass={informationStyles.title}>
          Responsibilities
        </Titles>
        {!!responsibilities?.length ? (
          <HTMLViewer
            html={responsibilities.reduce((previous, current) => {
              return `${previous}
                <div key={responsibility-${current.id}}>
                  ${!!current.title && `<h3>${current.title}</h3>`}
                  ${!!current.description && `<p>${current.description}</p>`}
                </div>
              `;
            }, "")}
          />
        ) : (
          noInfo
        )}
      </div>
    </>
  );
};
