import { useState } from "react";
import classNames from "classnames";
import grid from "@styles/modules/grid.module.scss";
import { getPhrases } from "@common/helpers/localization";
import { Svg, Titles } from "@ui";
import styles from "./welcome.module.scss";

export const Welcome: React.FC = () => {
  const [loc] = useState(getPhrases("reporting_page", "welcome"));

  return (
    <div className={classNames(grid.container, styles["page-container"])}>
      <Titles level="level_3" modClass={styles.title}>
        {loc.title} {loc.reporting}
      </Titles>
      <div className={styles["report-group-wrapper"]}>
        <Titles level="level_3" modClass={styles["report-title"]}>
          {loc.recentReports}
        </Titles>
        {[
          "By team / Cloud Support team",
          "By team / Linux OS Specialized Support team",
          "By supervisor / MJocelyn Finlay"
        ].map(item => (
          <div key={item} className={styles["report-block"]}>
            {item}
            <div className={styles["action-wrapper"]}>
              <Svg name="envelope" modClass={styles.action} />
              <Svg name="printer" modClass={styles.action} />
              <Svg name="upload" modClass={styles.action} />
            </div>
          </div>
        ))}
      </div>
      <div className={styles["report-group-wrapper"]}>
        <Titles level="level_3" modClass={styles["report-title"]}>
          {loc.favoriteReports}
        </Titles>
        {["By skills / Skills overview", "By team / Education Services team"].map(item => (
          <div key={item} className={styles["report-block"]}>
            {item}
            <div>
              <Svg name="envelope" modClass={styles.action} />
              <Svg name="printer" modClass={styles.action} />
              <Svg name="upload" modClass={styles.action} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
