import { AssessmentModel, LogStatus } from "@common/types/reports/activity-logs/activity-logs";
import { TableColumn } from "@common/types/table";
import classNames from "classnames";
import { DataType, SortDirection } from "ka-table/enums";
import grid from "@styles/modules/grid.module.scss";
import { Status } from "../ui";
import { UserStatusCell } from "@ui";
import { StatusTooltipHeader } from "@features/people/status-tooltip-header";

export const columns: TableColumn<AssessmentModel>[] = [
  {
    key: "",
    title: "#",
    dataType: DataType.Number,
    sortDirection: SortDirection.Ascend,
    modClass: classNames(grid.item, "min-w-15 text-sm")
  },
  {
    key: "full_name",
    title: "Name",
    dataType: DataType.String,
    modClass: classNames(grid.item, grid["item-2x"], "text-xs whitespace-pre")
  },
  {
    key: "status_name",
    title: "Account Status",
    dataType: DataType.String,
    modClass: classNames(grid.item, grid["item-1x"]),
    component: UserStatusCell,
    headCellComponent: StatusTooltipHeader
  },
  {
    key: "role_name",
    title: "Role",
    dataType: DataType.String,
    modClass: classNames(grid.item, grid["item-2x"], "text-xs")
  },
  {
    key: "unit_name",
    title: "Org Unit",
    dataType: DataType.String,
    modClass: classNames(grid.item, "flex-[0_0_10%] max-w-[10%] text-xs")
  },
  {
    key: "self",
    field: "self",
    title: "Self Tested",
    dataType: DataType.Object,
    modClass: classNames(grid.item, "flex-[0_0_10%] max-w-[10%]"),
    component: Status,
    decorator: () => LogStatus.pending
  },
  {
    key: "manager",
    title: "Manager Name",
    dataType: DataType.Object,
    modClass: classNames(grid.item, grid["item-2x"], "text-xs whitespace-pre"),
    decorator: ({ value }) => (value ? value.full_name : "")
  },
  {
    key: "manager_completed_at",
    field: "manager",
    title: "Manager Tested",
    dataType: DataType.Object,
    modClass: classNames(grid.item, "flex-[0_0_10%] max-w-[10%]"),
    component: Status,
    decorator: () => LogStatus.pending
  }
];
