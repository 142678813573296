import tabs from "@styles/modules/tabs.module.scss";
import styles from "./user-info-panel.module.scss";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { SendingManager } from "@common/types/managment/edit-person";
import classNames from "classnames";

interface Props extends SendingManager {
  activeTabsCount: number;
  index: number;
  setIndex: (index: number) => void;
}

export const UserInfoPanel = ({ activeTabsCount, index, setIndex, isSending }: Props) => {
  const tabsList: string[] = ["01 Details", "02 Role", "03 Skills", "04 Scope", "05 Accountabilities"];

  const changeAccess = (index: number): boolean => {
    return index + 1 > activeTabsCount;
  };

  return (
    <div className={styles.block}>
      <Tabs selectedIndex={index} className={tabs.tabs} selectedTabClassName={tabs["tab-selected"]} onSelect={setIndex}>
        <TabList className={tabs.list}>
          {tabsList.map((item, index) => {
            return (
              <Tab
                key={`tab-${index}`}
                className={classNames(tabs.tab, (isSending || changeAccess(index)) && tabs["tab-disabled"])}
                {...((isSending || changeAccess(index)) && { disabled: true })}
              >
                {item}
              </Tab>
            );
          })}
        </TabList>
        {tabsList.map((item, index) => (
          <TabPanel key={`tab-panel-${index}`} className={tabs["tab-hidden"]} />
        ))}
      </Tabs>
    </div>
  );
};
