import { assessmentsStaffCompletedColumns } from "./model";
import { AssessmentsStaffCompleted } from "@common/types/reports/assessments/assessments";
import { FirstLevelTable } from "@ui";
import { SortDirection } from "ka-table/enums";
import { Column } from "ka-table/models";
import { getExtendData } from "@common/helpers/data-helpers";
import { getAssessmentType } from "../helpers";
import { PropsWithChildren } from "react";
import { ICellTextProps } from "ka-table/props";

interface Props {
  data: AssessmentsStaffCompleted[];
}

export const StaffCompleted = ({ data }: Props) => {
  return FirstLevelTable<AssessmentsStaffCompleted>({
    pageSize: 25,
    data,
    columns: assessmentsStaffCompletedColumns,
    pagination: true,
    sorting: true,
    sort: ({ column }) => {
      if (column.key === "member") {
        return (a, b) => {
          const nameA = (a?.first_name + a?.last_name || "") as string;
          const nameB = (b?.first_name + b?.last_name || "") as string;
          const compare = nameA === "" ? -1 : nameB === "" ? 1 : nameA.localeCompare(nameB);
          return column.sortDirection === SortDirection.Ascend ? compare : ((compare * -1) as any);
        };
      }
    },
    search: ({
      searchText,
      rowData,
      column
    }: {
      searchText: string;
      rowData: AssessmentsStaffCompleted;
      column: Column;
    }) => {
      const searchTextLowCase = searchText.toLowerCase();
      if (column.key === "created_at") {
        return (
          getExtendData(rowData.created_at * 1000)
            .toLowerCase()
            .search(searchTextLowCase) > -1
        );
      }
      if (column.key === "assigned_to") {
        return rowData.assigned_to.toLowerCase().search(searchTextLowCase) > -1;
      }
      if (column.key === "type" && rowData.module) {
        return (
          getAssessmentType({ rowData } as unknown as PropsWithChildren<ICellTextProps>)
            .toLowerCase()
            .search?.(searchTextLowCase) > -1
        );
      }
      if (column.key === "member") {
        return (
          (rowData.member?.first_name + " " + rowData.member?.last_name).toLowerCase().search?.(searchTextLowCase) > -1
        );
      }
      return false;
    }
  });
};
