import { useState, useEffect, useCallback, MutableRefObject, MouseEventHandler } from "react";

interface IUsePopupReturnProps {
  isOpen: boolean;
  closeMenu: () => void;
  openMenu: () => void;
  toggleMenu: MouseEventHandler;
}

const usePopup = (ref: MutableRefObject<any>): IUsePopupReturnProps => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutSideOfMenu = useCallback(
    e => {
      const path = e.path || (e.composedPath && e.composedPath());
      if (path && !path.includes(ref.current) && isOpen) {
        setIsOpen(false);
      }
      // if (ref.current && !ref.current.contains(e.target)) {
      //   alert("You clicked outside of me!");
      // }
    },
    [ref, isOpen]
  );

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("click", handleClickOutSideOfMenu);
    } else {
      window.removeEventListener("click", handleClickOutSideOfMenu);
    }
    return () => window.removeEventListener("click", handleClickOutSideOfMenu);
  }, [handleClickOutSideOfMenu, isOpen]);

  const openMenu = () => setIsOpen(true);
  const closeMenu = () => setIsOpen(false);

  const toggleMenu = useCallback(
    (e: any) => {
      if (e) {
        e.preventDefault();
      }
      setIsOpen(!isOpen);
    },
    [isOpen]
  );

  return {
    isOpen,
    closeMenu,
    openMenu,
    toggleMenu
  };
};

export default usePopup;
