import { AssessmentsManager } from "@common/types/reports/assessments/assessments";
import grid from "@styles/modules/grid.module.scss";
import { Titles } from "@ui";
import stylesInner from "./assessments-page-manager.module.scss";

import { GlobalStore } from "@common/api/store/global";
import { Roles } from "@common/constants";
import { getAssessments, getAssessmentsSummary } from "@common/features/assessments/model";
import { AssessmentsSummary } from "@common/types/reports/assessments/summary";
import { MyCompleted, MyUncompleted, StaffCompleted, StaffUncompleted } from "@features/assessments";
import classNames from "classnames";
import { useContext, useEffect, useState } from "react";
import { FlatButton } from "src/ui/flat-button";

type AssessmentStatus = "MyUncompleted" | "MyCompleted" | "StaffUncompleted" | "StaffCompleted";

interface AssessmentsButtonProps {
  key: AssessmentStatus;
  title: string;
  quantity: number;
  onClick: () => void;
}

interface ButtonsGroup {
  title: string;
  buttons: AssessmentsButtonProps[];
}

export const AssessmentsPageManager: React.FC = () => {
  const [type, setType] = useState<AssessmentStatus>("MyUncompleted");
  const [data, setData] = useState<AssessmentsManager>();
  const { $profileStore } = useContext(GlobalStore);

  const role = $profileStore.getCurrentRoleId();
  const [summary, setSummary] = useState<AssessmentsSummary[]>([]);

  useEffect(() => {
    getAssessmentsSummary((data: any) => {
      setSummary(data);
    });
  }, []);

  useEffect(() => {
    getAssessments((data: AssessmentsManager) => {
      setData({
        my: {
          completed: data.my.completed.map((i, index) => ({ ...i, index })),
          uncompleted: data.my.uncompleted.map((i, index) => ({ ...i, index }))
        },
        staff: data.staff
      });
    });
  }, []);

  if (!data) {
    return null;
  }

  const buttons: ButtonsGroup[] = [
    {
      title: "My Tasks",
      buttons: [
        {
          key: "MyUncompleted",
          title: "Pending",
          quantity: data.my.uncompleted.length,
          onClick: () => setType("MyUncompleted")
        },
        {
          key: "MyCompleted",
          title: "Completed",
          quantity: data.my.completed.length,
          onClick: () => setType("MyCompleted")
        }
      ]
    }
  ];

  if (role !== Roles.employee) {
    buttons.push({
      title: "Staff Tasks",
      buttons: [
        {
          key: "StaffUncompleted",
          title: "Pending",
          quantity: data.staff.uncompleted.length,
          onClick: () => setType("StaffUncompleted")
        },
        {
          key: "StaffCompleted",
          title: "Completed",
          quantity: data.staff.completed.length,
          onClick: () => setType("StaffCompleted")
        }
      ]
    });
  }
  const getComponent = () => {
    switch (type) {
      case "MyUncompleted":
        return !data.my.uncompleted.length ? (
          <EmptyCom />
        ) : (
          <MyUncompleted
            data={data.my.uncompleted.map(item => ({
              ...item,
              action: ""
            }))}
          />
        );
      case "MyCompleted":
        return !data.my.completed.length ? <EmptyCom /> : <MyCompleted data={data.my.completed} />;
      case "StaffUncompleted":
        return !data.staff.uncompleted.length ? <EmptyCom /> : <StaffUncompleted data={data.staff.uncompleted} />;
      case "StaffCompleted":
        return !data.staff.completed.length ? <EmptyCom /> : <StaffCompleted data={data.staff.completed} />;
      default:
        return null;
    }
  };

  const EmptyCom = () => {
    return (
      <div className={stylesInner.noData}>
        <span>
          You do not have any {["MyCompleted", "StaffCompleted"].includes(type) ? "completed" : "pending"} assessments
          at this moment.
        </span>
      </div>
    );
  };
  return (
    <>
      <div className={classNames(grid["page-row"], stylesInner.pageHeader)}>
        <div className={grid.root}>
          <div className={grid.container}>
            <div className={stylesInner["top-line"]}>
              <div className={stylesInner.buttons}>
                {buttons.map((group, index) => {
                  return (
                    <div className={stylesInner.filter} key={`buttons-key-${group.title}`}>
                      <Titles level="level_3">{group.title}</Titles>
                      <div className={stylesInner["buttons-group"]}>
                        {group.buttons.map((button, index) => (
                          <FlatButton
                            theme={button.key === type ? "orange" : "gray"}
                            onClick={button.onClick}
                            size="thin"
                            className={classNames(
                              stylesInner.button,
                              index % 2 === 0 ? stylesInner.radiusLeft : stylesInner.radiusRight
                            )}
                            key={`button-key-${button.key}`}
                          >
                            {button.title}{" "}
                            {button.quantity < 10 && button.quantity > 0 ? `0${button.quantity}` : button.quantity}
                          </FlatButton>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={grid["page-row"]}>
        <div className={grid.root}>
          <div className={grid.container}>{getComponent()}</div>
        </div>
      </div>
    </>
  );
};
