import { ProgressLevels } from "./types";

interface Props {
  value: number;
}

export const getPositiveProgressClassNames = ({ value }: Props): ProgressLevels => {
  if (value < 50) {
    return "terrible";
  } else {
    return "great";
  }
};

export const getWithNegativeProgressClassNames = ({ value }: Props): ProgressLevels => {
  if (value >= 0) {
    return "great";
  } else if (value < 0 && value >= -20) {
    return "good";
  } else if (value < -20 && value >= -50) {
    return "bad";
  } else {
    return "terrible";
  }
};
