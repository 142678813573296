import { getPhrases } from "@common/helpers/localization";
import { SelectOptionsProp, SelectUI } from "@ui";
import classNames from "classnames";
import { ITableProps } from "ka-table";
import styles from "./count-select.module.scss";
import "./count-select.scss";
import { SingleValue } from "react-select";

interface Props {
  defaultValue: SelectOptionsProp;
  tableProps?: ITableProps;
  changeTableProps?: (props: ITableProps) => void;
  onChange?: (newValue: SingleValue<SelectOptionsProp>) => void;
}

export const CountSelect = ({ defaultValue, tableProps, changeTableProps, onChange }: Props) => {
  const loc = getPhrases("common", "pagination");
  const options: SelectOptionsProp[] = [
    { value: 10, label: "10" },
    { value: 25, label: "25" },
    { value: 50, label: "50" },
    { value: 100, label: "100" }
  ];
  return (
    <div className={styles.select}>
      <span className={classNames(styles.text, styles["text-pre"])}>{loc.count_pre}</span>
      <SelectUI
        name="count-select"
        className="count-select"
        options={options}
        defaultValue={defaultValue}
        onChange={newValue => {
          if (tableProps && changeTableProps) {
            return changeTableProps({
              ...tableProps,
              paging: {
                ...tableProps.paging,
                pageSize: newValue?.value ? +newValue?.value : +options[0].value
              }
            });
          }
          if (onChange) {
            return onChange(newValue);
          }
        }}
      />
      <span className={classNames(styles.text, styles["text-post"])}>{loc.count_post}</span>
    </div>
  );
};
