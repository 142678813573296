import React, { FC, useEffect, useState } from "react";
import * as yup from "yup";
import { getClientOrgUnits } from "@common/features/org-units/model";
import { useForm } from "@common/hooks/form";
import { IRoleForm } from "@common/types/forms/role";
import { OrgUnit } from "@common/types/org-unit/org-unit";
import { CKEditor, FormInputAutocomplete, FormInputText, FormWrapper, Switch } from "@ui";
import { IRoleFormTabInitData } from "../index";
import { FormError } from "../form-error";

export interface IRoleDetailsTabProps {
  formData: Partial<IRoleForm>;
  onInit?: (data: IRoleFormTabInitData) => void;
  onBack(): void;
  onNext(): void;
  onSubmit(data: Partial<IRoleForm>): void;
  onClose(): void;
}

interface IRoleDetailsForm {
  isManagerial?: boolean;
  name: string;
  orgUnit: number;
  description: string;
  responsibilities: {
    id: number;
    title: string;
    description: string;
  }[];
}

const detailsFormSchema: yup.SchemaOf<IRoleDetailsForm> = yup.object().shape({
  isManagerial: yup.boolean(),
  name: yup.string().required("This field is required"),
  orgUnit: yup.number().required("This field is required"),
  description: yup.string().required("This field is required"),
  responsibilities: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(),
        title: yup.string().required("This field is required"),
        description: yup.string().required("This field is required")
      })
    )
    .min(1)
});

export const RoleDetailsTab: FC<IRoleDetailsTabProps> = ({ formData, onInit, onBack, onNext, onSubmit, onClose }) => {
  const methods = useForm<IRoleDetailsForm>({
    schema: detailsFormSchema,
    defaultValues: {
      isManagerial: formData.isManagerial || false,
      name: formData.name || "",
      orgUnit: formData.orgUnit || undefined,
      description: formData.description || "",
      responsibilities: formData.responsibilities?.length
        ? formData.responsibilities
        : [{ id: 1, title: "", description: "" }]
    }
  });
  const data = methods.getValues();
  const [orgUnits, setOrgUnits] = useState<OrgUnit[]>([]);
  const [orgUnitText, setOrgUnitText] = useState("");
  const [showFormError, setShowFormError] = useState(false);

  useEffect(() => {
    getClientOrgUnits(setOrgUnits);
  }, []);

  useEffect(() => {
    if (onInit) {
      if (showFormError) {
        onInit({ buttons: undefined });
      } else {
        onInit({
          buttons: {
            leftSide: {
              title: (
                <span>
                  Cancel
                  <br />
                  And Close
                </span>
              ),
              theme: "secondary-gradient",
              className: "!w-30 !h-14 !text-sm !text-left !font-semibold",
              onClick: onClose
            },
            rightSide: [
              {
                title: "Next",
                theme: "warning",
                shape: "right-arrow",
                className: "!w-20 !text-sm !font-semibold",
                onClick() {
                  methods.handleSubmit(handleSuccess, handleError)();
                }
              }
            ]
          }
        });
      }
    }
  }, [onInit, showFormError]);

  useEffect(() => {
    if (formData.orgUnit && orgUnits.length) {
      const orgUnit = orgUnits.find(item => item.id === formData.orgUnit);
      if (orgUnit) {
        setOrgUnitText(orgUnit.name);
      }
    }
  }, [orgUnits, formData]);

  const onFieldChange = (field: keyof IRoleDetailsForm, value: any) => {
    methods.setValue(field, value);
    methods.trigger(field);
  };

  const onAddResponsibility = (index: number) => {
    const responsibilities = [...data.responsibilities];
    const maxId = Math.max(0, ...responsibilities.map(item => item.id));
    responsibilities.splice(index + 1, 0, {
      id: maxId + 1,
      title: "",
      description: ""
    });
    onFieldChange("responsibilities", responsibilities);
  };

  const onRemoveResponsibility = (index: number) => {
    let responsibilities = [...data.responsibilities];
    if (responsibilities.length === 1) {
      responsibilities = [{ id: 1, title: "", description: "" }];
    } else {
      responsibilities.splice(index, 1);
    }
    onFieldChange("responsibilities", responsibilities);
  };

  const handleSuccess = (values: IRoleDetailsForm) => {
    onSubmit(values);
    onNext();
  };

  const handleError = () => {
    setShowFormError(true);
  };

  return (
    <FormWrapper className="text-black" onSubmit={methods.handleSubmit(handleSuccess, handleError)}>
      <div className="grid sm:grid-cols-2 gap-4 border-b border-gray-cd px-5 pb-4">
        <div>
          <h6 className="text-primary text-sm font-bold mb-1">
            <i className="fa fa-caret-down" /> This is where you name the role and describe the purpose of the role
          </h6>
          <p className="text-xs">
            In order to define the role, we need to name it, add it to an Organisational Unit and describe the role’s
            purpose; once we have done this we enter and describe the list of responsibilities attached to the role.
          </p>
        </div>
        <div className="flex items-center ml-auto">
          <div className="text-secondary text-sm font-normal mr-3">
            This is&nbsp;
            <b className={data.isManagerial ? "text-primary" : ""}>
              {data.isManagerial ? "a managerial role" : "not a managerial role"}
            </b>
          </div>
          <Switch theme="primary" value={data.isManagerial} onChange={value => onFieldChange("isManagerial", value)} />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4 border-b border-gray-cd px-5 pt-4 pb-5">
        <FormInputText
          className="!text-sm !text-black !placeholder-secondary placeholder-italic"
          name="name"
          label="Role Name"
          placeholder="Enter the name of the new role here"
          register={methods.register}
          error={methods.formState.errors?.name?.message}
        />
        <FormInputAutocomplete
          className="!text-sm !text-black !placeholder-secondary placeholder-italic"
          name="orgUnit"
          label="Org Unit"
          placeholder="Enter the name of the role's org unit"
          list={orgUnits}
          getItemLabel={item => item.name}
          getItemValue={item => item.id}
          dropdownClass="max-h-40 overflow-auto !mt-0"
          dropdownItemClass="!text-sm"
          // register={methods.register}
          value={data.orgUnit}
          onChange={value => onFieldChange("orgUnit", value)}
        />
      </div>

      <div className="px-5 pt-4">
        <CKEditor
          value={data.description}
          config={{
            removePlugins: ["MediaEmbed", "Table"],
            placeholder: "Please enter the purpose and requirements of this role into this window"
          }}
          helperText={methods.formState.errors?.description?.message}
          helperTextClass="text-xs text-danger mt-1"
          onChange={value => onFieldChange("description", value)}
        />

        <table className="w-full mt-4">
          <thead>
            <tr className="text-gray text-xs text-left">
              <th className="pr-4">Responsibility</th>
              <th className="pr-4">Description of responsibility</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {(data.responsibilities || []).map((item, i) => (
              <tr key={i} className="group">
                <td className="pr-4 py-1">
                  <FormInputText
                    className="!text-sm !text-black !placeholder-secondary placeholder-italic"
                    name={`responsibilities[${i}].title`}
                    placeholder="Type the name of the responsibility"
                    showErrorText={false}
                    register={methods.register}
                    error={
                      methods.formState.errors?.responsibilities &&
                      methods.formState.errors?.responsibilities[i]?.title?.message
                    }
                  />
                </td>
                <td className="pr-4 py-1">
                  <FormInputText
                    className="!text-sm !text-black !placeholder-secondary placeholder-italic"
                    name={`responsibilities[${i}].description`}
                    placeholder="Describe the responsibility"
                    register={methods.register}
                    showErrorText={false}
                    error={
                      methods.formState.errors?.responsibilities &&
                      methods.formState.errors?.responsibilities[i]?.description?.message
                    }
                  />
                </td>
                <td className="w-10 py-1">
                  <i className="fa fa-times text-primary cursor-pointer" onClick={() => onRemoveResponsibility(i)} />
                  <i
                    className="fa fa-plus opacity-0 group-hover:opacity-100 text-warning cursor-pointer transition-all ml-2"
                    onClick={() => onAddResponsibility(i)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showFormError && (
        <FormError className="w-full h-full absolute top-0 left-0 z-100" onClose={() => setShowFormError(false)} />
      )}
    </FormWrapper>
  );
};
