export const parseDuration = (text: string) => {
  const match = /^(\d+(\.\d+)?)\s*(m|h)$/g.exec(text);
  if (!match) {
    return undefined;
  }
  return Number(match[1]) * (match[3] === "h" ? 60 : 1);
};

export const formatDuration = (minutes: number) => {
  if (minutes >= 60 && minutes % 6 === 0) {
    return `${minutes / 60}h`;
  }
  return `${minutes}m`;
};
