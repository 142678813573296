import styles from "./complete-state.module.scss";

interface Props {
  category: string;
  percent: number;
  text?: React.ReactNode;
}

export const CompleteState = ({ category, percent, text }: Props) => {
  return text ? (
    <>{text}</>
  ) : (
    <>
      <span className={styles.category}>{category}</span>
      {` / ${percent}% Complete`}
    </>
  );
};
