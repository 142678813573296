import { getTreeView } from "@common/features/learn/model";
import { TreeViewInterface } from "@common/types/reports/learn/learn";
import { TableColumn, TableDetailRows } from "@common/types/table";
import { FirstLevelTable } from "@ui";
import { useEffect, useState } from "react";
import classNames from "classnames";
import grid from "@styles/modules/grid.module.scss";
import { DataType, SortDirection } from "ka-table/enums";
import { LastLevel } from "@features/learn/views";

export const FirstLevel = () => {
  const [data, setData] = useState<TreeViewInterface[]>();
  useEffect(() => {
    getTreeView(setData);
  }, []);

  const columns: TableColumn<TreeViewInterface>[] = [
    {
      key: "name",
      title: "Category / Learning Resource",
      dataType: DataType.String,
      sortDirection: SortDirection.Ascend,
      modClass: classNames(grid.item, grid["item-12x"]),
      expandCell: true
    }
  ];

  const detailRows: TableDetailRows<TreeViewInterface> = {
    component: LastLevel,
    dataProperty: "learn_resources"
  };

  return data?.length
    ? FirstLevelTable<TreeViewInterface>({
        data,
        columns,
        detailRows
      })
    : null;
};
