import { Skill } from "@common/types/reports/skills/skills-employee";
import { IDataRowProps } from "ka-table/props";
import { SecondLevelTable } from "@ui";
import { skillsColumns } from "../../model";

export const SecondLevel: React.FC<IDataRowProps> = ({ rowData }) => {
  const data: Skill[] | null = rowData.skills?.length ? rowData.skills : null;
  return data
    ? SecondLevelTable<Skill>({
        data,
        columns: skillsColumns
      })
    : null;
};
