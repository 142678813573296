import React, { FC, useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { useDropzone } from "react-dropzone";
import { Button, FormInputRadio, FormWrapper } from "@ui";
import { useForm } from "@common/hooks/form";
import { getPhrases } from "@common/helpers/localization";
import { ILearnForm } from "@common/types/forms/learn";
import { ILearnFormTabInitData } from "../index";
import { FormError } from "../form-error";
import { UploadConfirmModal } from "../upload-confirm-modal";
import { LearningResourceDeleteConfirm } from "@features/learn/learn-form/learning-resource-delete-confirm";

export interface IResourceMediaTabProps {
  formData: Partial<ILearnForm>;
  onInit?: (data: ILearnFormTabInitData) => void;
  onBack(): void;
  onNext(): void;
  onSubmit(data: Partial<ILearnForm>): void;
  onClose(): void;
  editable?: boolean;
}

interface IResourceMediaForm {
  mediaType: string;
  mediaSrc: string;
}

const loc = getPhrases("common", "forms").validation;

const mediaFormSchema: yup.SchemaOf<IResourceMediaForm> = yup.object().shape({
  mediaType: yup.string().required(loc.required),
  mediaSrc: yup.string().required(loc.required)
});

const libraryImages = [
  "/images/mock/banner-28.jpg",
  "/images/mock/banner-30.jpg",
  "/images/mock/banner-31.jpg",
  "/images/mock/banner-32.jpg",
  "/images/mock/banner-33.jpg"
];

const youtubeImage = "/images/mock/banner-25.jpg";

export const LearnMediaTab: FC<IResourceMediaTabProps> = ({ formData, onInit, onBack, onNext, onSubmit, onClose }) => {
  const methods = useForm<IResourceMediaForm>({
    schema: mediaFormSchema,
    defaultValues: {
      mediaType: formData.mediaType || "local",
      mediaSrc: formData.mediaSrc || undefined
    }
  });
  const data = methods.getValues();

  const [timestamp, setTimestamp] = useState<number>();
  const [showFormError, setShowFormError] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const onDropImage = useCallback(acceptedFiles => {
    if (!acceptedFiles.length) {
      return;
    }

    const imageUrl = URL.createObjectURL(acceptedFiles[0]);

    onFieldChange("mediaType", "local");
    onFieldChange("mediaSrc", imageUrl);
  }, []);

  const {
    getRootProps,
    getInputProps,
    open: openDropzone
  } = useDropzone({
    noClick: true,
    accept: {
      "image/*": [".jpeg", ".png"]
    },
    onDrop: onDropImage
  });

  useEffect(() => {
    if (onInit) {
      if (showDeleteConfirm || showFormError) {
        onInit({ buttons: undefined });
      } else {
        onInit({
          buttons: {
            leftSide: [
              {
                title: (
                  <span>
                    Cancel
                    <br />
                    And Close
                  </span>
                ),
                theme: "secondary-gradient",
                className: "!w-30 !h-14 !text-sm !text-left !font-semibold",
                onClick: onClose
              },
              ...(formData.id
                ? [
                    {
                      title: (
                        <span className="w-full px-4">
                          Delete this
                          <br />
                          Learning Resource
                        </span>
                      ),
                      theme: "danger" as "danger",
                      className: "!w-44 !h-14 !text-xs !text-left !font-semibold !ml-4",
                      onClick: () => setShowDeleteConfirm(true)
                    }
                  ]
                : [])
            ],
            rightSide: [
              {
                title: "Prev",
                theme: "warning",
                shape: "left-arrow",
                className: "!w-20 !text-sm !font-semibold",
                onClick() {
                  goBack();
                }
              },
              {
                title: "Next",
                theme: "warning",
                shape: "right-arrow",
                className: "!w-20 !text-sm !font-semibold",
                onClick() {
                  methods.handleSubmit(handleSuccess, handleError)();
                }
              }
            ]
          }
        });
      }
    }
  }, [onInit, showDeleteConfirm, showFormError]);

  const onDelete = () => {
    onClose();
  };

  const onFieldChange = (field: keyof IResourceMediaForm, value: any) => {
    methods.setValue(field, value);
    methods.trigger(field);
    setTimestamp(new Date().getTime());
  };

  const onAttemptUploadImage = () => {
    setShowUploadModal(true);
  };

  const onGenerateRandomLibraryImage = () => {
    const image = libraryImages[Math.floor(Math.random() * libraryImages.length)];
    onFieldChange("mediaType", "library");
    onFieldChange("mediaSrc", image);
  };

  const onGenerateYoutubeImage = () => {
    onFieldChange("mediaType", "youtube");
    onFieldChange("mediaSrc", youtubeImage);
  };

  // useEffect(() => {
  //   onGenerateRandomLibraryImage();
  // }, []);

  const onConfirmUpload = (confirmed: boolean) => {
    setShowUploadModal(false);

    if (confirmed) {
      openDropzone();
    }
  };

  const handleSuccess = (values: IResourceMediaForm) => {
    onSubmit(values);
    onNext();
  };

  const handleError = () => {
    // setShowFormError(true);
  };

  const goBack = () => {
    onSubmit(methods.getValues());
    onBack();
  };

  return (
    <FormWrapper className="text-black px-5" onSubmit={methods.handleSubmit(handleSuccess, handleError)}>
      <div>
        <div className="flex items-center">
          <FormInputRadio
            name="mediaType"
            label="Select an image from local device"
            value="local"
            size="sm"
            theme="gray"
            checked={data.mediaType === "local"}
            onChange={onAttemptUploadImage}
          />
          <Button
            className="!w-auto !h-8 text-sm font-semibold ml-auto"
            theme="blue"
            appearance="solid"
            onClick={onAttemptUploadImage}
          >
            <span className="px-4">Upload</span>
          </Button>
        </div>
        <div className="flex items-center mt-4">
          <FormInputRadio
            name="mediaType"
            label="Use a random image from our library"
            value="library"
            size="sm"
            theme="gray"
            // checked={data.mediaType === 'library'}
            // onChange={onGenerateRandomLibraryImage}
          />
          <Button
            className="!w-auto !h-8 text-sm font-semibold ml-auto"
            disabled={true}
            theme="blue"
            appearance="solid"
            onClick={onGenerateRandomLibraryImage}
          >
            <span className="px-4">Generate</span>
          </Button>
        </div>
        <div className="flex items-center mt-4">
          <FormInputRadio
            name="mediaType"
            label="Automatically use the youtube video image (Only available for youtube videos)"
            value="youtube"
            size="sm"
            theme="gray"
            checked={data.mediaType === "youtube"}
            onChange={onGenerateYoutubeImage}
            disabled={formData.mediaType !== "youtube"}
          />
          <Button
            disabled={formData.mediaType !== "youtube"}
            className="!w-auto !h-8 text-sm font-semibold ml-auto"
            theme="blue"
            appearance="solid"
            onClick={onGenerateYoutubeImage}
          >
            <span className="px-4">Generate</span>
          </Button>
        </div>
      </div>

      <div className="flex flex-col items-center mt-6">
        <div className="border border-solid border-gray-cd text-sm text-gray rounded-full px-6 py-1">
          Image preview:
        </div>
        <div
          className="w-120 h-60 max-w-[70vw] max-h-[30vw] relative flex items-center bg-contain bg-center bg-no-repeat border border-solid border-gray-cd rounded-md mt-2"
          style={{ backgroundImage: `url(${data.mediaSrc})` }}
        >
          <div className="absolute right-0 bg-white text-md font-bold px-5 py-2 rounded-l-md">
            Programming for
            <br />
            Everybody (Getting
            <br />
            Started with Python)
          </div>
        </div>
      </div>

      <div {...getRootProps()}>
        <input {...getInputProps()} />
      </div>

      {showDeleteConfirm && (
        <LearningResourceDeleteConfirm
          className="w-full h-full absolute top-0 left-0 z-100"
          onDelete={onDelete}
          onClose={() => setShowDeleteConfirm(false)}
        />
      )}

      {showUploadModal && <UploadConfirmModal onClose={onConfirmUpload} />}

      {showFormError && (
        <FormError className="w-full h-full absolute top-0 left-0 z-100" onClose={() => setShowFormError(false)} />
      )}
    </FormWrapper>
  );
};
