import { HTMLViewer } from "@ui";
import styles from "./accountabilities.module.scss";
import grid from "@styles/modules/grid.module.scss";
import classNames from "classnames";
import titlesStyles from "@styles/modules/common/titles.module.scss";
import { useEffect, useState } from "react";
import { AccountabilitiesSession, Accountability } from "@common/types/reports/accountabilities/accountabilities";
import { getAccountabilitiesSessions, getRoleAccountabilityDetail } from "@common/features/accountabilities/model";
import { AccountabilityItem } from "./ui/accountability-item";
import { observer } from "mobx-react-lite";

interface Props {
  profileId: number;
  roleId: number;
  legendComponent: React.ReactNode;
}

export const Accountabilities = observer(({ profileId, roleId, legendComponent }: Props) => {
  const [accountabilitiesSessions, setaccountabilitiesSessions] = useState<AccountabilitiesSession>();

  const [isAccountabilitiesAvailable, setAccountabilitiesAvailable] = useState<boolean>(true);

  useEffect(() => {
    if (roleId) {
      getRoleAccountabilityDetail(roleId)
        .then(data => {
          data.is_enabled
            ? getAccountabilitiesSessions(Number(profileId), setaccountabilitiesSessions)
            : setAccountabilitiesAvailable(false);
        })
        .catch(error => setAccountabilitiesAvailable(false));
    }
  }, [roleId, profileId]);

  // const total: number | null = accountabilitiesSessions?.accountabilities
  //   ?.length
  //   ? accountabilitiesSessions.accountabilities.reduce(
  //       (sum, item) => sum + item.accountability.weight,
  //       0
  //     )
  //   : null;
  const total = 0;

  accountabilitiesSessions?.accountabilities &&
    accountabilitiesSessions.accountabilities.sort((a, b) => b.weight - a.weight);

  return isAccountabilitiesAvailable ? (
    <>
      <div className={styles.block}>
        <div className={styles.title}>Displaying accountabilities for 2022</div>
        {legendComponent}
        <div className={styles.accountabilities}>
          <div className={styles.list}>
            <div className={styles.grid}>
              {accountabilitiesSessions?.accountabilities?.length &&
                accountabilitiesSessions.accountabilities.map((item, index) => {
                  return (
                    <AccountabilityItem
                      interval={accountabilitiesSessions.interval}
                      item={item}
                      key={`accountability-item-${item.id}`}
                    />
                  );
                })}
            </div>
          </div>
        </div>
        {!!total && (
          <div className={styles.total}>
            <div className={styles["total-text"]}>Total weight for all Accountabilities</div>
            <div className={styles["total-value"]}>{total}%</div>
          </div>
        )}
      </div>
      {!!accountabilitiesSessions?.feedbacks?.length && (
        <>
          <div className={grid.list}>
            <div className={classNames(grid.item, grid["item-6x"], styles["feedback-titles"])}>
              <div className={titlesStyles.title}>Employee feedback and notes</div>
            </div>
            <div className={classNames(grid.item, grid["item-6x"])}>
              <div className={titlesStyles.title}>Manager feedback and notes</div>
            </div>
          </div>
          <div className={styles["feedbacks-list"]}>
            {accountabilitiesSessions.feedbacks.map((item, index) => {
              return (
                <div className={classNames(styles["feedback-row"], grid.list)} key={`feedback-${index}`}>
                  <div className={classNames(grid.item, grid["item-6x"], styles["feedback-block"])}>
                    <div className={styles["feedback-date"]}>{item.date ?? ""}</div>
                    <div className={styles["feedback-text"]}>
                      {item.self ? (
                        <HTMLViewer modClass={classNames(styles["feedback-self"])} html={item.self} />
                      ) : (
                        "No information"
                      )}
                    </div>
                  </div>
                  <div className={classNames(grid.item, grid["item-6x"], styles["feedback-block"])}>
                    <div className={styles["feedback-date"]}>{item.date ?? ""}</div>
                    <div className={styles["feedback-text"]}>
                      {item.manager ? <HTMLViewer html={item.manager} /> : "No information"}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  ) : (
    <div className={styles.nothing}>There are no accountabilities associated to your role</div>
  );
});
