import { TableColumn } from "@common/types/table";
import classNames from "classnames";
import { DataType } from "ka-table/enums";
import { IDataRowProps } from "ka-table/props";
import { Skill } from "../types";
import grid from "@styles/modules/grid.module.scss";
import table from "./table.module.scss";
import { SecondLevelTable } from "@ui";
import { StatusCell } from "./status";

export const SecondLevel: React.FC<IDataRowProps> = ({ rowData }) => {
  const data: Skill[] | null = rowData.skills?.length ? rowData.skills : null;

  const columns: TableColumn<Skill>[] = [
    {
      key: "is_active",
      title: "Status",
      dataType: DataType.Boolean,
      modClass: classNames(grid.item, grid["item-2x"]),
      component: StatusCell
    },
    {
      key: "name",
      title: "Skill name",
      dataType: DataType.String,
      modClass: classNames(grid.item, grid["item-7x"])
    },
    {
      key: "importance",
      title: "Importance",
      dataType: DataType.Number,
      modClass: classNames(grid.item, grid["item-2x"])
    },
    {
      key: "target",
      title: "Target",
      dataType: DataType.Number,
      modClass: classNames(grid.item, grid["item-2x"])
    }
  ];
  return data
    ? SecondLevelTable<Skill>({
        data,
        columns,
        modStyles: table
      })
    : null;
};
