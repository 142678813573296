import { ComplexModalButtons, FormInputAutocomplete, FormInputText, FormWrapper, Switch } from "@ui";
import form from "@styles/modules/form.module.scss";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { getPhrases } from "@common/helpers/localization";
import * as yup from "yup";
import { OfficeDetailFormInterface, RegionProps } from "@common/types/reports/offices/office-detail";
import { useForm } from "@common/hooks/form";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { SkillCategory } from "@common/types/skill-engine/skill-category";
import { GlobalStore } from "@common/api/store/global";
import { getCountryRegions } from "@common/features/offices/model";

interface Props {
  isSaving?: boolean;
  formData?: OfficeDetailFormInterface;
  onSubmit(data: Partial<OfficeDetailFormInterface>): void;
  onClose(): void;
  onSetModalButtons(buttons?: ComplexModalButtons): void;
}

const loc = getPhrases("common", "forms").validation;

export const UpdateOfficeSchema: yup.SchemaOf<OfficeDetailFormInterface> = yup.object().shape(
  {
    name: yup.string().required(loc.required),
    area_id: yup.number().required(loc.required),
    is_virtual: yup.boolean(),
    address: yup.object().when("is_virtual", isVirtual => {
      if (isVirtual) {
        return yup.object().notRequired();
      }

      return yup.object().shape({
        country_id: yup.number().required(loc.required),
        address1: yup.string(),
        address2: yup.string(),
        city: yup.string(),
        region_id: yup.number(),
        postal: yup.string()
      });
    })
  },
  [["is_virtual", "address"]]
);

export const OfficeInfoForm = observer(({ isSaving, formData, onSubmit, onClose, onSetModalButtons }: Props) => {
  const { $destinationDataStore } = useContext(GlobalStore);
  const [curFormData, setCurFormData] = useState<OfficeDetailFormInterface | undefined>(formData);
  const methods = useForm<OfficeDetailFormInterface>({
    schema: UpdateOfficeSchema,
    defaultValues: {
      area_id: formData?.area_id,
      name: formData?.name,
      is_virtual: formData?.is_virtual ?? false,
      address: {
        address1: formData?.address?.address1,
        address2: formData?.address?.address2,
        city: formData?.address?.city,
        country_id: formData?.address?.country_id ? +formData?.address?.country_id : undefined,
        postal: formData?.address?.postal || "",
        region_id: formData?.address?.region_id ? +formData?.address?.region_id : undefined
      }
    }
  });
  const [, setShowFormError] = useState(false);

  useEffect(() => {
    if (formData) {
      methods.reset({
        ...formData,
        is_virtual: formData?.is_virtual ?? true,
        address: {
          address1: formData?.address?.address1,
          address2: formData?.address?.address2,
          city: formData?.address?.city,
          country_id: formData?.address?.country_id ? +formData?.address?.country_id : undefined,
          postal: formData?.address?.postal || "",
          region_id: formData?.address?.region_id ? +formData?.address?.region_id : undefined
        }
      });
      setCurFormData(methods.getValues());
    }
  }, [formData]);

  const handleSuccess = useCallback(
    ({ is_virtual, area_id, name, address }: OfficeDetailFormInterface) => {
      onSubmit({ is_virtual, area_id, name, ...(is_virtual ? {} : { address }) });
    },
    [onSubmit]
  );

  const handleError = useCallback(() => {
    setShowFormError(true);
  }, [setShowFormError]);

  useEffect(() => {
    onSetModalButtons({
      rightSide: [
        {
          title: (
            <span>
              Cancel
              <br />
              Changes
            </span>
          ),
          theme: "secondary-gradient",
          className: "!w-30 !h-14 !text-sm !text-left !font-semibold",
          onClick: onClose,
          disabled: isSaving
        },
        {
          title: (
            <span>
              Save
              <br />
              Changes
            </span>
          ),
          theme: "orange",
          className: "!w-30 !h-14 !text-sm !text-left !font-semibold",
          onClick() {
            methods.handleSubmit(handleSuccess, handleError)();
          },
          disabled: isSaving
        }
      ]
    });
  }, [isSaving, onSetModalButtons]);

  const regions = useMemo(() => {
    const countryId = curFormData?.address?.country_id;
    if (countryId) {
      if ($destinationDataStore.regions[countryId]) {
        return $destinationDataStore.regions[countryId];
      }
    }
    return [] as RegionProps[];
  }, [curFormData?.address?.country_id && $destinationDataStore.regions[curFormData?.address?.country_id]]);

  useEffect(() => {
    const countryId = curFormData?.address?.country_id;
    if (countryId) {
      if (!$destinationDataStore.regions[countryId]) {
        getCountryRegions(countryId);
      }
    }
  }, [curFormData?.address?.country_id]);

  const onFieldChange = (field: any, value: any) => {
    methods.setValue(field, value);
    setCurFormData(methods.getValues());
  };

  return (
    <FormWrapper onSubmit={methods.handleSubmit(handleSuccess, handleError)}>
      <div className={classNames(form.row)}>
        <div className="mb-5 grid sm:grid-cols-2 gap-4">
          <FormInputText
            className="!placeholder-secondary placeholder-italic"
            name="name"
            label="Office Name"
            placeholder="Type name of office"
            register={methods?.register}
            error={methods?.formState.errors["name"]?.message}
          />
          <FormInputAutocomplete
            className="!placeholder-secondary placeholder-italic"
            name="area"
            label="Search for area"
            placeholder="Type the name of the area here"
            list={$destinationDataStore.areas}
            value={curFormData?.area_id || undefined}
            group
            groupSelectable
            getItemLabel={(item: SkillCategory) => item.name}
            getItemValue={(item: SkillCategory) => item.id}
            dropdownClass="max-h-30 overflow-auto !mt-0"
            dropdownItemClass="!text-sm"
            register={methods.register}
            error={methods?.formState.errors?.area_id?.message}
            onChange={id => onFieldChange("area_id", id)}
          />
          <div className="flex items-center mr-auto">
            <Switch
              theme="primary"
              value={curFormData?.is_virtual}
              onChange={value => onFieldChange("is_virtual", value)}
            />
            <div className="text-secondary text-sm font-normal ml-3">
              Office&nbsp;
              <b className={classNames(curFormData?.is_virtual ? "text-primary" : "", "font-bold")}>
                is {curFormData?.is_virtual ? "" : "not "}
              </b>
              virtual&nbsp;
            </div>
          </div>
        </div>
        {!curFormData?.is_virtual && (
          <div className="grid sm:grid-cols-2 gap-4">
            <FormInputAutocomplete
              className="!placeholder-secondary placeholder-italic"
              name="country_id"
              label="Search for country"
              placeholder="Type the name of the country here"
              list={$destinationDataStore.countries}
              value={curFormData?.address?.country_id || undefined}
              group
              groupSelectable
              getItemLabel={(item: SkillCategory) => item.name}
              getItemValue={(item: SkillCategory) => item.id}
              dropdownClass="max-h-30 overflow-auto !mt-0"
              dropdownItemClass="!text-sm"
              register={methods.register}
              error={methods?.formState.errors?.address?.country_id?.message}
              onChange={id => onFieldChange("address.country_id", id)}
            />
            {regions.length > 0 ? (
              <FormInputAutocomplete
                className="!placeholder-secondary placeholder-italic"
                name="region_id"
                label="Search for region of selected country"
                placeholder="Type the name of region here"
                list={regions}
                value={curFormData?.address?.region_id || undefined}
                group
                groupSelectable
                getItemLabel={(item: SkillCategory) => item.name}
                getItemValue={(item: SkillCategory) => item.id}
                dropdownClass="max-h-30 overflow-auto !mt-0"
                dropdownItemClass="!text-sm"
                register={methods.register}
                onChange={id => onFieldChange("address.region_id", id)}
                error={methods?.formState.errors?.address?.region_id?.message}
              />
            ) : (
              <div />
            )}
            <FormInputText
              className="!placeholder-secondary placeholder-italic"
              name="address.address1"
              label="Address 1"
              placeholder="Type address"
              register={methods?.register}
              error={methods?.formState.errors?.address?.address1?.message}
            />
            <FormInputText
              className="!placeholder-secondary placeholder-italic"
              name="address.address2"
              label="Address 2"
              placeholder="Type address"
              register={methods?.register}
              error={methods?.formState.errors?.address?.address2?.message}
            />
            <FormInputText
              className="!placeholder-secondary placeholder-italic"
              name="address.city"
              label="City"
              placeholder="Type city name"
              register={methods?.register}
              error={methods?.formState.errors?.address?.city?.message}
            />
            <FormInputText
              className="!placeholder-secondary placeholder-italic"
              name="address.postal"
              label="Postal"
              placeholder="Type postal"
              register={methods?.register}
              error={methods?.formState.errors?.address?.postal?.message}
            />
          </div>
        )}
      </div>
    </FormWrapper>
  );
});
