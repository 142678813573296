import { getMemberRoleById } from "@common/features/roles/model";
import { CommonWithId } from "@common/types/common";
import { EditPersonInterface } from "@common/types/reports/profile/profile";
import { TableCellProps } from "@common/types/table";
import { StatusSwitcher, UserInfoContent, UserInfoPanel } from "@features/people";
import { Button, ComplexModal, ComplexModalButtons } from "@ui";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { $RoleInfoStore } from "./model";
import styles from "./user-info-modal.module.scss";
import { $profileStore } from "@common/api/store/profile";
import { FormError } from "@features/settings/blocks/roles/role-form/form-error";
import classNames from "classnames";
import { KpiTooltip } from "@features/reporting/kpi-tooltip";
import { UserModalProps } from "@common/types/managment/edit-person";

interface Props extends Partial<TableCellProps>, UserModalProps {
  closeModal: () => void;
  isOpen: boolean;
}

export interface PersonProfile {
  id: number;
  full_name: string;
  is_active?: boolean;
  report?: string;
}

export const UserInfoModal = observer(({ isOpen, closeModal, isEditModal = false, props }: Props) => {
  const rowData = props?.rowData ?? undefined;

  const roleInfo: EditPersonInterface | null = $RoleInfoStore.getRoleInfo();

  const [personProfile, setPersonProfile] = useState<PersonProfile>(
    rowData?.id && {
      id: Number(rowData.id),
      ...(rowData?.full_name && { full_name: rowData.full_name }),
      ...(rowData?.report && { report: rowData.report }),
      ...(rowData?.is_active && { is_active: rowData.is_active })
    }
  );

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [isDataSending, setDataSendingStatus] = useState<boolean>(false);
  const activeTabsCount = personProfile?.id ? (roleInfo ? 5 : 2) : 1;

  const goToPrevPage = () => {
    setTabIndex(prev => prev - 1);
  };

  const goToNextPage = () => {
    setTabIndex(prev => prev + 1);
  };

  const buttons: ComplexModalButtons = {
    ...(tabIndex > 0 && {
      leftSide: {
        title: "Prev",
        theme: "gray",
        onClick: goToPrevPage,
        ...(isDataSending && { disabled: true })
      }
    }),
    rightSide: [
      {
        title: tabIndex < 4 ? "Cancel" : "Close",
        appearance: "bordered",
        theme: "gray",
        onClick: closeModal,
        ...(isDataSending && { disabled: true })
      },
      ...(tabIndex < activeTabsCount - 1
        ? [
            {
              title: "Next",
              onClick: goToNextPage,
              ...(isDataSending && { disabled: true })
            }
          ]
        : [])
    ]
  };
  return (
    <ComplexModal
      isOpen={isOpen}
      closeModal={() => {
        $RoleInfoStore.reset();
        closeModal();
      }}
      headerTitle={`${isEditModal ? "Edit Person: " : "Add Person"}`}
      {...(personProfile?.full_name && {
        secondaryTitle: personProfile.full_name
      })}
      buttons={buttons}
      HeaderTitleComponent={
        <div className={styles["header-right"]}>
          <KpiTooltip
            description={
              isEditModal ? (
                "Use this radio button to activate or deactivate an employee profile. Deactivating a Manager with direct reports is not possible. You need to assign a new manager first"
              ) : tabIndex === 1 ? (
                <span>
                  The employee profile is activated automatically by default after assigning a role. Yo can deactivate
                  if needed. ///This tooltip will not displayed in Edit (We will displayed instead: Tooltip: Use this
                  radio button to activate or deactivate an employee profile. Deactivating a Manager with direct reports
                  is not possible. You need to assign a new manager first.) ы
                </span>
              ) : (
                "The employee profile cannot be activated in this first step. Activation is done in the second tab after assigning a role"
              )
            }
            className={styles.tooltip}
          >
            ?
          </KpiTooltip>
          <StatusSwitcher
            {...(personProfile?.id && { userId: personProfile.id })}
            {...(personProfile?.is_active && {
              is_active: personProfile.is_active
            })}
          />
        </div>
      }
      headerSecondRow={
        <>
          <UserInfoPanel
            activeTabsCount={activeTabsCount}
            index={tabIndex}
            setIndex={setTabIndex}
            isSending={isDataSending}
            setSendingStatus={setDataSendingStatus}
          />
          {$profileStore.personProfileError && (
            <div className="w-full h-full absolute top-0 left-0 z-100 bg-blue-light flex-center flex-col">
              <div className="text-white text-2xl">Something is missing.</div>
              <div className="text-warning text-3xl mt-4">Please associate a Role to the person in the Role tab.</div>
              <Button
                className="!text-md !font-semibold mt-8"
                title="Return to the form"
                onClick={() => ($profileStore.personProfileError = "")}
              />
            </div>
          )}
        </>
      }
      modClass={styles.modal}
    >
      <>
        <UserInfoContent
          personProfile={personProfile}
          setPersonProfile={setPersonProfile}
          tabIndex={tabIndex}
          isSending={isDataSending}
          setSendingStatus={setDataSendingStatus}
          isEditModal={isEditModal}
        />
      </>
    </ComplexModal>
  );
});
