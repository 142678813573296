import { AlertModal, ComplexModal, ComplexModalButtons } from "@ui";
import modal from "@styles/modules/complex-modal.module.scss";
import { useCallback, useEffect, useState } from "react";
import { AreaFormInterface } from "@common/types/reports/offices/office-detail";
import { getAreaDetail, getOfficeDetail, getReportOffices, saveArea } from "@common/features/offices/model";
import { AreaInfoForm } from "@features/settings/blocks/offices/area-info-form";

interface Props {
  closeModal: () => void;
  isOpen: boolean;
  id?: number;
  rowData?: Partial<AreaFormInterface>;
}

export const AreaInfoModal = ({ id, isOpen, closeModal, rowData }: Props) => {
  const [formData, setFormData] = useState<Partial<AreaFormInterface>>({});
  const [buttons, setButtons] = useState<ComplexModalButtons>();
  const [isSendingError, setSendingErrorStatus] = useState<boolean | string>(false);
  const [isSending, setIsSending] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      getAreaDetail(id).then(data => {
        setFormData({
          name: data?.name
        });
      });
    }
  }, [id]);

  const submit = useCallback(
    (data: AreaFormInterface) => {
      saveArea(data, id)
        .then(() => {
          closeModal();
          getReportOffices();
        })
        .catch(e => {
          setSendingErrorStatus(e?.response?.data.errors[0].title);
        })
        .finally(() => setIsSending(false));
    },
    [id, closeModal]
  );

  return (
    <ComplexModal
      isOpen={isOpen}
      closeModal={closeModal}
      headerTitle="Place info:"
      secondaryTitle={rowData?.name}
      buttons={buttons}
    >
      <div className={modal.row}>
        <AreaInfoForm
          formData={formData as AreaFormInterface}
          isSaving={isSending}
          onSubmit={submit}
          onClose={closeModal}
          onSetModalButtons={setButtons}
        />
      </div>
      <AlertModal
        cancelButtonText="Close"
        message={
          typeof isSendingError === "string"
            ? isSendingError
            : "Something went wrong :-( We are working on it. Please try again later"
        }
        title="Alert"
        type="fail"
        closeModal={() => setSendingErrorStatus(false)}
        isOpen={!!isSendingError}
      />
    </ComplexModal>
  );
};
