import { getOrgUnits, sendOrgUnits, deleteOrgUnit as deleteOrgUnitFunc } from "@common/api/services/org-units";
import { $orgUnitsStore } from "@common/api/store/org-units";
import { OrgUnits, OrgUnitsData, OrgUnitsForm, OrgUnitsFormDetail } from "@common/types/reports/org-units/org-units";
import { OrgUnit } from "../../types/org-unit/org-unit";

export const getReportOrgUnits = async (callback?: any) => {
  try {
    const response = await getOrgUnits<OrgUnitsData>("/web/settings/org-units");
    const data: OrgUnits[] = response.data.data.map(item => {
      return {
        ...item,
        org_units_count: item.children?.length ? item.children?.length : 0
      };
    });
    $orgUnitsStore.setOrgUnits(data);
    if (callback) {
      callback(data);
    }
  } catch (e) {
    console.error("Get report org units error");
  }
};

export const getClientOrgUnits = async (callback?: any) => {
  try {
    const response = await getOrgUnits<OrgUnit>("/client/organization/units");
    const data: OrgUnit[] = response.data.data;
    $orgUnitsStore.setOrgUnits(data);
    if (callback) {
      callback(data);
    }
  } catch (e) {
    console.error("Get client org units error");
  }
};

export const getOrgUnitDetail = async (id: number, callback: any) => {
  try {
    const response = await getOrgUnits<OrgUnitsFormDetail>(`/client/organization/units/${id}`);
    callback(response.data.data);
  } catch (e) {
    console.error("Get detail org unit error");
  }
};

export const deleteOrg = async (id: number, callback?: any) => {
  try {
    const response = await deleteOrgUnitFunc(`/client/organization/units/${id}`);
    getReportOrgUnits();
    callback && callback(response.data.data);
  } catch (e) {
    console.error("Delete org unit error");
    throw e;
  }
};

export const sendNewOrgUnit = async (props: OrgUnitsForm) => {
  try {
    const response = await sendOrgUnits(`/client/organization/units`, props);
    if (response) {
      $orgUnitsStore.updateOrgUnits();
    }
  } catch (e) {
    console.error("Create new org unit error");
  }
};

export const updateOrgUnit = async (id: number, props: OrgUnitsForm) => {
  try {
    const response = await sendOrgUnits(`/client/organization/units/${id}`, props);
    if (response) {
      $orgUnitsStore.updateOrgUnits();
    }
  } catch (e) {
    console.error("Update org unit error");
  }
};
