import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { GlobalStore } from "@common/api/store/global";
import { TableColumn, TableDetailRows } from "@common/types/table";
import classNames from "classnames";
import { DataType, SortDirection } from "ka-table/enums";
import grid from "@styles/modules/grid.module.scss";
import { FirstLevelTable, Switch } from "@ui";
import { FormattedPeopleByOrgUnit, PeopleByOrgUnit } from "@common/types/reports/people/people";
import { getPeopleByOrgUnit } from "@common/features/people/model";
import { AverageScore } from "../progress";
import { IntermediateLevel } from "./intermediate-level";
import styles from "./first-level.module.scss";
import { getPhrases } from "@common/helpers/localization";
import { filterUsers } from "@features/people/reports/helpers";
import { cloneDeep } from "lodash";

export const FirstLevel = observer(() => {
  const loc = getPhrases("people_page", "table");

  const { $profileStore } = useContext(GlobalStore);
  const [data, setData] = useState<PeopleByOrgUnit[]>();
  const [showInactive, setShowInactive] = useState(false);

  useEffect(() => {
    getPeopleByOrgUnit(true, setData);
  }, [$profileStore.getCurrentRoleId()]);

  const formattedData: FormattedPeopleByOrgUnit[] | null = useMemo(() => {
    if (data) {
      const peopleData = cloneDeep(data);
      filterUsers(
        "members",
        member => (member.is_active && !showInactive) || (!member.is_active && showInactive),
        peopleData
      );
      return peopleData.reduce((previousArray: FormattedPeopleByOrgUnit[], currentElement: PeopleByOrgUnit) => {
        return [
          ...previousArray,
          {
            id: currentElement.id,
            name: currentElement.name,
            avg_skill_gap: currentElement.avg_skill_gap,
            members_count: currentElement.members_count,
            inside: {
              children: currentElement.children,
              members: currentElement.members
            }
          }
        ];
      }, []);
    }
    return null;
  }, [data, showInactive]);

  const columns: TableColumn<FormattedPeopleByOrgUnit>[] = [
    {
      key: "name",
      title: "Org Unit",
      dataType: DataType.String,
      sortDirection: SortDirection.Ascend,
      modClass: classNames(grid.item, grid["item-4x"], styles.strong),
      expandCell: true
    },
    {
      key: "members_count",
      title: "# Employees",
      dataType: DataType.Number,
      modClass: classNames(grid.item, grid["item-4x"])
    },
    {
      key: "avg_skill_gap",
      title: "Average Score",
      dataType: DataType.String,
      modClass: classNames(grid.item, grid["item-4x"]),
      component: AverageScore
    }
  ];

  const detailRows: TableDetailRows<FormattedPeopleByOrgUnit> = useMemo(
    () => ({
      component: IntermediateLevel,
      dataProperty: "inside",
      ...(formattedData?.length && {
        expandedRows: formattedData.reduce(
          (prev: any[], current: FormattedPeopleByOrgUnit) => [...prev, current.id],
          []
        )
      })
    }),
    [formattedData]
  );

  const handleChangeShowInactive = useCallback((value: boolean) => {
    setShowInactive(value);
  }, []);

  const extraFilter = useMemo(
    () => (
      <div className="ml-4 mr-auto flex item-center">
        <Switch value={showInactive} onChange={handleChangeShowInactive} />
        <span className="ml-2">{loc[showInactive ? "only_inactive_employee" : "only_active_employee"]}</span>
      </div>
    ),
    [showInactive, handleChangeShowInactive]
  );

  return formattedData?.length
    ? FirstLevelTable<FormattedPeopleByOrgUnit>({
        data: formattedData,
        columns,
        detailRows,
        modStyles: styles,
        pagination: true,
        pageSize: 25,
        additionalFilter: extraFilter
      })
    : null;
});
