import { logout } from "@common/features/user/model";
import { getPhrases } from "@common/helpers/localization";
import { Button } from "@ui";
import styles from "./titleline.module.scss";
import { $userStore } from "@common/api/store/user";

interface Props {
  name: string;
}

const loc = getPhrases("profile_page", "titleline");

export const Titleline = ({ name }: Props) => {
  const isFirstLogin = $userStore.isFirstLogin;
  return (
    <div className={styles.titleline}>
      <div className={styles["titleline-text"]}>
        {loc[isFirstLogin ? "origTitle" : "title"]}
        <span className={styles["titleline-name"]}> {name}</span>
      </div>
      <Button
        title={loc.button}
        theme="dark-blue"
        size="thin"
        className={styles["titleline-button"]}
        onClick={() => logout()}
      />
    </div>
  );
};
