import { AccountabilitiesForm } from "@common/types/forms/accountabilities";
import { AccountabilitiesCurrentSession } from "@common/types/reports/accountabilities/accountabilities";
import { AccountabilitiesSessionFormModal } from "@features/accountabilities/modals";
import { useMemo, useState } from "react";

interface Props {
  userName: string;
  currentSession: AccountabilitiesCurrentSession;
  memberId: number;
  isManager?: boolean;
  closeModal: () => void;
}

export const AccountabilitiesWrapper = ({ currentSession, closeModal, ...props }: Props) => {
  const [aptitudes, setAptitudes] = useState<AccountabilitiesForm[]>();

  useMemo(() => {
    if (currentSession && currentSession.accountabilities.length) {
      const aptitudes: AccountabilitiesForm[] = currentSession.accountabilities.reduce(
        (previous: AccountabilitiesForm[], current) => {
          return [
            ...previous,
            ...[
              {
                id: `${current.id}`,
                metrics: current.metrics,
                name: current.name,
                ...(current.results && { results: current.results })
              }
            ]
          ];
        },
        []
      );
      setAptitudes(aptitudes);
    }
  }, [currentSession]);

  return !!aptitudes?.length ? (
    <AccountabilitiesSessionFormModal aptitudes={aptitudes} isOpen={true} closeModal={closeModal} {...props} />
  ) : null;
};
