import { FC, ReactElement, useEffect, useState } from "react";
import classNames from "classnames";
// @ts-ignore
import { CKEditor as ReactCKEditor } from "@ckeditor/ckeditor5-react";
// @ts-ignore
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

interface ICKEditorProps {
  className?: string;
  value?: string;
  config?: any;
  helperText?: string | ReactElement;
  helperTextClass?: string;
  onChange?: (value: string) => void;
}

export const CKEditor: FC<ICKEditorProps> = ({
  className,
  value = "",
  config,
  helperText,
  helperTextClass,
  onChange = () => {}
}) => {
  const [text, setText] = useState(value);
  const [editor, setEditor] = useState();

  useEffect(() => {
    setText(value);
  }, [value]);

  const onEditorChange = (event: any, editor: any) => {
    const data = editor.getData();
    setText(data);
    onChange(data);
  };

  return (
    <div className={classNames("ckeditor-wrapper", className)}>
      <ReactCKEditor editor={ClassicEditor} data={text} config={config} onReady={setEditor} onChange={onEditorChange} />
      {helperText && <div className={helperTextClass}>{helperText}</div>}
    </div>
  );
};
