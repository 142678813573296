import { $assessmentsStore } from "@common/api/store/assessments";
import { AssessmentFormQuestion } from "@common/types/forms/assessments-employee";
import { AssessmentTypeEnum } from "@common/types/reports/assessments/common";
import { ProgressBar, RangeSlider, RangeSliderValueType } from "@ui";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { QuestionInfo } from "./question-info";
import styles from "./question-item.module.scss";

export interface QuestionItemProps {
  item: AssessmentFormQuestion;
  questionClass: string;
  skillClass: string;
  ratingClass: string;
  levelsMax: number;
  currentLevel?: number;
  category: string;
}

export const QuestionItem = ({
  item,
  questionClass,
  skillClass,
  ratingClass,
  levelsMax,
  currentLevel,
  category
}: QuestionItemProps) => {
  const [level, setLevel] = useState<number>(currentLevel ?? 0);
  useEffect(() => {
    const defaultValue = $assessmentsStore.getResultById(item.id);
    if (currentLevel !== undefined && Number.isInteger(currentLevel)) {
      $assessmentsStore.addResult(item.id, currentLevel);
      setLevel(currentLevel);
    } else if (defaultValue && Number.isInteger(defaultValue)) {
      setLevel(defaultValue);
    } else if (item.score?.[AssessmentTypeEnum.SelfAssessment]) {
      setLevel(item.score[AssessmentTypeEnum.SelfAssessment]);
      $assessmentsStore.addResult(item.id, item.score[AssessmentTypeEnum.SelfAssessment]);
    } else {
      setLevel(0);
    }
  }, [currentLevel, item.id, item.score]);

  const rangeSliderOnChange = (value: RangeSliderValueType) => {
    if (!Array.isArray(value)) {
      setLevel(value);
      $assessmentsStore.addResult(item.id, value);
      $assessmentsStore.setAssessmentCategorySetter(category, null);
    }
  };
  return (
    <div className={styles.item}>
      <div className={questionClass}>
        <div className={styles["question-title"]}>{item.name}</div>
        <div className={styles["question-text"]}>{item.description}</div>
      </div>
      <div className={skillClass}>
        <RangeSlider
          min={0}
          max={levelsMax}
          value={level}
          onChange={rangeSliderOnChange}
          className={styles["skill-row"]}
        />
        <ProgressBar
          progress={item.target * 10}
          type="line"
          modClass={classNames(styles["skill-row"], styles["skill-target"])}
        />
        {item.score?.[AssessmentTypeEnum.SelfAssessment] && (
          <ProgressBar
            progress={item.score?.[AssessmentTypeEnum.SelfAssessment] * 10}
            type="line"
            modClass={classNames(styles["skill-row"], styles["skill-self"])}
          />
        )}
      </div>
      <div className={ratingClass}>{level > 0 && <QuestionInfo level={level} />}</div>
    </div>
  );
};
