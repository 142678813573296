import React, { useEffect } from "react";
import {
  Profile,
  DashboardPageEmployee,
  PeoplePage,
  SettingsPage,
  SkillsPageEmployee,
  SkillsPage,
  AssessmentsPageEmployee,
  AssessmentsPageManager,
  LearnPage,
  CourseDetails,
  ActivityLogsPage,
  AdminCompaniesPage
} from "@pages";
import { getPhrases } from "@common/helpers/localization";
import { Roles } from "@common/constants";
import { LearningPath } from "../../pages/learning-path";
import { Reporting } from "../../pages/reporting";
import { ModulesList } from "@common/types/reports/profile/profile";

const loc = getPhrases();

export type Templates = "settings" | "default";

export type RolesRoutingType = {
  [key in Roles]: React.FC | null;
};

export type PageComponentType = React.FC | RolesRoutingType;

export interface RouteItem {
  route: string;
  text?: string;
  access?: Roles[];
  Component: PageComponentType;
  iconCode?: string;
  module?: ModulesList;
  disabled?: boolean;
}

interface AllRoutes {
  template: Templates;
  routes: RouteItem[];
}

export const rootRoutes: RouteItem[] = [
  {
    route: "dashboard",
    text: loc.menu?.dashboard,
    access: [Roles.employee],
    Component: DashboardPageEmployee
  },
  {
    route: "people",
    text: loc.menu?.["people"],
    access: [Roles.manager, Roles.clientAdmin, Roles.superAdmin],
    Component: () => <PeoplePage />
  },
  {
    route: "skills",
    text: loc.menu?.skills,
    Component: {
      [Roles.employee]: SkillsPageEmployee,
      [Roles.manager]: SkillsPage,
      [Roles.clientAdmin]: SkillsPage,
      [Roles.superAdmin]: SkillsPage
    }
  },
  {
    route: "assessments",
    text: loc.menu?.assessments,
    access: [Roles.employee, Roles.manager],
    Component: {
      [Roles.employee]: AssessmentsPageManager,
      [Roles.manager]: AssessmentsPageManager,
      [Roles.clientAdmin]: AssessmentsPageManager,
      [Roles.superAdmin]: AssessmentsPageManager
    }
  },
  {
    route: "reporting",
    text: loc.menu?.reporting,
    access: [Roles.manager, Roles.clientAdmin, Roles.superAdmin],
    Component: (() => <Reporting />) as React.FC<{}>
  },
  {
    route: "learn",
    text: loc.menu?.learn,
    Component: () => <LearnPage />,
    module: "learn"
  }
];

export const adminRoutes: RouteItem[] = [
  {
    route: "innovadmin/dashboard",
    text: loc.menu?.dashboard,
    access: [Roles.manager, Roles.clientAdmin, Roles.superAdmin],
    Component: AdminCompaniesPage,
    disabled: true
  },
  {
    route: "innovadmin/companies",
    text: loc.menu?.companies,
    access: [Roles.manager, Roles.clientAdmin, Roles.superAdmin],
    Component: AdminCompaniesPage
  }
];

export const settingsRoutes: RouteItem[] = [
  {
    route: "profile",
    Component: Profile,
    iconCode: "user",
    text: loc.menu?.profile
  },
  {
    route: "settings",
    access: [Roles.manager, Roles.clientAdmin, Roles.superAdmin],
    Component: SettingsPage,
    iconCode: "settings",
    text: loc.menu?.settings
  }
];

export const contentAdditionalRoutes: RouteItem[] = [
  {
    route: "learn/course-details/:id",
    text: loc.menu?.["course-details"],
    Component: () => <CourseDetails />
  },
  {
    route: "learn/learning-path/:id",
    text: loc.menu?.["course-details"],
    Component: LearningPath
  },
  {
    route: "activity-logs",
    text: loc.menu?.activity_logs,
    access: [Roles.clientAdmin],
    Component: ActivityLogsPage
  }
];

const allRootRoutes = rootRoutes.concat(contentAdditionalRoutes);

export const allRoutes: AllRoutes[] = [
  {
    routes: settingsRoutes,
    template: "settings"
  },
  {
    routes: allRootRoutes,
    template: "default"
  },
  {
    routes: adminRoutes,
    template: "default"
  }
];
