import React, { FC, useState } from "react";
import { ComplexModal, ComplexModalButtons } from "@ui";
import { ISkillForm } from "@common/types/skill-engine/skill";
import { SkillsForm } from "@features/skills/skills-form";
import { SavingError } from "@features/settings/blocks/roles/role-form/saving-error";
import { createSkill } from "@common/features/skills/model";
import { $userStore } from "@common/api/store/user";

interface AddSkillModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export const AddSkillModal: FC<AddSkillModalProps> = ({ isOpen, closeModal }) => {
  const [apiError, setApiError] = useState("");
  const [buttons, setButtons] = useState<ComplexModalButtons>();
  const [formData, setFormData] = useState<ISkillForm[]>();

  const onSubmit = async (data: ISkillForm[]) => {
    console.log("submit", data);
    const skills: ISkillForm[] = [];
    Promise.allSettled(
      data.map((item, index) => {
        return createSkill(item).catch(() => {
          skills.push(item);
        });
      })
    ).finally(() => {
      if (skills.length === 0) {
        closeModal();
        $userStore.setSkillUpdate(true);
      } else {
        setFormData(skills);
      }
    });
  };

  return (
    <ComplexModal
      isOpen={isOpen}
      closeModal={closeModal}
      shouldCloseOnOverlayClick={false}
      headerTitle="Add Skill"
      headerSecondRow={
        <p className="text-md uppercase font-normal opacity-80">Please enter the details of your skill below</p>
      }
      headerClass="pb-1"
      buttons={buttons}
    >
      <SkillsForm
        creating
        formData={formData}
        onSubmit={onSubmit}
        onClose={closeModal}
        onSetModalButtons={setButtons}
      />
      {apiError && (
        <SavingError
          className="absolute w-full h-full top-0 lef-0 z-100"
          message={apiError}
          onClose={() => setApiError("")}
        />
      )}
    </ComplexModal>
  );
};
