import { CategoryLine } from "@features/settings";
import { useState } from "react";
import { Roles } from "../roles";
import { AddRoleModal } from "../modals";

export const RolesCategoryLine = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const button = {
    title: "Add Role",
    onClick: () => setIsOpen(prev => !prev)
  };
  return (
    <>
      <CategoryLine title="Roles" content={<Roles />} button={button} />
      {isOpen && <AddRoleModal isOpen={isOpen} closeModal={() => setIsOpen(false)} />}
    </>
  );
};
