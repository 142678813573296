import React, { RefAttributes, useContext, useState } from "react";
import styles from "./tile-course-card.module.scss";
import classNames from "classnames";
import Grid from "@styles/modules/grid.module.scss";
import { Button, RatingWithNumber, Svg } from "@ui";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { Course } from "@features/learn/types";
import { getDurationHoursOrMinutes } from "@common/helpers/time-helpers";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { GlobalStore } from "@common/api/store/global";
import { Roles } from "@common/constants";

interface Props extends Course {
  link?: string;
  getComponentType?: (
    data: Course,
    child: React.ReactElement<any, any>
  ) => JSX.Element | { child: React.ReactElement<any, any> };
  handleClickEdit: (id: number) => void;
}

const TileCard: React.FC<Props> = observer(data => {
  const { $profileStore } = useContext(GlobalStore);
  const {
    id,
    authorName,
    authorImgSrc,
    rating,
    totalRatings,
    title,
    category,
    heroImgSrc,
    difficulty,
    duration,
    isFavourite,
    isCompleted,
    isFree,
    link,
    progress = 0,
    getComponentType,
    handleClickEdit
  } = data;

  const [isFavouriteCourse, setIsFavouriteCourse] = useState<boolean>(isFavourite ?? false);

  const toggleIsFavourite = () => {
    setIsFavouriteCourse(!isFavouriteCourse);

    //  Here we'll do the api work
  };

  return (
    <div className={styles.courseTileCardContainer}>
      <div className={classNames(Grid.list, styles.CourseTileCard)}>
        <div className={Grid["item-2x"]}>
          <div className={styles.imageContainer}>
            <img className={styles.courseImg} src={heroImgSrc} alt="" />
            <div className={styles.heartIcon}>
              <Svg
                onClick={() => {
                  toggleIsFavourite();
                }}
                name={isFavouriteCourse ? "heart-red" : "heart-gray"}
              />
            </div>
            {!!progress && (
              <div className={styles.progressbar}>
                <div className={styles.progressbarFiller} style={{ width: `${progress}%` }} />
              </div>
            )}
          </div>
        </div>
        <div className={classNames(Grid["item-3x"], styles.gameTitleAndDescriptionContainer)}>
          <Link to={`/learn/course-details/${data.id}`}>
            <div className={styles.gameTitleAndDescription}>
              {getComponentType
                ? getComponentType(
                    data,
                    <div className={styles.logoAndTitle}>
                      <img className={styles.logo} src={authorImgSrc} alt="" />
                      <p className={styles.title}>{category}</p>
                    </div>
                  )
                : ""}
              <div className={styles.description}>{title}</div>
            </div>
          </Link>
        </div>

        <div className={classNames(Grid["item-2x"], styles.ratingContainer)}>
          {(!!totalRatings || totalRatings === 0) && (!!rating || rating === 0) && (
            <div className={styles.rating}>
              <RatingWithNumber totalRatings={totalRatings} rating={rating} />
            </div>
          )}
        </div>
        <div className={classNames(Grid["item-2x"], styles.durationContainer)}>
          {duration && (
            <div className={styles.duration}>
              <div className={styles.durationIcon}>
                <CircularProgressbar
                  value={80}
                  strokeWidth={43}
                  backgroundPadding={7}
                  background
                  styles={buildStyles({
                    strokeLinecap: "Butt",
                    trailColor: "#fff",
                    pathColor: "#CDCDCD",
                    backgroundColor: "#fff"
                  })}
                />
              </div>
              <p className={classNames(styles.durationTime)}>{getDurationHoursOrMinutes(duration)}</p>
            </div>
          )}
        </div>
        <div className={classNames(Grid["item-3x"], styles.levelAndPriceContainer)}>
          <div className={styles.levelAndPrice}>
            <div className={styles.level}>{difficulty}</div>
            <div className={styles.btnContainer}>
              <Button
                type={"button"}
                appearance={"framed"}
                size={"thin"}
                theme={isFree ? "orange" : "red"}
                // FOR PAID
                // theme={'red'}
                onClick={() => {}}
                className={styles.ctaBtn}
              >
                {isFree ? "Free" : "Paid"}
              </Button>
              {$profileStore.currentRoleId === Roles.clientAdmin && (
                <Button
                  type={"button"}
                  appearance={"framed"}
                  size={"thin"}
                  theme={"blue"}
                  onClick={() => handleClickEdit(id)}
                  className={styles.ctaBtn}
                  title="Edit"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default TileCard;
