import React, { useState } from "react";
import styles from "./quiz-tab.module.scss";
import { Button, Svg } from "@ui";
import classNames from "classnames";
import { useTimer } from "react-timer-hook";

//    Quiz data
const quiz = {
  allowed_time_min: 5,
  allowed_retries_count: 3,
  min_passing_score_percent: 80,
  type: "Multi-answers",
  questions: {
    0: {
      question:
        "Of the following, if statements, which one correctly executes three instructions if the condition is true?",
      options: {
        a: "If (x < 0) a = b * 2; y = x; z = a – y;",
        b: "{ if (x < 0) a = b * 2; y = x; z = a – y; }",
        c: "If{ (x < 0) a = b * 2; y = x; z = a – y ; }",
        d: "If (x < 0) { a = b * 2; y = x; z = a – y; }"
      },
      answers: ["d"]
    },
    1: {
      question:
        "Which of the sets of statements below will add 1 to x if x is positive and subtract 1 from x if x is negative but leave x alone if x is 0?",
      options: {
        a: "If (x > 0) x++; else x--;",
        b: "If (x > 0) x++; else if (x < 0) x--;",
        c: "If (x == 0) x = 0; else x++; x--;",
        d: "X++; x--;"
      },
      answers: ["c"]
    },
    2: {
      question: "_______ is the process of finding errors and fixing them within a program.",
      options: {
        a: "Compiling",
        b: "Executing",
        c: "Debugging",
        d: "Scanning"
      },
      answers: ["c"]
    },
    3: {
      question:
        "Sal needs to execute a section of code ten times within a program. Compare the selection structures below and select which one meets the needs identified.",
      options: {
        a: "If-Else",
        b: "For",
        c: "While",
        d: "If"
      },
      answers: ["a"]
    },
    4: {
      question: "Which command will stop an infinite loop?",
      options: {
        a: "Alt - C",
        b: "Shift - C",
        c: "Esc",
        d: "Ctrl - C"
      },
      answers: ["d"]
    },
    5: {
      question: "During program development, software requirements specify",
      options: {
        a: " How the program will accomplish the task",
        b: "What the task is that the program must perform",
        c: "How to divide the task into subtasks",
        d: "How to test the program when it is done"
      },
      answers: ["b"]
    }
  }
};

type Props = {
  updateActiveTabIndex(index: number): void;
};

const QuizTab = ({ updateActiveTabIndex }: Props) => {
  const [isQuizStarted, setIsQuizStarted] = useState(false);
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [userSelectedAnswers, setUserSelectedAnswers] = useState<{ question: string; ans: string }[]>([]);
  const [isQuizReadyToSubmit, setIsQuizReadyToSubmit] = useState(false);

  const onTimeExpire = () => {
    window.alert("Time expired");
  };

  //    Timer Function
  const expiryTimestamp = new Date(Date.now() + quiz.allowed_time_min * 60 * 1000);
  const { seconds, minutes, hours, days, isRunning, start, pause, resume, restart } = useTimer({
    expiryTimestamp,
    onExpire: onTimeExpire
  });

  const resetQuiz = () => {
    //  Resetting all the fields
    setSelectedOption(null);
    setUserSelectedAnswers([]);
    setCurrentQuestion(0);
    setIsQuizReadyToSubmit(false);
  };

  const handleQuizStart = () => {
    // setQuizTime(new Date(Date.now() + quiz.allowed_time_min * 60 * 1000));
    restart(new Date(Date.now() + quiz.allowed_time_min * 60 * 1000));
    setIsQuizStarted(true);
    setIsQuizCompleted(false);
    resetQuiz();
  };

  const handleCancelQuiz = () => {
    setIsQuizStarted(false);
    setIsQuizCompleted(false);
    resetQuiz();
  };

  const goToNextQuestion = () => {
    console.log(currentQuestion, Object.keys(quiz.questions).length - 1);
    if (selectedOption && +currentQuestion < Object.keys(quiz.questions).length - 1) {
      setUserSelectedAnswers(prevState => [
        ...prevState,
        { question: currentQuestion.toString(), ans: selectedOption as string }
      ]);
      setSelectedOption(null);
      setCurrentQuestion(currentQuestion + 1);
    }

    if (selectedOption && +currentQuestion === Object.keys(quiz.questions).length - 1) {
      setIsQuizReadyToSubmit(true);
    }

    console.log(userSelectedAnswers);
  };

  const updateSelectedOption = (key: string) => {
    setSelectedOption(key);
  };

  const submitQuiz = () => {
    setIsQuizStarted(false);
    setIsQuizCompleted(true);

    // After submitting reset the quiz
    resetQuiz();
  };

  return (
    <div className={styles.quiz}>
      {/*     Left Section of the quiz    */}
      <div className={styles.left}>
        <h1 className={styles.heading}>{isQuizCompleted ? "Results breakdown" : "Test your knowledge"}</h1>

        {!isQuizCompleted && (
          <>
            <p className={styles.desc}>
              This is where we see if you can recall and put into practical use the information provided in the video.
              Please note that in order to start this quiz you must either complete the video or mark it as complete.
              <br />
              <br />
              Each of the questions will have an accompanying countdown of 60 seconds: failure to select the answer will
              mark as a failed question
            </p>

            {!isQuizStarted && (
              <Button onClick={handleQuizStart} className={styles["start-quiz-btn"]}>
                Start the Quiz
              </Button>
            )}
          </>
        )}

        {isQuizCompleted && (
          <div className={styles["stats-container"]}>
            {/*   Stat With details card    */}
            <div className={styles.stat}>
              {/*   Stats number  */}
              <h1 className={styles["stat-heading"]}>22</h1>

              {/*     Right Stats Details */}
              <div className={styles["stat-details"]}>
                <p>Questions</p>
                <p>Answered correctly</p>
              </div>
            </div>

            {/*   Stat With details card    */}
            <div className={styles.stat}>
              {/*   Stats number  */}
              <h1 className={styles["stat-heading"]}>01</h1>

              {/*     Right Stats Details */}
              <div className={styles["stat-details"]}>
                <p>Failed to answer</p>
                <p>Question in time</p>
              </div>
            </div>

            {/*   Stat With details card    */}
            <div className={styles.stat}>
              {/*   Stats number  */}
              <h1 className={styles["stat-heading"]}>22</h1>

              {/*     Right Stats Details */}
              <div className={styles["stat-details"]}>
                <p>Questions</p>
                <p>Answered incorrectly</p>
              </div>
            </div>

            {/*   Divider   */}
            <div className={styles.divider} />
            {/*   Stat With details card    */}
            <div className={styles.stat}>
              {/*   Stats number  */}
              <h1 className={classNames(styles["stat-heading"], styles.green)}>88%</h1>

              {/*     Right Stats Details */}
              <div className={classNames(styles["stat-details"], styles.green)}>
                <p>Overall</p>
                <p>Grade</p>
              </div>
            </div>
          </div>
        )}
      </div>

      {/*     Right Side of the Quiz */}
      <div className={styles.right}>
        {!isQuizStarted && !isQuizCompleted && (
          <>
            <h1 className={styles.heading}>Question 00 of 25</h1>
            <div>
              <h1 className={styles["heading-start-quiz"]}>Click “start the Quiz” to begin</h1>
            </div>
          </>
        )}

        {isQuizCompleted && (
          <div>
            <h1 className={styles.heading}>Complete!</h1>
            <h1 className={styles["result-heading"]}>
              You have <span className={styles.green}>Passed</span> this exam
            </h1>

            <div className={classNames(styles["stats-icon-container"], styles["green-bg"])}>
              <Svg name={"thumbs-up"} />
              {/*<Svg name={'thumbs-down'}/>*/}
            </div>

            <div className={styles["complete-quiz-cta"]}>
              <Button onClick={handleQuizStart} theme={"gray"} className={styles.btn}>
                Take Quiz again (2 tries left)
              </Button>
              <Button
                className={styles.btn}
                onClick={() => {
                  updateActiveTabIndex(2);
                }}
              >
                Go to notes
              </Button>
            </div>
          </div>
        )}

        {isQuizStarted && (
          <div>
            <div className={styles["quiz-header"]}>
              <h1 className={styles.heading}>
                Question {currentQuestion} of {Object.keys(quiz.questions).length - 1}
              </h1>

              <h2 className={styles["right-stats"]}>
                <span className={styles.red}>
                  {minutes} mins {seconds} seconds /{" "}
                </span>
                <span>{quiz.allowed_time_min} mins</span>
              </h2>
            </div>

            {/*<div className={styles['heading-secondary']}>*/}
            {/*  What does <span className={styles.red}>*/}
            {/*  myReactApp*/}
            {/*  </span> refer to in this command?*/}
            {/*</div>*/}

            {/*<div className={styles['create-app']}>*/}
            {/*  npx create-react-app myReactApp*/}
            {/*</div>*/}

            {/*     Quiz Heading  */}
            <div className={styles["heading-secondary"]}>
              {quiz.questions[currentQuestion as keyof typeof quiz.questions].question as string}
            </div>

            {/*     Quiz Options    */}
            <div className={styles.options}>
              {Object.entries(quiz.questions[currentQuestion as keyof typeof quiz.questions].options).map(
                ([key, val], index) => (
                  <div
                    onClick={() => {
                      updateSelectedOption(key);
                    }}
                    className={styles["option"]}
                  >
                    {/*     Left dot    */}
                    <div
                      className={classNames(
                        styles["dot"],
                        selectedOption === key ? styles["selected-dot"] : styles["non-selected-dot"]
                      )}
                    />
                    <div>{val}</div>
                  </div>
                )
              )}
            </div>

            <div className={styles["quiz-cta"]}>
              <Button onClick={handleCancelQuiz} className={styles.btn}>
                Cancel Quiz
              </Button>
              {!isQuizReadyToSubmit && (
                <Button onClick={goToNextQuestion} className={styles.btn}>
                  Next
                </Button>
              )}

              {isQuizReadyToSubmit && (
                <Button onClick={submitQuiz} className={styles.btn}>
                  Submit
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuizTab;
