import axios, { AxiosRequestConfig, AxiosResponse, Method } from "axios"; //
import { $API_URL } from "./../config";
import { $userStore } from "@common/api/store/user";
import { $commonStore } from "@common/api/store/common";

// TODO: expand api function

export const $api = axios.create({
  baseURL: $API_URL
});

$api.interceptors.request.use(config => {
  let token = localStorage.getItem("token");
  const impersonationToken = localStorage.getItem("impersonationToken");

  if (token && impersonationToken) {
    token = impersonationToken;
  } else if (!token && impersonationToken) {
    localStorage.getItem("impersonationToken");
  }
  (config.headers ??= {}).Authorization = `Bearer ${token}`;
  return config;
});

$api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("currentRoleId");
      $userStore.setAuth(false);
    }

    return Promise.reject(error);
  }
);

export class HttpService {
  static get<T>(url: string, option: AxiosRequestConfig = {}, hasSpinner = true) {
    return HttpService.request<T>("GET", url, { ...option }, hasSpinner);
  }

  static post<T>(url: string, body: any = {}, headers: any = {}, hasSpinner = true) {
    return HttpService.request<T>("POST", url, { data: body, headers }, hasSpinner);
  }

  static put<T>(url: string, body: any = {}, headers: any = {}, hasSpinner = true) {
    return HttpService.request<T>("PUT", url, { data: body, headers }, hasSpinner);
  }

  static patch<T>(url: string, body: any = {}, headers: any = {}, hasSpinner = true) {
    return HttpService.request<T>("PATCH", url, { data: body, headers }, hasSpinner);
  }

  static delete<T>(url: string, body: any = {}, headers: any = {}, hasSpinner = true) {
    return HttpService.request<T>("DELETE", url, { data: body, headers }, hasSpinner);
  }

  static request<T>(
    method: Method,
    url: string,
    options: AxiosRequestConfig,
    hasSpinner = true
  ): Promise<AxiosResponse<T>> {
    let loadingId = "";
    if (hasSpinner) {
      loadingId = $commonStore.setLoading();
    }

    return $api
      .request<T>({
        ...options,
        method,
        url
      })
      .finally(() => {
        if (loadingId) {
          $commonStore.finishLoading(loadingId);
        }
      });
  }
}
