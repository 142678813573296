import { TableCellProps } from "@common/types/table";
import { useState } from "react";
import { OfficeInfoModal } from "@features/settings/blocks/offices";
import styles from "./office-info.module.scss";

interface OfficeProps extends TableCellProps {}

export const OfficeInfo: React.FC<OfficeProps> = ({ props }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { rowData, column, value } = props;

  return (
    <>
      <div className={styles.office}>
        <div className={styles.name} onClick={() => setIsOpen(true)}>
          {(column.title && value) || "Edit"}
        </div>
      </div>
      <OfficeInfoModal closeModal={() => setIsOpen(false)} isOpen={isOpen} id={rowData.id} rowData={rowData} />
    </>
  );
};
