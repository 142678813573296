import React, { FC, useEffect, useState } from "react";
import * as yup from "yup";
import classNames from "classnames";
import { useForm } from "@common/hooks/form";
import { IRoleForm } from "@common/types/forms/role";
import { ButtonGroup, Checkbox, FormWrapper, Switch } from "@ui";
import { IRoleFormTabInitData } from "../index";
import { FormError } from "../form-error";

export interface IRoleAssessmentsTabProps {
  formData: Partial<IRoleForm>;
  onInit?: (data: IRoleFormTabInitData) => void;
  onBack(): void;
  onNext(): void;
  onSubmit(data: Partial<IRoleForm>): void;
  onClose(): void;
}

interface IRoleAssessmentsForm {
  assessmentIntervalEnabled?: boolean;
  overallAssessmentInterval: number;
  selfAssessmentOn: boolean;
  managerAssessmentOn: boolean;
  peerAssessmentOn: boolean;
}

const intervalOptions = [
  { label: "None", value: 0 },
  { label: "3 months", value: 3 },
  { label: "6 months", value: 6 },
  { label: "1 year", value: 12 }
];

const assessmentsFormSchema: yup.SchemaOf<IRoleAssessmentsForm> = yup.object().shape({
  assessmentIntervalEnabled: yup.boolean(),
  overallAssessmentInterval: yup.number().required(),
  selfAssessmentOn: yup.boolean().required(),
  managerAssessmentOn: yup.boolean().required(),
  peerAssessmentOn: yup.boolean().required()
});

export const RoleAssessmentsTab: FC<IRoleAssessmentsTabProps> = ({
  formData,
  onInit,
  onBack,
  onNext,
  onSubmit,
  onClose
}) => {
  const methods = useForm<IRoleAssessmentsForm>({
    schema: assessmentsFormSchema,
    defaultValues: {
      assessmentIntervalEnabled: formData.assessmentIntervalEnabled || false,
      overallAssessmentInterval: formData.assessmentIntervalEnabled ? formData.overallAssessmentInterval : 0,
      selfAssessmentOn: formData.assessmentIntervalEnabled || false,
      managerAssessmentOn: formData.assessmentIntervalEnabled || false,
      peerAssessmentOn: formData.peerAssessmentOn || false
    }
  });
  const data = methods.getValues();
  const [timestamp, setTimestamp] = useState<number>();
  const [showFormError, setShowFormError] = useState(false);

  const interval = intervalOptions.find(item => item.value === data.overallAssessmentInterval);

  useEffect(() => {
    if (onInit) {
      if (showFormError) {
        onInit({ buttons: undefined });
      } else {
        onInit({
          buttons: {
            leftSide: {
              title: (
                <span>
                  Cancel
                  <br />
                  And Close
                </span>
              ),
              theme: "secondary-gradient",
              className: "!w-30 !h-14 !text-sm !text-left !font-semibold",
              onClick: onClose
            },
            rightSide: [
              {
                title: "Prev",
                theme: "warning",
                shape: "left-arrow",
                className: "!w-20 !text-sm !font-semibold",
                onClick() {
                  goBack();
                }
              },
              {
                title: "Next",
                theme: "warning",
                shape: "right-arrow",
                className: "!w-20 !text-sm !font-semibold",
                onClick() {
                  methods.handleSubmit(handleSuccess, handleError)();
                }
              }
            ]
          }
        });
      }
    }
  }, [onInit, showFormError]);

  const onFieldChange = (field: keyof IRoleAssessmentsForm, value: any) => {
    if (field === "overallAssessmentInterval") {
      methods.setValue("assessmentIntervalEnabled", value > 0);
      methods.setValue("managerAssessmentOn", value > 0);
      methods.setValue("selfAssessmentOn", value > 0);
    }

    methods.setValue(field, value);
    methods.trigger(field);
    setTimestamp(new Date().getTime());
  };

  const handleSuccess = (values: IRoleAssessmentsForm) => {
    onSubmit(values);
    onNext();
  };

  const handleError = () => {
    setShowFormError(true);
  };

  const goBack = () => {
    onSubmit(methods.getValues());
    onBack();
  };

  return (
    <FormWrapper className="text-black" onSubmit={methods.handleSubmit(handleSuccess, handleError)}>
      <div className="grid sm:grid-cols-2 gap-4 border-b border-gray-cd px-5 pb-4">
        <div>
          <h6 className="text-primary text-sm font-bold mb-1">
            <i className="fa fa-caret-down" /> This is where you set up your skills assessments
          </h6>
          <p className="text-xs">
            In order to monitor training progress, each Role will be assessed on a regular basis - quarterly,
            semiannually or annually. Once the basic interval between assessments is set, you may then choose to opt out
            of the three types of assessment (Self, Manager and Peer assessments)
          </p>
        </div>
        <div className="flex items-center ml-auto">
          <div className="text-secondary text-sm font-normal mr-3">
            Skill Assessments are&nbsp;
            <b className={data.assessmentIntervalEnabled ? "text-primary" : ""}>
              {data.assessmentIntervalEnabled ? "active" : "inactive"}
            </b>
          </div>
          <Switch readonly theme="primary" value={data.assessmentIntervalEnabled} />
        </div>
      </div>

      <div className="min-h-70 grid xs:grid-cols-2 md:!grid-cols-4 gap-x-6 gap-y-6 px-5 pt-4">
        <div>
          <div className="text-xs text-gray-97 font-semibold">Skills Assessment Interval</div>
          <ButtonGroup
            className="mt-1"
            buttons={intervalOptions.map(item => ({
              ...item
            }))}
            buttonClass="italic"
            value={data.overallAssessmentInterval}
            onChange={value => onFieldChange("overallAssessmentInterval", value)}
          />
          <p className="text-xs text-secondary italic mt-3">
            This sets the interval for all skills assessment for this role
          </p>
        </div>
        <div>
          <div className="text-xs text-gray-97 font-semibold">Self Skills Assessment Interval</div>
          <div className="flex items-center">
            <span
              className={classNames(
                "flex-shrink-0 text-xs text-black leading-none bg-gray-cd rounded p-2 mt-1 mr-2",
                !data.selfAssessmentOn && "bg-opacity-30 text-opacity-20"
              )}
            >
              {interval?.label || "-"}
            </span>
            <Checkbox
              className="italic mt-1"
              readonly
              size="sm"
              theme="success"
              label={data.selfAssessmentOn ? "Self assessment ON" : "Self assessment OFF"}
              value={data.selfAssessmentOn}
              disabled={!data.assessmentIntervalEnabled}
            />
          </div>
          <p className="text-xs text-secondary italic mt-3">
            Self Skills Assessment is available automatically and immediately on hiring
          </p>
        </div>
        <div>
          <div className="text-xs text-gray-97 font-semibold">Manager Skills Assessment Interval</div>
          <div className="flex items-center">
            <span
              className={classNames(
                "flex-shrink-0 text-xs text-black leading-none bg-gray-cd rounded p-2 mt-1 mr-2",
                !data.managerAssessmentOn && "bg-opacity-30 text-opacity-20"
              )}
            >
              {interval?.label || "-"}
            </span>
            <Checkbox
              className="italic mt-1"
              readonly
              size="sm"
              theme="success"
              label={data.managerAssessmentOn ? "Manager assessment ON" : "Manager assessment OFF"}
              value={data.managerAssessmentOn}
              disabled={!data.assessmentIntervalEnabled}
            />
          </div>
          <p className="text-xs text-secondary italic mt-3">
            Manager Skills Assessment is available on employee hiring or on subsequent cycles
          </p>
        </div>
        <div>
          <div className="text-xs text-gray-97 font-semibold">Peer Skills Assessment Interval</div>
          <div className="flex items-center">
            <span
              className={classNames(
                "flex-shrink-0 text-xs text-black leading-none bg-gray-cd rounded p-2 mt-1 mr-2",
                !data.peerAssessmentOn && "bg-opacity-30 text-opacity-20"
              )}
            >
              {interval?.label || "-"}
            </span>
            <Checkbox
              className="italic mt-1"
              size="sm"
              theme="success"
              label={data.peerAssessmentOn ? "Peer assessment ON" : "Peer assessment OFF"}
              value={data.peerAssessmentOn}
              disabled
              onChange={value => onFieldChange("peerAssessmentOn", value)}
            />
          </div>
          <p className="text-xs text-secondary italic mt-3">
            Peer Skills Assessment is available on employee hiring or on subsequent cycle
          </p>
        </div>
      </div>

      {showFormError && (
        <FormError className="w-full h-full absolute top-0 left-0 z-100" onClose={() => setShowFormError(false)} />
      )}
    </FormWrapper>
  );
};
