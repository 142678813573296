import React from "react";
import styles from "./course-card.module.scss";
import { Titles } from "@ui";
import { getPhrases } from "@common/helpers/localization";
import classNames from "classnames";
import { learningPath } from "./types";

export const CourseCard: React.FC<learningPath> = ({ id, name, imgSrc, status, author, date, desc, learners }) => {
  const getStatus = () => {
    switch (status) {
      case "in-progress": {
        return loc_courses_list_block.status_in_progress;
      }
      case "not-started": {
        return loc_courses_list_block.status_not_stated;
      }
      case "completed": {
        return loc_courses_list_block.status_completed;
      }
    }
  };

  const loc_courses_list_block = getPhrases("learning_path_page", "courses_list_block");
  return (
    <div className={styles["course-card"]}>
      <div className={styles["status"]}>
        {/*<Svg*/}
        {/*    modClass={styles.icon}*/}
        {/*     name={getStatusIconName(status)}*/}
        {/*/>*/}

        <p className={classNames(styles.text, styles[status])}>{getStatus()}</p>
      </div>

      <div className={styles["course-img-container"]}>
        <img className={styles["course-img"]} src={imgSrc} alt={name} />
        <div className={styles.course}>
          <div className={styles["title"]}>{loc_courses_list_block.course}</div>
        </div>
      </div>

      <div className={styles["course-details"]}>
        <div>
          <Titles modClass={styles["title"]} level={"level_3"}>
            {name}
          </Titles>
          <p className={styles.about}>
            {loc_courses_list_block.by}: {author}
            {date && ` . ${date}`}
          </p>
        </div>

        <p className={styles.desc}>{desc}</p>

        <p className={styles.learners}>
          {learners && learners >= 10 ? learners : `0 ${learners !== null ? learners : ""}`}{" "}
          {loc_courses_list_block.learners}
        </p>
      </div>
    </div>
  );
};
