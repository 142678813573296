import React, { useState } from "react";
import classNames from "classnames";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { getPhrases } from "@common/helpers/localization";
import { SkillOverviewData } from "@features/reporting/report-overview/report-overview.mock";
import { Analytics } from "@common/types/reports/analytics/analytics";
import grid from "@styles/modules/grid.module.scss";
import tabs from "@styles/modules/tabs.module.scss";
import { FirstLevelTable, Titles } from "@ui";
import { skillOverviewColumns, skillTabItems } from "../model";
import styles from "../report-overview.module.scss";

interface SkillOverviewProps {
  route: string;
}

export const SkillOverview: React.FC<SkillOverviewProps> = () => {
  const [loc] = useState(getPhrases("reporting_page", "overview"));
  const [data] = useState<Analytics[]>(SkillOverviewData);

  return (
    <div className={classNames(grid.container, styles["page-container"])}>
      <Titles level="level_3" modClass={styles.title}>
        {loc.bySkillTitle} {loc.reporting}
      </Titles>
      <p className={styles.description}>{loc.description}</p>
      <div className={styles["kpi-box-wrapper"]}>
        <div className={styles["kpi-box"]}>
          <Titles level="level_3" modClass={styles["kpi-box-title"]}>
            12
          </Titles>
          <p className={styles["kpi-box-description"]}>Team members below average</p>
        </div>
        <div className={styles["kpi-box-divider"]} />
        <div className={styles["kpi-box"]}>
          <Titles level="level_3" modClass={styles["kpi-box-title"]}>
            06
          </Titles>
          <p className={styles["kpi-box-description"]}>Roles over-performing</p>
        </div>
        <div className={styles["kpi-box-divider"]} />
        <div className={styles["kpi-box"]}>
          <Titles level="level_3" modClass={styles["kpi-box-title"]}>
            03
          </Titles>
          <p className={styles["kpi-box-description"]}>Org unit under-performing</p>
        </div>
        <div className={styles["kpi-box-divider"]} />
        <div className={styles["kpi-box"]}>
          <Titles level="level_3" modClass={styles["kpi-box-title"]}>
            22
          </Titles>
          <p className={styles["kpi-box-description"]}>Skills with top level scoring</p>
        </div>
      </div>
      <div>
        {FirstLevelTable<Analytics>({
          data,
          columns: skillOverviewColumns,
          modStyles: styles,
          pagination: true
        })}
      </div>
      <Tabs className={tabs.tabs} selectedTabClassName={tabs["tab-selected"]}>
        <TabList controls={false} className={classNames(tabs.list, styles["overview-tab-wrapper"])}>
          {skillTabItems.map(teamName => (
            <Tab key={teamName.key} className={classNames(tabs.tab, styles["overview-tab-item"])}>
              {teamName.title}
            </Tab>
          ))}
        </TabList>
        {skillTabItems.map(teamName => (
          <TabPanel key={teamName.key} className={tabs.panel} />
        ))}
      </Tabs>
    </div>
  );
};
