import classNames from "classnames";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import styles from "./personal.module.scss";
import grid from "@styles/modules/grid.module.scss";
import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ComplexModal, Svg } from "@ui";
import { getLastAssessmentResult } from "@common/features/assessments/model";
import {
  EmployeeCurrentAssessment,
  EmployeeCurrentAssessmentItem,
  EmployeeCurrentAssessmentStatus
} from "@common/types/reports/assessments/employee-current-assessment";
import { EmployeeCurrentAssessmentButton } from "./employee-current-assessment-button";
import { DetailsEmployee, SelfAssessmentsModal } from "@features/dashboard";
import { AssessmentTypeEnum } from "@common/types/reports/assessments/common";
import { ProfileInterface } from "@common/types/reports/profile/profile";
import { getCroppedImg, getImage, readFile } from "@common/helpers/canvas-helpers";
import { getProfile, setPeopleProfilePhoto } from "@common/features/profile/model";
import { AccountabilitiesWrapper } from "@features/accountabilities/modals/accountabilities-form-modal/wrapper";
import { EmployeeCurrentAssessmentButtonInterface } from "./types";
import { AccountabilitiesCurrentSession } from "@common/types/reports/accountabilities/accountabilities";
import { getCurrentAccountabilitiesSession } from "@common/features/accountabilities/model";
import { GlobalStore } from "@common/api/store/global";

interface Props {
  information: ProfileInterface;
}

export const Personal = ({ information }: Props) => {
  const { $profileStore } = useContext(GlobalStore);
  const [imageSrc, setImageSrc] = useState<any>(null);
  const [crop, setCrop] = useState<Crop | undefined>();
  const [croppedImage, setCroppedImage] = useState("");
  const [isEditLogo, setIsEditLogo] = useState(false);
  const [lastAssessmentInfo, setLastAssessmentInfo] = useState<EmployeeCurrentAssessment>();
  const [currentAccountabilitiesSession, setCurrentAccountabilitiesSession] =
    useState<AccountabilitiesCurrentSession>();
  const fileInput = useRef<HTMLInputElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    getLastAssessmentResult(setLastAssessmentInfo);
  }, []);

  useEffect(() => {
    getCurrentAccountabilitiesSession(Number(information.id), setCurrentAccountabilitiesSession);
  }, []);

  const isFinished: boolean = useMemo(() => {
    if (currentAccountabilitiesSession) {
      const finishedQuestions = currentAccountabilitiesSession?.accountabilities.filter(
        item => item.results?.current?.self
      );
      return currentAccountabilitiesSession?.accountabilities.length === finishedQuestions.length;
    }
    return false;
  }, [currentAccountabilitiesSession]);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const openAvatarModal = async () => {
    setImageSrc(information?.avatar_url);
    if (information?.avatar_url) {
      const img = await getImage(information.avatar_url || "");
      if (img) {
        const { image } = img;
        setCropArea(image.width, image.height);
      }
    }
    setIsEditLogo(true);
  };
  const showCroppedImage = useCallback(async () => {
    try {
      const image = await getCroppedImg(imageSrc, crop, 0);
      if (image) {
        const { url: croppedImage, file } = image;
        setCroppedImage(croppedImage);
        const imageFile = new File([file], "newPhoto.jpg", {
          type: "image/jpg"
        });
        if (information?.id) {
          const res = await setPeopleProfilePhoto(+information.id, imageFile);
          $profileStore.setProfile({
            ...information,
            avatar_url: res?.data.url
          });
          getProfile();
        }
      }
    } catch (e) {
      console.error(e);
    }
    setIsEditLogo(false);
  }, [imageSrc, crop]);

  const onClickSelectFile = () => {
    fileInput.current?.click();
  };

  const setCropArea = useCallback((width: number, height: number) => {
    const minLength = Math.min(width, height);
    if (minLength > 300) {
      setCrop({
        width: 300 / (width / 100),
        x: (width - 300) / (width / 100) / 2,
        height: 300 / (height / 100),
        y: (height - 300) / (height / 100) / 2,
        unit: "%"
      });
    } else {
      setCrop({
        width: minLength / (width / 100),
        x: (width - minLength) / (width / 100) / 2,
        height: minLength / (height / 100),
        y: (height - minLength) / (height / 100) / 2,
        unit: "%"
      });
    }
  }, []);

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);

      await new Promise(res => setTimeout(() => res(""), 500));
      if (imageRef.current) {
        const { width, height } = imageRef.current;
        setCropArea(width, height);
      }
    }
  };

  const onCloseLogoEdit = useCallback(() => {
    setIsEditLogo(false);
    setCroppedImage("");
  }, []);

  const [accountabilitiesModalIsOpen, setAccountabilitiesModalStatus] = useState<boolean>(false);
  const openAccountabilitiesModal = () => {
    setAccountabilitiesModalStatus(true);
  };
  const closeAccountabilitiesModal = () => {
    setAccountabilitiesModalStatus(false);
    getCurrentAccountabilitiesSession(Number(information.id), setCurrentAccountabilitiesSession);
  };

  const assessmentsSessionButtons: EmployeeCurrentAssessmentButtonInterface[] = useMemo(() => {
    const getOrder = (type: number): number => {
      switch (Number(type)) {
        case AssessmentTypeEnum.ManagerAssessment:
          return 1;
        case AssessmentTypeEnum.SelfAssessment:
          return 3;
        default:
          return 0;
      }
    };

    const getOnclick = (status: EmployeeCurrentAssessmentStatus): (() => void) | null => {
      return openModal;
      // switch (status) {
      //   case "pending":
      //     return openModal;
      //   default:
      //     return null;
      // }
    };

    // TODO: refactoring and optimize buttons block. May need to decompose

    const assessmentInfo =
      lastAssessmentInfo?.assessments ||
      ([
        {
          type: { id: 1, name: "Self Assessment" },
          status: "denied"
        },
        {
          type: { id: 3, name: "Manager Assessment" },
          status: "denied"
        }
      ] as EmployeeCurrentAssessmentItem[]);
    const assessmentsSessionButtonsDefault: EmployeeCurrentAssessmentButtonInterface[] = assessmentInfo.reduce(
      (previous: EmployeeCurrentAssessmentButtonInterface[], current) => {
        const onClick = getOnclick(current.status);
        return [
          ...previous,
          {
            item: current,
            order: getOrder(current.type.id),
            prefix: "skills",
            label:
              Number(current.type.id) === AssessmentTypeEnum.ManagerAssessment
                ? "Skills Manager Assessment"
                : "Skills Self Assesment",
            ...(onClick && { onClick })
          }
        ];
      },
      []
    );

    return [...assessmentsSessionButtonsDefault].sort(function (a, b) {
      return a.order - b.order;
    });
  }, [lastAssessmentInfo?.assessments]);

  const accountabilitiesSessionButtons: EmployeeCurrentAssessmentButtonInterface[] = [
    {
      item: {
        status: "denied" as const,
        type: {
          id: AssessmentTypeEnum.ManagerAssessment,
          name: "Manager Assessment" as const
        }
      },
      prefix: "accountabilities",
      order: 4,
      label: "Accountabilities Manager Assessment"
    },
    {
      item: {
        status: currentAccountabilitiesSession && !isFinished ? ("pending" as const) : ("denied" as const),
        type: {
          id: AssessmentTypeEnum.SelfAssessment,
          name: "Self Assessment" as const
        }
      },
      prefix: "accountabilities",
      order: 5,
      ...(currentAccountabilitiesSession && !isFinished && { onClick: openAccountabilitiesModal }),
      label: "Accountabilities Self Assessment"
    }
  ];

  const userName = `${information?.first_name ? `${information.first_name} ` : ``}${information?.last_name}`;

  return (
    <>
      <div className={classNames(grid["page-row"], styles.row)}>
        <div className={grid.root}>
          <div className={grid.container}>
            <div className={classNames(styles.item, grid.list)}>
              {(croppedImage || information?.avatar_url || information.company.logo_url) && (
                <div className={classNames(grid.item, styles["logo-block"])}>
                  <span className={styles["logo-edit-btn"]} onClick={openAvatarModal}>
                    <Svg name="edit" />
                  </span>
                  <span className={styles.backdrop} />
                  <div
                    className={styles.logo}
                    style={{
                      backgroundImage: `url(${
                        (croppedImage ? croppedImage : information?.avatar_url) || information?.company?.logo_url
                      })`
                    }}
                  />
                </div>
              )}
              <DetailsEmployee
                information={information}
                {...(lastAssessmentInfo?.report_card_url && {
                  report: lastAssessmentInfo.report_card_url
                })}
              />
              <div className={classNames(grid.item, styles.assessments)}>
                <div className={styles["assessments-column"]}>
                  {!!assessmentsSessionButtons.length &&
                    assessmentsSessionButtons.map((item, index) => {
                      return (
                        <EmployeeCurrentAssessmentButton
                          item={item.item}
                          key={`${item.prefix}-${item.item.type.id}`}
                          {...(item.onClick && { onClick: item.onClick })}
                          {...(item.label && { label: item.label })}
                        />
                      );
                    })}
                </div>
                <div className={styles["assessments-column"]}>
                  {accountabilitiesSessionButtons.map((item, index) => (
                    <EmployeeCurrentAssessmentButton
                      item={item.item}
                      key={`${item.prefix}-${item.item.type.id}`}
                      {...(item.onClick && { onClick: item.onClick })}
                      {...(item.label && { label: item.label })}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpen && (
        <SelfAssessmentsModal
          {...(information?.current_role?.name ? { role: information?.current_role?.name } : {})}
          closeModal={closeModal}
        />
      )}
      {isEditLogo && (
        <ComplexModal
          isOpen
          closeModal={onCloseLogoEdit}
          bodyClass="py-0"
          shouldCloseOnOverlayClick={false}
          headerTitle="Edit & Upload photo"
          headerClass="pb-1"
          modClass="w-[500px]"
          buttons={{
            leftSide: {
              title: "Open File",
              theme: "blue",
              className: "!w-30 !h-10 !text-sm !text-left !font-semibold",
              onClick: onClickSelectFile
            },
            rightSide: [
              {
                title: <span>Save & Upload</span>,
                theme: "orange",
                className: "!w-30 !h-10 !text-sm !text-left !font-semibold",
                disabled: !imageSrc,
                onClick: showCroppedImage
              }
            ]
          }}
        >
          <input ref={fileInput} type="file" className="hidden" onChange={onFileChange} />
          <ReactCrop
            className={styles["logo-crop-container"]}
            renderSelectionAddon={() => <div className={styles["logo-crop-selection"]} />}
            aspect={1}
            crop={crop}
            keepSelection
            circularCrop
            disabled={!imageSrc}
            onChange={(c, pc) => setCrop(pc)}
            onComplete={(c, pc) => setCrop(pc)}
          >
            {imageSrc ? (
              <img ref={imageRef} src={imageSrc} className="w-full" />
            ) : (
              <span className={styles.center}>Please select image file</span>
            )}
          </ReactCrop>
          <p className="px-3 text-left">
            We ask you to upload a real picture of yourself to allow your manager and coworkers to easily navigate the
            organizational structure
          </p>
        </ComplexModal>
      )}
      {accountabilitiesModalIsOpen && currentAccountabilitiesSession && !isFinished && (
        <AccountabilitiesWrapper
          memberId={Number(information.id)}
          userName={userName}
          currentSession={currentAccountabilitiesSession}
          closeModal={closeAccountabilitiesModal}
        />
      )}
    </>
  );
};
