import titlesStyles from "@styles/modules/common/titles.module.scss";
import colorsStyles from "@styles/modules/common/text-colors.module.scss";

export const AccountabilitiesLegend = () => {
  return (
    <>
      <div className={titlesStyles.title}>Role-based Accountabilities</div>
      <div className={titlesStyles.description}>
        Self Score in <span className={colorsStyles.lightorange}>ORANGE</span> / Manager score in{" "}
        <span className={colorsStyles.cornflowerblue}>BLUE</span> / Orange Block ={" "}
        <span className={colorsStyles.lightorange}>NEXT ASSESSMENT</span> / Blue block ={" "}
        <span className={colorsStyles.cornflowerblue}>CURRENT MONTH</span>
        <br />
        RATING SCALE: A - Met or exceeded expectations / B - Fell somewhat short of expectations / C - Fell far short of
        expectations
      </div>
    </>
  );
};
