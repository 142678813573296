import classNames from "classnames";
import React, { ReactElement } from "react";
import styles from "./flat-button.module.scss";

// TODO: Add button attrs from default

export interface ButtonProps {
  title?: string | ReactElement;
  appearance?: "framed" | "solid" | "bordered";
  type?: "button" | "submit" | "reset";
  size?: "standart" | "thin";
  theme?:
    | "orange"
    | "gray"
    | "blue"
    | "dark-blue"
    | "red"
    | "dark-gray"
    | "warning"
    | "secondary-gradient"
    | "danger"
    | "green";
  shape?: "rect" | "left-arrow" | "right-arrow";
  className?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
}

export const FlatButton: React.FC<ButtonProps> = ({
  appearance = "framed",
  type = "button",
  title,
  className,
  size = "standart",
  theme = "orange",
  shape = "rect",
  onClick,
  children,
  disabled = false
}) => {
  const buttonClass = classNames(
    styles.wrapper,
    styles[`appearance-${appearance}`],
    styles[`size-${size}`],
    styles[`theme-${theme}`],
    styles[`shape-${shape}`],
    disabled && styles.disabled,
    className
  );
  const contentClass = classNames(styles.content);

  return (
    <button className={buttonClass} onClick={onClick} type={type} {...(disabled && { disabled })}>
      <span className={contentClass}>{children ?? title}</span>
    </button>
  );
};
