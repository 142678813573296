import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { FormInputAutocomplete, FormWrapper } from "@ui";
import { useForm } from "@common/hooks/form";
import { ILearnPathForm } from '@common/types/forms/learn';
import { ILearnPathFormTabInitData } from "../index";
import { FormError } from "../form-error";
import { ResourceDeleteConfirm } from "@features/learn/learn-path-form/resource-delete-confirm";
import { getPhrases } from "@common/helpers/localization";
import { formatDuration } from "../../../../utils/helpers";
import { getResources } from "@common/features/learn/model";
import { CourseItemInterface } from "@common/types/reports/learn/learn";
import { getSkillCategories } from "@common/features/skills/model";
import { SkillCategory } from "@common/types/skill-engine/skill-category";

export interface IPathResourcesTabProps {
  formData: Partial<ILearnPathForm>;
  onInit?: (data: ILearnPathFormTabInitData) => void;
  onBack(): void;
  onNext(data: Partial<ILearnPathForm>): void;
  onSubmit(data: Partial<ILearnPathForm>): void;
  onClose(): void;
}

interface IPathResourcesForm {
  resourceName?: string;
  resources: {
    id: number;
    name: string;
    category: string;
    skills: string[];
    type: string;
    duration: number;
    expertiseLevel: string;
  }[];
}

interface IResource {
  id: number;
  name: string;
  category?: string;
  type?: string;
  duration?: number;
  expertiseLevel?: string;
  children?: IResource[];
}

const loc = getPhrases('common', 'forms').validation;

const resourcesFormSchema: yup.SchemaOf<IPathResourcesForm> = yup
  .object()
  .shape({
    resourceName: yup.string(),
    resources: yup.array().of(
      yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required(loc.required),
        category: yup.string().required(loc.required),
        type: yup.string().required(loc.required),
        duration: yup.number().required(loc.required),
        skills: yup.array(),
        expertiseLevel: yup.string().required(loc.required),
      })
    ).min(1),
  });

export const LearnResourcesTab: FC<IPathResourcesTabProps> = ({
  formData,
  onInit,
  onBack,
  onNext,
  onSubmit,
  onClose,
}) => {
  const methods = useForm<IPathResourcesForm>({
    schema: resourcesFormSchema,
    defaultValues: {
      resourceName: '',
      resources: formData.resources || [],
    },
  });
  const data = methods.getValues();
  const [curPage, setCurPage] = useState<number>(1);
  const [resources, setResources] = useState<CourseItemInterface[]>([]);
  const [categories, setCategories] = useState<{ [key: string]: string }>({});
  const [timestamp, setTimestamp] = useState<number>();
  const [deletingResource, setDeletingResource] = useState<IResource>();
  const [showFormError, setShowFormError] = useState(false);

  const onLoadMore = useCallback(() => {
    getResources((fetchingResources: CourseItemInterface[]) => {
      setResources((prev) => [...prev, ...fetchingResources]);
      setCurPage((prev) => prev + 1)
    }, `page=${curPage}&per-page=30`);
  }, [curPage]);

  useEffect(() => {
    onLoadMore();
    getSkillCategories((categories: SkillCategory[]) => {
      const parentCategories = categories
        .filter((category) => !category.parent_id)
        .reduce((prev, category) => ({ ...prev, [category.id]: category.name }), {} as { [key: string]: string });
      setCategories(categories
        .filter((category) => category.parent_id)
        .reduce((prev, category) => ({
          ...prev, [category.name]: category.parent_id ? parentCategories[category.parent_id] : ''
        }), {} as { [key: string]: string }));
    });
  }, []);

  const groups: string[] = [];
  data.resources.forEach((item) => {
    if (!groups.includes(item.category)) {
      groups.push(item.category);
    }
  });

  const filteredResources = useMemo(() => {
    const selectedResources = data.resources.map((item) => item.id);
    const options = resources.reduce((prev, resource) => {
      if (selectedResources.includes(resource.id)) {
        return prev;
      }

      const res = {
        id: resource.id,
        category: resource.skill_categories.common,
        skills: resource.skills,
        name: resource.title,
        type: resource.content_type.name,
        duration: resource.duration,
        expertiseLevel: resource.expertise_level.name,
      }

      if (prev[res.category]) {
        return {
          ...prev,
          [res.category]: {
            ...prev[res.category],
            children: [...prev[res.category].children, res]
          }
        }
      }
      return {
        ...prev,
        [res.category]: {
          name: res.category,
          value: res.category,
          children: [res]
        }
      }
    }, {} as { [key: string]: { name: string, value: any, children: any[] } });

    return Object.values(options)
  }, [resources, data.resources]);

  useEffect(() => {
    if (onInit) {
      if (deletingResource || showFormError) {
        onInit({ buttons: undefined });
      } else {
        onInit({
          buttons: {
            leftSide: [
              {
                title: <span>Cancel<br/>And Close</span>,
                theme: 'secondary-gradient',
                className: '!w-30 !h-14 !text-sm !text-left !font-semibold',
                onClick: onClose,
              },
            ],
            rightSide: [
              {
                title: "Prev",
                theme: "warning",
                shape: "left-arrow",
                className: "!w-20 !text-sm !font-semibold",
                onClick() {
                  goBack();
                },
              },
              {
                title: "Next",
                theme: "warning",
                shape: "right-arrow",
                className: "!w-20 !text-sm !font-semibold",
                onClick() {
                  methods.handleSubmit(handleSuccess, handleError)();
                },
              },
            ],
          },
        });
      }
    }
  }, [onInit, deletingResource, showFormError]);

  const onFieldChange = (field: keyof IPathResourcesForm, value: any) => {
    methods.setValue(field, value);
    methods.trigger(field);
    setTimestamp(new Date().getTime());
  };

  const onAddResource = (resource: IResource) => {
    const resources = [
      ...data.resources,
      resource,
    ];
    onFieldChange('resourceName', '');
    onFieldChange('resources', resources);
  };

  const onAttemptDeleteResource = (resource: IResource) => {
    setDeletingResource(resource);
  };

  const onRemoveResource = () => {
    const resources = data.resources.filter((item) => item.id !== deletingResource!.id);
    onFieldChange('resources', resources);
    setDeletingResource(undefined);
  };

  const handleSuccess = (values: IPathResourcesForm) => {
    onSubmit(values);
    onNext(values);
  };

  const handleError = (error: any) => {
    // setShowFormError(true);
  };

  const goBack = () => {
    onSubmit(methods.getValues());
    onBack();
  };

  return (
    <FormWrapper className="text-black" onSubmit={methods.handleSubmit(handleSuccess, handleError)}>
      <div className="px-5">
        <p className="text-blue text-sm mb-4">In this tab, you can associate one or multiple learning resources to this learning path.</p>

        <FormInputAutocomplete
          className="!text-sm !text-black !placeholder-secondary placeholder-italic"
          name="resourceName"
          label="Learning resource name"
          placeholder="Type the name of the learning resource to select it"
          list={filteredResources}
          loadableMore
          group
          getItemLabel={(item: IResource) => item.name}
          getItemValue={(item: IResource) => item}
          dropdownClass="max-h-40 overflow-auto !mt-0"
          dropdownItemClass="!text-sm"
          register={methods.register}
          onChange={onAddResource}
          onLoadMore={onLoadMore}
        />
      </div>

      <div className="min-h-60 w-full mt-4">
        <table className="w-full text-sm mt-2">
          <thead>
          <tr className="text-gray text-xs text-left font-semibold border-b border-solid border-gray-cd">
            <th className="pl-5 pr-4 pb-1">Common skill category / Learning resource name</th>
            <th className="pl-5 pr-4 pb-1">Type</th>
            <th className="pl-5 pr-4 pb-1">Duration</th>
            <th className="pl-5 pr-4 pb-1">Expertise Level</th>
            <th className="pr-5 pb-1" />
          </tr>
          </thead>
          <tbody>
          {groups.map((group, i) => {
            const items = data.resources.filter((item) => item.category === group);
            return (
              <React.Fragment key={i}>
                <tr>
                  <td className="font-semibold px-5 pt-2" colSpan={5}>
                    <div className="flex items-center leading-tight pb-2">
                      <span className="bg-gray-97 uppercase text-2xs text-white px-1 py-0.5">{categories[group]}</span>
                      <span className="ml-2">{group}</span>
                    </div>
                  </td>
                </tr>
                {items.map((item) => (
                  <tr key={item.id} className="align-top">
                    <td className="pl-5">
                      <div className="flex items-center bg-gray-f5 mb-1">
                        <div className="pl-2 pr-6">{item.name}</div>
                      </div>
                    </td>
                    <td>
                      <div className="flex items-center bg-gray-f5 mb-1">
                        <div className="text-gray px-5">{item.type}</div>
                      </div>
                    </td>
                    <td>
                      <div className="flex items-center bg-gray-f5 mb-1">
                        <div className="text-gray px-5">{formatDuration(item.duration)}</div>
                      </div>
                    </td>
                    <td>
                      <div className="flex items-center bg-gray-f5 mb-1">
                        <div className="text-gray px-5">{item.expertiseLevel}</div>
                      </div>
                    </td>
                    <td className="pr-5">
                      <div className="h-5 bg-gray-f5 flex items-center justify-end pr-2 mb-1">
                        <i className="fa fa-times text-warning cursor-pointer"
                           onClick={() => onAttemptDeleteResource(item)}/>
                      </div>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            );
          })}
          </tbody>
        </table>
      </div>

      {deletingResource && (
        <ResourceDeleteConfirm
          className="w-full h-full absolute top-0 left-0 z-100"
          resource={deletingResource}
          onDelete={onRemoveResource}
          onClose={() => setDeletingResource(undefined)}
        />
      )}

      {showFormError && (
        <FormError className="w-full h-full absolute top-0 left-0 z-100" onClose={() => setShowFormError(false)} />
      )}
    </FormWrapper>
  );
};
