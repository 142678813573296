import classNames from "classnames";
import grid from "@styles/modules/grid.module.scss";
import styles from "./next.module.scss";
import { ProgressBar, Titles } from "@ui";
import { getPhrases } from "@common/helpers/localization";
import { DashboardAssessmentsCounters } from "@common/types/reports/assessments/dashboard-assessments-counters";

interface Props {
  props: DashboardAssessmentsCounters;
}

export const Next = ({ props }: Props) => {
  const loc = getPhrases("dashboard_page", "next");
  return (
    <div className={styles.data}>
      <div className={classNames(styles.item, grid.item)}>
        <div className={styles.value}>{props.counters.assessments ?? 0}</div>
        <div className={styles.title}>{loc.points_assessments}</div>
      </div>
      <div className={classNames(styles.item, grid.item)}>
        <div className={styles.value}>{props.counters.skills ?? 0}</div>
        <div className={styles.title}>{loc.points_skills}</div>
      </div>
      <div className={classNames(styles.item, grid.item)}>
        <div className={styles.value}>{props.counters.certificates ?? 0}</div>
        <div className={styles.title}>{loc.points_certs}</div>
      </div>
    </div>
  );
};
