import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import grid from "@styles/modules/grid.module.scss";
import styles from "./sidebar.module.scss";
import { Svg, Titles } from "@ui";
import { MenuProps } from "@common/types/reports/common";
import { appendRoute } from "@features/reporting/sidebar/helpers";

interface SidebarProps {
  groups: MenuProps[];
  route: string;
  onChange: (value: string) => void;
}

export const Sidebar: React.FC<SidebarProps> = ({ groups, route: currentRoute, onChange }) => {
  const [route, setRoute] = useState("");
  const menuList = useMemo(() => appendRoute(groups), [groups]);

  useEffect(() => {
    if (currentRoute && currentRoute !== route) {
      setRoute(currentRoute);
    }
  }, [currentRoute, route]);

  const handleRouterChange = (value: string = "", canPop = false) => {
    let newRoute = value;
    if (canPop && value === route) {
      const routeParams = value.split(">");
      // TODO: Delete after integration
      if (routeParams.length === 2) {
        return;
      }
      newRoute = routeParams.slice(0, routeParams.length - 1).join(">");
    }
    setRoute(newRoute);
    onChange(newRoute);
  };
  return (
    <div className={classNames(grid.root, styles.wrapper)}>
      <div className={classNames(grid.container, styles.container)}>
        {menuList.map(item => (
          <React.Fragment key={item.title}>
            <Titles level="level_3" modClass={styles.title}>
              {item.title}
            </Titles>
            {item.subMenus?.map(menu => (
              <div key={`${menu.title}-${menu.key}`} className={styles["item-wrapper"]}>
                <div
                  className={classNames(
                    styles["item-header"],
                    menu.breadcrumb && route.includes(menu.breadcrumb) ? styles.active : ""
                  )}
                  onClick={() => handleRouterChange(menu.breadcrumb, true)}
                >
                  {menu.title}
                  <Svg name="play" modClass={styles.indicator} />
                </div>
                {menu.breadcrumb &&
                  route.includes(menu.breadcrumb) &&
                  menu.subMenus?.map(submenu => (
                    <p
                      key={`${submenu.title}-${submenu.key}`}
                      className={classNames(styles["menu-item"], submenu.breadcrumb === route ? styles.active : "")}
                      onClick={() => handleRouterChange(submenu.breadcrumb)}
                    >
                      {submenu.title}
                    </p>
                  ))}
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
