import React, { FC, useEffect, useState } from "react";
import { ComplexModal, ComplexModalButtons } from "@ui";
import { ISkillCategoryForm, SkillCategory } from "@common/types/skill-engine/skill-category";
import { SkillCategoriesForm } from "@features/skills/skill-categories-form";
import { SavingError } from "@features/settings/blocks/roles/role-form/saving-error";
import { WithIntegerId } from "@common/types/common";
import { getSkillCategories, updateSkillCategory } from "@common/features/skills/model";
import { $userStore } from "@common/api/store/user";
import { $commonStore } from "@common/api/store/common";
import { observer } from "mobx-react-lite";

interface EditSkillCategoryModalProps {
  skillCategory: SkillCategory;
  isOpen: boolean;
  closeModal: () => void;
}

interface EditSkillCategoryForm extends ISkillCategoryForm, WithIntegerId {}

export const EditSkillCategoryModal: FC<EditSkillCategoryModalProps> = observer(
  ({ isOpen, skillCategory, closeModal }) => {
    const [apiError, setApiError] = useState("");
    const [buttons, setButtons] = useState<ComplexModalButtons>();
    const [formData, setFormData] = useState<EditSkillCategoryForm[]>([
      {
        id: skillCategory.id,
        is_active: skillCategory.is_active,
        name: skillCategory.name,
        description: skillCategory.description,
        parent_id: skillCategory.parent_id
      }
    ]);

    useEffect(() => {
      const category = $commonStore.skillCategories.find(category => category.id === skillCategory.id);
      if (category) {
        setFormData([
          {
            id: category.id,
            is_active: category.is_active,
            name: category.name,
            description: category.description,
            parent_id: category.parent_id
          }
        ]);
      }
    }, [$commonStore.skillCategories, skillCategory]);

    const onSubmit = async (data: EditSkillCategoryForm[]) => {
      console.log("submit", data);
      updateSkillCategory(skillCategory.id, data[0]).then(() => {
        closeModal();
        $userStore.setSkillUpdate(true);
        getSkillCategories();
      });
    };

    return (
      <ComplexModal
        isOpen={isOpen}
        closeModal={closeModal}
        shouldCloseOnOverlayClick={false}
        headerTitle="Edit Skill Category"
        headerSecondRow={
          <p className="text-md uppercase font-normal opacity-80">
            Please enter the details of your skill category below
          </p>
        }
        headerClass="pb-1"
        buttons={buttons}
      >
        <SkillCategoriesForm
          formData={formData}
          onSubmit={onSubmit}
          onClose={closeModal}
          onSetModalButtons={setButtons}
        />
        {apiError && (
          <SavingError
            className="absolute w-full h-full top-0 lef-0 z-100"
            message={apiError}
            onClose={() => setApiError("")}
          />
        )}
      </ComplexModal>
    );
  }
);
