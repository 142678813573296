import { GlobalStore } from "@common/api/store/global";
import { $userStore } from "@common/api/store/user";
import { getDashboardAssessmentCounters } from "@common/features/assessments/model";
import { getProfile } from "@common/features/profile/model";
import { DashboardAssessmentsCounters } from "@common/types/reports/assessments/dashboard-assessments-counters";
import { ProfileInterface } from "@common/types/reports/profile/profile";
import { Greetings, Information, Personal } from "@features/dashboard";
import { useContext, useEffect, useState } from "react";

export const DashboardPageEmployee = () => {
  const { $profileStore } = useContext(GlobalStore);
  const user = $userStore.getUserInfo();
  const userName = `${user?.first_name ? `${user.first_name} ` : ""}${user?.last_name}`;
  const [information, setInformation] = useState<ProfileInterface>();
  const [counters, setCounters] = useState<DashboardAssessmentsCounters>();
  useEffect(() => {
    const profile = $profileStore.getProfile();
    if (profile) {
      setInformation(profile);
    } else {
      getProfile(setInformation);
    }
    getDashboardAssessmentCounters(setCounters);
  }, []);
  return (
    <>
      <Greetings name={userName} counters={counters} />
      {!!information && <Personal information={information} />}
      {!!information && <Information information={information} />}
    </>
  );
};
