import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./learn-page.module.scss";
import Grid from "@styles/modules/grid.module.scss";
import { MultiSelectCheckbox } from "./MultiSelectCheckbox";
import { layoutOptions } from "./model";
import { Button, Dropdown, SelectUI, Svg } from "@ui";
import ReactPaginate from "react-paginate";
import { useSearchParams, useLocation } from "react-router-dom";
import { SwitchWithText } from "./SwitchWithText";
import { getPhrases } from "@common/helpers/localization";
import classNames from "classnames";
import TileCards from "@features/learn/TileCards/TileCards";
import { CourseCard } from "@features/learn/carousels/carousel/CourseCard/course-card";
import { $api } from "@common/api/http";
import { Roles } from "@common/constants";
import { AddLearningPathModal, AddLearningResourceModal } from "@features/learn/modals";
import { Favorites, SelectedForYou, YourLearningPaths, ContinueLearning } from "@features/learn/carousels";
import {
  getContentTypes,
  getExpertiseLevels,
  getFavorites,
  getLanguages,
  getLearningResource,
  getLearningResources,
  getProviders
} from "@common/features/learn/model";
import { LearningResource } from "@common/types/reports/learn/learning-resource";
import { ExtendedDataWrapper } from "@common/api/types/response/common";
import { CourseItemInterface } from "@common/types/reports/learn/learn";
import { observer } from "mobx-react-lite";
import { GlobalStore } from "@common/api/store/global";
import { getSupplementedResource } from "@features/learn/helpers";
import { TreeView } from "@features/learn/views";

type LayoutOption = "carousel" | "grid" | "list" | "tree";
const addLearningResourceOptions = ["Learning Campaign", "Learning Path"];

export const LearnPage = observer(() => {
  const { $learnStore, $profileStore } = useContext(GlobalStore);
  const role = $profileStore.getCurrentRoleId();
  const [layout, setLayout] = useState<LayoutOption>(role === Roles.employee ? "carousel" : "grid");
  const [isAdvanceSearchVisible, setIsAdvanceSearchVisible] = useState(false);
  const [isFavouriteCoursesPage, setIsFavouriteCoursesPage] = useState(false);
  const [isAddResource, setIsAddResource] = useState(false);
  const [editableResource, setEditableResource] = useState<LearningResource | null>(null);
  const [isAddPath, setIsAddPath] = useState(false);
  const searchQueryRef = useRef<HTMLInputElement>(null);
  const [isReload, setReload] = useState<number>(0);

  // todo: update the type of this
  const [resources, setResources] = useState<ExtendedDataWrapper<LearningResource[]>>();
  const [filterSortType, setFilterSortType] = useState<sortParams | undefined>();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const queryParams = Object.fromEntries(searchParams);
    setSearchParams({
      ...queryParams,
      ["per-page"]: "18"
    });
  }, []);

  const toggleAdvancedSearchVisible = () => {
    setIsAdvanceSearchVisible(!isAdvanceSearchVisible);
  };

  const isInternalContentToggler = () => {
    const queryParams = Object.fromEntries(searchParams);

    if (JSON.parse(searchParams.get("filter[is_internal]")!) === true) {
      delete queryParams["filter[is_internal]"];
      setSearchParams(queryParams);
      return;
    }

    setSearchParams({ ...queryParams, "filter[is_internal]": "true" });
  };

  const handleCloseAddResourceModal = (isAddOrEdit?: boolean) => {
    setIsAddResource(false);
    setEditableResource(null);
    if (isAddOrEdit) {
      setReload(Math.random() * 100);
    }
  };

  const isFreeContentToggler = () => {
    const queryParams = Object.fromEntries(searchParams);

    if (JSON.parse(searchParams.get("filter[is_free]")!) === true) {
      delete queryParams["filter[is_free]"];
      setSearchParams(queryParams);
      return;
    }

    setSearchParams({ ...queryParams, "filter[is_free]": "true" });
  };

  const loc = getPhrases("learn_page", "page_filters");

  enum sortParams {
    "relevance" = 1,
    "level" = 2,
    "skillCategory" = 3
  }

  const sortByOptions = [
    // {
    //   label: loc.sort_by_relevance,
    //   value: sortParams.relevance,
    // },
    {
      label: loc.sort_by_level,
      value: sortParams.level
    }
    // {
    //   label: loc.sort_by_skill_category,
    //   value: sortParams.skillCategory,
    // }
  ];

  type FilterData = {
    id: number;
    value: string;
    label: string;
    withPartner?: boolean;
  };

  const initialFilters = [
    {
      id: 1,
      name: loc.skill_categories,
      key: "skill_category_id",
      filterData: [] as FilterData[]
    },
    {
      id: 2,
      name: loc.training_providers,
      key: "provider_id",
      filterData: [] as FilterData[]
    },
    {
      id: 3,
      name: loc.content_duration,
      key: "duration",
      filterData: [] as FilterData[]
    },
    {
      id: 4,
      name: loc.content_types,
      key: "content_type_id",
      filterData: [] as FilterData[]
    }
  ];

  //  Api Data goes here
  const [filters, setFilters] = useState(initialFilters);

  useEffect(() => {
    // function to get correct names
    const getCorrectFilterName = (name: string) => {
      switch (name) {
        case "content_types":
          return { name: "Content Types", key: "content_type_id" };
        case "skill_categories":
          return { name: "Skill Categories", key: "skill_category_id" };
        case "providers":
          return { name: "Training Providers", key: "provider_id" };
        case "duration":
          return { name: "Content Duration", key: name };
        case "languages":
          return { name: "Languages", key: "language_id" };
        default:
          return { name, key: name };
      }
    };
    const getFilters = () => {
      $api
        .get("/web/learn/resources/filters")
        .then(res => {
          const filters = Object.entries(res.data.filter).map((entry: any[], index: number) => {
            if (entry[0] === "duration") {
              return {
                id: index,
                filterData: Object.entries(entry[1]).map(entry => ({
                  id: entry[0],
                  value: entry[0],
                  label: entry[1],
                  withPartner: false
                })),
                ...getCorrectFilterName(entry[0])
              };
            }
            const filterData: FilterData[] = entry[1].map((filter: { id: number; name: string }) => {
              return {
                id: filter.id,
                value: filter.name,
                label: filter.name,
                withPartner: false
              };
            });
            return {
              id: index,
              filterData: [
                ...filterData.filter(option => !option.label.toLowerCase().startsWith("other")),
                ...filterData.filter(option => option.label.toLowerCase().startsWith("other"))
              ],
              ...(getCorrectFilterName(entry[0]) as any)
            };
          });
          setFilters(filters);
        })
        .catch(err => {
          console.error("error: ", err);
        });
    };
    getFilters();
  }, []);

  const location = useLocation();

  useEffect(() => {
    getContentTypes();
    getLanguages();
    getExpertiseLevels();
    getProviders();
    getFavorites();
  }, []);

  // Get resources
  useEffect(() => {
    getLearningResources(location.search)
      .then(res => {
        console.log(res, "resources");
        setResources(res);
      })
      .catch(err => {
        console.log("error: ", err);
      });
  }, [location, isReload]);

  const searchClickHandler = () => {
    const paramsObj = Object.fromEntries(searchParams);
    if (searchQueryRef?.current?.value.trim()) {
      setSearchParams({
        ...paramsObj,
        ["filter[query]"]: searchQueryRef?.current?.value.toString()!
      });
    } else {
      delete paramsObj["filter[query]"];
      setSearchParams(paramsObj);
    }
  };

  const handleOpenAddResource = () => {
    setIsAddResource(true);
  };

  const handleClickEdit = async (id: number) => {
    const resource: LearningResource | undefined = await getLearningResource(id);
    if (resource) {
      setEditableResource(await getSupplementedResource(resource));
      setIsAddResource(true);
    }
  };

  const hasViewPermission = (access?: Roles[]) => {
    return !(access && role && access.indexOf(role) === -1);
  };

  const sortResources = (value: number) => {
    if (!filterSortType || (filterSortType && filterSortType !== value)) {
      if (resources?.data.length) {
        const sortResources = (value: number, data: CourseItemInterface[]) => {
          switch (value) {
            case sortParams.level:
              return data.sort(
                (a: CourseItemInterface, b: CourseItemInterface) => a.expertise_level.sort - b.expertise_level.sort
              );
            default:
              return data;
          }
        };

        setResources((prev: any) => {
          return {
            ...prev,
            data: sortResources(value, prev.data)
          };
        });
      }
      setFilterSortType(value);
    }
  };

  const onAddLearningResource = (option: string) => {
    switch (option) {
      case "Learning Campaign":
        setIsAddResource(true);
        break;

      case "Learning Path":
        setIsAddPath(true);
        break;
    }
  };

  return (
    <>
      <div className={Grid["page-row"]}>
        <div className={Grid.root}>
          <div className={Grid.container}>
            {/*   LEARN PAGE HEADER   */}
            <div className={styles.learnPageHeader}>
              {/*   HEADING AND DESCRIPTION */}
              <div>
                {(layout !== "carousel" || $learnStore.hasEmployeeResources === true) && (
                  <h2 className={styles.heading}>Welcome to the learn page</h2>
                )}
                {layout !== "carousel" && layout !== "tree" && (
                  <p className={styles.description}>
                    Please search and filter our content to get to the material you are looking for
                  </p>
                )}
              </div>

              {/*   RIGHT SIDE LAYOUT CHANGE BUTTONS  */}
              <div className={styles.headerLayoutFilters}>
                {layoutOptions
                  .filter(item => hasViewPermission(item.access))
                  .map(({ name, iconName, iconType }) => {
                    const onClick = () => {
                      setLayout(name as LayoutOption);
                    };
                    if (iconType === "fa") {
                      return (
                        <i
                          className={classNames(
                            styles["carousels-icon"],
                            name === layout ? styles["carousels-icon-active"] : "",
                            iconName
                          )}
                          onClick={onClick}
                        />
                      );
                    } else {
                      return (
                        <Svg
                          key={name}
                          modClass={`${styles.layoutIcon} ${name === layout ? styles.activeIcon : ""}`}
                          name={iconName}
                          onClick={onClick}
                        />
                      );
                    }
                  })}
                {role === Roles.clientAdmin && (
                  <Dropdown
                    button={
                      <Button size={"thin"} className={styles.addResourceBtn}>
                        + Learning Resource
                        <i className="fa fa-caret-down ml-2" />
                      </Button>
                    }
                    options={addLearningResourceOptions}
                    onSelectOption={onAddLearningResource}
                  />
                )}
              </div>
            </div>

            {/*     TOP FILTERS     */}

            {/*     SEARCH AND FILTERS  */}
            {layout !== "carousel" && layout !== "tree" && (
              <div className={styles.searchAndFilters}>
                <div className={Grid.container}>
                  <div className={classNames(Grid.list, styles.inputWithFiltersList)}>
                    <div className={classNames(Grid.item, Grid["item-7x"])}>
                      <input
                        ref={searchQueryRef}
                        className={styles.filtersInput}
                        type="text"
                        onKeyPress={e => {
                          e.key === "Enter" && searchClickHandler();
                        }}
                      />
                    </div>
                    <div className={classNames(Grid["item-1x"], styles.searchBtnContainer)}>
                      <Button size={"thin"} onClick={searchClickHandler} className={styles.searchBtn}>
                        Search
                      </Button>
                    </div>
                    <div className={classNames(Grid.item, Grid["item-2x"])}>
                      <div className={styles.sortBy}>
                        {/*{loc.sort_by}{" "}*/}
                        <SelectUI
                          placeholder={loc.sort_by}
                          className={styles.sortByOptions}
                          options={sortByOptions}
                          name={"sortBy"}
                          onChange={newValue => newValue?.value && sortResources(newValue?.value)}
                        />
                      </div>
                    </div>
                    <div className={classNames(Grid.item, Grid["item-2x"])}>
                      <div onClick={toggleAdvancedSearchVisible} className={styles.searchType}>
                        {isAdvanceSearchVisible ? "Simple Search" : "Advanced Search"}
                      </div>
                    </div>
                  </div>

                  {/*     ADVANCED FILTERS    */}
                  {isAdvanceSearchVisible && (
                    <div className={styles.advancedFiltersContainer}>
                      <div className={styles.topFilters}>
                        {filters.map(({ id, filterData, name, key }) => {
                          const slug = name.toLowerCase().replace(" ", "_");

                          return (
                            <MultiSelectCheckbox
                              key={id}
                              data={filterData}
                              placeholder={name}
                              isMulti
                              onChange={(newValue: any) => {
                                const queryParams = Object.fromEntries(searchParams);

                                const queryStr = newValue?.map((val: any) => val.id);
                                console.log(queryParams, "These are query params");

                                console.log(queryStr);

                                // todo - implement that as the api is ready
                                setSearchParams({
                                  ...queryParams,
                                  [`filter[${key}][]`]: queryStr
                                });
                              }}
                            />
                          );
                        })}
                        <div>
                          <SwitchWithText
                            onClick={isFreeContentToggler}
                            checked={JSON.parse(searchParams.get("filter[is_free]")!)}
                            text={loc.free_content}
                          />
                        </div>

                        {/* <div>
                          <SwitchWithText
                            onClick={isInternalContentToggler}
                            checked={JSON.parse(
                              searchParams.get("filter[is_internal]")!
                            )}
                            text={loc.internal_content}
                          />
                        </div> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {
              /*     Learning Resources available and sort by options    */
              (layout !== "carousel" || $learnStore.hasEmployeeResources === true) && (
                <div className={styles.resourcesAndSortBy}>
                  <p className={styles.heading}>
                    {resources?.meta?.total || 0} {loc.learning_resources_available}
                  </p>
                </div>
              )
            }

            {isFavouriteCoursesPage ? (
              <div className={isFavouriteCoursesPage ? "" : styles.hidden}>
                <Favorites
                  leftHeading={
                    <div className={styles.favouritePageHeadingWithIcon}>
                      <div className={styles.heartIcon}>
                        <Svg name={"heart-red"} />
                      </div>
                      <h2 className={styles.carouselHeading}>Now showing Favorites only</h2>
                    </div>
                  }
                  leftSubHeading="Resources which allow you to continue learning on whatever subject you desire."
                  rightHeading={
                    <div
                      onClick={() => {
                        setIsFavouriteCoursesPage(false);
                      }}
                      className={styles.backToMainView}
                    >
                      Back to main view
                    </div>
                  }
                  rightSubHeading="See a list of learning items which you have selected as favorites"
                />
              </div>
            ) : (
              <>
                {/*    Carousels LAYOUT    */}

                <div className={classNames(styles.carousels, layout !== "carousel" && styles.hidden)}>
                  {$learnStore.hasEmployeeResources !== null && $learnStore.hasEmployeeResources === false && (
                    <div className={styles.noResources}>
                      There are no learning resources associated to your role or profile. <br /> You can see the entire
                      Learning catalog{" "}
                      <span className={styles.noResourcesLink} onClick={() => setLayout("grid")}>
                        here
                      </span>
                      .
                    </div>
                  )}
                  <ContinueLearning
                    rightHeading={
                      <div className={styles.favouriteHeadingWithIcon}>
                        <h2
                          onClick={() => {
                            setIsFavouriteCoursesPage(true);
                          }}
                          className={styles.carouselHeading}
                        >
                          View Favorites only{" "}
                          {($learnStore.favoritesCount || $learnStore.favoritesCount === 0) &&
                            `(${$learnStore.favoritesCount})`}
                        </h2>
                        <div className={styles.heartIcon}>
                          <Svg name={"heart-red"} />
                        </div>
                      </div>
                    }
                    rightSubHeading={
                      <p className={styles.description}>
                        See a list of learning items which you have selected as favorites
                      </p>
                    }
                  />
                  <SelectedForYou />
                  <YourLearningPaths />
                </div>
                {/*     GRID LAYOUT     */}
                <div className={classNames(Grid.list, layout === "grid" ? "" : styles.hidden)}>
                  {resources?.data
                    .map((data: any) => ({
                      id: data.id,
                      isFree: data.is_free,
                      rating: data.rating_average || 0,
                      link: data.id,
                      authorImgSrc: data.provider?.logo_image_url,
                      authorName: data.provider?.name,
                      category: data.provider?.name,
                      difficulty: data.expertise_level.name,
                      duration: data.duration,
                      heroImgSrc: data.image_url || data.video_preview_url,
                      // todo - add this property after completing
                      isCompleted: false,
                      isFavourite: data.is_favourite,
                      // title: data.short_description || "",
                      title: data.title || "",
                      totalRatings: data.rating_count || 0,
                      type: data?.content_type?.id,
                      progress: data?.progress,
                      ...(data?.url && { url: data.url })
                    }))
                    .map(({ id, ...data }: any) => (
                      <div key={id} className={classNames(styles.gridItem, Grid["item-2x"])}>
                        <div className={styles.gridCourseCard}>
                          <CourseCard id={id} {...data} onEdit={handleClickEdit} />
                        </div>
                      </div>
                    ))}
                </div>

                {/*     TILES LAYOUT    */}
                <div className={classNames(styles.tilesLayout, layout === "list" ? "" : styles.hidden)}>
                  <TileCards
                    handleClickEdit={handleClickEdit}
                    CoursesData={
                      resources?.data.map((data: any) => ({
                        id: data.id,
                        isFree: data.is_free,
                        rating: data.rating_average || 0,
                        link: data.id,
                        authorImgSrc: data.provider?.logo_image_url,
                        authorName: data.provider?.name,
                        category: data.provider?.name,
                        difficulty: data.expertise_level?.name,
                        duration: data.duration,
                        heroImgSrc: data.image_url || data.video_preview_url,
                        // todo - add this property after completing
                        isCompleted: false,
                        isFavourite: data.is_favourite,
                        // title: data.short_description || "",
                        title: data.title || "",
                        totalRatings: data.rating_count || 0,
                        type: data?.content_type?.id,
                        ...(data?.url && { url: data.url }),
                        progress: data?.progress
                      })) || []
                    }
                  />
                </div>

                {/*     TREE LAYOUT    */}
                {layout === "tree" && <TreeView />}
              </>
            )}

            {layout !== "carousel" && layout !== "tree" && resources?.data.length && (
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <button className={styles.nextBtn}>
                    <Svg name={"right-arrow"} />
                  </button>
                }
                previousLabel={
                  <button className={styles.prevBtn}>
                    <Svg name="left-arrow" modClass="text-white" />
                  </button>
                }
                onPageChange={page => {
                  const queryParams = Object.fromEntries(searchParams);
                  setSearchParams({
                    ...queryParams,
                    ["page"]: (page.selected + 1).toString(),
                    ["per-page"]: "18"
                  });
                }}
                pageRangeDisplayed={5}
                initialPage={searchParams.get("page") ? Number(searchParams.get("page")) - 1 : 0}
                pageCount={resources?.meta?.last_page || 5}
                renderOnZeroPageCount={() => {}}
                className={styles.paginationContainer}
                activeLinkClassName={styles.activeLinkClassName}
                pageLinkClassName={styles.pageLinkClassName}
              />
            )}
          </div>
        </div>
      </div>
      {isAddResource && (
        <AddLearningResourceModal
          isOpen={true}
          closeModal={handleCloseAddResourceModal}
          editableResource={editableResource}
        />
      )}
      {isAddPath && <AddLearningPathModal isOpen={true} closeModal={() => setIsAddPath(false)} />}
    </>
  );
});
