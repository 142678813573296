import { TableCellProps, TableColumn } from "@common/types/table";
import styles from "./number-cell.module.scss";
import classNames from "classnames";

interface NumberCellInterface extends TableCellProps {}

export const NumberCell =
  (chartColor?: "blue" | "green" | "orange" | "red" | "purple") =>
  ({ props }: NumberCellInterface) => {
    const { value, column } = props;

    const { affix, maxValue, decorator } = column as TableColumn<string>;
    let content = (
      <>
        {value}
        {!isNaN(value) && affix}
      </>
    );
    if (decorator) {
      content = decorator(props) as unknown as any;
    }

    return (
      <div className={classNames(styles["cell-container"], chartColor && styles.percentage)}>
        {content}
        {chartColor && <p className={styles[chartColor]} style={{ width: `${(100 / (maxValue || 100)) * value}%` }} />}
      </div>
    );
  };
