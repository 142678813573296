import { TableCellProps } from "@common/types/table";
import classNames from "classnames";
import styles from "./title.module.scss";

const getStatusClassName = (isActive: boolean) => {
  return styles[isActive ? "active" : "deleted"];
};

export const Title = ({ props }: TableCellProps) => {
  return (
    <div className={styles.block}>
      <div className={styles.name}>{props.value}</div>
      <div className={styles.info}>
        {props.rowData.current_role_name && <div className={styles.role}>{props.rowData.current_role_name}</div>}
        <div className={classNames(styles.status, styles[props.rowData.is_active ? "active" : "deleted"])}>
          {props.rowData.is_active ? "Active" : "Inactive"}
        </div>
      </div>
    </div>
  );
};
