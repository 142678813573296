import { getDeclensions } from "@common/helpers/string-helpers";
import { ICellTextProps } from "ka-table/props";
import { PropsWithChildren } from "react";

export const getRolesCount = (data: PropsWithChildren<ICellTextProps>) => {
  return `${data.value || 0} ${getDeclensions(data.value, "role", "roles")}`;
};

export const getEmployeesCount = (data: PropsWithChildren<ICellTextProps>) => {
  return `${data.value || 0} ${getDeclensions(data.value, "employee", "employees")}`;
};

export function formatOrgUnitsObject<T>(orgUnits: T[]) {
  return orgUnits.map((item: T) => {
    return {
      ...item,
      action: "Edit"
    };
  });
}
