import { ComplexModalButtons, FormInputAutocomplete, FormInputText, FormWrapper, Switch } from "@ui";
import form from "@styles/modules/form.module.scss";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { getPhrases } from "@common/helpers/localization";
import * as yup from "yup";
import { AreaFormInterface, RegionProps } from "@common/types/reports/offices/office-detail";
import { useForm } from "@common/hooks/form";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { SkillCategory } from "@common/types/skill-engine/skill-category";
import { GlobalStore } from "@common/api/store/global";

interface Props {
  isSaving?: boolean;
  formData?: AreaFormInterface;

  onSubmit(data: Partial<AreaFormInterface>): void;

  onClose(): void;

  onSetModalButtons(buttons?: ComplexModalButtons): void;
}

const loc = getPhrases("common", "forms").validation;

export const UpdateAreaSchema: yup.SchemaOf<AreaFormInterface> = yup.object().shape({
  name: yup.string().required(loc.required)
});

export const AreaInfoForm = observer(({ isSaving, formData, onSubmit, onClose, onSetModalButtons }: Props) => {
  const { $destinationDataStore } = useContext(GlobalStore);
  const [curFormData, setCurFormData] = useState<AreaFormInterface | undefined>(formData);
  const methods = useForm<AreaFormInterface>({
    schema: UpdateAreaSchema,
    defaultValues: {
      name: formData?.name
    }
  });
  const [, setShowFormError] = useState(false);

  useEffect(() => {
    if (formData) {
      methods.reset({ ...formData });
      setCurFormData(methods.getValues());
    }
  }, [formData]);

  const handleSuccess = useCallback(
    ({ name }: AreaFormInterface) => {
      onSubmit({ name });
    },
    [onSubmit]
  );

  const handleError = useCallback(() => {
    setShowFormError(true);
  }, [setShowFormError]);

  useEffect(() => {
    onSetModalButtons({
      rightSide: [
        {
          title: (
            <span>
              Cancel
              <br />
              Changes
            </span>
          ),
          theme: "secondary-gradient",
          className: "!w-30 !h-14 !text-sm !text-left !font-semibold",
          onClick: onClose,
          disabled: isSaving
        },
        {
          title: (
            <span>
              Save
              <br />
              Changes
            </span>
          ),
          theme: "orange",
          className: "!w-30 !h-14 !text-sm !text-left !font-semibold",
          onClick() {
            methods.handleSubmit(handleSuccess, handleError)();
          },
          disabled: isSaving
        }
      ]
    });
  }, [isSaving, onSetModalButtons]);

  const onFieldChange = (field: any, value: any) => {
    methods.setValue(field, value);
    setCurFormData(methods.getValues());
  };

  return (
    <FormWrapper onSubmit={methods.handleSubmit(handleSuccess, handleError)}>
      <div className={classNames(form.row)}>
        <div className="mb-5 grid sm:grid-cols-1">
          <FormInputText
            className="!placeholder-secondary placeholder-italic"
            name="name"
            label="Area Name"
            placeholder="Type name of area"
            register={methods?.register}
            error={methods?.formState.errors["name"]?.message}
          />
        </div>
      </div>
    </FormWrapper>
  );
});
