import { makeAutoObservable } from "mobx";
import { Banner, BannerType, ClassCommon } from "../types/common";
import { SkillCategory } from "@common/types/skill-engine/skill-category";

export class Common implements ClassCommon {
  loadingIds: string[] = [];
  skillCategories: SkillCategory[] = [];
  banner: Banner | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(): string {
    const newId = `${Date.now()}-${Math.random().toString().slice(2, -1)}`;
    this.loadingIds = [...this.loadingIds, newId];
    return newId;
  }

  finishLoading(loadingId: string) {
    this.loadingIds = this.loadingIds.filter(id => id !== loadingId);
  }

  getLoading() {
    return this.loadingIds.length;
  }

  setSkillCategories(categories: SkillCategory[]) {
    this.skillCategories = categories;
  }

  getSkillCategories(categories: SkillCategory[]) {
    return this.skillCategories;
  }

  setBanner(type: BannerType, text = "") {
    this.banner = {
      type,
      text
    };
  }

  getBanner() {
    return this.banner;
  }

  clearBanner() {
    this.banner = null;
  }

  reset(): void {
    this.loadingIds = [];
    this.skillCategories = [];
    this.banner = null;
  }
}

export const $commonStore = new Common();
