import React from "react";
import ReactModal from "react-modal";
import { ShadowBlock } from "@ui";
import styles from "./short-modal.module.scss";
import { getDefaultProps } from "./helpers";
import { ModalProps } from "./types";

interface Props extends ModalProps {}

export const ShortModal: React.FC<Props> = ({ isOpen, closeModal, children, ...props }) => {
  const expandProps = { ...getDefaultProps(), ...props };
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={styles.empty}
      portalClassName={styles.empty}
      {...expandProps}
    >
      <ShadowBlock modClass={styles.short}>{children}</ShadowBlock>
    </ReactModal>
  );
};
