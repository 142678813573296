import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import grid from "@styles/modules/grid.module.scss";
import tabs from "@styles/modules/tabs.module.scss";
import styles from "./information.module.scss";
import classNames from "classnames";
import { getPhrases } from "@common/helpers/localization";
import { ProfileInterface } from "@common/types/reports/profile/profile";
import { DetailsRole, Optics } from "@features/dashboard";
import { $globalStore } from "@common/api/store/global";
import { Accountabilities } from "@features/accountabilities";
import { AdditionalReportEmployee } from "@features/accountabilities/ui/additional-report-employee";

interface Props {
  information: ProfileInterface;
}

export const Information = ({ information }: Props) => {
  const profile: ProfileInterface | null = $globalStore.$profileStore.getProfile();
  const role = profile?.current_role;
  const profileId = profile?.id;
  const loc = getPhrases("dashboard_page", "information");
  const tabsClassName = classNames(tabs.panel, styles.content);
  return (
    <div className={grid["page-row"]}>
      <div className={grid.root}>
        <div className={grid.container}>
          <Tabs className={tabs.tabs} selectedTabClassName={tabs["tab-selected"]} defaultIndex={1}>
            <TabList className={classNames(tabs.list, styles.list)}>
              <Tab className={tabs.tab}>{loc.role_details}</Tab>
              <Tab className={tabs.tab}>{loc.skills}</Tab>
              {!!role && !!profileId && !!profile.company.modules?.scorecards && (
                <Tab className={tabs.tab}>{loc.accountabilities}</Tab>
              )}
            </TabList>
            <TabPanel className={tabsClassName}>
              <DetailsRole />
            </TabPanel>
            <TabPanel className={tabsClassName}>
              <Optics />
            </TabPanel>
            {!!role && !!profileId && !!profile.company.modules?.scorecards && (
              <TabPanel className={tabsClassName}>
                <Accountabilities
                  roleId={role.id}
                  profileId={Number(profileId)}
                  legendComponent={<AdditionalReportEmployee name={profile.first_name} profileId={Number(profileId)} />}
                />
              </TabPanel>
            )}
          </Tabs>
        </div>
      </div>
    </div>
  );
};
