import { IDataRowProps } from "ka-table/props";
import React from "react";
import { CategoryLevel } from "../category-level";
import { SecondLevel } from "../second-level";
import { FormattedOrgUnits } from "../types";

export const IntermediateLevel: React.FC<IDataRowProps> = data => {
  const { rowData } = data;
  const categories: FormattedOrgUnits[] = [];
  const last: any[] = [];

  rowData.children.forEach((item: any) => {
    if (item?.children?.length) {
      categories.push(item);
    } else {
      last.push(item);
    }
  });
  return (
    <>
      {!!categories.length && <CategoryLevel {...data} />}
      {!!last.length && <SecondLevel data={last} />}
    </>
  );
};
