import { CategoryLine } from "@features/settings";
import { CompanyForm } from "../company-form";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { GlobalStore } from "@common/api/store/global";

export const CompanyCategoryLine = observer(() => {
  const { $profileStore } = useContext(GlobalStore);
  return (
    <>
      <CategoryLine
        title="Company Settings"
        contentClass="pr-[10px]"
        content={<>{!!$profileStore.companySetting && <CompanyForm />}</>}
        isCollapsable={false}
      />
    </>
  );
});
