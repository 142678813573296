import React from "react";
import { Link } from "react-router-dom";
import styles from "./category-and-subcategory.module.scss";

interface Props {
  category: string;
  subcategory: string;
}

export const CategoryAndSubCategory: React.FC<Props> = ({ category, subcategory }) => {
  return (
    <div className={styles["category-container"]}>
      <span className={styles.category}>
        <Link to="/learn/">
          <span className={styles["category-root"]}>Learn{(category || subcategory) && <>&nbsp;/&nbsp;</>}</span>
        </Link>
      </span>
      {!!category && category !== subcategory && <>{category}</>}
      {!!subcategory && category !== subcategory && <>/&nbsp;</>}
      {subcategory}
    </div>
  );
};
