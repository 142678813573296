import { TableCellProps, TableColumn } from "@common/types/table";
import styles from "./action-cell.module.scss";
import React from "react";

interface ActionCellInterface extends TableCellProps {}

export const ActionCell = ({ props }: ActionCellInterface) => {
  const { value, column } = props;
  const { actionInfo } = column as TableColumn<string>;

  const handleClick = () => {
    actionInfo?.onClick && actionInfo.onClick(value);
  };

  return (
    <button className={styles["action-button"]} onClick={handleClick}>
      {value}
    </button>
  );
};
