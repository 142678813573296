import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { RoutesList } from "@router";

// Import Swiper styles
import "swiper/css";
import "swiper/css/bundle";
import { SpinnerContainer } from "./ui/spinner-container";

function App() {
  return (
    <>
      <Router>
        <RoutesList />
      </Router>
      <SpinnerContainer />
    </>
  );
}

export default App;
