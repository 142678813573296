import classNames from "classnames";
import { useState } from "react";
import { Button } from "@ui";
import styles from "./category-line.module.scss";

interface PropsButton {
  title: string;
  onClick: () => void;
}
interface Props {
  title: string;
  contentClass?: string;
  content: JSX.Element;
  button?: PropsButton;
  buttons?: JSX.Element;
  isCollapsable?: boolean;
}

// TODO: Move open/close button to abstract ui

export const CategoryLine = ({ title, content, button, buttons, isCollapsable = true, contentClass = "" }: Props) => {
  const [isOpenState, setState] = useState<boolean>(!isCollapsable);
  return (
    <div className={styles.row}>
      <div className={styles.line} onClick={() => isCollapsable && setState(prev => !prev)}>
        <div
          className={classNames(styles.button, isOpenState && styles["button-hide"], !isCollapsable && "opacity-0")}
        />
        <div className={styles.title}>{title}</div>
        {button && (
          <Button
            title={button.title}
            size="thin"
            theme="dark-blue"
            className={styles["modal-button"]}
            onClick={e => {
              e.stopPropagation();
              button.onClick();
            }}
          />
        )}
        {buttons}
      </div>
      <div
        className={classNames(styles.content, contentClass, (!isCollapsable || isOpenState) && styles["content-show"])}
      >
        {content}
      </div>
    </div>
  );
};
