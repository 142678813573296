import { OfficesCategoryLine } from "@features/settings/blocks/offices";
import { OrgUnitsCategoryLine } from "@features/settings/blocks/org-units";
import { RolesCategoryLine } from "@features/settings/blocks/roles";
import layout from "@styles/layouts/settings.module.scss";
import grid from "@styles/modules/grid.module.scss";
import { CompanyForm } from "@features/settings/blocks/company/company-form";
import { CompanyCategoryLine } from "@features/settings/blocks/company";

export const SettingsPage = () => {
  return (
    <>
      <div className={layout.row}>
        <div className={grid.root}>
          <div className={grid.container}>Welcome to Setup</div>
        </div>
      </div>
      <div className={layout.row}>
        <div className={grid.root}>
          <div className={grid.container}>
            <CompanyCategoryLine />
            <OrgUnitsCategoryLine />
            <OfficesCategoryLine />
            <RolesCategoryLine />
          </div>
        </div>
      </div>
    </>
  );
};
