import React from "react";
import styles from "./rating-with-number.module.scss";
import { Rating } from "@ui";

interface Props {
  totalRatings: number;
  rating: number;
}

export const RatingWithNumber: React.FC<Props> = ({ totalRatings, rating }) => {
  return (
    <div className={styles["rating-container"]}>
      <div className={styles["rating-start"]}>
        <Rating onlyVisible={true} rating={rating || 0} />
      </div>
      <div className={styles["rating-number"]}>({totalRatings ?? 0})</div>
    </div>
  );
};
