import classNames from "classnames";
import { DataType, SortDirection } from "ka-table/enums";
import {
  EmployeeAnalytics,
  OfficeReport,
  Analytics,
  RoleReport,
  SupervisorAnalytics,
  TeamAnalytics
} from "@common/types/reports/analytics/analytics";
import { TableColumn } from "@common/types/table";
import { ActionCell, NumberCell } from "@ui";
import styles from "./report-overview.module.scss";

export const teamOverviewColumns: TableColumn<TeamAnalytics>[] = [
  {
    key: "name",
    title: "Team",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["team-name"], styles["action-item"]),
    component: ActionCell
  },
  {
    key: "employees_count",
    title: "Employees",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-col"]),
    component: NumberCell("blue")
  },
  {
    key: "roles_count",
    title: "Roles",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-col"]),
    component: NumberCell("blue")
  },
  {
    key: "avg_skills_count",
    title: "Avg# skills",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-col"]),
    component: NumberCell("blue")
  },
  {
    key: "high_expertise_count",
    title: "High Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-col"]),
    component: NumberCell()
  },
  {
    key: "high_expertise_percent",
    title: "%High",
    affix: "%",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-col"]),
    component: NumberCell("green")
  },
  {
    key: "medium_expertise_count",
    title: "Medium Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-col"]),
    component: NumberCell()
  },
  {
    key: "medium_expertise_percent",
    title: "%Medium",
    affix: "%",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-col"]),
    component: NumberCell("orange")
  },
  {
    key: "near_expertise_count",
    title: "Near Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-col"]),
    component: NumberCell()
  },
  {
    key: "near_expertise_percent",
    title: "%Near",
    affix: "%",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-col"]),
    component: NumberCell("orange")
  },
  {
    key: "limited_expertise_count",
    title: "Limited Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-col"]),
    component: NumberCell()
  },
  {
    key: "limited_expertise_percent",
    title: "%Limited",
    affix: "%",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-col"]),
    component: NumberCell("red")
  }
];

export const teamRosterColumns: TableColumn<EmployeeAnalytics>[] = [
  {
    key: "full_name",
    title: "Name",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["team-roster-name"])
  },
  {
    key: "title",
    title: "Title",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["team-roster-title"])
  },
  {
    key: "industry_experience",
    title: "Industry Experience (Years)",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-roster-exp"]),
    component: NumberCell("blue"),
    decorator: props => (+props.value < 1 ? "< 1" : props.value)
  },
  {
    key: "company_tenure",
    title: "Company Tenure (Years)",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-roster-exp"]),
    component: NumberCell("blue")
  },
  {
    key: "area_name",
    title: "Area",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["team-roster-area"])
  },
  {
    key: "expertise",
    title: "Expertise",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["team-roster-exp"])
  },
  {
    key: "skill_percentage",
    title: "Skill %",
    affix: "%",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-roster-exp"]),
    component: NumberCell("blue")
  },
  {
    key: "training_need",
    title: "Training Needs",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["team-roster-exp"])
  }
];

export const employeeRosterColumns: TableColumn<EmployeeAnalytics>[] = [
  {
    key: "full_name",
    title: "Name",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["employee-roster-name"])
  },
  {
    key: "title",
    title: "Title",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["employee-roster-title"])
  },
  {
    key: "industry_experience",
    title: "Industry Experience (Years)",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["employee-roster-exp"]),
    decorator: props => (+props.value < 1 ? "< 1" : props.value)
  },
  {
    key: "company_tenure",
    title: "Company Tenure (Years)",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["employee-roster-tenure"])
  },
  {
    key: "geography",
    title: "Geography",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["employee-roster-geo"])
  },
  {
    key: "result",
    title: "Result",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["employee-roster-result"])
  },
  {
    key: "skill_percentage",
    title: "Skill %",
    affix: "%",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["employee-roster-avg"])
  },
  {
    key: "score_avg",
    title: "Avg Score",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["employee-roster-avg"])
  }
];

export const roleOverviewColumns: TableColumn<RoleReport>[] = [
  {
    key: "title",
    title: "Title",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["role-name"], styles["colored-col"])
  },
  {
    key: "employees_count",
    title: "Employees",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["role-other"], styles["colored-col"]),
    sortDirection: SortDirection.Descend,
    component: NumberCell("blue")
  },
  {
    key: "industry_exp",
    title: "Employee Avg industry Experience",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["role-other"], styles["colored-col"]),
    component: NumberCell("blue")
  },
  {
    key: "company_tenure",
    title: "Employee Avg company Tenure",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["role-other"], styles["colored-col"]),
    component: NumberCell("blue")
  },
  {
    key: "high_expertise",
    title: "#High Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["role-other"], styles["colored-col"]),
    component: NumberCell()
  },
  {
    key: "high_percentage",
    title: "%High Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["role-other"], styles["colored-col"]),
    affix: "%",
    component: NumberCell("green")
  },
  {
    key: "medium_expertise",
    title: "#Medium Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["role-other"], styles["colored-col"]),
    component: NumberCell()
  },
  {
    key: "medium_percentage",
    title: "%Medium Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["role-other"], styles["colored-col"]),
    affix: "%",
    component: NumberCell("orange")
  },
  {
    key: "limited_expertise",
    title: "#Limited Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["role-other"], styles["colored-col"]),
    component: NumberCell()
  },
  {
    key: "limited_percentage",
    title: "%Limited Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["role-other"], styles["colored-col"]),
    affix: "%",
    component: NumberCell("red")
  }
];

export const officeOverviewColumns: TableColumn<OfficeReport>[] = [
  {
    key: "area",
    title: "Area",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles.name)
  },
  {
    key: "employees_count",
    title: "Employees",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles.thin),
    sortDirection: SortDirection.Descend,
    component: NumberCell("blue")
  },
  {
    key: "industry_exp",
    title: "Industry Experience (Years)",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles.thin),
    component: NumberCell("blue"),
    decorator: props => (+props.value < 1 ? "< 1" : props.value)
  },
  {
    key: "company_tenure",
    title: "Company Tenure (Years)",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles.thin),
    component: NumberCell("blue")
  },
  {
    key: "high_expertise",
    title: "High Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles.thin),
    component: NumberCell()
  },
  {
    key: "high_percentage",
    title: "% High",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles.thin),
    affix: "%",
    component: NumberCell("green")
  },
  {
    key: "medium_expertise",
    title: "Medium Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles.thin),
    component: NumberCell()
  },
  {
    key: "medium_percentage",
    title: "% Medium",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles.thin),
    affix: "%",
    component: NumberCell("orange")
  },
  {
    key: "limited_expertise",
    title: "Limited Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles.thin),
    component: NumberCell()
  },
  {
    key: "limited_percentage",
    title: "% Limited",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles.thin),
    affix: "%",
    component: NumberCell("red")
  }
];

export const supervisorOverviewColumns: TableColumn<SupervisorAnalytics>[] = [
  {
    key: "full_name",
    title: "Supervisors",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles.name)
  },
  {
    key: "employees_count",
    title: "Employees",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles.thin),
    sortDirection: SortDirection.Descend,
    component: NumberCell("blue")
  },
  {
    key: "avg_industry_experience",
    title: "Industry Experience (Years)",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles.thin),
    component: NumberCell("blue"),
    decorator: props => (+props.value < 1 ? "< 1" : props.value)
  },
  {
    key: "avg_company_tenure",
    title: "Company Tenure (Years)",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles.thin),
    component: NumberCell("blue")
  },
  {
    key: "high_expertise_count",
    title: "High Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles.thin),
    component: NumberCell()
  },
  {
    key: "high_expertise_percent",
    title: "% High",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles.thin),
    affix: "%",
    component: NumberCell("green")
  },
  {
    key: "medium_expertise_count",
    title: "Medium Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles.thin),
    component: NumberCell()
  },
  {
    key: "medium_expertise_percent",
    title: "% Medium",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles.thin),
    affix: "%",
    component: NumberCell("orange")
  },
  {
    key: "near_expertise_count",
    title: "Near Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles.thin),
    component: NumberCell()
  },
  {
    key: "near_expertise_percent",
    title: "% Near",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles.thin),
    affix: "%",
    component: NumberCell("orange")
  },
  {
    key: "limited_expertise_count",
    title: "Limited Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles.thin),
    component: NumberCell()
  },
  {
    key: "limited_expertise_percent",
    title: "% Limited",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles.thin),
    affix: "%",
    component: NumberCell("red")
  },
  {
    key: "avg_skill_level",
    title: "Skill %",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles.thin),
    affix: "%",
    component: NumberCell("purple")
  },
  {
    key: "target_percentage",
    title: "Target %",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles.thin),
    affix: "%",
    component: NumberCell("purple")
  }
];

export const supervisorColumns: TableColumn<SupervisorAnalytics>[] = [
  {
    key: "full_name",
    title: "Supervisors",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["team-supervisor-overview"], styles.supervisor)
  },
  {
    key: "employees_count",
    title: "Employees",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-supervisor-overview"], styles.employee),
    sortDirection: SortDirection.Descend,
    component: NumberCell()
  },
  {
    key: "avg_industry_experience",
    title: "Employee Avg industry Experience",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-supervisor-overview"], styles["other-column"]),
    component: NumberCell()
  },
  {
    key: "avg_company_tenure",
    title: "Employee Avg company Tenure",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-supervisor-overview"], styles["other-column"]),
    component: NumberCell()
  },
  {
    key: "roles_count",
    title: "Roles",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-supervisor-overview"], styles["other-column"]),
    component: NumberCell()
  },
  {
    key: "avg_skill_level",
    title: "Avg Skills per employee",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-supervisor-overview"], styles["other-column"]),
    component: NumberCell()
  },
  {
    key: "high_expertise_count",
    title: "#High Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-supervisor-overview"], styles["other-column"]),
    component: NumberCell()
  },
  {
    key: "high_expertise_percent",
    title: "%High Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-supervisor-overview"], styles["other-column"]),
    affix: "%",
    component: NumberCell()
  },
  {
    key: "medium_expertise_count",
    title: "#Medium Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-supervisor-overview"], styles["other-column"]),
    component: NumberCell()
  },
  {
    key: "medium_expertise_percent",
    title: "%Medium Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-supervisor-overview"], styles["other-column"]),
    affix: "%",
    component: NumberCell()
  },
  {
    key: "limited_expertise_count",
    title: "#Limited Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-supervisor-overview"], styles["other-column"]),
    component: NumberCell()
  },
  {
    key: "limited_expertise_percent",
    title: "%Limited Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-supervisor-overview"], styles["other-column"]),
    affix: "%",
    component: NumberCell()
  }
];

export const supervisorTeamColumns: TableColumn<SupervisorAnalytics>[] = [
  {
    key: "full_name",
    title: "Supervisors",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["team-supervisor-overview"], styles.supervisor)
  },
  {
    key: "employees_count",
    title: "Employees",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-supervisor-overview"], styles.employee),
    sortDirection: SortDirection.Descend,
    component: NumberCell()
  },
  {
    key: "avg_industry_experience",
    title: "Employee Avg industry Experience",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-supervisor-overview"], styles["other-column"]),
    component: NumberCell()
  },
  {
    key: "avg_company_tenure",
    title: "Employee Avg company Tenure",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-supervisor-overview"], styles["other-column"]),
    component: NumberCell()
  },
  {
    key: "roles_count",
    title: "Roles",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-supervisor-overview"], styles["other-column"]),
    component: NumberCell()
  },
  {
    key: "avg_skill_level",
    title: "Avg Skills per employee",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-supervisor-overview"], styles["other-column"]),
    component: NumberCell()
  },
  {
    key: "high_expertise_count",
    title: "#High Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-supervisor-overview"], styles["other-column"]),
    component: NumberCell()
  },
  {
    key: "high_expertise_percent",
    title: "%High Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-supervisor-overview"], styles["other-column"]),
    affix: "%",
    component: NumberCell()
  },
  {
    key: "medium_expertise_count",
    title: "#Medium Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-supervisor-overview"], styles["other-column"]),
    component: NumberCell()
  },
  {
    key: "medium_expertise_percent",
    title: "%Medium Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-supervisor-overview"], styles["other-column"]),
    affix: "%",
    component: NumberCell()
  },
  {
    key: "limited_expertise_count",
    title: "#Limited Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-supervisor-overview"], styles["other-column"]),
    component: NumberCell()
  },
  {
    key: "limited_expertise_percent",
    title: "%Limited Expertise",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["team-supervisor-overview"], styles["other-column"]),
    affix: "%",
    component: NumberCell()
  }
];

export const skillOverviewColumns: TableColumn<Analytics>[] = [
  {
    key: "skill_category",
    title: "Skill Category",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["skill-report"], styles.name)
  },
  {
    key: "skills_count",
    title: "Skills",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["skill-report"], styles.column),
    sortDirection: SortDirection.Descend,
    component: NumberCell("blue")
  },
  {
    key: "target",
    title: "Target %",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["skill-report"], styles.column),
    affix: "%",
    component: NumberCell("red")
  },
  {
    key: "high_training_needs",
    title: "High Training Needs",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["skill-report"], styles.column),
    component: NumberCell()
  },
  {
    key: "medium_training_needs",
    title: "Medium Training Needs",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["skill-report"], styles.column),
    component: NumberCell()
  },
  {
    key: "low_training_needs",
    title: "Low Training Needs",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["skill-report"], styles.column),
    component: NumberCell()
  }
];

export const skillTabColumns: TableColumn<Analytics>[] = [
  {
    key: "skill_category",
    title: "Skill Category Name",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["skill-report"], styles.name, styles["colored-col"])
  },
  {
    key: "skills_count",
    title: "Skills",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["skill-report"], styles.column, styles["colored-col"]),
    sortDirection: SortDirection.Descend
  },
  {
    key: "target",
    title: "Target %",
    dataType: DataType.Number,
    modClass: classNames(
      styles.item,
      styles["skill-report"],
      styles.column,
      styles["text-left"],
      styles["colored-col"]
    ),
    affix: "%",
    component: NumberCell()
  },
  {
    key: "high_training_needs",
    title: "Average Expertise Level",
    dataType: DataType.Number,
    modClass: classNames(
      styles.item,
      styles["skill-report"],
      styles.column,
      styles["text-left"],
      styles["colored-col"]
    ),
    affix: "%",
    component: NumberCell()
  },
  {
    key: "high_training_needs",
    title: "Avg Skills% vs Target",
    dataType: DataType.Number,
    modClass: classNames(
      styles.item,
      styles["skill-report"],
      styles.column,
      styles["text-left"],
      styles["colored-col"]
    ),
    affix: "%",
    component: NumberCell()
  },
  {
    key: "high_training_needs",
    title: "Employee High training Needs",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["skill-report"], styles.column, styles["colored-col"])
  },
  {
    key: "medium_training_needs",
    title: "Employee Medium training Needs",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["skill-report"], styles.column, styles["colored-col"])
  },
  {
    key: "low_training_needs",
    title: "Employee Low training Needs",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["skill-report"], styles.column, styles["colored-col"])
  }
];

export const employeeOverviewColumns: TableColumn<EmployeeAnalytics>[] = [
  {
    key: "full_name",
    title: "Employee",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["employee-overview-name"]),
    component: ActionCell
  },
  {
    key: "industry_experience",
    title: "Industry Experience (Years)",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["employee-overview-exp"]),
    component: NumberCell("blue"),
    decorator: props => (+props.value < 1 ? "< 1" : props.value)
  },
  {
    key: "company_tenure",
    title: "Company Tenure (Years)",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["employee-overview-tenure"]),
    component: NumberCell("blue")
  },
  {
    key: "area_name",
    title: "Area",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["employee-overview-area"])
  },
  {
    key: "manager_full_name",
    title: "Supervisor",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["employee-overview-supervisor"])
  }
];

export const employeeTeamOverviewColumns: TableColumn<EmployeeAnalytics>[] = [
  {
    key: "full_name",
    title: "Employee Name",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["employee-overview-name"])
  },
  {
    key: "industry_experience",
    title: "Industry Experience (Years)",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["employee-overview-exp"]),
    component: NumberCell("blue"),
    decorator: props => (+props.value < 1 ? "< 1" : props.value)
  },
  {
    key: "company_tenure",
    title: "Company Tenure (Years)",
    dataType: DataType.Number,
    modClass: classNames(styles.item, styles["employee-overview-tenure"]),
    component: NumberCell("blue")
  },
  {
    key: "role_name",
    title: "Role",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["employee-overview-area"], "px-5")
  },
  {
    key: "supervisor_full_name",
    title: "Supervisor",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["employee-overview-supervisor"], "px-5")
  },
  {
    key: "office_name",
    title: "Office",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles["employee-overview-supervisor"])
  },
  {
    key: "expertise",
    title: "Expertise Level",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles.thin, styles["colored-col"])
  },
  {
    key: "skill_percentage",
    title: "Skill% vs Target",
    dataType: DataType.Number,
    affix: "%",
    modClass: classNames(styles.item, styles.skill, styles["colored-col"]),
    component: NumberCell("red")
  },
  {
    key: "training_need",
    title: "Training Needs",
    dataType: DataType.String,
    modClass: classNames(styles.item, styles.thin, styles["colored-col"])
  }
];

export const skillTabItems = [
  { title: "Skills", key: "Skills" },
  { title: "Employees", key: "Employees" },
  { title: "Offices", key: "Offices" },
  { title: "Roles", key: "Roles" }
];

export type TeamViewType = "supervisors" | "employees" | "offices" | "roles" | "skills";

export const teamTabItems: { title: string; key: TeamViewType }[] = [
  { title: "Employees", key: "employees" },
  { title: "Supervisors", key: "supervisors" }
  // { title: "Offices", key: "offices" },
  // { title: "Roles", key: "roles" },
  // { title: "Skills", key: "skills" },
];

export const supervisorTabItems = [
  // { title: "Teams", key: "Teams" },
  { title: "Employees", key: "Employees" }
  // { title: "Offices", key: "Offices" },
  // { title: "Roles", key: "Roles" },
  // { title: "Skills", key: "Skills" },
];

export const teamKpiList = [
  {
    value: 5,
    title: "Total number of teams"
  },
  {
    value: 36,
    title: "Total employees in teams"
  },
  {
    value: 16,
    title: "Roles connected to teams"
  },
  {
    value: 7.3,
    title: "Average skills per employee"
  },
  {
    value: 24,
    affix: "%",
    title: "Employees rated High Expertise"
  },
  {
    value: 49,
    affix: "%",
    title: "Employees rated Medium Expertise"
  },
  {
    value: 27,
    affix: "%",
    title: "Employees rated Limited expertise"
  }
];

export const supervisorKpiList = [
  {
    value: 2,
    title: "Total number of Supervisors"
  },
  {
    value: 9.3,
    title: "Avg employees per supervisor"
  },
  {
    value: 16,
    title: "Average company tenure"
  },
  {
    value: 24,
    affix: "%",
    title: "Supervisors rated High expertise"
  },
  {
    value: 49,
    affix: "%",
    title: "Supervisors rated Medium Expertise"
  },
  {
    value: 27,
    affix: "%",
    title: "Supervisor rated Limited Expertise"
  }
];

export const officeKpiList = [
  {
    value: 6,
    title: "Total number of offices"
  },
  {
    value: 27.4,
    title: "Avg employees per office"
  },
  {
    value: 2.1,
    title: "Avg supervisors per office"
  }
];

export const employeeKpiList = [
  {
    value: 38,
    title: "Total number of employees"
  },
  {
    value: 12.7,
    title: "Average skills per employee"
  },
  {
    value: 16,
    title: "Average industry Experience (Years)"
  },
  {
    value: 7.3,
    title: "Average tenure (Years)"
  },
  {
    value: 24,
    affix: "%",
    title: "Employees rated High Expertise"
  },
  {
    value: 49,
    affix: "%",
    title: "Employees rated Medium Expertise"
  },
  {
    value: 27,
    affix: "%",
    title: "Employees rated Limited expertise"
  }
];

export const roleKpiList = [
  {
    value: 16,
    title: "Total number of roles"
  },
  {
    value: 2.4,
    title: "Avg employees per role"
  },
  {
    value: 2.1,
    affix: "yrs",
    title: "Avg tenure per role"
  },
  {
    value: 7.3,
    affix: "yrs",
    title: "Avg industry exp per role"
  },
  {
    value: 24,
    affix: "%",
    title: "Roles rated High expertise"
  },
  {
    value: 49,
    affix: "%",
    title: "Roles rated Medium Expertise"
  },
  {
    value: 27,
    affix: "%",
    title: "Roles rated Limited expertise"
  }
];
