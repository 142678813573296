import React, { useContext, useMemo, useState } from "react";
import classNames from "classnames";
import { GlobalStore } from "@common/api/store/global";
import { getPhrases } from "@common/helpers/localization";
import { TeamAnalytics } from "@common/types/reports/analytics/analytics";
import grid from "@styles/modules/grid.module.scss";
import { FirstLevelTable, Titles } from "@ui";
import { teamOverviewColumns } from "../model";
import { TeamView } from "./team-view";
import styles from "../report-overview.module.scss";
import { KpiTooltip } from "@features/reporting/kpi-tooltip";

interface TeamOverviewProps {
  route: string;
  onNavigate: (value: string) => void;
}

export const TeamOverview: React.FC<TeamOverviewProps> = ({ route, onNavigate }) => {
  const { $analyticsStore } = useContext(GlobalStore);
  const [loc] = useState(getPhrases("reporting_page", "overview"));
  const category = useMemo(() => (route.includes("byTeam>") ? route.split("byTeam>")[1].toLowerCase() : ""), [route]);
  const tableColumns = useMemo(() => {
    const [nameLink, ...overviewColumns] = teamOverviewColumns;
    nameLink.actionInfo = {
      onClick: value => {
        const teamId = $analyticsStore.teams.find(({ name }) => name === value)?.id;
        if (teamId) {
          onNavigate && onNavigate(`${route}>${teamId}`);
        }
      }
    };
    return [nameLink, ...overviewColumns];
  }, [route, $analyticsStore.teams]);
  const kpiList = useMemo(
    () => [
      {
        value: $analyticsStore.teamsSummary?.count || 0,
        title: "Total number of teams"
      },
      {
        value: $analyticsStore.teamsSummary?.employees_count || 0,
        title: "Total employees in teams"
      },
      {
        value: $analyticsStore.teamsSummary?.roles_count || 0,
        title: "Roles connected to teams"
      },
      {
        value: $analyticsStore.teamsSummary?.avg_skills_count || 0,
        title: "Average skills per employee"
      },
      {
        value: $analyticsStore.teamsSummary?.high_expertise_count || 0,
        affix: "",
        title: "Employees rated High Expertise",
        tooltip: {
          title: "HIGH EXPERTISE LEVEL",
          description:
            "Employees who are on target or above target for at least 75% of skills AND have an average overall score greater than or equal to their average target"
        }
      },
      {
        value: $analyticsStore.teamsSummary?.medium_expertise_count || 0,
        affix: "",
        title: "Employees rated Medium Expertise",
        tooltip: {
          title: "MEDIUM EXPERTISE LEVEL",
          description:
            "Employees who are on target or above target for 60-75% of skills OR have an average overall score greater than or equal to their average target"
        }
      },
      {
        value: $analyticsStore.teamsSummary?.near_expertise_count || 0,
        affix: "",
        title: "Employees rated Near Expertise",
        tooltip: {
          title: "NEAR EXPERTISE LEVEL",
          description:
            "Employees who are on target or above target for 50-60% of skills AND have an average overall score below their average target"
        }
      },
      {
        value: $analyticsStore.teamsSummary?.limited_expertise_count || 0,
        affix: "",
        title: "Employees rated Limited expertise",
        tooltip: {
          title: "LIMITED EXPERTISE LEVEL",
          description:
            "Employees who are on target or above target for less than 50% of skills AND have an average overall score below their average target"
        }
      },
      {
        value: $analyticsStore.teamsSummary?.employees_count
          ? $analyticsStore.teamsSummary?.employees_count -
            $analyticsStore.teamsSummary?.high_expertise_count -
            $analyticsStore.teamsSummary?.medium_expertise_count -
            $analyticsStore.teamsSummary?.near_expertise_count -
            $analyticsStore.teamsSummary?.limited_expertise_count
          : 0,
        affix: "",
        title: "Employees not rated"
      }
    ],
    [$analyticsStore.teamsSummary]
  );

  return (
    <div className={classNames(grid.container, styles["page-container"])}>
      {!category && (
        <>
          <Titles level="level_3" modClass={styles.title}>
            {loc.byTeamTitle} {loc.reporting}
          </Titles>
          <p className={styles.description}>{loc.description}</p>
          {$analyticsStore.teamsSummary && (
            <div className={styles["kpi-box-wrapper"]}>
              {kpiList.map((item, index) => (
                <React.Fragment key={item.title}>
                  {index > 0 && <div className={styles["kpi-box-divider"]} />}
                  <div className={styles["kpi-box"]}>
                    <div className="w-full flex justify-between">
                      <Titles level="level_3" modClass={classNames(styles["kpi-box-title"], "flex")}>
                        {item.value} {item.affix}
                      </Titles>
                      {item.tooltip && (
                        <KpiTooltip className="mt-2 mr-3 mb-auto" {...item.tooltip}>
                          <div className="w-4 h-4 flex justify-center items-center text-xs rounded-full border border-solid border-white cursor-pointer select-none">
                            ?
                          </div>
                        </KpiTooltip>
                      )}
                    </div>
                    <p className={classNames(styles["kpi-box-description"], styles.team)}>{item.title}</p>
                  </div>
                </React.Fragment>
              ))}
            </div>
          )}
          <div>
            {FirstLevelTable<TeamAnalytics>({
              data: $analyticsStore.teams,
              columns: tableColumns,
              modStyles: styles
            })}
          </div>
        </>
      )}
      {category && <TeamView teamId={category} />}
    </div>
  );
};
