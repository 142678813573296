import { getPhrases } from "@common/helpers/localization";
import { FormattedSkillsDataProps, SkillsDataProps } from "@common/types/reports/skills/skills-employee";
import { ModelDataInterface } from "@common/types/reports/common";
import { TableColumn, TableDetailRows } from "@common/types/table";
import classNames from "classnames";
import { DataType, SortDirection } from "ka-table/enums";
import grid from "@styles/modules/grid.module.scss";
import styles from "./../../../skills.module.scss";
import { FirstLevelTable, ProgressCell, RatingCell } from "@ui";
import { SecondLevel } from "./second-level";

const loc = getPhrases("skills_page", "table_employee");
export const FirstLevel = ({ modelData }: ModelDataInterface<SkillsDataProps>) => {
  const data: FormattedSkillsDataProps[] = modelData.map(item => {
    return {
      ...item,
      skills: item.skills.length > 0 ? item.skills : undefined
    };
  });
  const columns: TableColumn<FormattedSkillsDataProps>[] = [
    {
      key: "name",
      title: loc.title_category,
      dataType: DataType.String,
      sortDirection: SortDirection.Ascend,
      modClass: classNames(grid.item, grid["item-6x"], styles["first-cell"]),
      expandCell: true
    },
    {
      key: "interest_level",
      title: loc.interest_level,
      dataType: DataType.Number,
      modClass: classNames(grid.item, grid["item-3x"]),
      component: RatingCell
    },
    {
      key: "weighted_score",
      title: loc.title_score,
      dataType: DataType.Number,
      modClass: classNames(grid.item, grid["item-3x"]),
      component: ProgressCell
    }
  ];

  const detailRows: TableDetailRows<FormattedSkillsDataProps> = {
    component: SecondLevel,
    dataProperty: "skills"
  };

  return FirstLevelTable<FormattedSkillsDataProps>({
    data,
    columns,
    detailRows
  });
};
