import { $DEVELOPING_MODE } from "@common/api/config";
import { getPhrases } from "@common/helpers/localization";
import * as yup from "yup";
import YupPassword from "yup-password";
YupPassword(yup);

// TODO: Move interfaces
export interface LoginFormInterface {
  email?: string;
  password?: string;
}

export const defaultValues: LoginFormInterface = {
  email: "",
  password: ""
};

const loc = getPhrases("common", "forms").validation;
const passwordMinSpecial = `${loc.password} ${loc.min_special_middle}`;

// TODO: Think about optimize condition

const passwordScheme = $DEVELOPING_MODE
  ? yup.string().required(loc.required)
  : yup
      .string()
      .required(loc.required)
      .min(12, `${loc.min_characters_pre} 12 ${loc.min_characters_post}`)
      .minLowercase(4, `${passwordMinSpecial} 4 ${loc.min_lowercase_post}`)
      .minUppercase(2, `${passwordMinSpecial} 2 ${loc.min_uppercase_post}`)
      .minNumbers(2, `${passwordMinSpecial} 2 ${loc.digits}`)
      .minSymbols(2, `${passwordMinSpecial} 2 ${loc.symbols}`);

export const loginFormSchema: yup.SchemaOf<LoginFormInterface> = yup.object().shape({
  email: yup.string().required(loc.required).email(loc.email_format),
  password: passwordScheme
});
