import React, { useContext, useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { GlobalStore } from "@common/api/store/global";
import { EmployeeAnalytics, Analytics, SupervisorAnalytics } from "@common/types/reports/analytics/analytics";
import {
  employeeTeamOverviewColumns,
  skillTabColumns,
  supervisorTeamColumns,
  teamTabItems
} from "@features/reporting/report-overview/model";
import tabs from "@styles/modules/tabs.module.scss";
import { FirstLevelTable, Titles } from "@ui";
import { OrgUnit } from "@common/types/org-unit/org-unit";
import { getTeams } from "@common/features/analytics/model";
import styles from "../report-overview.module.scss";
import { toJS } from "mobx";

interface TeamViewProps {
  teamId: string;
  teamDescription?: string;
}

export const TeamView: React.FC<TeamViewProps> = ({ teamId }) => {
  const { $analyticsStore } = useContext(GlobalStore);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const selectedTeam = useMemo(
    () => $analyticsStore.teamTypes.find(teams => +teams.id === +teamId) as OrgUnit,
    [$analyticsStore.teamTypes, teamId]
  );
  const teamsByEmployees = useMemo(
    () => $analyticsStore.teamsByEmployees[teamId] || [],
    [$analyticsStore.teamsByEmployees[teamId], teamId]
  );
  const teamsBySupervisors = useMemo(
    () => $analyticsStore.teamsBySupervisors[teamId] || [],
    [$analyticsStore.teamsBySupervisors[teamId], teamId]
  );
  const sortByItems = useMemo(() => {
    switch (teamTabItems[activeTab].key) {
      case "supervisors":
        return teamsBySupervisors.map((item, index) => ({ value: index, label: item.full_name || "" })) || [];
      case "employees":
        return teamsByEmployees.map((item, index) => ({ value: index, label: item.full_name || "" })) || [];
      case "skills":
        return skillTabColumns.map((item, index) => ({ value: index, label: item.title || "" }));
    }
    return [];
  }, [activeTab]);

  useEffect(() => {
    if (
      !isLoading &&
      ((teamTabItems[activeTab].key === "supervisors" && teamsBySupervisors.length === 0) ||
        (teamTabItems[activeTab].key === "employees" && teamsByEmployees.length === 0))
    ) {
      setIsLoading(true);
      getTeams(+teamId, teamTabItems[activeTab].key).finally(() => {
        setIsLoading(false);
      });
    }
  }, [teamId, teamTabItems[activeTab].key, $analyticsStore.teamTypes]);

  return (
    <>
      <Titles level="level_3" modClass={styles.title}>
        {selectedTeam.name} team
      </Titles>
      <p className={styles["virtual-description"]}>
        The {selectedTeam.name} team is responsible for the maintenance and security of emulated and virtualized
        systems, company-wide and in specific locations according to necessity; click on a tab below to see more
        information about their activities
      </p>
      <Tabs
        className={tabs.tabs}
        selectedTabClassName={classNames(tabs["tab-selected"], styles["report-table-wrapper"])}
        onSelect={value => setActiveTab(value)}
      >
        <TabList controls={false} className={classNames(tabs.list, styles["overview-tab-wrapper"])}>
          {teamTabItems.map(teamName => (
            <Tab key={teamName.key} className={classNames(tabs.tab, styles["overview-tab-item"])}>
              {teamName.title}
            </Tab>
          ))}
        </TabList>
        {/*TODO: need to uncomment after integration*/}
        {/*<ReportFilter modClass={styles["report-filter"]} options={sortByItems} />*/}
        <TabPanel className={tabs.panel}>
          {FirstLevelTable<EmployeeAnalytics>({
            data: teamsByEmployees,
            columns: employeeTeamOverviewColumns,
            modStyles: styles
          })}
        </TabPanel>
        <TabPanel className={tabs.panel}>
          {FirstLevelTable<SupervisorAnalytics>({
            data: teamsBySupervisors,
            columns: supervisorTeamColumns,
            modStyles: styles
          })}
        </TabPanel>
        {/*<TabPanel className={tabs.panel}>*/}
        {/*</TabPanel>*/}
        {/*<TabPanel className={tabs.panel}>*/}
        {/*</TabPanel>*/}
        {/*<TabPanel className={tabs.panel}>*/}
        {/*  {FirstLevelTable<Analytics>({*/}
        {/*    data: SkillOverviewData,*/}
        {/*    columns: skillTabColumns,*/}
        {/*    modStyles: styles,*/}
        {/*  })}*/}
        {/*</TabPanel>*/}
      </Tabs>
    </>
  );
};
