import { ForgotForm } from "@features/user/forms";
import { AlertModal, Button, Titles } from "@ui";
import styles from "./forgot-modal.module.scss";
import { getPhrases } from "@common/helpers/localization";
import { useState } from "react";
import { ForgotFormProps } from "@features/user/forms/forgot/types";

export const ForgotModal = (props: ForgotFormProps) => {
  const loc = getPhrases("login_page", "reset_modal");
  const [isSuccess, setModalType] = useState<boolean>();
  const [sendModalIsOpen, setSendModalIsOpen] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();

  const onCloseModal = () => {
    setSendModalIsOpen(false);
    props.onClose();
  };

  return (
    <>
      <Titles level="level_1">{loc.title}</Titles>
      <p className={styles.text}>{loc.description}</p>
      <ForgotForm
        {...props}
        failCallback={() => {
          setModalType(false);
          setSendModalIsOpen(true);
        }}
        successCallback={(email: string) => {
          setEmail(email);
          setModalType(true);
          setSendModalIsOpen(true);
        }}
      />
      <AlertModal closeModal={onCloseModal} isOpen={sendModalIsOpen}>
        {isSuccess ? (
          <>
            An email has been sent to <span className={styles["alert-email"]}>{email}</span> with details of how to
            reset your password. Please check your email in order to log back in
          </>
        ) : (
          <>
            <Titles level="level_1">There seems to be a problem</Titles>
            Something went wrong. Please try again or try again later
          </>
        )}

        <Button title={"Close"} theme="gray" onClick={onCloseModal} className={styles["alert-button"]} />
      </AlertModal>
    </>
  );
};
