import { TableCellProps, TableColumn } from "@common/types/table";
import classNames from "classnames";
import styles from "./class-mapper.module.scss";

export const ClassMapper =
  (className: string = "") =>
  ({ props }: TableCellProps) => {
    const { value, column } = props;
    const { decorator } = column as TableColumn<string>;
    return (
      <div className={classNames(styles.status, className, "text-xs rounded-sm")}>
        {decorator ? decorator(props) : value}
      </div>
    );
  };
