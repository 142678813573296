import { getPeopleService } from "@common/api/services/people";
import { People, PeopleByOrgUnit, PeopleSubordinates } from "@common/types/reports/people/people";

// TODO: Move this model to common features

export const getPeopleByManager = async (showActive: boolean, callback?: any) => {
  try {
    const response = await getPeopleService<People>("/web/people/by-managers", {
      params: { "show-inactive": showActive }
    });
    callback && callback(response.data.data);
  } catch (e) {
    console.error("Get people by manager error");
  }
};

export const getPeopleByMyDirect = async (callback: any) => {
  try {
    const response = await getPeopleService<PeopleSubordinates>("/web/people/direct");
    callback(response.data.data);
  } catch (e) {
    console.error("Get people by my direct error");
  }
};
export const getPeopleByOrgUnit = async (showActive: boolean, callback: any) => {
  try {
    const response = await getPeopleService<PeopleByOrgUnit>("/web/people/by-org-units", {
      params: { "show-inactive": showActive }
    });
    callback(response.data.data);
  } catch (e) {
    console.error("Get people by org units error");
  }
};
