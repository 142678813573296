import { $assessmentsStore } from "@common/api/store/assessments";
import classNames from "classnames";
import styles from "./level-setter.module.scss";
import { observer } from "mobx-react-lite";

interface LevelSetterInterface {
  maxLevel: number;
  currentCategory: string;
}

export const LevelSetter: React.FC<LevelSetterInterface> = observer(({ maxLevel, currentCategory }) => {
  const levels = [];
  const widthValue = 100 / (maxLevel + 1);
  const width = `${widthValue}%`;
  const margin = `-${widthValue / 4}%`;
  for (let i = 0; i <= maxLevel; i++) {
    levels.push(i);
  }
  const level = $assessmentsStore.getAssessmentCategorySetterById(currentCategory);
  const currentLevel = level ?? 0;
  return (
    <div className={styles.wrapper} style={{ marginLeft: margin, marginRight: margin }}>
      <div className={styles.list}>
        {levels.map(item => {
          const itemClass = classNames(styles.item, item === currentLevel && styles["item-current"]);

          return (
            <div
              className={itemClass}
              key={`level-item-${item}`}
              onClick={() => $assessmentsStore.setAssessmentCategorySetter(currentCategory, item)}
              style={{ width }}
            >
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
});
