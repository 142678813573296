import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { getAllSkills, getSkillCategories } from '@common/features/skills/model';
import { useForm } from '@common/hooks/form';
import { InputRadioOption } from '@common/types/form';
import { IRoleForm } from '@common/types/forms/role';
import { FormInputAutocomplete, FormInputRadioGroup, FormWrapper, RangeSlider } from '@ui';
import { IRoleFormTabInitData } from '../index';
import { FormError } from '../form-error';
import { DetailsButton } from '../../../../../../ui/table/details-button';
import { ICellTextProps } from 'ka-table/props';
import { FieldPath } from 'react-hook-form/dist/types/path';
import { ArrayPath, FieldArrayPath } from 'react-hook-form/dist/types/path/eager';
import { ISkillCategory } from '@features/skills/skill-categories-form';
import classNames from "classnames";
import styles from "./skills-tab.module.scss";
import { UseFormReturn } from 'react-hook-form';
import { FormInputCheckbox } from 'src/ui/form/input-checkbox';

export interface IRoleSkillsTabProps {
  formData: Partial<IRoleForm>;
  onInit?: (data: IRoleFormTabInitData) => void;

  onBack(): void;

  onNext(): void;

  onSubmit(data: Partial<IRoleForm>): void;

  onClose(): void;
}

interface ISkills {
  id: number;
  name: string;
  category: string;
  target: number;
  important: boolean;
  frequentlyUsed: boolean;
  frequentlyUsedInFuture: boolean;
}

interface IRoleSkillsForm {
  skillName?: string;
  skillType?: number;
  skills: ISkills[];
}

interface ISkill {
  id: number;
  name: string;
  category?: string;
  children?: ISkill[];
  skillType?: number;
}

const skillsFormSchema: yup.SchemaOf<IRoleSkillsForm> = yup.object().shape({
  skillName: yup.string(),
  skillType: yup.number(),
  skills: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().required(),
        name: yup.string().required("This field is required"),
        category: yup.string().required("This field is required"),
        target: yup.number().required("This field is required"),
        important: yup.boolean().required("This field is required"),
        frequentlyUsed: yup.boolean().required("This field is required"),
        frequentlyUsedInFuture: yup.boolean().required("This field is required")
      })
    )
    .min(1)
});

interface IGroupedSkills {
  groupSkills: {
    important?: boolean;
    frequentlyUsed?: boolean;
    frequentlyUsedInFuture?: boolean;
  }[];
}

const groupedSkillsFormSchema: yup.SchemaOf<IGroupedSkills> = yup.object().shape({
  groupSkills: yup.array().of(
    yup.object().shape({
      important: yup.boolean(),
      frequentlyUsed: yup.boolean(),
      frequentlyUsedInFuture: yup.boolean()
    })
  )
});

const skillTypes: InputRadioOption[] = [{ label: "All Skills", value: 0 }];

const yesNoOptions: InputRadioOption[] = [
  { label: "Yes", value: true },
  { label: "No", value: false }
];

export const RoleSkillsTab: FC<IRoleSkillsTabProps> = ({ formData, onInit, onBack, onNext, onSubmit, onClose }) => {
  const groupMethods = useForm<IGroupedSkills>({
    schema: groupedSkillsFormSchema,
    defaultValues: {
      groupSkills: []
    }
  });

  const methods = useForm<IRoleSkillsForm>({
    schema: skillsFormSchema,
    defaultValues: {
      skillName: "",
      skillType: 0,
      skills: formData.skills || []
    }
  });
  const data = methods.getValues();
  const [skills, setSkills] = useState<ISkill[]>([]);
  const [timestamp, setTimestamp] = useState<number>();
  const [showFormError, setShowFormError] = useState(false);
  const [collapsingInfo, setCollapsingInfo] = useState<boolean[]>([]);
  const [categories, setCategories] = useState<InputRadioOption[]>([]);
  const skillType = methods.watch("skillType") || 0;

  useEffect(() => {
    getSkillCategories().then((categories: ISkillCategory[]) => {
      categories.forEach(category => {
        if (category.parent_id) {
          const parentCategory = categories.find(item => item.id === category.parent_id);
          if (parentCategory) {
            if (parentCategory.children) {
              parentCategory.children.push(category);
            } else {
              parentCategory.children = [category];
            }
          }
        }
      });
      setCategories([
        ...skillTypes,
        ...categories.filter(item => !item.parent_id).map(item => ({ value: item.id, label: item.name }))
      ]);
    });
  }, []);

  useEffect(() => {
    getAllSkills().then(skills => {
      const categories: ISkill[] = [];
      skills.forEach(skill => {
        const category = categories.find(item => item.id === skill.category.id);
        if (category) {
          category.children!.push({
            id: skill.id,
            name: skill.name,
            category: skill.category.name,
            skillType: skill.category.parent_id
          });
        } else {
          categories.push({
            id: skill.category.id,
            name: skill.category.name,
            skillType: skill.category.parent_id,
            children: [
              {
                id: skill.id,
                name: skill.name,
                category: skill.category.name,
                skillType: skill.category.parent_id
              }
            ]
          });
        }
      });
      setSkills(categories);
    });
  }, []);

  const groups: string[] = [];
  data.skills.forEach(item => {
    if (!groups.includes(item.category)) {
      groups.push(item.category);
    }
  });

  const filteredSkills = useMemo(() => {
    const selectedSkills = data.skills.map(item => item.id);
    return skills
      .map(skill => ({
        ...skill,
        children: skill.children!.filter(item => !selectedSkills.includes(item.id))
      }))
      .filter(skill => (+skillType === 0 || +skillType === skill.skillType) && skill.children.length);
  }, [skills, data.skills, skillType]);

  useEffect(() => {
    if (onInit) {
      if (showFormError) {
        onInit({ buttons: undefined });
      } else {
        onInit({
          buttons: {
            leftSide: {
              title: (
                <span>
                  Cancel
                  <br />
                  And Close
                </span>
              ),
              theme: "secondary-gradient",
              className: "!w-30 !h-14 !text-sm !text-left !font-semibold",
              onClick: onClose
            },
            rightSide: [
              {
                title: "Prev",
                theme: "warning",
                shape: "left-arrow",
                className: "!w-20 !text-sm !font-semibold",
                onClick() {
                  goBack();
                }
              },
              {
                title: "Next",
                theme: "warning",
                shape: "right-arrow",
                className: "!w-20 !text-sm !font-semibold",
                onClick() {
                  methods.handleSubmit(handleSuccess, handleError)();
                }
              }
            ]
          }
        });
      }
    }
  }, [onInit, showFormError]);

  const onFieldChange = (field: FieldPath<IRoleSkillsForm>, value: any) => {
    methods.setValue(field, value);
    methods.trigger(field);
    setTimestamp(new Date().getTime());
  };

  const onAddSkill = (skill: ISkill) => {
    const skills = [
      ...data.skills,
      {
        ...skill,
        target: 1,
        important: false,
        frequentlyUsed: false,
        frequentlyUsedInFuture: false
      }
    ];
    onFieldChange("skillName", "");
    onFieldChange("skills", skills);
  };

  const onAddSkills = (skills: ISkill[]) => {
    if (skills.length) {
      const updatedSkills = [
        ...data.skills,
        ...skills.map(skill => ({
          ...skill,
          target: 1,
          important: false,
          frequentlyUsed: false,
          frequentlyUsedInFuture: false
        }))
      ];
      onFieldChange("skillName", "");
      onFieldChange("skills", updatedSkills);
    }
  };

  const onPatchSkill = (id: number, field: string, value: any) => {
    const skills = data.skills.map(item => (item.id === id ? { ...item, [field]: value } : item));
    onFieldChange("skills", skills);
  };

  const onPatchSkills = (
    group: string,
    field: "target" | "important" | "frequentlyUsed" | "frequentlyUsedInFuture",
    value: any
  ) => {
    data.skills.forEach((item, index) => {
      if (item.category === group) {
        // @ts-ignore
        onFieldChange(`skills[${index}].${field}`, isNaN(+`${value}`) ? `${value}` === "true" : +value);
      }
    });
  };

  const onPatchSkillsType = (
    id: number,
    field: 'target' | 'important' | 'frequentlyUsed' | 'frequentlyUsedInFuture',
    value: any
  ) => {
    data.skills.forEach((item, index) => {
      if (item.id === id) {
        // @ts-ignore
        onFieldChange(`skills[${index}][${field}]`, isNaN(+`${value}`) ? `${value}` === 'true' : +value);
      }
    });
  };

  const onRemoveSkill = (id: number) => {
    const skills = data.skills.filter(item => item.id !== id);
    onFieldChange("skills", skills);
  };

  const onRemove = (skill: ISkill) => {
    const skills = data.skills.filter(item => item.id !== skill.id);
    onFieldChange("skills", skills);
  };

  useEffect(() => {
    setCollapsingInfo(groups.map(() => false));
  }, [groups.length]);

  const toggleCollapse = useCallback(
    index => {
      collapsingInfo[index] = !collapsingInfo[index];
      setCollapsingInfo([...collapsingInfo]);
    },
    [collapsingInfo]
  );

  const groupSkillTargets = useMemo(() => {
    return groups.map(group => {
      const targetValues = data.skills
        .filter(item => item.category === group)
        .map(skill => skill.target)
        .reduce((prev, target) => {
          prev[target] = (prev[target] || 0) + 1;
          return prev;
        }, {} as { [key: number | string]: number });
      const maxCount = Math.max(...Object.values(targetValues));
      return +(Object.entries(targetValues).find(([target, count]) => count === maxCount)?.[0] || 1);
    });
  }, [groups, data.skills]);

  const handleSuccess = (values: IRoleSkillsForm) => {
    onSubmit(values);
    onNext();
  };

  const handleError = () => {
    setShowFormError(true);
  };

  const goBack = () => {
    onSubmit(methods.getValues());
    onBack();
  };

  return (
    <FormWrapper className="text-black" onSubmit={methods.handleSubmit(handleSuccess, handleError)}>
      <div className="grid sm:grid-cols-2 gap-4 border-b border-gray-cd px-5 pb-4">
        <div>
          <h6 className="text-primary text-sm font-bold mb-1">
            <i className="fa fa-caret-down" /> This is where you locate skills and map them to the role
          </h6>
          <p className="text-xs">
            In the text field below, please type the name of the skill you require, and then click on the resulting list
            of skills to add it to the table. You can filter the results by hard or soft skill, or leave default to
            return results for both
          </p>
        </div>
      </div>

      <div className="grid grid-cols-1fr-auto gap-4 px-5 py-4">
        <FormInputAutocomplete
          className="!text-sm !text-black !placeholder-secondary placeholder-italic"
          name="skillName"
          label="Skill Name"
          placeholder="Type the name of your skill to select it"
          list={filteredSkills}
          multiSelectable
          group
          getItemLabel={(item: ISkill) => item.name}
          getItemValue={(item: ISkill) => item}
          dropdownClass="max-h-40 overflow-auto !mt-0"
          dropdownItemClass="!text-sm"
          register={methods.register}
          onChange={onAddSkill}
          onChangeBulk={onAddSkills}
        />
        <div className="flex items-center">
          <FormInputRadioGroup
            className="radio-gray mt-5"
            name="skillType"
            size="sm"
            direction="horizontal"
            options={categories}
            defaultValue={0}
            methods={methods}
          />
        </div>
      </div>

      <div className="min-h-40 w-full">
        <table className="w-full text-sm mt-2">
          <thead>
            <tr className="text-gray text-xs text-left font-semibold border-b border-gray-cd">
              <th className="pl-5 pr-4 pb-1">
                Category / Skill name
                <br />
                Skill type
              </th>
              <th className="min-w-[150px] pr-4 pb-1">
                Skill Target
                <br />
                From 1-10
              </th>
              <th className="pr-4 pb-1">
                Skill is
                <br />
                Important?
              </th>
              <th className="pr-4 pb-1">
                Skill is
                <br />
                frequently used?
              </th>
              <th className="pr-4 pb-1">
                Skill will be frequently
                <br />
                used in the future?
              </th>
              <th className="pr-5 pb-1" />
            </tr>
          </thead>
          <tbody>
            {groups.map((group, i) => {
              const items = data.skills.filter(item => item.category === group);
              return (
                <React.Fragment key={i}>
                  <RowSkill
                    i={i}
                    collapsingInfo={collapsingInfo}
                    toggleCollapse={toggleCollapse}
                    group={group}
                    groupSkillTargets={groupSkillTargets}
                    onPatchSkills={onPatchSkills}
                    onPatchSkillsType={onPatchSkillsType}
                    groupMethods={groupMethods}
                    items={items}
                    data={data}
                    onPatchSkill={onPatchSkill}
                    onRemoveSkill={onRemoveSkill}
                    methods={methods}
                  />
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>

      {showFormError && (
        <FormError className="w-full h-full absolute top-0 left-0 z-100" onClose={() => setShowFormError(false)} />
      )}
    </FormWrapper>
  );
};

interface IRowSkill {
  i: number
  collapsingInfo: boolean[]
  toggleCollapse: (index: number) => void
  group: string
  groupSkillTargets: number[]
  onPatchSkills: (group: string, field: 'target' | 'important' | 'frequentlyUsed' | 'frequentlyUsedInFuture', value: any) => void
  onPatchSkillsType: (id: number, field: 'target' | 'important' | 'frequentlyUsed' | 'frequentlyUsedInFuture', value: any) => void
  groupMethods: UseFormReturn<IGroupedSkills, object>
  items: ISkills[]
  data: IRoleSkillsForm
  onPatchSkill: (id: number, field: string, value: any) => void
  onRemoveSkill: (id: number) => void
  methods: UseFormReturn<IRoleSkillsForm, object>
}

const RowSkill = ({
  i, 
  collapsingInfo, 
  toggleCollapse, 
  group, 
  groupSkillTargets, 
  onPatchSkills,
  onPatchSkillsType,
  groupMethods, 
  items,
  data,
  onPatchSkill,
  onRemoveSkill,
  methods
}: IRowSkill) => {
  const [classHighlightFrequentlyUsed, setClassHighlightFrequentlyUsed] = useState<string>("")
  const [classHighlightFrequentlyUsedInFuture, setClassHighlightFrequentlyUsedInFuture] = useState<string>("")

  useEffect(() => {
    if(items.every((item) => item["frequentlyUsed"] === true)) {
      setClassHighlightFrequentlyUsed("true")
    } 
    else if (items.every((item) => item["frequentlyUsed"] === false)) {
      setClassHighlightFrequentlyUsed("false")
    } 
    else {
      setClassHighlightFrequentlyUsed("highlight")
    }
  }, [items])

  useEffect(() => {
    if(items.every((item) => item["frequentlyUsedInFuture"] === true)) {
      setClassHighlightFrequentlyUsedInFuture("true")
    } 
    else if(items.every((item) => item["frequentlyUsedInFuture"] === false)) {
      setClassHighlightFrequentlyUsedInFuture("false")
    } 
    else {
      setClassHighlightFrequentlyUsedInFuture("highlight")
    }
  }, [items])

  return (
    <>
      <tr>
        <td className="font-semibold px-5 py-3" colSpan={1}>
          <DetailsButton
            {...({} as ICellTextProps)}
            dispatch={() => toggleCollapse(i)}
            rowKeyValue={i}
            isDetailsRowShown={collapsingInfo[i]}
          >
            {group}
          </DetailsButton>
        </td>
        <td>
          <div className="h-5 bg-gray-f5 flex items-center pr-6">
            <RangeSlider
              className="slider-primary rounded-none size-sm show-value"
              min={1}
              max={10}
              value={groupSkillTargets[i]}
              onChange={(value) => onPatchSkills(group, 'target', value)}
            />
          </div>
        </td>
        <td>
          <div className={classNames(styles["skill-is-important-column"], "h-5 bg-gray-f5 flex items-center pr-6")}>
            <FormInputCheckbox 
              className="checkbox-gray"
              name={`groupSkills[${i}].important`}
              checked={true}
              value={true}
              disabled={true}
            />
            {/* <FormInputRadioGroup
              className="radio-gray"
              name={`groupSkills[${i}].important`}
              size="sm"
              direction="horizontal"
              options={yesNoOptions}
              defaultValue={true}
              onChange={(e) => onPatchSkills(group, 'important', e.target.value === 'true')}
              methods={groupMethods}
              disabled={true}
              isSkipValue={true}
            /> */}
          </div>
        </td>
        <td>
          <div className="h-5 bg-gray-f5 flex items-center pr-6">
            <FormInputCheckbox 
              className={classNames(
                classHighlightFrequentlyUsed,
                "checkbox-gray"
              )}
              name={`groupSkills[${i}].frequentlyUsed`}
              checked={classHighlightFrequentlyUsed === "true" ? true : false}
              value={true}
              onChange={(e) => onPatchSkills(group, 'frequentlyUsed', e.target.checked)}
            />
            {/* <FormInputRadioGroup
              className={classNames(
                classHighlightFrequentlyUsed,
                "radio-gray"
              )}
              name={`groupSkills[${i}].frequentlyUsed`}
              size="sm"
              direction="horizontal"
              options={yesNoOptions}
              defaultValue={classHighlightFrequentlyUsed === "true" ? true : false}
              onChange={(e) => onPatchSkills(group, 'frequentlyUsed', e.target.value === 'true')}
              methods={groupMethods}
            /> */}
          </div>
        </td>
        <td>
          <div className="h-5 bg-gray-f5 flex items-center pr-6">
            <FormInputCheckbox 
              className={classNames(
                classHighlightFrequentlyUsedInFuture,
                "checkbox-gray"
              )}
              name={`groupSkills[${i}].frequentlyUsed`}
              checked={classHighlightFrequentlyUsedInFuture === "true" ? true : false}
              value={true}
              onChange={(e) => onPatchSkills(group, 'frequentlyUsedInFuture', e.target.checked)}
            />
            {/* <FormInputRadioGroup
              className={classNames(
                classHighlightFrequentlyUsedInFuture,
                "radio-gray"
              )}
              name={`groupSkills[${i}].frequentlyUsedInFuture`}
              size="sm"
              direction="horizontal"
              options={yesNoOptions}
              defaultValue={classHighlightFrequentlyUsedInFuture === "true" ? true : false}
              onChange={(e) => onPatchSkills(group, 'frequentlyUsedInFuture', e.target.value === 'true')}
              methods={groupMethods}
            /> */}
          </div>
        </td>
        <td className="pr-5">
          <div className="h-5 flex items-center text-right pr-1 opacity-0">
            <i className="fa fa-times" />
          </div>
        </td>
      </tr>
      {collapsingInfo[i] && items.map((item) => {
        const index = data.skills.indexOf(item);
        return (
          <tr key={item.id} className="align-top">
            <td className="pl-5 pb-1">
              <div className="flex items-center bg-gray-f5">
                <div className="px-6">{item.name}</div>
              </div>
            </td>
            <td>
              <div className="h-5 bg-gray-f5 flex items-center pr-6">
                <RangeSlider
                  className="slider-primary rounded-none size-sm show-value"
                  min={1}
                  max={10}
                  value={item.target}
                  onChange={(value) => onPatchSkill(item.id, 'target', value)}
                />
              </div>
            </td>
            <td>
              <div className={classNames(styles["skill-is-important-column"], "h-5 bg-gray-f5 flex items-center pr-6")}>
                <FormInputCheckbox 
                  className="checkbox-gray"
                  name={`skills[${index}].important`}
                  checked={true}
                  value={true}
                  disabled={true}
                />
                {/* <FormInputRadioGroup
                  className="radio-gray"
                  name={`skills[${index}].important`}
                  size="sm"
                  direction="horizontal"
                  options={yesNoOptions}
                  defaultValue={true}
                  isSkipValue={true}
                  methods={methods}
                /> */}
              </div>
            </td>
            <td>
              <div className="h-5 bg-gray-f5 flex items-center pr-6">
                <FormInputCheckbox 
                  className="checkbox-gray"
                  name={`skills[${index}].frequentlyUsed`}
                  checked={item.frequentlyUsed}
                  value={true}
                  onChange={(e) => onPatchSkillsType(item.id, 'frequentlyUsed', e.target.checked)}
                />
                {/* <FormInputRadioGroup
                  className="radio-gray"
                  name={`skills[${index}].frequentlyUsed`}
                  size="sm"
                  direction="horizontal"
                  options={yesNoOptions}
                  defaultValue={false}
                  methods={methods}
                  onChange={(e) => onPatchSkillsType(item.id, 'frequentlyUsed', e.target.value === 'true')}
                /> */}
              </div>
            </td>
            <td>
              <div className="h-5 bg-gray-f5 flex items-center pr-6">
                <FormInputCheckbox 
                  className="checkbox-gray"
                  name={`skills[${index}].frequentlyUsedInFuture`}
                  checked={item.frequentlyUsedInFuture}
                  value={true}
                  onChange={(e) => onPatchSkillsType(item.id, 'frequentlyUsedInFuture', e.target.checked)}
                />
                {/* <FormInputRadioGroup
                  className="radio-gray"
                  name={`skills[${index}].frequentlyUsedInFuture`}
                  size="sm"
                  direction="horizontal"
                  options={yesNoOptions}
                  defaultValue={false}
                  methods={methods}
                  onChange={(e) => onPatchSkillsType(item.id, 'frequentlyUsedInFuture', e.target.value === 'true')}
                /> */}
              </div>
            </td>
            <td className="pr-5">
              <div className="h-5 bg-gray-f5 flex items-center text-right pr-1">
                <i
                  className="fa fa-times text-warning cursor-pointer"
                  onClick={() => onRemoveSkill(item.id)}
                />
              </div>
            </td>
          </tr>
        );
      })}
    </>
  );
}
