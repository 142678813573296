import React from "react";
import styles from "./tile-cards.module.scss";
import Grid from "@styles/modules/grid.module.scss";
import classNames from "classnames";
import TileCard from "./TileCard/TileCard";
import { ContentTypeEnum } from "@common/types/reports/learn/learn";
import { Link } from "react-router-dom";
import { Course } from "../types";

interface TileCardsProps {
  CoursesData: Course[];
  refresh?: () => void;
  handleClickEdit: (id: number) => void;
}

const TileCards = ({ CoursesData, refresh, handleClickEdit }: TileCardsProps) => {
  return (
    <div className={styles.tileCardsContainer}>
      <div className={classNames(Grid.list, styles.tileCards)}>
        <div className={classNames(classNames(Grid["item-2x"], styles.favourite))}>Favorite?</div>
        <div className={classNames(classNames(Grid["item-3x"], styles.name))}>Name</div>
        <div className={classNames(classNames(Grid["item-2x"]), styles.rating)}>Rating</div>
        <div className={classNames(classNames(Grid["item-2x"]), styles.duration)}>Duration</div>
        <div className={classNames(classNames(Grid["item-3x"], styles.levenAndPrice))}>
          <div>Level</div>
          <div>Price</div>
        </div>
      </div>

      {/*Tile cards*/}
      <div className={styles.cards}>
        {CoursesData.map(data => {
          return <TileCard key={`course-${data.id}`} {...data} handleClickEdit={handleClickEdit} />;
        })}
      </div>
    </div>
  );
};

export default TileCards;
