import styles from "./additional-report-manager.module.scss";
import { Button, FormInputText, FormWrapper, Svg } from "@ui";
import { AccountabilitiesLegend } from "../legend";
import { useContext, useEffect, useState } from "react";
import { AccountabilitiesMembersParams } from "@common/types/reports/accountabilities/accountabilities";
import {
  getAccountabilitiesMembersParams,
  setAccountabilitiesMembersParams
} from "@common/features/accountabilities/model";
import * as yup from "yup";
import { AccountabilitiesMembersFormParams } from "@common/types/forms/accountabilities";
import { useForm } from "@common/hooks/form";
import { FieldErrors } from "react-hook-form";
import { GlobalStore } from "@common/api/store/global";
import { BannerType } from "@common/api/types/common";
import { RegExp } from "@common/helpers/forms";

interface Props {
  name?: string;
  profileId: number;
}

export const AdditionalReportManager = ({ name, profileId }: Props) => {
  const { $commonStore } = useContext(GlobalStore);
  const [isEditable, setEditableStatus] = useState<boolean>(false);
  const [params, setParams] = useState<AccountabilitiesMembersParams>();
  const [currentLink, setCurrentLink] = useState<string>();

  useEffect(() => {
    getAccountabilitiesMembersParams(profileId, setParams);
  }, []);

  useEffect(() => {
    setCurrentLink(params?.custom_report_url ?? "");
  }, [params]);

  const text = `${name && `${name}'s `}Additional Reports`;

  const paramsSchema: yup.SchemaOf<AccountabilitiesMembersFormParams> = yup.object().shape({
    custom_report_url: yup.string().matches(RegExp.url, "Please enter a valid URL")
  });

  const methods = useForm<AccountabilitiesMembersFormParams>({
    schema: paramsSchema,
    defaultValues: {
      ...(params?.custom_report_url && {
        custom_report_url: params.custom_report_url
      })
    }
  });

  useEffect(() => {
    methods.reset({
      ...(params?.custom_report_url && {
        custom_report_url: params.custom_report_url
      })
    });
  }, [params, methods]);

  const handleSuccess = (values: AccountabilitiesMembersFormParams) => {
    setAccountabilitiesMembersParams(profileId, values)
      .then(data => {
        $commonStore.setBanner(BannerType.success, "URL has been added successfully");
        methods.clearErrors();
        setCurrentLink(values.custom_report_url);
        setEditableStatus(false);
      })
      .catch(errors => $commonStore.setBanner(BannerType.error, "Something went wrong, please try again later"));
  };

  const handleError = (errors: FieldErrors<AccountabilitiesMembersFormParams>) => {
    console.error(errors);
  };

  const onCancel = () => {
    setEditableStatus(false);
    methods.clearErrors();
  };

  return (
    <div className={styles.intro}>
      <div className={styles["intro-info"]}>
        <AccountabilitiesLegend />
      </div>
      <div className={styles["intro-reports"]}>
        {!isEditable ? (
          <div className={styles.link}>
            {currentLink ? (
              <a target="_blank" href={currentLink} rel="noreferrer">
                {text}
              </a>
            ) : (
              text
            )}
            <Svg name="edit" modClass={styles["link-icon"]} onClick={() => setEditableStatus(true)} />
          </div>
        ) : (
          <div>
            <FormWrapper className={styles.wrapper} onSubmit={methods.handleSubmit(handleSuccess, handleError)}>
              <FormInputText
                name="custom_report_url"
                placeholder="Set URL"
                register={methods.register}
                error={methods.formState.errors?.custom_report_url?.message}
              />
              <Button type="submit" size="thin" title="Save URL" className={styles.button} theme="blue" />
              <Button
                type="button"
                size="thin"
                title="Cancel"
                className={styles.button}
                theme="gray"
                onClick={onCancel}
              />
            </FormWrapper>
          </div>
        )}
      </div>
    </div>
  );
};
