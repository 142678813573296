import { Route, Routes } from "react-router-dom";
import { DefaultLayout } from "../../layouts";
import { LoginPage } from "@pages";

export const Auth = () => {
  return (
    <DefaultLayout>
      <Routes>
        <Route path="/" element={<LoginPage />}></Route>
      </Routes>
    </DefaultLayout>
  );
};
