import React, { FC, useState } from "react";
import classNames from "classnames";
import { Tab, TabList, Tabs } from "react-tabs";
import { ComplexModal, ComplexModalButtons, Switch } from "@ui";
import { setRoleAccountabilityDetail, setRoleAccountabilities } from "@common/features/accountabilities/model";
import { setAssessmentIntervalSetting } from "@common/features/assessments/model";
import { setResponsibilities } from "@common/features/responsibilities/model";
import { attachSkillsToRole, createRole, getRoleSummaries } from "@common/features/roles/model";
import { IRoleForm } from "@common/types/forms/role";
import { Responsibility } from "@common/types/responsibilities/responsibilities";
import { Accountability } from "@common/types/reports/accountabilities/accountabilities";
import { RoleSkill } from "@common/types/roles/roles";
import tabs from "@styles/modules/tabs.module.scss";
import { RoleForm } from "../../role-form";
import { SavingError } from "../../role-form/saving-error";

interface AddRoleModalProps {
  closeModal: () => void;
  isOpen: boolean;
}

export const AddRoleModal: FC<AddRoleModalProps> = ({ isOpen, closeModal }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [apiError, setApiError] = useState("");
  const [buttons, setButtons] = useState<ComplexModalButtons>();
  const [formData, setFormData] = useState<Partial<IRoleForm>>({
    isActive: true,
    isManagerial: true
  });

  const tabsHeader = (
    <Tabs
      selectedIndex={tabIndex}
      className={tabs.tabs}
      selectedTabClassName={tabs["tab-selected"]}
      disabledTabClassName={tabs["tab-disabled"]}
    >
      <TabList className={classNames(tabs.list, "mt-2")}>
        <Tab className={tabs.tab} disabled>
          01 Details
        </Tab>
        <Tab className={tabs.tab} disabled>
          02 Skills
        </Tab>
        <Tab className={tabs.tab} disabled>
          03 Skills Assessments
        </Tab>
        <Tab className={tabs.tab} disabled>
          04 Accountabilities
        </Tab>
      </TabList>
    </Tabs>
  );

  const onFieldChange = (field: string, value: any) => {
    setFormData({
      ...formData,
      [field]: value
    });
  };

  const onFormDataChange = (data: Partial<IRoleForm>) => {
    const newData = {
      ...formData,
      ...data
    };
    setFormData(newData);
    return newData;
  };

  const onSubmit = async (data: IRoleForm) => {
    console.log("submit", data);

    const role = await createRole({
      name: data.name,
      unit_id: data.orgUnit,
      is_managerial: data.isManagerial,
      is_active: data.isActive,
      job_purpose: data.description,
      description: data.description
    }).catch(e => {
      if (e?.response?.data?.message) {
        setApiError(e.response.data.message);
        setTabIndex(0);
      }
    });

    if (role) {
      await Promise.all([
        setResponsibilities(
          role.id,
          data.responsibilities!.map(item => ({ title: item.title, description: item.description }))
        ).then((responsibilities: Responsibility[]) => {
          console.log(responsibilities);
        }),

        setAssessmentIntervalSetting(role.id, {
          is_enabled: data.assessmentIntervalEnabled,
          assessment_interval: data.overallAssessmentInterval || 1
        }).then(setting => {
          console.log(setting);
        }),

        setRoleAccountabilityDetail(role.id, {
          is_enabled: !!data.accountabilityInterval,
          // starting_month: new Date(data.accountabilityStartDate).getMonth() + 1,
          // ...(!!data.accountabilityInterval ? { interval_months: data.accountabilityInterval } : {}),
          interval_months: data.accountabilityInterval || 1
        }).then(accountabilitySetting => {
          console.log(accountabilitySetting);
        }),

        attachSkillsToRole(
          role.id,
          data.skills!.map(item => {
            let importance = 0;
            if (item.important) {
              importance |= 1;
            }
            if (item.frequentlyUsed) {
              importance |= 2;
            }
            if (item.frequentlyUsedInFuture) {
              importance |= 4;
            }
            return {
              id: item.id,
              target_assessment: item.target,
              importance,
              is_core: item.important,
              is_used_now: item.frequentlyUsed,
              is_used_in_future: item.frequentlyUsedInFuture
            };
          })
        ).then((skill: RoleSkill) => {
          console.log(skill);
        }),

        new Promise(res => {
          if (!!data.accountabilityInterval) {
            setRoleAccountabilities(
              role.id,
              data.accountability!.map(item => ({ area_name: item.area, metrics: item.metrics, weight: item.weight }))
            ).then((accountablities: Accountability) => {
              res(accountablities);
              console.log(accountablities);
            });
          } else {
            res([]);
          }
        })
      ]);
      getRoleSummaries();
      closeModal();
    }
  };

  return (
    <ComplexModal
      isOpen={isOpen}
      closeModal={closeModal}
      shouldCloseOnOverlayClick={false}
      headerTitle="Add a New Role"
      HeaderTitleComponent={
        <div className="flex items-center ml-auto mr-4">
          <div className="text-secondary text-sm font-normal mr-3">
            This role is{" "}
            <b className={formData.isActive ? "text-success" : ""}>{formData.isActive ? "Active" : "Inactive"}</b>
          </div>
          <Switch
            theme="success"
            readonly
            value={formData.isActive}
            onChange={value => onFieldChange("isActive", value)}
          />
        </div>
      }
      headerSecondRow={tabsHeader}
      headerClass="pb-0"
      buttons={buttons}
    >
      <RoleForm
        tab={tabIndex}
        formData={formData}
        onTabChange={setTabIndex}
        onChange={onFormDataChange}
        onSubmit={onSubmit}
        onClose={closeModal}
        onSetModalButtons={setButtons}
      />
      {apiError && (
        <SavingError
          className="absolute w-full h-full top-0 lef-0 z-100"
          message={apiError}
          onClose={() => setApiError("")}
        />
      )}
    </ComplexModal>
  );
};
