import React from "react";
import "@styles/layouts/default.scss";
import styles from "@styles/layouts/default.module.scss";
import classNames from "classnames";
import { Footer, Header } from "./components";

interface Props {
  modClass?: string;
}

export const DefaultLayout: React.FC<Props> = ({ modClass, children }) => {
  const mainClass = classNames(styles.main, modClass);
  return (
    <div className={styles.body}>
      <Header />
      <main className={mainClass}>{children}</main>
      <Footer />
    </div>
  );
};
