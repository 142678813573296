import React, { FC, useEffect, useMemo } from "react";
import { ILearnPathForm } from '@common/types/forms/learn';
import { ILearnPathFormTabInitData } from "../index";
import { $commonStore } from "@common/api/store/common";

export interface IPathSkillsTabProps {
  formData: Partial<ILearnPathForm>;
  onInit?: (data: ILearnPathFormTabInitData) => void;
  onBack(): void;
  onNext(): void;
  onSubmit(data: Partial<ILearnPathForm>): void;
  onClose(): void;
}

export const LearnSkillsTab: FC<IPathSkillsTabProps> = ({
  formData,
  onInit,
  onBack,
  onNext,
  onSubmit,
  onClose,
}) => {
  const categories = useMemo(() => {
    const parentCategories = $commonStore.skillCategories
      .filter((category) => !category.parent_id)
      .reduce((prev, category) => ({ ...prev, [category.id]: category.name }), {} as { [key: string]: string });
    return $commonStore.skillCategories
      .filter((category) => category.parent_id)
      .reduce((prev, category) => ({
        ...prev, [category.name]: category.parent_id ? parentCategories[category.parent_id] : ''
      }), {} as { [key: string]: string })
  }, []);

  const skills = useMemo(() => (formData.resources || []).reduce((prev, resource) => {
    const prevSkills = prev.map((skill) => skill.name);
    const filteredSkills = resource.skills
      .filter((skill) => !prevSkills.includes(skill))
      .map((skill, index) => ({ id: prev.length + index, name: skill, category: resource.category }))
    return [...prev, ...filteredSkills];
  }, [] as { id: number, name: string, category: string }[]), [formData]);

  const groups: string[] = useMemo(() => skills.reduce((prev, item) => [
    ...prev, ...(prev.includes(item.category) ? [] : [item.category])
  ], [] as string[]), [skills]);

  useEffect(() => {
    if (onInit) {
      onInit({
        buttons: {
          leftSide: [
            {
              title: <span>Cancel<br/>And Close</span>,
              theme: 'secondary-gradient',
              className: '!w-30 !h-14 !text-sm !text-left !font-semibold',
              onClick: onClose,
            },
          ],
          rightSide: [
            {
              title: "Prev",
              theme: "warning",
              shape: "left-arrow",
              className: "!w-20 !text-sm !font-semibold",
              onClick: onBack,
            },
            {
              title: "Save & Finish",
              theme: "warning",
              className: "!w-32 !text-sm !font-semibold",
              onClick() {
                onClose();
                // methods.handleSubmit(handleSuccess, handleError)();
              },
            },
          ],
        },
      });
    }
  }, [onInit]);

  return (
    <div className="text-black">
      <div className="px-5">
        <p className="text-blue text-sm">Below is the list of covered skills by the learning resources associated to this learning path.</p>
      </div>

      <div className="w-full mt-4">
        <table className="w-full text-sm mt-2">
          <thead>
          <tr className="text-gray text-xs text-left font-semibold border-b border-solid border-gray-cd">
            <th className="pl-5 pr-4 pb-1">Skill Category / Skill name</th>
            <th className="pr-5 pb-1" />
          </tr>
          </thead>
          <tbody>
          {groups.map((group, i) => {
            const items = skills.filter((item) => item.category === group);
            return (
              <React.Fragment key={i}>
                <tr>
                  <td className="font-semibold px-5 pt-2" colSpan={2}>
                    <div className="flex items-center leading-tight pb-2">
                      <span className="bg-gray-97 uppercase text-2xs text-white px-1 py-0.5">{categories[group]}</span>
                      <span className="ml-2">{group}</span>
                    </div>
                  </td>
                </tr>
                {items.map((item) => (
                  <tr key={item.id} className="align-top">
                    <td className="pl-5">
                      <div className="flex items-center bg-gray-f5 mb-1">
                        <div className="pl-2 pr-6">{item.name}</div>
                      </div>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            );
          })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
