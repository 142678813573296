import { FC, useEffect, useState } from "react";
import classNames from "classnames";
import "./switch.scss";

type SwitchTheme = "primary" | "success";
type SwitchSize = "sm" | "md" | "lg";

interface ISwitchProps {
  className?: string;
  theme?: SwitchTheme;
  size?: SwitchSize;
  value?: boolean;
  readonly?: boolean;
  onChange?: (value: boolean) => void;
}

export const Switch: FC<ISwitchProps> = ({
  className,
  theme = "primary",
  size = "md",
  value = false,
  readonly,
  onChange = () => {}
}) => {
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  const onToggle = () => {
    if (!readonly) {
      setChecked(!checked);
      onChange(!checked);
    }
  };

  return (
    <div
      className={classNames(
        `switch switch-${theme} switch-${size}`,
        checked ? "switch-on" : "switch-off",
        readonly && "readonly",
        className
      )}
      onClick={onToggle}
    >
      <div className="button" />
    </div>
  );
};
