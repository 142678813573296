import { useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { GlobalStore } from "@common/api/store/global";
import { SecondLevelTable } from "@ui";
import { FormattedEmployeeList, PeopleSubordinates } from "@common/types/reports/people/people";
import { getPeopleByMyDirect } from "@common/features/people/model";
import { employeeColumns } from "../model";
import { createEmployeeTableData } from "../helpers";
import styles from "./../employees-table.module.scss";

export const ReportByMyDirect = observer(() => {
  const { $profileStore } = useContext(GlobalStore);
  const [data, setData] = useState<PeopleSubordinates[]>();
  useEffect(() => {
    getPeopleByMyDirect(setData);
  }, [$profileStore.getCurrentRoleId()]);

  const formattedData: FormattedEmployeeList[] | null = data ? createEmployeeTableData(data) : null;
  return formattedData?.length ? (
    SecondLevelTable<FormattedEmployeeList>({
      data: formattedData,
      columns: employeeColumns,
      modStyles: styles,
      pagination: true,
      pageSize: 25
    })
  ) : (
    <>No Direct Reports listed</>
  );
});
