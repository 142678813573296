import React from "react";
import styles from "./circular-chart.module.scss";
import classNames from "classnames";

type Props = {
  progress: number;
};

export const CircularChart = ({ progress }: Props) => {
  return (
    <div className={styles["chart-container"]}>
      <div className={styles.progress}>
        <div className={styles["bar-overflow"]}>
          <div
            style={{
              transform: `rotate(${progress >= 0 && progress <= 100 ? -135 + progress * 1.8 : 45}deg)`
            }}
            className={classNames(styles.bar)}
          ></div>
        </div>
        <div className={styles["progress-value"]}>
          <span>{progress}</span>%
        </div>
      </div>
    </div>
  );
};
