import { AxiosResponse } from "axios";
import { HttpService } from "../http";
import { DataWrapper } from "../types/response/common";

export const getResponsibilitiesService = async <T>(url: string): Promise<AxiosResponse<DataWrapper<T[]>>> => {
  return HttpService.get<DataWrapper<T[]>>(url);
};

export const postResponsibilitiesService = async <T>(
  url: string,
  data: any
): Promise<AxiosResponse<DataWrapper<T[]>>> => {
  return HttpService.post<DataWrapper<T[]>>(url, data);
};
