import { Button, ButtonProps } from "@ui";
import classNames from "classnames";
import React from "react";
import ReactModal from "react-modal";
import styles from "./complex-modal.module.scss";
import moduleStyles from "@styles/modules/complex-modal.module.scss";
import { getDefaultProps } from "./helpers";
import { ModalProps } from "./types";

// TODO: Release generate Modal after it activate
export interface ComplexModalButtons {
  leftSide?: ButtonProps | ButtonProps[];
  rightSide?: ButtonProps[];
}
interface Props extends ModalProps {
  headerTitle?: string | JSX.Element;
  secondaryTitle?: string;
  HeaderTitleComponent?: JSX.Element;
  headerSecondRow?: JSX.Element;
  buttons?: ComplexModalButtons;
  FooterComponent?: JSX.Element;
  modClass?: string;
  headerClass?: string;
  bodyClass?: string;
  shouldCloseOnOverlayClick?: boolean;
}

export const ComplexModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  children,
  headerTitle,
  secondaryTitle,
  HeaderTitleComponent,
  headerSecondRow,
  buttons,
  FooterComponent,
  modClass,
  bodyClass,
  headerClass,
  ...props
}) => {
  const expandProps = { ...getDefaultProps(), ...props };

  // TODO: think about optimize buttons generate

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={styles.empty}
      portalClassName={styles.empty}
      {...expandProps}
    >
      <div className={classNames(styles.complex, modClass)}>
        <div className={classNames(styles.header, headerClass)}>
          <div className={styles["header-top-row"]}>
            {headerTitle}
            {secondaryTitle && <span className={styles["header-secondary-title"]}>&nbsp;{secondaryTitle}</span>}
            {HeaderTitleComponent}
            <div className={styles.close} onClick={closeModal} />
          </div>
          {headerSecondRow && <div className={styles["header-second-row"]}>{headerSecondRow}</div>}
        </div>
        <div className={classNames(styles.body, bodyClass)}>{children}</div>
        {(buttons || FooterComponent) && (
          <div className={styles.footer}>
            {buttons && (
              <div className={styles.buttons}>
                <div className={classNames(moduleStyles.row, moduleStyles["two-columns"])}>
                  {buttons.leftSide && (
                    <div className={moduleStyles["two-columns-left"]}>
                      {(Array.isArray(buttons.leftSide) ? buttons.leftSide : [buttons.leftSide]).map(
                        ({ className: addClass, ...item }, index) => {
                          return (
                            <Button
                              key={`left-side-buttons-${index}`}
                              className={classNames(
                                styles["buttons-item"],
                                moduleStyles["two-columns-left-button"],
                                addClass
                              )}
                              {...item}
                            />
                          );
                        }
                      )}
                    </div>
                  )}
                  {buttons.rightSide && (
                    <div
                      className={classNames(
                        moduleStyles["two-columns-right"],
                        styles["buttons-right-side"],
                        styles["buttons-block"]
                      )}
                    >
                      {buttons.rightSide.map(({ className: addClass, ...item }, index) => {
                        return (
                          <Button
                            key={`right-side-buttons-${index}`}
                            className={classNames(styles["buttons-item"], addClass)}
                            {...item}
                          />
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            )}
            {FooterComponent}
          </div>
        )}
      </div>
    </ReactModal>
  );
};
