import { PeopleByOrgUnit, PeopleIntermediate } from "@common/types/reports/people/people";
import { TableColumn, TableDetailRows } from "@common/types/table";
import classNames from "classnames";
import { DataType, SortDirection } from "ka-table/enums";
import grid from "@styles/modules/grid.module.scss";
import { CategoryTable } from "@ui";
import { IntermediateLevel } from "./intermediate-level";
import { createIntermediateTableData } from "../helpers";
import { AverageScore } from "@features/people";

interface Props {
  data: PeopleByOrgUnit[];
}

export const CategoryLevel = ({ data }: Props) => {
  const formattedData: PeopleIntermediate[] = createIntermediateTableData(data);
  const columns: TableColumn<PeopleIntermediate>[] = [
    {
      key: "name",
      title: "",
      dataType: DataType.String,
      sortDirection: SortDirection.Ascend,
      modClass: classNames(grid.item, grid["item-4x"]),
      expandCell: true
    },
    {
      key: "members_count",
      title: "",
      dataType: DataType.Number,
      modClass: classNames(grid.item, grid["item-4x"])
    },
    {
      key: "avg_skill_gap",
      title: "Average Score",
      dataType: DataType.String,
      modClass: classNames(grid.item, grid["item-4x"]),
      component: AverageScore
    }
  ];

  const detailRows: TableDetailRows<PeopleIntermediate> = {
    component: IntermediateLevel,
    dataProperty: "inside"
  };

  return CategoryTable<PeopleIntermediate>({
    data: formattedData,
    columns,
    detailRows,
    hideHeader: true
  });
};
