import { GlobalStore } from "@common/api/store/global";
import { $userStore } from "@common/api/store/user";
import { Roles } from "@common/constants";
import { getProfile } from "@common/features/profile/model";
import { logout, logoutImpersonation } from "@common/features/user/model";
import { ProfileInterface } from "@common/types/reports/profile/profile";
import grid from "@styles/modules/grid.module.scss";
import { Svg } from "@ui";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { settingsRoutes } from "../../../router/config/routes";
import { Logo } from "../logo";
import styles from "./top-line.module.scss";

interface Props {
  changeMenu: () => void;
}

export const TopLine = observer(({ changeMenu }: Props) => {
  const { $profileStore } = useContext(GlobalStore);
  const [profile, setProfile] = useState<ProfileInterface>();
  useEffect(() => {
    if ($userStore.isAuth) {
      const profile = $profileStore.getProfile();
      if (profile) {
        setProfile(profile);
      } else {
        getProfile(setProfile);
      }
    }
  }, []);

  const userInfo = $userStore.getUserInfo();
  const role = $profileStore.getCurrentRoleId();
  const topLineInnerClass = classNames(grid.container, styles.inner);
  const userBlockClass = classNames(styles["user-block"], grid.list);
  const logoutIconClass = classNames(styles.icon, styles["icon-logout"]);
  const itemWrapperClass = classNames(grid.item, styles["item-wrapper"], styles["user-info"]);
  const userInfoWrapperClass = classNames(grid.item, styles["item-wrapper"]);
  const menuWrapperClass = classNames(styles["mobile-menu"], grid.item, styles["item-wrapper"]);

  const impersonationToken = localStorage.getItem("impersonationToken");

  return (
    <div className={styles["top-line"]}>
      <div className={grid.root}>
        <div className={topLineInnerClass}>
          <Logo />
          {$userStore.isAuth && (
            <div className={userBlockClass}>
              {impersonationToken && (
                <div
                  className="mb-4 mr-4 flex items-center text-[#474a51] text-sm cursor-pointer hover:text-warning"
                  onClick={() => logoutImpersonation()}
                >
                  <b className="mr-1">{"<"}</b> Close {userInfo?.first_name} {userInfo?.last_name}'s Session
                </div>
              )}
              <div className={userInfoWrapperClass}>
                <div className={styles.username}>
                  {impersonationToken && <span className="text-[#474a51] font-normal">Your are logged in as </span>}
                  {userInfo?.first_name} {userInfo?.last_name}
                </div>
                {profile?.current_role?.name && <div className={styles.role}>{profile.current_role.name}</div>}
              </div>
              {role === Roles.superAdmin ? (
                <div className={`${itemWrapperClass} mb-2`}>
                  <NavLink to={`#`} className={styles.link}>
                    Innovorg Superadmin
                  </NavLink>
                </div>
              ) : (
                settingsRoutes.map(item => {
                  return (
                    item.iconCode &&
                    (!item.access || (item.access && role && item.access.indexOf(Number(role)) !== -1)) && (
                      <div className={itemWrapperClass} key={`top-line-${item.route}`}>
                        <NavLink to={`/${item.route}`} className={styles.link}>
                          <Svg name={item.iconCode} modClass={styles.icon} />
                        </NavLink>
                      </div>
                    )
                  );
                })
              )}
              <div className={itemWrapperClass} onClick={() => logout()}>
                <Svg name="logout" modClass={logoutIconClass} />
              </div>
              <div className={menuWrapperClass}>
                <Svg name="menu" modClass={styles.icon} onClick={changeMenu} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
