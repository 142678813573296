import React from "react";
import styles from "./learning-resource-card.module.scss";
import { RatingWithNumber, Svg } from "@ui";
import classNames from "classnames";

interface Props {
  title: string;
  authorImgSrc: string;
  rating: number;
  totalRating: number;
  isBookmarked?: boolean;
}

export const LearningResourceCard: React.FC<Props> = ({ title, authorImgSrc, rating, totalRating, isBookmarked }) => {
  return (
    <div className={styles.resource}>
      <div className={classNames(styles["author-logo"], "flex justify-center items-center bg-white overflow-hidden")}>
        <img src={authorImgSrc} alt="author-logo" className="w-full" />
      </div>

      <div className={styles["course-details"]}>
        <h2 className={styles["course-title"]}>{title}</h2>

        <div className={styles["rating-bookmark-container"]}>
          <RatingWithNumber totalRatings={totalRating} rating={rating} />
          <div>
            <Svg modClass={styles.bookmark} name={"bookmark"} />
          </div>
        </div>
      </div>
    </div>
  );
};
