import classNames from "classnames";
import React, { MouseEventHandler } from "react";
import styles from "./shadow-block.module.scss";

interface Props {
  modClass?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

export const ShadowBlock: React.FC<Props> = ({ children, onClick, modClass }) => {
  const wrapperClass = classNames(styles.wrapper, modClass);
  return (
    <div className={wrapperClass} onClick={onClick}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
