import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import styles from "./progress-cell.module.scss";
import { getPhrases } from "@common/helpers/localization";
import { TableCellProps } from "@common/types/table";
import { getPositiveProgressClassNames, getWithNegativeProgressClassNames } from "./helpers";
import { ProgressLevels } from "./types";
import { ICellTextProps } from "ka-table/props";

const loc = getPhrases("common", "progress");

// TODO: Replace React.PropsWithChildren<ICellTextProps> in to abstract interface

type Theme = "white" | "default";
interface ProgressCellInterface extends TableCellProps {
  theme?: Theme;
  withNegative?: boolean;
  valueText?: (data: PropsWithChildren<ICellTextProps>) => string;
}

function getTheme(theme: Theme) {
  switch (theme) {
    case "white":
      return styles["progress-white"];
    default:
      return styles["progress-default"];
  }
}

export const ProgressCell: React.FC<ProgressCellInterface> = ({
  theme = "default",
  withNegative = true,
  valueText,
  props
}) => {
  const resultText: string | null = valueText ? valueText(props) : null;
  const { value } = props;
  const currentValue = Math.round(value * 100);

  let level: ProgressLevels = withNegative
    ? getWithNegativeProgressClassNames({ value: currentValue })
    : getPositiveProgressClassNames({ value: currentValue });

  const fillerClass = classNames(styles.filler, styles[`filler-${level}`]);

  const partClass = classNames(styles.part, styles[currentValue > 0 ? "part-positive" : "part-negative"]);

  let position = "";

  if (currentValue > 0) {
    position = loc.target_over;
  } else if (currentValue < 0) {
    position = loc.target_under;
  }

  const result = `${currentValue}% ${position} ${loc.target}`;
  const progressBarLineOptions: ProgressBarLineProps = {
    fillerClass,
    value: currentValue
  };

  return (
    <div className={classNames(styles.progress, getTheme(theme))}>
      {resultText ?? result.toLowerCase()}
      <div className={styles.line}>
        {withNegative ? (
          <>
            <div className={styles.separator} />
            <div className={partClass}>
              <ProgressBarLine {...progressBarLineOptions} />
            </div>
          </>
        ) : (
          <ProgressBarLine {...progressBarLineOptions} />
        )}
      </div>
    </div>
  );
};

interface ProgressBarLineProps {
  fillerClass: string;
  value: number;
}

const ProgressBarLine = ({ fillerClass, value }: ProgressBarLineProps) => {
  return <div className={fillerClass} style={{ width: `${Math.abs(value)}%` }} />;
};
