import { AssessmentForm, AssessmentFormResult } from "@common/types/forms/assessments-employee";
import { DashboardAssessmentsCounters } from "@common/types/reports/assessments/dashboard-assessments-counters";
import { EmployeeCurrentAssessment } from "@common/types/reports/assessments/employee-current-assessment";
import { AxiosResponse } from "axios";
import { HttpService } from "../http";
import { DataWrapper } from "../types/response/common";
import { AssessmentIntervalSetting } from "../../types/reports/assessments/assessments";

export const getAssessments = async <T>(url: string): Promise<AxiosResponse<DataWrapper<T[]>>> => {
  return HttpService.get<DataWrapper<T[]>>(url);
};

export const getAssessmentQuestions = async <T = DataWrapper<AssessmentForm[]>>(url: string): Promise<AxiosResponse<T>> => {
  return HttpService.get<T>(url);
};

export const sendAssessmentResult = async (
  url: string,
  result: AssessmentFormResult
): Promise<AxiosResponse<DataWrapper<AssessmentFormResult>>> => {
  return HttpService.post<DataWrapper<AssessmentFormResult>>(url, result);
};

export const getLastAssessmentResult = async (
  url: string
): Promise<AxiosResponse<DataWrapper<EmployeeCurrentAssessment>>> => {
  return HttpService.get<DataWrapper<EmployeeCurrentAssessment>>(url);
};

export const getDashboardAssessmentCounters = async (
  url: string
): Promise<AxiosResponse<DataWrapper<DashboardAssessmentsCounters>>> => {
  return HttpService.get<DataWrapper<DashboardAssessmentsCounters>>(url);
};

export const getAssessmentIntervalSetting = async (
  url: string
): Promise<AxiosResponse<DataWrapper<AssessmentIntervalSetting>>> => {
  return HttpService.get<DataWrapper<AssessmentIntervalSetting>>(url);
};

export const setAssessmentIntervalSetting = async (
  url: string,
  setting: Omit<AssessmentIntervalSetting, "role">
): Promise<AxiosResponse<DataWrapper<AssessmentIntervalSetting>>> => {
  return HttpService.post<DataWrapper<AssessmentIntervalSetting>>(url, setting);
};

export const downloadAssessmentReport = async (url: string): Promise<AxiosResponse> => {
  return HttpService.get(url, { responseType: "arraybuffer" });
};
