import { ExtendedDataWrapper } from "@common/api/types/response/common";
import { $api } from "@common/api/http";
import {
  Assessment,
  AssessmentModel,
  FilterParams,
  Invitation,
  NotificationModel,
  PaginationParams
} from "@common/types/reports/activity-logs/activity-logs";
import { AssessmentSummary, NotificationSummary } from "@common/types/activity-logs/activity-logs";

export const getNotifications = async (
  params: Partial<PaginationParams & FilterParams>,
  successCallback?: (param: ExtendedDataWrapper<NotificationModel[]>) => void
): Promise<ExtendedDataWrapper<NotificationModel[]>> => {
  try {
    const response = await $api.get<ExtendedDataWrapper<Invitation[]>>(`/web/activity-logs/notifications`, { params });
    const { data, meta } = response.data;
    const resData = {
      data: data.map(
        (invitation, index) => new NotificationModel(invitation, index + meta.per_page * (meta.current_page - 1) + 1)
      ),
      meta
    };
    if (successCallback) {
      successCallback(resData);
    }
    return resData;
  } catch (e) {
    console.error("Get notifications error", e);
    throw e;
  }
};

export const getNotificationsSummary = async (
  successCallback?: (param: NotificationSummary) => void
): Promise<NotificationSummary> => {
  try {
    const response = await $api.get<NotificationSummary>(`/web/activity-logs/notifications/summary-stats`);
    const data = response.data;
    if (successCallback) {
      successCallback(data);
    }
    return data;
  } catch (e) {
    console.error("Get notifications summary error", e);
    throw e;
  }
};

export const getAssessments = async (
  params: Partial<PaginationParams & FilterParams>,
  category: "skills-assessments" | "scorecards-assessments",
  successCallback?: (param: ExtendedDataWrapper<AssessmentModel[]>) => void
): Promise<ExtendedDataWrapper<AssessmentModel[]>> => {
  try {
    const response = await $api.get<ExtendedDataWrapper<Assessment[]>>(`/web/activity-logs/${category}`, { params });
    const { data, meta } = response.data;
    const resData = {
      data: data.map(
        (assessment, index) => new AssessmentModel(assessment, index + meta.per_page * (meta.current_page - 1) + 1)
      ),
      meta
    };
    if (successCallback) {
      successCallback(resData);
    }
    return resData;
  } catch (e) {
    console.error("Get assessments error", e);
    throw e;
  }
};

export const getAssessmentsSummary = async (
  category: "skills-assessments" | "scorecards-assessments",
  successCallback?: (param: AssessmentSummary) => void
): Promise<AssessmentSummary> => {
  try {
    const response = await $api.get<AssessmentSummary>(`/web/activity-logs/${category}/summary-stats`);
    const data = response.data;
    if (successCallback) {
      successCallback(data);
    }
    return data;
  } catch (e) {
    console.error("Get assessments summary error", e);
    throw e;
  }
};
