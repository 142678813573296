import { MouseEvent, useContext, useEffect, useState } from "react";
import { getPhrases } from "@common/helpers/localization";
import { FormattedSkillsDataProps, SkillsDataProps } from "@common/types/reports/skills/skills";
import { ModelDataInterface } from "@common/types/reports/common";
import { TableColumn, TableDetailRows } from "@common/types/table";
import classNames from "classnames";
import { DataType, SortDirection } from "ka-table/enums";
import grid from "@styles/modules/grid.module.scss";
import styles from "./../../../skills.module.scss";
import { Button, FirstLevelTable, ProgressCell } from "@ui";
import { SecondLevel } from "./second-level";
import { SkillCategory } from "@common/types/skill-engine/skill-category";
import { EditSkillCategoryModal } from "@features/skills/edit-skill-category-modal";
import { Roles } from "@common/constants";
import { GlobalStore } from "@common/api/store/global";

const loc = getPhrases("skills_page", "table");
export const FirstLevel = ({ modelData }: ModelDataInterface<SkillsDataProps>) => {
  const { $profileStore } = useContext(GlobalStore);
  const [editingSkillCategory, setEditingSkillCategory] = useState<SkillCategory>();
  const handleEditSkillCategory = (e: MouseEvent, skillCategory: SkillCategory) => {
    e.preventDefault();
    setEditingSkillCategory(skillCategory);
  };
  const isClientAdmin = $profileStore.currentRoleId === Roles.clientAdmin;

  const data: FormattedSkillsDataProps[] = modelData.map(item => {
    return {
      ...item,
      skills: item.skills.length > 0 ? item.skills : undefined
    };
  });
  const columns: TableColumn<FormattedSkillsDataProps>[] = [
    {
      key: "name",
      title: loc.title_category,
      dataType: DataType.String,
      sortDirection: SortDirection.Ascend,
      modClass: classNames(grid.item, grid["item-6x"], styles["first-cell"]),
      expandCell: true,
      component: ({ props }) => {
        const skillCategory: SkillCategory = {
          id: props.rowData.id,
          name: props.rowData.name,
          is_active: props.rowData.is_active,
          description: props.rowData.description,
          parent_id: props.rowData.parent_id
        };
        return (
          <div className="flex items-center">
            {props.value}
            {isClientAdmin && (
              <Button
                className="w-20 ml-5"
                size="thin"
                title="Edit"
                theme="blue"
                onClick={e => handleEditSkillCategory(e, skillCategory)}
              />
            )}
          </div>
        );
      }
    },
    {
      key: "skills_count",
      title: loc.title_skills,
      dataType: DataType.Number,
      modClass: classNames(grid.item, grid["item-2x"])
    },
    {
      key: "is_active",
      title: loc.title_status,
      dataType: DataType.String,
      modClass: classNames(grid.item, grid["item-2x"]),
      decorator: ({ value }) => (value ? "Active" : "Inactive")
    },
    {
      key: "avg_skill_level",
      title: loc.title_average,
      dataType: DataType.Number,
      modClass: classNames(grid.item, grid["item-2x"]),
      component: ProgressCell
    }
  ];

  const detailRows: TableDetailRows<FormattedSkillsDataProps> = {
    component: SecondLevel,
    dataProperty: "skills"
  };

  return (
    <>
      {FirstLevelTable<FormattedSkillsDataProps>({
        data,
        columns,
        detailRows
      })}
      {editingSkillCategory && (
        <EditSkillCategoryModal
          isOpen
          skillCategory={editingSkillCategory}
          closeModal={() => setEditingSkillCategory(undefined)}
        />
      )}
    </>
  );
};
