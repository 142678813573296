import { TableColumn, TableDetailRows } from "@common/types/table";
import classNames from "classnames";
import { DataType } from "ka-table/enums";
import grid from "@styles/modules/grid.module.scss";
import table from "./table.module.scss";
import { FirstLevelTable } from "@ui";
import { SecondLevel } from "./";
import { SkillsTree } from "../types";

interface Props {
  data: SkillsTree[];
}

export const FirstLevel = ({ data }: Props) => {
  const columns: TableColumn<SkillsTree>[] = [
    {
      key: "name",
      title: "Category name",
      dataType: DataType.String,
      modClass: classNames(grid.item, grid["item-6x"]),
      expandCell: true
    },
    {
      key: "description",
      title: "Description",
      dataType: DataType.String,
      modClass: classNames(grid.item, grid["item-6x"])
    }
  ];

  const detailRows: TableDetailRows<SkillsTree> = {
    component: SecondLevel,
    dataProperty: "skills"
  };
  return FirstLevelTable<SkillsTree>({
    data,
    columns,
    detailRows,
    modStyles: table
  });
};
