import { $accountabilitiesStore } from "@common/api/store/accountabilities";
import { AlertModal, ComplexModal, ComplexModalButtons, ShortModal, CompleteState, ProgressiveModalWrapper } from "@ui";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import modal from "@styles/modules/complex-modal.module.scss";
import { AssessmentFormCloseModal } from "@features/assessments";
import { QuestionsList } from "./questions-list";
import {
  AccountabilitiesForm,
  AccountabilityFormResult,
  AccountabilitySendFormAccountability
} from "@common/types/forms/accountabilities";
import { Feedback } from "./feedback";
import * as yup from "yup";
import { useForm } from "@common/hooks/form";
import { FieldErrors } from "react-hook-form";
import { sendSelfAccountabilityResult } from "@common/features/accountabilities/model";
import { AccoutabilitiesSessionsTypes } from "@common/types/reports/accountabilities/accountabilities";
import { AdditionalReport } from "./additional-reports";

interface Props {
  aptitudes: AccountabilitiesForm[];
  isOpen: boolean;
  closeModal: () => void;
  userName: string;
  isManager?: boolean;
  memberId: number;
}

export interface AccountabilitiesFeedback {
  feedback?: string;
}

export const AccountabilitiesSessionFormModal = observer(
  ({ aptitudes, isOpen, closeModal, userName, memberId, isManager, ...props }: Props) => {
    const [cancelModalIsOpen, setCancelModalIsOpen] = useState<boolean>(false);

    const [isLastCategory, setLastCategoryStatus] = useState<boolean>(false);

    // TODO: optimize and decompoze the code
    const [sendFailModalIsOpen, setSendFailModalIsOpen] = useState<boolean>(false);
    const [sendServerFailModalIsOpen, setSendServerFailModalIsOpen] = useState<boolean>(false);
    const [sendSuccessModalIsOpen, setSendSuccessModalIsOpen] = useState<boolean>(false);
    const [feedbackEmptyErrorModalIsOpen, setFeedbackEmptyErrorModalIsOpen] = useState<boolean>(false);

    const [currentQuestionPage, setCurrentQuestionPage] = useState<number>(0);

    const categoriesQuantity = aptitudes?.length ?? 1;

    // TODO: delete percent calc after moving results to global state
    const completePercent = $accountabilitiesStore.getPercent();

    useEffect(() => {
      // TODO: Move results to global state
      if (aptitudes && !$accountabilitiesStore.results) {
        const defaultValues: AccountabilityFormResult = aptitudes.reduce(
          (prev, category) => {
            $accountabilitiesStore.setAccountabilityCategorySetter(category.id, null);
            return {
              results: { ...prev.results, ...{ [category.id]: null } }
            };
          },
          { results: {} }
        );

        $accountabilitiesStore.setResults(defaultValues);
        $accountabilitiesStore.setCurrentAccountabilityCategory(aptitudes[0].id);
      }
    }, [aptitudes]);

    if (aptitudes) {
      const schema: yup.SchemaOf<AccountabilitiesFeedback> = yup.object().shape({
        feedback: yup.string().required()
      });

      const methods = useForm<AccountabilitiesFeedback>({
        schema
      });

      const checkCategoryAnswers = (successCallback: (results: AccountabilityFormResult) => void) => {
        const results = $accountabilitiesStore.getResults();
        const resultsValues = results?.results;
        if (resultsValues) {
          const question = aptitudes[currentQuestionPage];

          if (resultsValues[question.id]) {
            successCallback(results);
          } else {
            setSendFailModalIsOpen(true);
          }
        } else {
          setSendServerFailModalIsOpen(true);
        }
      };

      const handleSuccess = (values: any) => {
        sendResults(values);
      };

      const handleError = (errors: FieldErrors<any>) => {
        if (errors.feedback) {
          setFeedbackEmptyErrorModalIsOpen(true);
        }
        console.error(errors);
      };

      const sendResults = (values: any) => {
        const results = $accountabilitiesStore.getResults();
        if (results) {
          const failCallback = () => {
            setSendServerFailModalIsOpen(true);
          };
          const successCallback = () => {
            setSendSuccessModalIsOpen(true);
          };

          const resultsKeys = Object.keys(results.results);
          const accountabilities: AccountabilitySendFormAccountability[] = resultsKeys.reduce(
            (previous: AccountabilitySendFormAccountability[], current) => {
              return [
                ...previous,
                {
                  session_accountability_id: Number(current),
                  mark_id: results.results[current]
                }
              ];
            },
            []
          );

          sendSelfAccountabilityResult({
            type: isManager ? AccoutabilitiesSessionsTypes.manager : AccoutabilitiesSessionsTypes.self,
            memberId,
            accountabilities,
            feedback: methods.getValues()?.feedback || "",
            successCallback,
            failCallback
          });
        }
      };

      const onPageChange = (index: number) => {
        $accountabilitiesStore.setCurrentAccountabilityCategory(aptitudes[index].id);
        return index;
      };

      const goToNextPage = () => {
        checkCategoryAnswers(() => {
          setCurrentQuestionPage(prev => onPageChange(prev + 1));
        });
      };

      const goToLastPage = () => {
        checkCategoryAnswers(() => {
          setLastCategoryStatus(true);
        });
      };

      const goToPrevPage = () => {
        isLastCategory ? setLastCategoryStatus(false) : setCurrentQuestionPage(prev => onPageChange(prev - 1));
      };
      const onClose = () => {
        setCancelModalIsOpen(true);
      };

      const onCancelModalSuccess = () => {
        $accountabilitiesStore.reset();
        setCancelModalIsOpen(false);
        closeModal();
      };

      const buttons: ComplexModalButtons = {
        ...(currentQuestionPage > 0 && {
          leftSide: {
            title: "Prev",
            theme: "gray",
            onClick: goToPrevPage
          }
        }),
        rightSide: [
          {
            title: "Cancel",
            appearance: "bordered",
            theme: "gray",
            onClick: onClose
          },
          //@ts-ignore
          ...(currentQuestionPage === categoriesQuantity - 1 && isLastCategory
            ? [
                {
                  title: "Finish",
                  type: "button",
                  theme: "red",
                  onClick: () => {
                    methods.handleSubmit(handleSuccess, handleError)();
                  }
                }
              ]
            : [
                {
                  title: "Next",
                  onClick:
                    currentQuestionPage === categoriesQuantity - 1 && !isLastCategory ? goToLastPage : goToNextPage
                }
              ])
        ]
      };

      return (
        <>
          <ComplexModal
            isOpen={isOpen}
            closeModal={onClose}
            headerTitle="Accountabilities assessment: "
            secondaryTitle={userName}
            HeaderTitleComponent={<AdditionalReport profileId={memberId} />}
            headerSecondRow={
              <CompleteState
                category={aptitudes[currentQuestionPage].name}
                percent={completePercent}
                {...(isLastCategory && { text: <>{"Complete!"}</> })}
              />
            }
            buttons={buttons}
          >
            <div className={modal.row}>
              <ProgressiveModalWrapper progress={completePercent}>
                {isLastCategory ? (
                  <Feedback methods={methods} />
                ) : (
                  <QuestionsList
                    isManager={isManager}
                    categoryId={aptitudes[currentQuestionPage].id}
                    categoryName={aptitudes[currentQuestionPage].name}
                    data={aptitudes[currentQuestionPage]}
                    {...props}
                  />
                )}
              </ProgressiveModalWrapper>
            </div>
          </ComplexModal>
          <ShortModal isOpen={cancelModalIsOpen} closeModal={() => setCancelModalIsOpen}>
            <AssessmentFormCloseModal onCancel={() => setCancelModalIsOpen(false)} onSuccess={onCancelModalSuccess} />
          </ShortModal>
          <AlertModal
            cancelButtonText="Close"
            message="Please select your rating before moving to the next section"
            title="Alert"
            type="fail"
            closeModal={() => setSendFailModalIsOpen(false)}
            isOpen={sendFailModalIsOpen}
          />
          <AlertModal
            cancelButtonText="Close"
            message="Your answers were not saved properly for a technical reason. Please try again later or contact our support team."
            title="Alert"
            type="fail"
            closeModal={() => setSendServerFailModalIsOpen(false)}
            isOpen={sendServerFailModalIsOpen}
          />
          <AlertModal
            cancelButtonText="Close"
            message="Thank you for participating!"
            title="Results sent successfully!"
            type="success"
            closeModal={() => {
              setSendSuccessModalIsOpen(false);
              closeModal();
              window.location.reload();
            }}
            isOpen={sendSuccessModalIsOpen}
          />
          <AlertModal
            cancelButtonText="Close"
            message="Please complete the feedback section"
            title="Alert"
            type="fail"
            closeModal={() => setFeedbackEmptyErrorModalIsOpen(false)}
            isOpen={feedbackEmptyErrorModalIsOpen}
          />
        </>
      );
    } else {
      return null;
    }
  }
);
