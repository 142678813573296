import { FirstLevelTable } from "@ui";
import { FC, useEffect, useState } from "react";
import { columns } from "./model";
import { DurationCategories, NotificationModel } from "@common/types/reports/activity-logs/activity-logs";
import { getNotifications } from "@common/features/activity-logs/model";
import { MetaData } from "@common/api/types/response/common";
import { ITableProps } from "ka-table";

interface InvitationTableProps {
  duration: DurationCategories;
}

export const NotificationTable: FC<InvitationTableProps> = ({ duration }) => {
  const [data, setData] = useState<NotificationModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [metaData, setMetadata] = useState<MetaData>({
    current_page: 1,
    per_page: 25,
    total: 1
  } as MetaData);

  useEffect(() => {
    setIsLoading(true);
    const createdAt = Date.now() - (duration || 0) * 1000 * 60 * 60 * 24;
    getNotifications({
      "page": metaData?.current_page ? metaData?.current_page : 1,
      "per-page": metaData?.per_page || 25,
      "filter[created_at]": new Date(createdAt).toISOString().split("T")[0]
    })
      .then(({ data, meta }) => {
        setData(data);
        setMetadata(meta);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [metaData?.current_page, metaData?.per_page, duration]);

  const handleChangeProps = (props: ITableProps) => {
    if (metaData && !isLoading) {
      setMetadata({
        ...metaData,
        current_page: (props?.paging?.pageIndex || 0) + 1,
        per_page: props?.paging?.pageSize || 10
      });
    }
  };

  return FirstLevelTable<NotificationModel>({
    data,
    columns,
    pagination: true,
    onChangeProps: handleChangeProps,
    pageIndex: metaData?.current_page ? metaData?.current_page - 1 : 0,
    pageSize: metaData?.per_page,
    pagesCount: metaData ? Math.ceil(metaData.total / metaData.per_page) : 1
  });
};
