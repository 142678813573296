import { Skill, SkillsDataProps } from "@common/types/reports/skills/skills";
import { TargetsType } from "@common/types/reports/skills/common";
import { ModelDataInterface } from "@common/types/reports/common";
import { FirstLevelTable } from "@ui";
import { useMemo } from "react";
import { skillsColumns } from "../../model";
import { checkTargetsType } from "../../../helpers";

interface Props extends ModelDataInterface<SkillsDataProps> {
  type?: TargetsType;
}

export const Skills = ({ modelData, type }: Props) => {
  const data: Skill[] = useMemo(() => {
    return modelData.reduce((previous: Skill[], current: SkillsDataProps) => {
      const skills: Skill[] = current.skills.reduce((arrayPrevious: Skill[], currentItem: Skill) => {
        if ((type && checkTargetsType(currentItem.avg_skill_level, type)) || !type) {
          arrayPrevious.push(currentItem);
        }
        return arrayPrevious;
      }, []);
      return [...previous, ...skills];
    }, []);
  }, [modelData, type]);

  return FirstLevelTable<Skill>({
    data,
    columns: skillsColumns,
    pagination: true,
    pageSize: 25
  });
};
