import React, { FC, useState } from "react";
import { ComplexModal, ComplexModalButtons } from "@ui";
import { ISkillForm, Skill } from "@common/types/skill-engine/skill";
import { SkillsForm } from "@features/skills/skills-form";
import { SavingError } from "@features/settings/blocks/roles/role-form/saving-error";
import { WithIntegerId } from "@common/types/common";
import { updateSkill } from "@common/features/skills/model";
import { $userStore } from "@common/api/store/user";

interface EditSkillModalProps {
  isOpen: boolean;
  skill: Skill;
  closeModal: () => void;
}

interface EditSkillForm extends ISkillForm, WithIntegerId {}

export const EditSkillModal: FC<EditSkillModalProps> = ({ skill, isOpen, closeModal }) => {
  const [apiError, setApiError] = useState("");
  const [buttons, setButtons] = useState<ComplexModalButtons>();
  const [formData, setFormData] = useState<EditSkillForm[]>([
    {
      id: skill.id,
      is_active: skill.is_active,
      name: skill.name,
      description: skill.description,
      category_id: skill.category.id
    }
  ]);

  const onSubmit = async (data: ISkillForm[]) => {
    console.log("submit", data);
    updateSkill(skill.id, data[0]).then(() => {
      closeModal();
      $userStore.setSkillUpdate(true);
    });
  };

  return (
    <ComplexModal
      isOpen={isOpen}
      closeModal={closeModal}
      shouldCloseOnOverlayClick={false}
      headerTitle="Edit Skill"
      headerSecondRow={
        <p className="text-md uppercase font-normal opacity-80">Please enter the details of your skill below</p>
      }
      headerClass="pb-1"
      buttons={buttons}
    >
      <SkillsForm formData={formData} onSubmit={onSubmit} onClose={closeModal} onSetModalButtons={setButtons} />
      {apiError && (
        <SavingError
          className="absolute w-full h-full top-0 lef-0 z-100"
          message={apiError}
          onClose={() => setApiError("")}
        />
      )}
    </ComplexModal>
  );
};
