import { ClassCommon } from "@common/api/types/common";
import { getMemberRoleById } from "@common/features/roles/model";
import { EditPersonInterface } from "@common/types/reports/profile/profile";
import { makeAutoObservable, toJS } from "mobx";

export class RoleInfo implements ClassCommon {
  roleInfo: EditPersonInterface | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setRoleInfo(roleInfo: EditPersonInterface) {
    this.roleInfo = roleInfo;
  }

  getRoleInfo() {
    return toJS(this.roleInfo);
  }

  async updateRoleInfo(id: number) {
    const updateData = await getMemberRoleById(id);
    if (updateData) {
      this.setRoleInfo(updateData);
    }
  }

  reset(): void {
    this.roleInfo = null;
  }
}

export const $RoleInfoStore = new RoleInfo();
