import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getResources } from "@common/features/learn/model";
import { getPhrases } from "@common/helpers/localization";
import { CourseItemInterface } from "@common/types/reports/learn/learn";
import { Carousel } from "@features/learn/carousels/carousel";
import { Course } from "../../types";
import { getReducedData } from "../helpers";
import { CompletedSwitcher } from "../completed-switcher";
import { observer } from "mobx-react-lite";
import { GlobalStore } from "@common/api/store/global";

export const SelectedForYou = observer(() => {
  const { $learnStore } = useContext(GlobalStore);
  const loc_selected_for_you_section = getPhrases("learn_page", "selected_for_you_section");

  const [data, setData] = useState<CourseItemInterface[]>();
  const [isShowCompleted, setShowCompletedStatus] = useState<boolean>(false);

  const refresh = useCallback(
    () =>
      getResources(
        setData,
        `filter[for_you]=true${
          !!isShowCompleted ? "" : "&filter[progress_status][]=not_started&filter[progress_status][]=in_progress"
        }`
      ),
    [isShowCompleted]
  );

  useEffect(() => {
    refresh();
  }, [refresh, isShowCompleted]);

  const editedData: Course[] = useMemo(() => {
    $learnStore.setEmployeeResourceAvailability("selectedForYou", !!data?.length);
    return data?.length ? getReducedData(data) : [];
  }, [data, $learnStore]);

  return editedData.length ? (
    <Carousel
      leftHeading={loc_selected_for_you_section.selected_for_you}
      leftSubHeading={"Resources which allow you to continue learning on whatever subject you desire."}
      rightHeading={
        <CompletedSwitcher
          isShowCompleted={isShowCompleted}
          setShowCompletedStatus={() => setShowCompletedStatus(prev => !prev)}
        />
      }
      CoursesData={editedData}
      refresh={refresh}
    />
  ) : null;
});
