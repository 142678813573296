import React, { useContext, useEffect, useState } from "react";
import { ButtonProps, ControlLine, FirstLevelTable, Switch } from "@ui";
import grid from "@styles/modules/grid.module.scss";
import styles from "./companies.module.scss";
import { TableColumn } from "@common/types/table";
import { DataType } from "ka-table/enums";
import classNames from "classnames";
import { getPhrases } from "@common/helpers/localization";
import { GlobalStore } from "@common/api/store/global";
import { getListCompanies } from "@common/features/companies/model";
import moment from "moment/moment";
import ReactPaginate from "../../../ui/table/ReactPaginate";
import tableStyles from "../../../ui/table/table.module.scss";
import { CountSelect } from "../../../ui/table/count-select";
import { Search } from "../../../ui/table/search";

const loc = getPhrases("admin_companies_table", "table");

export const AdminCompaniesPage = () => {
  const [data, setData] = useState<Record<string, any>>({});
  const [filterParams, setFilterParams] = useState<{
    "page": number;
    "per-page": number;
    "filter[is_active]": boolean;
    "filter[query]": string | undefined;
  }>({
    "page": 1,
    "per-page": 25,
    "filter[is_active]": true,
    "filter[query]": undefined
  });
  const { $profileStore } = useContext(GlobalStore);

  useEffect(() => {
    getListCompanies(filterParams, setData);
  }, [$profileStore.getCurrentRoleId(), filterParams]);

  const controlLineButtons: ButtonProps[] = [
    {
      theme: "blue",
      className: styles.button,
      onClick: () => {},
      children: (
        <>
          Add Company <span className={styles["button-span"]} />
        </>
      )
    }
  ];

  const columns: TableColumn<any>[] = [
    {
      key: "is_active",
      title: loc?.title_status,
      dataType: DataType.String,
      modClass: classNames(grid.item, grid["item-1x"], styles["first-cell"]),
      component: ({ props }) => {
        return (
          <div className={classNames(props.value ? styles["activated"] : styles["deleted"])}>
            {props.value ? "Active" : "Inactive"}
          </div>
        );
      }
    },
    {
      key: "id",
      title: loc?.id,
      dataType: DataType.String,
      modClass: classNames(grid.item, grid["item-1x"])
    },
    {
      key: "name",
      title: loc?.title_company_name,
      dataType: DataType.String,
      modClass: classNames(grid.item, grid["item-2x"])
    },
    {
      key: "active_members_count",
      title: loc.title_status,
      dataType: DataType.Number,
      modClass: classNames(grid.item, grid["item-1x"])
    },
    {
      key: "active_units_count",
      title: loc.title_org_units,
      dataType: DataType.Number,
      modClass: classNames(grid.item, grid["item-1x"])
    },
    {
      key: "active_roles_count",
      title: loc.title_roles,
      dataType: DataType.Number,
      modClass: classNames(grid.item, grid["item-1x"])
    },
    {
      key: "modules",
      title: loc.title_enabled_modules,
      dataType: DataType.Object,
      modClass: classNames(grid.item, grid["item-2x"]),
      component: ({ props }) => {
        return (
          <div className="flex-col">
            {Object.keys(props?.value ?? {}).map((value: string, index: number) => (
              <div key={index} className="flex">
                <input type="checkbox" checked={props?.value?.[value]} className="w-auto mr-2" disabled />
                {loc?.[value]}
              </div>
            ))}
          </div>
        );
      }
    },
    {
      key: "client_admins",
      title: loc.title_client_admins,
      dataType: DataType.Object,
      modClass: classNames(grid.item, grid["item-2x"]),
      component: ({ props }) => {
        return (
          <div className={`w-full`}>
            {props?.value?.map(
              (
                user: {
                  email: string;
                },
                index: number
              ) => (
                <div className={styles["row-break-text"]} key={index}>
                  {user.email}
                </div>
              )
            )}
          </div>
        );
      }
    },
    {
      key: "created_at",
      title: loc.title_created,
      dataType: DataType.String,
      modClass: classNames(grid.item, grid["item-2x"]),
      decorator: data => moment(data.value).format("DD/MM/yyyy")
    }
  ];

  return (
    <div className={grid.root}>
      <div className={grid.container}>
        {/*<ControlLine buttonsProps={controlLineButtons} />*/}
        <div className={tableStyles.filter}>
          <CountSelect
            defaultValue={{
              value: filterParams["per-page"],
              label: filterParams["per-page"]?.toString()
            }}
            onChange={newValue => {
              setFilterParams({
                ...filterParams,
                "per-page": +newValue?.value
              });
            }}
          />
          <div className="ml-4 mr-auto flex item-center">
            <Switch
              value={filterParams?.["filter[is_active]"]}
              onChange={value =>
                setFilterParams({
                  ...filterParams,
                  "filter[is_active]": value
                })
              }
            />
            <span className="ml-2">
              {loc[filterParams?.["filter[is_active]"] ? "only_inactive_company" : "only_active_company"]}
            </span>
          </div>
          <Search
            dispatch={(value: { searchText: string }) => {
              setFilterParams({
                ...filterParams,
                "filter[query]": value.searchText !== "" ? value.searchText : undefined
              });
            }}
          />
        </div>
        {FirstLevelTable({
          data: data?.data ?? [],
          columns
        })}
        <ReactPaginate
          onPageChange={page => {
            setFilterParams({
              ...filterParams,
              ["page"]: page.selected + 1
            });
          }}
          initialPage={filterParams.page ? Number(filterParams.page) - 1 : 0}
          pageCount={data?.meta?.last_page || 1}
          pageRangeDisplayed={filterParams["per-page"]}
        />
      </div>
    </div>
  );
};
