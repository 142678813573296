import { $api, HttpService } from "@common/api/http";
import {
  deleteLearnData,
  getLearnData,
  getLearnDataObject,
  setLearnData,
  setLearnFileService
} from "@common/api/services/learn";
import {
  ILearningPath,
  ILearningPathRes,
  ILearnResource,
  ILearnResourceQuizSetting,
  ILearnResourceSkill,
  ILearnResourceSkillsForm,
  SetRating,
  UpdateCourseFavouriteStatus
} from "@common/types/forms/learn";
import { CourseItemInterface, TreeViewInterface, YourLearningPathsInterface } from "@common/types/reports/learn/learn";
import { LearningResource, LearningResourceNote } from "@common/types/reports/learn/learning-resource";
import { DataWrapper, ExtendedDataWrapper } from "@common/api/types/response/common";
import { CommonWithIntegerId } from "@common/types/common";
import { $learnStore } from "@common/api/store/learn";

export const getResources = async (successCallback: any, params?: string) => {
  try {
    const response = await getLearnData<CourseItemInterface>(`/web/learn/resources/${params && `?${params}`}`);
    successCallback(response.data.data);
  } catch (e) {
    console.error("Get resources error");
  }
};

export const getContinueLearning = async (successCallback?: any) => {
  try {
    const response = await getLearnData<CourseItemInterface>(`/web/learn/resources/in-progress`);
    $learnStore.setContinueLearning(response.data.data);
    successCallback && successCallback(response.data.data);
  } catch (e) {
    console.error("Get continue learning error");
  }
};

export const getSelectedForYou = async (successCallback: any) => {
  try {
    const response = await getLearnData<CourseItemInterface>(`/web/learn/resources/for-you`);
    successCallback(response.data.data);
  } catch (e) {
    console.error("Get selected for you error");
  }
};

export const getFavorites = async (successCallback?: any) => {
  try {
    const response = await getLearnData<CourseItemInterface>(`/web/learn/resources/favourites`);
    $learnStore.setFavoriteResources(response.data);
    (response.data.meta.total || response.data.meta.total === 0) &&
      $learnStore.setFavoritesCount(response.data.meta.total);
    successCallback && successCallback(response.data.data);
  } catch (e) {
    console.error("Get favorites error");
  }
};

export const getLearningPaths = async (successCallback: any, params?: string) => {
  try {
    const response = await getLearnData<YourLearningPathsInterface>(`/web/learn/paths/${params && `?${params}`}`);
    successCallback(response.data.data);
  } catch (e) {
    console.error("Get your learning path error");
  }
};

export const getYourLearningPath = async (successCallback: any) => {
  try {
    const response = await getLearnData<YourLearningPathsInterface>(`/web/learn/paths/for-you`);
    successCallback(response.data.data);
  } catch (e) {
    console.error("Get your learning path error");
  }
};

export const addCourseToFavorites = async (values: UpdateCourseFavouriteStatus) => {
  return await setLearnData<UpdateCourseFavouriteStatus>("/web/learn/resources/favourites", values);
};

export const removeCourseFromFavorites = async (values: UpdateCourseFavouriteStatus) => {
  return await deleteLearnData<UpdateCourseFavouriteStatus>("/web/learn/resources/favourites", values);
};

export const getLearningResources = async (
  search = "",
  callback?: any
): Promise<ExtendedDataWrapper<LearningResource[]> | undefined> => {
  try {
    const response = await $api.get<ExtendedDataWrapper<LearningResource[]>>(`/web/learn/resources${search}`);
    const data = response.data;
    callback && callback(data);
    return data;
  } catch (e) {
    console.error("Get learning resources error");
  }
};

export const getLearningResource = async (id: number, callback?: any): Promise<LearningResource | undefined> => {
  try {
    const response = await HttpService.get<LearningResource>(`/web/learn/resources/${id}`);
    const data = response.data;
    callback && callback(data);
    return data;
  } catch (e) {
    console.error("Get learning resource error");
  }
};

export const getLearningResourceSkills = async (id: number, callback?: any) => {
  try {
    const response = await $api.get<ExtendedDataWrapper<ILearnResourceSkill[]>>(`/client/learn/resources/${id}/skills`);
    const data = response.data;
    callback && callback(data);
    return data;
  } catch (e) {
    console.error("Get learning resource skills error");
  }
};

export const getLearningResourceQuizSetting = async (id: number, callback?: any) => {
  try {
    const response = await $api.get<ExtendedDataWrapper<ILearnResourceQuizSetting>>(`/web/learn/resources/${id}/quiz`);
    const data = response.data;
    callback && callback(data);
    return data;
  } catch (e) {
    console.error("Get learning resource quiz error");
  }
};

export const toggleLearningResource = async (id: number, isFavourite: boolean, callback?: any): Promise<void> => {
  try {
    let response;

    if (isFavourite) {
      response = await HttpService.post<{ resource_id: number }>(`/web/learn/resources/favourites`, {
        resource_id: id
      });
    } else {
      response = await HttpService.delete<{ resource_id: number }>(`/web/learn/resources/favourites`, {
        resource_id: id
      });
    }

    const data = response?.data;
    callback && callback(data);
  } catch (e) {
    console.error("Get learning resource error");
  }
};

export const getLearningRelatedResources = async (
  id: number,
  callback?: any,
  params = { params: { "page": 1, "per-page": 1000 } }
): Promise<LearningResource[] | undefined> => {
  try {
    const response = await $api.get<ExtendedDataWrapper<LearningResource[]>>(
      `/web/learn/resources/${id}/related-resources`,
      { params }
    );
    const data = response.data.data;
    callback && callback(data);
    return data;
  } catch (e) {
    console.error("Get learning resource error");
  }
};

export const getLearningResourceNote = async (id: number, callback?: any): Promise<string | undefined> => {
  try {
    const response = await $api.get<DataWrapper<LearningResourceNote>>(`/web/learn/resources/${id}/notes`);
    const data = response.data.data;
    callback && callback(data);
    return data?.text || "";
  } catch (e) {
    console.error("Get learning resource note error");
  }
};

export const setLearningResourceNote = async (
  id: number,
  data: { text: string; is_shareable: boolean },
  callback?: any
): Promise<string | undefined> => {
  try {
    const response = await $api.post<DataWrapper<LearningResourceNote>>(`/web/learn/resources/${id}/notes`, data);
    const savedData = response.data.data;
    callback && callback(savedData);
    return savedData?.text || "";
  } catch (e) {
    console.error("Set learning resource note error");
  }
};

export const setCourseProgress = async (
  id: number,
  progress: number,
  successCallback?: () => void,
  failCallback?: () => void
): Promise<void> => {
  try {
    const setProgress = await HttpService.post("/web/learn/resources/progress", {
      resource_id: id,
      progress
    });
    if (successCallback) {
      successCallback();
    }
  } catch (e) {
    console.error("Set course progress error");
    if (failCallback) {
      failCallback();
    }
  }
};

export const getContentTypes = async (callback?: any): Promise<CommonWithIntegerId[] | undefined> => {
  try {
    const response = await $api.get<DataWrapper<CommonWithIntegerId[]>>("client/learn/content-types");
    const data = response.data.data;
    callback && callback(data);
    $learnStore.setResourceTypes(data);
    return data;
  } catch (e) {
    console.error("Get learning content types error");
  }
};

export const getLanguages = async (callback?: any): Promise<CommonWithIntegerId[] | undefined> => {
  try {
    const response = await $api.get<DataWrapper<CommonWithIntegerId[]>>("client/core/languages");
    const data = response.data.data;
    callback && callback(data);
    $learnStore.setLanguages(data);
    return data;
  } catch (e) {
    console.error("Get core languages error");
  }
};

export const getExpertiseLevels = async (callback?: any): Promise<CommonWithIntegerId[] | undefined> => {
  try {
    const response = await $api.get<DataWrapper<CommonWithIntegerId[]>>("client/learn/expertise-levels");
    const data = response.data.data;
    callback && callback(data);
    $learnStore.setExpertiseLevels(data);
    return data;
  } catch (e) {
    console.error("Get expertise levels error");
  }
};

export const getProviders = async (callback?: any): Promise<CommonWithIntegerId[] | undefined> => {
  try {
    const response = await $api.get<DataWrapper<CommonWithIntegerId[]>>("client/learn/providers");
    const data = response.data.data;
    callback && callback(data);
    $learnStore.setProviders(data);
    return data;
  } catch (e) {
    console.error("Get providers error");
  }
};

export const getLearningPathById = async (id: number, successCallback: any) => {
  try {
    const response = await getLearnDataObject<YourLearningPathsInterface>(`/web/learn/paths/${id}`);
    successCallback(response.data.data);
  } catch (e) {
    console.error("Get your learning path error");
  }
};

export const getLearningPathList = async (id: number, successCallback: any) => {
  try {
    const response = await getLearnDataObject<CourseItemInterface>(`/client/learn/paths/${id}/resources`);
    successCallback(response.data.data);
  } catch (e) {
    console.error("Get learning path list error");
  }
};

type LearningResourceType = { id: number, order: number };

export const setLearningPathList = async (id: number, data: LearningResourceType[], successCallback?: any) => {
  try {
    const response = await setLearnData<{ resources: LearningResourceType[]}, DataWrapper<LearningResource>>(
      `/client/learn/paths/${id}/resources`,
      { resources: data }
    );
    successCallback && successCallback(response.data.data);
  } catch (e) {
    console.error("Get learning path list error");
  }
};

export const getRelatedLearningPaths = async (id: number, successCallback: any) => {
  try {
    const response = await getLearnDataObject<YourLearningPathsInterface>(`/web/learn/paths/${id}/related-paths`);
    successCallback(response.data.data);
  } catch (e) {
    console.error("Get related learning paths error");
  }
};

export const setLearningPathRating = async (id: number, values: SetRating): Promise<void> => {
  try {
    const setRating = setLearnData<SetRating>(`/web/learn/paths/${id}/rating`, values);
  } catch (e) {
    console.error("Set learning rating error");
  }
};

export const setResourceRating = async (id: number, values: SetRating): Promise<void> => {
  try {
    const setRating = await setLearnData<SetRating>(`/web/learn/resources/${id}/rating`, values);
  } catch (e) {
    console.error("Set video course rating error");
  }
};

export const getResourceRating = async (id: number, callback?: any): Promise<number> => {
  try {
    const {
      data: { data }
    } = await getLearnDataObject<{ value: number }>(`/web/learn/resources/${id}/rating`);
    callback && callback(data.value);
    return data.value;
  } catch (e) {
    console.error("Get video course rating error");
  }
  return 0;
};

export const setLearningPathDetail = async (
  value: ILearningPath,
  id?: number,
  successCallback?: any,
): Promise<ILearningPathRes | undefined> => {
  try {
    const response = await setLearnData<ILearningPath, DataWrapper<ILearningPathRes>>(
      `/client/learn/paths${id ? `/${id}` : ''}`,
      value
    );
    successCallback && successCallback(response.data.data);
    return response.data.data;
  } catch (e) {
    console.error("Set learning rating error");
  }
};

export const getTreeView = async (callback: any) => {
  try {
    const response = await getLearnDataObject<TreeViewInterface>(`/web/learn/resources/tree-view`);
    callback(response.data.data);
  } catch (e) {
    console.error("Get tree view error");
  }
};

export const setVideoRating = async (id: number, values: SetRating): Promise<void> => {
  try {
    const setRating = setLearnData<SetRating>(`/web/learn/resources/${id}/rating`, values);
  } catch (e) {
    console.error("Set video course rating error");
  }
};

export const getVideoRating = async (id: number, callback?: any): Promise<number> => {
  try {
    const {
      data: { data }
    } = await getLearnDataObject<{ value: number }>(`/web/learn/resources/${id}/rating`);
    callback && callback(data.value);
    return data.value;
  } catch (e) {
    console.error("Get video course rating error");
  }
  return 0;
};

export const createResource = async (values: ILearnResource) => {
  try {
    const response = await $api.post<DataWrapper<ILearnResource>>(`/client/learn/resources`, values);
    return response.data.data;
  } catch (e) {
    console.error("create learn resource error");
  }
};

export const updateResource = async (resourceId: number | undefined, values: ILearnResource) => {
  try {
    const response = await $api.post<DataWrapper<ILearnResource>>(`/client/learn/resources/${resourceId}`, values);
    return response.data.data;
  } catch (e) {
    console.error("update learn resource error");
  }
};

export const attachResourceSkills = async (resourceId: number | undefined, values: ILearnResourceSkillsForm) => {
  try {
    const response = await $api.post<DataWrapper<ILearnResource>>(
      `/client/learn/resources/${resourceId}/skills`,
      values
    );
    return response.data.data;
  } catch (e) {
    console.error("attach resource skills error");
  }
};

export const saveResourceQuizSetting = async (resourceId: number | undefined, values: ILearnResourceQuizSetting) => {
  try {
    const response = await $api.post<DataWrapper<ILearnResource>>(`/web/learn/resources/${resourceId}/quiz`, values);
    return response.data.data;
  } catch (e) {
    console.error("save resource quiz setting error");
  }
};

export const uploadQuizQuestions = async (resourceId: number | undefined, file: File | string | null) => {
  try {
    if (file instanceof File) {
      await setLearnFileService(`/web/learn/resources/${resourceId}/quiz-questions`, file);
    }
  } catch (e) {
    console.error("upload quiz questions error error");
  }
};
