import { getLearningResourceQuizSetting, getLearningResourceSkills } from "@common/features/learn/model";
import { LearningResource } from "@common/types/reports/learn/learning-resource";

export const getSupplementedResource = async (resource: LearningResource) => {
  const [resourceSkills, resourceQuiz] = [
    await getLearningResourceSkills(resource.id),
    await getLearningResourceQuizSetting(resource.id)
  ];
  return {
    ...resource,
    skills:
      resourceSkills?.data?.map(skill => ({
        id: skill.id,
        name: skill.name,
        category: skill?.category?.name || ""
      })) || [],
    quiz: resourceQuiz?.data
  };
};
