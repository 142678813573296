import styles from "./account-activation-page.module.scss";
import grid from "@styles/modules/grid.module.scss";
import { AlertModal, ShadowBlock, Titles } from "@ui";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { AccountActivationForm } from "@features/user/forms/account-activation";
import { DefaultLayout } from "../../layouts";

export const AccountActivationPage = () => {
  const navigate = useNavigate();
  const [isSuccess, setModalType] = useState<boolean>();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("token") || "";

  useEffect(() => {
    if (!token) {
      navigate("/login/");
    }
  }, [token]);

  const [sendModalIsOpen, setSendModalIsOpen] = useState<boolean>(false);

  const onCloseModal = () => {
    setSendModalIsOpen(false);
    if (isSuccess) {
      navigate("/login/");
    }
  };

  return (
    <DefaultLayout>
      <div className={styles.wrapper}>
        <div className={grid.root}>
          <div className={grid.container}>
            <ShadowBlock modClass={styles.block}>
              <div className={styles.inner}>
                <Titles level="level_1">Account Activation</Titles>
                <AccountActivationForm
                  token={token}
                  failCallback={() => {
                    setModalType(false);
                    setSendModalIsOpen(true);
                  }}
                  successCallback={() => {
                    setModalType(true);
                    setSendModalIsOpen(true);
                  }}
                />
                <AlertModal
                  closeModal={onCloseModal}
                  isOpen={sendModalIsOpen}
                  title={isSuccess ? "Success!" : "There seems to be a problem"}
                  cancelButtonText={isSuccess ? "Close" : "OK"}
                  type={isSuccess ? "success" : "fail"}
                  message={
                    isSuccess
                      ? "You have successfully set a new password. After closing the window you will be redirected to the authorization page"
                      : "Something went wrong. Please try again or try again later"
                  }
                />
              </div>
            </ShadowBlock>
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
};
