import { TableCellProps } from "@common/types/table";
import { useState } from "react";
import styles from "./edit-action.module.scss";
import { OrgUnitChangeModal } from "../modals";

interface EditActionProps extends TableCellProps {}

export const OrgUnitEditAction: React.FC<EditActionProps> = ({ props }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { column, value } = props;

  return (
    <>
      <div className={styles.text} onClick={() => setIsOpen(true)}>
        {(column.title && value) || "Edit"}
      </div>
      <OrgUnitChangeModal closeModal={() => setIsOpen(false)} isOpen={isOpen} props={props} />
    </>
  );
};
