import React, { ChangeEventHandler, ReactElement } from "react";
import form from "@styles/modules/form.module.scss";
import classNames from "classnames";
import { InputInterface } from "@common/types/form";

// TODO: add type and any input properties
interface Props extends Omit<InputInterface, "value"> {
  value: number;
  min?: number;
  max?: number;
  step?: number;
  label?: string | ReactElement;
  labelClass?: string;
  wrapperClass?: string;
  showErrorText?: boolean;
  disabled?: boolean;
  onChange(value: number): void;
}

export function FormInputNumberSpinner({
  name,
  className,
  value,
  min,
  max,
  step = 1,
  label,
  labelClass,
  wrapperClass,
  disabled,
  showErrorText = true,
  register,
  error,
  theme = "primary",
  onChange,
  ...props
}: Props) {
  const wrapperClassName = classNames(form.field, form["number-spinner"], form[`theme-${theme}`], wrapperClass);

  const inputClass = classNames(form.text, form.input, className, error && form["input-error"]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    let value = Number(e.target.value);
    if (typeof min === "number") {
      value = Math.max(min, value);
    }
    if (typeof max === "number") {
      value = Math.min(max, value);
    }
    onChange(value);
  };

  return (
    <div className={wrapperClassName} data-error={showErrorText ? error : ""}>
      {label && (
        <label className={classNames("text-xs", error ? "text-danger" : "text-gray", labelClass)}>{label}</label>
      )}
      <div className="flex items-stretch">
        <button
          type="button"
          disabled={disabled || (typeof min === "number" && value - step < min)}
          onClick={() => onChange && onChange(+value - step)}
        >
          -
        </button>
        <input
          {...props}
          type="text"
          value={value}
          {...(register && register(name))}
          className={inputClass}
          disabled={disabled}
          onChange={handleChange}
        />
        <button
          type="button"
          disabled={disabled || (typeof max === "number" && value + step > max)}
          onClick={() => onChange && onChange(+value + step)}
        >
          +
        </button>
      </div>
    </div>
  );
}
