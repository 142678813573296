import classNames from "classnames";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import styles from "./modal.module.scss";
import grid from "@styles/modules/grid.module.scss";

export const getDefaultProps = () => {
  const afterOpen = () => {
    disablePageScroll();
  };

  const afterClose = () => {
    enablePageScroll();
  };
  return {
    ariaHideApp: false,
    overlayClassName: classNames(styles.overlay, grid.root),
    onAfterOpen: afterOpen,
    onAfterClose: afterClose
  };
};
