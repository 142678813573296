import { getPhrases } from "@common/helpers/localization";
import { Skill } from "@common/types/reports/skills/skills";
import { TableColumn } from "@common/types/table";
import classNames from "classnames";
import { DataType, SortDirection } from "ka-table/enums";
import grid from "@styles/modules/grid.module.scss";
import { ProgressCell, StatusCell } from "@ui";

const loc = getPhrases("skills_page", "table_inner");

export const skillsColumns: TableColumn<Skill>[] = [
  {
    key: "is_active",
    title: loc.title_status,
    dataType: DataType.Boolean,
    sortDirection: SortDirection.Descend,
    modClass: classNames(grid.item, grid["item-3x"]),
    component: StatusCell
  },
  {
    key: "name",
    title: loc.title_name,
    dataType: DataType.String,
    modClass: classNames(grid.item, grid["item-3x"])
  },
  {
    key: "employees_count",
    title: loc.title_count,
    dataType: DataType.Number,
    modClass: classNames(grid.item, grid["item-3x"])
  },
  {
    key: "avg_skill_level",
    title: loc.title_average,
    dataType: DataType.Number,
    modClass: classNames(grid.item, grid["item-3x"]),
    component: ProgressCell
  }
];
