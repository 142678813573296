import React, { FocusEventHandler, useCallback, useContext, useEffect, useState } from "react";
import styles from "./course-details.module.scss";
import Grid from "@styles/modules/grid.module.scss";
import classNames from "classnames";
import {
  TextWithDescription,
  Rating,
  CategoryAndSubCategory,
  OutlinedButtonWithIcon,
  Button,
  Svg,
  Titles,
  RatingWithNumber,
  MarkAsCompleteButton
} from "@ui";
import { getPhrases } from "@common/helpers/localization";
import { RelatedResourceCard } from "../learn/related-resource-card";
import { CourseModal } from "@features/learn/course-modal/CourseModal";
import { useParams } from "react-router-dom";
import { LearningResource } from "@common/types/reports/learn/learning-resource";
import {
  getLearningRelatedResources,
  getLearningResource,
  getLearningResourceNote,
  setLearningResourceNote,
  setCourseProgress,
  toggleLearningResource,
  setResourceRating,
  getResourceRating
} from "@common/features/learn/model";
import { getDurationHoursAndMinutes } from "@common/helpers/time-helpers";
import { CourseNotifyModal } from "@features/learn/course-notify-modal/course-notify-modal";
import { NotifyModal } from "@features/learn/course-notify-modal/types";
import { AddLearningResourceModal } from "@features/learn/modals";
import { observer } from "mobx-react-lite";
import { GlobalStore } from "@common/api/store/global";
import { Roles } from "@common/constants";
import { ContentTypeEnum } from "@common/types/reports/learn/learn";
import { getSupplementedResource } from "@features/learn/helpers";

export const CourseDetails = observer(() => {
  const { $learnStore, $profileStore } = useContext(GlobalStore);
  const role = $profileStore.getCurrentRoleId();
  const [loc_title_block] = useState(getPhrases("course_details_page", "title_block"));
  const [loc_overview_block] = useState(getPhrases("course_details_page", "overview_block"));
  const [loc_related_block] = useState(getPhrases("course_details_page", "related_block"));
  const [loc_summary_block] = useState(getPhrases("course_details_page", "summary_block"));

  const { id } = useParams();
  const [isFavourite, setIsFavourite] = useState<boolean>(false);
  const [note, setNote] = useState<string>("");
  const [resource, setResource] = useState<LearningResource>();
  const [editableResource, setEditableResource] = useState<LearningResource>();
  const [relatedResources, setRelatedResources] = useState<LearningResource[]>();
  const [isCourseCompleted, setIsCourseCompleted] = useState<boolean>(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState<boolean>(false);
  const [isCourseModalOpen, setIsCourseModalOpen] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [myRate, setMyRate] = useState<number>(0);

  useEffect(() => {
    if (resource) {
      setIsCourseCompleted(resource?.progress === 100);
      setIsFavourite(!!resource?.is_favourite);
    }
  }, [resource]);

  const [isOpenEditModal, setOpenEditModalStatus] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      getLearningResource(+id, setResource);
      getResourceRating(+id, setMyRate);
      getLearningRelatedResources(+id, setRelatedResources);
      getLearningResourceNote(+id).then(note => setNote(note || ""));
    }
  }, [id]);

  const handleOpenCourse = useCallback(() => {
    if (resource && Number(resource.content_type.id) !== ContentTypeEnum.Video && +(resource.progress || 0) < 5) {
      setCourseProgress(resource.id, 5);
    }
    setIsCourseModalOpen(true);
  }, [resource]);

  const handleNoteChange: FocusEventHandler<HTMLTextAreaElement> = useCallback(
    e => {
      if (id) {
        const text = e.target.value;
        setLearningResourceNote(+id, { text, is_shareable: true });
      }
    },
    [id]
  );

  const updateActiveTabIndex = useCallback((index: number) => {
    setTabIndex(index);
  }, []);

  const toggleVideoModal = useCallback(() => {
    setIsVideoModalOpen(isVideoModalOpen => !isVideoModalOpen);
  }, []);

  const toggleIsFavourite = useCallback(() => {
    if (resource) {
      toggleLearningResource(resource.id, !isFavourite).then(() => setIsFavourite(!isFavourite));
    }
  }, [isFavourite, resource]);

  const toggleIsCourseCompleted = useCallback(() => {
    if (resource) {
      setCourseProgress(resource.id, 100).then(() => {
        getLearningResource(resource.id, setResource);
        $learnStore.updateContinueLearning();
      });
    }
  }, [isCourseCompleted, resource]);

  const onChangeRating = (value: number) => {
    if (resource?.id) {
      setResourceRating(resource.id, { value }).then(data => getLearningResource(resource.id, setResource));
      setResourceRating(resource.id, { value }).then(data => getLearningResource(resource.id, setResource));
    }
  };

  const notifyData: NotifyModal = {
    title: resource?.title || "",
    authorName: resource?.provider.name || "",
    logo: resource?.provider.favicon_image_url || "",
    url: resource?.url || ""
  };

  const isClientAdmin = role === Roles.clientAdmin;

  const getOpenButtonText = (type: ContentTypeEnum) => {
    switch (type) {
      case 1:
        return "Watch the video";
      case 2:
        return "Open the online course";
      case 3:
        return "Open the document";
      case 4:
        return "Read the article";
    }
  };

  const onEditResourceClickHandler = async () => {
    if (resource) {
      await setEditableResource(await getSupplementedResource(resource));
      setOpenEditModalStatus(true);
    }
  };

  const onCloseEditModalHandler = () => {
    if (id) {
      getLearningResource(+id, setEditableResource);
    }
    setOpenEditModalStatus(false);
  };

  return (
    <div className={Grid["page-row"]}>
      <div className={Grid.root}>
        <div className={Grid.container}>
          {resource?.skill_categories.common && resource?.skill_categories.common_root && (
            <CategoryAndSubCategory
              category={resource.skill_categories.common}
              subcategory={resource.skill_categories.common_root}
            />
          )}
          <div className={styles["course-desc"]}>
            Here are the details of the course you have selected - click on the right to get started
          </div>

          <div className={classNames(Grid.list, styles["top-details-container"])}>
            <div className={classNames(Grid.item, Grid["item-9x"])}>
              {/*     Top Details Card    */}
              <div className={styles["top-details"]}>
                {/*     Logos And Actions   */}
                <div className={styles["logo-and-actions"]}>
                  <img className={styles.logo} src={resource?.provider.logo_image_url} alt="Logo" />

                  {/*     top details actions     */}
                  <div className={styles.actions}>
                    {isClientAdmin && (
                      <Button
                        className={styles.editButton}
                        size="thin"
                        title="Edit"
                        theme="blue"
                        onClick={onEditResourceClickHandler}
                      />
                    )}

                    <OutlinedButtonWithIcon
                      disabled
                      theme={"orange"}
                      icon={<Svg name={"list"} />}
                      text={<div className={styles.addToListBtn}>{loc_title_block.add_to_list}</div>}
                    />

                    <MarkAsCompleteButton isCourseCompleted={isCourseCompleted} onClick={toggleIsCourseCompleted} />

                    <Svg
                      onClick={toggleIsFavourite}
                      modClass={styles.heartIcon}
                      name={isFavourite ? "heart-red" : "heart-gray"}
                    />
                  </div>
                </div>

                {/*     Course Title    */}
                <Titles level={"level_3"} modClass={styles.title}>
                  {resource?.title}
                </Titles>

                <p className={styles["course-source"]}>Average rating </p>
                <RatingWithNumber totalRatings={resource?.rating_count || 0} rating={resource?.rating_average || 0} />
              </div>

              {/*     Bottom Course Overview Card    */}
              <div className={styles["course-overview"]}>
                <Titles level={"level_3"} modClass={styles.title}>
                  {loc_overview_block.overview}
                </Titles>
                {/* <p className={styles.description}>
                  {resource?.short_description || "No description"}
                </p> */}

                <Titles level={"level_3"} modClass={`${styles.heading} ${styles["syllabus-heading"]}`}>
                  Syllabus
                </Titles>

                <div className={styles["syllabus-details"]}>{resource?.syllabus || "No syllabus"}</div>

                {/*<div className={styles["read-more-syllabus"]}>Read More</div>*/}

                <div>
                  <Titles level={"level_3"} modClass={classNames("text-blue-700 mt-10 mb-3")}>
                    Covered Skill(s)
                  </Titles>
                  <div className={classNames("flex flex-col text-blue-700 text-base")}>
                    {resource?.skills.length
                      ? resource.skills.map(skill => (
                          <span key={skill.id} className="ml-2">
                            – {skill}
                          </span>
                        ))
                      : "No covered skills"}
                  </div>
                </div>

                <div>
                  <Titles level={"level_3"} modClass={styles.heading}>
                    Instructor(s)
                  </Titles>
                  <p className={styles.instructor}>{resource?.instructors || "No instructors"}</p>
                </div>

                <div>
                  <Titles level={"level_3"} modClass={styles.heading}>
                    {loc_overview_block.tags}
                  </Titles>

                  <div className={styles.tagsContainer}>
                    {/*<Button
                      className={styles.tag}
                      appearance={"bordered"}
                      size={"thin"}
                      theme={"dark-blue"}
                    >
                      Gaftana
                    </Button>*/}
                    <span className="text-blue">No tags</span>
                  </div>
                </div>
              </div>

              {/*     Related Learning Resources    */}
              <RelatedResourceCard
                title={loc_related_block.related_learning_resources}
                cardsData={relatedResources || []}
              />

              {/*       Learning Tracks and Notes     */}
              <div className={styles["course-notes"]}>
                <div className={classNames(Grid.container)}>
                  <div className={classNames(Grid.list)}>
                    {/*<div className={classNames(Grid.item, Grid["item-3x"])}>
                      <Titles
                        modClass={classNames(styles["heading"])}
                        level={"level_3"}
                      >
                        Keep track of <br />
                        your learning
                      </Titles>

                      <div className={styles["keep-track__desc"]}>
                        This is where we see if you can recall and put into
                        practical use the information provided in the video.
                        Please note that in order to start this quiz you must
                        either complete the video or mark it as complete.
                        <br /> <br />
                        Each of the questions will have an accompanying
                        countdown of 60 seconds: failure to select the answer
                        will mark as a failed question
                      </div>

                      <Titles
                        level={"level_3"}
                        modClass={classNames(
                          styles["heading"],
                          styles["heading-2"],
                          styles["personal-checklist__heading"]
                        )}
                      >
                        Your personal checklist
                      </Titles>

                      <div className={classNames(styles["checkbox-list"])}>
                             Heading With Checkbox
                        <CheckboxWithDesc
                          isChecked={true}
                          desc={"Create a script to import the database"}
                        />

                        <CheckboxWithDesc
                          isChecked={true}
                          desc={"Create a script to import the database"}
                        />

                        <CheckboxWithDesc
                          isChecked={false}
                          desc={"Create a script to import the database"}
                        />
                      </div>
                    </div>*/}

                    <div className={classNames(Grid.item, Grid["item-12x"])}>
                      <Titles level={"level_3"} modClass={classNames(styles["heading"], styles["notes-heading"])}>
                        Notes <span className="text-sm">(autosaved)</span>
                      </Titles>

                      <textarea
                        className={styles["notes-input"]}
                        value={note || ""}
                        onChange={e => setNote(e.target.value)}
                        onBlur={handleNoteChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*   Right Side Course Card
                (includes course details and open course button)
            */}
            <div className={classNames(Grid.item, Grid["item-3x"])}>
              <div className={styles["course-details-card"]}>
                {/*     Course Image    */}
                <div className={styles["image-container"]}>
                  <img className={styles.image} src={resource?.image_url} alt="course-main" />
                  <div className={styles["play-icon"]}>
                    <Svg modClass={styles["play-circle"]} name={"play-circle"} />
                  </div>
                </div>

                {/*    Open the Course Btn      */}
                <div className={styles["course-details"]}>
                  <Button onClick={handleOpenCourse} className={styles.courseBtn} appearance="framed" theme="orange">
                    {resource?.content_type.id && getOpenButtonText(Number(resource.content_type.id))}
                  </Button>

                  <div className={styles.features}>
                    <TextWithDescription text="Provider" description={resource?.provider?.name} />

                    <TextWithDescription text="Type" description={resource?.content_type?.name} />

                    <TextWithDescription text="Language" description={resource?.language?.name} />

                    <TextWithDescription
                      text="Duration"
                      description={getDurationHoursAndMinutes(resource?.duration || 0)}
                    />

                    <TextWithDescription text="Created" description="11th Apr 2022" />

                    <TextWithDescription text="Level" description={resource?.expertise_level?.name} />

                    <TextWithDescription text="Closed Captions" description="English" />

                    {/*<TextWithDescription*/}
                    {/*  description={'asdfas'}*/}
                    {/*  text={loc_summary_block.share_this_course}*/}
                    {/*/>*/}
                  </div>
                </div>
                <div className={styles["course-rating"]}>
                  <p className={styles.title}>{loc_summary_block.share_this_course}</p>
                </div>

                <div className={styles["course-rating"]}>
                  <p className={styles.title}>{loc_summary_block.rate_this_course}</p>

                  <Rating rating={myRate} onChange={onChangeRating} />
                </div>

                <div className={styles["course-bottom"]}>
                  <Button
                    onClick={() => {
                      setTabIndex(1);
                      toggleVideoModal();
                    }}
                    disabled
                    className={styles.quizBtn}
                    appearance={"framed"}
                    theme={"blue"}
                  >
                    Take a quiz
                  </Button>
                  <div className={styles["bottom-details"]}>
                    Take a quiz once you completed the review of this learning resource. It will allows us to tailor the
                    content to your needs fully.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {resource && isCourseModalOpen && (
          <>
            {Number(resource.content_type.id) === ContentTypeEnum.Video ? (
              <CourseModal
                isOpenModal={isCourseModalOpen}
                toggleModal={() => setIsCourseModalOpen(false)}
                isCourseCompleted={resource.progress === 100}
                toggleIsCourseCompleted={() => console.log("course completed")}
                activeTabIndex={0}
                updateActiveTabIndex={() => console.log("update tabindex")}
                {...resource}
              />
            ) : (
              <CourseNotifyModal
                isOpenModal={isCourseModalOpen}
                toggleModal={() => setIsCourseModalOpen(false)}
                {...notifyData}
              />
            )}
          </>
        )}
        {editableResource && isOpenEditModal && isClientAdmin && (
          <AddLearningResourceModal
            isOpen={true}
            closeModal={onCloseEditModalHandler}
            editableResource={editableResource}
          />
        )}
      </div>
    </div>
  );
});
