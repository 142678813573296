import React, { ReactNode } from "react";
import classNames from "classnames";
import styles from "./outlined-btn-with-icon.module.scss";
import { Button } from "@ui";

interface Props {
  icon: ReactNode;
  text: string | ReactNode;
  modClass?: string;
  iconRight?: boolean;
  disabled?: boolean;
  theme?: "blue" | "orange" | "gray" | "dark-blue" | "red" | "dark-gray";
  onClick?: () => void;
}

export const OutlinedButtonWithIcon: React.FC<Props> = ({
  icon,
  text,
  iconRight,
  disabled,
  theme,
  modClass,
  onClick
}) => {
  return (
    <Button
      className={classNames(styles["btn-outlined"], modClass)}
      disabled={disabled}
      size="thin"
      appearance="bordered"
      theme={theme || "dark-gray"}
      onClick={onClick}
    >
      {!iconRight && icon} {text} {iconRight && icon}
    </Button>
  );
};
