import { FC } from "react";
import classNames from "classnames";
import { Button } from "@ui";
import { PureModal } from "../../../../ui/modal/pure-modal";

export interface IUploadConfirmModalProps {
  className?: string;
  onClose(result: boolean): void;
}

export const UploadConfirmModal: FC<IUploadConfirmModalProps> = ({ className, onClose }) => {
  return (
    <PureModal isOpen overlayClassName="!bg-black !bg-opacity-60" closeModal={() => onClose(false)}>
      <div className={classNames("bg-white rounded shadow-md", className)}>
        <div className="flex items-center justify-between border-b border-solid border-primary px-4 py-3">
          <div className="text-primary-dark text-md font-bold">Select an image from local device</div>
          <i className="fa fa-times cursor-pointer text-gray" onClick={() => onClose(false)} />
        </div>
        <div className="text-black text-center px-12 py-6">
          <p className="text-sm">
            Please note: the image width should be at least 421 pixels, height at least 282 pixels
          </p>
          <Button
            className="!w-24 !h-7 !text-sm !font-semibold mt-4 mx-auto"
            theme="blue"
            appearance="solid"
            onClick={() => onClose(true)}
          >
            Upload
          </Button>
        </div>
      </div>
    </PureModal>
  );
};
