import languages from "./languages.json";

const hash = 1646029324529;
const storageName = "localization";
const localization = {} as any;

const memoriedLocalization: string | null = localStorage.getItem(storageName);
const parsedLocalization = memoriedLocalization ? JSON.parse(memoriedLocalization) : false;

export const getCurrentLanguage = (): string => {
  const defaultLanguage = "en-EN";

  return parsedLocalization?.["currentLanguage"] ?? defaultLanguage;
};

// export const CURRENT_LANGUAGE = "ru-RU";
export const CURRENT_LANGUAGE = "en-EN";
// export const CURRENT_LANGUAGE = getCurrentLanguage();

const getLocalization = () => {
  let constructedPages = {};
  const isHashExpired = parsedLocalization && parsedLocalization.langs[CURRENT_LANGUAGE].hash !== hash;
  if (!memoriedLocalization || isHashExpired) {
    const stringifiedLangs = JSON.stringify(languages);
    const langs = JSON.parse(stringifiedLangs);
    const savedLangs = {
      currentLanguage: CURRENT_LANGUAGE,
      langs: languages
    };
    localStorage.setItem(storageName, JSON.stringify(savedLangs));
    constructedPages = langs?.[CURRENT_LANGUAGE]?.pages;
  }
  const pages = Object.keys(constructedPages).length
    ? constructedPages
    : parsedLocalization?.["langs"]?.[CURRENT_LANGUAGE]?.["pages"];
  if (pages) {
    return pages;
  }

  console.error("Failed to access language data");
  return {};
};

export const getPhrases = (page?: string, block?: string) => {
  // TODO: Think about show errors of common blocks

  if (!Object.keys(localization).length) {
    const loc = getLocalization();
    localization["pages"] = loc;
  }
  const commonPhrases = localization["pages"]["common"];
  if (page && block) {
    const phrasesBlock = localization["pages"]?.[page]?.[block];

    if (!phrasesBlock) {
      console.error("Failed to get data for the specified block. Check if page and block name are correct");
    }
    return { ...phrasesBlock, ...commonPhrases };
  } else {
    return commonPhrases;
  }
};
