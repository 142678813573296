import React, { useContext, useMemo, useState } from "react";
import classNames from "classnames";
import { EmployeeAnalytics } from "@common/types/reports/analytics/analytics";
import { getPhrases } from "@common/helpers/localization";
import { EmployeeFilter } from "@features/reporting/employee-filter";
import { ScoreCardModal } from "@features/reporting/score-card-modal";
import grid from "@styles/modules/grid.module.scss";
import { FirstLevelTable, Titles } from "@ui";
import { employeeOverviewColumns } from "../model";
import styles from "../report-overview.module.scss";
import { EmployeeView } from "./employee-view";
import { GlobalStore } from "@common/api/store/global";
import { KpiTooltip } from "@features/reporting/kpi-tooltip";

interface EmployeeOverviewProps {
  route: string;
}

export const EmployeeOverview: React.FC<EmployeeOverviewProps> = ({ route }) => {
  const { $analyticsStore } = useContext(GlobalStore);
  const [selectedEmployee, setSelectedEmployee] = useState<EmployeeAnalytics | undefined>();
  const [loc] = useState(getPhrases("reporting_page", "overview"));
  const category = useMemo(
    () => (route.includes("byEmployee>") ? route.split("byEmployee>")[1].toLowerCase() : ""),
    [route]
  );
  const kpiList = useMemo(
    () => [
      {
        value: $analyticsStore.employeesSummary?.count ?? 0,
        title: "Total number of employees"
      },
      {
        value: $analyticsStore.employeesSummary?.avg_skills_count ?? 0,
        title: "Average skills per employee"
      },
      {
        value: $analyticsStore.employeesSummary?.avg_industry_experience ?? 0,
        title: "Average industry Experience (Years)"
      },
      {
        value: $analyticsStore.employeesSummary?.avg_company_tenure ?? 0,
        title: "Average tenure (Years)"
      },
      {
        value: $analyticsStore.employeesSummary?.high_expertise_count ?? 0,
        title: "Employees rated High Expertise",
        tooltip: {
          title: "HIGH EXPERTISE LEVEL",
          description:
            "Employees who are on target or above target for at least 75% of skills AND have an average overall score greater than or equal to their average target"
        }
      },
      {
        value: $analyticsStore.employeesSummary?.medium_expertise_count ?? 0,
        title: "Employees rated Medium Expertise",
        tooltip: {
          title: "MEDIUM EXPERTISE LEVEL",
          description:
            "Employees who are on target or above target for 60-75% of skills OR have an average overall score greater than or equal to their average target"
        }
      },
      {
        value: $analyticsStore.employeesSummary?.near_expertise_count ?? 0,
        title: "Near Expertise Level",
        tooltip: {
          title: "NEAR EXPERTISE LEVEL",
          description:
            "Employees who are on target or above target for 50-60% of skills AND have an average overall score below their average target"
        }
      },
      {
        value: $analyticsStore.employeesSummary?.limited_expertise_count ?? 0,
        title: "Employees rated Limited expertise",
        tooltip: {
          title: "LIMITED EXPERTISE LEVEL",
          description:
            "Employees who are on target or above target for less than 50% of skills AND have an average overall score below their average target"
        }
      },
      {
        value: $analyticsStore.employeesSummary?.count
          ? $analyticsStore.employeesSummary?.count -
            $analyticsStore.employeesSummary?.high_expertise_count -
            $analyticsStore.employeesSummary?.medium_expertise_count -
            $analyticsStore.employeesSummary?.near_expertise_count -
            $analyticsStore.employeesSummary?.limited_expertise_count
          : 0,
        title: "Employee not rated"
      }
    ],
    [$analyticsStore.employeesSummary]
  );

  const handleSelect = (name: string | null) => {
    const selEmployee = $analyticsStore.employees.find(employee => employee.full_name === name);
    setSelectedEmployee(selEmployee);
  };

  const columns = useMemo(() => {
    const [nameColumn, ...rest] = employeeOverviewColumns;
    return [
      {
        ...nameColumn,
        actionInfo: {
          onClick: handleSelect
        }
      },
      ...rest
    ];
  }, []);

  return (
    <>
      <div className={classNames(grid.container, styles["page-container"])}>
        {!category && (
          <>
            <Titles level="level_3" modClass={styles.title}>
              {loc.byEmployeeTitle} {loc.reporting}
            </Titles>
            <p className={styles.description}>{loc.description}</p>
            <div className={styles["supervisor-kpi-box-wrapper"]}>
              {kpiList.map((item, index) => (
                <React.Fragment key={item.title}>
                  {index > 0 && <div className={styles["kpi-box-divider"]} />}
                  <div className={styles["kpi-box"]}>
                    <div className="w-full flex justify-between">
                      <Titles level="level_3" modClass={classNames(styles["kpi-box-title"], "flex")}>
                        {item.value}
                      </Titles>
                      {item.tooltip && (
                        <KpiTooltip className="mt-2 mr-3 mb-auto" {...item.tooltip}>
                          <div className="w-4 h-4 flex justify-center items-center text-xs rounded-full border border-solid border-white cursor-pointer select-none">
                            ?
                          </div>
                        </KpiTooltip>
                      )}
                    </div>
                    <p className={classNames(styles["kpi-box-description"], styles.team)}>{item.title}</p>
                  </div>
                </React.Fragment>
              ))}
            </div>
            {/*TODO: need to uncomment after integration*/}
            {/*<EmployeeFilter category="all employees" modClass={styles["supervisor-filter"]} />*/}
            <div>
              {FirstLevelTable<EmployeeAnalytics>({
                columns,
                data: $analyticsStore.employees,
                modStyles: styles
              })}
            </div>
          </>
        )}
        {category && <EmployeeView employeeType={category} columns={columns} />}
      </div>
      {selectedEmployee && (
        <ScoreCardModal
          name={selectedEmployee.full_name}
          url={selectedEmployee.skills_report_card_url}
          onClose={() => setSelectedEmployee(undefined)}
        />
      )}
    </>
  );
};
