import { getCalendarData } from "@common/helpers/data-helpers";
import { getPhrases } from "@common/helpers/localization";
import { PeopleFormSendInterface } from "@common/types/reports/profile/profile";
import * as yup from "yup";
import { AnyObject } from "yup/lib/object";

const loc = getPhrases("common", "forms").validation;

const ert = (value: number | undefined, context: yup.TestContext<AnyObject>): boolean => {
  if (context.parent.start_date && value) {
    const startDataTimestamp = new Date(context.parent.start_date.split(".").reverse().join());
    const nowTimestamp = new Date();
    const yearsDifferent = nowTimestamp.getUTCFullYear() - startDataTimestamp.getUTCFullYear();
    return value - yearsDifferent >= 0;
  }
  return false;
};

export const EditProfileSchema: yup.SchemaOf<PeopleFormSendInterface> = yup.object().shape({
  first_name: yup.string().required(loc.required),
  middle_name: yup.string(),
  last_name: yup.string().required(loc.required),
  linkedin: yup.string(),
  primary_phone: yup.string(),
  industry_experience: yup
    .number()
    .required(loc.required)
    .typeError("Industry experience must be a number")
    .test(
      "IndustryExperienceValid",
      "Industry experience should be greater than company experience (Today - Start date)",
      (value, context) => ert(value, context)
    ),
  start_date: yup.string().required(loc.required),
  timezone_id: yup.number(),
  employment_type_id: yup.number().required(loc.required),
  work_type_id: yup.number().required(loc.required),
  office_id: yup.number().required(loc.required),
  email: yup.string().required(loc.required).email(loc.email_format)
});
