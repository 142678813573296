import React from "react";
import styles from "./notes-tab.module.scss";
import { Button, CheckboxWithDesc } from "@ui";

export const NotesTab = () => {
  return (
    <div className={styles.notes}>
      {/*     Left Side of notes    */}
      <div className={styles.left}>
        <h1 className={styles.heading}>Keep track of your learning</h1>

        <p className={styles.desc}>
          This is where we see if you can recall and put into practical use the information provided in the video.
          Please note that in order to start this quiz you must either complete the video or mark it as complete.
          <br /> <br />
          Each of the questions will have an accompanying countdown of 60 seconds: failure to select the answer will
          mark as a failed question
        </p>

        <div className={styles.checklist}>
          <h1 className={styles.heading}>Your personal checklist</h1>
          <div className={styles["checklist-list"]}>
            <CheckboxWithDesc isChecked={true} desc={"Create a script to import the database"} />

            <CheckboxWithDesc isChecked={true} desc={"Create a script to import the database"} />

            <CheckboxWithDesc isChecked={true} desc={"Create a script to import the database"} />
          </div>
        </div>
      </div>

      {/*   Right Side of notes   */}
      <div className={styles.right}>
        <h1 className={styles.heading}>Notes</h1>
        <textarea rows={20} className={styles["notes-input"]} />
        <div>
          <Button className={styles["save-btn"]}>Save & Close</Button>
        </div>
      </div>
    </div>
  );
};
