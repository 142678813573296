import { FormattedPeople } from "@common/types/reports/people/people";
import { TableDetailRows } from "@common/types/table";
import { CategoryTable } from "@ui";
import { IntermediateLevel } from "./intermediate-level";
import { columns } from "./model";

interface Props {
  data: FormattedPeople[];
}

export const CategoryLevel = ({ data }: Props) => {
  const detailRows: TableDetailRows<FormattedPeople> = {
    component: IntermediateLevel,
    dataProperty: "subordinates"
  };

  return CategoryTable<FormattedPeople>({
    data,
    columns,
    detailRows,
    hideHeader: true
  });
};
