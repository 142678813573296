import { CommonWithId } from "@common/types/common";
import { makeAutoObservable } from "mobx";
import { ClassCommon } from "../types/common";
import { CountryProps, RegionProps } from "@common/types/reports/offices/office-detail";
import { AreaOfficesDataProps } from "@common/types/reports/offices/offices";

export class DestinationData implements ClassCommon {
  // TODO: Separate interfaces
  countries: CountryProps[] = [];
  regions: { [countryId: string]: RegionProps[] } = {};

  timezones: CommonWithId[] = [];
  offices: CommonWithId[] = [];
  areas: AreaOfficesDataProps[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setCountries(value: CountryProps[]) {
    this.countries = value;
  }

  getCountries() {
    return this.countries;
  }

  setCountryRegions(countryId: number, value: RegionProps[]) {
    this.regions[countryId] = value;
  }

  getCountryRegions(countryId: number) {
    return this.regions[countryId];
  }

  setTimezones(timezones: CommonWithId[]) {
    this.timezones = timezones;
  }

  getTimezones() {
    return this.timezones;
  }

  setOffices(offices: CommonWithId[]) {
    this.offices = offices;
  }

  getOffices() {
    return this.offices;
  }

  setAreas(areas: AreaOfficesDataProps[]) {
    this.areas = areas;
  }

  getAreas() {
    return this.areas;
  }

  reset(): void {
    this.offices = [];
    this.timezones = [];
  }
}

export const $destinationDataStore = new DestinationData();
