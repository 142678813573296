import { FormInputRadioGroup, FormInputSelect, FormInputText, FormWrapper, SelectOptionsProp, SelectUI } from "@ui";
import form from "@styles/modules/form.module.scss";
import grid from "@styles/modules/grid.module.scss";
import classNames from "classnames";
import { UseFormReturn } from "react-hook-form";
import { OrgUnits, OrgUnitsForm } from "@common/types/reports/org-units/org-units";
import { useMemo } from "react";
import { InputRadioOption } from "@common/types/form";
import { $orgUnitsStore } from "@common/api/store/org-units";
import { observer } from "mobx-react-lite";
import { defaultOrgUnitTypeValue } from "../model";
import { CSSObjectWithLabel } from "react-select";

interface Props {
  methods: UseFormReturn<OrgUnitsForm, object>;
  id?: number;
}

export const OrgUnitChangeForm = observer(({ methods, id }: Props) => {
  const orgUnitsList: OrgUnits[] = $orgUnitsStore.getOrgUnits();
  console.log(methods.getValues());

  const parentSections: SelectOptionsProp[] = useMemo(() => {
    return orgUnitsList.reduce(
      (previousArray: SelectOptionsProp[], current): SelectOptionsProp[] => {
        return [
          ...previousArray,
          ...(id === +current.id ? [] : [{ label: current.name, value: +current.id }]),
          ...(current.children?.length
            ? current.children.reduce((previousChildrenArray: SelectOptionsProp[], child) => {
                return [
                  ...previousChildrenArray,
                  ...(id === +child.id ? [] : [{ label: child.name, value: +child.id }])
                ];
              }, [])
            : [])
        ];
      },
      [
        {
          label: "Select Parent Org Unit...",
          value: 0
        }
      ]
    );
  }, [orgUnitsList]);

  const radioButtons: InputRadioOption[] = [
    {
      value: 1,
      label: "Business Unit"
    },
    {
      value: 2,
      label: "Division"
    },
    {
      value: 3,
      label: "Department"
    },
    {
      value: 4,
      label: "Team"
    },
    {
      value: 5,
      label: "Pod"
    }
  ];

  return (
    <FormWrapper>
      <div className={classNames(form.row, grid.list)}>
        <div className={classNames(grid.item, grid["item-6x"])}>
          <FormInputText
            name="name"
            label="Org Unit Name:"
            labelClass="text-current text-base"
            placeholder="Org Unit Name"
            register={methods?.register}
            error={methods?.formState.errors["name"]?.message}
          />
        </div>
        <div className={classNames(grid.item, grid["item-6x"])}>
          <FormInputSelect
            label="Parent Org Unit:"
            labelClass="text-current text-base"
            type="select"
            name="parent_id"
            getItemLabel={option => option.label}
            getItemValue={option => option.value}
            list={parentSections}
            methods={methods}
            styles={{
              control: (styles: CSSObjectWithLabel) => ({
                ...styles,
                background: "none !important",
                border: "none !important"
              }),
              valueContainer: (styles: CSSObjectWithLabel) => ({ ...styles, padding: "0 9px 0 !important" }),
              singleValue: (styles: CSSObjectWithLabel) => ({ ...styles, color: "#3478bc !important" })
            }}
          />
        </div>
      </div>
      <div className={form.row}>
        <div className="mb-1 text-current text-base">Org Unit Type:</div>
        <FormInputRadioGroup
          methods={methods}
          defaultValue={methods.control._defaultValues.type_id ?? defaultOrgUnitTypeValue}
          name="type_id"
          options={radioButtons}
        />
      </div>
    </FormWrapper>
  );
});
