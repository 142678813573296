import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getPhrases } from "@common/helpers/localization";
import { CarouselContent } from "../types";
import { CourseItemInterface } from "@common/types/reports/learn/learn";
import { getContinueLearning } from "@common/features/learn/model";
import { getReducedData } from "../helpers";
import { Course } from "@features/learn/types";
import { CarouselHeader } from "../carousel-header";
import { observer } from "mobx-react-lite";
import { GlobalStore } from "@common/api/store/global";
import { Carousel } from "@features/learn/carousels/carousel";

export const ContinueLearning = observer((props: CarouselContent) => {
  const { $learnStore } = useContext(GlobalStore);
  const loc_continue_learning_section = getPhrases("learn_page", "continue_learning_section");

  useEffect(() => {
    if (!$learnStore.continueLearning) {
      $learnStore.updateContinueLearning();
    }
  }, [$learnStore]);

  const editedData: Course[] = useMemo(() => {
    $learnStore.setEmployeeResourceAvailability("continueLearning", !!$learnStore.continueLearning?.length);
    return $learnStore.continueLearning?.length ? getReducedData($learnStore.continueLearning) : [];
  }, [$learnStore.continueLearning, $learnStore]);

  return editedData.length ? (
    <Carousel
      leftHeading={loc_continue_learning_section.continue_learning}
      leftSubHeading={"Learning items which you have selected as favorites"}
      CoursesData={editedData}
      refresh={$learnStore.updateContinueLearning}
      {...props}
    />
  ) : (
    <CarouselHeader {...props} />
  );
});
