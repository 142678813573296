import { AxiosResponse } from "axios";
import { HttpService } from "../http";
import { DataWrapper, ExtendedDataWrapper } from "../types/response/common";
import { AvatarUploadResultInterface } from "@common/types/reports/profile/profile";
import { $API_URL } from "@common/api/config";

export const getLearnData = async <T>(url: string): Promise<AxiosResponse<ExtendedDataWrapper<T[]>>> => {
  return HttpService.get<ExtendedDataWrapper<T[]>>(url);
};

export const getLearnDataObject = async <T>(url: string): Promise<AxiosResponse<DataWrapper<T>>> => {
  return HttpService.get<DataWrapper<T>>(url);
};

export const setLearnData = async <T, R = T>(url: string, values: T): Promise<AxiosResponse<R>> => {
  return HttpService.post<R>(url, values);
};

export const deleteLearnData = async <T>(url: string, values: T): Promise<AxiosResponse<T>> => {
  return HttpService.delete<T>(url, values);
};

export const getLearnDataArray = async <T>(url: string): Promise<AxiosResponse<DataWrapper<T[]>>> => {
  return HttpService.get<DataWrapper<T[]>>(url);
};

export const setLearnFileService = async (
  url: string,
  value: File | string | null
): Promise<DataWrapper<AvatarUploadResultInterface>> => {
  const formData = new FormData();
  formData.append("file", value || "");

  return await fetch($API_URL + url, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`
    }
  }).then(async res => {
    return res.json();
  });
};
