import { SkillsDataProps } from "@common/types/reports/skills/skills";
import { getSkills as getSkillFunction, saveSkill } from "@common/api/services/skills";
import { $userStore } from "@common/api/store/user";
import { Roles } from "@common/constants";
import { ScoreDataProps } from "@common/types/reports/skills/skills-employee";
import { ISkillForm, Skill } from "@common/types/skill-engine/skill";
import { SkillByRole } from "@common/types/managment/edit-person";
import { ISkillCategoryForm, SkillCategory } from "@common/types/skill-engine/skill-category";
import { $profileStore } from "@common/api/store/profile";
import { $commonStore } from "@common/api/store/common";

// TODO: add type for callback
// TODO: check member type and throw exception?

const getUrl = (role: number, memberId: number | undefined) => {
  switch (role) {
    case Roles.employee:
      return "/web/skills/" + memberId;
    default:
      return "/web/skills/staff";
  }
};

export const getSkills = async (callback: any) => {
  try {
    const role = $profileStore.getCurrentRoleId();
    const memberId = $userStore.getUserInfo()?.id;
    const response = await getSkillFunction<SkillsDataProps>(getUrl(role, memberId));
    callback(response.data.data);
  } catch (e) {
    console.error("Get skills error");
  }
};

export const getScores = async (callback: any) => {
  try {
    const response = await getSkillFunction<ScoreDataProps>("/web/skills/my-score");
    callback(response.data.data);
  } catch (e) {
    console.error("Get skills score error");
  }
};

export const getAllSkills = () => {
  return getSkillFunction<Skill>("/client/skills-engine/skills").then(response => response.data.data);
};

export const getSkillsByRole = async (id: number, callback: any) => {
  try {
    const response = await getSkillFunction<SkillByRole>(`/client/skills-engine/roles/${id}/skills`);

    callback(response.data.data);
  } catch (e) {
    console.error("Get skills by role error");
  }
};

export const getSkillCategories = (successCallback?: any) => {
  return getSkillFunction<SkillCategory>("/client/skills-engine/skill-categories")
    .then((response) => {
      const data = response.data.data;
      $commonStore.setSkillCategories(data);
      successCallback && successCallback(data);
      return data;
    });
};

export const createSkillCategory = (data: ISkillCategoryForm) => {
  return saveSkill<ISkillCategoryForm>("/client/skills-engine/skill-categories", data).then(
    response => response.data.data
  );
};

export const updateSkillCategory = (id: number, data: ISkillCategoryForm) => {
  return saveSkill<ISkillCategoryForm>(`/client/skills-engine/skill-categories/${id}`, data).then(
    response => response.data.data
  );
};

export const createSkill = (data: ISkillForm) => {
  return saveSkill<ISkillForm>("/client/skills-engine/skills", data).then(response => response.data.data);
};

export const updateSkill = (id: number, data: ISkillForm) => {
  return saveSkill<ISkillForm>(`/client/skills-engine/skills/${id}`, data).then(response => response.data.data);
};
