import { CourseItemInterface } from "@common/types/reports/learn/learn";
import { Course } from "../types";

export const getReducedData = (data: CourseItemInterface[]): Course[] => {
  return data.reduce((previousArray: Course[], current) => {
    return [
      ...previousArray,
      {
        id: current.id,
        title: current.title,
        category: current.provider?.name || "",
        // short_description: current.short_description,
        difficulty: current.expertise_level.name,
        rating: current.rating_average ?? 0,
        totalRatings: current.rating_count ?? 0,
        duration: current.duration, // in minutes
        heroImgSrc: current.image_url,
        ...(current.provider?.favicon_image_url && {
          authorImgSrc: current.provider?.favicon_image_url
        }),
        ...(current.provider?.name && {
          authorName: current.provider?.name
        }),
        ...(current.reviews_count && { reviews_count: current.reviews_count }),
        isFavourite: current.is_favourite,
        isCompleted: current.progress === 100, // BE issue,
        isFree: current.is_free,
        type: current.content_type.id,
        url: current.url,
        progress: current.progress
      }
    ];
  }, []);
};
