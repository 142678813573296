import {
  EmployeeAnalytics,
  EmployeeAnalyticsSummary,
  SupervisorAnalytics,
  TeamAnalytics,
  TeamAnalyticsSummary
} from "../../types/reports/analytics/analytics";
import { getCategories, getCategory } from "../../api/services/analytics";
import { $globalStore } from "@common/api/store/global";
import { getClientOrgUnits } from "@common/features/org-units/model";
import { OrgUnit } from "@common/types/org-unit/org-unit";
import { CommonWithId } from "@common/types/common";
import { TeamViewType } from "@features/reporting/report-overview/model";
import { Role } from "@common/types/roles/roles";

const { $analyticsStore } = $globalStore;

export const getTeamTypes = async () => {
  try {
    let orgUnits: OrgUnit[] | undefined;
    await getClientOrgUnits((data: OrgUnit[]) => (orgUnits = data));
    if (orgUnits) {
      $analyticsStore.setTeamTypes(orgUnits.filter(unit => unit.type.name === "Team"));
    }
    return orgUnits;
  } catch (e) {
    console.error("Get Teams analytics error");
  }
};

export const getTeams = async (id?: number, type?: TeamViewType) => {
  try {
    const response = await getCategories<TeamAnalytics | EmployeeAnalytics | SupervisorAnalytics>(
      id ? `web/analytics/units/${id}/${type}` : "web/analytics/units/4"
    );
    switch (type) {
      case "employees":
        $analyticsStore.setTeamsByEmployees(response.data.data as EmployeeAnalytics[], id as number);
        break;
      case "supervisors":
        $analyticsStore.setTeamsBySupervisors(response.data.data as SupervisorAnalytics[], id as number);
        break;
      default:
        $analyticsStore.setTeams(response.data.data as TeamAnalytics[]);
    }
    return response.data.data;
  } catch (e) {
    console.error("Get Team analytics error");
  }
};

export const getTeamsSummary = async () => {
  try {
    const response = await getCategory<TeamAnalyticsSummary>("web/analytics/units/summary/4");
    $analyticsStore.setTeamsSummary(response.data.data);
    return response.data.data;
  } catch (e) {
    console.error("Get Employee analytics error");
  }
};

export const getEmployees = async () => {
  try {
    const response = await getCategories<EmployeeAnalytics>("web/analytics/employees");
    $analyticsStore.setEmployees(response.data.data);
    return response.data.data;
  } catch (e) {
    console.error("Get Employee analytics error");
  }
};

export const getEmploymentTypes = async () => {
  try {
    const response = await getCategories<CommonWithId>("web/analytics/employment-types");
    $analyticsStore.setEmployeeTypes(response.data.data);
    return response.data.data;
  } catch (e) {
    console.error("Get Employee analytics error");
  }
};

export const getEmployeesByType = async (id: number) => {
  try {
    const response = await getCategories<EmployeeAnalytics>(`web/analytics/employees/by-employment-type/${id}`);
    $analyticsStore.setEmployeesByType(response.data.data, id);
    return response.data.data;
  } catch (e) {
    console.error("Get Employee types error");
  }
};

export const getEmployeesSummary = async () => {
  try {
    const response = await getCategory<EmployeeAnalyticsSummary>("web/analytics/employees/summary");
    $analyticsStore.setEmployeesSummary(response.data.data);
    return response.data.data;
  } catch (e) {
    console.error("Get Employee analytics error");
  }
};

export const getSupervisors = async () => {
  try {
    const response = await getCategories<SupervisorAnalytics>("web/analytics/supervisors");
    $analyticsStore.setSupervisors(response.data.data);
    return response.data.data;
  } catch (e) {
    console.error("Get Employee analytics error");
  }
};

export const getSupervisorEmployees = async (id: number) => {
  try {
    const response = await getCategories<EmployeeAnalytics>(`web/analytics/supervisors/${id}/employees`);
    $analyticsStore.setSupervisorEmployees(response.data.data, id);
    return response.data.data;
  } catch (e) {
    console.error("Get Supervisor employees analytics error");
  }
};

export const getRoles = async () => {
  try {
    const response = await getCategories<Role>("client/organization/roles");
    $analyticsStore.setRoles(response.data.data);
    return response.data.data;
  } catch (e) {
    console.error("Get Roles error");
  }
};
