import { ReportByManager, ReportByMyDirect, ReportByOrgUnit, UserInfoModal } from "@features/people";
import grid from "@styles/modules/grid.module.scss";
import { ButtonProps, ControlLine, SelectOptionsProp, SelectProps } from "@ui";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import styles from "./people.module.scss";
import { observer } from "mobx-react-lite";
import { $globalStore, GlobalStore } from "@common/api/store/global";
import { Roles } from "@common/constants";
import { ProfileInterface } from "@common/types/reports/profile/profile";

export const PeoplePage: React.FC = observer(() => {
  const { $profileStore } = useContext(GlobalStore);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const profile: ProfileInterface | null = $globalStore.$profileStore.getProfile();
  const isClientAdmin = $profileStore.profile?.scope.is_client_admin;
  enum reportsCategories {
    "manager" = 1,
    "orgUnit" = 2,
    "myDirectReports" = 3
  }

  const options: SelectOptionsProp[] = useMemo(
    () => [
      { value: reportsCategories.manager, label: "By Manager" },
      { value: reportsCategories.orgUnit, label: "By Organizational Unit" },
      ...($profileStore.currentRoleId === Roles.clientAdmin
        ? []
        : [{ value: reportsCategories.myDirectReports, label: "My Direct Reports" }])
    ],
    [$profileStore.currentRoleId]
  );
  const [selectedOption, setSelectedOption] = useState<SelectOptionsProp>(
    options[isClientAdmin ? reportsCategories.orgUnit - 1 : reportsCategories.myDirectReports - 1]
  );

  const filterProps: SelectProps = useMemo(() => {
    return {
      name: "people-category",
      options: options,
      defaultValue:
        options[
          $profileStore.currentRoleId === Roles.clientAdmin
            ? reportsCategories.orgUnit - 1
            : reportsCategories.myDirectReports - 1
        ],
      value: selectedOption,
      onChange: (newValue: any) => {
        setSelectedOption(newValue);
      }
    };
  }, [options, selectedOption]);

  useEffect(() => {
    setSelectedOption(
      options[
        $profileStore.currentRoleId === Roles.clientAdmin
          ? reportsCategories.orgUnit - 1
          : reportsCategories.myDirectReports - 1
      ]
    );
  }, [options]);

  const getTableComponent = useCallback((): React.ReactNode => {
    switch (selectedOption?.value) {
      case reportsCategories.manager:
        return <ReportByManager />;
      case reportsCategories.orgUnit:
        return <ReportByOrgUnit />;
      case reportsCategories.myDirectReports:
        return <ReportByMyDirect />;
    }
  }, [selectedOption]);

  const getControlLine = useCallback((): React.ReactNode => {
    return <ControlLine {...{ filterProps }} buttonsProps={buttons} />;
  }, [filterProps]);

  const buttons: ButtonProps[] = [
    {
      theme: "blue",
      onClick: () => setIsOpen(true),
      children: (
        <>
          Add Person <span className={styles.button} />
        </>
      )
    }
  ];

  return (
    <div className={grid.root}>
      <div className={grid.container}>
        {getControlLine()}
        {getTableComponent()}
        {isOpen && <UserInfoModal closeModal={() => setIsOpen(false)} isOpen={isOpen} />}
      </div>
    </div>
  );
});
