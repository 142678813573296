import { createContext } from "react";
import { $destinationDataStore, DestinationData } from "./destination";
import { $userStore, User } from "./user";
import { $profileStore, Profile } from "./profile";
import { $assessmentsStore, Assessments } from "./assessments";
import { $analyticsStore, Analytics } from "./analytics";
import { WorkTypes, $workTypesStore } from "./work-types";
import { $employmentTypesStore, EmploymentTypes } from "./employment-types";
import { $rolesStore, Roles } from "@common/api/store/roles";
import { $accountabilitiesStore, Accountabilities } from "./accountabilities";
import { $commonStore, Common } from "@common/api/store/common";
import { $learnStore, Learn } from "@common/api/store/learn";

interface State {
  $userStore: User;
  $destinationDataStore: DestinationData;
  $profileStore: Profile;
  $assessmentsStore: Assessments;
  $analyticsStore: Analytics;
  $employmentTypesStore: EmploymentTypes;
  $workTypesStore: WorkTypes;
  $rolesStore: Roles;
  $accountabilitiesStore: Accountabilities;
  $commonStore: Common;
  $learnStore: Learn;
}

export const $globalStore = {
  $userStore,
  $destinationDataStore,
  $profileStore,
  $assessmentsStore,
  $analyticsStore,
  $employmentTypesStore,
  $workTypesStore,
  $rolesStore,
  $accountabilitiesStore,
  $commonStore,
  $learnStore
};

export const $resetGlobalStore = () => {
  $userStore.reset();
  $destinationDataStore.reset();
  $profileStore.reset();
  $assessmentsStore.reset();
  $analyticsStore.reset();
  $employmentTypesStore.reset();
  $workTypesStore.reset();
  $rolesStore.reset();
  $accountabilitiesStore.reset();
  $commonStore.reset();
  $learnStore.reset();
};

export const GlobalStore = createContext<State>({ ...$globalStore });
