import styles from "./progressive-modal-wrapper.module.scss";
import { ProgressBar } from "@ui";

interface Props {
  progress: number;
}

export const ProgressiveModalWrapper: React.FC<Props> = ({ progress, children }) => {
  return (
    <>
      <ProgressBar progress={progress} modClass={styles.progress} />
      {!!children && children}
    </>
  );
};
