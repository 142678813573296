import { SelectOptionsProp } from "@ui";
import { getPhrases } from "@common/helpers/localization";
import * as yup from "yup";
import {
  AccountabilitiesScoreEnums,
  AccountabilitiesSessionAssessment
} from "@common/types/reports/accountabilities/accountabilities";

export const getItemWeight = (value: number): string => {
  return `${value < 10 && value > 0 ? `0${value}` : value}`;
};

export const getLabel = (value: number): SelectOptionsProp | undefined => {
  switch (value) {
    case AccountabilitiesScoreEnums.A:
      return { label: "A", value: 0 };
    case AccountabilitiesScoreEnums.B:
      return { label: "B", value: 1 };
    case AccountabilitiesScoreEnums.C:
      return { label: "C", value: 2 };
    default:
      return undefined;
  }
};

export const getValue = (value: number): string => {
  switch (value) {
    case AccountabilitiesScoreEnums.A:
      return "A";
    case AccountabilitiesScoreEnums.B:
      return "B";
    case AccountabilitiesScoreEnums.C:
      return "C";
    default:
      return "";
  }
};
