import { GlobalStore } from "@common/api/store/global";
import { Roles } from "@common/constants";
import { addCourseToFavorites, removeCourseFromFavorites } from "@common/features/learn/model";
import { getPhrases } from "@common/helpers/localization";
import { getDurationHoursOrMinutes } from "@common/helpers/time-helpers";
import { Course } from "@features/learn/types";
import { Button, RatingWithNumber, Svg } from "@ui";
import { observer } from "mobx-react-lite";
import React, { RefAttributes, useContext, useState } from "react";
import "react-circular-progressbar/dist/styles.css";
import { Link } from "react-router-dom";
import { CircularProgressWithDuration } from "../../../../../ui/circular-progress-with-duration/circular-progress-with-duration";
import styles from "./course-card.module.scss";

interface Props extends Course {
  refresh?: () => void;
  isShowFavorite?: boolean;
  isLearningPath?: boolean;
  onEdit?: (id: number) => void;
}

export const CourseCard: React.FC<Props> = observer(({ ...props }) => {
  const {
    id,
    authorName,
    authorImgSrc,
    rating,
    totalRatings,
    title,
    category,
    heroImgSrc,
    difficulty,
    duration,
    isFavourite,
    isCompleted,
    isFree,
    type,
    refresh,
    progress = 0,
    isShowFavorite = true,
    isLearningPath = false,
    onEdit
  } = props;

  const { $learnStore, $profileStore } = useContext(GlobalStore);

  const [isFavouriteCourse, setIsFavouriteCourse] = useState<boolean>(isFavourite ?? false);

  const loc = getPhrases("learn_page", "common");

  const CourseNavigate = ({ children }: { children: RefAttributes<HTMLAnchorElement> }) =>
    !isLearningPath ? (
      <Link to={`/learn/course-details/${id}`}>{children}</Link>
    ) : (
      <React.Fragment>{children}</React.Fragment>
    );

  const toggleIsFavourite = async () => {
    const changeFavoriteState = () => {
      setIsFavouriteCourse(prev => !prev);
    };

    !isFavouriteCourse
      ? await addCourseToFavorites({ resource_id: id })
          .then(data => {
            changeFavoriteState();
            $learnStore.increaseFavoritesCount();
          })
          .catch(errors => console.error("Add course to favorites error"))
      : await removeCourseFromFavorites({ resource_id: id })
          .then(data => {
            changeFavoriteState();
            $learnStore.decreaseFavoritesCount();
          })
          .catch(errors => console.log("Remove course from favorites error"));
  };

  const CourseDetails = (): JSX.Element => {
    return (
      <div className={styles.courseCard}>
        <div className={styles.mainImageContainer}>
          {heroImgSrc && <img className={styles.mainImage} src={heroImgSrc} alt={title} />}
          {!!isShowFavorite && (
            <div
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                toggleIsFavourite();
              }}
              className={styles.heartIcon}
            >
              <Svg name={isFavouriteCourse ? "heart-red" : "heart-gray"} />
            </div>
          )}
          <div className={styles.progress}>
            <div className={styles.progressFiller} style={{ width: `${progress}%` }} />
          </div>
        </div>

        {/*     COURSE DETAILS  */}
        <div className={styles.videoDesc}>
          <CircularProgressWithDuration duration={duration ? getDurationHoursOrMinutes(duration) : ""} progress={50} />

          {/*   VIDEO DIFFICULTY  */}
          <div className={styles.videoDifficulty}>{difficulty}</div>
        </div>

        <div className={styles.courseDetails}>
          <CourseNavigate>
            <div>
              <div className={styles.logoAndTitle}>
                {authorImgSrc && (
                  <div>
                    <img className={styles.authorImg} src={authorImgSrc} alt="author" />
                  </div>
                )}
                {/*     tile    */}
                <h3 className={styles.title}>{category}</h3>
              </div>
              {/*     Course Title        */}
              <div className={styles.courseTitle}>{title}</div>
            </div>
          </CourseNavigate>
          <div className={styles.cardFooter}>
            {/*   Rating  */}
            {(totalRatings || totalRatings === 0) && (rating || rating === 0) && (
              <div className={styles.rating}>
                <RatingWithNumber totalRatings={totalRatings} rating={rating} />
              </div>
            )}

            <div className={styles.freeBtnContainer}>
              <Button
                type={"button"}
                appearance={"framed"}
                size={"thin"}
                theme={isFree ? "orange" : "red"}
                onClick={() => {}}
                className={styles.freeBtn}
              >
                {isFree ? loc.free : "Paid"}
              </Button>
              {$profileStore.currentRoleId === Roles.clientAdmin && (
                <Button
                  type={"button"}
                  appearance={"framed"}
                  size={"thin"}
                  theme={"blue"}
                  onClick={() => onEdit?.(id)}
                  className={styles.freeBtn}
                  title="Edit"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return <CourseDetails />;
});
