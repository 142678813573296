import React, { FC, useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import classNames from "classnames";
import { useDropzone } from "react-dropzone";
import { useForm } from "@common/hooks/form";
import { getPhrases } from "@common/helpers/localization";
import { ILearnForm } from "@common/types/forms/learn";
import { Button, FormInputNumberSpinner, FormInputSelect, FormWrapper, Switch } from "@ui";
import { ILearnFormTabInitData } from "../index";
import { FormError } from "../form-error";
import { InvalidQuizFile } from "@features/learn/learn-form/invalid-quiz-file";
import { QuizFileDeleteConfirm } from "@features/learn/learn-form/quiz-file-delete-confirm";
import { LearningResourceDeleteConfirm } from "@features/learn/learn-form/learning-resource-delete-confirm";

export interface IResourceQuizTabProps {
  formData: Partial<ILearnForm>;
  onInit?: (data: ILearnFormTabInitData) => void;
  onBack(): void;
  onNext(data: Partial<ILearnForm>): void;
  onSubmit(data: Partial<ILearnForm>): Partial<ILearnForm>;
  onClose(): void;
  editable?: boolean;
}

interface IResourceQuizForm {
  quizFileFormat: string;
  quizFile: string;
  quizRetryUnlimited: boolean;
  quizRetryLimit: number;
  quizTimeUnlimited: boolean;
  quizTimeLimit: number;
  quizShouldComplete: boolean;
  quizMinPassingScore: number;
}

const loc = getPhrases("common", "forms").validation;

const quizFormSchema: yup.SchemaOf<IResourceQuizForm> = yup.object().shape({
  quizFileFormat: yup.string().required(loc.required),
  quizFile: yup.string().required(loc.required),
  quizRetryUnlimited: yup.boolean().required(loc.required),
  quizRetryLimit: yup.number().required(loc.required).min(1),
  quizTimeUnlimited: yup.boolean().required(loc.required),
  quizTimeLimit: yup.number().required(loc.required).min(1),
  quizShouldComplete: yup.boolean().required(loc.required),
  quizMinPassingScore: yup.number().required(loc.required).min(1).max(100)
});

const quizFileFormatOptions = [
  "Aiken format"
  //'Blackboard',
  //'Embedded answers (Cloze)',
  //'Examview', 'Gift format',
  //'Missing word format',
  //'Moodle XML format',
  //'WebCT format'
];

export const LearnQuizTab: FC<IResourceQuizTabProps> = ({
  formData,
  onInit,
  onBack,
  onNext,
  onSubmit,
  onClose,
  editable
}) => {
  const methods = useForm<IResourceQuizForm>({
    schema: quizFormSchema,
    defaultValues: {
      quizFileFormat: formData.id ? (formData.quizFileFormat ? formData.quizFileFormat : quizFileFormatOptions[0]) : "",
      quizFile: formData.quizFile
        ? typeof formData.quizFile === "string"
          ? formData.quizFile
          : formData.quizFile.name
        : "",
      quizRetryUnlimited: formData.quizRetryUnlimited === undefined ? true : formData.quizRetryUnlimited,
      quizRetryLimit: formData.quizRetryUnlimited ? 1 : formData.quizRetryLimit || 1,
      quizTimeUnlimited: formData.quizTimeUnlimited === undefined ? true : formData.quizTimeUnlimited,
      quizTimeLimit: formData.quizTimeUnlimited ? 1 : formData.quizTimeLimit || 1,
      quizShouldComplete: formData.quizShouldComplete === undefined ? true : formData.quizShouldComplete,
      quizMinPassingScore: formData.quizShouldComplete ? 100 : formData.quizMinPassingScore || 100
    }
  });
  const data = methods.getValues();
  const [quizFile, setQuizFile] = useState<File | null>(null);
  const [invalidQuizFile, setInvalidQuizFile] = useState<string>();
  const [deletingQuizFile, setDeletingQuizFile] = useState<string>();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showFormError, setShowFormError] = useState(false);
  const [timestamp, setTimestamp] = useState<number>();

  const onDropFile = useCallback(acceptedFiles => {
    if (!acceptedFiles.length) {
      return;
    }

    const file = acceptedFiles[0];
    if (file.type !== "text/plain") {
      setInvalidQuizFile(file.name);
      return;
    }
    setQuizFile(file);
    onFieldChange("quizFile", file.name);
  }, []);

  const {
    getRootProps,
    getInputProps,
    open: openDropzone
  } = useDropzone({
    noClick: true,
    maxSize: 301 * 1024 * 1024,
    onDrop: onDropFile
  });

  useEffect(() => {
    if (onInit) {
      if (showDeleteConfirm || invalidQuizFile || deletingQuizFile || showFormError) {
        onInit({ buttons: undefined });
      } else {
        onInit({
          buttons: {
            leftSide: [
              {
                title: (
                  <span>
                    Cancel
                    <br />
                    And Close
                  </span>
                ),
                theme: "secondary-gradient",
                className: "!w-30 !h-14 !text-sm !text-left !font-semibold",
                onClick: onClose
              },
              ...(formData.id
                ? [
                    {
                      title: (
                        <span className="w-full px-4">
                          Delete this
                          <br />
                          Learning Resource
                        </span>
                      ),
                      theme: "danger" as "danger",
                      className: "!w-44 !h-14 !text-xs !text-left !font-semibold !ml-4",
                      onClick: () => setShowDeleteConfirm(true)
                    }
                  ]
                : [])
            ],
            rightSide: [
              {
                title: "Prev",
                theme: "warning",
                shape: "left-arrow",
                className: "!w-20 !text-sm !font-semibold",
                onClick() {
                  goBack();
                }
              },
              {
                title: editable ? "Update and Finish" : "Save & Finish",
                theme: "warning",
                className: "!w-32 !text-sm !font-semibold",
                onClick() {
                  methods.handleSubmit(handleSuccess, handleError)();
                }
              }
            ]
          }
        });
      }
    }
  }, [onInit, showDeleteConfirm, invalidQuizFile, deletingQuizFile, showFormError, editable, quizFile]);

  const onDelete = () => {
    onClose();
  };

  const onFieldChange = (field: keyof IResourceQuizForm, value: any) => {
    methods.setValue(field, value);
    methods.trigger(field);
    setTimestamp(new Date().getTime());
  };

  const onDeleteQuizFile = () => {
    setQuizFile(null);
    setDeletingQuizFile(undefined);
    onFieldChange("quizFile", null);
  };

  const handleSuccess = (values: IResourceQuizForm) => {
    const form: Partial<ILearnForm> = values;
    if (quizFile) {
      form.quizFile = quizFile;
    }
    const formData = onSubmit(form);
    onNext(formData);
  };

  const handleError = () => {
    // setShowFormError(true);
  };

  const goBack = () => {
    const form: Partial<ILearnForm> = methods.getValues();
    if (quizFile) {
      form.quizFile = quizFile;
    }
    onSubmit(form);
    onBack();
  };

  return (
    <FormWrapper className="text-black px-5" onSubmit={methods.handleSubmit(handleSuccess, handleError)}>
      <div>
        <FormInputSelect
          className="!text-sm !text-black !border-none !p-0"
          label="Select the questions file format"
          labelClass="!text-sm !mb-1"
          list={quizFileFormatOptions}
          name="quizFileFormat"
          placeholder="Please select"
          methods={methods}
          error={methods?.formState.errors.quizFileFormat?.message}
          onChange={({ value }) => onFieldChange("quizFileFormat", value)}
        />

        <div className="mt-4">
          <div className={classNames("text-sm mb-1", methods?.formState.errors.quizFile ? "text-danger" : "text-gray")}>
            Upload a quiz
          </div>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className="flex items-center">
              <Button
                className="!w-32 !h-7 !text-sm !font-semibold"
                theme="blue"
                appearance="solid"
                disabled={!data.quizFileFormat}
                onClick={openDropzone}
              >
                Choose a file
              </Button>
              <span className="text-sm text-gray-a8 ml-4">Maximum size: 301MB</span>
            </div>
          </div>
          {data.quizFile && (
            <div className="inline-flex items-center bg-gray-f5 text-gray rounded-md px-4 py-2 mt-3">
              <i className="far fa-file" />
              <span className="text-black ml-2 mr-1">{data.quizFile}</span>
              is uploaded
              <i
                className="fa fa-times text-warning cursor-pointer ml-4"
                onClick={() => setDeletingQuizFile(data.quizFile)}
              />
            </div>
          )}
          {methods?.formState.errors.quizFile && <div className="text-danger text-xs mt-1">Please select a file</div>}
        </div>

        <div className="mt-4">
          <div className="text-gray text-sm">Restrict retries</div>
          <div className="flex items-center">
            <span className="text-sm text-gray-a8">The number of Quiz Retries is</span>
            <span
              className={classNames(
                "text-sm font-semibold ml-1",
                data.quizRetryUnlimited ? "text-primary" : "text-gray"
              )}
            >
              Unlimited
            </span>
            <Switch
              className="ml-2"
              theme="primary"
              value={data.quizRetryUnlimited}
              onChange={value => onFieldChange("quizRetryUnlimited", value)}
            />
            <FormInputNumberSpinner
              name="quizRetryLimit"
              className="!text-sm text-black"
              wrapperClass="ml-2"
              theme="primary"
              value={data.quizRetryLimit}
              min={1}
              max={100}
              disabled={data.quizRetryUnlimited}
              showErrorText={false}
              register={methods?.register}
              error={methods?.formState.errors.quizRetryLimit?.message}
              onChange={value => onFieldChange("quizRetryLimit", value)}
            />
          </div>
        </div>

        <div className="mt-4">
          <div className="text-gray text-sm">Allowed time to complete the quiz in minutes</div>
          <div className="flex items-center">
            <span className="text-sm text-gray-a8">Time is</span>
            <span
              className={classNames(
                "text-sm font-semibold ml-1",
                data.quizTimeUnlimited ? "text-primary" : "text-gray"
              )}
            >
              Unlimited
            </span>
            <Switch
              className="ml-2"
              theme="primary"
              value={data.quizTimeUnlimited}
              onChange={value => onFieldChange("quizTimeUnlimited", value)}
            />
            <FormInputNumberSpinner
              name="quizTimeLimit"
              className="!text-sm text-black"
              wrapperClass="ml-2"
              theme="primary"
              value={data.quizTimeLimit}
              min={1}
              max={100}
              disabled={data.quizTimeUnlimited}
              showErrorText={false}
              register={methods?.register}
              error={methods?.formState.errors.quizTimeLimit?.message}
              onChange={value => onFieldChange("quizTimeLimit", value)}
            />
          </div>
        </div>

        <div className="mt-4">
          <div className="text-gray text-sm">Minimum passing score percentage</div>
          <div className="flex items-center">
            <span className="text-sm text-gray-a8">The passing score is</span>
            <span
              className={classNames(
                "text-sm font-semibold ml-1",
                data.quizShouldComplete ? "text-primary" : "text-gray"
              )}
            >
              100%
            </span>
            <Switch
              className="ml-2"
              theme="primary"
              value={data.quizShouldComplete}
              onChange={value => onFieldChange("quizShouldComplete", value)}
            />
            <FormInputNumberSpinner
              name="quizMinPassingScore"
              className="!text-sm text-black"
              wrapperClass="ml-2"
              theme="primary"
              value={data.quizMinPassingScore}
              min={1}
              max={100}
              disabled={data.quizShouldComplete}
              showErrorText={false}
              register={methods?.register}
              error={methods?.formState.errors.quizMinPassingScore?.message}
              onChange={value => onFieldChange("quizMinPassingScore", value)}
            />
          </div>
        </div>
      </div>

      {showDeleteConfirm && (
        <LearningResourceDeleteConfirm
          className="w-full h-full absolute top-0 left-0 z-100"
          onDelete={onDelete}
          onClose={() => setShowDeleteConfirm(false)}
        />
      )}

      {invalidQuizFile && (
        <InvalidQuizFile
          className="w-full h-full absolute top-0 left-0 z-100"
          fileName={invalidQuizFile}
          quizFileFormat={data.quizFileFormat}
          onClose={() => setInvalidQuizFile(undefined)}
        />
      )}

      {deletingQuizFile && (
        <QuizFileDeleteConfirm
          className="w-full h-full absolute top-0 left-0 z-100"
          fileName={deletingQuizFile}
          onDelete={() => onDeleteQuizFile()}
          onClose={() => setDeletingQuizFile(undefined)}
        />
      )}

      {showFormError && (
        <FormError className="w-full h-full absolute top-0 left-0 z-100" onClose={() => setShowFormError(false)} />
      )}
    </FormWrapper>
  );
};
