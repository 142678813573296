import { useContext, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";

import { GlobalStore } from "@common/api/store/global";
import { getPhrases } from "@common/helpers/localization";
import { ReportOverview, Sidebar, Welcome } from "@features/reporting";
import { menuData } from "./model";
import styles from "./reporting.module.scss";
import {
  getEmployees,
  getEmployeesSummary,
  getEmploymentTypes,
  getTeamTypes,
  getTeamsSummary,
  getTeams,
  getRoles,
  getSupervisors
} from "@common/features/analytics/model";
import { MenuProps } from "@common/types/reports/common";

export const Reporting: React.FC = observer(() => {
  const { $analyticsStore, $profileStore } = useContext(GlobalStore);
  const [isLoading, setIsLoading] = useState(false);
  const [route, setRoute] = useState("reportType>byTeam");
  const routeParams = useMemo(() => route.split(">"), [route]);

  const sidebarMenu = useMemo(
    () =>
      menuData.map(group => {
        const loc = getPhrases("reporting_page", group.title);
        return {
          key: group.key,
          title: loc.title,
          subMenus: group.subMenus?.map(menu => {
            let subMenus: MenuProps[] = menu.subMenus || [];

            if (menu.key === "byTeam") {
              subMenus = $analyticsStore.teamTypes.map(team => ({
                key: team.id,
                title: team.name,
                subMenus: []
              }));
            } else if (menu.key === "byEmployee") {
              subMenus = $analyticsStore.employeeTypes.map(employee => ({
                key: employee.id,
                title: employee.name,
                subMenus: []
              }));
            } else if (menu.key === "byRole") {
              subMenus = $analyticsStore.roles.map(role => ({
                key: role.id,
                title: role.name,
                subMenus: []
              }));
            } else if (menu.key === "bySupervisor") {
              subMenus = $analyticsStore.supervisors.map(role => ({
                key: role.id,
                title: role.full_name,
                subMenus: []
              }));
            }

            return {
              key: menu.key,
              title: loc[menu.title] || menu.title,
              subMenus
            };
          })
        };
      }),
    [
      $analyticsStore.employees,
      $analyticsStore.employeeTypes,
      $analyticsStore.roles,
      $analyticsStore.supervisors,
      $analyticsStore.teamTypes
    ]
  );

  useEffect(() => {
    setIsLoading(true);
    $analyticsStore.reset();
    Promise.all([
      getTeamTypes(),
      getTeams(),
      getTeamsSummary(),
      getEmploymentTypes(),
      getEmployees(),
      getEmployeesSummary(),
      getSupervisors(),
      getRoles()
    ]).finally(() => setIsLoading(false));
  }, [$profileStore.getCurrentRoleId()]);

  return (
    <div className={styles.wrapper}>
      <Sidebar groups={sidebarMenu} route={route} onChange={value => setRoute(value)} />
      <div className={styles["page-container"]}>
        {(!route || routeParams.length === 1) && <Welcome />}
        {routeParams.length > 1 && routeParams[1] && (
          <ReportOverview keyword={routeParams[1]} route={route} onNavigate={setRoute} />
        )}
      </div>
    </div>
  );
});
