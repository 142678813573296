import React, { useContext, useState } from "react";
import classNames from "classnames";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { getPhrases } from "@common/helpers/localization";
import { RoleReport } from "@common/types/reports/analytics/analytics";
import { ReportFilter } from "@features/reporting/report-filter";
import grid from "@styles/modules/grid.module.scss";
import tabs from "@styles/modules/tabs.module.scss";
import { FirstLevelTable, Titles } from "@ui";
import { roleKpiList, roleOverviewColumns, teamTabItems } from "../model";
import styles from "../report-overview.module.scss";
import { GlobalStore } from "@common/api/store/global";

interface RoleOverviewProps {
  route: string;
}

export const RoleOverview: React.FC<RoleOverviewProps> = () => {
  const { $analyticsStore } = useContext(GlobalStore);
  const [loc] = useState(getPhrases("reporting_page", "overview"));

  return (
    <div className={classNames(grid.container, styles["page-container"])}>
      <Titles level="level_3" modClass={styles.title}>
        {loc.byRoleTitle} {loc.reporting}
      </Titles>
      <p className={styles.description}>{loc.description}</p>
      <div className={styles["kpi-box-wrapper"]}>
        {roleKpiList.map((item, index) => (
          <React.Fragment key={item.title}>
            {index > 0 && <div className={styles["kpi-box-divider"]} />}
            <div className={styles["kpi-box"]}>
              <Titles level="level_3" modClass={classNames(styles["kpi-box-title"])}>
                {item.value} {item.affix}
              </Titles>
              <p className={classNames(styles["kpi-box-description"], styles.team)}>{item.title}</p>
            </div>
          </React.Fragment>
        ))}
      </div>
      <div className={styles["report-table-wrapper"]}>
        {FirstLevelTable<RoleReport>({
          data: $analyticsStore.roles as unknown as RoleReport[],
          columns: roleOverviewColumns,
          modStyles: styles
        })}
      </div>
      {/* TODO: need to uncomment after integration
      <Tabs
        className={tabs.tabs}
        selectedTabClassName={tabs["tab-selected"]}
      >
        <TabList controls={false} className={classNames(tabs.list, styles["overview-tab-wrapper"])}>
          {teamTabItems.map((teamName) => (
            <Tab
              key={teamName.key}
              className={classNames(tabs.tab, styles["overview-tab-item"])}
            >
              {teamName.title}
            </Tab>
          ))}
        </TabList>
        <ReportFilter modClass={styles["report-filter"]} options={[]} />
        {teamTabItems.map((teamName) => (
          <TabPanel
            key={teamName.key}
            className={tabs.panel}
          />
        ))}
      </Tabs>
      */}
    </div>
  );
};
