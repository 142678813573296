import { GlobalStore } from "@common/api/store/global";
import { BannerType } from "@common/api/types/common";
import { Roles } from "@common/constants";
import { getCompanySetting, getProfile, setCompanyLogo, setCompanySetting } from "@common/features/profile/model";
import { getCroppedImg, readFile } from "@common/helpers/canvas-helpers";
import { useForm } from "@common/hooks/form";
import { CompanyFormInterface } from "@common/types/reports/profile/profile";
import { CompanyFormSchema } from "@features/settings/blocks/company/company-form/model";
import { Button, ComplexModal, FormInputSelect, FormInputText, FormWrapper, Svg } from "@ui";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { ChangeEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { FieldErrors } from "react-hook-form";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { CSSObjectWithLabel } from "react-select";
import styles from "../company.module.scss";

export const MonthsOptions = [
  {
    label: "January",
    value: 1
  },
  {
    label: "February",
    value: 2
  },
  {
    label: "March",
    value: 3
  },
  {
    label: "April",
    value: 4
  },
  {
    label: "May",
    value: 5
  },
  {
    label: "June",
    value: 6
  },
  {
    label: "July",
    value: 7
  },
  {
    label: "August",
    value: 8
  },
  {
    label: "September",
    value: 9
  },
  {
    label: "October",
    value: 10
  },
  {
    label: "November",
    value: 11
  },
  {
    label: "December",
    value: 12
  }
];

export const CompanyForm = observer(() => {
  const { $commonStore, $profileStore, $userStore } = useContext(GlobalStore);
  const [imageSrc, setImageSrc] = useState<any>(null);
  const [crop, setCrop] = useState<Crop | undefined>();
  const companySetting = $profileStore.getCompanySetting();

  const [croppedImage, setCroppedImage] = useState("");
  const [isEditLogo, setIsEditLogo] = useState(false);
  const fileInput = useRef<HTMLInputElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const defaultValues: CompanyFormInterface = {
    name: companySetting?.name ?? "",
    portal_name: companySetting?.portal_name ?? ""
  };
  const methods = useForm<CompanyFormInterface>({
    schema: CompanyFormSchema,
    defaultValues
  });

  const isEditable = useMemo(() => $userStore.getUserRoleId() === Roles.clientAdmin, [$userStore.userInfo]);

  useEffect(() => {
    console.log(companySetting);
    if (companySetting) {
      methods.reset({
        name: companySetting?.name ?? "",
        portal_name: companySetting?.portal_name ?? "",
        calendar_year_end: companySetting?.calendar_year_end
      });
    } else {
      getCompanySetting();
    }
  }, [$profileStore.profile]);

  const showCroppedImage = useCallback(async () => {
    try {
      const image = await getCroppedImg(imageSrc, crop, 0);
      if (image) {
        const { url: croppedImage, file } = image;
        setCroppedImage(croppedImage);
        const imageFile = new File([file], "newPhoto.jpg", {
          type: "image/jpg"
        });
        if (companySetting?.id) {
          await setCompanyLogo(+companySetting.id, imageFile)
            .then(() => {
              $commonStore.setBanner(BannerType.success, "Your changes have been saved successfully.");
            })
            .catch(e => {
              $commonStore.setBanner(
                BannerType.error,
                "Your changes have not been saved. Please try again later or contact our support team."
              );
            });
          getProfile();
        }
      }
    } catch (e) {
      console.error(e);
    }
    setIsEditLogo(false);
  }, [companySetting, imageSrc, crop]);

  const onClickSelectFile = () => {
    fileInput.current?.click();
  };

  const onFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      setImageSrc(imageDataUrl);

      await new Promise(res => setTimeout(() => res(""), 500));
      if (imageRef.current) {
        const { width, height } = imageRef.current;
        const minLength = Math.min(width, height);
        if (minLength > 300) {
          setCrop({
            width: 300 / (width / 100),
            x: (width - 300) / (width / 100) / 2,
            height: 300 / (height / 100),
            y: (height - 300) / (height / 100) / 2,
            unit: "%"
          });
        } else {
          setCrop({
            width: minLength / (width / 100),
            x: (width - minLength) / (width / 100) / 2,
            height: minLength / (height / 100),
            y: (height - minLength) / (height / 100) / 2,
            unit: "%"
          });
        }
      }
    }
  };

  const onCloseLogoEdit = useCallback(() => {
    setIsEditLogo(false);
    setCroppedImage("");
  }, []);

  const handleSuccess = (values: CompanyFormInterface) => {
    setCompanySetting(values)
      .then(() => {
        getCompanySetting();
      })
      .then(() => {
        $commonStore.setBanner(BannerType.success, "Your changes have been saved successfully.");
      })
      .catch(e => {
        $commonStore.setBanner(
          BannerType.error,
          "Your changes have not been saved. Please try again later or contact our support team."
        );
      });
  };
  const handleError = (errors: FieldErrors<any>) => {
    console.error(errors);
  };

  return (
    <>
      <FormWrapper className="flex" onSubmit={methods.handleSubmit(handleSuccess, handleError)}>
        <div className="mr-5">
          <div className={classNames(styles["logo-block"])}>
            {isEditable && (
              <span className={styles["logo-edit-btn"]} onClick={() => setIsEditLogo(true)}>
                <Svg name="edit" />
              </span>
            )}
            <span className={styles.backdrop} />
            <div
              className={styles.logo}
              style={{
                backgroundImage: `url(${croppedImage ? croppedImage : companySetting?.logo_url})`
              }}
            />
          </div>
        </div>
        <div className="col-span-2 grid grid-cols-1 gap-y-6">
          <FormInputText
            readOnly={!isEditable}
            label="Company Name"
            labelClass="text-white"
            className="col-span-1"
            register={methods.register}
            placeholder="Company name"
            name="name"
            theme="orange"
            error={methods.formState.errors["name"]?.message}
          />
          <FormInputText
            readOnly={!isEditable}
            label="Portal Name"
            labelClass="text-white"
            className="col-span-1"
            register={methods.register}
            placeholder="Portal name"
            name="portal_name"
            theme="orange"
            error={methods.formState.errors["portal_name"]?.message}
          />
          <FormInputSelect
            readOnly={!isEditable}
            label="Company Calendar Year End"
            labelClass="text-white"
            type="select"
            className="col-span-1"
            name="calendar_year_end"
            theme="orange"
            getItemLabel={option => option.label}
            getItemValue={option => option.value}
            list={MonthsOptions}
            methods={methods}
            styles={{
              control: (styles: CSSObjectWithLabel) => ({
                ...styles,
                background: "none !important",
                border: "none !important"
              }),
              valueContainer: (styles: CSSObjectWithLabel) => ({
                ...styles,
                padding: "0 9px 0 !important"
              }),
              singleValue: (styles: CSSObjectWithLabel) => ({
                ...styles,
                color: "white !important"
              }),
              placeholder: (styles: CSSObjectWithLabel) => ({
                ...styles,
                color: "#3478bc !important"
              })
            }}
            error={methods.formState.errors["calendar_year_end"]?.message}
          />
        </div>
        {isEditable && (
          <Button
            title="Save Changes"
            size="thin"
            type="submit"
            theme="dark-blue"
            className={classNames(styles["modal-button"], "mt-auto ml-auto")}
          />
        )}
      </FormWrapper>
      <ComplexModal
        isOpen={isEditLogo}
        closeModal={onCloseLogoEdit}
        bodyClass="py-0"
        shouldCloseOnOverlayClick={false}
        headerTitle="Edit & Upload photo"
        headerClass="pb-1"
        buttons={{
          leftSide: {
            title: "Open File",
            theme: "blue",
            className: "!w-30 !h-10 !text-sm !text-left !font-semibold",
            onClick: onClickSelectFile
          },
          rightSide: [
            {
              title: <span>Save & Upload</span>,
              theme: "orange",
              className: "!w-30 !h-10 !text-sm !text-left !font-semibold",
              disabled: !imageSrc,
              onClick: showCroppedImage
            }
          ]
        }}
      >
        <input ref={fileInput} type="file" className="hidden" onChange={onFileChange} />
        <ReactCrop
          className={styles["logo-crop-container"]}
          renderSelectionAddon={() => <div className={styles["logo-crop-selection"]} />}
          aspect={1}
          crop={crop}
          keepSelection
          circularCrop
          disabled={!imageSrc}
          onChange={(c, pc) => setCrop(pc)}
          onComplete={(c, pc) => setCrop(pc)}
        >
          {imageSrc ? (
            <img ref={imageRef} src={imageSrc} className="w-full" />
          ) : (
            <span className={styles.center}>Please select image file</span>
          )}
        </ReactCrop>
      </ComplexModal>
    </>
  );
});
