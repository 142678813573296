import { getPhrases } from "@common/helpers/localization";
import { OrgUnitsForm } from "@common/types/reports/org-units/org-units";
import * as yup from "yup";

const loc = getPhrases("common", "forms").validation;

export const UpdateOrgUnitSchema: yup.SchemaOf<OrgUnitsForm> = yup.object().shape({
  name: yup.string().required(loc.required),
  type_id: yup.number().required(loc.required),
  parent_id: yup.number()
});

export const defaultOrgUnitTypeValue = 1;
