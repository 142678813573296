import { makeAutoObservable } from "mobx";
import { ClassCommon } from "../types/common";
import { CommonWithIntegerId } from "@common/types/common";
import { ExtendedDataWrapper } from "@common/api/types/response/common";
import { CourseItemInterface } from "@common/types/reports/learn/learn";
import { getContinueLearning } from "@common/features/learn/model";

type EmployeeResources = "continueLearning" | "selectedForYou" | "learningPaths";

export class Learn implements ClassCommon {
  resourceTypes: CommonWithIntegerId[] = [];
  providers: CommonWithIntegerId[] = [];
  languages: CommonWithIntegerId[] = [];
  expertiseLevels: CommonWithIntegerId[] = [];
  favoriteResources: ExtendedDataWrapper<CourseItemInterface[]> | null = null;
  favoritesCount: number | null = null;
  continueLearning: CourseItemInterface[] | null = null;
  hasEmployeeResources: boolean | null = null;
  employeeResourcesAvailability: { [key in EmployeeResources]?: boolean | null } | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setContinueLearning(resources: CourseItemInterface[]) {
    this.continueLearning = resources;
  }

  updateContinueLearning() {
    getContinueLearning();
  }

  checkEmployeeResources() {
    if (this.employeeResourcesAvailability !== null) {
      if (
        this.employeeResourcesAvailability.continueLearning === false &&
        this.employeeResourcesAvailability.selectedForYou === false &&
        this.employeeResourcesAvailability.learningPaths === false
      ) {
        this.hasEmployeeResources = false;
      } else {
        this.hasEmployeeResources = true;
      }
    }
  }
  setEmployeeResourceAvailability(code: EmployeeResources, value: boolean) {
    if (this.employeeResourcesAvailability) {
      this.employeeResourcesAvailability[code] = value;
    } else {
      this.employeeResourcesAvailability = { [code]: value };
    }
    this.checkEmployeeResources();
  }

  setFavoriteResources(data: ExtendedDataWrapper<CourseItemInterface[]>) {
    this.favoriteResources = data;
  }

  setFavoritesCount(count: number) {
    this.favoritesCount = count;
  }

  increaseFavoritesCount() {
    this.favoritesCount === null ? (this.favoritesCount = 1) : (this.favoritesCount += 1);
  }

  decreaseFavoritesCount() {
    this.favoritesCount !== null && (this.favoritesCount -= 1);
  }

  setResourceTypes(types: CommonWithIntegerId[]) {
    this.resourceTypes = types;
  }

  getResourceTypes() {
    return this.resourceTypes;
  }

  setProviders(data: CommonWithIntegerId[]) {
    this.providers = data;
  }

  getProviders() {
    return this.providers;
  }

  setLanguages(data: CommonWithIntegerId[]) {
    this.languages = data;
  }

  getLanguages() {
    return this.languages;
  }

  setExpertiseLevels(data: CommonWithIntegerId[]) {
    this.expertiseLevels = data;
  }

  getExpertiseLevels() {
    return this.expertiseLevels;
  }

  reset(): void {
    this.resourceTypes = [];
    this.favoriteResources = null;
    this.favoritesCount = null;
    this.continueLearning = null;
  }
}

export const $learnStore = new Learn();
