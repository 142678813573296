import { AssessmentModules } from "@common/types/reports/assessments/assessments";
import { ICellTextProps } from "ka-table/props";
import { PropsWithChildren } from "react";

export const getMemberData = (data: PropsWithChildren<ICellTextProps>): string => {
  const { member } = data.rowData;
  return member
    ? `${member.first_name ? `${member.first_name} ` : ""}${member.middle_name ? `${member.middle_name} ` : ""}${
        member.last_name ?? ""
      }`
    : "";
};

export const getAssessmentType = (data: PropsWithChildren<ICellTextProps>): string => {
  const module: AssessmentModules = data.rowData.module;

  switch (module) {
    case "sc":
      return "Accountabilities Assessment";
    case "se":
      return "Skills Assessment";
    default:
      return "";
  }
};
