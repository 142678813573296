import * as yup from "yup";
import { getPhrases } from "@common/helpers/localization";
import { AssignRoleForm } from "@common/types/reports/profile/profile";

const loc = getPhrases("common", "forms").validation;
export const assignRoleSchema: yup.SchemaOf<AssignRoleForm> = yup.object().shape({
  orgUnit: yup.number().required(loc.required),
  role: yup.number().required(loc.required),
  manager: yup.number().required(loc.required),
  startDate: yup.string().required(loc.required)
});
