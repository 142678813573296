import classNames from "classnames";
import React from "react";
import sprite from "./sprite.svg";
import styles from "./svg.module.scss";

interface Props {
  name: string;
  modClass?: string;
  onClick?: () => void;
}

export const Svg = ({ name, modClass, onClick }: Props) => {
  const svgClass = classNames(styles.svg, name && styles[`svg-${name}`], modClass);

  return (
    <span className={svgClass} onClick={onClick}>
      <svg preserveAspectRatio="none">
        <use xlinkHref={`${sprite}#${name}`}></use>
      </svg>
    </span>
  );
};
