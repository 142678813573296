import { ResetPasswordInterface } from "@features/user/forms/reset-password/model";
import { AxiosResponse } from "axios";
import { HttpService } from "../http";
import { AuthResponse } from "../types/response/auth";
import { SendResetPasswordLink } from "@common/api/types/response/send-reset-password-link";
import { ResetPasswordSendFormInterface } from "@features/user/forms/reset-password/model";
import { LoginFormInterface } from "@features/user/forms/login/model";
import { AccountActivationSendFormInterface } from "@features/user/forms/account-activation/model";
import { DataWrapper } from "@common/api/types/response/common";

export const login = async ({ email, password }: LoginFormInterface): Promise<AxiosResponse<AuthResponse>> => {
  return HttpService.post<AuthResponse>("/auth/login", { email, password });
};

export const loginAs = async (memberId: number): Promise<AxiosResponse<AuthResponse>> => {
  return HttpService.post<AuthResponse>("/client/organization/login-as", { member_id: memberId });
};

export const sendResetPasswordLink = async ({
  email
}: ResetPasswordInterface): Promise<AxiosResponse<SendResetPasswordLink>> => {
  return HttpService.post<SendResetPasswordLink>("/auth/send-password-reset-link", {
    email
  });
};

export const resetPassword = async ({
  ...props
}: ResetPasswordSendFormInterface): Promise<AxiosResponse<SendResetPasswordLink>> => {
  return HttpService.post<SendResetPasswordLink>("/auth/reset-password", {
    ...props
  });
};

export const activateAccount = async ({
  ...props
}: AccountActivationSendFormInterface): Promise<AxiosResponse<DataWrapper<string>>> => {
  return HttpService.post<DataWrapper<string>>("/auth/activate-member", {
    ...props
  });
};
