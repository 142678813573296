import React, { ReactNode } from "react";
import classNames from "classnames";
import { getPhrases } from "@common/helpers/localization";
import { ButtonProps, Button, SelectUI, SelectProps } from "@ui";
import styles from "./control-line.module.scss";
import "./control-line-select.scss";
import { MultiSelectCheckbox } from "../../pages/learn/MultiSelectCheckbox";

const loc = getPhrases("common", "control_line");

export interface ExtendedSelectProps extends SelectProps {
  label: string;
  isMulti?: boolean;
}

export interface ControlLineProps {
  extraFilters?: ExtendedSelectProps[];
  filterProps: SelectProps;
  buttonsProps?: ButtonProps[];
  modClass?: string;
  children?: ReactNode;
}

export const ControlLine = ({ extraFilters, filterProps, buttonsProps, modClass, children }: ControlLineProps) => {
  return (
    <div className={classNames(styles.wrapper, modClass, "flex-wrap")}>
      <div className="w-full flex justify-between items-center">
        <div className={styles["filter-block"]}>
          <div className={styles["filter-block-title"]}>{`${loc.filter_title}:`}</div>
          <SelectUI {...filterProps} className="control-line-select" classNamePrefix="control-line-select" />
        </div>
        {extraFilters?.map(({ label, isMulti, options, placeholder, ...filterProps }) => (
          <div key={label} className={classNames("ml-2", styles["filter-block"])}>
            <div className={styles["filter-block-title"]}>{label}:</div>
            {isMulti ? (
              <div className="min-w-46">
                <MultiSelectCheckbox {...filterProps} placeholder={placeholder || ""} data={options} isMulti />
              </div>
            ) : (
              <SelectUI
                {...filterProps}
                options={options}
                className="control-line-select"
                classNamePrefix="control-line-select"
              />
            )}
          </div>
        ))}
        <div className={styles.buttons}>
          {buttonsProps &&
            buttonsProps.map((props, index) => {
              return (
                <div key={`control-button-${index}`} className={styles.button}>
                  <Button {...props} size="thin" className={classNames(styles["button-item"], props.className)} />
                </div>
              );
            })}
        </div>
      </div>
      {children}
    </div>
  );
};
