import { FC } from "react";
import classNames from "classnames";
import { Button } from "@ui";

export interface ILearningResourceDeleteConfirmProps {
  className?: string;
  onClose(): void;
  onDelete(): void;
}

export const LearningResourceDeleteConfirm: FC<ILearningResourceDeleteConfirmProps> = ({
  className,
  onDelete,
  onClose
}) => {
  return (
    <div className={classNames("bg-blue-light flex-center flex-col", className)}>
      <div className="text-white text-2xl">You are about to delete this</div>
      <div className="max-w-[80%] text-warning text-3xl truncate mt-4">Learning Resource</div>
      <div className="w-120 max-w-[90%] text-white text-2xl text-center mt-4">
        This will permanently delete this learning resource and all associated metrics
      </div>
      <div className="flex mt-6">
        <Button className="!w-32 !h-14 !text-sm !text-left !font-semibold" theme="secondary-gradient" onClick={onClose}>
          <span className="w-full px-2">
            Cancel
            <br />
            Deletion
          </span>
        </Button>
        <Button className="!w-40 !h-14 !text-sm !text-left !font-semibold ml-4" onClick={onDelete}>
          <span className="w-full px-2">
            Delete
            <br />
            Learning Resource
          </span>
        </Button>
      </div>
    </div>
  );
};
