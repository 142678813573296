import { WithId } from "../../common";

export enum DurationCategories {
  month = 30,
  quarter = 90,
  half_year = 182,
  year = 365
}

export enum DisplayCategories {
  all = 1,
  activation,
  password_reset,
  self_assessment,
  manage_assessment
}

export enum InvitationTypeEnum {
  activation = "Account Activation",
  password = "Password Reset",
  self_assess = "Self Assessment Invitation"
}

export enum ActiveStatus {
  completed,
  active,
  pending,
  inactive
}

export enum LogStatus {
  pending = "Pending",
  active = "Active",
  inactive = "Inactive",
  completed = "Completed"
}

export interface Member {
  id: number;
  email: string;
  full_name: string;
  is_active: boolean;
}

export interface ExtendedMember extends Member {
  completed_at: string;
}

export interface Invitation extends WithId {
  type: NotificationType;
  member: Member;
  created_at: string;
  role_name: string;
  unit_name: string;
}

export interface FilterParams {
  [key: string]: any;
}

export interface PaginationParams {
  "page": number;
  "per-page": number;
}

export interface NotificationType {
  abbreviation: string;
  name: InvitationTypeEnum;
}

export class NotificationModel {
  index: number;
  id: string;
  name: string;
  request_id: string;
  type: InvitationTypeEnum;
  typeAbbreviation: string;
  email: string;
  is_active?: boolean;
  status_name: string = "";
  used_at?: string;
  requested_at: string;
  role_name: string;
  unit_name: string;
  created_at: string;

  constructor(notification?: Invitation, index = 0) {
    this.index = index;
    this.id = "";
    this.name = "";
    this.request_id = "";
    this.type = InvitationTypeEnum.activation;
    this.typeAbbreviation = "";
    this.email = "";
    this.used_at = "";
    this.requested_at = "";
    this.created_at = "";
    this.role_name = "";
    this.unit_name = "";

    if (notification) {
      this.id = notification.id;
      this.name = notification.member.full_name;
      this.email = notification.member.email;
      this.is_active = notification.member.is_active;
      this.status_name = notification.member.is_active ? "Active" : "Inactive";
      this.type = notification.type.name;
      this.typeAbbreviation = notification.type.abbreviation;
      this.created_at = notification.created_at;
      this.role_name = notification.role_name;
      this.unit_name = notification.unit_name;
    }
  }
}

export interface Assessment {
  id: number;
  member?: Member | null;
  role_name: string;
  manager?: Member | null;
  self_completed_at: string | null;
  manager_completed_at: string | null;
  report_card_url?: string | null;
  unit_name: string;
}

export class AssessmentModel {
  id: number;
  member?: Member;
  role_name: string;
  manager?: ExtendedMember;
  self?: Partial<ExtendedMember>;
  self_completed_at?: string;
  manager_completed_at?: string;
  report_card_url?: string;
  index: number;
  request_id: number;
  email: string;
  full_name: string;
  status_name: string;
  requested_at: string;
  unit_name?: string;
  reports: number;
  required: number;

  constructor(assessment?: Assessment, index = 0) {
    this.index = index;
    this.id = 0;
    this.request_id = 0;
    this.full_name = "";
    this.email = "";
    this.requested_at = "";
    this.status_name = "";
    this.reports = 0;
    this.required = 0;
    this.role_name = "";
    this.unit_name = "";
    this.self_completed_at = "";

    if (assessment) {
      this.id = assessment.id;
      this.email = assessment.member?.email || "";
      this.full_name = assessment.member?.full_name || "";
      this.status_name = assessment.member?.is_active ? "Active" : "Inactive" || "";
      this.role_name = assessment.role_name;
      this.unit_name = assessment.unit_name;
      this.self_completed_at = assessment.self_completed_at || "";
      if (assessment.manager) {
        this.manager = {
          ...assessment.manager,
          completed_at: assessment.manager_completed_at || ""
        };
      }
      if (assessment.member) {
        this.member = assessment.member;
      }
      this.self = {
        completed_at: assessment.self_completed_at || ""
      };
      this.report_card_url = assessment.report_card_url || "";
      this.unit_name = assessment.unit_name || "";
    }
  }
}
