import { FC } from "react";
import classNames from "classnames";
import { Button } from "@ui";

export interface IFormErrorProps {
  className?: string;
  onClose(): void;
}

export const FormError: FC<IFormErrorProps> = ({ className, onClose }) => {
  return (
    <div className={classNames("bg-blue-light flex-center flex-col", className)}>
      <div className="text-white text-2xl">You have not completed this stage</div>
      <div className="text-warning text-3xl mt-4">Please fill out the form in order to continue</div>
      <Button className="!text-md !font-semibold mt-8" title="Return to Form" onClick={onClose} />
    </div>
  );
};
