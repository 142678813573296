import { getPhrases } from "@common/helpers/localization";
import { FormInputText } from "@ui";
import { search } from "ka-table/actionCreators";
import { DispatchFunc } from "ka-table/types";
import styles from "./search.module.scss";

interface Props {
  dispatch: DispatchFunc;
}

export const Search = ({ dispatch }: Props) => {
  const loc = getPhrases("common", "search");
  return (
    <div className={styles.search}>
      {loc.search_text}:
      <div className={styles["search-input"]}>
        <FormInputText
          type="search"
          name="table-search"
          onChange={(event: any) => {
            dispatch(search(event.currentTarget.value));
          }}
        />
      </div>
    </div>
  );
};
