import { FC } from "react";
import classNames from "classnames";
import { Button } from "@ui";

export interface IInvalidQuizFileProps {
  className?: string;
  fileName: string;
  quizFileFormat: string;
  onClose(): void;
}

export const InvalidQuizFile: FC<IInvalidQuizFileProps> = ({ className, fileName, quizFileFormat, onClose }) => {
  return (
    <div className={classNames("bg-blue-light flex-center flex-col", className)}>
      <div className="text-white text-2xl">Invalid file type</div>
      <div className="max-w-[80%] text-warning text-3xl truncate mt-4">
        {fileName} is not a {quizFileFormat} format
      </div>
      <div className="w-120 max-w-[90%] text-white text-2xl text-center mt-4">
        This file can not be uploaded. Please, check that the file's content meets the {quizFileFormat} requirements and
        upload it again.
      </div>
      <div className="flex mt-6">
        <Button className="!w-32 !h-14 !text-sm !font-semibold ml-4" onClick={onClose}>
          Ok
        </Button>
      </div>
    </div>
  );
};
