import { Roles } from "@common/constants";

interface LayoutOptions {
  name: string;
  iconName: string;
  iconType?: "fa" | "svg-sprite";
  access?: Roles[];
}

export const layoutOptions: LayoutOptions[] = [
  {
    name: "tree",
    iconName: "fas fa-stream",
    iconType: "fa",
    access: [Roles.clientAdmin]
  },
  { name: "carousel", iconName: "carousel-icon", access: [Roles.employee] },
  { name: "grid", iconName: "grid-icon" },
  {
    name: "list",
    iconName: "list-icon",
    access: [Roles.manager, Roles.clientAdmin]
  }
];
