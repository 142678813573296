import { $userStore } from "@common/api/store/user";
import { getExtendData } from "@common/helpers/data-helpers";
import { AssessmentsMyCompleted } from "@common/types/reports/assessments/assessments";
import { FirstLevelTable } from "@ui";
import { DataType, SortDirection } from "ka-table/enums";
import { Column } from "ka-table/models";
import { ICellTextProps } from "ka-table/props";
import { PropsWithChildren } from "react";
import { getAssessmentType, getMemberData } from "../helpers";
import styles from "./my-completed.module.scss";

interface Props {
  data: AssessmentsMyCompleted[];
}

export const MyCompleted = ({ data }: Props) => {
  return FirstLevelTable<AssessmentsMyCompleted>({
    pageSize: 25,
    sorting: true,
    search: ({
      searchText,
      rowData,
      column
    }: {
      searchText: string;
      rowData: AssessmentsMyCompleted;
      column: Column;
    }) => {
      const searchTextLowCase = searchText.toLowerCase();
      if (column.key === "created_at") {
        return (
          getExtendData(rowData.created_at * 1000)
            .toLowerCase()
            .search(searchTextLowCase) > -1
        );
      }
      if (column.key === "assigned_to") {
        return rowData.assigned_to.toLowerCase().search(searchTextLowCase) > -1;
      }
      if (column.key === "type" && rowData.module) {
        return (
          getAssessmentType({ rowData } as unknown as PropsWithChildren<ICellTextProps>)
            .toLowerCase()
            .search?.(searchTextLowCase) > -1
        );
      }
      if (column.key === "member") {
        return (
          (rowData.member?.first_name + " " + rowData.member?.last_name).toLowerCase().search?.(searchTextLowCase) > -1
        );
      }
      return false;
    },
    sort: ({ column }) => {
      if (column.key === "member") {
        return (a, b) => {
          const nameA = (a?.first_name + a?.last_name || "") as string;
          const nameB = (b?.first_name + b?.last_name || "") as string;
          const compare = nameA === "" ? -1 : nameB === "" ? 1 : nameA.localeCompare(nameB);
          return column.sortDirection === SortDirection.Ascend ? compare : ((compare * -1) as any);
        };
      }
    },

    data,
    columns: [
      {
        key: "index",
        title: "#",
        dataType: DataType.String,
        sortDirection: SortDirection.Ascend,
        decorator: data => data.rowData.rowDataIndex + 1
      },
      {
        key: "type",
        title: "Type",
        dataType: DataType.Object,
        decorator: data => getAssessmentType(data)
      },
      {
        key: "assigned_to",
        title: "Assigned To",
        dataType: DataType.String,
        decorator: data => {
          const currentUserName = `${$userStore.userInfo?.first_name ? `${$userStore.userInfo?.first_name} ` : ""}${
            $userStore.userInfo?.last_name ?? ""
          }`;
          return currentUserName === data.value ? "Me" : data.value === "You" ? "Me" : data.value;
        }
      },
      {
        key: "member",
        title: "Full Name",
        dataType: DataType.Object,
        sortDirection: SortDirection.Descend,
        decorator: data => getMemberData(data)
      },
      {
        key: "created_at",
        title: "Created on",
        dataType: DataType.Number,
        decorator: data => getExtendData(data.value * 1000)
      }
    ],
    pagination: true,
    modStyles: styles
  });
};
