import { AssessmentsStaffUncompleted } from "@common/types/reports/assessments/assessments";
import { TableColumn } from "@common/types/table";
import classNames from "classnames";
import { DataType, SortDirection } from "ka-table/enums";
import grid from "@styles/modules/grid.module.scss";
import { getExtendData } from "@common/helpers/data-helpers";
import { getAssessmentType, getMemberData } from "../helpers";

export const assessmentsStaffUncompletedColumns: TableColumn<AssessmentsStaffUncompleted>[] = [
  {
    key: "id",
    title: "#",
    dataType: DataType.String,
    modClass: classNames(grid.item, grid["item-1x"]),
    sortDirection: SortDirection.Descend,
    decorator: data => data.rowData.rowDataIndex + 1
  },
  {
    key: "type",
    title: "Type",
    dataType: DataType.Object,
    modClass: classNames(grid.item, grid["item-3x"]),
    decorator: data => getAssessmentType(data)
  },
  {
    key: "assigned_to",
    title: "Assigned To",
    dataType: DataType.String,
    modClass: classNames(grid.item, grid["item-3x"])
  },
  {
    key: "member",
    title: "Full Name",
    dataType: DataType.Object,
    modClass: classNames(grid.item, grid["item-3x"]),
    decorator: data => getMemberData(data)
  },
  {
    key: "created_at",
    title: "Created",
    dataType: DataType.Number,
    modClass: classNames(grid.item, grid["item-2x"]),
    decorator: data => getExtendData(data.value * 1000)
  }
];
