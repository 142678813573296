import { Button, Titles } from "@ui";
import styles from "./auth-notify.module.scss";

interface AuthNotifyProps {
  title: string;
  submitText: string;
  onSubmit: () => void;
}

export const AuthNotify: React.FC<AuthNotifyProps> = ({ title, submitText, onSubmit, children }) => {
  return (
    <>
      <Titles level="level_1" modClass={styles["notify-title"]}>
        {title}
      </Titles>
      <div className={styles["notify-description"]}>{children}</div>
      <Button title={submitText} className={styles["notify-button"]} onClick={() => onSubmit()} />
    </>
  );
};
