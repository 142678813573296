import React from "react";
import {
  ShadowBlock,
  Titles,
  Button,
  FormInputText,
  FormInputPassword,
  FormInputAutocomplete,
  FormInputRadioGroup
} from "@ui";
import grid from "@styles/modules/grid.module.scss";
import form from "@styles/modules/form.module.scss";
import styles from "./ui-page.module.scss";
import { InputRadioOption } from "@common/types/form";
import { useForm } from "@common/hooks/form";
import { UIPageInterface } from "./types";
import { UIPageScheme } from "./model";

export const UIPage = (): JSX.Element => {
  const autocompleteList: string[] = ["item 1", "item 2", "item 3", "item 4", "item 5", "item 6", "item 7"];

  const radioButtons: InputRadioOption[] = [
    {
      label: "Text label 1",
      value: 1
    },
    {
      label: "Text label 2",
      value: 2
    }
  ];

  const methods = useForm<UIPageInterface>({
    schema: UIPageScheme
  });

  return (
    <div className={grid.root}>
      <div className={grid.container}>
        <div className={styles.row}>
          <ShadowBlock>Shadow Block</ShadowBlock>
        </div>
        <div className={styles.row}>
          <Titles level="level_1">Title level 1</Titles>
        </div>
        <div className={styles.row}>
          <div className={grid.list}>
            <div className={`${grid.item} ${grid["item-4x"]}`}>
              <div className={form.field}>
                <FormInputText placeholder="Some text" name="input" />
              </div>
            </div>
            <div className={`${grid.item} ${grid["item-4x"]}`}>
              <div className={form.field}>
                <FormInputPassword name="password" placeholder="Some text" />
              </div>
            </div>
            <div className={`${grid.item} ${grid["item-4x"]}`}>
              <div className={form.field}>
                <FormInputAutocomplete list={autocompleteList} name="autocomplete" placeholder="Some text" />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={grid.list}>
            <div className={`${grid.item} ${grid["item-4x"]}`}>
              <Button title="Button text" />
            </div>
            <div className={`${grid.item} ${grid["item-4x"]}`}>
              <Button title="Button text" theme="gray" />
            </div>
            <div className={`${grid.item} ${grid["item-4x"]}`}>
              <Button title="Button text" theme="blue" />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={grid.list}>
            <div className={`${grid.item} ${grid["item-4x"]}`}>
              <Button title="Button text" appearance="bordered" />
            </div>
            <div className={`${grid.item} ${grid["item-4x"]}`}>
              <Button title="Button text" theme="gray" appearance="bordered" />
            </div>
            <div className={`${grid.item} ${grid["item-4x"]}`}>
              <Button title="Button text" theme="blue" appearance="bordered" />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={grid.list}>
            <div className={`${grid.item} ${grid["item-4x"]}`}>
              <Button title="Button text" size="thin" />
            </div>
            <div className={`${grid.item} ${grid["item-4x"]}`}>
              <Button title="Button text" size="thin" theme="gray" />
            </div>
            <div className={`${grid.item} ${grid["item-4x"]}`}>
              <Button title="Button text" size="thin" theme="blue" />
            </div>
          </div>
        </div>
        <div className={styles.row}>
          <div className={grid.list}>
            <div className={`${grid.item} ${grid["item-4x"]}`}>
              <FormInputRadioGroup methods={methods} name="radio" options={radioButtons} defaultValue={2} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
