import { ProgressBar, Titles } from "@ui";
import classNames from "classnames";
import styles from "./categories.module.scss";
import optics from "./../optics.module.scss";
import information from "./../../information.module.scss";
import { getPhrases } from "@common/helpers/localization";
import { ScoreDataProps } from "@common/types/reports/skills/skills-employee";
import { useEffect, useState } from "react";
import { formatCategoriesData } from "./model";

export interface CategoriesOptics extends Omit<ScoreDataProps, "skills"> {}

interface Props {
  data: ScoreDataProps[];
}

export const Categories = ({ data }: Props) => {
  const [categories, setCategories] = useState<CategoriesOptics[]>([]);
  useEffect(() => {
    setCategories(formatCategoriesData(data));
  }, [data]);
  const loc = getPhrases("dashboard_page", "optics_category");
  return (
    <>
      <div className={optics.titleline}>
        <Titles level="level_3" modClass={classNames(optics.title, information.title)}>
          {loc.title}
        </Titles>
        <div className={styles.legend}>
          <span className={styles["legend-score"]}>{loc.legend_score}</span>
          {" / "}
          <span className={styles["legend-target"]}>{loc.legend_target}</span>
        </div>
      </div>
      <div className={styles.block}>
        {!!categories?.length &&
          categories.map((item, index) => {
            return (
              <div className={styles.item} key={`categories-item-${item.id}`}>
                <div className={styles["item-title"]}>{item.name}</div>
                <ProgressBar
                  progress={item.avg_score}
                  modClass={classNames(styles.value, styles["item-row"], styles["item-line"])}
                />
                <ProgressBar progress={item.avg_target} modClass={classNames(styles.target, styles["item-line"])} />
              </div>
            );
          })}
      </div>
    </>
  );
};
