import React, {ChangeEvent, FC} from "react";
import classNames from "classnames";
import { InputInterface } from "@common/types/form";
import form from "@styles/modules/form.module.scss";

interface Props
  extends Omit<InputInterface, "placeholder" | "register" | "error"> {
className?: string;
  label?: string;
  value: any;
  checked?: boolean;
  size?: 'sm' | 'md';
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

export const FormInputCheckbox: FC<Props> = ({
  className = '',
  name,
  theme = "gray",
  label,
  value,
  checked = false,
  size = 'md',
  onChange,
  disabled = false,
  ...props
}) => {
  const labelClassName = classNames(
    form.label,
    form[`label-${theme}`],
    form["radio-label"],
    form[`size-${size}`],
    className
  );

  const checkboxProps = checked ? {
    checked: true
  } : {}

  return (
    <label
      className={labelClassName}
    >
      <input
        type="checkbox"
        name={name}
        className="input-checkox"
        value={value}
        defaultChecked={checked}
        onChange={onChange}
        disabled={disabled}
        {...props}
        {...checkboxProps}
      />
      <span className="overlay" />
      <span className={form["label-text"]}>{label}</span>
    </label>
  );
}
