import {
  getRoles as getRolesFunction,
  getRoleDetail as getRoleDetailFunction,
  createRole as createRoleFunction,
  updateMemberRoleService,
  deleteRole as deleteRoleFunction
} from "@common/api/services/roles";
import { UpdateMembersRole } from "@common/types/managment/edit-person";
import { CurrentRole, EditPersonInterface } from "@common/types/reports/profile/profile";
import { RoleDetailInterface } from "@common/types/reports/roles/role-detail";
import { RolesDataProps } from "@common/types/reports/roles/roles";
import { Role, RoleForm, RoleSkill, RoleSkillForm, RoleSummary } from "@common/types/roles/roles";
import { $rolesStore } from "@common/api/store/roles";

export const getRoles = async (callback: any) => {
  try {
    const response = await getRolesFunction<RolesDataProps>("web/settings/roles/by-org-units");
    callback(response.data.data);
  } catch (e) {
    console.error("Get roles error");
  }
};

export const getRoleSummaries = () => {
  return getRolesFunction<RoleSummary>("web/settings/roles").then(response => {
    $rolesStore.setRoleSummaries(response.data.data);
    return response.data.data;
  });
};

export const getRoleDetail = async (id: number, callback: any) => {
  try {
    const response = await getRolesFunction<RoleDetailInterface>(`/web/settings/roles/${id}`);
    callback(response.data.data);
  } catch (e) {
    console.error("Get detail role error");
  }
};

export const getMemberRoleById = async (id: number) => {
  try {
    const response = await getRoleDetailFunction<EditPersonInterface>(`/web/members/${id}/role`);
    return response.data.data;
  } catch (e) {
    console.error("Get current role by id error");
  }
};

export const createRole = (data: RoleForm) => {
  return createRoleFunction<Role>("/client/organization/roles", data).then(response => response.data.data);
};

export const getRoleById = (id: number) => {
  return getRoleDetailFunction<Role>(`/client/organization/roles/${id}`).then(response => response.data.data);
};

export const updateRole = (id: number, data: RoleForm) => {
  return createRoleFunction<Role>(`/client/organization/roles/${id}`, data).then(response => response.data.data);
};

export const deleteRole = (id: number) => {
  return deleteRoleFunction(`/client/organization/roles/${id}`).then(response => response.data.data);
};

export const getRoleSkills = (roleId: number) => {
  return getRolesFunction<RoleSkill>(`/client/skills-engine/roles/${roleId}/skills`).then(
    response => response.data.data
  );
};

export const attachSkillsToRole = (roleId: number, skills: RoleSkillForm[]) => {
  return createRoleFunction<RoleSkill>(`/client/skills-engine/roles/${roleId}/skills`, { skills }).then(
    response => response.data.data
  );
};

export const getRoleByOrgUnit = async (id: number, callback: any) => {
  try {
    const response = await getRolesFunction<Role>(`/client/organization/units/${id}/active-roles`);
    callback(response.data.data);
  } catch (e) {
    console.error("Get active roles by org unit error");
  }
};

export const updateMemberRole = async (
  id: number,
  values: UpdateMembersRole,
  successCallback: any,
  failCallback: any
) => {
  try {
    await updateMemberRoleService(id, values);
    successCallback();
  } catch (e) {
    failCallback(e);
    console.error("Update member role error");
  }
};
