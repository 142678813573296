import { TableCellProps } from "@common/types/table";
import { useState } from "react";
import { AreaInfoModal } from "@features/settings/blocks/offices";
import styles from "./area-info.module.scss";

interface AreaProps extends TableCellProps {}

export const AreaInfo: React.FC<AreaProps> = ({ props }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { rowData, column, value } = props;

  return (
    <>
      <div className={styles.office}>
        <div className={styles.name} onClick={() => setIsOpen(true)}>
          {(column.title && value) || "Edit"}
        </div>
      </div>
      {isOpen && <AreaInfoModal closeModal={() => setIsOpen(false)} isOpen id={rowData.id} rowData={rowData} />}
    </>
  );
};
