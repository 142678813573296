import { FormattedPeople } from "@common/types/reports/people/people";
import { TableColumn } from "@common/types/table";
import classNames from "classnames";
import { DataType, SortDirection } from "ka-table/enums";
import grid from "@styles/modules/grid.module.scss";
import { Title } from "./ui";
import { AverageScore } from "../progress";

export const columns: TableColumn<FormattedPeople>[] = [
  {
    key: "full_name",
    title: "Manager Name",
    dataType: DataType.String,
    sortDirection: SortDirection.Ascend,
    modClass: classNames(grid.item, grid["item-6x"]),
    expandCell: true,
    component: Title
  },
  {
    key: "employees_count",
    title: "# Employees",
    dataType: DataType.Number,
    modClass: classNames(grid.item, grid["item-2x"])
  },
  {
    key: "current_unit_name",
    title: "Organizational Unit",
    dataType: DataType.String,
    modClass: classNames(grid.item, grid["item-2x"])
  },
  {
    key: "average_score",
    title: "Average score",
    dataType: DataType.String,
    modClass: classNames(grid.item, grid["item-2x"]),
    component: AverageScore
  }
];
