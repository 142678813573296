import React, { useContext, useEffect, useState } from "react";
import styles from "./video-tab.module.scss";
import ReactPlayer from "react-player";
import { CircularProgressWithDuration } from "../../../ui/circular-progress-with-duration/circular-progress-with-duration";
import { AlertModal, Button, Rating, RatingWithNumber, Svg } from "@ui";
import { Course } from "../types";
import { getDurationHoursAndMinutes } from "@common/helpers/time-helpers";
import {
  addCourseToFavorites,
  getResourceRating,
  removeCourseFromFavorites,
  setCourseProgress,
  setResourceRating
} from "@common/features/learn/model";
import { observer } from "mobx-react-lite";
import { GlobalStore } from "@common/api/store/global";

interface Props extends Course {}

export const VideoTab = observer(({ ...props }: Props) => {
  const { $learnStore } = useContext(GlobalStore);
  const [sendSuccessModalIsOpen, setSendSuccessModalStatus] = useState<boolean>(false);

  const [myRate, setMyRate] = useState<number>(0);

  const [sendFailModalIsOpen, setSendFailModalStatus] = useState<boolean>(false);

  const [isFavourite, setFavouriteStatus] = useState<boolean>(props.isFavourite ?? false);

  const [progressCheckpoint, setProgressCheckpoint] = useState<number>(props.progress || 0);

  const [isComplete, setCompleteStatus] = useState<boolean>(Boolean(props.progress && props.progress === 100));

  useEffect(() => {
    getResourceRating(props.id, setMyRate);
  }, []);

  const courseCompleteHandler = () => {
    sendProgress(
      100,
      () => {
        setSendSuccessModalStatus(true);
        setCompleteStatus(true);
        $learnStore.updateContinueLearning();
      },
      () => setSendFailModalStatus(true)
    );
  };

  const checkStartProgress = () => {
    if (progressCheckpoint === 0) {
      sendProgress(5, () => $learnStore.updateContinueLearning());
    }
  };

  const calcProgress = (progressValue: number) => {
    const progressPercentage = Math.round(progressValue * 100);
    if (progressPercentage >= 25 && progressPercentage < 50 && progressCheckpoint < 25) {
      sendProgress(25);
    } else if (progressPercentage >= 50 && progressPercentage < 75 && progressCheckpoint < 50) {
      sendProgress(50);
    } else if (progressPercentage >= 75 && progressCheckpoint < 75) {
      sendProgress(75);
    }
  };

  const sendProgress = (value: number, successCallback?: () => void, failCallback?: () => void) => {
    setCourseProgress(
      props.id,
      value,
      () => {
        setProgressCheckpoint(value);
        successCallback && successCallback();
      },
      () => failCallback && failCallback()
    );
  };

  const toggleIsFavourite = async () => {
    const changeFavoriteState = () => {
      setFavouriteStatus(prev => !prev);
    };

    !isFavourite
      ? await addCourseToFavorites({ resource_id: props.id })
          .then(data => {
            changeFavoriteState();
            $learnStore.increaseFavoritesCount();
          })
          .catch(errors => console.error("Add course to favorites error"))
      : await removeCourseFromFavorites({ resource_id: props.id })
          .then(data => {
            changeFavoriteState();
            $learnStore.decreaseFavoritesCount();
          })
          .catch(errors => console.log("Remove course from favorites error"));
  };

  const onChangeVideoRating = (value: number) => {
    setResourceRating(props.id, { value });
  };

  return (
    <>
      <div>
        {/*     VIDEO TOP DETAILS   */}
        {/* <div className={styles["video-details"]}>
          <div className={styles.item}>
            <Svg name={"heart-red"} />
            <Svg name={"clock-history"} />
            <div>3h</div>
            <Badge type={"Beginner"}>Beginner</Badge>
            <div className={styles.free}>Free</div>
          </div>

          <div className={styles.item}>
            <p className={styles.heading}>Checklist:</p>
            <p className={styles.text}>2/5</p>
          </div>

          <div className={styles.item}>
            <p className={styles.heading}>Quiz</p>
            <p className={styles.text}>Passed (86%) - 1 attempt</p>
          </div>

          <div>
            <Svg modClass={styles.share} name={"share-video"} />
          </div>
        </div> */}

        {/*     Video*/}
        <div className={styles.video}>
          <ReactPlayer
            controls={true}
            width={"100%"}
            url={props.url}
            {...(progressCheckpoint < 100 && {
              onProgress: data => calcProgress(data.played),
              onPlay: () => checkStartProgress(),
              onEnded: () => sendProgress(100, () => setCompleteStatus(true))
            })}
          />
        </div>

        {/*     VIDEO BOTTOM DETAILS    */}

        {/*   Bottom First Row    */}
        <div className={styles["modal-bottom-first-row"]}>
          {/*    Bottom First Row (Left Side   details)   */}
          <div className={styles["first-row-left"]}>
            {props.duration && (
              <CircularProgressWithDuration duration={getDurationHoursAndMinutes(props.duration)} progress={50} />
            )}
            <Svg name={`heart-${isFavourite ? "red" : "gray"}`} onClick={toggleIsFavourite} />
            <div className={styles["bold"]}>{props.difficulty}</div>
            {props.isFree && <div className={styles["bold"]}>Free</div>}
          </div>
          {/*    Bottom First Row (Right Side   details)   */}
          <div>
            <Button
              theme={isComplete ? "green" : "orange"}
              appearance={"solid"}
              size={"thin"}
              {...(!isComplete && { onClick: courseCompleteHandler })}
              {...(isComplete && { className: styles["video-completed"] })}
            >
              {!isComplete ? "Mark Complete" : "Completed"}
            </Button>
          </div>
        </div>
        {/*   Bottom Second Row   */}
        <div className={styles["modal-bottom-second-row"]}>
          <div className={styles["second-row-left"]}>
            {props.reviews_count && (
              <div className={styles["logo-with-views"]}>
                <img
                  src="https://www.freepnglogos.com/uploads/youtube-play-red-logo-png-transparent-background-6.png"
                  alt="logo"
                  className={styles["logo"]}
                />
                <div>{props.reviews_count} people have watched this video</div>
              </div>
            )}

            {(props.totalRatings || props.totalRatings === 0) && (props.rating || props.rating === 0) && (
              <div>
                <div className={styles["small"]}>Average Rating</div>
                <div className={styles["mt-sm"]}>
                  <RatingWithNumber totalRatings={props.totalRatings} rating={props.rating} />
                </div>
              </div>
            )}

            <div>
              <div className={styles["small"]}>Your rating</div>
              <div className={styles["mt-sm"]}>
                <Rating rating={myRate || 0} onChange={value => onChangeVideoRating(value)} />
              </div>
            </div>
          </div>
          <div className={styles["second-row-right"]}>
            {/* <div>
            <div className={classNames(styles["small"])}>Checklist</div>
            <div className={classNames(styles["bold"], styles["medium"])}>
              2 / 5
            </div>
          </div>

          <div>
            <div className={classNames(styles["small"])}>Quiz</div>
            <div className={classNames(styles["bold"], styles["medium"])}>
              Passed (86%) - 1 attempt
            </div>
          </div> */}
          </div>
        </div>
      </div>
      <AlertModal
        cancelButtonText="Close"
        message="Something went wrong :-( We are working on it. Please try again later"
        title="Alert"
        type="fail"
        closeModal={() => setSendFailModalStatus(false)}
        isOpen={sendFailModalIsOpen}
      />
      <AlertModal
        cancelButtonText="Close"
        message="Course successfully completed!"
        title="Done"
        type="success"
        closeModal={() => setSendSuccessModalStatus(false)}
        isOpen={sendSuccessModalIsOpen}
      />
    </>
  );
});
